<template>
    <OpenNavigationApp
        :coordinate="coordinate"
        :form-name="formName"
        :show="softwareChoiceDialogVisible"
        @close-dialog="closeDialog"
    />

    <article
        v-if="!loading"
        v-bind="$attrs"
    >
        <div class="main__header">
            <h3 class="title">{{ layerAlias }}</h3>
            <button
                class="btn--back custom_link"
                @click="goBack"
            >
                {{ $t('objectData.objectSelection') }}
            </button>
        </div>

        <el-row
            v-if="!loading"
            class="m-0"
        >
            <el-col
                v-if="isLayerEditable(layerData)"
                :span="12"
                class="p-[10px]"
            >
                <el-button
                    v-if="!loadingData"
                    :icon="Edit"
                    class="w-full"
                    plain
                    type="primary"
                    @click="openForm"
                >
                    {{ $t('globals.edit') }}
                </el-button>
                <el-button
                    v-if="loadingData"
                    :icon="Edit"
                    :loading="true"
                    class="w-full"
                    disabled
                    plain
                    type="primary"
                >
                    {{ $t('globals.loading') }}
                </el-button>
            </el-col>
            <el-col
                v-if="isLayerEditable(layerData)"
                :span="12"
                class="p-[10px]"
            >
                <el-button
                    :icon="MapLocation"
                    class="w-full"
                    type="primary"
                    @click="mapsSelector"
                >
                    Itinéraire
                </el-button>
            </el-col>
            <el-col
                v-if="isLayerEditable(layerData)"
                :span="12"
            />
            <el-col
                v-if="!layerData.coordinate"
                :span="24"
                class="p-[10px]"
            >
                <span class="error">
                    <FontAwesomeIcon icon="exclamation-circle" />
                    {{ $t('objectData.error') }}
                </span>
            </el-col>
            <el-col
                v-if="locationNameLoading"
                :span="24"
                class="p-[10px]"
            >
                <span class="custom_label_input">{{
                    $t('objectData.loadingAddress')
                }}</span>
            </el-col>
            <el-col
                v-if="locationName && !locationNameLoading"
                :span="24"
                class="p-[10px]"
            >
                <span class="custom_label_input">
                    <FontAwesomeIcon icon="map-marker-alt" />
                    {{ locationName }}
                </span>
            </el-col>
            <el-col
                v-if="item"
                :span="24"
            >
                <div
                    v-for="entry in item"
                    :key="entry"
                >
                    <el-row class="custom_row">
                        <el-col
                            :span="12"
                            class="align"
                        >
                            <span class="custom_label_input"
                                >{{ entry.label }} :</span
                            >
                        </el-col>
                        <el-col
                            :span="12"
                            class="align"
                        >
                            <span class="custom_label_input">{{
                                entry.value
                            }}</span>
                        </el-col>
                    </el-row>
                </div>
            </el-col>
        </el-row>
    </article>
</template>

<script lang="ts">
import { Edit, MapLocation } from '@element-plus/icons-vue';
import { mapActions, mapState } from 'pinia';
import { defineComponent } from 'vue';
import { toLonLat } from 'ol/proj';

import * as AddressApi from '@connect-field/client/services/api/address';
import {
    type ProjectConfigurationLayer,
    ProjectConfigurationLayerFormatEnum,
} from '@connect-field/client/sdk/generated';
import useObjectStore, {
    type LayerDataInterface,
} from '@connect-field/client/stores/object';
import { getLayerFromObjectData } from '@connect-field/client/services/form.service';
import OpenNavigationApp from '@connect-field/client/components/dialogs/OpenNavigationApp.vue';
import { redirectToForm } from '@connect-field/client/services/formRouter.service';
import { removePinOnMap } from '@connect-field/client/services/map.service';
import useMenuStore from '@connect-field/client/stores/menu';
import useNavigationStore from '@connect-field/client/stores/navigation';
import usePanelStore from '@connect-field/client/stores/panel';
import useProjectsStore from '@connect-field/client/stores/projects';

interface DataInterface {
    coordinate?: Array<number>;
    formName?: string;
    isRouteFromOfflineSearch: boolean;
    isRouteFromSearched: boolean;
    item?: Array<{ label: string; value: unknown }>;
    layerAlias?: string;
    loading: boolean;
    loadingData: boolean;
    locationName?: string;
    locationNameLoading: boolean;
    softwareChoiceDialogVisible: boolean;
}

export default defineComponent({
    components: {
        OpenNavigationApp,
    },
    props: {
        objectId: { type: String, required: true },
        projectId: { type: String, required: true },
    },
    setup() {
        return {
            Edit,
            MapLocation,
            menuStore: useMenuStore(),
            panelStore: usePanelStore(),
        };
    },
    data(): DataInterface {
        return {
            coordinate: undefined,
            formName: undefined,
            isRouteFromOfflineSearch: false,
            isRouteFromSearched: false,
            layerAlias: undefined,
            loading: true,
            loadingData: false,
            locationName: undefined,
            locationNameLoading: false,
            softwareChoiceDialogVisible: false,
        };
    },
    computed: {
        ...mapState(useObjectStore, {
            dataForm: 'dataForm',
            feature: 'Feature',
            layerData: 'layerData',
        }),
        ...mapState(usePanelStore, {
            panelHeight: 'panelHeight',
            panelMinHeight: 'panelMinHeight',
            panelState: 'panelState',
            panelStateClosed: 'panelStateClosed',
            panelStateHalfOpened: 'panelStateHalfOpened',
            panelStateOpened: 'panelStateOpened',
        }),
        ...mapState(useMenuStore, {
            displayMenu: 'displayMenu',
        }),
        ...mapState(useNavigationStore, {
            objects: 'currentObjects',
        }),
        ...mapState(useProjectsStore, {
            projectConfiguration: (state) => {
                if (!state.selectedProject) {
                    throw new Error('Missing projectConfiguration');
                }

                return state.selectedProject;
            },
        }),
    },
    async mounted() {
        this.loading = true;
        removePinOnMap();

        this.menuStore.displayMenu = true;
        this.panelStore.setPanelHalfOpened();

        if (this.projectId) {
            if (this.dataForm && this.layerData) {
                this.item = this.getDataForm();
                this.layerAlias = this.getLayerName();

                await this.fetchAddress();

                if (!this.isOffline()) {
                    // TODO : Try to retrieve object data from server
                    // await this.fetchObjectData();
                }

                if (this.objectId !== '-') {
                    console.debug('Feature ID', this.objectId);
                } else {
                    console.debug('Feature without ID');
                }

                this.loading = false;
            } else {
                if (this.isOffline()) {
                    this.$router.push({
                        name: 'offlineProject',
                        params: { projectId: this.projectId },
                    });
                } else {
                    this.$router.push({
                        name: 'project',
                        params: { projectId: this.projectId },
                    });
                }
            }
        } else {
            this.$router.push({ name: 'projects' });
        }
    },
    methods: {
        ...mapActions(usePanelStore, {
            getHalfHeight: 'getHalfHeight',
            getMaxHeight: 'getMaxHeight',
        }),
        closeDialog(): void {
            this.softwareChoiceDialogVisible = false;
        },
        async fetchAddress(): Promise<void> {
            this.locationNameLoading = true;
            try {
                const coordinate = this.layerData?.coordinate;
                if (!coordinate) {
                    throw new Error('Coordinate is undefined');
                }

                const gps = toLonLat(coordinate);
                const position = await AddressApi.getReverseAddress(
                    gps[1].toString(),
                    gps[0].toString(),
                );
                if (!position.features || position.features.length < 1) {
                    throw new Error('Features are undefined or empty ');
                }

                const { properties } = position.features[0];
                if (!properties) {
                    throw new Error('Feature is undefined');
                }

                this.locationName = `${properties.name}, ${properties.postcode} ${properties.city}`;
            } catch {
                this.locationName = 'Aucun adresse détectée';
            } finally {
                this.locationNameLoading = false;
            }
        },
        getDataForm(): Array<{ label: string; value: unknown }> {
            if (!this.dataForm || !this.layerData) {
                throw new Error('layerData or dataForm are undefined');
            }

            const data = [];
            let layer: ProjectConfigurationLayer | undefined;

            if (
                this.isRouteFromSearched &&
                this.layerData.layerFormat ===
                    ProjectConfigurationLayerFormatEnum.PBF
            ) {
                [layer] = this.projectConfiguration.layers.filter(
                    (layer: ProjectConfigurationLayer) => {
                        return layer.name === this.layerData?.name;
                    },
                );
            } else {
                layer = getLayerFromObjectData(this.layerData);
            }

            if (!layer) {
                throw new Error("Can't find layer");
            }

            const summaryFields = layer.summaryFields;
            const labelFields = layer.labelFields as Record<string, string>;

            for (const [key, value] of Object.entries(this.dataForm)) {
                if (summaryFields.includes(key)) {
                    data.push({ label: key, value });
                }
            }
            data.forEach((item: { label: string; value: unknown }) => {
                for (const label in labelFields) {
                    if (label === item.label) {
                        item.label = label;
                    }
                }
            });

            return data;
        },
        // TODO : Refactor with coordinate.findAddress
        getLayerName(): string {
            if (!this.layerData || !this.dataForm) {
                throw new Error('layerData or dataForm are undefined');
            }
            const layer = getLayerFromObjectData(this.layerData);
            if (!layer) {
                return '';
            }

            return layer.alias as string;
        },
        goBack(): void {
            if (this.isRouteFromSearched) {
                this.$router.push({ name: 'search' });
            } else if (this.isRouteFromOfflineSearch) {
                this.$router.push({ name: 'searchOffline' });
            } else if (this.isOffline()) {
                this.$router.push({
                    name: 'objectListOffline',
                    params: { projectId: this.projectId },
                });
            } else {
                this.$router.push({
                    name: 'objectList',
                    params: { projectId: this.projectId },
                });
            }
        },
        isLayerEditable(layer: LayerDataInterface) {
            return (
                layer.layerFormat ===
                ProjectConfigurationLayerFormatEnum.GEOJSON
            );
        },
        // @duplicated ObjectList.vue
        isOffline(): boolean {
            if (this.$route.meta) {
                return Boolean(this.$route.meta.offline);
            }

            return false;
        },
        mapsSelector(): void {
            this.softwareChoiceDialogVisible = true;
            this.coordinate = this.layerData?.coordinate;
            this.formName = this.layerData?.name;
        },
        async openForm(): Promise<void> {
            try {
                this.loadingData = true;

                await redirectToForm({
                    form: this.layerData.form,
                    isOffline: this.isOffline(),
                    layerName: this.layerData?.name,
                    objectId: this.objectId,
                    projectId: this.projectId,
                    router: this.$router,
                    type: this.layerData?.type,
                });
            } catch (error: unknown) {
                console.error(error);
                alert(
                    "Une erreur vient de se produire, impossible d'ouvrir le formulaire",
                );
            } finally {
                this.loadingData = false;
            }
        },
    },
});
</script>

<style lang="scss" scoped>
@import 'ObjectData.scss';
</style>
