import HTTP from '@connect-field/client/utilities/http';

export interface UserInterface {
    token: string;
    userEmail: string;
    userId: number;
    userName: string;
}

export async function postAuthLogin(email: string, password: string): Promise<UserInterface> {
    const response = await HTTP.client().AuthApi.authControllerLogin({
        loginDto: { email, password },
    });

    if (!response.data) {
        throw new Error('No data on login');
    }

    return response.data;
}

export async function postAuthLoginLdap(username: string, password: string): Promise<UserInterface> {
    const response = await HTTP.client().AuthApi.authControllerLoginLDAP({
        loginLdapDto: { password, username },
    });

    if (!response.data) {
        throw new Error('No data on login');
    }

    return response.data;
}

export async function postAuthRegister(email: string, name: string, password: string): Promise<void> {
    await HTTP.client().AuthApi.authControllerRegister({
        registerDto: {
            email,
            name,
            password,
        },
    });
}
