<template>
    <FormWrapper
        v-if="!loading"
        ref="customFormRef"
        :images-model="images"
        :form-model="form"
        :changes-model="changes"
        :layer-name="layerName"
        :object-id="objectId"
        :project-id="projectId"
        :latitude="latitude"
        :longitude="longitude"
        @update:images-model="
            (e) => {
                images = e;
            }
        "
        @update:form-model="
            (e) => {
                form = e;
            }
        "
        @update:changes-model="
            (e) => {
                changes = e;
            }
        "
    >
        <template #menu_tabs>
            <el-row class="scroll-menu">
                <el-col :span="12">
                    <div
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'environment' }"
                        @click="selectTab('environment')"
                    >
                        <FontAwesomeIcon
                            size="xs"
                            icon="leaf"
                        />
                    </div>
                </el-col>
                <el-col :span="12">
                    <div
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'box' }"
                        @click="selectTab('box')"
                    >
                        <FontAwesomeIcon
                            size="xs"
                            icon="box"
                        />
                    </div>
                </el-col>
            </el-row>
        </template>
        <template #main_form>
            <el-row>
                <el-col
                    v-show="selectedTab === 'environment'"
                    :span="24"
                >
                    <CustomInput
                        :label="labels.code"
                        :value="form.code"
                        :disabled="true"
                        :maxlength="255"
                        @update:value="(newValue) => saveData('code', newValue)"
                    />
                    <CustomInput
                        :label="labels.input1"
                        :value="form.input1"
                        :maxlength="255"
                        @update:value="
                            (newValue) => saveData('input1', newValue)
                        "
                    />
                    <CustomDatePicker
                        :label="labels.date1"
                        :value="form.date1"
                        @update:value="
                            (newValue) => saveData('date1', newValue)
                        "
                    />
                    <CustomInputSelect
                        :options="options.select1"
                        :label="labels.select1"
                        :value="form.select1"
                        @update:value="
                            (newValue) => saveData('select1', newValue)
                        "
                    />
                    <CustomInputSelect
                        :options="options.select2"
                        :label="labels.select2"
                        :value="form.select2"
                        @update:value="
                            (newValue) => {
                                saveData('select2', newValue);
                                if (newValue === 'CONFORME') {
                                    level = 0;
                                } else {
                                    level = 1;
                                }
                            }
                        "
                    />
                    <CustomInputMultiSelect
                        v-show="level === 0"
                        :options="options.multiSelect1"
                        :label="labels.multiSelect1"
                        :value="form.multiSelect1"
                        :disabled-option-value="'CONFORME'"
                        @update:value="
                            (newValue) => saveData('multiSelect1', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :options="options.switch1"
                        :label="labels.switch1"
                        :value="form.switch1"
                        @update:value="
                            (newValue) => saveData('switch1', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.images1"
                        :label="labels.images1"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('images1', newValue)
                        "
                    />
                </el-col>
                <el-col
                    v-show="selectedTab === 'box'"
                    :span="24"
                >
                    <CustomInputSelect
                        :options="options.select3"
                        :label="labels.select3"
                        :value="form.select3"
                        @update:value="
                            (newValue) => saveData('select3', newValue)
                        "
                    />
                    <CustomInputMultiSelect
                        v-show="level === 0"
                        :options="options.multiSelect2"
                        :label="labels.multiSelect2"
                        :value="form.multiSelect2"
                        @update:value="
                            (newValue) => saveData('multiSelect2', newValue)
                        "
                    />
                    <CustomInputTextarea
                        v-show="level === 0"
                        :label="labels.inputText1"
                        :value="form.inputText1"
                        :maxlength="255"
                        @update:value="
                            (newValue) => saveData('inputText1', newValue)
                        "
                    />
                    <div v-show="level === 0">
                        <InputCamera
                            :images="images.images2"
                            :label="labels.images2"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) => saveImage('images2', newValue)
                            "
                        />
                    </div>
                </el-col>
            </el-row>
        </template>
    </FormWrapper>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import {
    labels,
    options,
} from '@connect-field/client/components/forms/demo/Formulaire_1.data';
import type { FormDataInterface } from '@connect-field/client/components/forms/form.types';
import FormTemplate from '@connect-field/client/components/forms/FormTemplate.vue';

interface DataInterface extends FormDataInterface {}

export default defineComponent({
    extends: FormTemplate,
    data(): DataInterface {
        return {
            changes: false,
            form: {},
            images: {
                images1: [],
                images2: [],
            },
            labels,
            level: 0,
            loading: true,
            mandatoryFields: [],
            options,
            selectedTab: 'environment',
            valueMissing: false,
        };
    },
});
</script>

<style lang="scss">
.el-row {
    margin: 10px;
}
</style>
