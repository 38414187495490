<template>
    <FormWrapper
        v-if="!loading"
        ref="customFormRef"
        :images-model="images"
        :form-model="form"
        :mandatory-fields="mandatoryFields"
        :changes-model="changes"
        :layer-name="layerName"
        :object-id="objectId"
        :project-id="projectId"
        :latitude="latitude"
        :longitude="longitude"
        @update:images-model="
            (e) => {
                images = e;
            }
        "
        @update:value-missing="
            (e) => {
                valueMissing = e;
            }
        "
        @update:form-model="
            (e) => {
                form = e;
            }
        "
        @update:changes-model="
            (e) => {
                changes = e;
            }
        "
    >
        <template #menu_tabs> </template>
        <template #main_form>
            <el-row>
                <el-col :span="24">
                    <CustomInput
                        :label="labels.tirfac_code"
                        :value="form.tirfac_code"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.tirfac_ptech_code"
                        :value="form.tirfac_ptech_code"
                        :maxlength="255"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.tirfac_cable_code"
                        :value="form.tirfac_cable_code"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.tirfac_cable_capa"
                        :value="form.tirfac_cable_capa"
                        :maxlength="255"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.tirfac_cable_phase"
                        :value="form.tirfac_cable_phase"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.tirfac_ebp_code"
                        :value="form.tirfac_ebp_code"
                        :maxlength="255"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.tirfac_ebp_type"
                        :value="form.tirfac_ebp_type"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.tirfac_conv_fac"
                        :value="form.tirfac_conv_fac"
                        :maxlength="255"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.tirfac_zone"
                        :value="form.tirfac_zone"
                        :disabled="true"
                    />
                    <CustomDatePicker
                        :label="labels.date_pose"
                        :value="form.date_pose"
                        :disabled="true"
                    />
                    <InputCamera
                        :label="labels.img_environnement"
                        :images="images.img_environnement"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_environnement', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.tirfac_ri"
                        :options="options.tirfac_ri"
                        :value="form.tirfac_ri"
                        @update:value="
                            (newValue) => saveData('tirfac_ri', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.tirfac_chb_prop"
                        :options="options.tirfac_chb_prop"
                        :value="form.tirfac_chb_prop"
                        @update:value="
                            (newValue) => saveData('tirfac_chb_prop', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.tirfac_chb_tmp"
                        :options="options.tirfac_chb_tmp"
                        :value="form.tirfac_chb_tmp"
                        @update:value="
                            (newValue) => saveData('tirfac_chb_tmp', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.tirfac_chb_obt"
                        :options="options.tirfac_chb_obt"
                        :value="form.tirfac_chb_obt"
                        @update:value="
                            (newValue) => saveData('tirfac_chb_obt', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.tirfac_cable_acc"
                        :options="options.tirfac_cable_acc"
                        :value="form.tirfac_cable_acc"
                        @update:value="
                            (newValue) => saveData('tirfac_cable_acc', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.tirfac_conf_ca"
                        :options="options.tirfac_conf_ca"
                        :value="form.tirfac_conf_ca"
                        @update:value="
                            (newValue) => saveData('tirfac_conf_ca', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.tirfac_cbl_glove"
                        :options="options.tirfac_cbl_glove"
                        :value="form.tirfac_cbl_glove"
                        @update:value="
                            (newValue) => saveData('tirfac_cbl_glove', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_cable"
                        :images="images.img_cable"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('img_cable', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.tirfac_etq_pot"
                        :options="options.tirfac_etq_pot"
                        :value="form.tirfac_etq_pot"
                        @update:value="
                            (newValue) => saveData('tirfac_etq_pot', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.tirfac_etq_cable"
                        :options="options.tirfac_etq_cable"
                        :value="form.tirfac_etq_cable"
                        @update:value="
                            (newValue) => saveData('tirfac_etq_cable', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_etiquette"
                        :images="images.img_etiquette"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('img_etiquette', newValue)
                        "
                    />
                    <CustomInputTextarea
                        :label="labels.tirfac_com"
                        :value="form.tirfac_com"
                        :maxlength="255"
                        @update:value="
                            (newValue) => saveData('tirfac_com', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.tirfac_type_audit"
                        :options="options.tirfac_type_audit"
                        :value="form.tirfac_type_audit"
                        @update:value="
                            (newValue) =>
                                saveData('tirfac_type_audit', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.tirfac_statut_audit + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.tirfac_statut_audit"
                        :value="form.tirfac_statut_audit"
                        @update:value="
                            (newValue) =>
                                saveData('tirfac_statut_audit', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.tirfac_gene + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.tirfac_gene"
                        :value="form.tirfac_gene"
                        @update:value="
                            (newValue) => saveData('tirfac_gene', newValue)
                        "
                    />
                </el-col>
            </el-row>
        </template>
    </FormWrapper>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import {
    labels,
    options,
} from '@connect-field/client/components/forms/AIC/Travaux/t_tvx_facade.data';
import type { FormDataInterface } from '@connect-field/client/components/forms/form.types';
import FormTemplate from '@connect-field/client/components/forms/FormTemplate.vue';

interface DataInterface extends FormDataInterface {}

export default defineComponent({
    extends: FormTemplate,
    data(): DataInterface {
        return {
            changes: false,
            form: {},
            images: {
                img_environnement: [],
                img_cable: [],
                img_etiquette: [],
            },
            labels,
            loading: true,
            mandatoryFields: ['tirfac_statut_audit', 'tirfac_gene'],
            options,

            valueMissing: false,
        };
    },
    methods: {},
});
</script>
