/*
Store to save layers
 */

import { acceptHMRUpdate, defineStore } from 'pinia';
import type Layer from 'ol/layer/Layer';

import type {
    LayerStyleSpecification,
    ProjectConfigurationLayerFormatEnum,
    ProjectConfigurationLayerTypeEnum,
} from '@connect-field/client/sdk/generated';

export interface LayerProperties {
    alias: string;
    category: string | null;
    enableCreation: boolean;
    enableEdition: boolean;
    form: string | null;
    format: ProjectConfigurationLayerFormatEnum;
    global: boolean;
    hiddenLegend: boolean;
    id: number | string;
    idField?: string;
    isBackgroundLayer?: boolean;
    name: string;
    online: boolean;
    parentLayerId?: number;
    sourceLayer?: string;
    style?: Array<LayerStyleSpecification>;
    table: string;
    type: ProjectConfigurationLayerTypeEnum;
}

export interface StateInterface {
    layers: Array<Layer>;
    layerVisibility: {};
    selection: {};
}

const useLayersStore = defineStore('layers', {
    actions: {
        add(layer: Layer): void {
            this.layers.push(layer);
        },
    },
    getters: {
        backgroundLayers(state) {
            return (state.layers as Array<Layer>).filter(
                (layer: Layer) => layer.getProperties().isBackgroundLayer && layer.getProperties().name,
            );
        },
    },
    state: (): StateInterface => {
        return {
            layers: [],
            layerVisibility: {},
            selection: {},
        };
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useLayersStore, import.meta.hot));
}

export default useLayersStore;
