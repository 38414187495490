import type { OptionsInterface, SelectInterface } from '@connect-field/client/components/forms/form.types';

export const labels: Record<string, string> = {
    boite_cable: 'Entrée de câble',
    boite_etat: 'État : MOC',
    boite_etat_1: 'État du boîtier',
    boite_etq: 'Étiquette du boîtier',
    boite_fix: 'Fixation du boîtier',
    boite_fonc: 'Type',
    boite_gest: 'Gestionnaire / Propriétaire',
    boite_gest_pt: 'Type Gestionnaire PT',
    boite_gps: 'Coordonnées GPS',
    boite_info: 'Information boîtier SIG',
    boite_ptech: 'Point Technique',
    boite_recet: 'Recette du boîtier réalisable',
    boite_ref: 'Reference',
    boite_remarque: 'Remarque supplémentaire',
    boite_struc: 'Type Structure PT',
    boite_support: 'Support',
    code_boite: 'Nom de la boîte',
    date_pose: 'Date de pose',
    img_entree: 'Photo Entrée de câble',
    img_environnement: 'Photo Environnement',
    img_etat: 'Photo État du boîtier',
    img_etiquette: 'Photo Étiquette de boîte',
    img_fixation: 'Photo Fixation du boîtier',
    tirboit_statut_audit: "Statut de l'audit",
    tirboit_type_audit: "Type de l'audit",
};

export const options: Record<string, OptionsInterface | SelectInterface> = {
    boite_cable: [
        { label: 'CONFORME', value: 'CONFORME' },
        { label: 'ECAM mal/non serrés', value: 'ECAM mal/non serrés' },
        { label: 'Entrées de câble mal/non réalisées', value: 'Entrées de câble mal/non réalisées' },
        {
            label: 'Entrées de câble boitier aérien mal/non réalisées',
            value: 'Entrées de câble boitier aérien mal/non réalisées',
        },
        { label: 'Câble entrant mal positionné', value: 'Câble entrant mal positionné' },
    ],
    boite_etat_1: [
        { label: 'CONFORME', value: 'CONFORME' },
        { label: 'Boîtier non posé', value: 'Boîtier non posé' },
        { label: 'Boîtier détérioré', value: 'Boîtier détérioré' },
        { label: 'Système de fermeture du boîtier HS', value: 'Système de fermeture du boîtier HS' },
        { label: "Boitier non adapté à l'infra", value: "Boitier non adapté à l'infra" },
        { label: 'Boitier non adapté au raccordement', value: 'Boitier non adapté au raccordement' },
        { label: 'Boîtier non étanche', value: 'Boîtier non étanche' },
    ],
    boite_etq: [
        { label: 'CONFORME', value: 'CONFORME' },
        { label: 'Étiquette boîtier absente/détériorée', value: 'Étiquette boîtier absente/détériorée' },
        {
            label: 'Étiquette boîtier incohérente entre terrain/SIG',
            value: 'Étiquette boîtier incohérente entre terrain/SIG',
        },
        {
            label: 'Étiquette boîtier incomplète (4 derniers digits CONFORME)',
            value: 'Étiquette boîtier incomplète (4 derniers digits CONFORME)',
        },
        { label: "Type d'étiquette boîtier non conforme", value: "Type d'étiquette boîtier non conforme" },
        {
            label: 'Etiquette boîtier temporaire posée (pose QR)',
            value: 'Etiquette boîtier temporaire posée (pose QR)',
        },
    ],
    boite_fix: [
        { label: 'CONFORME', value: 'CONFORME' },
        { label: 'Boîtier mal/non fixé en souterrain', value: 'Boîtier mal/non fixé en souterrain' },
        { label: 'Boîtier mal/non fixé en aérien', value: 'Boîtier mal/non fixé en aérien' },
        { label: "Boîtier aérien n'est pas entre 2m et 2m50", value: "Boîtier aérien n'est pas entre 2m et 2m50" },
        { label: "Boîtier aérien n'est pas entre 2m et 4m", value: "Boîtier aérien n'est pas entre 2m et 4m" },
    ],
    boite_gest_pt: [
        { label: 'CONFORME', value: 'CONFORME' },
        {
            label: 'Propriétaire et/ou gestionnaire du point technique erroné(s)',
            value: 'Propriétaire et/ou gestionnaire du point technique erroné(s)',
        },
    ],
    boite_gps: [
        { label: 'CONFORME', value: 'CONFORME' },
        { label: 'Mauvaise position GPS', value: 'Mauvaise position GPS' },
    ],
    boite_info: [
        { label: 'CONFORME', value: 'CONFORME' },
        {
            label: 'Type fonctionnel (PBO/ BET/ etc…) du boîtier erroné sur SIG',
            value: 'Type fonctionnel (PBO/ BET/ etc…) du boîtier erroné sur SIG',
        },
        {
            label: 'Référence (OFMC/ 3M T0/ etc…) du boîtier erronée sur SIG',
            value: 'Référence (OFMC/ 3M T0/ etc…) du boîtier erronée sur SIG',
        },
    ],
    boite_recet: [
        { label: 'CONFORME', value: 'CONFORME' },
        { label: "Demande d'accès nécessaire", value: "Demande d'accès nécessaire" },
        { label: 'Arrêté de circulation nécessaire', value: 'Arrêté de circulation nécessaire' },
        { label: "Nécessité d'être deux/nacelle", value: "Nécessité d'être deux/nacelle" },
        { label: 'Véhicule stationné sur la chambre', value: 'Véhicule stationné sur la chambre' },
        { label: 'Point technique non trouvé', value: 'Point technique non trouvé' },
        {
            label: 'Chambre sous enrobée sur chaussée (chambre Tierce)',
            value: 'Chambre sous enrobé sur chaussée (chambre Tierce)',
        },
        {
            label: 'Chambre sous enrobée sur trottoir (chambre Tierce)',
            value: 'Chambre sous enrobé sur trottoir (chambre Tierce)',
        },
        {
            label: 'Chambre Collectivité non exploitable (sous enrobée/ fourreaux écrasées...)',
            value: 'Chambre Collectivité non exploitable (sous enrobée/ fourreaux écrasées...)',
        },
        { label: 'Audit non réalisable - validé par photo sst', value: 'Audit non réalisable - validé par photo sst' },
        { label: 'Audit non réalisable - validé sans contrôle', value: 'Audit non réalisable - validé sans contrôle' },
    ],
    boite_struc: [
        { label: 'CONFORME', value: 'CONFORME' },
        { label: 'CHAMBRE sur le terrain', value: 'CHAMBRE sur le terrain' },
        { label: 'APPUI AERIEN sur le terrain', value: 'APPUI AERIEN sur le terrain' },
        { label: 'ANCRAGE FACADE sur le terrain', value: 'ANCRAGE FACADE sur le terrain' },
    ],
    tirboit_statut_audit: [
        { label: 'NON RÉALISÉ', value: 'NON REALISE' },
        { label: 'PARTIELLEMENT', value: 'PARTIELLEMENT' },
        { label: 'TERMINÉ AVEC RESERVE', value: 'TERMINE AVEC RESERVE' },
        { label: 'TERMINÉ SANS RESERVE', value: 'TERMINE SANS RESERVE' },
    ],
    tirboit_type_audit: { false: 'RECETTE', true: 'CONTROLE' },
};
