import { createRouter, createWebHashHistory, type RouteRecordRaw } from 'vue-router';

import { generateFormRoutes } from '@connect-field/client/services/formRouter.service';
import useAuthStore from '@connect-field/client/stores/auth';

import Basemap from '@connect-field/client/pages/home/basemap/BasemapView.vue';
import Login from '@connect-field/client/pages/auth/LoginPage.vue';
import Logout from '@connect-field/client/pages/auth/LogoutPage.vue';
import MainVue from '@connect-field/client/pages/home/MainView.vue';
import NotFound from '@connect-field/client/pages/NotFoundPage.vue';
import ObjectList from '@connect-field/client/pages/home/objects/ObjectList.vue';
import ObjectVue from '@connect-field/client/components/object/ObjectData.vue';
import OfflineProjectDetail from '@connect-field/client/pages/home/project/OfflineProjectDetail.vue';
import ProjectDetail from '@connect-field/client/pages/home/project/ProjectDetail.vue';
import ProjectDownload from '@connect-field/client/pages/home/project/ProjectDownload.vue';
import ProjectLayers from '@connect-field/client/pages/home/project/ProjectLayers.vue';
import ProjectList from '@connect-field/client/pages/home/projects/ProjectList.vue';
import SearchVue from '@connect-field/client/pages/home/search/SearchView.vue';
import SettingsVue from '@connect-field/client/pages/home/settings/SettingsView.vue';
import useNavigationStore from '@connect-field/client/stores/navigation';

const formRoutes = generateFormRoutes();

export const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: { name: 'projects' },
    },
    {
        path: '/home',
        redirect: { name: 'projects' },
    },
    {
        component: Login,
        meta: { requiresUnauth: true },
        path: '/auth',
    },
    {
        component: Logout,
        path: '/logout',
        props: false,
    },
    {
        component: Logout,
        path: '/logout/:code',
        props: true,
    },
    {
        component: MainVue,
        meta: { requiresAuth: true },
        path: '/home',
        // eslint-disable-next-line vue/sort-keys
        children: [
            {
                component: Basemap,
                name: 'backgroundMap',
                path: 'backgroundMap',
            },
            {
                component: SettingsVue,
                name: 'settings',
                path: 'settings',
            },
            {
                component: ProjectList,
                name: 'projects',
                path: 'projects',
            },
            {
                component: ProjectDetail,
                path: 'projects/:projectId',
                props: true,
                // eslint-disable-next-line vue/sort-keys
                children: [
                    {
                        component: ProjectLayers,
                        name: 'project',
                        path: '',
                        props: true,
                    },
                    {
                        component: ObjectList,
                        name: 'objectList',
                        path: 'objects',
                        props: true,
                    },
                    {
                        component: ObjectVue,
                        name: 'objectData',
                        path: 'layer/:layerName/object/:objectId',
                        props: true,
                    },
                    {
                        component: SearchVue,
                        name: 'search',
                        path: 'search',
                        props: true,
                    },
                    {
                        component: ProjectDownload,
                        name: 'projectDownload',
                        path: 'download',
                        props: true,
                    },
                    ...formRoutes.online,
                ],
            },
            {
                component: OfflineProjectDetail,
                meta: { offline: true },
                path: 'offline/projects/:projectId',
                props: true,
                // eslint-disable-next-line vue/sort-keys
                children: [
                    {
                        component: ProjectLayers,
                        meta: { offline: true },
                        name: 'offlineProject',
                        path: '',
                        props: true,
                    },
                    {
                        component: ObjectList,
                        meta: { offline: true },
                        name: 'objectListOffline',
                        path: 'objects',
                        props: true,
                    },
                    {
                        component: ObjectVue,
                        meta: { offline: true },
                        name: 'objectDataOffline',
                        path: 'layer/:layerName/object/:objectId',
                        props: true,
                    },
                    {
                        component: SearchVue,
                        meta: { offline: true },
                        name: 'searchOffline',
                        path: 'search',
                        props: true,
                    },
                    ...formRoutes.offline,
                ],
            },
        ],
    },
    {
        component: NotFound,
        path: '/:notFound(.*)',
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    const authStore = useAuthStore();
    const navigationStore = useNavigationStore();
    const isOnline = navigator.onLine;

    navigationStore.setCurrentRoute({
        meta: to.meta,
        name: to.name,
        params: to.params,
        path: to.path,
    });
    navigationStore.setPreviousRoute({
        meta: from.meta,
        name: from.name,
        params: from.params,
        path: from.path,
    });

    if (to.meta.requiresAuth && !authStore.loggedIn && isOnline) {
        next('/auth');
    } else if (to.meta.requiresUnauth && authStore.loggedIn && isOnline) {
        next('/home/projects');
    } else {
        next();
    }
});

export default router;
