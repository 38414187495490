import { Circle, Fill, RegularShape, Stroke, Style, Text } from 'ol/style';

export const polygonStyle = new Style({
    fill: new Fill({
        color: 'rgba(255, 113, 166, 0.3)',
    }),
    stroke: new Stroke({
        color: '#FF71A6',
        width: 4,
    }),
});

export const verticePolygonStyle = new Style({
    image: new Circle({
        fill: new Fill({
            color: '#FF71A6',
        }),
        radius: 5,
    }),
});

export const labelStyle = new Style({
    image: new RegularShape({
        angle: Math.PI,
        displacement: [0, 10],
        fill: new Fill({
            color: 'rgba(0, 0, 0, 0.7)',
        }),
        points: 3,
        radius: 8,
    }),
    text: new Text({
        backgroundFill: new Fill({
            color: 'rgba(0, 0, 0, 0.7)',
        }),
        fill: new Fill({
            color: 'rgba(255, 255, 255, 1)',
        }),
        font: '14px Calibri,sans-serif',
        offsetY: -15,
        padding: [3, 3, 3, 3],
        textBaseline: 'bottom',
    }),
});

export const tipStyle = new Style({
    text: new Text({
        backgroundFill: new Fill({
            color: 'rgba(0, 0, 0, 0.4)',
        }),
        fill: new Fill({
            color: 'rgba(255, 255, 255, 1)',
        }),
        font: '12px Calibri,sans-serif',
        offsetX: 15,
        padding: [2, 2, 2, 2],
        textAlign: 'left',
    }),
});

export const modifyStyle = new Style({
    image: new Circle({
        fill: new Fill({
            color: 'rgba(0, 0, 0, 0.4)',
        }),
        radius: 5,
        stroke: new Stroke({
            color: 'rgba(0, 0, 0, 0.7)',
        }),
    }),
    text: new Text({
        backgroundFill: new Fill({
            color: 'rgba(0, 0, 0, 0.7)',
        }),
        fill: new Fill({
            color: 'rgba(255, 255, 255, 1)',
        }),
        font: '12px Calibri,sans-serif',
        offsetX: 15,
        padding: [2, 2, 2, 2],
        text: 'Drag to modify',
        textAlign: 'left',
    }),
});

export const segmentStyle = new Style({
    image: new RegularShape({
        angle: Math.PI,
        displacement: [0, 8],
        fill: new Fill({
            color: 'rgba(0, 0, 0, 0.4)',
        }),
        points: 3,
        radius: 6,
    }),
    text: new Text({
        backgroundFill: new Fill({
            color: 'rgba(0, 0, 0, 0.4)',
        }),
        fill: new Fill({
            color: 'rgba(255, 255, 255, 1)',
        }),
        font: '12px Calibri,sans-serif',
        offsetY: -12,
        padding: [2, 2, 2, 2],
        textBaseline: 'bottom',
    }),
});

export const segmentStyles = [segmentStyle];
