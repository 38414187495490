import { bbox } from 'ol/loadingstrategy';
import { createXYZ } from 'ol/tilegrid';
import type { Extent } from 'ol/extent';
import type { Feature } from 'ol';
import GeoJSON from 'ol/format/GeoJSON';
import type { Geometry } from 'ol/geom';
import MVT from 'ol/format/MVT';
import type { Projection } from 'ol/proj';
import { pick as radashPick } from 'radash';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';

import {
    type ProjectConfigurationLayer,
    ProjectConfigurationLayerFormatEnum,
} from '@connect-field/client/sdk/generated';
import { applyStyle } from '@connect-field/client/services/styles.service';
import { getGeoJSON } from '@connect-field/client/services/api/layer';
import type { LayerProperties } from '@connect-field/client/stores/layers';
import useAuthStore from '@connect-field/client/stores/auth';

function generatePBFLayer(projectConfigurationLayer: ProjectConfigurationLayer): VectorTileLayer {
    const authStore = useAuthStore();

    const source = new VectorTileSource({
        format: new MVT({
            idProperty: 'sid',
        }),
        maxZoom: 30,
        minZoom: 0,
        tileGrid: createXYZ({ maxZoom: 14, tileSize: [512, 512] }),
        url: projectConfigurationLayer.url + `?accessToken=${authStore.token}`,
        wrapX: false,
    });

    const properties: LayerProperties = {
        ...radashPick(projectConfigurationLayer, [
            'alias',
            'category',
            'enableCreation',
            'enableEdition',
            'form',
            'format',
            'hiddenLegend',
            'id',
            'idField',
            'name',
            'sourceLayer',
            'style',
            'table',
            'type',
        ]),
        global: false,
        online: true,
    };

    const layer = new VectorTileLayer({
        className: `layer-pbf-${projectConfigurationLayer.name}`,
        declutter: true,
        maxZoom: projectConfigurationLayer.maxZoom,
        minZoom: projectConfigurationLayer.minZoom,
        preload: 10,
        properties,
        renderMode: 'hybrid',
        source,
        visible: !projectConfigurationLayer.hiddenByDefault,
    });

    applyStyle(layer);

    layer.on('error', (e) => console.error(`[layer:pbf:${projectConfigurationLayer.name}]`, e));
    layer.setZIndex(projectConfigurationLayer.zIndex);

    return layer;
}

function generateGeoJSONLayer(projectConfigurationLayer: ProjectConfigurationLayer): VectorLayer<VectorSource> {
    const source = new VectorSource({
        format: new GeoJSON(),
        loader: async (
            bbox: Extent,
            resolution: number,
            projection: Projection,
            success?: (args: Array<Feature<Geometry>>) => void,
            failure?: () => void,
        ): Promise<void> => {
            try {
                const proj = projection.getCode();
                const data = await getGeoJSON(projectConfigurationLayer.id, bbox, proj);
                const features = source?.getFormat()?.readFeatures(data) as Array<Feature>;
                if (!features) {
                    return;
                }

                source.addFeatures(features);
                if (!success) {
                    return;
                }

                success(features);
            } catch {
                source.removeLoadedExtent(bbox);
                if (failure) {
                    failure();
                }
            }
        },
        strategy: bbox,
    });

    const properties: LayerProperties = {
        ...radashPick(projectConfigurationLayer, [
            'alias',
            'category',
            'enableCreation',
            'enableEdition',
            'form',
            'format',
            'hiddenLegend',
            'id',
            'idField',
            'name',
            'parentLayerId',
            'style',
            'table',
            'type',
        ]),
        global: false,
        online: true,
    };

    const layer = new VectorLayer({
        className: `layer-geojson-${projectConfigurationLayer.name}`,
        declutter: false,
        maxZoom: projectConfigurationLayer.maxZoom,
        minZoom: projectConfigurationLayer.minZoom,
        properties,
        source,
        visible: !projectConfigurationLayer.hiddenByDefault,
    });

    applyStyle(layer);

    layer.on('error', (e) => console.error(`[layer:geojson:${projectConfigurationLayer.name}]`, e));
    layer.setZIndex(projectConfigurationLayer.zIndex);

    return layer;
}

export function generateLayer(layer: ProjectConfigurationLayer): VectorTileLayer | VectorLayer<VectorSource> {
    if (layer.format === ProjectConfigurationLayerFormatEnum.PBF) {
        return generatePBFLayer(layer);
    } else if (layer.format === ProjectConfigurationLayerFormatEnum.GEOJSON) {
        return generateGeoJSONLayer(layer);
    } else {
        throw new Error(`type of layer is unknown : ${layer.type}`);
    }
}
