export const labels = {
    azimut_ant: "Azimut de l'antenne par rapport au nord géographique (en degrès)",
    comentaires_ant: 'Commentaires',
    date_ant: 'Date de mise en service',
    frequence_ant: 'Fréquence',
    hauteur_ant: "Hauteur au dessus du sol de l'axe médian (en mètre)",
    proprietaire_ant: 'Propriétaire',
    techno_ant: 'Technologie',
};

export const options = {};
