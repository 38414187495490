export const labels = {
    adresse_chambre: 'Adresse de la chambre',
    code: 'Code de la Chambre',
    commentaire: 'Commentaire',
    date_construction: 'Date de Construction de la chambre',
    date_visite: 'Date de  Visite',
    degre_intervention: 'Degré Intervention',
    etat_cadre: 'Etat Cadre',
    etat_chambre: 'Etat Chambre',
    etat_fourreaux: 'Etat Fourreaux',
    etat_masque: 'Etat Masque',
    etat_tampon: 'Etat Tampon',
    etiquetage_cables: 'Etat des étiquettes des cables',
    etiquetage_fourreaux: 'Etiquetage Fourreaux',
    fixation_BPE: 'Etat des fixations du Boitier',
    gestionnaire: 'Gestionnaire',
    img_BPE: 'Photo - Etat BPE',
    img_cables: 'Photo - Cables',
    img_env_ext: 'Photo - Environnement extérieur chambre',
    img_etat_cadre: 'Photo - Etat Cadre',
    img_etat_chambre: 'Photo - Etat Chambre',
    img_etat_fourreaux: 'Photo - Etat Fourreaux',
    img_etat_masque: 'Photo - Etat Masque',
    img_etat_tampon: 'Photo - Etat Tampon',
    img_etiquetage_fourreaux: 'Photo - Etiquetage Fourreaux',
    img_nb_alv_a: 'Photo - Nombre d’alveoles (Face A)',
    img_nb_alv_b: 'Photo - Nombre d’alveoles (Face B)',
    img_nb_alv_c: 'Photo - Nombre d’alveoles (Face C)',
    img_nb_alv_d: 'Photo - Nombre d’alveoles (Face D)',
    img_occupation_fourreaux: 'Photo - Occupation des Fourreaux',
    nb_alv_a: 'Nombre d’alveoles (Face A)',
    nb_alv_b: 'Nombre d’alveoles (Face B)',
    nb_alv_c: 'Nombre d’alveoles (Face C)',
    nb_alv_d: 'Nombre d’alveoles (Face D)',
    nb_alv_ocp_a: 'Nombre d’alveoles occupées (Face A)',
    nb_alv_ocp_b: 'Nombre d’alveoles occupées (Face B)',
    nb_alv_ocp_c: 'Nombre d’alveoles occupées (Face C)',
    nb_alv_ocp_d: 'Nombre d’alveoles occupées (Face D)',
    nb_BPE: 'Nombre de Boitiers Implantés',
    nb_cables: 'Nombre de Cables Optiques',
    occupation_fourreaux: 'Occupation des Fourreaux',
    presence_grille: 'Présence de la grille de protection',
    propriete: 'Propriétaire',
    statut_visite: 'Statut Visite',
    type_chambre: 'Type de Chambre',
};

export const options = {
    degre_intervention: [
        { label: 'Aucun', value: 'Aucun' },
        { label: 'Immédiat', value: 'Immédiat' },
        { label: '-1sem', value: '-1sem' },
        { label: '-1mois', value: '-1mois' },
        { label: '-6mois', value: '-6mois' },
    ],
    etat_cadre: [
        { label: 'RAS', value: 'RAS' },
        { label: 'Défaut Maconnerie', value: 'Défaut Maconnerie' },
    ],
    etat_chambre: [
        { label: 'RAS', value: 'RAS' },
        { label: 'Inondée', value: 'Inondée' },
        { label: 'Déchet', value: 'Déchet' },
        { label: 'Boue', value: 'Boue' },
    ],
    etat_fourreaux: [
        { label: 'RAS', value: 'RAS' },
        { label: 'Bouché', value: 'Bouché' },
        { label: 'Ecrasé', value: 'Ecrasé' },
        { label: 'Absence bouchon', value: 'Absence bouchon' },
    ],
    etat_masque: [
        { label: 'RAS', value: 'RAS' },
        { label: 'Défaut Maconnerie', value: 'Défaut Maconnerie' },
        { label: 'Défaut Percussion', value: 'Défaut Percussion' },
    ],
    etat_tampon: [
        { label: 'RAS', value: 'RAS' },
        { label: 'Cassé', value: 'Cassé' },
        { label: 'Fendu', value: 'Fendu' },
        { label: 'Voilé', value: 'Voilé' },
        { label: 'Inexistant', value: 'Inexistant' },
    ],
    etiquetage_fourreaux: [
        { label: 'RAS', value: 'RAS' },
        { label: 'Incohérent', value: 'Incohérent' },
        { label: 'Defaut couleur', value: 'Defaut couleur' },
        { label: 'Absent', value: 'Absent' },
    ],
    occupation_fourreaux: [
        { label: 'Free', value: 'Free' },
        { label: 'Orange', value: 'Orange' },
        { label: 'AI', value: 'AI' },
        { label: 'Bouygues', value: 'Bouygues' },
        { label: 'SFR', value: 'SFR' },
    ],
    presence_grille: [
        { label: 'RAS', value: 'RAS' },
        { label: 'Inexistante', value: 'Inexistante' },
        { label: 'Enfoncée', value: 'Enfoncée' },
        { label: 'Cassée', value: 'Cassée' },
    ],
    statut_visite: [
        { label: 'Non effectuée', value: 'Non effectuée' },
        { label: 'Partielle', value: 'Partielle' },
        { label: 'RAS', value: 'RAS' },
        { label: 'Avec Réserve', value: 'Avec Réserve' },
    ],
};
