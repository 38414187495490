import type { OptionsInterface } from '@connect-field/client/components/forms/form.types';

export const labels = {
    boitierId: 'Id du boitier',
    cableId: 'Id du câble',
    cassetteId: 'Id de la cassette',
    connectorisation: 'Connectorisation',
    idBoiteAmont: 'Id de la boite amont',
    idBoiteAval: 'Id de la boite aval',
    modulo: 'Modulo',
    position: 'Position amont/aval',
    taille: 'Taille',
};
export const options: Record<string, OptionsInterface> = {
    connectorisation: [
        { label: 'AT', value: 'AT' },
        { label: 'EP', value: 'EP' },
        { label: 'PA', value: 'PA' },
    ],
    modulo: [
        { label: '6', value: '6' },
        { label: '12', value: '12' },
    ],
    position: [
        { label: 'Amont', value: 'amont' },
        { label: 'Aval', value: 'aval' },
    ],
    taille: [
        { label: '6FO', value: '6FO' },
        { label: '12FO', value: '12FO' },
        { label: '24FO', value: '24FO' },
        { label: '36FO', value: '36FO' },
        { label: '48FO', value: '48FO' },
        { label: '72FO', value: '72FO' },
        { label: '96FO', value: '96FO' },
        { label: '144FO', value: '144FO' },
        { label: '288FO', value: '288FO' },
        { label: '432FO', value: '432FO' },
        { label: '576FO', value: '576FO' },
        { label: '720FO', value: '720FO' },
    ],
};
