<template>
    <FormWrapper
        v-if="!loading"
        ref="customFormRef"
        :images-model="images"
        :form-model="form"
        :mandatory-fields="mandatoryFields"
        :changes-model="changes"
        :layer-name="layerName"
        :object-id="objectId"
        :project-id="projectId"
        :latitude="latitude"
        :longitude="longitude"
        @update:images-model="
            (e) => {
                images = e;
            }
        "
        @update:value-missing="
            (e) => {
                valueMissing = e;
            }
        "
        @update:form-model="
            (e) => {
                form = e;
            }
        "
        @update:changes-model="
            (e) => {
                changes = e;
            }
        "
    >
        <template #menu_tabs> </template>
        <template #main_form>
            <el-row>
                <el-col :span="24">
                    <CustomInput
                        :label="labels.cb_code + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.cb_code"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('cb_code', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.real_audit"
                        :options="options.real_audit"
                        :value="form.real_audit"
                        @update:value="
                            (newValue) => saveData('real_audit', newValue)
                        "
                    />

                    <div v-show="condition_img_cable()">
                        <InputCamera
                            :label="labels.img_cable"
                            :images="images.img_cable"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) => saveImage('img_cable', newValue)
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="condition_cb_codeext()"
                        :label="labels.cb_codeext + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.cb_codeext"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('cb_codeext', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_cb_etiquet()"
                        :label="labels.cb_etiquet + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.cb_etiquet"
                        @update:value="
                            (newValue) => saveData('cb_etiquet', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_cb_nd1()"
                        :label="labels.cb_nd1 + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.cb_nd1"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('cb_nd1', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_cb_nd2()"
                        :label="labels.cb_nd2 + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.cb_nd2"
                        @update:value="
                            (newValue) => saveData('cb_nd2', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_cb_r1_code()"
                        :label="labels.cb_r1_code + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.cb_r1_code"
                        :maxlength="100"
                        @update:value="
                            (newValue) => saveData('cb_r1_code', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_cb_r2_code()"
                        :label="labels.cb_r2_code + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.cb_r2_code"
                        @update:value="
                            (newValue) => saveData('cb_r2_code', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_cb_r3_code()"
                        :label="labels.cb_r3_code + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.cb_r3_code"
                        :maxlength="100"
                        @update:value="
                            (newValue) => saveData('cb_r3_code', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_cb_r4_code()"
                        :label="labels.cb_r4_code + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.cb_r4_code"
                        @update:value="
                            (newValue) => saveData('cb_r4_code', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_cb_prop()"
                        :label="labels.cb_prop + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.cb_prop"
                        :maxlength="20"
                        @update:value="
                            (newValue) => saveData('cb_prop', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_cb_gest()"
                        :label="labels.cb_gest + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.cb_gest"
                        @update:value="
                            (newValue) => saveData('cb_gest', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_cb_user()"
                        :label="labels.cb_user + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.cb_user"
                        :maxlength="20"
                        @update:value="
                            (newValue) => saveData('cb_user', newValue)
                        "
                    />
                    <CustomInputSelect
                        v-show="condition_cb_proptyp()"
                        :label="labels.cb_proptyp"
                        :options="options.cb_proptyp"
                        :value="form.cb_proptyp"
                        @update:value="
                            (newValue) => saveData('cb_proptyp', newValue)
                        "
                    />
                    <CustomInputSelect
                        v-show="condition_cb_statut()"
                        :label="labels.cb_statut"
                        :options="options.cb_statut"
                        :value="form.cb_statut"
                        @update:value="
                            (newValue) => saveData('cb_statut', newValue)
                        "
                    />
                    <CustomInputSelect
                        v-show="condition_cb_etat()"
                        :label="labels.cb_etat + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.cb_etat"
                        :value="form.cb_etat"
                        @update:value="
                            (newValue) => saveData('cb_etat', newValue)
                        "
                    />
                    <CustomDatePicker
                        v-show="condition_cb_dateins()"
                        :label="labels.cb_dateins + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.cb_dateins"
                        @update:value="
                            (newValue) => saveData('cb_dateins', newValue)
                        "
                    />
                    <CustomDatePicker
                        v-show="condition_cb_datemes()"
                        :label="labels.cb_datemes + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.cb_datemes"
                        @update:value="
                            (newValue) => saveData('cb_datemes', newValue)
                        "
                    />
                    <CustomInputSelect
                        v-show="condition_cb_avct()"
                        :label="labels.cb_avct + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.cb_avct"
                        :value="form.cb_avct"
                        @update:value="
                            (newValue) => saveData('cb_avct', newValue)
                        "
                    />
                    <CustomInputSelect
                        v-show="condition_cb_tech()"
                        :label="labels.cb_tech + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.cb_tech"
                        :value="form.cb_tech"
                        @update:value="
                            (newValue) => saveData('cb_tech', newValue)
                        "
                    />
                    <CustomInputSelect
                        v-show="condition_cb_typephy()"
                        :label="labels.cb_typephy + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.cb_typephy"
                        :value="form.cb_typephy"
                        @update:value="
                            (newValue) => saveData('cb_typephy', newValue)
                        "
                    />
                    <CustomInputSelect
                        v-show="condition_cb_typelog()"
                        :label="labels.cb_typelog + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.cb_typelog"
                        :value="form.cb_typelog"
                        @update:value="
                            (newValue) => saveData('cb_typelog', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_cb_rf_code()"
                        :label="labels.cb_rf_code + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.cb_rf_code"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('cb_rf_code', newValue)
                        "
                    />
                    <InputNumber
                        v-show="condition_cb_capafo()"
                        :label="labels.cb_capafo + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.cb_capafo"
                        @update:value="
                            (newValue) => saveData('cb_capafo', newValue)
                        "
                    />
                    <InputNumber
                        v-show="condition_cb_fo_disp()"
                        :label="labels.cb_fo_disp + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.cb_fo_disp"
                        @update:value="
                            (newValue) => saveData('cb_fo_disp', newValue)
                        "
                    />
                    <InputNumber
                        v-show="condition_cb_fo_util()"
                        :label="labels.cb_fo_util + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.cb_fo_util"
                        @update:value="
                            (newValue) => saveData('cb_fo_util', newValue)
                        "
                    />
                    <InputNumber
                        v-show="condition_cb_modulo()"
                        :label="labels.cb_modulo + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.cb_modulo"
                        @update:value="
                            (newValue) => saveData('cb_modulo', newValue)
                        "
                    />
                    <InputNumber
                        v-show="condition_cb_diam()"
                        :label="labels.cb_diam + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.cb_diam"
                        @update:value="
                            (newValue) => saveData('cb_diam', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_cb_color()"
                        :label="labels.cb_color + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.cb_color"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('cb_color', newValue)
                        "
                    />
                    <InputNumber
                        v-show="condition_cb_lgreel()"
                        :label="labels.cb_lgreel + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.cb_lgreel"
                        @update:value="
                            (newValue) => saveData('cb_lgreel', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_cb_localis()"
                        :label="labels.cb_localis + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.cb_localis"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('cb_localis', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_cb_comment()"
                        :label="labels.cb_comment + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.cb_comment"
                        @update:value="
                            (newValue) => saveData('cb_comment', newValue)
                        "
                    />
                    <CustomDatePicker
                        v-show="condition_cb_creadat()"
                        :label="labels.cb_creadat + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.cb_creadat"
                        :disabled="true"
                    />
                    <CustomDatePicker
                        v-show="condition_cb_majdate()"
                        :label="labels.cb_majdate + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.cb_majdate"
                        :disabled="true"
                    />
                    <CustomInput
                        v-show="condition_cb_majsrc()"
                        :label="labels.cb_majsrc + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.cb_majsrc"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('cb_majsrc', newValue)
                        "
                    />
                    <CustomDatePicker
                        v-show="condition_cb_abddate()"
                        :label="labels.cb_abddate + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.cb_abddate"
                        @update:value="
                            (newValue) => saveData('cb_abddate', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_cb_abdsrc()"
                        :label="labels.cb_abdsrc + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.cb_abdsrc"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('cb_abdsrc', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_commentaire()"
                        :label="labels.commentaire"
                        :value="form.commentaire"
                        @update:value="
                            (newValue) => saveData('commentaire', newValue)
                        "
                    />
                </el-col>
            </el-row>
        </template>
    </FormWrapper>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import type { FormDataInterface } from '@connect-field/client/components/forms/form.types';
import FormTemplate from '@connect-field/client/components/forms/FormTemplate.vue';

import {
    labels,
    options,
} from '@connect-field/client/components/forms/Dotic/INEO/form_cable.data';

interface DataInterface extends FormDataInterface {}

export default defineComponent({
    extends: FormTemplate,
    data(): DataInterface {
        return {
            changes: false,
            form: {},
            images: {
                img_cable: [],
            },
            labels,
            loading: true,
            mandatoryFields: [
                'cb_code',
                'cb_codeext',
                'cb_etiquet',
                'cb_nd1',
                'cb_nd2',
                'cb_r1_code',
                'cb_r2_code',
                'cb_r3_code',
                'cb_r4_code',
                'cb_prop',
                'cb_gest',
                'cb_user',
                'cb_etat',
                'cb_dateins',
                'cb_datemes',
                'cb_avct',
                'cb_tech',
                'cb_typephy',
                'cb_typelog',
                'cb_rf_code',
                'cb_capafo',
                'cb_fo_disp',
                'cb_fo_util',
                'cb_modulo',
                'cb_diam',
                'cb_color',
                'cb_lgreel',
                'cb_localis',
                'cb_comment',
                'cb_creadat',
                'cb_majdate',
                'cb_majsrc',
                'cb_abddate',
                'cb_abdsrc',
            ],
            options,

            valueMissing: false,
        };
    },
    methods: {
        condition_cb_abddate() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_cb_abdsrc() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_cb_avct() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_cb_capafo() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_cb_codeext() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_cb_color() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_cb_comment() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_cb_creadat() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_cb_dateins() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_cb_datemes() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_cb_diam() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_cb_etat() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_cb_etiquet() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_cb_fo_disp() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_cb_fo_util() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_cb_gest() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_cb_lgreel() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_cb_localis() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_cb_majdate() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_cb_majsrc() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_cb_modulo() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_cb_nd1() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_cb_nd2() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_cb_prop() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_cb_proptyp() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_cb_r1_code() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_cb_r2_code() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_cb_r3_code() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_cb_r4_code() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_cb_rf_code() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_cb_statut() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_cb_tech() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_cb_typelog() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_cb_typephy() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_cb_user() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_commentaire() {
            const { real_audit } = this.form;
            return real_audit === 'NON';
        },
        condition_img_cable() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
    },
});
</script>
