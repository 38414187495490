<template>
    <div class="steps">
        <div
            :class="{ custom_link: step.status === 'failed' }"
            @click="() => (readErrors = !readErrors)"
        >
            <el-progress
                :percentage="step.downloadPercentage"
                :status="step.downloadStatus"
                :stroke-width="7"
            />
            <div
                class="full_step"
                :class="{
                    running: step.status === 'running',
                    success: step.status === 'success',
                    failed: step.status === 'failed',
                }"
            >
                <FontAwesomeIcon
                    v-if="step.status === ''"
                    icon="ellipsis-h"
                    class="icon"
                />
                <FontAwesomeIcon
                    v-if="step.status === 'running'"
                    icon="sync-alt"
                    class="icon"
                    spin
                />
                <FontAwesomeIcon
                    v-if="step.status === 'success'"
                    icon="check-circle"
                    class="icon"
                />
                <FontAwesomeIcon
                    v-if="step.status === 'failed'"
                    icon="exclamation-circle"
                    class="icon"
                />

                <div class="ml-20">
                    <div>
                        <span>{{ $t(step.message) }}</span>
                        <span
                            v-if="step?.errors?.length > 0"
                            class="far_right"
                        >
                            <FontAwesomeIcon
                                v-if="readErrors"
                                icon="angle-down"
                            />
                            <FontAwesomeIcon
                                v-if="!readErrors"
                                icon="angle-right"
                            />
                        </span>
                    </div>

                    <div v-if="step.status === 'failed' && readErrors">
                        <ul
                            v-for="message in step.errors"
                            :key="message"
                            class="failedDownloadMessages"
                        >
                            <li class="failedDownloadMessage">{{ message }}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';

import type { SyncProjectStep } from '@connect-field/client/services/download.service';

export default defineComponent({
    props: {
        step: {
            required: true,
            type: Object as PropType<SyncProjectStep>,
        },
    },
    data() {
        return {
            readErrors: false,
        };
    },
});
</script>

<style scoped lang="scss">
.full_step {
    display: flex;
    padding: 10px;
    color: #909399;

    &.running {
        color: #409eff;
    }

    &.success {
        color: #67c23a;
    }

    &.failed {
        color: #f56c6c;
    }

    .icon {
        position: absolute;
        margin-top: 0.1rem;
    }
}

.far_right {
    margin-left: auto;
}
</style>
