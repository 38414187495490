/*
Store to save map
 */

import { acceptHMRUpdate, defineStore } from 'pinia';
import type Map from 'ol/Map';

export interface StateInterface {
    geolocation?: GeolocationCoordinates;
    map?: Map;
    rulerDisplay: boolean;
    zoomDisplay: boolean;
    zoomLevel: number;
}

const useMapStore = defineStore('map', {
    actions: {
        resetGeolocation(): void {
            this.geolocation = undefined;
        },
        resetMap(): void {
            this.$reset();
        },
        toggleRulerDisplay(): void {
            this.rulerDisplay = !this.rulerDisplay;
        },
        toggleZoomDisplay(): void {
            this.zoomDisplay = !this.zoomDisplay;
        },
    },
    state: (): StateInterface => {
        return {
            geolocation: undefined,
            map: undefined,
            rulerDisplay: false,
            zoomDisplay: false,
            zoomLevel: 5,
        };
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useMapStore, import.meta.hot));
}

export default useMapStore;
