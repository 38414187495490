import type { ElProgress } from 'element-plus';

import * as LayerApi from '@connect-field/client/services/api/layer';
import * as ProjectApi from '@connect-field/client/services/api/project';
import type {
    OfflineGeoJSONLayerConfiguration,
    OfflineMBTilesLayerConfiguration,
} from '@connect-field/client/services/layers/offlineLayers.service';
import {
    type ProjectConfigurationLayer,
    ProjectConfigurationLayerFormatEnum,
    type ProjectConfigurationDto,
} from '@connect-field/client/sdk/generated';
import ApiError from '@connect-field/client/services/apiError.service';
import { extractMBTiles } from '@connect-field/client/services/api/layer';

type ElProgressPropsType = InstanceType<typeof ElProgress>['$props'];

export enum StepStatusEnum {
    FAILED = 'failed',
    IDLE = '',
    RUNNING = 'running',
    SUCCESS = 'success',
}

export interface DownloadOptions {
    bbox?: Array<number>;
    projectId: number;
    signal: unknown;
    zoneIds?: Array<number | string>;
}

export class SyncProjectStep {
    public downloadPercentage: number;
    public downloadStatus: ElProgressPropsType['status'];
    public errors: Array<string>;
    public message: string;
    public status: StepStatusEnum;

    public constructor(message: string) {
        this.downloadPercentage = 0;
        this.downloadStatus = '';
        this.errors = [];
        this.message = message;
        this.status = StepStatusEnum.IDLE;
    }
}

export class DownloadProjectStep {
    public errors: Array<string>;
    public message: string;
    public status: StepStatusEnum;

    public constructor(message: string) {
        this.errors = [];
        this.message = message;
        this.status = StepStatusEnum.IDLE;
    }
}

export class DownloadService {
    public incrementPercentage: (percentage: number) => void;
    public options: DownloadOptions;
    public stepPercentage: number;
    public steps: Array<DownloadProjectStep>;

    public constructor(
        options: DownloadOptions,
        steps: Array<DownloadProjectStep>,
        incrementPercentage: (percentage: number) => void,
    ) {
        this.incrementPercentage = incrementPercentage;
        this.options = options;
        this.stepPercentage = Math.round(100 / steps.length);
        this.steps = steps;
    }

    /**
     * Step 4
     */
    public async downloadBaseMap(): Promise<Uint8Array> {
        try {
            this.steps[3].status = StepStatusEnum.RUNNING;

            if (!this.options.bbox) {
                throw new Error('[downloadBaseMap] bbox must be defined');
            }
            const basemap = await ProjectApi.getProjectBasemap(
                this.options.projectId,
                this.options.bbox,
                this.options.zoneIds,
            );

            this.steps[3].status = StepStatusEnum.SUCCESS;
            this.incrementPercentage(this.stepPercentage);

            return basemap;
        } catch (error: unknown) {
            this.steps[3].status = StepStatusEnum.FAILED;

            if (error instanceof ApiError) {
                this.steps[3].errors.push(error.title);
            } else {
                if (error?.toString()) {
                    this.steps[3].errors.push(error.toString());
                }
                this.steps[3].errors.push('Error when downloading basemap');
            }

            throw error;
        }
    }

    /**
     * Step 2
     * @param projectConfiguration
     */
    public async downloadGeoJSONLayers(
        projectConfiguration: ProjectConfigurationDto,
    ): Promise<Array<OfflineGeoJSONLayerConfiguration>> {
        try {
            this.steps[1].status = StepStatusEnum.RUNNING;

            const bbox = this.options.bbox;
            if (!bbox) {
                throw new Error('[downloadGeoJSONLayers] bbox must be defined');
            }
            const layers = projectConfiguration.layers.filter((layer: ProjectConfigurationLayer) => {
                return layer.format === ProjectConfigurationLayerFormatEnum.GEOJSON;
            });

            const geoJSONLayers = await Promise.all(
                layers.map(async (layer: ProjectConfigurationLayer): Promise<OfflineGeoJSONLayerConfiguration> => {
                    const geoJSON = await LayerApi.extractGeoJSON(
                        layer.id,
                        this.options.projectId,
                        bbox,
                        this.options.zoneIds,
                    );

                    return { ...layer, data: geoJSON };
                }),
            );

            this.steps[1].status = StepStatusEnum.SUCCESS;
            this.incrementPercentage(this.stepPercentage);

            return geoJSONLayers;
        } catch (error: unknown) {
            this.steps[1].status = StepStatusEnum.FAILED;

            if (error instanceof ApiError) {
                this.steps[1].errors.push(error.title);
            } else {
                if (error?.toString()) {
                    this.steps[1].errors.push(error.toString());
                }
                this.steps[1].errors.push('Error when downloading forms');
            }

            throw error;
        }
    }

    /**
     * Step 6
     */
    public async downloadImagesDump(): Promise<Uint8Array> {
        try {
            this.steps[5].status = StepStatusEnum.RUNNING;

            if (!this.options.bbox) {
                throw new Error('[downloadImagesDump] bbox must be defined');
            }
            const imagesDump = await ProjectApi.getProjectImageDump(
                this.options.projectId,
                true,
                this.options.bbox,
                this.options.zoneIds,
            );

            this.steps[5].status = StepStatusEnum.SUCCESS;
            this.incrementPercentage(this.stepPercentage);

            return imagesDump;
        } catch (error: unknown) {
            this.steps[5].status = StepStatusEnum.FAILED;

            if (error instanceof ApiError) {
                this.steps[5].errors.push(error.title);
            } else {
                if (error?.toString()) {
                    this.steps[5].errors.push(error.toString());
                }
                this.steps[5].errors.push('Error when downloading image dump');
            }

            throw error;
        }
    }

    /**
     * Step 3
     */
    public async downloadPBFLayers2(
        projectConfiguration: ProjectConfigurationDto,
    ): Promise<Array<OfflineMBTilesLayerConfiguration>> {
        try {
            this.steps[2].status = StepStatusEnum.RUNNING;

            const bbox = this.options.bbox;
            if (!bbox) {
                throw new Error('[downloadPBFLayers] bbox must be defined');
            }
            const pbfLayers = projectConfiguration.layers.filter((layer: ProjectConfigurationLayer) => {
                return layer.format === ProjectConfigurationLayerFormatEnum.PBF;
            });
            const mbTilesLayers = await Promise.all(
                pbfLayers.map(async (layer: ProjectConfigurationLayer): Promise<OfflineMBTilesLayerConfiguration> => {
                    const mbtiles = await extractMBTiles(layer.id, this.options.projectId, bbox, this.options.zoneIds);

                    return { ...layer, data: mbtiles };
                }),
            );

            this.steps[2].status = StepStatusEnum.SUCCESS;
            this.incrementPercentage(this.stepPercentage);

            return mbTilesLayers;
        } catch (error: unknown) {
            this.steps[2].status = StepStatusEnum.FAILED;

            if (error instanceof ApiError) {
                this.steps[2].errors.push(error.title);
            } else {
                if (error?.toString()) {
                    this.steps[2].errors.push(error.toString());
                }
                this.steps[2].errors.push('Error when downloading forms');
            }

            throw error;
        }
    }

    /**
     * Step 1
     */
    public async downloadProjectConfiguration(): Promise<ProjectConfigurationDto> {
        try {
            this.steps[0].status = StepStatusEnum.RUNNING;

            const projectConfiguration = await ProjectApi.getProjectConfiguration(this.options.projectId);

            this.steps[0].status = StepStatusEnum.SUCCESS;
            this.incrementPercentage(this.stepPercentage);

            return projectConfiguration;
        } catch (error: unknown) {
            this.steps[0].status = StepStatusEnum.FAILED;

            if (error instanceof ApiError) {
                this.steps[0].errors.push(error.title);
            } else {
                if (error?.toString()) {
                    this.steps[0].errors.push(error.toString());
                }
                this.steps[0].errors.push('Error when downloading project configuration');
            }

            throw error;
        }
    }

    /**
     * Step 5
     */
    public async downloadSearchDump(): Promise<Uint8Array> {
        try {
            this.steps[4].status = StepStatusEnum.RUNNING;

            if (!this.options.bbox) {
                throw new Error('[downloadSearchDump] bbox project must be defined');
            }
            const searchDump = await ProjectApi.getProjectSearchDump(
                this.options.projectId,
                this.options.bbox,
                this.options.zoneIds,
            );

            this.steps[4].status = StepStatusEnum.SUCCESS;
            this.incrementPercentage(this.stepPercentage);

            return searchDump;
        } catch (error: unknown) {
            this.steps[4].status = StepStatusEnum.FAILED;

            if (error instanceof ApiError) {
                this.steps[4].errors.push(error.title);
            } else {
                if (error?.toString()) {
                    this.steps[4].errors.push(error.toString());
                }
                this.steps[4].errors.push('Error when downloading search dump');
            }

            throw error;
        }
    }
}
