<template>
    <li
        :class="{
            active: isActive,
            'project-item--card': isDisplayGrid,
            'project-item--list': !isDisplayGrid,
        }"
    >
        <div
            v-if="isDisplayGrid"
            class="project card"
            @click="openProject"
        >
            <div class="card__header">
                <p class="card__header__title">{{ projectTitle }}</p>
                <div
                    v-show="isOnline"
                    class="card__header__action"
                    @click.stop="goToDownloadProject"
                >
                    <icon-cloud :state="downloadState" />
                </div>
            </div>
            <div class="card__body">
                <img
                    v-if="image"
                    :src="image"
                    alt="project image"
                />
                <img
                    v-else
                    alt="default project image"
                    src="../../../public/img/default_project_img.jpg"
                />
            </div>
        </div>
        <div
            v-else
            :class="{ list__big: isFeatured }"
            class="project list"
            @click="openProject"
        >
            <div class="list__main">
                <div>
                    <img
                        v-if="image"
                        :src="image"
                        alt="project image"
                    />
                    <img
                        v-else
                        alt="default project image"
                        src="../../../public/img/default_project_img.jpg"
                    />
                </div>
                <div class="list__header">
                    <div class="list__title">{{ projectTitle }}</div>
                    <div
                        v-show="isOnline"
                        class="list__action"
                    >
                        <div
                            class="list__action__btn"
                            @click.stop="goToDownloadProject"
                        >
                            <icon-cloud :state="downloadState" />
                        </div>
                        <div class="list__action__btn list_action__btn__angle">
                            <FontAwesomeIcon icon="angle-right" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </li>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';
import { mapActions } from 'pinia';

import * as ProjectApi from '@connect-field/client/services/api/project';
import IconCloud from '@connect-field/client/components/atoms/IconCloud.vue';
import useProjectsStore from '@connect-field/client/stores/projects';

const GRID = 'grid';

interface DataInterface {
    image?: string;
    isActive: boolean;
    isOnline: boolean;
    loading: boolean;
}

export default defineComponent({
    components: {
        IconCloud,
    },
    props: {
        displayMode: {
            type: String as PropType<'grid' | 'list'>,
            default: 'grid',
        },
        downloadState: {
            type: String,
            default: 'download',
        },
        isFeatured: {
            type: Boolean,
            default: false,
        },
        projectId: {
            type: String,
            required: true,
        },
        projectTitle: {
            type: String,
            required: true,
        },
    },
    emits: ['stateChanged'],
    data(): DataInterface {
        return {
            image: undefined,
            isActive: false,
            isOnline: navigator.onLine,
            loading: true,
        };
    },
    computed: {
        isDisplayGrid(): boolean {
            return this.displayMode === GRID;
        },
    },
    async mounted() {
        this.loading = true;
        const image = await ProjectApi.getProjectImage(this.projectId);
        if (image) {
            this.image = image;
        }
        this.loading = false;
    },
    unmounted() {
        this.isActive = false;
    },
    methods: {
        ...mapActions(useProjectsStore, {
            setLastProjectViewed: 'setLastProjectViewed',
        }),
        goToDownloadProject(): void {
            this.$router.push({
                name: 'projectDownload',
                params: { projectId: this.projectId },
            });
        },
        openProject(): void {
            this.setLastProjectViewed(this.projectId, this.projectTitle, true);
            this.isActive = true;
            this.$router.push({
                name: 'project',
                params: { projectId: this.projectId },
            });
        },
    },
});
</script>

<style lang="scss" scoped>
@import 'ProjectItem';
</style>
