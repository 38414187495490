<template>
    <div
        class="container custom_link"
        @click="onClick"
    >
        <div class="imgContainer">
            <img
                :alt="getAltText"
                :src="getBackgroundMapUrl"
                :class="{ active: isActive }"
            />
        </div>

        <h4
            class="imgTitle"
            :class="{ active: isActive }"
        >
            {{ layerAlias }}
        </h4>
    </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';

import defaultMap from '@connect-field/client/assets/img/map/osm.jpg';
import satelliteMap from '@connect-field/client/assets/img/map/satellite.jpg';

interface DataInterface {
    backgroundMaps: {
        default: { alt: string; url: string };
        satellite: {
            alt: string;
            url: string;
        };
    };
}

export default defineComponent({
    props: {
        clickAction: {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            type: Function as PropType<(name: string) => void>,
            required: true,
        },
        isActive: { type: Boolean, required: true },
        layerAlias: { type: String, default: 'Carte' },
        layerName: { type: String, required: true },
        layerType: {
            type: String as PropType<'default' | 'satellite'>,
            required: true,
        },
    },
    data(): DataInterface {
        return {
            backgroundMaps: {
                default: { alt: 'default background map', url: defaultMap },
                satellite: {
                    alt: 'satellite background map',
                    url: satelliteMap,
                },
            },
        };
    },
    computed: {
        getAltText(): string {
            return this.backgroundMaps[this.layerType].alt;
        },
        getBackgroundMapUrl(): string {
            return this.backgroundMaps[this.layerType].url;
        },
    },
    methods: {
        onClick(): void {
            this.clickAction(this.layerName);
        },
    },
});
</script>

<style scoped lang="scss">
.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

img.active {
    box-shadow: 0 0 0 0.2rem var(--color-primary);
}

h4.active {
    color: var(--color-primary);
}

.imgContainer {
    display: flex;
    justify-content: center;
    width: 100%;
}

img {
    box-shadow: 0 0 0 0.2rem transparent;
    border-radius: 10px;
    object-fit: cover;
    width: 8rem;
    height: 5rem;
}

.imgTitle {
    color: white;
    font-weight: bold;
    padding: 0.5rem;
}
</style>
