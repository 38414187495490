<template>
    <FormWrapper
        v-if="!loading"
        ref="customFormRef"
        :images-model="images"
        :form-model="form"
        :mandatory-fields="mandatoryFields"
        :changes-model="changes"
        :layer-name="layerName"
        :object-id="objectId"
        :project-id="projectId"
        :latitude="latitude"
        :longitude="longitude"
        @update:images-model="
            (e) => {
                images = e;
            }
        "
        @update:value-missing="
            (e) => {
                valueMissing = e;
            }
        "
        @update:form-model="
            (e) => {
                form = e;
            }
        "
        @update:changes-model="
            (e) => {
                changes = e;
            }
        "
    >
        <template #menu_tabs>
            <el-row class="scroll-menu">
                <el-col :span="8">
                    <div
                        @click="selectTab('Général')"
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'Général' }"
                    >
                        Général
                    </div>
                </el-col>

                <el-col :span="8">
                    <div
                        @click="selectTab('Site NRO')"
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'Site NRO' }"
                    >
                        Site NRO
                    </div>
                </el-col>

                <el-col :span="8">
                    <div
                        @click="selectTab('Energie')"
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'Energie' }"
                    >
                        Energie
                    </div>
                </el-col>

                <el-col :span="8">
                    <div
                        @click="selectTab('Supervision AIE')"
                        class="tabs"
                        :class="{
                            selectedTab: selectedTab === 'Supervision AIE',
                        }"
                    >
                        Supervision AIE
                    </div>
                </el-col>

                <el-col :span="8">
                    <div
                        @click="selectTab('ODF')"
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'ODF' }"
                    >
                        ODF
                    </div>
                </el-col>

                <el-col :span="8">
                    <div
                        @click="selectTab('Baie Active')"
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'Baie Active' }"
                    >
                        Baie Active
                    </div>
                </el-col>

                <el-col :span="8">
                    <div
                        @click="selectTab('Altiline')"
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'Altiline' }"
                    >
                        Altiline
                    </div>
                </el-col>

                <el-col :span="8">
                    <div
                        @click="selectTab('Netgeo NRO')"
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'Netgeo NRO' }"
                    >
                        Netgeo NRO
                    </div>
                </el-col>
            </el-row>
        </template>
        <template #main_form>
            <el-row>
                <el-col
                    :span="24"
                    v-show="selectedTab === 'Général'"
                >
                    <CustomInput
                        :label="labels.ref_nro + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.ref_nro"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.adresse"
                        :value="form.adresse"
                        :maxlength="254"
                        :disabled="true"
                    />
                    <CustomDatePicker
                        :label="labels.date_audit + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.date_audit"
                        @update:value="
                            (newValue) => saveData('date_audit', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.type_recette + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.type_recette"
                        :value="form.type_recette"
                        @update:value="
                            (newValue) => saveData('type_recette', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.type_site + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.type_site"
                        :value="form.type_site"
                        @update:value="
                            (newValue) => saveData('type_site', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.statut_sitenro + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.statut_sitenro"
                        :value="form.statut_sitenro"
                        @update:value="
                            (newValue) => saveData('statut_sitenro', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.statut_energie + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.statut_energie"
                        :value="form.statut_energie"
                        @update:value="
                            (newValue) => saveData('statut_energie', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.statut_supervision + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.statut_supervision"
                        :value="form.statut_supervision"
                        @update:value="
                            (newValue) =>
                                saveData('statut_supervision', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.statut_odf + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.statut_odf"
                        :value="form.statut_odf"
                        @update:value="
                            (newValue) => saveData('statut_odf', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.statut_baieactive + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.statut_baieactive"
                        :value="form.statut_baieactive"
                        @update:value="
                            (newValue) =>
                                saveData('statut_baieactive', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.statut_altiline + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.statut_altiline"
                        :value="form.statut_altiline"
                        @update:value="
                            (newValue) => saveData('statut_altiline', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.statut_netgeo + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.statut_netgeo"
                        :value="form.statut_netgeo"
                        @update:value="
                            (newValue) => saveData('statut_netgeo', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.site_interieur_exterieur"
                        :images="images.site_interieur_exterieur"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('site_interieur_exterieur', newValue)
                        "
                    />
                </el-col>
                <el-col
                    :span="24"
                    v-show="selectedTab === 'Site NRO'"
                >
                    <CustomInputSwitch
                        :label="labels.sn_etat_general"
                        :options="options.sn_etat_general"
                        :value="form.sn_etat_general"
                        @update:value="
                            (newValue) => saveData('sn_etat_general', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.sn_badgeuse"
                        :options="options.sn_badgeuse"
                        :value="form.sn_badgeuse"
                        @update:value="
                            (newValue) => saveData('sn_badgeuse', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.sn_ouvertureb"
                        :options="options.sn_ouvertureb"
                        :value="form.sn_ouvertureb"
                        @update:value="
                            (newValue) => saveData('sn_ouvertureb', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.sn_ouverturec"
                        :options="options.sn_ouverturec"
                        :value="form.sn_ouverturec"
                        @update:value="
                            (newValue) => saveData('sn_ouverturec', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.badge_serrure"
                        :images="images.badge_serrure"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('badge_serrure', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.sn_plaque_pen"
                        :options="options.sn_plaque_pen"
                        :value="form.sn_plaque_pen"
                        @update:value="
                            (newValue) => saveData('sn_plaque_pen', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.plaque_id"
                        :images="images.plaque_id"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('plaque_id', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.sn_fumee"
                        :options="options.sn_fumee"
                        :value="form.sn_fumee"
                        @update:value="
                            (newValue) => saveData('sn_fumee', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.detecteur_fumee"
                        :images="images.detecteur_fumee"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('detecteur_fumee', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.sn_extincteur"
                        :options="options.sn_extincteur"
                        :value="form.sn_extincteur"
                        @update:value="
                            (newValue) => saveData('sn_extincteur', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.sn_date_extincteur"
                        :options="options.sn_date_extincteur"
                        :value="form.sn_date_extincteur"
                        @update:value="
                            (newValue) =>
                                saveData('sn_date_extincteur', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.extincteur"
                        :images="images.extincteur"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('extincteur', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.sn_clim"
                        :options="options.sn_clim"
                        :value="form.sn_clim"
                        @update:value="
                            (newValue) => saveData('sn_clim', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.climatisation"
                        :images="images.climatisation"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('climatisation', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.sn_convecteur"
                        :options="options.sn_convecteur"
                        :value="form.sn_convecteur"
                        @update:value="
                            (newValue) => saveData('sn_convecteur', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.sn_camera"
                        :options="options.sn_camera"
                        :value="form.sn_camera"
                        @update:value="
                            (newValue) => saveData('sn_camera', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.camera"
                        :images="images.camera"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('camera', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.sn_fourreau"
                        :options="options.sn_fourreau"
                        :value="form.sn_fourreau"
                        @update:value="
                            (newValue) => saveData('sn_fourreau', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_sn_fourreau"
                        :images="images.img_sn_fourreau"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('img_sn_fourreau', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.sn_obs"
                        :value="form.sn_obs"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('sn_obs', newValue)
                        "
                    />
                </el-col>
                <el-col
                    :span="24"
                    v-show="selectedTab === 'Energie'"
                >
                    <CustomInputSwitch
                        :label="labels.eng_compteur"
                        :options="options.eng_compteur"
                        :value="form.eng_compteur"
                        @update:value="
                            (newValue) => saveData('eng_compteur', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.logette_erdf"
                        :images="images.logette_erdf"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('logette_erdf', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.eng_erdf_calible"
                        :value="form.eng_erdf_calible"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('eng_erdf_calible', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.eng_erdf_supprdiff"
                        :options="options.eng_erdf_supprdiff"
                        :value="form.eng_erdf_supprdiff"
                        @update:value="
                            (newValue) =>
                                saveData('eng_erdf_supprdiff', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.eng_relevecompteur"
                        :value="form.eng_relevecompteur"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData('eng_relevecompteur', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.logette_erdf_ouverte"
                        :images="images.logette_erdf_ouverte"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('logette_erdf_ouverte', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.eng_tgbt_calibre"
                        :value="form.eng_tgbt_calibre"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('eng_tgbt_calibre', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.eng_tgbt_presdiff"
                        :options="options.eng_tgbt_presdiff"
                        :value="form.eng_tgbt_presdiff"
                        @update:value="
                            (newValue) =>
                                saveData('eng_tgbt_presdiff', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.eng_reenclencheur"
                        :options="options.eng_reenclencheur"
                        :value="form.eng_reenclencheur"
                        @update:value="
                            (newValue) =>
                                saveData('eng_reenclencheur', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.eng_parafoudre"
                        :options="options.eng_parafoudre"
                        :value="form.eng_parafoudre"
                        @update:value="
                            (newValue) => saveData('eng_parafoudre', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.eng_groupeelect"
                        :options="options.eng_groupeelect"
                        :value="form.eng_groupeelect"
                        @update:value="
                            (newValue) => saveData('eng_groupeelect', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.tgbt"
                        :images="images.tgbt"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('tgbt', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.eng_docbaie"
                        :options="options.eng_docbaie"
                        :value="form.eng_docbaie"
                        @update:value="
                            (newValue) => saveData('eng_docbaie', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.eng_onduleur"
                        :options="options.eng_onduleur"
                        :value="form.eng_onduleur"
                        @update:value="
                            (newValue) => saveData('eng_onduleur', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.eng_nbredresseur"
                        :value="form.eng_nbredresseur"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('eng_nbredresseur', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.eng_caparedresseur"
                        :value="form.eng_caparedresseur"
                        @update:value="
                            (newValue) =>
                                saveData('eng_caparedresseur', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.eng_disj48"
                        :options="options.eng_disj48"
                        :value="form.eng_disj48"
                        @update:value="
                            (newValue) => saveData('eng_disj48', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.baie_energie_haute"
                        :images="images.baie_energie_haute"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('baie_energie_haute', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.eng_disjbatterie"
                        :options="options.eng_disjbatterie"
                        :value="form.eng_disjbatterie"
                        @update:value="
                            (newValue) => saveData('eng_disjbatterie', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.eng_batterie"
                        :options="options.eng_batterie"
                        :value="form.eng_batterie"
                        @update:value="
                            (newValue) => saveData('eng_batterie', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.eng_nbbatterie"
                        :options="options.eng_nbbatterie"
                        :value="form.eng_nbbatterie"
                        @update:value="
                            (newValue) => saveData('eng_nbbatterie', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.eng_mesbatterie"
                        :options="options.eng_mesbatterie"
                        :value="form.eng_mesbatterie"
                        @update:value="
                            (newValue) => saveData('eng_mesbatterie', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.eng_bascule"
                        :options="options.eng_bascule"
                        :value="form.eng_bascule"
                        @update:value="
                            (newValue) => saveData('eng_bascule', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.eng_intensite"
                        :options="options.eng_intensite"
                        :value="form.eng_intensite"
                        @update:value="
                            (newValue) => saveData('eng_intensite', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.eng_floating"
                        :options="options.eng_floating"
                        :value="form.eng_floating"
                        @update:value="
                            (newValue) => saveData('eng_floating', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.baie_energie_batteries"
                        :images="images.baie_energie_batteries"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('baie_energie_batteries', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.eng_obs"
                        :value="form.eng_obs"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('eng_obs', newValue)
                        "
                    />
                </el-col>
                <el-col
                    :span="24"
                    v-show="selectedTab === 'Supervision AIE'"
                >
                    <CustomInputSwitch
                        :label="labels.sa_olt"
                        :options="options.sa_olt"
                        :value="form.sa_olt"
                        @update:value="
                            (newValue) => saveData('sa_olt', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.sa_switch"
                        :options="options.sa_switch"
                        :value="form.sa_switch"
                        @update:value="
                            (newValue) => saveData('sa_switch', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.sa_badgeuse"
                        :options="options.sa_badgeuse"
                        :value="form.sa_badgeuse"
                        @update:value="
                            (newValue) => saveData('sa_badgeuse', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.sa_distance"
                        :options="options.sa_distance"
                        :value="form.sa_distance"
                        @update:value="
                            (newValue) => saveData('sa_distance', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.sa_supervision"
                        :options="options.sa_supervision"
                        :value="form.sa_supervision"
                        @update:value="
                            (newValue) => saveData('sa_supervision', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.sa_camera"
                        :options="options.sa_camera"
                        :value="form.sa_camera"
                        @update:value="
                            (newValue) => saveData('sa_camera', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.sa_onduleur"
                        :options="options.sa_onduleur"
                        :value="form.sa_onduleur"
                        @update:value="
                            (newValue) => saveData('sa_onduleur', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.sa_porte"
                        :options="options.sa_porte"
                        :value="form.sa_porte"
                        @update:value="
                            (newValue) => saveData('sa_porte', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.sa_batterie"
                        :options="options.sa_batterie"
                        :value="form.sa_batterie"
                        @update:value="
                            (newValue) => saveData('sa_batterie', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.sa_redresseur"
                        :options="options.sa_redresseur"
                        :value="form.sa_redresseur"
                        @update:value="
                            (newValue) => saveData('sa_redresseur', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.sa_temperateur"
                        :options="options.sa_temperateur"
                        :value="form.sa_temperateur"
                        @update:value="
                            (newValue) => saveData('sa_temperateur', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.sa_incendie"
                        :options="options.sa_incendie"
                        :value="form.sa_incendie"
                        @update:value="
                            (newValue) => saveData('sa_incendie', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.sa_clim"
                        :options="options.sa_clim"
                        :value="form.sa_clim"
                        @update:value="
                            (newValue) => saveData('sa_clim', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.sa_nom"
                        :value="form.sa_nom"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('sa_nom', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.sa_obs"
                        :value="form.sa_obs"
                        @update:value="
                            (newValue) => saveData('sa_obs', newValue)
                        "
                    />
                </el-col>
                <el-col
                    :span="24"
                    v-show="selectedTab === 'ODF'"
                >
                    <CustomInputSwitch
                        :label="labels.odf_collecte"
                        :options="options.odf_collecte"
                        :value="form.odf_collecte"
                        @update:value="
                            (newValue) => saveData('odf_collecte', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.presse_etoupe"
                        :images="images.presse_etoupe"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('presse_etoupe', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.odf_lovecco"
                        :options="options.odf_lovecco"
                        :value="form.odf_lovecco"
                        @update:value="
                            (newValue) => saveData('odf_lovecco', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.odf_etqcco"
                        :options="options.odf_etqcco"
                        :value="form.odf_etqcco"
                        @update:value="
                            (newValue) => saveData('odf_etqcco', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.etiquette_cable"
                        :images="images.etiquette_cable"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('etiquette_cable', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.odf_eclateur_cco"
                        :options="options.odf_eclateur_cco"
                        :value="form.odf_eclateur_cco"
                        @update:value="
                            (newValue) => saveData('odf_eclateur_cco', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_eclateur_cco"
                        :images="images.img_eclateur_cco"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_eclateur_cco', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.odf_baiea1"
                        :options="options.odf_baiea1"
                        :value="form.odf_baiea1"
                        @update:value="
                            (newValue) => saveData('odf_baiea1', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.odf_abaque"
                        :options="options.odf_abaque"
                        :value="form.odf_abaque"
                        @update:value="
                            (newValue) => saveData('odf_abaque', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.baiea1"
                        :images="images.baiea1"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('baiea1', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.odf_positiontco"
                        :options="options.odf_positiontco"
                        :value="form.odf_positiontco"
                        @update:value="
                            (newValue) => saveData('odf_positiontco', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.odf_etqtco"
                        :options="options.odf_etqtco"
                        :value="form.odf_etqtco"
                        @update:value="
                            (newValue) => saveData('odf_etqtco', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.tco"
                        :images="images.tco"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('tco', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.odf_capuchontco"
                        :options="options.odf_capuchontco"
                        :value="form.odf_capuchontco"
                        @update:value="
                            (newValue) => saveData('odf_capuchontco', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.odf_connecteurtco"
                        :options="options.odf_connecteurtco"
                        :value="form.odf_connecteurtco"
                        @update:value="
                            (newValue) =>
                                saveData('odf_connecteurtco', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.odf_ritco"
                        :options="options.odf_ritco"
                        :value="form.odf_ritco"
                        @update:value="
                            (newValue) => saveData('odf_ritco', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.odf_sigterraintco"
                        :options="options.odf_sigterraintco"
                        :value="form.odf_sigterraintco"
                        @update:value="
                            (newValue) =>
                                saveData('odf_sigterraintco', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.plateaux_tco"
                        :images="images.plateaux_tco"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('plateaux_tco', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.odf_positiontbo"
                        :options="options.odf_positiontbo"
                        :value="form.odf_positiontbo"
                        @update:value="
                            (newValue) => saveData('odf_positiontbo', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.odf_etqtbo"
                        :options="options.odf_etqtbo"
                        :value="form.odf_etqtbo"
                        @update:value="
                            (newValue) => saveData('odf_etqtbo', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.tbo"
                        :images="images.tbo"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('tbo', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.odf_capuchontbo"
                        :options="options.odf_capuchontbo"
                        :value="form.odf_capuchontbo"
                        @update:value="
                            (newValue) => saveData('odf_capuchontbo', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.odf_connecteurtbo"
                        :options="options.odf_connecteurtbo"
                        :value="form.odf_connecteurtbo"
                        @update:value="
                            (newValue) =>
                                saveData('odf_connecteurtbo', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.odf_etatjarretiere"
                        :options="options.odf_etatjarretiere"
                        :value="form.odf_etatjarretiere"
                        @update:value="
                            (newValue) =>
                                saveData('odf_etatjarretiere', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.odf_typejarretiere"
                        :options="options.odf_typejarretiere"
                        :value="form.odf_typejarretiere"
                        @update:value="
                            (newValue) =>
                                saveData('odf_typejarretiere', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.odf_etqjarretiere"
                        :options="options.odf_etqjarretiere"
                        :value="form.odf_etqjarretiere"
                        @update:value="
                            (newValue) =>
                                saveData('odf_etqjarretiere', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.odf_nomtco"
                        :value="form.odf_nomtco"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('odf_nomtco', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.odf_ligne"
                        :value="form.odf_ligne"
                        @update:value="
                            (newValue) => saveData('odf_ligne', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.odf_colonne"
                        :value="form.odf_colonne"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('odf_colonne', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.branchement_jarretiere_tco"
                        :images="images.branchement_jarretiere_tco"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage(
                                    'branchement_jarretiere_tco',
                                    newValue,
                                )
                        "
                    />
                    <CustomInput
                        :label="labels.odf_obs"
                        :value="form.odf_obs"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('odf_obs', newValue)
                        "
                    />
                </el-col>
                <el-col
                    :span="24"
                    v-show="selectedTab === 'Baie Active'"
                >
                    <CustomInputSwitch
                        :label="labels.ba_etqpe"
                        :options="options.ba_etqpe"
                        :value="form.ba_etqpe"
                        @update:value="
                            (newValue) => saveData('ba_etqpe', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.ba_etqnom"
                        :options="options.ba_etqnom"
                        :value="form.ba_etqnom"
                        @update:value="
                            (newValue) => saveData('ba_etqnom', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.ba_porte"
                        :options="options.ba_porte"
                        :value="form.ba_porte"
                        @update:value="
                            (newValue) => saveData('ba_porte', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.baie_active"
                        :images="images.baie_active"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('baie_active', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.ba_position"
                        :options="options.ba_position"
                        :value="form.ba_position"
                        @update:value="
                            (newValue) => saveData('ba_position', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.ba_etattbo"
                        :options="options.ba_etattbo"
                        :value="form.ba_etattbo"
                        @update:value="
                            (newValue) => saveData('ba_etattbo', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.ba_etq_tirdwdm"
                        :options="options.ba_etq_tirdwdm"
                        :value="form.ba_etq_tirdwdm"
                        @update:value="
                            (newValue) => saveData('ba_etq_tirdwdm', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.ba_capuchondwdm"
                        :options="options.ba_capuchondwdm"
                        :value="form.ba_capuchondwdm"
                        @update:value="
                            (newValue) => saveData('ba_capuchondwdm', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.ba_connecteurdwdm"
                        :options="options.ba_connecteurdwdm"
                        :value="form.ba_connecteurdwdm"
                        @update:value="
                            (newValue) =>
                                saveData('ba_connecteurdwdm', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.ba_etatdwdm"
                        :options="options.ba_etatdwdm"
                        :value="form.ba_etatdwdm"
                        @update:value="
                            (newValue) => saveData('ba_etatdwdm', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.ba_typedwdm"
                        :options="options.ba_typedwdm"
                        :value="form.ba_typedwdm"
                        @update:value="
                            (newValue) => saveData('ba_typedwdm', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.ba_etq_tbodwdm"
                        :options="options.ba_etq_tbodwdm"
                        :value="form.ba_etq_tbodwdm"
                        @update:value="
                            (newValue) => saveData('ba_etq_tbodwdm', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.ba_dwdm"
                        :options="options.ba_dwdm"
                        :value="form.ba_dwdm"
                        @update:value="
                            (newValue) => saveData('ba_dwdm', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.partie_haute_baie_active"
                        :images="images.partie_haute_baie_active"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('partie_haute_baie_active', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.ba_etatttbo"
                        :options="options.ba_etatttbo"
                        :value="form.ba_etatttbo"
                        @update:value="
                            (newValue) => saveData('ba_etatttbo', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.ba_capuchontolt"
                        :options="options.ba_capuchontolt"
                        :value="form.ba_capuchontolt"
                        @update:value="
                            (newValue) => saveData('ba_capuchontolt', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.ba_connecteurtolt"
                        :options="options.ba_connecteurtolt"
                        :value="form.ba_connecteurtolt"
                        @update:value="
                            (newValue) =>
                                saveData('ba_connecteurtolt', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.ba_etat_jolt"
                        :options="options.ba_etat_jolt"
                        :value="form.ba_etat_jolt"
                        @update:value="
                            (newValue) => saveData('ba_etat_jolt', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.ba_type_jolt"
                        :options="options.ba_type_jolt"
                        :value="form.ba_type_jolt"
                        @update:value="
                            (newValue) => saveData('ba_type_jolt', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.ba_etq_jolt"
                        :options="options.ba_etq_jolt"
                        :value="form.ba_etq_jolt"
                        @update:value="
                            (newValue) => saveData('ba_etq_jolt', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.ba_etq_olt"
                        :options="options.ba_etq_olt"
                        :value="form.ba_etq_olt"
                        @update:value="
                            (newValue) => saveData('ba_etq_olt', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.partie_basse_baie_active"
                        :images="images.partie_basse_baie_active"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('partie_basse_baie_active', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.ba_ethernet"
                        :options="options.ba_ethernet"
                        :value="form.ba_ethernet"
                        @update:value="
                            (newValue) => saveData('ba_ethernet', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.ba_etqethernet"
                        :options="options.ba_etqethernet"
                        :value="form.ba_etqethernet"
                        @update:value="
                            (newValue) => saveData('ba_etqethernet', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.ba_alim"
                        :options="options.ba_alim"
                        :value="form.ba_alim"
                        @update:value="
                            (newValue) => saveData('ba_alim', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.ba_etqalim"
                        :options="options.ba_etqalim"
                        :value="form.ba_etqalim"
                        @update:value="
                            (newValue) => saveData('ba_etqalim', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.ba_disjoncteur"
                        :options="options.ba_disjoncteur"
                        :value="form.ba_disjoncteur"
                        @update:value="
                            (newValue) => saveData('ba_disjoncteur', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.ba_apc"
                        :options="options.ba_apc"
                        :value="form.ba_apc"
                        @update:value="
                            (newValue) => saveData('ba_apc', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.ba_etqapc"
                        :options="options.ba_etqapc"
                        :value="form.ba_etqapc"
                        @update:value="
                            (newValue) => saveData('ba_etqapc', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.apc_baie_active"
                        :images="images.apc_baie_active"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('apc_baie_active', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.ba_obs"
                        :value="form.ba_obs"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('ba_obs', newValue)
                        "
                    />
                </el-col>
                <el-col
                    :span="24"
                    v-show="selectedTab === 'Altiline'"
                >
                    <CustomInputSwitch
                        :label="labels.al_gps"
                        :options="options.al_gps"
                        :value="form.al_gps"
                        @update:value="
                            (newValue) => saveData('al_gps', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.al_adresse"
                        :options="options.al_adresse"
                        :value="form.al_adresse"
                        @update:value="
                            (newValue) => saveData('al_adresse', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.al_acces"
                        :options="options.al_acces"
                        :value="form.al_acces"
                        @update:value="
                            (newValue) => saveData('al_acces', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.al_equip"
                        :options="options.al_equip"
                        :value="form.al_equip"
                        @update:value="
                            (newValue) => saveData('al_equip', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.al_erdf"
                        :options="options.al_erdf"
                        :value="form.al_erdf"
                        @update:value="
                            (newValue) => saveData('al_erdf', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.al_dateconstruction"
                        :options="options.al_dateconstruction"
                        :value="form.al_dateconstruction"
                        @update:value="
                            (newValue) =>
                                saveData('al_dateconstruction', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.al_daterecette"
                        :options="options.al_daterecette"
                        :value="form.al_daterecette"
                        @update:value="
                            (newValue) => saveData('al_daterecette', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.al_obs"
                        :value="form.al_obs"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('al_obs', newValue)
                        "
                    />
                </el-col>
                <el-col
                    :span="24"
                    v-show="selectedTab === 'Netgeo NRO'"
                >
                    <CustomInputSwitch
                        :label="labels.nn_nom"
                        :options="options.nn_nom"
                        :value="form.nn_nom"
                        @update:value="
                            (newValue) => saveData('nn_nom', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nn_code"
                        :options="options.nn_code"
                        :value="form.nn_code"
                        @update:value="
                            (newValue) => saveData('nn_code', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nn_adresse"
                        :options="options.nn_adresse"
                        :value="form.nn_adresse"
                        @update:value="
                            (newValue) => saveData('nn_adresse', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nn_etat"
                        :options="options.nn_etat"
                        :value="form.nn_etat"
                        @update:value="
                            (newValue) => saveData('nn_etat', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nn_gestionnaire"
                        :options="options.nn_gestionnaire"
                        :value="form.nn_gestionnaire"
                        @update:value="
                            (newValue) => saveData('nn_gestionnaire', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nn_proprietaire"
                        :options="options.nn_proprietaire"
                        :value="form.nn_proprietaire"
                        @update:value="
                            (newValue) => saveData('nn_proprietaire', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nn_typefonc"
                        :options="options.nn_typefonc"
                        :value="form.nn_typefonc"
                        @update:value="
                            (newValue) => saveData('nn_typefonc', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nn_typestruc"
                        :options="options.nn_typestruc"
                        :value="form.nn_typestruc"
                        @update:value="
                            (newValue) => saveData('nn_typestruc', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nn_nomlt02"
                        :options="options.nn_nomlt02"
                        :value="form.nn_nomlt02"
                        @update:value="
                            (newValue) => saveData('nn_nomlt02', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nn_codelt02"
                        :options="options.nn_codelt02"
                        :value="form.nn_codelt02"
                        @update:value="
                            (newValue) => saveData('nn_codelt02', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nn_etatlt02"
                        :options="options.nn_etatlt02"
                        :value="form.nn_etatlt02"
                        @update:value="
                            (newValue) => saveData('nn_etatlt02', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nn_gestionnairelt02"
                        :options="options.nn_gestionnairelt02"
                        :value="form.nn_gestionnairelt02"
                        @update:value="
                            (newValue) =>
                                saveData('nn_gestionnairelt02', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nn_proprietairelt02"
                        :options="options.nn_proprietairelt02"
                        :value="form.nn_proprietairelt02"
                        @update:value="
                            (newValue) =>
                                saveData('nn_proprietairelt02', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nn_typelt02"
                        :options="options.nn_typelt02"
                        :value="form.nn_typelt02"
                        @update:value="
                            (newValue) => saveData('nn_typelt02', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nn_nomtco"
                        :options="options.nn_nomtco"
                        :value="form.nn_nomtco"
                        @update:value="
                            (newValue) => saveData('nn_nomtco', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nn_codetco"
                        :options="options.nn_codetco"
                        :value="form.nn_codetco"
                        @update:value="
                            (newValue) => saveData('nn_codetco', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nn_typestructco"
                        :options="options.nn_typestructco"
                        :value="form.nn_typestructco"
                        @update:value="
                            (newValue) => saveData('nn_typestructco', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nn_typefonctco"
                        :options="options.nn_typefonctco"
                        :value="form.nn_typefonctco"
                        @update:value="
                            (newValue) => saveData('nn_typefonctco', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nn_capatco"
                        :options="options.nn_capatco"
                        :value="form.nn_capatco"
                        @update:value="
                            (newValue) => saveData('nn_capatco', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nn_cabletco"
                        :options="options.nn_cabletco"
                        :value="form.nn_cabletco"
                        @update:value="
                            (newValue) => saveData('nn_cabletco', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nn_terrainsigtco"
                        :options="options.nn_terrainsigtco"
                        :value="form.nn_terrainsigtco"
                        @update:value="
                            (newValue) => saveData('nn_terrainsigtco', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nn_rop"
                        :options="options.nn_rop"
                        :value="form.nn_rop"
                        @update:value="
                            (newValue) => saveData('nn_rop', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.nn_obs"
                        :value="form.nn_obs"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('nn_obs', newValue)
                        "
                    />
                </el-col>
            </el-row>
        </template>
    </FormWrapper>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import {
    labels,
    options,
} from '@connect-field/client/components/forms/AIC/QR/t_qr_nro.data';
import type { FormDataInterface } from '@connect-field/client/components/forms/form.types';
import FormTemplate from '@connect-field/client/components/forms/FormTemplate.vue';

interface DataInterface extends FormDataInterface {}

export default defineComponent({
    extends: FormTemplate,
    data(): DataInterface {
        return {
            changes: false,
            form: {},
            images: {
                site_interieur_exterieur: [],
                badge_serrure: [],
                plaque_id: [],
                detecteur_fumee: [],
                extincteur: [],
                climatisation: [],
                camera: [],
                img_sn_fourreau: [],
                logette_erdf: [],
                logette_erdf_ouverte: [],
                tgbt: [],
                baie_energie_haute: [],
                baie_energie_batteries: [],
                presse_etoupe: [],
                etiquette_cable: [],
                img_eclateur_cco: [],
                baiea1: [],
                tco: [],
                plateaux_tco: [],
                tbo: [],
                branchement_jarretiere_tco: [],
                baie_active: [],
                partie_haute_baie_active: [],
                partie_basse_baie_active: [],
                apc_baie_active: [],
            },
            labels,
            loading: true,
            mandatoryFields: [
                'ref_nro',
                'date_audit',
                'type_recette',
                'type_site',
                'statut_sitenro',
                'statut_energie',
                'statut_supervision',
                'statut_odf',
                'statut_baieactive',
                'statut_altiline',
                'statut_netgeo',
            ],
            options,
            selectedTab: 'Général',
            valueMissing: false,
        };
    },
    methods: {},
});
</script>
