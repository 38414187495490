<template>
    <FormWrapper
        v-if="!loading"
        ref="customFormRef"
        :changes-model="changes"
        :form-model="form"
        :images-model="images"
        :latitude="latitude"
        :layer-name="layerName"
        :longitude="longitude"
        :mandatory-fields="mandatoryFields"
        :object-id="objectId"
        :project-id="projectId"
        @update:images-model="
            (e) => {
                images = e;
            }
        "
        @update:value-missing="
            (e) => {
                valueMissing = e;
            }
        "
        @update:form-model="
            (e) => {
                form = e;
            }
        "
        @update:changes-model="
            (e) => {
                changes = e;
            }
        "
    >
        <template #menu_tabs>
            <el-row class="scroll-menu">
                <el-col :span="12">
                    <div
                        :class="{ selectedTab: selectedTab === 'GENERAL' }"
                        class="tabs"
                        @click="selectTab('GENERAL')"
                    >
                        GENERAL
                    </div>
                </el-col>

                <el-col :span="12">
                    <div
                        :class="{ selectedTab: selectedTab === 'MECANIQUE' }"
                        class="tabs"
                        @click="selectTab('MECANIQUE')"
                    >
                        MECANIQUE
                    </div>
                </el-col>
            </el-row>
        </template>
        <template #main_form>
            <el-row>
                <el-col
                    v-show="selectedTab === 'GENERAL'"
                    :span="24"
                >
                    <CustomInput
                        :class="{ 'text-warning': valueMissing }"
                        :disabled="true"
                        :label="labels.pt_code + ' *'"
                        :maxlength="254"
                        :value="form.pt_code"
                    />
                    <CustomInputSelect
                        :label="labels.statut"
                        :options="options.statut"
                        :value="form.statut"
                        @update:value="
                            (newValue) => saveData('statut', newValue)
                        "
                    />
                    <CustomInput
                        :class="{ 'text-warning': valueMissing }"
                        :disabled="true"
                        :label="labels.pt_codeext + ' *'"
                        :maxlength="254"
                        :value="form.pt_codeext"
                    />
                    <CustomInputSwitch
                        :disabled="true"
                        :label="labels.pt_abandon"
                        :options="options.pt_abandon"
                        :value="form.pt_abandon"
                    />
                    <CustomInput
                        :disabled="true"
                        :label="labels.pt_etiquet"
                        :maxlength="254"
                        :value="form.pt_etiquet"
                    />
                    <CustomInput
                        v-show="condition_pt_perirec()"
                        :disabled="true"
                        :label="labels.pt_perirec"
                        :value="form.pt_perirec"
                    />
                    <CustomInput
                        v-show="condition_pt_nd_code()"
                        :disabled="true"
                        :label="labels.pt_nd_code"
                        :maxlength="254"
                        :value="form.pt_nd_code"
                    />
                    <CustomInput
                        v-show="condition_pt_ad_code()"
                        :disabled="true"
                        :label="labels.pt_ad_code"
                        :value="form.pt_ad_code"
                    />
                    <CustomInput
                        v-show="condition_pt_gest_do()"
                        :disabled="true"
                        :label="labels.pt_gest_do"
                        :maxlength="20"
                        :value="form.pt_gest_do"
                    />
                    <CustomInput
                        v-show="condition_pt_prop_do()"
                        :disabled="true"
                        :label="labels.pt_prop_do"
                        :value="form.pt_prop_do"
                    />
                    <CustomInput
                        v-show="condition_pt_prop()"
                        :disabled="true"
                        :label="labels.pt_prop"
                        :maxlength="20"
                        :value="form.pt_prop"
                    />
                    <CustomInput
                        v-show="condition_pt_gest()"
                        :disabled="true"
                        :label="labels.pt_gest"
                        :value="form.pt_gest"
                    />
                    <CustomInput
                        v-show="condition_pt_user()"
                        :disabled="true"
                        :label="labels.pt_user"
                        :maxlength="20"
                        :value="form.pt_user"
                    />
                    <CustomInputSelect
                        v-show="condition_pt_proptyp()"
                        :disabled="true"
                        :label="labels.pt_proptyp"
                        :options="options.pt_proptyp"
                        :value="form.pt_proptyp"
                    />
                    <CustomInputSelect
                        v-show="condition_pt_statut()"
                        :disabled="true"
                        :label="labels.pt_statut"
                        :options="options.pt_statut"
                        :value="form.pt_statut"
                    />
                    <CustomInputSelect
                        v-show="condition_pt_etat()"
                        :disabled="true"
                        :label="labels.pt_etat"
                        :options="options.pt_etat"
                        :value="form.pt_etat"
                    />
                    <CustomDatePicker
                        v-show="condition_pt_datemes()"
                        :disabled="true"
                        :label="labels.pt_datemes"
                        :value="form.pt_datemes"
                    />
                    <CustomInputSelect
                        v-show="condition_pt_avct()"
                        :disabled="true"
                        :label="labels.pt_avct"
                        :options="options.pt_avct"
                        :value="form.pt_avct"
                    />
                    <CustomInputSelect
                        v-show="condition_pt_typephy()"
                        :disabled="true"
                        :label="labels.pt_typephy"
                        :options="options.pt_typephy"
                        :value="form.pt_typephy"
                    />
                    <CustomInputSelect
                        v-show="condition_pt_typelog()"
                        :disabled="true"
                        :label="labels.pt_typelog"
                        :options="options.pt_typelog"
                        :value="form.pt_typelog"
                    />
                    <CustomInput
                        v-show="condition_pt_rf_code()"
                        :disabled="true"
                        :label="labels.pt_rf_code"
                        :value="form.pt_rf_code"
                    />
                    <CustomInputSelect
                        v-show="condition_pt_nature()"
                        :disabled="true"
                        :label="labels.pt_nature"
                        :options="options.pt_nature"
                        :value="form.pt_nature"
                    />
                    <CustomInputSwitch
                        v-show="condition_pt_secu()"
                        :disabled="true"
                        :label="labels.pt_secu"
                        :options="options.pt_secu"
                        :value="form.pt_secu"
                    />
                    <CustomInputSelect
                        v-show="condition_pt_occp()"
                        :disabled="true"
                        :label="labels.pt_occp"
                        :options="options.pt_occp"
                        :value="form.pt_occp"
                    />
                    <InputNumber
                        v-show="condition_pt_a_dan()"
                        :disabled="true"
                        :label="labels.pt_a_dan"
                        :value="form.pt_a_dan"
                    />
                    <CustomDatePicker
                        v-show="condition_pt_a_dtetu()"
                        :disabled="true"
                        :label="labels.pt_a_dtetu"
                        :value="form.pt_a_dtetu"
                    />
                    <CustomInput
                        v-show="condition_pt_a_struc()"
                        :disabled="true"
                        :label="labels.pt_a_struc"
                        :maxlength="100"
                        :value="form.pt_a_struc"
                    />
                    <InputNumber
                        v-show="condition_pt_a_haut()"
                        :disabled="true"
                        :label="labels.pt_a_haut"
                        :value="form.pt_a_haut"
                    />
                    <CustomInputSwitch
                        v-show="condition_pt_a_passa()"
                        :disabled="true"
                        :label="labels.pt_a_passa"
                        :options="options.pt_a_passa"
                        :value="form.pt_a_passa"
                    />
                    <CustomInputSwitch
                        v-show="condition_pt_a_strat()"
                        :disabled="true"
                        :label="labels.pt_a_strat"
                        :options="options.pt_a_strat"
                        :value="form.pt_a_strat"
                    />
                    <CustomInputSwitch
                        v-show="condition_pt_detec()"
                        :disabled="true"
                        :label="labels.pt_detec"
                        :options="options.pt_detec"
                        :value="form.pt_detec"
                    />
                    <CustomInput
                        v-show="condition_pt_nomvoie()"
                        :disabled="true"
                        :label="labels.pt_nomvoie"
                        :value="form.pt_nomvoie"
                    />
                    <CustomInput
                        v-show="condition_pt_local()"
                        :disabled="true"
                        :label="labels.pt_local"
                        :maxlength="254"
                        :value="form.pt_local"
                    />
                    <CustomInput
                        v-show="condition_pt_postal()"
                        :disabled="true"
                        :label="labels.pt_postal"
                        :value="form.pt_postal"
                    />
                    <CustomInput
                        v-show="condition_pt_insee()"
                        :disabled="true"
                        :label="labels.pt_insee"
                        :maxlength="20"
                        :value="form.pt_insee"
                    />
                    <CustomInput
                        v-show="condition_pt_commune()"
                        :disabled="true"
                        :label="labels.pt_commune"
                        :value="form.pt_commune"
                    />
                    <CustomInput
                        v-show="condition_pt_section()"
                        :disabled="true"
                        :label="labels.pt_section"
                        :maxlength="5"
                        :value="form.pt_section"
                    />
                    <CustomInput
                        v-show="condition_pt_idpar()"
                        :disabled="true"
                        :label="labels.pt_idpar"
                        :value="form.pt_idpar"
                    />
                    <InputNumber
                        v-show="condition_pt_numero()"
                        :disabled="true"
                        :label="labels.pt_numero"
                        :value="form.pt_numero"
                    />
                    <CustomInput
                        v-show="condition_pt_rep()"
                        :disabled="true"
                        :label="labels.pt_rep"
                        :maxlength="20"
                        :value="form.pt_rep"
                    />
                    <CustomInput
                        v-show="condition_pt_comment()"
                        :disabled="true"
                        :label="labels.pt_comment"
                        :value="form.pt_comment"
                    />
                    <CustomDatePicker
                        v-show="condition_pt_creadat()"
                        :disabled="true"
                        :label="labels.pt_creadat"
                        :value="form.pt_creadat"
                    />
                    <CustomDatePicker
                        v-show="condition_pt_majdate()"
                        :disabled="true"
                        :label="labels.pt_majdate"
                        :value="form.pt_majdate"
                    />
                    <CustomInput
                        v-show="condition_pt_majsrc()"
                        :disabled="true"
                        :label="labels.pt_majsrc"
                        :maxlength="254"
                        :value="form.pt_majsrc"
                    />
                    <CustomDatePicker
                        v-show="condition_pt_abddate()"
                        :disabled="true"
                        :label="labels.pt_abddate"
                        :value="form.pt_abddate"
                    />
                    <CustomInput
                        v-show="condition_pt_abdsrc()"
                        :disabled="true"
                        :label="labels.pt_abdsrc"
                        :maxlength="254"
                        :value="form.pt_abdsrc"
                    />
                </el-col>
                <el-col
                    v-show="selectedTab === 'MECANIQUE'"
                    :span="24"
                >
                    <CustomRadioButton
                        :label="labels.fixation_du_des_cables_s_1"
                        :options="options.fixation_du_des_cables_s_1"
                        :value="form.fixation_du_des_cables_s_1"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('fixation_du_des_cables_s_1', newValue)
                        "
                    />

                    <div v-show="condition_img_fixation_du_des_cables_s_1()">
                        <InputCamera
                            :images="images.img_fixation_du_des_cables_s_1"
                            :label="labels.img_fixation_du_des_cables_s_1"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage(
                                        'img_fixation_du_des_cables_s_1',
                                        newValue,
                                    )
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="
                            condition_commentaire_fixation_du_des_cables_s_1()
                        "
                        :label="labels.commentaire_fixation_du_des_cables_s_1"
                        :maxlength="254"
                        :value="form.commentaire_fixation_du_des_cables_s_1"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_fixation_du_des_cables_s_1',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.obturation_des_alveoles_fou"
                        :options="options.obturation_des_alveoles_fou"
                        :value="form.obturation_des_alveoles_fou"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'obturation_des_alveoles_fou',
                                    newValue,
                                )
                        "
                    />

                    <div v-show="condition_img_obturation_des_alveoles_fou()">
                        <InputCamera
                            :images="images.img_obturation_des_alveoles_fou"
                            :label="labels.img_obturation_des_alveoles_fou"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage(
                                        'img_obturation_des_alveoles_fou',
                                        newValue,
                                    )
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="
                            condition_commentaire_obturation_des_alveoles_fou()
                        "
                        :label="labels.commentaire_obturation_des_alveoles_fou"
                        :maxlength="254"
                        :value="form.commentaire_obturation_des_alveoles_fou"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_obturation_des_alveoles_fou',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.passage_dans_l_alveole_adequa"
                        :options="options.passage_dans_l_alveole_adequa"
                        :value="form.passage_dans_l_alveole_adequa"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'passage_dans_l_alveole_adequa',
                                    newValue,
                                )
                        "
                    />

                    <div v-show="condition_img_passage_dans_l_alveole_adequa()">
                        <InputCamera
                            :images="images.img_passage_dans_l_alveole_adequa"
                            :label="labels.img_passage_dans_l_alveole_adequa"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage(
                                        'img_passage_dans_l_alveole_adequa',
                                        newValue,
                                    )
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="
                            condition_commentaire_passage_dans_l_alveole_adequa()
                        "
                        :label="
                            labels.commentaire_passage_dans_l_alveole_adequa
                        "
                        :maxlength="254"
                        :value="form.commentaire_passage_dans_l_alveole_adequa"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_passage_dans_l_alveole_adequa',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.passage_cable_s_en_goutte_le"
                        :options="options.passage_cable_s_en_goutte_le"
                        :value="form.passage_cable_s_en_goutte_le"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'passage_cable_s_en_goutte_le',
                                    newValue,
                                )
                        "
                    />

                    <div v-show="condition_img_passage_cable_s_en_goutte_le()">
                        <InputCamera
                            :images="images.img_passage_cable_s_en_goutte_le"
                            :label="labels.img_passage_cable_s_en_goutte_le"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage(
                                        'img_passage_cable_s_en_goutte_le',
                                        newValue,
                                    )
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="
                            condition_commentaire_passage_cable_s_en_goutte_le()
                        "
                        :label="labels.commentaire_passage_cable_s_en_goutte_le"
                        :maxlength="254"
                        :value="form.commentaire_passage_cable_s_en_goutte_le"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_passage_cable_s_en_goutte_le',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.gaine_de_protection_cable_s_1"
                        :options="options.gaine_de_protection_cable_s_1"
                        :value="form.gaine_de_protection_cable_s_1"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'gaine_de_protection_cable_s_1',
                                    newValue,
                                )
                        "
                    />

                    <div v-show="condition_img_gaine_de_protection_cable_s_1()">
                        <InputCamera
                            :images="images.img_gaine_de_protection_cable_s_1"
                            :label="labels.img_gaine_de_protection_cable_s_1"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage(
                                        'img_gaine_de_protection_cable_s_1',
                                        newValue,
                                    )
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="
                            condition_commentaire_gaine_de_protection_cable_s_1()
                        "
                        :label="
                            labels.commentaire_gaine_de_protection_cable_s_1
                        "
                        :maxlength="254"
                        :value="form.commentaire_gaine_de_protection_cable_s_1"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_gaine_de_protection_cable_s_1',
                                    newValue,
                                )
                        "
                    />
                    <CustomInputSelect
                        :label="labels.autres_observations"
                        :options="options.autres_observations"
                        :value="form.autres_observations"
                        @update:value="
                            (newValue) =>
                                saveData('autres_observations', newValue)
                        "
                    />

                    <div v-show="condition_img_autres_observations()">
                        <InputCamera
                            :images="images.img_autres_observations"
                            :label="labels.img_autres_observations"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage(
                                        'img_autres_observations',
                                        newValue,
                                    )
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="condition_commentaire_autres_observations()"
                        :label="labels.commentaire_autres_observations"
                        :maxlength="254"
                        :value="form.commentaire_autres_observations"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_autres_observations',
                                    newValue,
                                )
                        "
                    />
                </el-col>
            </el-row>
        </template>
    </FormWrapper>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import {
    labels,
    options,
} from '@connect-field/client/components/forms/NGE/form_audit_chambre_nge.data';
import type { FormDataInterface } from '@connect-field/client/components/forms/form.types';
import FormTemplate from '@connect-field/client/components/forms/FormTemplate.vue';

interface DataInterface extends FormDataInterface {}

export default defineComponent({
    extends: FormTemplate,
    data(): DataInterface {
        return {
            changes: false,
            form: {},
            images: {
                img_fixation_du_des_cables_s_1: [],
                img_obturation_des_alveoles_fou: [],
                img_passage_dans_l_alveole_adequa: [],
                img_passage_cable_s_en_goutte_le: [],
                img_gaine_de_protection_cable_s_1: [],
                img_autres_observations: [],
            },
            labels,
            loading: true,
            mandatoryFields: ['pt_code', 'pt_codeext'],
            options,
            selectedTab: 'GENERAL',
            valueMissing: false,
        };
    },
    methods: {
        condition_pt_perirec(): boolean {
            const {} = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_nd_code(): boolean {
            const {} = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_ad_code(): boolean {
            const {} = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_gest_do(): boolean {
            const {} = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_prop_do(): boolean {
            const {} = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_prop(): boolean {
            const {} = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_gest(): boolean {
            const {} = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_user(): boolean {
            const {} = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_proptyp(): boolean {
            const {} = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_statut(): boolean {
            const {} = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_etat(): boolean {
            const {} = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_datemes(): boolean {
            const {} = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_avct(): boolean {
            const {} = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_typephy(): boolean {
            const {} = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_typelog(): boolean {
            const {} = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_rf_code(): boolean {
            const {} = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_nature(): boolean {
            const {} = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_secu(): boolean {
            const {} = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_occp(): boolean {
            const {} = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_a_dan(): boolean {
            const {} = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_a_dtetu(): boolean {
            const {} = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_a_struc(): boolean {
            const {} = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_a_haut(): boolean {
            const {} = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_a_passa(): boolean {
            const {} = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_a_strat(): boolean {
            const {} = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_detec(): boolean {
            const {} = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_nomvoie(): boolean {
            const {} = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_local(): boolean {
            const {} = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_postal(): boolean {
            const {} = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_insee(): boolean {
            const {} = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_commune(): boolean {
            const {} = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_section(): boolean {
            const {} = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_idpar(): boolean {
            const {} = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_numero(): boolean {
            const {} = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_rep(): boolean {
            const {} = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_comment(): boolean {
            const {} = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_creadat(): boolean {
            const {} = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_majdate(): boolean {
            const {} = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_majsrc(): boolean {
            const {} = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_abddate(): boolean {
            const {} = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_abdsrc(): boolean {
            const {} = this.form;
            return donnees_metier === 'OUI';
        },
        condition_img_fixation_du_des_cables_s_1(): boolean {
            const { fixation_du_des_cables_s_1 } = this.form;
            return fixation_du_des_cables_s_1?.startsWith('NOK');
        },
        condition_commentaire_fixation_du_des_cables_s_1(): boolean {
            const { fixation_du_des_cables_s_1 } = this.form;
            return fixation_du_des_cables_s_1?.startsWith('NOK');
        },
        condition_img_obturation_des_alveoles_fou(): boolean {
            const { obturation_des_alveoles_fou } = this.form;
            return obturation_des_alveoles_fou?.startsWith('NOK');
        },
        condition_commentaire_obturation_des_alveoles_fou(): boolean {
            const { obturation_des_alveoles_fou } = this.form;
            return obturation_des_alveoles_fou?.startsWith('NOK');
        },
        condition_img_passage_dans_l_alveole_adequa(): boolean {
            const { passage_dans_l_alveole_adequa } = this.form;
            return passage_dans_l_alveole_adequa?.startsWith('NOK');
        },
        condition_commentaire_passage_dans_l_alveole_adequa(): boolean {
            const { passage_dans_l_alveole_adequa } = this.form;
            return passage_dans_l_alveole_adequa?.startsWith('NOK');
        },
        condition_img_passage_cable_s_en_goutte_le(): boolean {
            const { passage_cable_s_en_goutte_le } = this.form;
            return passage_cable_s_en_goutte_le?.startsWith('NOK');
        },
        condition_commentaire_passage_cable_s_en_goutte_le(): boolean {
            const { passage_cable_s_en_goutte_le } = this.form;
            return passage_cable_s_en_goutte_le?.startsWith('NOK');
        },
        condition_img_gaine_de_protection_cable_s_1(): boolean {
            const { gaine_de_protection_cable_s_1 } = this.form;
            return gaine_de_protection_cable_s_1?.startsWith('NOK');
        },
        condition_commentaire_gaine_de_protection_cable_s_1(): boolean {
            const { gaine_de_protection_cable_s_1 } = this.form;
            return gaine_de_protection_cable_s_1?.startsWith('NOK');
        },
        condition_img_autres_observations(): boolean {
            const { autres_observations } = this.form;
            return autres_observations === 'OUI';
        },
        condition_commentaire_autres_observations(): boolean {
            const { autres_observations } = this.form;
            return autres_observations === 'OUI';
        },
    },
});
</script>
