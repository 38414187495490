import * as Sentry from '@sentry/vue';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { AxiosError } from 'axios';

import * as AuthApi from '@connect-field/client/services/api/auth';
import ApiError from '@connect-field/client/services/apiError.service';
import HTTP from '@connect-field/client/utilities/http';
import { removeLastActionLocalStorage } from '@connect-field/client/services/form.service';
import { resetAllStores } from '@connect-field/client/services/stores.service';
import useUserStore from '@connect-field/client/stores/user';

export interface UserInterface {
    token: string;
    userEmail: string;
    userId: number;
    userName: string;
}

export interface StateInterface {
    loggedIn: boolean;
    token: string | null;
    tokenExpiration: null;
    userId: string | number | null;
}

const useAuthStore = defineStore('auth', {
    actions: {
        async login(payload: { email: string; password: string }) {
            try {
                const user = await AuthApi.postAuthLogin(payload.email, payload.password);
                this.saveUser(user);
            } catch (error: unknown) {
                console.error(error);
                if (error instanceof AxiosError) {
                    if (error?.response?.data) {
                        throw new ApiError(error.response.data);
                    }
                }
                throw error;
            }
        },
        async loginLDAP(payload: { email: string; password: string }) {
            try {
                const user = await AuthApi.postAuthLoginLdap(payload.email, payload.password);
                this.saveUser(user);
            } catch (error: unknown) {
                console.error(error);
                if (error instanceof AxiosError) {
                    if (error?.response?.data) {
                        throw new ApiError(error.response.data);
                    }
                }
                throw error;
            }
        },
        logout() {
            resetAllStores();
            removeLastActionLocalStorage();
            Sentry.configureScope((scope) => scope.setUser(null));
            localStorage.removeItem('token');
            localStorage.removeItem('userId');
            localStorage.removeItem('userName');
            localStorage.removeItem('userEmail');
            localStorage.removeItem('expirationDate');
        },
        saveUser(user: UserInterface): void {
            const { userEmail, token, userId, userName } = user;
            const userStore = useUserStore();

            localStorage.setItem('token', token);
            localStorage.setItem('userId', userId.toString());
            localStorage.setItem('userName', userName);
            localStorage.setItem('userEmail', userEmail);

            this.setUser({
                token: token,
                userId: userId,
            });

            userStore.setUser({
                userEmail: userEmail,
                userId: userId,
                userName: userName,
            });
        },
        setUser(payload: { token: string; userId: number }) {
            HTTP.setToken(payload.token);
            this.$patch({
                loggedIn: true,
                token: payload.token,
                userId: payload.userId,
            });
        },
        async signup(payload: { email: string; name: string; password: string }) {
            try {
                await AuthApi.postAuthRegister(payload.email, payload.name, payload.password);
            } catch (error: unknown) {
                console.error(error);
                if (error instanceof AxiosError) {
                    if (error?.response?.data) {
                        throw new ApiError(error.response.data);
                    }
                }
                throw error;
            }
        },
        tryLogin() {
            const userStore = useUserStore();

            const token = localStorage.getItem('token');
            const userId = localStorage.getItem('userId');
            const userName = localStorage.getItem('userName');
            const userEmail = localStorage.getItem('userEmail');
            if (token && userId && userName && userEmail) {
                this.setUser({
                    token,
                    userId: parseInt(userId),
                });

                userStore.setUser({
                    userEmail: userEmail,
                    userId: parseInt(userId),
                    userName: userName,
                });
            }
        },
    },
    state: (): StateInterface => ({
        loggedIn: false,
        token: null,
        tokenExpiration: null,
        userId: null,
    }),
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
}

export default useAuthStore;
