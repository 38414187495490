// Checks if the given object is equal to {}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isEmptyObject(obj: Record<string, any>): boolean {
    return obj && Object.keys(obj).length < 1 && Object.getPrototypeOf(obj) === Object.prototype;
}

export function isAndroidDevice(): boolean {
    return /(android)/i.test(navigator.userAgent);
}

/**
 * Here we use window.MSStream which is an old property implemented on Windows phones which fake User Agent to be detected as an iPhone
 */
export function isAppleDevice(): boolean {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
}

export function isIOS(): boolean {
    return (
        ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
            navigator.platform,
        ) ||
        // iPad on iOS 13+ detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
}

export function detectDevice(): string {
    if (isIOS()) {
        return 'ios';
    }
    if (isAndroidDevice()) {
        return 'android';
    }
    if (isAppleDevice()) {
        return 'macos';
    }

    return 'desktop';
}

export function arrayIntersect<T>(array1: Array<T>, array2: Array<T>): Array<T> {
    return array1.filter((value: T) => array2.includes(value));
}

export function isFunction(fn: unknown): fn is Function {
    return typeof fn === 'function';
}

export function isArray(arr: unknown): arr is Array<unknown> {
    return Array.isArray(arr);
}

export function isObject(obj: unknown): obj is Record<string, unknown> {
    return !Array.isArray(obj) && typeof obj === 'object';
}

export function isString(str: unknown): str is string {
    return typeof str === 'string';
}
export function isBoolean(bool: unknown): bool is boolean {
    return typeof bool === 'boolean';
}
export function isNumber(nbr: unknown): nbr is number {
    return typeof nbr === 'number';
}

export function transformProxyToObject(proxy: unknown): unknown | null | Array<unknown> | Record<string, unknown> {
    if (proxy === null || proxy === undefined) {
        return proxy;
    }
    if (isArray(proxy)) {
        return proxy.map((element: unknown) => transformProxyToObject(element));
    } else if (isObject(proxy)) {
        return Object.keys(proxy).reduce(
            (acc: Record<string, unknown>, key: string) => {
                acc[key] = transformProxyToObject(proxy[key]);

                return acc;
            },
            {} as Record<string, unknown>,
        );
    } else if (isFunction(proxy)) {
        return undefined;
    }

    return proxy;
}

export function deepCopy<T>(element: T): T {
    try {
        const stringifiedElement = JSON.stringify(element);

        return JSON.parse(stringifiedElement);
    } catch {
        return Object.assign({}, element);
    }
}

export function resetScrollValue(htmlElementIdentifier: string): void {
    const htmlElement = document.querySelector(htmlElementIdentifier);
    if (htmlElement) {
        htmlElement.scrollTo({ behavior: 'auto', left: 0, top: 0 });
    }
}

export function formatBytes(bytes: number, decimals = 2): string {
    if (bytes === 0) {
        return '0 octet';
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['octets', 'Ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function convertBufferToJSON<T>(buffer: ArrayBuffer): T {
    const decoder = new TextDecoder('utf-8');

    return JSON.parse(decoder.decode(buffer)) as T;
}

export function analyzeCondition(
    condition: 'OR' | 'AND',
    detectedValues: Array<string>,
    expectedValues: Array<string>,
): boolean {
    if (condition.toUpperCase() === 'AND') {
        return expectedValues.every((operand: string) => {
            return detectedValues.includes(operand);
        });
    }

    return expectedValues.some((operand: string) => {
        return detectedValues.includes(operand);
    });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function sortObject<T extends Record<string, any>>(object: T): T {
    const sortedKeys = Object.keys(object).sort();

    return sortedKeys.reduce((newObject: T, key: keyof T) => {
        newObject[key] = object[key];

        return newObject;
    }, {} as T);
}

export type RecordOfArrays<K extends string, T> = { [Key in K]: Array<T> };

export function sortArrayInObject<T>(
    obj: RecordOfArrays<string, T>,
    sortFn: (first: T, second: T) => number,
): RecordOfArrays<string, T> {
    const sortedKeys = Object.keys(obj).sort();

    return sortedKeys.reduce((newObject: RecordOfArrays<string, T>, key: keyof RecordOfArrays<string, T>) => {
        newObject[key] = obj[key].sort(sortFn);

        return newObject;
    }, {});
}
