<template>
    <FormWrapper
        v-if="!loading"
        ref="customFormRef"
        :images-model="images"
        :form-model="form"
        :mandatory-fields="mandatoryFields"
        :changes-model="changes"
        :layer-name="layerName"
        :object-id="objectId"
        :project-id="projectId"
        :latitude="latitude"
        :longitude="longitude"
        @update:images-model="
            (e) => {
                images = e;
            }
        "
        @update:value-missing="
            (e) => {
                valueMissing = e;
            }
        "
        @update:form-model="
            (e) => {
                form = e;
            }
        "
        @update:changes-model="
            (e) => {
                changes = e;
            }
        "
    >
        <template #menu_tabs> </template>
        <template #main_form>
            <el-row>
                <el-col :span="24">
                    <CustomInput
                        :label="labels.tirimb_code"
                        :value="form.tirimb_code"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.tirimb_ptech_code"
                        :value="form.tirimb_ptech_code"
                        :maxlength="255"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.tirimb_cable_code"
                        :value="form.tirimb_cable_code"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.tirimb_cable_capa"
                        :value="form.tirimb_cable_capa"
                        :maxlength="255"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.tirimb_cable_phase"
                        :value="form.tirimb_cable_phase"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.tirimb_ebp_code"
                        :value="form.tirimb_ebp_code"
                        :maxlength="255"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.tirimb_ebp_type"
                        :value="form.tirimb_ebp_type"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.tirimb_conv_imb"
                        :value="form.tirimb_conv_imb"
                        :maxlength="255"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.tirimb_fai"
                        :value="form.tirimb_fai"
                        @update:value="
                            (newValue) => saveData('tirimb_fai', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.tirimb_dta"
                        :value="form.tirimb_dta"
                        :maxlength="255"
                        @update:value="
                            (newValue) => saveData('tirimb_dta', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.tirimb_dti"
                        :value="form.tirimb_dti"
                        @update:value="
                            (newValue) => saveData('tirimb_dti', newValue)
                        "
                    />
                    <CustomDatePicker
                        :label="labels.date_pose"
                        :value="form.date_pose"
                        :disabled="true"
                    />
                    <InputCamera
                        :label="labels.img_environnement"
                        :images="images.img_environnement"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_environnement', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.tirimb_ri"
                        :options="options.tirimb_ri"
                        :value="form.tirimb_ri"
                        @update:value="
                            (newValue) => saveData('tirimb_ri', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.tirimb_chb_prop"
                        :options="options.tirimb_chb_prop"
                        :value="form.tirimb_chb_prop"
                        @update:value="
                            (newValue) => saveData('tirimb_chb_prop', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.tirimb_chb_tmp"
                        :options="options.tirimb_chb_tmp"
                        :value="form.tirimb_chb_tmp"
                        @update:value="
                            (newValue) => saveData('tirimb_chb_tmp', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.tirimb_chb_obt"
                        :options="options.tirimb_chb_obt"
                        :value="form.tirimb_chb_obt"
                        @update:value="
                            (newValue) => saveData('tirimb_chb_obt', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.tirimb_cbl_acc"
                        :options="options.tirimb_cbl_acc"
                        :value="form.tirimb_cbl_acc"
                        @update:value="
                            (newValue) => saveData('tirimb_cbl_acc', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.tirimb_conf_cable"
                        :options="options.tirimb_conf_cable"
                        :value="form.tirimb_conf_cable"
                        @update:value="
                            (newValue) =>
                                saveData('tirimb_conf_cable', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.tirimb_cable_glove"
                        :options="options.tirimb_cable_glove"
                        :value="form.tirimb_cable_glove"
                        @update:value="
                            (newValue) =>
                                saveData('tirimb_cable_glove', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_cable"
                        :images="images.img_cable"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('img_cable', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.tirimb_etq_pot"
                        :options="options.tirimb_etq_pot"
                        :value="form.tirimb_etq_pot"
                        @update:value="
                            (newValue) => saveData('tirimb_etq_pot', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.tirimb_etq_cable"
                        :options="options.tirimb_etq_cable"
                        :value="form.tirimb_etq_cable"
                        @update:value="
                            (newValue) => saveData('tirimb_etq_cable', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_etiquette"
                        :images="images.img_etiquette"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('img_etiquette', newValue)
                        "
                    />
                    <CustomInputTextarea
                        :label="labels.tirimb_com_global"
                        :value="form.tirimb_com_global"
                        :maxlength="255"
                        @update:value="
                            (newValue) =>
                                saveData('tirimb_com_global', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.tirimb_type_audit"
                        :options="options.tirimb_type_audit"
                        :value="form.tirimb_type_audit"
                        @update:value="
                            (newValue) =>
                                saveData('tirimb_type_audit', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.tirimb_statut_audit"
                        :options="options.tirimb_statut_audit"
                        :value="form.tirimb_statut_audit"
                        @update:value="
                            (newValue) =>
                                saveData('tirimb_statut_audit', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.tirimb_gene"
                        :options="options.tirimb_gene"
                        :value="form.tirimb_gene"
                        @update:value="
                            (newValue) => saveData('tirimb_gene', newValue)
                        "
                    />
                </el-col>
            </el-row>
        </template>
    </FormWrapper>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import {
    labels,
    options,
} from '@connect-field/client/components/forms/AIC/Travaux/t_tvx_immeuble.data';
import type { FormDataInterface } from '@connect-field/client/components/forms/form.types';
import FormTemplate from '@connect-field/client/components/forms/FormTemplate.vue';

interface DataInterface extends FormDataInterface {}

export default defineComponent({
    extends: FormTemplate,
    data(): DataInterface {
        return {
            changes: false,
            form: {},
            images: {
                img_environnement: [],
                img_cable: [],
                img_etiquette: [],
            },
            labels,
            loading: true,
            mandatoryFields: [],
            options,

            valueMissing: false,
        };
    },
    methods: {},
});
</script>
