<template>
    <div class="container auth">
        <div class="container__backdrop" />
        <main class="main">
            <p>{{ $t('logout.logoutIP') }}</p>
        </main>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import useAuthStore from '@connect-field/client/stores/auth';

export default defineComponent({
    setup() {
        return {
            authStore: useAuthStore(),
        };
    },
    mounted() {
        // Timeout on purpose to confirm the action of login out for the user
        setTimeout(() => {
            this.authStore.logout();
            this.$router.push('/auth');
        }, 2500);
    },
});
</script>

<style scoped lang="scss">
.auth {
    .container__backdrop {
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        object-fit: cover;
        background-image: url('../../assets/img/background/authentication.jpg');
        background-repeat: no-repeat;
        background-size: cover;
    }

    .main {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 1rem;
        backdrop-filter: blur(16px) saturate(180%);
        -webkit-backdrop-filter: blur(16px) saturate(180%);
        background-color: rgba(17, 25, 40, 0.75);
        color: #fff;
    }
}
</style>
