import type { OptionsInterface, SelectInterface } from '@connect-field/client/components/forms/form.types';

export const labels: Record<string, string> = {
    arrimage_gaine: 'Arrimage de la gaine de la bloolite',
    cheminement_bloolite: 'Cheminement de la bloolite (longueur suffisante, courbure)',
    commentaire_arrimage_gaine: 'Commentaire',
    commentaire_cheminement_bloolite: 'Commentaire',
    commentaire_controle_acces: 'Commentaire',
    commentaire_detubage_fibres_lovage_fibres: 'Commentaire',
    commentaire_epanouissement: 'Commentaire',
    commentaire_etiquettes_cab_ecl: 'Commentaire',
    commentaire_etiquettes_tiroir: 'Commentaire',
    commentaire_fixation_cables_entree: 'Commentaire',
    commentaire_fixation_eclateur: 'Commentaire',
    commentaire_fixation_tiroir: 'Commentaire',
    commentaire_georeferencement_nro: 'Commentaire',
    commentaire_nb_fibres_transport: 'Commentaire',
    commentaire_nb_u_correct: 'Commentaire',
    commentaire_ouverture_libre_tiroirs: 'Commentaire',
    commentaire_presence_bouchons: 'Commentaire',
    commentaire_presence_gaine_annelee: 'Commentaire',
    commentaire_proprete: 'Commentaire',
    commentaire_verification_position_tiroirs: 'Commentaire',
    controle_acces: "Contrôle d'accès",
    detubage_fibres_lovage_fibres: 'Détubage des fibres et lovages des fibres',
    donnees_metier: 'Affichage des données métier',
    epanouissement: 'Epanouissement (bloolite fixée et assez longue)',
    etiquettes_cab_ecl: 'Etiquettes (Présentes, libres et justes)',
    etiquettes_tiroir: 'Etiquettes (Présentes, libres et justes)',
    fixation_cables_entree: 'Fixation des câbles en entrée',
    fixation_eclateur: "Fixation de l'éclateur",
    fixation_tiroir: 'Fixation (Partie fixe + Charnière)',
    georeferencement_nro: 'Géoréférencement du NRO',
    img_arrimage_gaine: 'Photos de quelques tiroirs, surtout si non-conformité',
    img_cheminement_bloolite: "Photo  du cheminement en fond d'armoire",
    img_controle_acces: 'Photo',
    img_detubage_fibres_lovage_fibres: 'Photos de quelques tiroirs, surtout si non-conformité',
    img_epanouissement: 'Photo',
    img_etiquettes_cab_ecl: 'Photo',
    img_etiquettes_tiroir: 'Photo',
    img_fixation_cables_entree: 'Photo',
    img_fixation_eclateur: 'Photo éclateur fermé',
    img_fixation_tiroir: 'Photos des vis de fixation',
    img_georeferencement_nro: 'Photo de loin, dans le contexte',
    img_nb_u_correct: 'Photo',
    img_ouverture_libre_tiroirs: 'Photo  de face tous les tiroirs ouverts',
    img_parcours_cables_transport: 'Photos  le long du parcours dans le NRO',
    img_presence_bouchons: 'Photos de quelques tiroirs, surtout si non-conformité',
    img_presence_gaine_annelee: 'Photo éclateur ouvert',
    img_proprete: "Photo  globable de l'intérieur",
    img_verification_position_tiroirs: 'Photo  globale des tiroirs de distri',
    nb_u_correct: 'Nb U correct',
    ouverture_libre_tiroirs: 'Ouverture libre des tiroirs (Absence de frottements)',
    parcours_cables_transport: 'Parcours des câbles de transport (fixation)',
    presence_bouchons: 'Présence des bouchons (Connecteurs)',
    presence_gaine_annelee: 'Présence de la gaine annelée / bloolite',
    proprete: 'Propreté',
    st_abddate: "Date d'abandon de l'objet",
    st_abdsrc: "Cause de l'abandon de l'objet",
    st_ad_code: 'Identifiant unique contenu dans la table ADRESSE',
    st_avct: "Attribut synthétisant l'avancement.",
    st_code: 'Code du site',
    st_codeext: 'Code chez un tiers',
    st_comment: 'Commentaire',
    st_creadat: "Date de création de l'objet en base",
    st_dateins: "Date d'installation",
    st_datemes: 'Date de mise en service',
    st_etat: 'Etat du site.',
    st_gest: 'Identifiant du gestionnaire',
    st_majdate: "Date de la mise à jour de l'objet en base",
    st_majsrc: 'Source utilisée pour la mise à jour',
    st_nblines: 'Nombre de lignes du site.',
    st_nd_code: 'Identifiant unique contenu dans la table Noeud',
    st_nom: 'Nom du site.',
    st_prop: 'Identifiant du propriétaire',
    st_proptyp: 'Type de propriété',
    st_statut: "Phase d'avancement",
    st_typelog: 'Type logique du site',
    st_typephy: 'Type physique du site',
    st_user: 'utilisateur du site',
    statut: 'Statut audit',
    verification_position_tiroirs: 'Vérification de la position des tiroirs',
};

export const options: Record<string, OptionsInterface | SelectInterface> = {
    arrimage_gaine: [
        { label: 'Sans objet', value: null },
        {
            label: 'Conforme',
            value: 'Conforme',
        },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    cheminement_bloolite: [
        { label: 'Sans objet', value: null },
        {
            label: 'Conforme',
            value: 'Conforme',
        },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    controle_acces: [
        { label: 'Sans objet', value: null },
        {
            label: 'Conforme',
            value: 'Conforme',
        },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    detubage_fibres_lovage_fibres: [
        { label: 'Sans objet', value: null },
        {
            label: 'Conforme',
            value: 'Conforme',
        },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    donnees_metier: { false: 'NON', true: 'OUI' },
    epanouissement: [
        { label: 'Sans objet', value: null },
        {
            label: 'Conforme',
            value: 'Conforme',
        },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    etiquettes_cab_ecl: [
        { label: 'Sans objet', value: null },
        {
            label: 'Conforme',
            value: 'Conforme',
        },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    etiquettes_tiroir: [
        { label: 'Sans objet', value: null },
        {
            label: 'Conforme',
            value: 'Conforme',
        },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    fixation_cables_entree: [
        { label: 'Sans objet', value: null },
        {
            label: 'Conforme',
            value: 'Conforme',
        },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    fixation_eclateur: [
        { label: 'Sans objet', value: null },
        {
            label: 'Conforme',
            value: 'Conforme',
        },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    fixation_tiroir: [
        { label: 'Sans objet', value: null },
        {
            label: 'Conforme',
            value: 'Conforme',
        },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    georeferencement_nro: [
        { label: 'Sans objet', value: null },
        {
            label: 'Conforme',
            value: 'Conforme',
        },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    nb_u_correct: [
        { label: 'Sans objet', value: null },
        {
            label: 'Conforme',
            value: 'Conforme',
        },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    ouverture_libre_tiroirs: [
        { label: 'Sans objet', value: null },
        {
            label: 'Conforme',
            value: 'Conforme',
        },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    parcours_cables_transport: [
        { label: 'Sans objet', value: null },
        {
            label: 'Conforme',
            value: 'Conforme',
        },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    presence_bouchons: [
        { label: 'Sans objet', value: null },
        {
            label: 'Conforme',
            value: 'Conforme',
        },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    presence_gaine_annelee: [
        { label: 'Sans objet', value: null },
        {
            label: 'Conforme',
            value: 'Conforme',
        },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    proprete: [
        { label: 'Sans objet', value: null },
        {
            label: 'Conforme',
            value: 'Conforme',
        },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    st_etat: [
        { label: 'A CHANGER', value: 'HS' },
        { label: 'MAUVAIS ETAT', value: 'ME' },
        {
            label: 'BON ETAT',
            value: 'OK',
        },
        { label: 'NON CONCERNE', value: 'NC' },
    ],
    st_proptyp: [
        { label: 'CONSTRUCTION', value: 'CST' },
        { label: 'RACHAT', value: 'RAC' },
        {
            label: 'CESSION',
            value: 'CES',
        },
        { label: 'IRU', value: 'IRU' },
        { label: 'LOCATION', value: 'LOC' },
        {
            label: 'OCCUPATION',
            value: 'OCC',
        },
    ],
    st_statut: [
        { label: 'ETUDE PRELIMINAIRE', value: 'PRE' },
        {
            label: 'ETUDE DE DIAGNOSTIC',
            value: 'DIA',
        },
        { label: 'AVANT-PROJET', value: 'AVP' },
        {
            label: 'PROJET',
            value: 'PRO',
        },
        { label: 'PASSATION DES MARCHES DE TRAVAUX', value: 'ACT' },
        {
            label: 'ETUDE D EXECUTION',
            value: 'EXE',
        },
        { label: 'TRAVAUX', value: 'TVX' },
        {
            label: 'RECOLEMENT',
            value: 'REC',
        },
        { label: 'MAINTIENT EN CONDITIONS OPERATIONNELLES', value: 'MCO' },
    ],
    statut: [
        { label: 'Audit à réaliser', value: 'NON EFFECTUE' },
        {
            label: 'Audit partiellement réalisé',
            value: 'PARTIEL',
        },
        { label: 'Audit réalisé', value: 'TERMINE' },
        { label: 'Non concerné par un audit', value: 'NON CONCERNE' },
    ],
    verification_position_tiroirs: [
        { label: 'Sans objet', value: null },
        {
            label: 'Conforme',
            value: 'Conforme',
        },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
};
