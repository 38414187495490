<template>
    <div>
        <div v-show="state === 'download'">
            <FontAwesomeIcon
                icon="cloud"
                size="2x"
            />
            <FontAwesomeIcon
                icon="download"
                class="frontIcon"
                size="sm"
            />
        </div>
        <div v-show="state === 'loading'">
            <FontAwesomeIcon
                icon="cloud"
                size="2x"
            />
            <FontAwesomeIcon
                icon="sync-alt"
                class="frontIcon"
                spin
                size="sm"
            />
        </div>
        <div v-show="state === 'synchronized'">
            <FontAwesomeIcon
                icon="cloud"
                size="2x"
            />
            <FontAwesomeIcon
                icon="check"
                class="frontIcon"
                size="sm"
            />
        </div>
        <div v-show="state === 'needUpdate'">
            <FontAwesomeIcon
                icon="cloud"
                size="2x"
            />
            <FontAwesomeIcon
                icon="sync-alt"
                class="frontIcon"
                size="sm"
            />
            <FontAwesomeIcon
                icon="circle"
                class="frontIcon update"
                size="lg"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        state: {
            type: String,
            default: 'download',
        },
    },
});
</script>

<style scoped lang="scss">
div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.5rem;
    position: relative;
}

svg {
    color: #fff;
}

.frontIcon {
    color: var(--color-primary);
    position: absolute;
    margin-top: 0.1rem;
}

.update {
    color: var(--hot-pink);
    margin-bottom: 1rem;
    margin-left: 1rem;
}
</style>
