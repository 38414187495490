/*
Use store to save panel
 */

import { acceptHMRUpdate, defineStore } from 'pinia';

export type PanelStateType = 'fully-opened' | 'half-opened' | 'closed';

const MIN_HEIGHT = '90px';

export interface StateInterface {
    isPanelResizable: boolean;
    panelHeight: string;
    panelLastState: string | null;
    panelMinHeight: string;
    panelState: PanelStateType;
    panelStateClosed: PanelStateType;
    panelStateHalfOpened: PanelStateType;
    panelStateOpened: PanelStateType;
}

const usePanelStore = defineStore('panel', {
    actions: {
        getHalfHeight() {
            return `${this.getMaxHeight() / 2}px`;
        },
        getMaxHeight() {
            return +window.innerHeight;
        },
        setPanelClosed() {
            if (!this.isPanelResizable) {
                return;
            }
            this.panelLastState = this.panelState;
            this.panelHeight = MIN_HEIGHT;
            this.panelState = 'closed';
        },
        setPanelFullyOpened() {
            if (!this.isPanelResizable) {
                return;
            }
            this.panelLastState = this.panelState;
            this.panelHeight = `${this.getMaxHeight()}px`;
            this.panelState = 'fully-opened';
        },
        setPanelHalfOpened() {
            if (!this.isPanelResizable) {
                return;
            }
            this.panelLastState = this.panelState;
            this.panelHeight = this.getHalfHeight();
            this.panelState = 'half-opened';
        },
        setPanelHeight(height: string) {
            if (!this.isPanelResizable) {
                return;
            }
            this.panelHeight = height;
        },
        setPanelLastState(panelState: PanelStateType) {
            if (!this.isPanelResizable) {
                return;
            }
            this.panelLastState = panelState;
        },
        setPanelNewHeight() {
            if (!this.isPanelResizable) {
                return;
            }

            switch (this.panelState) {
                case 'closed':
                    this.panelHeight = MIN_HEIGHT;
                    break;
                case 'half-opened':
                    this.panelHeight = this.getHalfHeight();
                    break;
                case 'fully-opened':
                    this.panelHeight = `${this.getMaxHeight()}px`;
            }
        },
        setPanelState(panelState: PanelStateType) {
            if (!this.isPanelResizable) {
                return;
            }
            this.panelState = panelState;
        },
    },
    state(): StateInterface {
        return {
            isPanelResizable: true,
            panelHeight: `${+window.innerHeight / 2}px`,
            panelLastState: null,
            panelMinHeight: MIN_HEIGHT,
            panelState: 'half-opened',
            panelStateClosed: 'closed',
            panelStateHalfOpened: 'half-opened',
            panelStateOpened: 'fully-opened',
        };
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(usePanelStore, import.meta.hot));
}

export default usePanelStore;
