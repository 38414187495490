import type { CustomRecordRoute } from '@connect-field/client/services/formRouter.service';

import AIC_Audit_Securite from '@connect-field/client/components/forms/AIC/Audit/t_audit_securite.vue';
import AIC_Levee_Reserve from '@connect-field/client/components/forms/AIC/SST/Levee_Reserve.vue';
import AIC_QR_Boite from '@connect-field/client/components/forms/AIC/QR/t_qr_boite.vue';
import AIC_QR_NRO from '@connect-field/client/components/forms/AIC/QR/t_qr_nro.vue';
import AIC_QR_SRO from '@connect-field/client/components/forms/AIC/QR/t_qr_sro.vue';
import AIC_Tirage_Aerien from '@connect-field/client/components/forms/AIC/Travaux/t_tvx_aerien.vue';
import AIC_Tirage_Aerien_Du from '@connect-field/client/components/forms/AIC/Travaux/t_tvx_aerien_du.vue';
import AIC_Tirage_Boite from '@connect-field/client/components/forms/AIC/Travaux/t_tvx_boite.vue';
import AIC_Tirage_Facade from '@connect-field/client/components/forms/AIC/Travaux/t_tvx_facade.vue';
import AIC_Tirage_Immeuble from '@connect-field/client/components/forms/AIC/Travaux/t_tvx_immeuble.vue';
import AIC_Tirage_Souterrain from '@connect-field/client/components/forms/AIC/Travaux/t_tvx_souterrain.vue';
import AIC_Travaux_Armoire from '@connect-field/client/components/forms/AIC/GC/t_tvx_armoire.vue';
import AIC_Travaux_Genie_Civil from '@connect-field/client/components/forms/AIC/GC/t_tvx_gc.vue';
import AIC_Travaux_Point_Technique from '@connect-field/client/components/forms/AIC/GC/t_tvx_implantation_ptech.vue';
import Demo_Boite_IA from '@connect-field/client/components/forms/demo/Boite_IA.vue';
import Demo_Formulaire_1 from '@connect-field/client/components/forms/demo/Formulaire_1.vue';
import Demo_Formulaire_2 from '@connect-field/client/components/forms/demo/Formulaire_2.vue';

import Dotic_GraceV2_BP from '@connect-field/client/components/forms/Dotic/GraceTHD-v2/BP.vue';
import Dotic_GraceV2_Chambre from '@connect-field/client/components/forms/Dotic/GraceTHD-v2/ChambreForm.vue';
import Dotic_GraceV2_NR from '@connect-field/client/components/forms/Dotic/GraceTHD-v2/NR.vue';
import Dotic_GraceV2_PB from '@connect-field/client/components/forms/Dotic/GraceTHD-v2/PB.vue';
import Dotic_GraceV2_PTO from '@connect-field/client/components/forms/Dotic/GraceTHD-v2/PTO.vue';
import Dotic_GraceV2_SR from '@connect-field/client/components/forms/Dotic/GraceTHD-v2/SR.vue';
import Dotic_INEO_Baie from '@connect-field/client/components/forms/Dotic/INEO/form_baie.vue';
import Dotic_INEO_Cable from '@connect-field/client/components/forms/Dotic/INEO/form_cable.vue';
import Dotic_INEO_EBP from '@connect-field/client/components/forms/Dotic/INEO/form_ebp.vue';
import Dotic_Ingenierie_Antenne from '@connect-field/client/components/forms/Dotic/Ingenierie/AntenneForm.vue';
import Dotic_Ingenierie_Site from '@connect-field/client/components/forms/Dotic/Ingenierie/SiteForm.vue';
import Dotic_PlanBoite from '@connect-field/client/components/forms/PlanBoite/PlanBoite.vue';
import Dotic_SIPPEREC_Armoire from '@connect-field/client/components/forms/Dotic/SIPPEREC/ReleveArmoire.vue';
import Dotic_SIPPEREC_Chambre from '@connect-field/client/components/forms/Dotic/SIPPEREC/ReleveChambre.vue';
import Dotic_Tisseo_Cable from '@connect-field/client/components/forms/Dotic/Tisseo/CableForm.vue';

import Axione_Audit_BPE_v3 from '@connect-field/client/components/forms/Axione/form_audit_bpe_v3_axione.vue';
import Axione_Audit_NRO_v3 from '@connect-field/client/components/forms/Axione/form_audit_nro_v3_axione.vue';
import Axione_Audit_PBO_v3 from '@connect-field/client/components/forms/Axione/form_audit_pbo_v3_axione.vue';
import Axione_Audit_SRO_v3 from '@connect-field/client/components/forms/Axione/form_audit_sro_v3_axione.vue';
import Axione_Creation_BPE_v3 from '@connect-field/client/components/forms/Axione/form_creation_bpe_v3_axione.vue';
import Axione_Creation_NRO_v3 from '@connect-field/client/components/forms/Axione/form_creation_nro_v3_axione.vue';
import Axione_Creation_PBO_v3 from '@connect-field/client/components/forms/Axione/form_creation_pbo_v3_axione.vue';
import Axione_Creation_SRO_v3 from '@connect-field/client/components/forms/Axione/form_creation_sro_v3_axione.vue';

import StrategicScout_Audit_BPE from '@connect-field/client/components/forms/StrategicScout/Audit_BPE.vue';
import StrategicScout_Audit_Form_BPE from '@connect-field/client/components/forms/StrategicScout/audit_form_bpe.vue';
import StrategicScout_Audit_Form_NRO from '@connect-field/client/components/forms/StrategicScout/audit_form_nro.vue';
import StrategicScout_Audit_Form_PBO from '@connect-field/client/components/forms/StrategicScout/audit_form_pbo.vue';
import StrategicScout_Audit_Form_PM from '@connect-field/client/components/forms/StrategicScout/audit_form_pm.vue';
import StrategicScout_Audit_NRO from '@connect-field/client/components/forms/StrategicScout/Audit_NRO.vue';
import StrategicScout_Audit_PBO from '@connect-field/client/components/forms/StrategicScout/Audit_PBO.vue';
import StrategicScout_Audit_PM from '@connect-field/client/components/forms/StrategicScout/Audit_PM.vue';
import StrategicScout_Creation_BPE from '@connect-field/client/components/forms/StrategicScout/form_creation_bpe_strategicscout.vue';
import StrategicScout_Creation_NRO from '@connect-field/client/components/forms/StrategicScout/form_creation_nro_strategicscout.vue';
import StrategicScout_Creation_PBO from '@connect-field/client/components/forms/StrategicScout/form_creation_pbo_strategicscout.vue';
import StrategicScout_Creation_PM from '@connect-field/client/components/forms/StrategicScout/form_creation_pm_strategicscout.vue';

import DrivOptic_Audit_Appui_Passage_Enedis from '@connect-field/client/components/forms/DrivOptic/form_audit_appui_passage_enedis_drivoptic.vue';
import DrivOptic_Audit_Appui_Passage_Ft from '@connect-field/client/components/forms/DrivOptic/form_audit_appui_passage_ft_drivoptic.vue';
import DrivOptic_Audit_BPE from '@connect-field/client/components/forms/DrivOptic/form_audit_bpe.vue';
import DrivOptic_Audit_BTI from '@connect-field/client/components/forms/DrivOptic/form_audit_bti_drivoptic.vue';
import DrivOptic_Audit_Chambre_Passage from '@connect-field/client/components/forms/DrivOptic/form_audit_chambre_passage_drivoptic.vue';
import DrivOptic_Audit_Chambre_Zero from '@connect-field/client/components/forms/DrivOptic/form_audit_chambre_zero_drivoptic.vue';
import DrivOptic_Audit_NRO from '@connect-field/client/components/forms/DrivOptic/form_audit_nro.vue';
import DrivOptic_Audit_PA_Appui_Enedis from '@connect-field/client/components/forms/DrivOptic/form_audit_pa_appui_enedis_drivoptic.vue';
import DrivOptic_Audit_PA_Appui_Ft from '@connect-field/client/components/forms/DrivOptic/form_audit_pa_appui_ft_drivoptic.vue';
import DrivOptic_Audit_PA_Chambre from '@connect-field/client/components/forms/DrivOptic/form_audit_pa_chambre_drivoptic.vue';
import DrivOptic_Audit_PB_Appui_Enedis from '@connect-field/client/components/forms/DrivOptic/form_audit_pb_appui_enedis_drivoptic.vue';
import DrivOptic_Audit_PB_Appui_Ft from '@connect-field/client/components/forms/DrivOptic/form_audit_pb_appui_ft_drivoptic.vue';
import DrivOptic_Audit_PB_Chambre from '@connect-field/client/components/forms/DrivOptic/form_audit_pb_chambre_drivoptic.vue';
import DrivOptic_Audit_PB_Facade from '@connect-field/client/components/forms/DrivOptic/form_audit_pb_facade_drivoptic.vue';
import DrivOptic_Audit_PB_Immeuble from '@connect-field/client/components/forms/DrivOptic/form_audit_pb_immeuble_drivoptic.vue';
import DrivOptic_Audit_PBO from '@connect-field/client/components/forms/DrivOptic/form_audit_pbo.vue';
import DrivOptic_Audit_PEP_Appui_Enedis from '@connect-field/client/components/forms/DrivOptic/form_audit_pep_appui_enedis_drivoptic.vue';
import DrivOptic_Audit_PEP_Appui_Ft from '@connect-field/client/components/forms/DrivOptic/form_audit_pep_appui_ft_drivoptic.vue';
import DrivOptic_Audit_PEP_Chambre from '@connect-field/client/components/forms/DrivOptic/form_audit_pep_chambre_drivoptic.vue';
import DrivOptic_Audit_PM from '@connect-field/client/components/forms/DrivOptic/form_audit_pm.vue';
import DrivOptic_Audit_PMZ from '@connect-field/client/components/forms/DrivOptic/form_audit_pmz_drivoptic.vue';
import DrivOptic_Creation_BPE from '@connect-field/client/components/forms/DrivOptic/form_creation_bpe.vue';
import DrivOptic_Creation_NRO from '@connect-field/client/components/forms/DrivOptic/form_creation_nro.vue';
import DrivOptic_Creation_PBO from '@connect-field/client/components/forms/DrivOptic/form_creation_pbo.vue';
import DrivOptic_Creation_PM from '@connect-field/client/components/forms/DrivOptic/form_creation_pm.vue';

import NGE_Audit_BPE from '@connect-field/client/components/forms/NGE/form_audit_bpe_nge.vue';
import NGE_Audit_NRO from '@connect-field/client/components/forms/NGE/form_audit_nro_nge.vue';
import NGE_Audit_PBO from '@connect-field/client/components/forms/NGE/form_audit_pbo_nge.vue';
import NGE_Audit_PM from '@connect-field/client/components/forms/NGE/form_audit_pm_nge.vue';
import NGE_Audit_Chambre from '@connect-field/client/components/forms/NGE/form_audit_chambre_nge.vue';
import NGE_Concept_Armoire from '@connect-field/client/components/forms/NGE/form_concept_armoire_nge.vue';
import NGE_Concept_Chambre from '@connect-field/client/components/forms/NGE/form_concept_chambre_nge.vue';
import NGE_Concept_GC from '@connect-field/client/components/forms/NGE/form_concept_gc_nge.vue';
import NGE_Concept_Poteau from '@connect-field/client/components/forms/NGE/form_concept_poteau_nge.vue';

const formRoutes: Array<CustomRecordRoute> = [
    {
        component: Demo_Formulaire_1,
        name: 'demoFormulaire1',
        path: 'demo/formulaire-1',
    },
    {
        component: Demo_Formulaire_2,
        name: 'demoFormulaire2',
        path: 'demo/formulaire-2',
    },
    {
        component: Demo_Boite_IA,
        name: 'demoBoiteIA',
        path: 'demo/boite-ia',
    },
    {
        component: AIC_QR_Boite,
        name: 'boxFormNetwork',
        path: 'qr-boite',
    },
    {
        component: AIC_QR_SRO,
        name: 'sroForm',
        path: 'qr-sro',
    },
    {
        component: AIC_QR_NRO,
        name: 'nroForm',
        path: 'qr-nro',
    },
    {
        component: Dotic_GraceV2_SR,
        name: 'sroGraceV2',
        path: 'sro',
    },
    {
        component: Dotic_GraceV2_SR,
        name: 'srolGraceV2',
        path: 'srol',
    },
    {
        component: Dotic_GraceV2_NR,
        name: 'nroGraceV2',
        path: 'nro',
    },
    {
        component: Dotic_GraceV2_NR,
        name: 'nraGraceV2',
        path: 'nra',
    },
    {
        component: Dotic_GraceV2_PB,
        name: 'pbGraceV2',
        path: 'pb',
    },
    {
        component: Dotic_GraceV2_BP,
        name: 'bpGraceV2',
        path: 'bp',
    },
    {
        component: Dotic_GraceV2_PTO,
        name: 'ptoGraceV2',
        path: 'pto',
    },
    {
        component: Dotic_GraceV2_Chambre,
        name: 'chambreGraceV2',
        path: 'chambre',
    },
    {
        component: AIC_Tirage_Aerien,
        name: 'aerialForm',
        path: 'tirage-aerien',
    },
    {
        component: AIC_Tirage_Aerien_Du,
        name: 'aerialDuForm',
        path: 'tirage-aerien-du',
    },
    {
        component: AIC_Audit_Securite,
        name: 'securityAuditForm',
        path: 'audit-securite',
    },
    {
        component: AIC_Tirage_Boite,
        name: 'boxForm',
        path: 'tirage-boite',
    },
    {
        component: AIC_Tirage_Immeuble,
        name: 'buildingForm',
        path: 'tirage-immeuble',
    },
    {
        component: AIC_Tirage_Facade,
        name: 'frontForm',
        path: 'tirage-facade',
    },
    {
        component: AIC_Tirage_Souterrain,
        name: 'undergroundForm',
        path: 'tirage-souterrain',
    },
    {
        component: AIC_Travaux_Point_Technique,
        name: 'TravauxPTForm',
        path: 'aic/travaux/point-technique',
    },
    {
        component: AIC_Travaux_Genie_Civil,
        name: 'TravauxGCForm',
        path: 'aic/travaux/genie-civil',
    },
    {
        component: AIC_Travaux_Armoire,
        name: 'TravauxArmoireForm',
        path: 'aic/travaux/armoire',
    },
    {
        component: AIC_Levee_Reserve,
        name: 'Levée de réserves',
        path: 'aic/levee-reserve',
    },
    {
        component: Dotic_Ingenierie_Antenne,
        name: 'doticIngenierieAntenneForm',
        path: 'dotic/ingenierie/antenne',
    },
    {
        component: Dotic_Ingenierie_Site,
        name: 'doticIngenierieSiteForm',
        path: 'dotic/ingenierie/site',
    },
    {
        component: Dotic_INEO_Baie,
        name: 'doticIneoFormBaie',
        path: 'dotic/ineo/baie',
    },
    {
        component: Dotic_INEO_Cable,
        name: 'doticIneoFormCable',
        path: 'dotic/ineo/cable',
    },
    {
        component: Dotic_INEO_EBP,
        name: 'doticIneoFormEBP',
        path: 'dotic/ineo/ebp',
    },
    {
        component: Dotic_Tisseo_Cable,
        name: 'doticTisseoCable',
        path: 'dotic/tisseo/cable',
    },
    {
        component: Dotic_SIPPEREC_Armoire,
        name: 'doticSipperecArmoire',
        path: 'dotic/sipperec/armoire',
    },
    {
        component: Dotic_SIPPEREC_Chambre,
        name: 'doticSipperecChambre',
        path: 'dotic/sipperec/chambre',
    },
    {
        component: StrategicScout_Audit_BPE,
        name: 'strategicScoutAuditBPE',
        path: 'strategicscout/audit_bpe',
    },
    {
        component: StrategicScout_Audit_NRO,
        name: 'strategicScoutAuditNRO',
        path: 'strategicscout/audit_nro',
    },
    {
        component: StrategicScout_Audit_PBO,
        name: 'strategicScoutAuditPBO',
        path: 'strategicscout/audit_pbo',
    },
    {
        component: StrategicScout_Audit_PM,
        name: 'strategicScoutAuditPM',
        path: 'strategicscout/audit_pm',
    },
    {
        component: StrategicScout_Creation_BPE,
        name: 'StrategicScout_Creation_BPE',
        path: 'strategicscout/creation_bpe',
    },
    {
        component: StrategicScout_Creation_NRO,
        name: 'StrategicScout_Creation_NRO',
        path: 'strategicscout/creation_nro',
    },
    {
        component: StrategicScout_Creation_PBO,
        name: 'StrategicScout_Creation_PBO',
        path: 'strategicscout/creation_pbo',
    },
    {
        component: StrategicScout_Creation_PM,
        name: 'StrategicScout_Creation_PM',
        path: 'strategicscout/creation_pm',
    },
    {
        component: StrategicScout_Audit_Form_BPE,
        name: 'StrategicScout_Audit_Form_BPE',
        path: 'strategicscout/audit_form_bpe',
    },
    {
        component: StrategicScout_Audit_Form_PBO,
        name: 'StrategicScout_Audit_Form_PBO',
        path: 'strategicscout/audit_form_pbo',
    },
    {
        component: StrategicScout_Audit_Form_NRO,
        name: 'StrategicScout_Audit_Form_NRO',
        path: 'strategicscout/audit_form_nro',
    },
    {
        component: StrategicScout_Audit_Form_PM,
        name: 'StrategicScout_Audit_Form_PM',
        path: 'strategicscout/audit_form_pm',
    },
    {
        component: DrivOptic_Audit_Appui_Passage_Enedis,
        name: 'DrivOptic_Audit_Appui_Passage_Enedis',
        path: 'drivoptic/audit_appui_passage_enedis',
    },
    {
        component: DrivOptic_Audit_Appui_Passage_Ft,
        name: 'DrivOptic_Audit_Appui_Passage_Ft',
        path: 'drivoptic/audit_appui_passage_ft',
    },
    {
        component: DrivOptic_Audit_BPE,
        name: 'DrivOptic_Audit_BPE',
        path: 'drivoptic/audit_bpe',
    },
    {
        component: DrivOptic_Audit_NRO,
        name: 'DrivOptic_Audit_NRO',
        path: 'drivoptic/audit_nro',
    },
    {
        component: DrivOptic_Audit_PA_Appui_Enedis,
        name: 'DrivOptic_Audit_PA_Appui_Enedis',
        path: 'drivoptic/audit_pa_appui_enedis',
    },
    {
        component: DrivOptic_Audit_PA_Appui_Ft,
        name: 'DrivOptic_Audit_PA_Appui_Ft',
        path: 'drivoptic/audit_pa_appui_ft',
    },
    {
        component: DrivOptic_Audit_PA_Chambre,
        name: 'DrivOptic_Audit_PA_Chambre',
        path: 'drivoptic/audit_pa_chambre',
    },
    {
        component: DrivOptic_Audit_PB_Appui_Enedis,
        name: 'DrivOptic_Audit_PB_Appui_Enedis',
        path: 'drivoptic/audit_pb_appui_enedis',
    },
    {
        component: DrivOptic_Audit_PB_Appui_Ft,
        name: 'DrivOptic_Audit_PB_Appui_Ft',
        path: 'drivoptic/audit_pb_appui_ft',
    },
    {
        component: DrivOptic_Audit_PB_Chambre,
        name: 'DrivOptic_Audit_PB_Chambre',
        path: 'drivoptic/audit_pb_chambre',
    },
    {
        component: DrivOptic_Audit_PB_Facade,
        name: 'DrivOptic_Audit_PB_Facade',
        path: 'drivoptic/audit_pb_facade',
    },
    {
        component: DrivOptic_Audit_PB_Immeuble,
        name: 'DrivOptic_Audit_PB_Immeuble',
        path: 'drivoptic/audit_pb_immeuble',
    },
    {
        component: DrivOptic_Audit_PBO,
        name: 'DrivOptic_Audit_PBO',
        path: 'drivoptic/audit_pbo',
    },
    {
        component: DrivOptic_Audit_PEP_Appui_Enedis,
        name: 'DrivOptic_Audit_PEP_Appui_Enedis',
        path: 'drivoptic/audit_pep_appui_enedis',
    },
    {
        component: DrivOptic_Audit_PEP_Appui_Ft,
        name: 'DrivOptic_Audit_PEP_Appui_Ft',
        path: 'drivoptic/audit_pep_appui_ft',
    },
    {
        component: DrivOptic_Audit_PEP_Chambre,
        name: 'DrivOptic_Audit_PEP_Chambre',
        path: 'drivoptic/audit_pep_chambre',
    },
    {
        component: DrivOptic_Audit_PM,
        name: 'DrivOptic_Audit_PM',
        path: 'drivoptic/audit_pm',
    },
    {
        component: DrivOptic_Audit_PMZ,
        name: 'DrivOptic_Audit_PMZ',
        path: 'drivoptic/audit_pmz',
    },
    {
        component: DrivOptic_Creation_BPE,
        name: 'DrivOptic_Creation_BPE',
        path: 'drivoptic/creation_bpe',
    },
    {
        component: DrivOptic_Audit_BTI,
        name: 'DrivOptic_Audit_BTI',
        path: 'drivoptic/audit_bti',
    },
    {
        component: DrivOptic_Audit_Chambre_Passage,
        name: 'DrivOptic_Audit_Chambre_Passage',
        path: 'drivoptic/audit_chambre_passage',
    },
    {
        component: DrivOptic_Audit_Chambre_Zero,
        name: 'DrivOptic_Audit_Chambre_Zero',
        path: 'drivoptic/audit_chambre_zero',
    },
    {
        component: DrivOptic_Creation_NRO,
        name: 'DrivOptic_Creation_NRO',
        path: 'drivoptic/creation_nro',
    },
    {
        component: DrivOptic_Creation_PBO,
        name: 'DrivOptic_Creation_PBO',
        path: 'drivoptic/creation_pbo',
    },
    {
        component: DrivOptic_Creation_PM,
        name: 'DrivOptic_Creation_PM',
        path: 'drivoptic/creation_pm',
    },
    {
        component: Dotic_PlanBoite,
        name: 'Dotic_PlanBoite',
        path: 'dotic/planboite',
    },
    {
        component: Axione_Audit_BPE_v3,
        name: 'Axione_Audit_BPE',
        path: 'axione/audit_bpe',
    },
    {
        component: Axione_Audit_NRO_v3,
        name: 'Axione_Audit_NRO',
        path: 'axione/audit_nro',
    },
    {
        component: Axione_Audit_PBO_v3,
        name: 'Axione_Audit_PBO',
        path: 'axione/audit_pbo',
    },
    {
        component: Axione_Audit_SRO_v3,
        name: 'Axione_Audit_SRO',
        path: 'axione/audit_sro',
    },
    {
        component: Axione_Creation_BPE_v3,
        name: 'Axione_Creation_BPE',
        path: 'axione/creation_bpe',
    },
    {
        component: Axione_Creation_NRO_v3,
        name: 'Axione_Creation_NRO',
        path: 'axione/creation_nro',
    },
    {
        component: Axione_Creation_PBO_v3,
        name: 'Axione_Creation_PBO',
        path: 'axione/creation_pbo',
    },
    {
        component: Axione_Creation_SRO_v3,
        name: 'Axione_Creation_SRO',
        path: 'axione/creation_sro',
    },
    {
        component: NGE_Audit_Chambre,
        name: 'NGE_Audit_Chambre',
        path: 'nge/audit_chambre',
    },
    {
        component: NGE_Audit_BPE,
        name: 'NGE_Audit_BPE',
        path: 'nge/audit_bpe',
    },
    {
        component: NGE_Audit_NRO,
        name: 'NGE_Audit_NRO',
        path: 'nge/audit_nro',
    },
    {
        component: NGE_Audit_PBO,
        name: 'NGE_Audit_PBO',
        path: 'nge/audit_pbo',
    },
    {
        component: NGE_Audit_PM,
        name: 'NGE_Audit_PM',
        path: 'nge/audit_pm',
    },
    {
        component: NGE_Concept_Armoire,
        name: 'NGE_Concept_Armoire',
        path: 'nge/concept_armoire',
    },
    {
        component: NGE_Concept_Chambre,
        name: 'NGE_Concept_Chambre',
        path: 'nge/concept_chambre',
    },
    {
        component: NGE_Concept_GC,
        name: 'NGE_Concept_GC',
        path: 'nge/concept_gc',
    },
    {
        component: NGE_Concept_Poteau,
        name: 'NGE_Concept_Poteau',
        path: 'nge/concept_poteau',
    },
];

export default formRoutes;
