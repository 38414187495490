import semver from 'semver';

import * as ClientSettingsApi from '@connect-field/client/services/api/clientSettings';

export async function isClientOutdated(): Promise<boolean> {
    const response = await ClientSettingsApi.getClientVersion();

    const clientVersion = import.meta.env.VITE_VERSION;
    const distributedVersion = response.version;

    console.debug(`[version] Local: v${clientVersion} | Distributed: v${distributedVersion}`);

    // Returns true if client version is behind the distributed version.
    return semver.lt(clientVersion, distributedVersion);
}

export async function getDownTime(): Promise<{ message: string; time: Date }> {
    const response = await ClientSettingsApi.getClientDownTime();

    return {
        ...response,
        time: new Date(response.time),
    };
}
