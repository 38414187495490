<template>
    <BaseDialog
        :title="$t('softwareDialog.choose')"
        :has-backdrop-escape="true"
        :show="isVisible"
        @close="closeDialog"
    >
        <div class="dialog-actions">
            <BaseDialogButton
                label="Google Maps"
                button-type="action"
                @click="openInGoogleMaps"
            />
            <BaseDialogButton
                label="Waze"
                button-type="action"
                @click="openInWaze"
            />
            <BaseDialogButton
                v-if="device === 'ios'"
                label="Apple Plans"
                button-type="action"
                @click="openInApplePlans"
            />
        </div>
        <template #actions>
            <div class="dialog-footer">
                <BaseDialogButton
                    :label="$t('globals.cancel')"
                    button-type="danger"
                    @click="closeDialog"
                />
            </div>
        </template>
    </BaseDialog>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';
import type { Coordinate } from 'ol/coordinate';
import { toLonLat } from 'ol/proj';

import BaseDialog from '@connect-field/client/components/ui/BaseDialog.vue';
import BaseDialogButton from '@connect-field/client/components/ui/BaseDialogButton.vue';
import { detectDevice } from '@connect-field/client/utilities/tools';

export default defineComponent({
    components: {
        BaseDialog,
        BaseDialogButton,
    },
    props: {
        coordinate: {
            type: Array as PropType<Array<number>>,
            default: () => {
                return [];
            },
        },
        formName: { type: String, default: '' },
        isVisible: { type: Boolean },
    },
    emits: ['closeDialog'],
    computed: {
        device(): string {
            return detectDevice();
        },
    },
    methods: {
        closeDialog(): void {
            this.$emit('closeDialog');
        },
        openInApplePlans(): void {
            const [lng, lat] = toLonLat(this.coordinate as Coordinate);
            this.$emit('closeDialog');
            window.open(
                `https://maps.apple.com/?ll=${lat},${lng}&z=17&q=${this.formName}`,
                '_blank',
            );
        },
        openInGoogleMaps(): void {
            const [lng, lat] = toLonLat(this.coordinate as Coordinate);
            this.$emit('closeDialog');
            if (this.device === 'android') {
                window.open(
                    `maps://maps.google.com/maps?z=17&daddr=${lat},${lng}&ll=`,
                    '_blank',
                );
            }
            window.open(
                `https://maps.google.com/maps?z=17&t=m&daddr=${lat},${lng}`,
                '_blank',
            );
        },
        openInWaze(): void {
            const [lng, lat] = toLonLat(this.coordinate as Coordinate);
            this.$emit('closeDialog');
            window.open(
                `https://www.waze.com/ul?ll=${lat}%2C${lng}&navigate=yes&zoom=17`,
                '_blank',
            );
        },
    },
});
</script>

<style scoped lang="scss">
.dialog-actions {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;

    .el-button {
        margin: 0.5rem 0;
    }
}

.dialog-footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    flex-wrap: wrap;

    .el-button {
        margin: 0.5rem 0;
    }
}
</style>
