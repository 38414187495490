{
    "apiStatus": {
        "latency": "Latence",
        "title": "Statut de l'API :"
    },
    "estimateUsage": {
        "savedObjects": "Nombres d'objets en base :",
        "storageUsed": "Stockage utilisé :"
    },
    "form": {
        "changesWillBeLost": "Les modifications seront perdues",
        "closeWithoutSaving": "Le formulaire contient des modifications non enregistrées.",
        "confirmClosing": "Confirmation de sortie",
        "confirmClosingQuestion": "Êtes vous sûr de vouloir quitter ?",
        "confirmDraft": "Confirmation de reprise du formulaire",
        "draftExists": "Le formulaire contient des modifications en attente.",
        "getDraftQuestion": "Souhaitez-vous les récupérer ?",
        "removeDraft": "Perdre les modifications"
    },
    "formCreation": {
        "currentGeolocation": "Géolocalisation actuelle :",
        "error": "La géolocalisation n'est pas activée sur votre appareil. Il n'est pas possible de créer un point.",
        "title": "Création d'un point",
        "type": "Choisissez le type de point"
    },
    "geolocationModal": {
        "iosPath": "Application Réglages > Confidentialité > Service de localisation > Sites safari > Demander la prochaine fois ou lors de mon partage.",
        "iosPath2": "Application Réglages > Safari > Position > Demander",
        "openSettingsApp": "Ouvrir l'application Réglages",
        "toActivate": "Pour activer la géolocalisation :",
        "toVerify": "Pour vérifier que la localisation pour Connect-Field n'est pas bloquée :"
    },
    "globals": {
        "back": "Retour",
        "basemaps": "Fonds de carte",
        "cancel": "Annuler",
        "close": "Fermer",
        "confirm": "Confirmer",
        "download": "Téléchargement",
        "edit": "Éditer",
        "error": "Une erreur est survenue",
        "from": "venant",
        "keep": "Conserver",
        "layer": "Couche",
        "layers": "Couches",
        "loading": "Chargement",
        "object": "objet | objets",
        "opacity": "Opacité",
        "open": "Ouvrir",
        "project": "projet",
        "save": "Sauvegarder",
        "submit": "Valider",
        "synchronisation": "Synchronisation"
    },
    "informations": {
        "downtime": {
            "title": "Une maintenance est prévue le"
        },
        "update": {
            "reload": "N'hésitez pas à recharger ConnectField pour profiter des dernières fonctionnalités.",
            "title": "Nouvelle version disponible"
        }
    },
    "inputCamera": {
        "delete": "Supprimer la photo",
        "deleteQuestion": "Voulez vous supprimer la photo ?",
        "deleteTitle": "Confirmation de suppression",
        "noIADetection": "Notre IA n'a pas détecté l'élément attendu sur cette photo.",
        "noIADetectionTitle": "Absence de détection"
    },
    "login": {
        "LDAP": "Connexion via l'annuaire d'entreprise (AD)",
        "email": "Email",
        "login": "Se connecter",
        "password": "Mot de passe",
        "register": "S'inscrire",
        "username": "Nom d'utilisateur"
    },
    "logout": {
        "logoutIP": "Déconnexion en cours..."
    },
    "notFound": {
        "error": "Désolé mais la page que vous recherchez n'existe pas..."
    },
    "objectData": {
        "error": "Erreur : Cet objet n'a pas de coordonnées",
        "loadingAddress": "Chargement de l'adresse en cours...",
        "objectSelection": "Sélection des objets"
    },
    "objectList": {
        "layersList": "Liste des couches",
        "loadingAddress": "Chargement de l'adresse en cours...",
        "loadingObjects": "Chargement des objets...",
        "noObject": "Pas d'objet sur ce point",
        "objectSelection": "Sélection des objets"
    },
    "offlineProjectItem": {
        "aboutDelete": "Vous êtes sur le point de supprimer",
        "cancelSync": "Annuler la synchronisation",
        "changesToSync": "modification à synchroniser | modifications à synchroniser",
        "definitiveAction": "Cette action est définitive.",
        "delete": "Confirmation de suppression",
        "deleteProject": "Supprimer le projet",
        "someDataAlert": "Certaines données n'ont pas été synchronisées et vont être supprimées."
    },
    "offlineProjectSyncSteps": {
        "step0": "Synchronisation des données"
    },
    "offlineSelection": {
        "areaSelection": "Sélection de l'emprise",
        "max5Zones": "Veuillez sélectionner 5 zones maximum",
        "zoneSelection": "Sélection des zones"
    },
    "projectDetail": {
        "backToProjects": "Retour vers les projets ?",
        "backToProjectsAction": "Retourner aux projets",
        "backToProjectsQuestion": "Voulez vous retourner vers la page des projets ?",
        "loadingProject": "Chargement du projet...",
        "title": "Projets"
    },
    "projectDownload": {
        "title": "Téléchargement du projet",
        "cancelDownload": "Annuler le téléchargement",
        "downloadName": "Nom du téléchargement",
        "pleaseNameIt": "Veuillez saisir un nom"
    },
    "projectDownloadSteps": {
        "step1": "Téléchargement de la configuration 1/7",
        "step2": "Téléchargement des formulaires 2/7",
        "step3": "Téléchargement des données consultables 3/7",
        "step4": "Téléchargement du fond de carte 4/7",
        "step5": "Téléchargement des données de recherche 5/7",
        "step6": "Téléchargement des images 6/7",
        "step7": "Ajout du projet en hors ligne 7/7"
    },
    "projectList": {
        "allOfflineProjects": "Tous les projets hors-ligne",
        "allProjects": "Tous les projets",
        "choose": "Choisir un projet",
        "lastProject": "Dernier projet ouvert",
        "loading": "Chargement des projets...",
        "noProjectFound": "Aucun projet trouvé..."
    },
    "restoreDialog": {
        "question": "Voulez-vous retourner aux dernières actions effectuées ?",
        "title": "Restaurer la session précédente ?"
    },
    "search": {
        "aim": "Centrer",
        "searchOnMap": "Rechercher sur la carte",
        "title": "Recherche"
    },
    "settings": {
        "debugIP": "Transfert des données de debug en cours...",
        "displayRuler": "Afficher l'outil de mesure :",
        "displayZoom": "Afficher les boutons de zoom :",
        "leaveConfirmation": "Souhaitez-vous quitter l'application ?",
        "logout": "Déconnexion",
        "sendDebug": "Envoyer des données de debug",
        "title": "Paramètres"
    },
    "softwareDialog": {
        "choose": "Choisissez l'application de votre choix",
        "title": "Ouvrir la navigation sur"
    },
    "version": {
        "build": "build :",
        "version": "Version :"
    }
}
