<template>
    <FormWrapper
        v-if="!loading"
        ref="customFormRef"
        :changes-model="changes"
        :form-model="form"
        :images-model="images"
        :latitude="latitude"
        :layer-name="layerName"
        :longitude="longitude"
        :mandatory-fields="mandatoryFields"
        :object-id="objectId"
        :project-id="projectId"
        @update:images-model="
            (e) => {
                images = e;
            }
        "
        @update:value-missing="
            (e) => {
                valueMissing = e;
            }
        "
        @update:form-model="
            (e) => {
                form = e;
            }
        "
        @update:changes-model="
            (e) => {
                changes = e;
            }
        "
    >
        <template #menu_tabs>
            <el-row class="scroll-menu">
                <el-col :span="10">
                    <div
                        :class="{ selectedTab: selectedTab === 'METIER' }"
                        class="tabs"
                        @click="selectTab('METIER')"
                    >
                        METIER
                    </div>
                </el-col>

                <el-col :span="10">
                    <div
                        :class="{ selectedTab: selectedTab === 'GENERAL' }"
                        class="tabs"
                        @click="selectTab('GENERAL')"
                    >
                        GENERAL
                    </div>
                </el-col>

                <el-col :span="10">
                    <div
                        :class="{ selectedTab: selectedTab === 'CAB/ECL' }"
                        class="tabs"
                        @click="selectTab('CAB/ECL')"
                    >
                        CAB/ECL
                    </div>
                </el-col>

                <el-col :span="10">
                    <div
                        :class="{ selectedTab: selectedTab === 'TIROIR' }"
                        class="tabs"
                        @click="selectTab('TIROIR')"
                    >
                        TIROIR
                    </div>
                </el-col>
            </el-row>
        </template>
        <template #main_form>
            <el-row>
                <el-col
                    v-show="selectedTab === 'METIER'"
                    :span="24"
                >
                    <CustomInput
                        :disabled="true"
                        :label="labels.st_code"
                        :maxlength="254"
                        :value="form.st_code"
                    />
                    <CustomInputSelect
                        :class="{ 'text-warning': valueMissing }"
                        :label="labels.statut + ' *'"
                        :options="options.statut"
                        :value="form.statut"
                        @update:value="
                            (newValue) => saveData('statut', newValue)
                        "
                    />
                    <CustomInput
                        :disabled="true"
                        :label="labels.st_nd_code"
                        :maxlength="254"
                        :value="form.st_nd_code"
                    />
                    <CustomInput
                        :disabled="true"
                        :label="labels.st_codeext"
                        :value="form.st_codeext"
                    />
                    <CustomInputSwitch
                        :label="labels.donnees_metier"
                        :options="options.donnees_metier"
                        :value="form.donnees_metier"
                        @update:value="
                            (newValue) => saveData('donnees_metier', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_st_nom()"
                        :disabled="true"
                        :label="labels.st_nom"
                        :value="form.st_nom"
                    />
                    <CustomInput
                        v-show="condition_st_prop()"
                        :disabled="true"
                        :label="labels.st_prop"
                        :maxlength="20"
                        :value="form.st_prop"
                    />
                    <CustomInput
                        v-show="condition_st_gest()"
                        :disabled="true"
                        :label="labels.st_gest"
                        :value="form.st_gest"
                    />
                    <CustomInput
                        v-show="condition_st_user()"
                        :disabled="true"
                        :label="labels.st_user"
                        :maxlength="20"
                        :value="form.st_user"
                    />
                    <CustomInputSelect
                        v-show="condition_st_proptyp()"
                        :disabled="true"
                        :label="labels.st_proptyp"
                        :options="options.st_proptyp"
                        :value="form.st_proptyp"
                    />
                    <CustomInputSelect
                        v-show="condition_st_statut()"
                        :disabled="true"
                        :label="labels.st_statut"
                        :options="options.st_statut"
                        :value="form.st_statut"
                    />
                    <CustomInputSelect
                        v-show="condition_st_etat()"
                        :disabled="true"
                        :label="labels.st_etat"
                        :options="options.st_etat"
                        :value="form.st_etat"
                    />
                    <CustomDatePicker
                        v-show="condition_st_dateins()"
                        :disabled="true"
                        :label="labels.st_dateins"
                        :value="form.st_dateins"
                    />
                    <CustomDatePicker
                        v-show="condition_st_datemes()"
                        :disabled="true"
                        :label="labels.st_datemes"
                        :value="form.st_datemes"
                    />
                    <CustomInput
                        v-show="condition_st_avct()"
                        :disabled="true"
                        :label="labels.st_avct"
                        :maxlength="1"
                        :value="form.st_avct"
                    />
                    <CustomInput
                        v-show="condition_st_typephy()"
                        :disabled="true"
                        :label="labels.st_typephy"
                        :value="form.st_typephy"
                    />
                    <CustomInput
                        v-show="condition_st_typelog()"
                        :disabled="true"
                        :label="labels.st_typelog"
                        :maxlength="10"
                        :value="form.st_typelog"
                    />
                    <InputNumber
                        v-show="condition_st_nblines()"
                        :disabled="true"
                        :label="labels.st_nblines"
                        :value="form.st_nblines"
                    />
                    <CustomInput
                        v-show="condition_st_ad_code()"
                        :disabled="true"
                        :label="labels.st_ad_code"
                        :maxlength="254"
                        :value="form.st_ad_code"
                    />
                    <CustomInput
                        v-show="condition_st_comment()"
                        :disabled="true"
                        :label="labels.st_comment"
                        :value="form.st_comment"
                    />
                    <CustomDatePicker
                        v-show="condition_st_creadat()"
                        :disabled="true"
                        :label="labels.st_creadat"
                        :value="form.st_creadat"
                    />
                    <CustomDatePicker
                        v-show="condition_st_majdate()"
                        :disabled="true"
                        :label="labels.st_majdate"
                        :value="form.st_majdate"
                    />
                    <CustomInput
                        v-show="condition_st_majsrc()"
                        :disabled="true"
                        :label="labels.st_majsrc"
                        :maxlength="254"
                        :value="form.st_majsrc"
                    />
                    <CustomDatePicker
                        v-show="condition_st_abddate()"
                        :disabled="true"
                        :label="labels.st_abddate"
                        :value="form.st_abddate"
                    />
                    <CustomInput
                        v-show="condition_st_abdsrc()"
                        :disabled="true"
                        :label="labels.st_abdsrc"
                        :maxlength="254"
                        :value="form.st_abdsrc"
                    />
                </el-col>
                <el-col
                    v-show="selectedTab === 'GENERAL'"
                    :span="24"
                >
                    <CustomRadioButton
                        :label="labels.georeferencement_pm"
                        :options="options.georeferencement_pm"
                        :value="form.georeferencement_pm"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('georeferencement_pm', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.img_georeferencement_pm"
                        :label="labels.img_georeferencement_pm + ' *'"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_georeferencement_pm', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_georeferencement_pm"
                        :maxlength="254"
                        :value="form.commentaire_georeferencement_pm"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_georeferencement_pm',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.etiquette_armoire"
                        :options="options.etiquette_armoire"
                        :value="form.etiquette_armoire"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('etiquette_armoire', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.img_etiquette_armoire"
                        :label="labels.img_etiquette_armoire + ' *'"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_etiquette_armoire', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_etiquette_armoire"
                        :maxlength="254"
                        :value="form.commentaire_etiquette_armoire"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_etiquette_armoire',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.proprete"
                        :options="options.proprete"
                        :value="form.proprete"
                        size="small"
                        @update:value="
                            (newValue) => saveData('proprete', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.img_proprete"
                        :label="labels.img_proprete + ' *'"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('img_proprete', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_proprete"
                        :maxlength="254"
                        :value="form.commentaire_proprete"
                        @update:value="
                            (newValue) =>
                                saveData('commentaire_proprete', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.nb_u_correct"
                        :options="options.nb_u_correct"
                        :value="form.nb_u_correct"
                        size="small"
                        @update:value="
                            (newValue) => saveData('nb_u_correct', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.img_nb_u_correct"
                        :label="labels.img_nb_u_correct"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_nb_u_correct', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_nb_u_correct"
                        :maxlength="254"
                        :value="form.commentaire_nb_u_correct"
                        @update:value="
                            (newValue) =>
                                saveData('commentaire_nb_u_correct', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.obturation_fourreaux_site"
                        :options="options.obturation_fourreaux_site"
                        :value="form.obturation_fourreaux_site"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('obturation_fourreaux_site', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.img_obturation_fourreaux_site"
                        :label="labels.img_obturation_fourreaux_site + ' *'"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage(
                                    'img_obturation_fourreaux_site',
                                    newValue,
                                )
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_obturation_fourreaux_site"
                        :maxlength="254"
                        :value="form.commentaire_obturation_fourreaux_site"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_obturation_fourreaux_site',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.controle_acces"
                        :options="options.controle_acces"
                        :value="form.controle_acces"
                        size="small"
                        @update:value="
                            (newValue) => saveData('controle_acces', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.img_controle_acces"
                        :label="labels.img_controle_acces + ' *'"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_controle_acces', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_controle_acces"
                        :maxlength="254"
                        :value="form.commentaire_controle_acces"
                        @update:value="
                            (newValue) =>
                                saveData('commentaire_controle_acces', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.presence_consigne_brassage"
                        :options="options.presence_consigne_brassage"
                        :value="form.presence_consigne_brassage"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('presence_consigne_brassage', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.img_presence_consigne_brassage"
                        :label="labels.img_presence_consigne_brassage + ' *'"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage(
                                    'img_presence_consigne_brassage',
                                    newValue,
                                )
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_presence_consigne_brassage"
                        :maxlength="254"
                        :value="form.commentaire_presence_consigne_brassage"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_presence_consigne_brassage',
                                    newValue,
                                )
                        "
                    />
                    <CustomInputSelect
                        :label="labels.type_brassage"
                        :options="options.type_brassage"
                        :value="form.type_brassage"
                        @update:value="
                            (newValue) => saveData('type_brassage', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.img_type_brassage"
                        :label="labels.img_type_brassage"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_type_brassage', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_type_brassage"
                        :maxlength="254"
                        :value="form.commentaire_type_brassage"
                        @update:value="
                            (newValue) =>
                                saveData('commentaire_type_brassage', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.brassage_correct"
                        :options="options.brassage_correct"
                        :value="form.brassage_correct"
                        size="small"
                        @update:value="
                            (newValue) => saveData('brassage_correct', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.img_brassage_correct"
                        :label="labels.img_brassage_correct + ' *'"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_brassage_correct', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_brassage_correct"
                        :maxlength="254"
                        :value="form.commentaire_brassage_correct"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_brassage_correct',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.autre_general"
                        :options="options.autre_general"
                        :value="form.autre_general"
                        size="small"
                        @update:value="
                            (newValue) => saveData('autre_general', newValue)
                        "
                    />

                    <div v-show="condition_img_autre_general()">
                        <InputCamera
                            :images="images.img_autre_general"
                            :label="labels.img_autre_general + ' *'"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage('img_autre_general', newValue)
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="condition_commentaire_autre_general()"
                        :label="labels.commentaire_autre_general"
                        :maxlength="254"
                        :value="form.commentaire_autre_general"
                        @update:value="
                            (newValue) =>
                                saveData('commentaire_autre_general', newValue)
                        "
                    />
                </el-col>
                <el-col
                    v-show="selectedTab === 'CAB/ECL'"
                    :span="24"
                >
                    <CustomRadioButton
                        :label="labels.etiquettes_cab_ecl"
                        :options="options.etiquettes_cab_ecl"
                        :value="form.etiquettes_cab_ecl"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('etiquettes_cab_ecl', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.img_etiquettes_cab_ecl"
                        :label="labels.img_etiquettes_cab_ecl + ' *'"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_etiquettes_cab_ecl', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_etiquettes_cab_ecl"
                        :maxlength="254"
                        :value="form.commentaire_etiquettes_cab_ecl"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_etiquettes_cab_ecl',
                                    newValue,
                                )
                        "
                    />
                    <CustomInputSelect
                        :label="labels.nb_fibres_transport"
                        :options="options.nb_fibres_transport"
                        :value="form.nb_fibres_transport"
                        @update:value="
                            (newValue) =>
                                saveData('nb_fibres_transport', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.img_nb_fibres_transport"
                        :label="labels.img_nb_fibres_transport + ' *'"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_nb_fibres_transport', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_nb_fibres_transport"
                        :maxlength="254"
                        :value="form.commentaire_nb_fibres_transport"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_nb_fibres_transport',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.fixation_eclateur"
                        :options="options.fixation_eclateur"
                        :value="form.fixation_eclateur"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('fixation_eclateur', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.img_fixation_eclateur"
                        :label="labels.img_fixation_eclateur + ' *'"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_fixation_eclateur', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_fixation_eclateur"
                        :maxlength="254"
                        :value="form.commentaire_fixation_eclateur"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_fixation_eclateur',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.presence_gaine_annelee"
                        :options="options.presence_gaine_annelee"
                        :value="form.presence_gaine_annelee"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('presence_gaine_annelee', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.img_presence_gaine_annelee"
                        :label="labels.img_presence_gaine_annelee + ' *'"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage(
                                    'img_presence_gaine_annelee',
                                    newValue,
                                )
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_presence_gaine_annelee"
                        :maxlength="254"
                        :value="form.commentaire_presence_gaine_annelee"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_presence_gaine_annelee',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.epanouissement"
                        :options="options.epanouissement"
                        :value="form.epanouissement"
                        size="small"
                        @update:value="
                            (newValue) => saveData('epanouissement', newValue)
                        "
                    />

                    <div v-show="condition_img_epanouissement()">
                        <InputCamera
                            :images="images.img_epanouissement"
                            :label="labels.img_epanouissement + ' *'"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage('img_epanouissement', newValue)
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="condition_commentaire_epanouissement()"
                        :label="labels.commentaire_epanouissement"
                        :maxlength="254"
                        :value="form.commentaire_epanouissement"
                        @update:value="
                            (newValue) =>
                                saveData('commentaire_epanouissement', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.fixation_cables_entree"
                        :options="options.fixation_cables_entree"
                        :value="form.fixation_cables_entree"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('fixation_cables_entree', newValue)
                        "
                    />

                    <div v-show="condition_img_fixation_cables_entree()">
                        <InputCamera
                            :images="images.img_fixation_cables_entree"
                            :label="labels.img_fixation_cables_entree + ' *'"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage(
                                        'img_fixation_cables_entree',
                                        newValue,
                                    )
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="condition_commentaire_fixation_cables_entree()"
                        :label="labels.commentaire_fixation_cables_entree"
                        :maxlength="254"
                        :value="form.commentaire_fixation_cables_entree"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_fixation_cables_entree',
                                    newValue,
                                )
                        "
                    />
                </el-col>
                <el-col
                    v-show="selectedTab === 'TIROIR'"
                    :span="24"
                >
                    <CustomRadioButton
                        :label="labels.etiquettes_tiroir"
                        :options="options.etiquettes_tiroir"
                        :value="form.etiquettes_tiroir"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('etiquettes_tiroir', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.img_etiquettes_tiroir"
                        :label="labels.img_etiquettes_tiroir + ' *'"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_etiquettes_tiroir', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_etiquettes_tiroir"
                        :maxlength="254"
                        :value="form.commentaire_etiquettes_tiroir"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_etiquettes_tiroir',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.fixation_tiroir"
                        :options="options.fixation_tiroir"
                        :value="form.fixation_tiroir"
                        size="small"
                        @update:value="
                            (newValue) => saveData('fixation_tiroir', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.img_fixation_tiroir"
                        :label="labels.img_fixation_tiroir + ' *'"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_fixation_tiroir', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_fixation_tiroir"
                        :maxlength="254"
                        :value="form.commentaire_fixation_tiroir"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_fixation_tiroir',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.verification_position_tiroirs"
                        :options="options.verification_position_tiroirs"
                        :value="form.verification_position_tiroirs"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'verification_position_tiroirs',
                                    newValue,
                                )
                        "
                    />
                    <InputCamera
                        :images="images.img_verification_position_tiroirs"
                        :label="labels.img_verification_position_tiroirs + ' *'"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage(
                                    'img_verification_position_tiroirs',
                                    newValue,
                                )
                        "
                    />
                    <CustomInput
                        :label="
                            labels.commentaire_verification_position_tiroirs
                        "
                        :maxlength="254"
                        :value="form.commentaire_verification_position_tiroirs"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_verification_position_tiroirs',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.detubage_fibres_lovage_fibres"
                        :options="options.detubage_fibres_lovage_fibres"
                        :value="form.detubage_fibres_lovage_fibres"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'detubage_fibres_lovage_fibres',
                                    newValue,
                                )
                        "
                    />
                    <InputCamera
                        :images="images.img_detubage_fibres_lovage_fibres"
                        :label="labels.img_detubage_fibres_lovage_fibres + ' *'"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage(
                                    'img_detubage_fibres_lovage_fibres',
                                    newValue,
                                )
                        "
                    />
                    <CustomInput
                        :label="
                            labels.commentaire_detubage_fibres_lovage_fibres
                        "
                        :maxlength="254"
                        :value="form.commentaire_detubage_fibres_lovage_fibres"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_detubage_fibres_lovage_fibres',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.presence_bouchons"
                        :options="options.presence_bouchons"
                        :value="form.presence_bouchons"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('presence_bouchons', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.img_presence_bouchons"
                        :label="labels.img_presence_bouchons + ' *'"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_presence_bouchons', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_presence_bouchons"
                        :maxlength="254"
                        :value="form.commentaire_presence_bouchons"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_presence_bouchons',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.arrimage_gaine"
                        :options="options.arrimage_gaine"
                        :value="form.arrimage_gaine"
                        size="small"
                        @update:value="
                            (newValue) => saveData('arrimage_gaine', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.img_arrimage_gaine"
                        :label="labels.img_arrimage_gaine + ' *'"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_arrimage_gaine', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_arrimage_gaine"
                        :maxlength="254"
                        :value="form.commentaire_arrimage_gaine"
                        @update:value="
                            (newValue) =>
                                saveData('commentaire_arrimage_gaine', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.ouverture_libre_tiroirs"
                        :options="options.ouverture_libre_tiroirs"
                        :value="form.ouverture_libre_tiroirs"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('ouverture_libre_tiroirs', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.img_ouverture_libre_tiroirs"
                        :label="labels.img_ouverture_libre_tiroirs + ' *'"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage(
                                    'img_ouverture_libre_tiroirs',
                                    newValue,
                                )
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_ouverture_libre_tiroirs"
                        :maxlength="254"
                        :value="form.commentaire_ouverture_libre_tiroirs"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_ouverture_libre_tiroirs',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.cheminement_bloolite"
                        :options="options.cheminement_bloolite"
                        :value="form.cheminement_bloolite"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('cheminement_bloolite', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.img_cheminement_bloolite"
                        :label="labels.img_cheminement_bloolite + ' *'"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_cheminement_bloolite', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_cheminement_bloolite"
                        :maxlength="254"
                        :value="form.commentaire_cheminement_bloolite"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_cheminement_bloolite',
                                    newValue,
                                )
                        "
                    />
                </el-col>
            </el-row>
        </template>
    </FormWrapper>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import {
    labels,
    options,
} from '@connect-field/client/components/forms/NGE/form_audit_pm_nge.data';
import type { FormDataInterface } from '@connect-field/client/components/forms/form.types';
import FormTemplate from '@connect-field/client/components/forms/FormTemplate.vue';

interface DataInterface extends FormDataInterface {}

export default defineComponent({
    extends: FormTemplate,
    data(): DataInterface {
        return {
            changes: false,
            form: {},
            images: {
                img_georeferencement_pm: [],
                img_etiquette_armoire: [],
                img_proprete: [],
                img_nb_u_correct: [],
                img_obturation_fourreaux_site: [],
                img_controle_acces: [],
                img_presence_consigne_brassage: [],
                img_type_brassage: [],
                img_brassage_correct: [],
                img_autre_general: [],
                img_etiquettes_cab_ecl: [],
                img_nb_fibres_transport: [],
                img_fixation_eclateur: [],
                img_presence_gaine_annelee: [],
                img_epanouissement: [],
                img_fixation_cables_entree: [],
                img_etiquettes_tiroir: [],
                img_fixation_tiroir: [],
                img_verification_position_tiroirs: [],
                img_detubage_fibres_lovage_fibres: [],
                img_presence_bouchons: [],
                img_arrimage_gaine: [],
                img_ouverture_libre_tiroirs: [],
                img_cheminement_bloolite: [],
            },
            labels,
            loading: true,
            mandatoryFields: [
                'statut',
                'img_georeferencement_pm',
                'img_etiquette_armoire',
                'img_proprete',
                'img_obturation_fourreaux_site',
                'img_controle_acces',
                'img_presence_consigne_brassage',
                'img_brassage_correct',
                'img_autre_general',
                'img_etiquettes_cab_ecl',
                'img_nb_fibres_transport',
                'img_fixation_eclateur',
                'img_presence_gaine_annelee',
                'img_epanouissement',
                'img_fixation_cables_entree',
                'img_etiquettes_tiroir',
                'img_fixation_tiroir',
                'img_verification_position_tiroirs',
                'img_detubage_fibres_lovage_fibres',
                'img_presence_bouchons',
                'img_arrimage_gaine',
                'img_ouverture_libre_tiroirs',
                'img_cheminement_bloolite',
            ],
            options,
            selectedTab: 'METIER',
            valueMissing: false,
        };
    },
    methods: {
        condition_st_nom(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === true;
        },
        condition_st_prop(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === true;
        },
        condition_st_gest(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === true;
        },
        condition_st_user(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === true;
        },
        condition_st_proptyp(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === true;
        },
        condition_st_statut(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === true;
        },
        condition_st_etat(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === true;
        },
        condition_st_dateins(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === true;
        },
        condition_st_datemes(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === true;
        },
        condition_st_avct(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === true;
        },
        condition_st_typephy(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === true;
        },
        condition_st_typelog(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === true;
        },
        condition_st_nblines(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === true;
        },
        condition_st_ad_code(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === true;
        },
        condition_st_comment(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === true;
        },
        condition_st_creadat(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === true;
        },
        condition_st_majdate(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === true;
        },
        condition_st_majsrc(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === true;
        },
        condition_st_abddate(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === true;
        },
        condition_st_abdsrc(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === true;
        },
        condition_img_autre_general(): boolean {
            const { autre_general } = this.form;
            return autre_general?.startsWith('Non-conforme');
        },
        condition_commentaire_autre_general(): boolean {
            const { autre_general } = this.form;
            return autre_general?.startsWith('Non-conforme');
        },
        condition_img_epanouissement(): boolean {
            const { epanouissement } = this.form;
            return epanouissement?.startsWith('Non-conforme');
        },
        condition_commentaire_epanouissement(): boolean {
            const { epanouissement } = this.form;
            return epanouissement?.startsWith('Non-conforme');
        },
        condition_img_fixation_cables_entree(): boolean {
            const { fixation_cables_entree } = this.form;
            return fixation_cables_entree?.startsWith('Non-conforme');
        },
        condition_commentaire_fixation_cables_entree(): boolean {
            const { fixation_cables_entree } = this.form;
            return fixation_cables_entree?.startsWith('Non-conforme');
        },
    },
});
</script>
