import type { OptionsInterface, SelectInterface } from '@connect-field/client/components/forms/form.types';

export const labels: Record<string, string> = {
    adresse: 'Adresse du NRO',
    al_acces: 'Accès site renseigné (GTR, site, clés, badges, accès routier, etc…)',
    al_adresse: 'Adresse NRO',
    al_dateconstruction: 'Date de construction renseignée',
    al_daterecette: 'Date "Recette maintenance" renseignée par QR',
    al_equip: 'Présence équipements',
    al_erdf: 'Partie ERDF renseignée',
    al_gps: 'Positionnement GPS du NRO',
    al_obs: 'Observations Altiline',
    apc_baie_active: 'Photo APC baie active',
    ba_alim: 'Câbles Alimentations Passage / État',
    ba_apc: 'APC Secouru et Non Secouru Présence / État',
    ba_capuchondwdm: 'Présence de capuchons sur TBO DWDM',
    ba_capuchontolt: 'Présence de capuchons sur TBO OLT',
    ba_connecteurdwdm: 'Connecteurs TBO DWDM Type LC-PC',
    ba_connecteurtolt: 'Connecteurs TBO OLT Type SC-APC',
    ba_disjoncteur: 'Étiquettes Disjoncteurs Présence / État / Nommage',
    ba_dwdm: 'Étiquette DWDM Présence / État / nommage',
    ba_etat_jolt: 'Jarretière TBO vers OLT Présence / État',
    ba_etatdwdm: 'Jarretière TBO vers DWDM Présence / État',
    ba_etattbo: 'État des tiroirs TBO DWDM et OLT',
    ba_etatttbo: 'Étiquette des tiroirs TBO OLT  Présence / État / Nommage',
    ba_ethernet: 'Câbles Éthernets Passage / Couleur',
    ba_etq_jolt: 'Étiquettes jarretières TBO-OLT Présence / Type / Nommage',
    ba_etq_olt: 'Étiquette OLT Présence / État / nommage',
    ba_etq_tbodwdm: 'Étiquettes jarretières TBO-DWDM Présence / Type / Nommage',
    ba_etq_tirdwdm: 'Étiquette des tiroir TBO DWDM Présence / État / Nommage',
    ba_etqalim: 'Étiquettes Câbles Alimentations Présence / État / Nommage',
    ba_etqapc: 'Étiquettes APC Présence / État / Nommage',
    ba_etqethernet: 'Étiquettes Câbles Éthernets Présence / État / Nommage',
    ba_etqnom: 'Étiquette Baie Active Nommage',
    ba_etqpe: 'Étiquette Baie Active Présence / État',
    ba_obs: 'Observations Baie Active',
    ba_porte: 'Baie active avec portes perforées avant et arrière',
    ba_position: 'Positionnement des tiroirs TBO DWDM et OLT',
    ba_type_jolt: 'Jarretière TBO vers OLT Passage / Type / Couleur',
    ba_typedwdm: 'Jarretière TBO vers DWDM Passage / Type / Couleur',
    badge_serrure: 'Photo Badge/serrure',
    baie_active: 'Photo Baie active',
    baie_energie_batteries: 'Photo Baie énergie batteries',
    baie_energie_haute: 'Photo Baie énergie vue haute',
    baiea1: 'Photo baie A1',
    branchement_jarretiere_tco: 'Photo Branchement jarretière TCO',
    camera: 'Photo Caméra',
    climatisation: 'Photo Climatisation',
    date_audit: "Date de l'audit",
    detecteur_fumee: 'Photo détecteur de fumée',
    eng_bascule: 'Baie NRJ : Bascule sur énergie secourue',
    eng_batterie: 'Baie NRJ : État visuel des batteries',
    eng_caparedresseur: 'Baie NRJ : Capacité max redresseur',
    eng_compteur: 'Logette ERDF : Etat du compteur / armoire',
    eng_disj48: 'Baie NRJ : Présence disjoncteur 48V pour chaque baie active',
    eng_disjbatterie: 'Baie NRJ : Disjoncteurs batteries Présence / État',
    eng_docbaie: 'Présence documentation Baie énergie + TGBT',
    eng_erdf_calible: 'Logette ERDF : Calibre disjoncteur (A)',
    eng_erdf_supprdiff: 'Logette ERDF : Différentiel supprimé',
    eng_floating: 'Baie NRJ : Tension de floating batteries entre 48 et 55,5V',
    eng_groupeelect: 'TGBT : Prise groupe électrogène Présence / État',
    eng_intensite: 'Baie NRJ : Intensité nominale batteries entre 5 et 15A',
    eng_mesbatterie: 'Baie NRJ : Date de mise en service batteries renseignée',
    eng_nbbatterie: 'Baie NRJ : Nombre de batteries (nécessité de 8 batteries)',
    eng_nbredresseur: 'Baie NRJ : Nombre de redresseur',
    eng_obs: 'Observations énergie',
    eng_onduleur: 'Baie NRJ : Onduleur x2 Présence / État',
    eng_parafoudre: 'TGBT : Parafoudre Présence / État',
    eng_reenclencheur: 'TGBT : Réenclencheur fonctionnel',
    eng_relevecompteur: 'Logette ERDF : Relevé du compteur',
    eng_tgbt_calibre: 'TGBT : Calibre disjoncteur (A)',
    eng_tgbt_presdiff: 'TGBT : Différentiel présent',
    etiquette_cable: 'Photo Étiquette câble',
    extincteur: 'Photo Extincteur',
    img_eclateur_cco: 'Photo Éclateur CCO',
    img_sn_fourreau: 'Photo Obturation Foureaux Chambre 0',
    logette_erdf: 'Photo Logette ERDF',
    logette_erdf_ouverte: 'Photo Logette ERDF ouverte',
    nn_adresse: 'Adresse Site NRO',
    nn_cabletco: 'Câbles raccordés au Tiroir TCO',
    nn_capatco: 'Capacité Tiroir TCO',
    nn_code: 'Code Site NRO',
    nn_codelt02: 'Code Baie LT02-A1',
    nn_codetco: 'Code Tiroir TCO',
    nn_etat: 'État en service Site NRO',
    nn_etatlt02: 'État en service Baie LT02-A1',
    nn_gestionnaire: 'Gestionnaire Site NRO',
    nn_gestionnairelt02: 'Gestionnaire Baie LT02-A1',
    nn_nom: 'Nom Site NRO',
    nn_nomlt02: 'Nom Baie LT02-A1',
    nn_nomtco: 'Nom Tiroir TCO',
    nn_obs: 'Observations NETGEO NRO',
    nn_proprietaire: 'Propriétaire Site NRO',
    nn_proprietairelt02: 'Propriétaire Baie LT02-A1',
    nn_rop: 'Réservation ROP réalisée par QR',
    nn_terrainsigtco: 'Correspondance Câblage Tiroir TCO Terrain / SIG',
    nn_typefonc: 'Type fonctionnel Site NRO',
    nn_typefonctco: 'Type fonctionnel Tiroir TCO',
    nn_typelt02: 'Type Baie LT02-A1',
    nn_typestruc: 'Type structurel Site NRO',
    nn_typestructco: 'Type structurel Tiroir TCO',
    odf_abaque: 'Abaque Présence / État',
    odf_baiea1: 'Étiquette Baie A1 Présence / État / Nommage',
    odf_capuchontbo: 'Présence de capuchons sur TBO',
    odf_capuchontco: 'Présence de capuchons sur TCO',
    odf_collecte: 'Presse-étoupes Présence / État',
    odf_colonne: 'Colonne connectée (jarretière)',
    odf_connecteurtbo: 'Connecteurs TBO Type SC-APC',
    odf_connecteurtco: 'Connecteurs TCO Type SC-APC',
    odf_eclateur_cco: 'Éclateur/bluelight CCO Fixation/État',
    odf_etatjarretiere: 'Jarretière TCO vers TBO Présence / État',
    odf_etqcco: 'Étiquette Câble CCO Présence / État / Nommage',
    odf_etqjarretiere: 'Étiquettes jarretières Présence / Type / Nommage',
    odf_etqtbo: 'Étiquette des tiroirs TBO Présence / État / Nommage',
    odf_etqtco: 'Étiquette des tiroirs TCO Présence / État / Nommage',
    odf_ligne: 'Ligne connectée (jarretière)',
    odf_lovecco: 'Lovage Câbles CCO Présence / État',
    odf_nomtco: 'Nom TCO connecté',
    odf_obs: 'Observation ODF',
    odf_positiontbo: 'Positionnement / État des tiroirs TBO',
    odf_positiontco: 'Positionnement / État des tiroirs TCO',
    odf_ritco: 'Règle ingénierie câblage TCO',
    odf_sigterraintco: 'Correspondance câblage TCO Terrain / SIG',
    odf_typejarretiere: 'Jarretière TCO vers TBO Passage / Type / Couleur',
    partie_basse_baie_active: 'Photo Partie basse baie active',
    partie_haute_baie_active: 'Photo Partie Haute baie active',
    plaque_id: 'Photo Plaque ID',
    plateaux_tco: 'Photo Plateaux TCO',
    presse_etoupe: 'Photo Presse étoupe',
    ref_nro: 'Identité du NRO',
    sa_badgeuse: 'Accès AIE : Badgeuse',
    sa_batterie: 'Remontée alarme : Passage sur batteries',
    sa_camera: 'Accès AIE : Caméra',
    sa_clim: "Remontée alarme : Extracteur d'air / climatiseur",
    sa_distance: 'Accès AIE : Ouverture à distance badgeuse',
    sa_incendie: 'Remontée alarme : Alarme incendie',
    sa_nom: 'Nom AIE test alarme',
    sa_obs: 'Observations supervision AIE',
    sa_olt: 'Accès AIE : OLT',
    sa_onduleur: 'Remontée alarme : Onduleur',
    sa_porte: 'Remontée alarme : Porte instrusion',
    sa_redresseur: 'Remontée alarme : Redresseurs',
    sa_supervision: 'Accès AIE : Module de supervision',
    sa_switch: 'Accès AIE : Switch / routeur',
    sa_temperateur: 'Remontée alarme : Température',
    site_interieur_exterieur: 'Photo Site intérieur/extérieur',
    sn_badgeuse: 'Présence badgeuse',
    sn_camera: 'Caméra Présence / État',
    sn_clim: "Extracteur d'air / climatisation Présence / État",
    sn_convecteur: 'Convecteur (chauffage) Présence / État',
    sn_date_extincteur: "Date de validation de l'extincteur renseignée",
    sn_etat_general: 'État général / Propreté',
    sn_extincteur: 'Extincteur Présence / État',
    sn_fourreau: 'Obturation fourreaux Chambre 0',
    sn_fumee: 'Détecteur de fumée Présence / État',
    sn_obs: 'Observations Site NRO',
    sn_ouvertureb: 'Ouverture via badge',
    sn_ouverturec: 'Ouverture via clé',
    sn_plaque_pen: 'Plaque ID NRO Intérieur Présence / État / Nommage',
    statut_altiline: 'Statut audit Altiline',
    statut_baieactive: 'Statut audit BAIE ACTIVE',
    statut_energie: 'Statut audit ENERGIE',
    statut_netgeo: 'Statut audit NETGEO',
    statut_odf: 'Statut audit ODF',
    statut_sitenro: 'Statut Site NRO',
    statut_supervision: 'Statut test SUPERVISION AIE',
    tbo: 'Photo TBO',
    tco: 'Photo TCO',
    tgbt: 'Photo TGBT',
    type_recette: 'Type recette',
    type_site: 'Type',
};

export const options: Record<string, OptionsInterface | SelectInterface> = {
    al_acces: { false: 'NON CONFORME', true: 'CONFORME' },
    al_adresse: { false: 'NON CONFORME', true: 'CONFORME' },
    al_dateconstruction: { false: 'NON CONFORME', true: 'CONFORME' },
    al_daterecette: { false: 'NON CONFORME', true: 'CONFORME' },
    al_equip: { false: 'NON CONFORME', true: 'CONFORME' },
    al_erdf: { false: 'NON CONFORME', true: 'CONFORME' },
    al_gps: { false: 'NON CONFORME', true: 'CONFORME' },
    ba_alim: { false: 'NON CONFORME', true: 'CONFORME' },
    ba_apc: { false: 'NON CONFORME', true: 'CONFORME' },
    ba_capuchondwdm: { false: 'NON CONFORME', true: 'CONFORME' },
    ba_capuchontolt: { false: 'NON CONFORME', true: 'CONFORME' },
    ba_connecteurdwdm: { false: 'NON CONFORME', true: 'CONFORME' },
    ba_connecteurtolt: { false: 'NON CONFORME', true: 'CONFORME' },
    ba_disjoncteur: { false: 'NON CONFORME', true: 'CONFORME' },
    ba_dwdm: { false: 'NON CONFORME', true: 'CONFORME' },
    ba_etat_jolt: { false: 'NON CONFORME', true: 'CONFORME' },
    ba_etatdwdm: { false: 'NON CONFORME', true: 'CONFORME' },
    ba_etattbo: { false: 'NON CONFORME', true: 'CONFORME' },
    ba_etatttbo: { false: 'NON CONFORME', true: 'CONFORME' },
    ba_ethernet: { false: 'NON CONFORME', true: 'CONFORME' },
    ba_etq_jolt: { false: 'NON CONFORME', true: 'CONFORME' },
    ba_etq_olt: { false: 'NON CONFORME', true: 'CONFORME' },
    ba_etq_tbodwdm: { false: 'NON CONFORME', true: 'CONFORME' },
    ba_etq_tirdwdm: { false: 'NON CONFORME', true: 'CONFORME' },
    ba_etqalim: { false: 'NON CONFORME', true: 'CONFORME' },
    ba_etqapc: { false: 'NON CONFORME', true: 'CONFORME' },
    ba_etqethernet: { false: 'NON CONFORME', true: 'CONFORME' },
    ba_etqnom: { false: 'NON CONFORME', true: 'CONFORME' },
    ba_etqpe: { false: 'NON CONFORME', true: 'CONFORME' },
    ba_porte: { false: 'NON CONFORME', true: 'CONFORME' },
    ba_position: { false: 'NON CONFORME', true: 'CONFORME' },
    ba_type_jolt: { false: 'NON CONFORME', true: 'CONFORME' },
    ba_typedwdm: { false: 'NON CONFORME', true: 'CONFORME' },
    eng_bascule: { false: 'NON CONFORME', true: 'CONFORME' },
    eng_batterie: { false: 'NON CONFORME', true: 'CONFORME' },
    eng_compteur: { false: 'NON CONFORME', true: 'CONFORME' },
    eng_disj48: { false: 'NON CONFORME', true: 'CONFORME' },
    eng_disjbatterie: { false: 'NON CONFORME', true: 'CONFORME' },
    eng_docbaie: { false: 'NON CONFORME', true: 'CONFORME' },
    eng_erdf_supprdiff: { false: 'NON CONFORME', true: 'CONFORME' },
    eng_floating: { false: 'NON CONFORME', true: 'CONFORME' },
    eng_groupeelect: { false: 'NON CONFORME', true: 'CONFORME' },
    eng_intensite: { false: 'NON CONFORME', true: 'CONFORME' },
    eng_mesbatterie: { false: 'NON CONFORME', true: 'CONFORME' },
    eng_nbbatterie: { false: 'NON CONFORME', true: 'CONFORME' },
    eng_onduleur: { false: 'NON CONFORME', true: 'CONFORME' },
    eng_parafoudre: { false: 'NON CONFORME', true: 'CONFORME' },
    eng_reenclencheur: { false: 'NON CONFORME', true: 'CONFORME' },
    eng_tgbt_presdiff: { false: 'NON CONFORME', true: 'CONFORME' },
    nn_adresse: { false: 'NON CONFORME', true: 'CONFORME' },
    nn_cabletco: { false: 'NON CONFORME', true: 'CONFORME' },
    nn_capatco: { false: 'NON CONFORME', true: 'CONFORME' },
    nn_code: { false: 'NON CONFORME', true: 'CONFORME' },
    nn_codelt02: { false: 'NON CONFORME', true: 'CONFORME' },
    nn_codetco: { false: 'NON CONFORME', true: 'CONFORME' },
    nn_etat: { false: 'NON CONFORME', true: 'CONFORME' },
    nn_etatlt02: { false: 'NON CONFORME', true: 'CONFORME' },
    nn_gestionnaire: { false: 'NON CONFORME', true: 'CONFORME' },
    nn_gestionnairelt02: { false: 'NON CONFORME', true: 'CONFORME' },
    nn_nom: { false: 'NON CONFORME', true: 'CONFORME' },
    nn_nomlt02: { false: 'NON CONFORME', true: 'CONFORME' },
    nn_nomtco: { false: 'NON CONFORME', true: 'CONFORME' },
    nn_proprietaire: { false: 'NON CONFORME', true: 'CONFORME' },
    nn_proprietairelt02: { false: 'NON CONFORME', true: 'CONFORME' },
    nn_rop: { false: 'NON CONFORME', true: 'CONFORME' },
    nn_terrainsigtco: { false: 'NON CONFORME', true: 'CONFORME' },
    nn_typefonc: { false: 'NON CONFORME', true: 'CONFORME' },
    nn_typefonctco: { false: 'NON CONFORME', true: 'CONFORME' },
    nn_typelt02: { false: 'NON CONFORME', true: 'CONFORME' },
    nn_typestruc: { false: 'NON CONFORME', true: 'CONFORME' },
    nn_typestructco: { false: 'NON CONFORME', true: 'CONFORME' },
    odf_abaque: { false: 'NON CONFORME', true: 'CONFORME' },
    odf_baiea1: { false: 'NON CONFORME', true: 'CONFORME' },
    odf_capuchontbo: { false: 'NON CONFORME', true: 'CONFORME' },
    odf_capuchontco: { false: 'NON CONFORME', true: 'CONFORME' },
    odf_collecte: { false: 'NON CONFORME', true: 'CONFORME' },
    odf_connecteurtbo: { false: 'NON CONFORME', true: 'CONFORME' },
    odf_connecteurtco: { false: 'NON CONFORME', true: 'CONFORME' },
    odf_eclateur_cco: { false: 'NON CONFORME', true: 'CONFORME' },
    odf_etatjarretiere: { false: 'NON CONFORME', true: 'CONFORME' },
    odf_etqcco: { false: 'NON CONFORME', true: 'CONFORME' },
    odf_etqjarretiere: { false: 'NON CONFORME', true: 'CONFORME' },
    odf_etqtbo: { false: 'NON CONFORME', true: 'CONFORME' },
    odf_etqtco: { false: 'NON CONFORME', true: 'CONFORME' },
    odf_lovecco: { false: 'NON CONFORME', true: 'CONFORME' },
    odf_positiontbo: { false: 'NON CONFORME', true: 'CONFORME' },
    odf_positiontco: { false: 'NON CONFORME', true: 'CONFORME' },
    odf_ritco: { false: 'NON CONFORME', true: 'CONFORME' },
    odf_sigterraintco: { false: 'NON CONFORME', true: 'CONFORME' },
    odf_typejarretiere: { false: 'NON CONFORME', true: 'CONFORME' },
    sa_badgeuse: { false: 'NON CONFORME', true: 'CONFORME' },
    sa_batterie: { false: 'NON CONFORME', true: 'CONFORME' },
    sa_camera: { false: 'NON CONFORME', true: 'CONFORME' },
    sa_clim: { false: 'NON CONFORME', true: 'CONFORME' },
    sa_distance: { false: 'NON CONFORME', true: 'CONFORME' },
    sa_incendie: { false: 'NON CONFORME', true: 'CONFORME' },
    sa_olt: { false: 'NON CONFORME', true: 'CONFORME' },
    sa_onduleur: { false: 'NON CONFORME', true: 'CONFORME' },
    sa_porte: { false: 'NON CONFORME', true: 'CONFORME' },
    sa_redresseur: { false: 'NON CONFORME', true: 'CONFORME' },
    sa_supervision: { false: 'NON CONFORME', true: 'CONFORME' },
    sa_switch: { false: 'NON CONFORME', true: 'CONFORME' },
    sa_temperateur: { false: 'NON CONFORME', true: 'CONFORME' },
    sn_badgeuse: { false: 'NON CONFORME', true: 'CONFORME' },
    sn_camera: { false: 'NON CONFORME', true: 'CONFORME' },
    sn_clim: { false: 'NON CONFORME', true: 'CONFORME' },
    sn_convecteur: { false: 'NON CONFORME', true: 'CONFORME' },
    sn_date_extincteur: { false: 'NON CONFORME', true: 'CONFORME' },
    sn_etat_general: { false: 'NON CONFORME', true: 'CONFORME' },
    sn_extincteur: { false: 'NON CONFORME', true: 'CONFORME' },
    sn_fourreau: { false: 'NON CONFORME', true: 'CONFORME' },
    sn_fumee: { false: 'NON CONFORME', true: 'CONFORME' },
    sn_ouvertureb: { false: 'NON CONFORME', true: 'CONFORME' },
    sn_ouverturec: { false: 'NON CONFORME', true: 'CONFORME' },
    sn_plaque_pen: { false: 'NON CONFORME', true: 'CONFORME' },
    statut_altiline: [
        { label: 'NON REALISE', value: 'NON REALISE' },
        { label: 'PARTIELLEMENT', value: 'PARTIELLEMENT' },
        { label: 'TERMINE', value: 'TERMINE' },
    ],
    statut_baieactive: [
        { label: 'NON REALISE', value: 'NON REALISE' },
        { label: 'PARTIELLEMENT', value: 'PARTIELLEMENT' },
        { label: 'TERMINE', value: 'TERMINE' },
    ],
    statut_energie: [
        { label: 'NON REALISE', value: 'NON REALISE' },
        { label: 'PARTIELLEMENT', value: 'PARTIELLEMENT' },
        { label: 'TERMINE', value: 'TERMINE' },
    ],
    statut_netgeo: [
        { label: 'NON REALISE', value: 'NON REALISE' },
        { label: 'PARTIELLEMENT', value: 'PARTIELLEMENT' },
        { label: 'TERMINE', value: 'TERMINE' },
    ],
    statut_odf: [
        { label: 'NON REALISE', value: 'NON REALISE' },
        { label: 'PARTIELLEMENT', value: 'PARTIELLEMENT' },
        { label: 'TERMINE', value: 'TERMINE' },
    ],
    statut_sitenro: [
        { label: 'NON REALISE', value: 'NON REALISE' },
        { label: 'PARTIELLEMENT', value: 'PARTIELLEMENT' },
        { label: 'TERMINE', value: 'TERMINE' },
    ],
    statut_supervision: [
        { label: 'NON REALISE', value: 'NON REALISE' },
        { label: 'PARTIELLEMENT', value: 'PARTIELLEMENT' },
        { label: 'TERMINE', value: 'TERMINE' },
    ],
    type_recette: [
        { label: '1ERE VISITE', value: '1ERE VISITE' },
        { label: 'REVISITE', value: 'REVISITE' },
        { label: 'LEVEE DE RESERVE', value: 'LEVEE DE RESERVE' },
    ],
    type_site: [
        { label: 'SHELTER', value: 'SHELTER' },
        { label: 'BAIE HEBERGEE', value: 'BAIE HEBERGEE' },
        { label: 'AUTRES', value: 'AUTRES' },
    ],
};
