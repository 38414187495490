<template>
    <BaseDialog
        :show="showDownloadDialog"
        :title="$t('projectDownload.title')"
        :has-backdrop-escape="false"
        :close-btn-label="$t('globals.cancel')"
        @close="closeDownloadDialog"
    >
        <el-progress
            :percentage="downloadPercentage"
            :status="statusDownload"
            :text-inside="true"
            :stroke-width="20"
        />

        <DownloadStep
            v-for="step in steps"
            :key="step"
            :step="step"
        >
        </DownloadStep>

        <template #actions>
            <BaseDialogButton
                v-show="downloadPercentage < 100"
                :label="$t('globals.cancel')"
                button-type="danger"
                @click="closeDownloadDialog"
            />
            <BaseDialogButton
                v-show="downloadPercentage === 100"
                :label="$t('globals.submit')"
                button-type="action"
                @click="closeDownloadDialog"
            />
        </template>
    </BaseDialog>

    <BaseDialog
        :show="showPreDownloadDialog"
        :title="$t('projectDownload.downloadName')"
        :has-backdrop-escape="false"
    >
        <input
            v-model="projectCustomName"
            class="download_input"
            :placeholder="$t('projectDownload.pleaseNameIt')"
            @input="$event.target.composing = false"
        />
        <p
            v-if="projectCustomNameError"
            class="inputError"
        >
            {{ projectCustomNameError }}
        </p>

        <template #actions>
            <BaseDialogButton
                :label="$t('globals.cancel')"
                button-type="default"
                @click="abortDownload"
            />
            <BaseDialogButton
                :label="$t('globals.submit')"
                button-type="action"
                :is-disabled="!isProjectCustomNameValid"
                @click="downloadProject"
            />
        </template>
    </BaseDialog>

    <ProjectLayers />
</template>

<script lang="ts">
import * as Sentry from '@sentry/vue';
import type BaseLayer from 'ol/layer/Base';
import { defineComponent } from 'vue';
import type Feature from 'ol/Feature';
import type Map from 'ol/Map';
import { mapState } from 'pinia';
import VectorLayer from 'ol/layer/Vector';
import type { VectorSourceEvent } from 'ol/source/Vector';

import * as ProjectApi from '@connect-field/client/services/api/project';
import {
    type DownloadOptions,
    DownloadProjectStep,
    DownloadService,
} from '@connect-field/client/services/download.service';
import type {
    OfflineGeoJSONLayerConfiguration,
    OfflineMBTilesLayerConfiguration,
} from '@connect-field/client/services/layers/offlineLayers.service';
import {
    removeHighlightedFeature,
    removeLayers,
    removePinOnMap,
    resetView,
} from '@connect-field/client/services/map.service';
import useProjectsStore, {
    type LayerSelection,
    type OfflineProjectInterface,
} from '@connect-field/client/stores/projects';
import BaseDialog from '@connect-field/client/components/ui/BaseDialog.vue';
import BaseDialogButton from '@connect-field/client/components/ui/BaseDialogButton.vue';
import { deepCopy } from '@connect-field/client/utilities/tools';
import DownloadStep from '@connect-field/client/components/atoms/DownloadStep.vue';
import { generateSelectionLayer } from '@connect-field/client/services/layers/utilityLayers.service';
import { importImages } from '@connect-field/client/services/images';
import { isStringUniqueInObject } from '@connect-field/client/utilities/validators';
import type { ProjectConfigurationDto } from '@connect-field/client/sdk/generated';
import ProjectLayers from '@connect-field/client/pages/home/project/ProjectLayers.vue';
import { setStore } from '@connect-field/client/utilities/idb-utility';
import useAuthStore from '@connect-field/client/stores/auth';
import useMapStore from '@connect-field/client/stores/map';
import useMenuStore from '@connect-field/client/stores/menu';
import usePanelStore from '@connect-field/client/stores/panel';

const projectCustomNameErrors = {
    alreadyUsed: 'Ce nom est déjà utilisé',
    emptyName: 'Veuillez saisir un nom',
};

interface DataInterface {
    controller?: AbortController;
    downloadPercentage: number;
    failedDownload: boolean;
    isProjectCustomNameValid: boolean;
    loading: boolean;
    projectCustomName: string;
    projectCustomNameError: string;
    showDownloadDialog: boolean;
    showPreDownloadDialog: boolean;
    showReturnAlert: boolean;
    signal?: AbortSignal;
    statusDownload: '' | 'success' | 'warning' | 'exception';
    steps: Array<DownloadProjectStep>;
}

export default defineComponent({
    components: {
        BaseDialog,
        BaseDialogButton,
        DownloadStep,
        ProjectLayers,
    },
    props: {
        projectId: {
            type: String,
            required: true,
        },
    },
    emits: ['stateChanged'],

    setup() {
        return {
            mapStore: useMapStore(),
            menuStore: useMenuStore(),
            panelStore: usePanelStore(),
            projectsStore: useProjectsStore(),
        };
    },
    data(): DataInterface {
        return {
            downloadPercentage: 0,
            failedDownload: false,
            isProjectCustomNameValid: false,
            loading: false,
            projectCustomName: '',
            projectCustomNameError: '',
            showDownloadDialog: false,
            showPreDownloadDialog: false,
            showReturnAlert: false,
            statusDownload: '',
            steps: [],
        };
    },
    computed: {
        ...mapState(useAuthStore, { token: 'token' }),
        ...mapState(useProjectsStore, {
            layerSelection: 'selectedLayersOffline',
            offlineProjects: 'offlineProjects',
            offlineSelectionStatus: 'offlineSelectionStatus',
            saveFullProject: 'saveFullProject',
            selectedProject: 'selectedProject',
            validationOfflineDownload: 'validationOfflineDownload',
            zoneSelection: 'zoneSelection',
        }),
        map(): Map {
            if (!this.mapStore.map) {
                throw new Error('Map is undefined');
            }

            return this.mapStore.map as Map;
        },
    },
    watch: {
        offlineSelectionStatus(newVal) {
            if (newVal === false) {
                this.reset();
            }
        },
        projectCustomName() {
            if (this.projectCustomName.length < 1) {
                this.projectCustomNameError = projectCustomNameErrors.emptyName;
                this.isProjectCustomNameValid = false;

                return;
            }

            const isCustomNameUnique = isStringUniqueInObject(
                this.projectCustomName,
                this.offlineProjects,
                'customName',
            );
            if (!isCustomNameUnique) {
                this.projectCustomNameError =
                    projectCustomNameErrors.alreadyUsed;
                this.isProjectCustomNameValid = false;

                return;
            }

            this.isProjectCustomNameValid = true;
            this.projectCustomNameError = '';
        },
        validationOfflineDownload(newVal) {
            if (newVal === true) {
                this.openPreDownloadDialog();
            }
        },
    },
    mounted() {
        this.controller = new AbortController();
        this.signal = this.controller.signal;

        removePinOnMap();
        this.menuStore.$patch({ displayMenu: true });

        this.loading = true;

        this.menuStore.$patch({ displayMenu: false });
        this.panelStore.setPanelClosed();
        this.applyLayerOfflineSelection();

        this.loading = false;
    },
    methods: {
        abortDownload(): void {
            this.showPreDownloadDialog = false;
            this.projectsStore.$patch({ validationOfflineDownload: false });
        },
        applyLayerOfflineSelection(): void {
            if (!this.selectedProject?.selectionLayer) {
                return;
            }

            const selectionLayer = generateSelectionLayer(
                `${this.selectedProject.selectionLayer.url}?accessToken=${this.token}`,
                this.selectedProject.selectionLayer.keyColumnName,
            );
            this.map.addLayer(selectionLayer);
            this.getZASROLayers();
        },
        closeDownloadDialog(): void {
            if (this.downloadPercentage < 100) {
                console.debug('Cancel download');
                this.controller?.abort();
                this.$emit('stateChanged', {
                    newState: 'download',
                    projectId: this.projectId,
                });
            }

            this.downloadPercentage = 0;
            this.showDownloadDialog = false;
            this.reset();
        },
        async downloadProject(): Promise<void> {
            try {
                this.$emit('stateChanged', {
                    newState: 'loading',
                    projectId: this.projectId,
                });

                this.initDownloadProject();

                const isCustomNameUnique = isStringUniqueInObject(
                    this.projectCustomName,
                    this.offlineProjects,
                    'customName',
                );

                if (this.projectCustomName.length < 1) {
                    this.projectCustomNameError =
                        projectCustomNameErrors.emptyName;
                    throw new Error('Project name is missing');
                }

                if (!isCustomNameUnique) {
                    throw new Error('Project name is not unique');
                }

                const customName = this.projectCustomName.trim();

                let options: DownloadOptions = {
                    projectId: parseInt(this.projectId, 10),
                    signal: this.signal,
                };

                if (isNaN(options.projectId) || options.projectId < 1) {
                    throw new Error('projectId is invalid');
                }

                options = await this.generateBboxOptions(options);

                const downloadService = new DownloadService(
                    options,
                    this.steps,
                    (percentage: number) =>
                        (this.downloadPercentage += percentage),
                );

                const projectConfiguration =
                    await downloadService.downloadProjectConfiguration();
                const geoJSONLayers =
                    await downloadService.downloadGeoJSONLayers(
                        projectConfiguration,
                    );
                const mbTilesLayers =
                    await downloadService.downloadPBFLayers2(
                        projectConfiguration,
                    );
                const basemap = await downloadService.downloadBaseMap();
                const searchDump = await downloadService.downloadSearchDump();
                const imagesDump = await downloadService.downloadImagesDump();

                await this.saveProject(
                    projectConfiguration,
                    customName,
                    options,
                    geoJSONLayers,
                    mbTilesLayers,
                    basemap,
                    searchDump,
                    imagesDump,
                );

                this.downloadPercentage = 100;
                this.statusDownload = 'success';
                this.$emit('stateChanged', {
                    newState: 'synchronized',
                    projectId: this.projectId,
                });
            } catch (error: unknown) {
                console.error(error);
                Sentry.captureException(error);

                this.failedDownload = true;
                this.statusDownload = 'exception';

                this.$emit('stateChanged', {
                    newState: 'download',
                    projectId: this.projectId,
                });
            }
        },
        async generateBboxOptions(
            options: DownloadOptions,
        ): Promise<DownloadOptions> {
            if (this.saveFullProject) {
                throw new Error('Not implemented at the moment');
            }

            if (this.zoneSelection && this.layerSelection?.length) {
                options.zoneIds = this.layerSelection;
                options.bbox = await ProjectApi.getProjectBbox(
                    options.projectId,
                    options.zoneIds,
                );

                return options;
            }

            options.zoneIds = undefined;
            options.bbox = this.map
                .getView()
                .calculateExtent(this.map.getSize());

            return options;
        },
        getZASROLayers(): void {
            const [layerFiltered] = this.map
                .getLayers()
                .getArray()
                .filter((layer: BaseLayer) => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const [$name, type] = layer.getProperties().name
                        ? layer.getProperties().name.split('.')
                        : [null, null];
                    if (type === 'selection_layer') {
                        return layer;
                    }
                });
            if (layerFiltered && layerFiltered instanceof VectorLayer) {
                layerFiltered
                    .getSource()
                    .on('change', (evt: VectorSourceEvent) => {
                        const source = evt.target;

                        if (
                            source.getState() === 'ready' &&
                            source.getFeatures().length
                        ) {
                            const features: Array<LayerSelection> = source
                                .getFeatures()
                                .map((feature: Feature) => {
                                    const featureProps =
                                        feature.getProperties();
                                    const layerProps =
                                        layerFiltered.getProperties();

                                    return {
                                        id: featureProps.sid,
                                        label: `Zone ${
                                            featureProps[
                                                layerProps.keyColumnName
                                            ]
                                        }`,
                                        value: layerProps.keyColumnName,
                                    };
                                });

                            this.projectsStore.$patch({
                                layerSelectionList: features,
                            });
                        }
                    });
            }
        },
        handleConfirm(): void {
            removePinOnMap();
            removeHighlightedFeature();
            removeLayers();
            this.map
                .getLayers()
                .getArray()
                .forEach((layer: BaseLayer) => {
                    if (layer.getProperties()?.name === 'background_OSM') {
                        layer.setVisible(true);
                    }
                    layer.setOpacity(1);
                });
            resetView();

            this.showReturnAlert = false;
            this.projectsStore.$patch((state) => {
                state.selectedProject = undefined;
                state.selectedProjectId = undefined;
            });
            this.$router.push({ name: 'projects' });
        },
        initDownloadProject(): void {
            this.steps = [
                new DownloadProjectStep('projectDownloadSteps.step1'),
                new DownloadProjectStep('projectDownloadSteps.step2'),
                new DownloadProjectStep('projectDownloadSteps.step3'),
                new DownloadProjectStep('projectDownloadSteps.step4'),
                new DownloadProjectStep('projectDownloadSteps.step5'),
                new DownloadProjectStep('projectDownloadSteps.step6'),
                new DownloadProjectStep('projectDownloadSteps.step7'),
            ];

            this.failedDownload = false;
            this.statusDownload = '';

            this.showPreDownloadDialog = false;
            this.showDownloadDialog = true;
            this.downloadPercentage = 0;
            this.projectCustomNameError = '';
        },
        openPreDownloadDialog(): void {
            this.showPreDownloadDialog = true;
            this.projectCustomName = '';
            this.projectCustomNameError = '';
            this.isProjectCustomNameValid = false;
        },
        reset(): void {
            removeLayers();
            this.projectsStore.$patch({
                layerSelectionList: [],
                selectedLayersOffline: [],
                validationOfflineDownload: false,
            });
            this.menuStore.$patch({ displayMenu: true });
            this.panelStore.setPanelHalfOpened();
            this.handleConfirm();
        },
        async saveProject(
            projectConfiguration: ProjectConfigurationDto,
            customName: string,
            options: DownloadOptions,
            geoJSONLayers: Array<OfflineGeoJSONLayerConfiguration>,
            mbTilesLayers: Array<OfflineMBTilesLayerConfiguration>,
            basemap: Uint8Array,
            searchDump: Uint8Array,
            imagesDump: Uint8Array,
        ) {
            try {
                this.steps[6].status = 'running';
                await setStore(
                    'projectConfigurations',
                    projectConfiguration,
                    this.projectId,
                );
                const id = `${this.projectId}-${Date.now()}`;

                await setStore('projectGeojsons', geoJSONLayers, id);
                await setStore('projectMbtiles', mbTilesLayers, id);
                await setStore('projectBasemaps', basemap, id);
                await setStore('projectSearch', searchDump, id);
                await importImages(imagesDump);

                const offlineProjectObject: OfflineProjectInterface = {
                    customName,
                    id,
                    name: projectConfiguration.name,
                    offlineBbox: options.bbox ?? [],
                    parentId: this.projectId,
                    zoneIds: options.zoneIds,
                };
                const offlineProjectObjectClone =
                    deepCopy(offlineProjectObject);
                await this.projectsStore.addOfflineProject(
                    offlineProjectObjectClone,
                );

                this.steps[6].status = 'success';
            } catch (error: unknown) {
                this.steps[6].status = 'failed';
                this.steps[6].errors.push(
                    'Erreur lors de la sauvegarde des données',
                );

                throw error;
            }
        },
    },
});
</script>

<style scoped lang="scss">
@import 'ProjectDownload.scss';
</style>
