import { createI18n } from 'vue-i18n';

import en from '@connect-field/client/assets/locales/en.json';
import fr from '@connect-field/client/assets/locales/fr.json';

type MessageSchema = typeof fr;

const i18n = createI18n<[MessageSchema], 'fr' | 'en'>({
    fallbackLocale: 'en',
    globalInjection: true,
    legacy: false,
    locale: 'fr',
    messages: {
        en,
        fr,
    },
});

export default i18n;
