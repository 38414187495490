/*
Store for box plan.
 */

import { acceptHMRUpdate, defineStore } from 'pinia';

import type { BoxPlanPositionInterface } from '@connect-field/client/services/boxPlan.service';
import type { RecordOfArrays } from '@connect-field/client/utilities/tools';

export type CurrentTabType = 'cables' | 'cable' | 'cassettes' | 'cassette' | 'tubes' | 'tube' | 'fibres' | 'general';

export interface StateInterface {
    cableData?: {
        data: Record<string, unknown>;
        key: string;
    };
    cablesData?: Record<string, unknown>;
    cassettesData?: RecordOfArrays<string, BoxPlanPositionInterface>;
    currentTab: CurrentTabType;
}

const useBoxPlanStore = defineStore('boxPlan', {
    actions: {
        goToCable() {
            this.currentTab = 'cable';
        },
    },
    state(): StateInterface {
        return {
            cableData: undefined,
            cablesData: undefined,
            cassettesData: undefined,
            currentTab: 'general',
        };
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useBoxPlanStore, import.meta.hot));
}

export default useBoxPlanStore;
