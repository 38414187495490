<template>
    <FormWrapper
        v-if="!loading"
        ref="customFormRef"
        :images-model="images"
        :form-model="form"
        :mandatory-fields="mandatoryFields"
        :changes-model="changes"
        :layer-name="layerName"
        :object-id="objectId"
        :project-id="projectId"
        :latitude="latitude"
        :longitude="longitude"
        @update:images-model="
            (e) => {
                images = e;
            }
        "
        @update:value-missing="
            (e) => {
                valueMissing = e;
            }
        "
        @update:form-model="
            (e) => {
                form = e;
            }
        "
        @update:changes-model="
            (e) => {
                changes = e;
            }
        "
    >
        <template #menu_tabs>
            <el-row class="scroll-menu">
                <el-col :span="8">
                    <div
                        @click="selectTab('Environnement')"
                        class="tabs"
                        :class="{
                            selectedTab: selectedTab === 'Environnement',
                        }"
                    >
                        <FontAwesomeIcon
                            size="xs"
                            icon="leaf"
                        />
                    </div>
                </el-col>

                <el-col :span="8">
                    <div
                        @click="selectTab('Boite')"
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'Boite' }"
                    >
                        <FontAwesomeIcon
                            size="xs"
                            icon="box"
                        />
                    </div>
                </el-col>

                <el-col :span="8">
                    <div
                        @click="selectTab('Optique')"
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'Optique' }"
                    >
                        <FontAwesomeIcon
                            size="xs"
                            icon="diagram-project"
                        />
                    </div>
                </el-col>
            </el-row>
        </template>
        <template #main_form>
            <el-row>
                <el-col
                    :span="24"
                    v-show="selectedTab === 'Environnement'"
                >
                    <CustomInput
                        :label="labels.code"
                        :value="form.code"
                        :maxlength="254"
                        :disabled="true" />
                    <CustomInput
                        :label="labels.urlexcel"
                        :value="form.urlexcel"
                        :disabled="true" />
                    <CustomDatePicker
                        :label="labels.date_emission_rsv"
                        :value="form.date_emission_rsv"
                        @update:value="
                            (newValue) =>
                                saveData('date_emission_rsv', newValue)
                        " />
                    <CustomInputSelect
                        :label="labels.rec_boitier_real + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.rec_boitier_real"
                        :value="form.rec_boitier_real"
                        @update:value="
                            (newValue) => saveData('rec_boitier_real', newValue)
                        " />
                    <CustomInputSelect
                        :label="labels.coord_gps"
                        :options="options.coord_gps"
                        :value="form.coord_gps"
                        @update:value="
                            (newValue) => saveData('coord_gps', newValue)
                        " />
                    <InputCamera
                        :label="labels.environnement"
                        :images="images.environnement"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('environnement', newValue)
                        " />
                    <CustomInput
                        :label="labels.support"
                        :value="form.support"
                        :maxlength="254"
                        :disabled="true" />
                    <CustomInput
                        :label="labels.id_parent"
                        :value="form.id_parent"
                        :disabled="true" />
                    <CustomInput
                        :label="labels.type_struc"
                        :value="form.type_struc"
                        :maxlength="254"
                        :disabled="true" />
                    <CustomInput
                        :label="labels.proprietai"
                        :value="form.proprietai"
                        :disabled="true" />
                    <CustomInput
                        :label="labels.gestionnai"
                        :value="form.gestionnai"
                        :maxlength="254"
                        :disabled="true" />
                    <CustomInput
                        :label="labels.emprise"
                        :value="form.emprise"
                        :disabled="true" />
                    <CustomInputSelect
                        v-show="condition_type_struc_pt()"
                        :label="labels.type_struc_pt"
                        :options="options.type_struc_pt"
                        :value="form.type_struc_pt"
                        @update:value="
                            (newValue) => saveData('type_struc_pt', newValue)
                        " />
                    <CustomInputSelect
                        v-show="condition_type_gest_pt()"
                        :label="labels.type_gest_pt"
                        :options="options.type_gest_pt"
                        :value="form.type_gest_pt"
                        @update:value="
                            (newValue) => saveData('type_gest_pt', newValue)
                        " />
                    <CustomInputMultiSelect
                        v-show="condition_tampon_cadre()"
                        :label="labels.tampon_cadre"
                        :options="options.tampon_cadre"
                        :value="form.tampon_cadre"
                        :disabled-option-value="'CONFORME'"
                        @update:value="
                            (newValue) => saveData('tampon_cadre', newValue)
                        " />

                    <div v-show="condition_infrastructure()">
                        <InputCamera
                            :label="labels.infrastructure"
                            :images="images.infrastructure"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage('infrastructure', newValue)
                            "
                        /></div
                ></el-col>
                <el-col
                    :span="24"
                    v-show="selectedTab === 'Boite'"
                >
                    <CustomInputMultiSelect
                        v-show="condition_cable_terrain()"
                        :label="labels.cable_terrain"
                        :options="options.cable_terrain"
                        :value="form.cable_terrain"
                        :disabled-option-value="'CONFORME'"
                        @update:value="
                            (newValue) => saveData('cable_terrain', newValue)
                        " />

                    <div v-show="condition_cb_love()">
                        <InputCamera
                            :label="labels.cb_love"
                            :images="images.cb_love"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) => saveImage('cb_love', newValue)
                            "
                        />
                    </div>
                    <CustomInputMultiSelect
                        v-show="condition_fixation_boitier()"
                        :label="labels.fixation_boitier"
                        :options="options.fixation_boitier"
                        :value="form.fixation_boitier"
                        :disabled-option-value="'CONFORME'"
                        @update:value="
                            (newValue) => saveData('fixation_boitier', newValue)
                        " />

                    <div v-show="condition_cb_fix()">
                        <InputCamera
                            :label="labels.cb_fix"
                            :images="images.cb_fix"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) => saveImage('cb_fix', newValue)
                            "
                        />
                    </div>
                    <CustomInput
                        :label="labels.amont"
                        :value="form.amont"
                        :maxlength="254"
                        :disabled="true" />
                    <CustomInput
                        :label="labels.capaamont"
                        :value="form.capaamont"
                        :disabled="true" />
                    <CustomInputMultiSelect
                        v-show="condition_cable_sig()"
                        :label="labels.cable_sig"
                        :options="options.cable_sig"
                        :value="form.cable_sig"
                        :disabled-option-value="'CONFORME'"
                        @update:value="
                            (newValue) => saveData('cable_sig', newValue)
                        " />
                    <CustomInputMultiSelect
                        v-show="condition_etiquette_cable()"
                        :label="labels.etiquette_cable"
                        :options="options.etiquette_cable"
                        :value="form.etiquette_cable"
                        :disabled-option-value="'CONFORME'"
                        @update:value="
                            (newValue) => saveData('etiquette_cable', newValue)
                        " />

                    <div v-show="condition_eti_cable()">
                        <InputCamera
                            :label="labels.eti_cable"
                            :images="images.eti_cable"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) => saveImage('eti_cable', newValue)
                            "
                        />
                    </div>
                    <CustomInputMultiSelect
                        v-show="condition_etiquette_boitier()"
                        :label="labels.etiquette_boitier + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.etiquette_boitier"
                        :value="form.etiquette_boitier"
                        :disabled-option-value="'CONFORME'"
                        @update:value="
                            (newValue) =>
                                saveData('etiquette_boitier', newValue)
                        " />

                    <div v-show="condition_eti_boite()">
                        <InputCamera
                            :label="labels.eti_boite"
                            :images="images.eti_boite"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) => saveImage('eti_boite', newValue)
                            "
                        />
                    </div>
                    <CustomInputMultiSelect
                        v-show="condition_info_boitier_sig()"
                        :label="labels.info_boitier_sig"
                        :options="options.info_boitier_sig"
                        :value="form.info_boitier_sig"
                        :disabled-option-value="'CONFORME'"
                        @update:value="
                            (newValue) => saveData('info_boitier_sig', newValue)
                        " />
                    <CustomInputMultiSelect
                        v-show="condition_etat_boitier()"
                        :label="labels.etat_boitier"
                        :options="options.etat_boitier"
                        :value="form.etat_boitier"
                        :disabled-option-value="'CONFORME'"
                        @update:value="
                            (newValue) => saveData('etat_boitier', newValue)
                        " />

                    <div v-show="condition_intb_ens()">
                        <InputCamera
                            :label="labels.intb_ens"
                            :images="images.intb_ens"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) => saveImage('intb_ens', newValue)
                            "
                        />
                    </div>
                    <CustomInputMultiSelect
                        v-show="condition_entree_cable()"
                        :label="labels.entree_cable"
                        :options="options.entree_cable"
                        :value="form.entree_cable"
                        :disabled-option-value="'CONFORME'"
                        @update:value="
                            (newValue) => saveData('entree_cable', newValue)
                        " />
                    <CustomInputMultiSelect
                        v-show="condition_arrimage_cable()"
                        :label="labels.arrimage_cable"
                        :options="options.arrimage_cable"
                        :value="form.arrimage_cable"
                        :disabled-option-value="'CONFORME'"
                        @update:value="
                            (newValue) => saveData('arrimage_cable', newValue)
                        " />

                    <div v-show="condition_cb_entree()">
                        <InputCamera
                            :label="labels.cb_entree"
                            :images="images.cb_entree"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) => saveImage('cb_entree', newValue)
                            "
                        /></div
                ></el-col>
                <el-col
                    :span="24"
                    v-show="selectedTab === 'Optique'"
                >
                    <CustomInputMultiSelect
                        v-show="condition_tubes()"
                        :label="labels.tubes"
                        :options="options.tubes"
                        :value="form.tubes"
                        :disabled-option-value="'CONFORME'"
                        @update:value="
                            (newValue) => saveData('tubes', newValue)
                        "
                    />

                    <div v-show="condition_intb_tubes()">
                        <InputCamera
                            :label="labels.intb_tubes"
                            :images="images.intb_tubes"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) => saveImage('intb_tubes', newValue)
                            "
                        />
                    </div>
                    <CustomInputMultiSelect
                        v-show="condition_cassette()"
                        :label="labels.cassette"
                        :options="options.cassette"
                        :value="form.cassette"
                        :disabled-option-value="'CONFORME'"
                        @update:value="
                            (newValue) => saveData('cassette', newValue)
                        "
                    />

                    <div v-show="condition_intb_k7()">
                        <InputCamera
                            :label="labels.intb_k7"
                            :images="images.intb_k7"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) => saveImage('intb_k7', newValue)
                            "
                        />
                    </div>
                    <CustomInputMultiSelect
                        v-show="condition_fibres()"
                        :label="labels.fibres"
                        :options="options.fibres"
                        :value="form.fibres"
                        :disabled-option-value="'CONFORME'"
                        @update:value="
                            (newValue) => saveData('fibres', newValue)
                        "
                    />
                    <CustomInputMultiSelect
                        v-show="condition_plan_boite()"
                        :label="labels.plan_boite + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.plan_boite"
                        :value="form.plan_boite"
                        :disabled-option-value="'CONFORME'"
                        @update:value="
                            (newValue) => saveData('plan_boite', newValue)
                        "
                    />
                    <CustomInputMultiSelect
                        v-show="condition_continuite_optique()"
                        :label="labels.continuite_optique + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.continuite_optique"
                        :value="form.continuite_optique"
                        :disabled-option-value="'CONFORME'"
                        @update:value="
                            (newValue) =>
                                saveData('continuite_optique', newValue)
                        "
                    />

                    <div v-show="condition_intb_optique()">
                        <InputCamera
                            :label="labels.intb_optique"
                            :images="images.intb_optique"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage('intb_optique', newValue)
                            "
                        />
                    </div>
                    <CustomInputSelect
                        v-show="condition_criticite_remarque()"
                        :label="labels.criticite_remarque"
                        :options="options.criticite_remarque"
                        :value="form.criticite_remarque"
                        @update:value="
                            (newValue) =>
                                saveData('criticite_remarque', newValue)
                        "
                    />
                    <CustomInputTextarea
                        v-show="condition_remarque_supplementaire()"
                        :label="labels.remarque_supplementaire"
                        :value="form.remarque_supplementaire"
                        @update:value="
                            (newValue) =>
                                saveData('remarque_supplementaire', newValue)
                        "
                    />

                    <div v-show="condition_autres_remarques()">
                        <InputCamera
                            :label="labels.autres_remarques"
                            :images="images.autres_remarques"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage('autres_remarques', newValue)
                            "
                        />
                    </div>
                    <CustomInputSelect
                        :label="labels.statut_audit + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.statut_audit"
                        :value="form.statut_audit"
                        @update:value="
                            (newValue) => saveData('statut_audit', newValue)
                        "
                    />
                </el-col>
            </el-row>
        </template>
    </FormWrapper>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import {
    labels,
    options,
} from '@connect-field/client/components/forms/AIC/QR/t_qr_boite.data';
import type { FormDataInterface } from '@connect-field/client/components/forms/form.types';
import FormTemplate from '@connect-field/client/components/forms/FormTemplate.vue';

interface DataInterface extends FormDataInterface {}

export default defineComponent({
    extends: FormTemplate,
    data(): DataInterface {
        return {
            changes: false,
            form: {},
            images: {
                environnement: [],
                infrastructure: [],
                cb_love: [],
                cb_fix: [],
                eti_cable: [],
                eti_boite: [],
                intb_ens: [],
                cb_entree: [],
                intb_tubes: [],
                intb_k7: [],
                intb_optique: [],
                autres_remarques: [],
            },
            labels,
            loading: true,
            mandatoryFields: [
                'rec_boitier_real',
                'etiquette_boitier',
                'plan_boite',
                'continuite_optique',
                'statut_audit',
            ],
            options,
            selectedTab: 'Environnement',
            valueMissing: false,
        };
    },
    methods: {
        condition_type_struc_pt(): boolean {
            const { rec_boitier_real } = this.form;
            return rec_boitier_real === 'CONFORME' || rec_boitier_real === null;
        },
        condition_type_gest_pt(): boolean {
            const { rec_boitier_real } = this.form;
            return rec_boitier_real === 'CONFORME' || rec_boitier_real === null;
        },
        condition_tampon_cadre(): boolean {
            const { rec_boitier_real } = this.form;
            return rec_boitier_real === 'CONFORME' || rec_boitier_real === null;
        },
        condition_infrastructure(): boolean {
            const { rec_boitier_real } = this.form;
            return rec_boitier_real === 'CONFORME' || rec_boitier_real === null;
        },
        condition_cable_terrain(): boolean {
            const { rec_boitier_real } = this.form;
            return rec_boitier_real === 'CONFORME' || rec_boitier_real === null;
        },
        condition_cb_love(): boolean {
            const { rec_boitier_real } = this.form;
            return rec_boitier_real === 'CONFORME' || rec_boitier_real === null;
        },
        condition_fixation_boitier(): boolean {
            const { rec_boitier_real } = this.form;
            return rec_boitier_real === 'CONFORME' || rec_boitier_real === null;
        },
        condition_cb_fix(): boolean {
            const { rec_boitier_real } = this.form;
            return rec_boitier_real === 'CONFORME' || rec_boitier_real === null;
        },
        condition_cable_sig(): boolean {
            const { rec_boitier_real } = this.form;
            return rec_boitier_real === 'CONFORME' || rec_boitier_real === null;
        },
        condition_etiquette_cable(): boolean {
            const { rec_boitier_real } = this.form;
            return rec_boitier_real === 'CONFORME' || rec_boitier_real === null;
        },
        condition_eti_cable(): boolean {
            const { rec_boitier_real } = this.form;
            return rec_boitier_real === 'CONFORME' || rec_boitier_real === null;
        },
        condition_etiquette_boitier(): boolean {
            const { rec_boitier_real } = this.form;
            return rec_boitier_real === 'CONFORME' || rec_boitier_real === null;
        },
        condition_eti_boite(): boolean {
            const { rec_boitier_real } = this.form;
            return rec_boitier_real === 'CONFORME' || rec_boitier_real === null;
        },
        condition_info_boitier_sig(): boolean {
            const { rec_boitier_real } = this.form;
            return rec_boitier_real === 'CONFORME' || rec_boitier_real === null;
        },
        condition_etat_boitier(): boolean {
            const { rec_boitier_real } = this.form;
            return rec_boitier_real === 'CONFORME' || rec_boitier_real === null;
        },
        condition_intb_ens(): boolean {
            const { rec_boitier_real } = this.form;
            return rec_boitier_real === 'CONFORME' || rec_boitier_real === null;
        },
        condition_entree_cable(): boolean {
            const { rec_boitier_real } = this.form;
            return rec_boitier_real === 'CONFORME' || rec_boitier_real === null;
        },
        condition_arrimage_cable(): boolean {
            const { rec_boitier_real } = this.form;
            return rec_boitier_real === 'CONFORME' || rec_boitier_real === null;
        },
        condition_cb_entree(): boolean {
            const { rec_boitier_real } = this.form;
            return rec_boitier_real === 'CONFORME' || rec_boitier_real === null;
        },
        condition_tubes(): boolean {
            const { rec_boitier_real } = this.form;
            return rec_boitier_real === 'CONFORME';
        },
        condition_intb_tubes(): boolean {
            const { rec_boitier_real } = this.form;
            return rec_boitier_real === 'CONFORME';
        },
        condition_cassette(): boolean {
            const { rec_boitier_real } = this.form;
            return rec_boitier_real === 'CONFORME';
        },
        condition_intb_k7(): boolean {
            const { rec_boitier_real } = this.form;
            return rec_boitier_real === 'CONFORME';
        },
        condition_fibres(): boolean {
            const { rec_boitier_real } = this.form;
            return rec_boitier_real === 'CONFORME';
        },
        condition_plan_boite(): boolean {
            const { rec_boitier_real } = this.form;
            return rec_boitier_real === 'CONFORME';
        },
        condition_continuite_optique(): boolean {
            const { rec_boitier_real } = this.form;
            return rec_boitier_real === 'CONFORME';
        },
        condition_intb_optique(): boolean {
            const { rec_boitier_real } = this.form;
            return rec_boitier_real === 'CONFORME';
        },
        condition_criticite_remarque(): boolean {
            const { rec_boitier_real } = this.form;
            return rec_boitier_real === 'CONFORME';
        },
        condition_remarque_supplementaire(): boolean {
            const { rec_boitier_real, criticite_remarque } = this.form;
            return rec_boitier_real === 'CONFORME' && criticite_remarque;
        },
        condition_autres_remarques(): boolean {
            const { rec_boitier_real } = this.form;
            return rec_boitier_real === 'CONFORME';
        },
    },
});
</script>
