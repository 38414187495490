<template>
    <el-row>
        <el-col :span="24">
            <div class="custom_label_input">
                {{ label }}
            </div>
        </el-col>
        <el-col :span="24">
            <el-form-item>
                <el-input
                    v-model="model"
                    :disabled="disabled"
                    :maxlength="maxlength"
                    :size="size"
                    :placeholder="placeholder"
                />
            </el-form-item>
        </el-col>
    </el-row>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';

enum SizeEnum {
    large = 'large',
    default = 'default',
    small = 'small',
}

export default defineComponent({
    name: 'CustomInput',
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: '',
        },
        maxlength: {
            type: Number,
            default: 255,
        },
        placeholder: {
            type: String,
            default: '',
        },
        size: {
            type: String as PropType<SizeEnum>,
            values: ['large', 'default', 'small'],
            default: SizeEnum.default,
        },
        value: {
            type: String,
            default: null,
        },
    },
    emits: ['update:value'],
    computed: {
        model: {
            get(): string {
                return this.value;
            },
            set(value: string): void {
                this.$emit('update:value', value);
            },
        },
    },
});
</script>

<style lang="scss">
/* It's a fix, check at each update of element-plus if it's needed */
.el-col {
    .el-input.is-disabled .el-input__wrapper {
        background-color: #989899;
        box-shadow: 0 0 0 1px #989899 inset;
    }

    .el-input.is-disabled {
        cursor: not-allowed;
    }

    input[disabled],
    input[disabled]:hover {
        background-color: #989899 !important;
        color: #f0f6ff !important;
        -webkit-text-fill-color: #f0f6ff !important;
    }
}
</style>
