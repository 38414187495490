import * as Sentry from '@sentry/vue';
import type BaseLayer from 'ol/layer/Base';
import type Feature from 'ol/Feature';
import VectorLayer from 'ol/layer/Vector';
import type VectorSource from 'ol/source/Vector';

import { ProjectConfigurationLayerFormatEnum } from '@connect-field/client/sdk/generated';
import useMapStore from '@connect-field/client/stores/map';

export function reloadLayerSource(layerId: string): void {
    const mapStore = useMapStore();
    const map = mapStore.map;
    let layerFound = false;

    if (!map) {
        throw new Error('Map is undefined');
    }

    map.getLayers()
        .getArray()
        .forEach((layer: BaseLayer) => {
            if (layer.getProperties().id?.toString() === layerId && layer instanceof VectorLayer) {
                layerFound = true;
                const layerSource = layer.getSource() as VectorSource;
                layerSource.clear();
                layerSource.refresh();
                layer.changed();
            }
        });

    if (!layerFound) {
        console.warn(`[form/reloadLayerSource] No layer ${layerId} found.`);
        Sentry.captureException(`[form/reloadLayerSource] No layer ${layerId} found.`);

        return;
    }
}

export function addFeatureToLayerSource(layerId: string, newFeature: Feature): void {
    const mapStore = useMapStore();
    const map = mapStore.map;
    let layerFound = false;

    if (!map) {
        throw new Error('Map is undefined');
    }

    map.getLayers()
        .getArray()
        .forEach((layer: BaseLayer) => {
            if (layer.getProperties().id?.toString() === layerId && layer instanceof VectorLayer) {
                layerFound = true;
                (layer.getSource() as VectorSource).addFeature(newFeature);
                layer.changed();
            }
        });

    if (!layerFound) {
        console.warn(`[form/addFeatureToLayerSource] No layer ${layerId} found.`);
        Sentry.captureException(`[form/addFeatureToLayerSource] No layer ${layerId} found.`);

        return;
    }

    map.getView().changed();
}

export function updateFeatureToLayerSource(
    layerId: string,
    featureIdToUpdate: string,
    updatedObject: Record<string, unknown> & {
        dataForm: Record<string, unknown>;
    },
): void {
    const mapStore = useMapStore();
    const map = mapStore.map;
    let layerFound = false;

    if (!map) {
        throw new Error('Map is undefined');
    }

    map.getLayers()
        .getArray()
        .forEach((layer: BaseLayer) => {
            if (layer.getProperties().id?.toString() === layerId && layer instanceof VectorLayer) {
                layerFound = true;
                const layerSource = layer.getSource() as VectorSource;
                const featureToUpdate = layerSource.getFeatureById(featureIdToUpdate);
                if (!featureToUpdate) {
                    return;
                }

                Object.keys(featureToUpdate.getProperties()).forEach((propertyKey: string) => {
                    if (updatedObject.dataForm[propertyKey]) {
                        featureToUpdate.setProperties({
                            [propertyKey]: updatedObject.dataForm[propertyKey],
                        });
                    }
                });

                layer.changed();
            }
        });

    if (!layerFound) {
        console.warn(`[form/updateFeatureToLayerSource] No layer ${layerId} found.`);
        Sentry.captureException(`[form/updateFeatureToLayerSource] No layer ${layerId} found.`);

        return;
    }
}

export function updateLayers(mapLayers: Array<BaseLayer>, isOffline: boolean): void {
    if (isOffline) {
        return;
    }

    mapLayers
        .filter(
            (layer: BaseLayer) =>
                layer.getProperties().global === false &&
                layer.getProperties().format === ProjectConfigurationLayerFormatEnum.GEOJSON,
        )
        .forEach((layer: BaseLayer) => {
            if (layer instanceof VectorLayer) {
                (layer.getSource() as VectorSource).clear();
                (layer.getSource() as VectorSource).refresh();
            }
        });
}
