import type { OptionsInterface, SelectInterface } from '@connect-field/client/components/forms/form.types';

export const labels: Record<string, string> = {
    commentaire_sst: 'Commentaire',
    levee_reserve: 'Levée de réserve',
    obj_rattach: 'Code boitier',
    photos: 'Photo de la levée de réserve',
};

export const options: Record<string, OptionsInterface | SelectInterface> = {
    levee_reserve: { false: 'NON', true: 'OUI' },
};
