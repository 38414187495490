export const labels = {
    adresse: 'Adresse',
    code_insee: 'Code INSEE',
    code_rivol: 'Code RIVOLI',
    com_audit: 'Commentaire',
    commune: 'Commune Equipement',
    constructe: 'Constructeur',
    date_visite: 'Date Visite Equipement',
    designatio: 'Désignation',
    etiquett00: "Complément d'étiquette",
    etiquette_: 'Etiquette',
    id_corolle: 'Identifiant',
    img_amplis: 'Photo Ampli',
    img_armoire_porte: 'Photo Porte',
    img_coupleur: 'Photo Coupleur',
    img_env: 'Photo Environnement',
    img_fo: 'Photo Fibre',
    img_pres_alim_locale: 'Photo alimentation locale',
    img_tiroir_fo: 'Photo Fibre',
    nom_commun: 'Ville',
    num_rue: 'Rue Equipement',
    position: 'Position Equipement',
    pres_alim_locale: 'Présence alimentation locale',
    pres_ampli: 'Présence Ampli',
    pres_coupleur: 'Présence Coupleur',
    pres_fibre: 'Présence Fibre',
    pres_tiroir_fo: 'Présence Fibre',
    rec_audit: 'Audit réalisable',
    rec_audit_com: 'Commentaire',
    reference: 'Référence',
    statut_audit: "Statut de l'audit",
    statut_position: 'Statut position Equipement',
    type_equip: "Type d'équipement",
    ver_porte: 'Porte Verouillée',
};

export const options = {
    designatio: [{ label: '', value: '' }],
    pres_alim_locale: { false: 'Non', true: 'Oui' },
    pres_ampli: { false: 'Non', true: 'Oui' },
    pres_coupleur: { false: 'Non', true: 'Oui' },
    pres_fibre: { false: 'Non', true: 'Oui' },
    pres_tiroir_fo: { false: 'Non', true: 'Oui' },
    rec_audit: [
        { label: 'Réalisable', value: 'Réalisable' },
        { label: "Problème d'accès", value: "Problème d'accès" },
        { label: 'Problème équipement', value: 'Problème équipement' },
    ],
    reference: [{ label: '', value: '' }],
    statut_audit: [
        { label: 'NON REALISE', value: 'NON REALISE' },
        { label: 'PARTIELLEMENT', value: 'PARTIELLEMENT' },
        { label: 'TERMINE', value: 'TERMINE' },
    ],
    statut_position: [
        { label: 'Correcte', value: 'Correcte' },
        { label: 'Incorrecte', value: 'Incorrecte' },
    ],
    type_equip: [{ label: '', value: '' }],
    ver_porte: { false: 'Non', true: 'Oui' },
};
