<template>
    <div>
        <table
            v-for="(cassette, cassetteId) in cassettesData"
            :key="cassetteId"
        >
            <tr>
                <th>
                    <FontAwesomeIcon
                        size="1x"
                        icon="left-from-line"
                    />
                    Cable
                </th>
                <th>
                    <FontAwesomeIcon
                        size="1x"
                        icon="left-from-line"
                    />
                    Tube
                </th>
                <th>Cassette</th>
                <th>
                    <FontAwesomeIcon
                        size="1x"
                        icon="right-from-line"
                    />
                    Tube
                </th>
                <th>
                    <FontAwesomeIcon
                        size="1x"
                        icon="right-from-line"
                    />
                    Cable
                </th>
            </tr>
            <tr
                v-for="(cable, index) in cassette"
                :key="index"
                class="cable"
            >
                <td>{{ cable.cable_entrant.slice(-4) }}</td>
                <td>
                    <TubeEntrant
                        :position="cable.numero_tube_entrant"
                        :color="cable.couleur_tube_entrant"
                    />
                </td>
                <td
                    v-if="index === 0"
                    rowspan="0"
                    class="upright"
                >
                    {{ cable.cassette }}
                </td>
                <td>
                    <TubeSortant
                        v-if="cable.numero_tube_sortant"
                        :position="cable.numero_tube_sortant"
                        :color="cable.couleur_tube_sortant"
                    />
                </td>
                <td class="text-right">{{ cable.cable_sortant?.slice(-4) }}</td>
            </tr>
        </table>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'pinia';

import TubeEntrant from '@connect-field/client/components/forms/PlanBoite/TubeEntrant.vue';
import TubeSortant from '@connect-field/client/components/forms/PlanBoite/TubeSortant.vue';

import useBoxPlanStore from '@connect-field/client/stores/boxPlan';

export default defineComponent({
    components: {
        TubeEntrant,
        TubeSortant,
    },
    computed: {
        ...mapState(useBoxPlanStore, {
            cassettesData: 'cassettesData',
        }),
    },
});
</script>

<style scoped lang="scss">
table {
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;

    td,
    th {
        padding: 4px;
    }

    th {
        text-align: center;
    }
}

.upright {
    writing-mode: vertical-lr;
    text-orientation: upright;
}
</style>
