<template>
    <FormWrapper
        v-if="!loading"
        ref="customFormRef"
        :images-model="images"
        :form-model="form"
        :mandatory-fields="mandatoryFields"
        :changes-model="changes"
        :layer-name="layerName"
        :object-id="objectId"
        :project-id="projectId"
        :latitude="latitude"
        :longitude="longitude"
        @update:images-model="
            (e) => {
                images = e;
            }
        "
        @update:value-missing="
            (e) => {
                valueMissing = e;
            }
        "
        @update:form-model="
            (e) => {
                form = e;
            }
        "
        @update:changes-model="
            (e) => {
                changes = e;
            }
        "
    >
        <template #menu_tabs>
            <el-row class="scroll-menu">
                <el-row :span="12">
                    <div
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'Général' }"
                        @click="selectTab('Général')"
                    >
                        Général
                    </div>
                </el-row>
                <el-row :span="12">
                    <div
                        class="tabs"
                        :class="{
                            selectedTab: selectedTab === 'Environnement',
                        }"
                        @click="selectTab('Environnement')"
                    >
                        Environnement
                    </div>
                </el-row>
                <el-row :span="12">
                    <div
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'Armoire' }"
                        @click="selectTab('Armoire')"
                    >
                        Armoire
                    </div>
                </el-row>
            </el-row>
        </template>
        <template #main_form>
            <el-row>
                <el-col
                    v-show="selectedTab === 'Général'"
                    :span="24"
                >
                    <CustomInput
                        :label="labels.id_corolle + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.id_corolle"
                        :maxlength="255"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.etiquette_ + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.etiquette_"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.etiquett00 + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.etiquett00"
                        :maxlength="255"
                        :disabled="true"
                    />
                    <CustomInputSelect
                        :label="labels.type_equip + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.type_equip"
                        :value="form.type_equip"
                        :disabled="true"
                    />
                    <CustomInputSelect
                        :label="labels.designatio + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.designatio"
                        :value="form.designatio"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.constructe + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.constructe"
                        :disabled="true"
                    />
                    <CustomInputSelect
                        :label="labels.reference + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.reference"
                        :value="form.reference"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.adresse + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.adresse"
                        :disabled="true"
                    />
                    <InputNumber
                        :label="labels.code_insee + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.code_insee"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.nom_commun + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.nom_commun"
                        :maxlength="255"
                        :disabled="true"
                    />
                    <InputNumber
                        :label="labels.code_rivol + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.code_rivol"
                        :disabled="true"
                    />
                </el-col>
                <el-col
                    v-show="selectedTab === 'Environnement'"
                    :span="24"
                >
                    <CustomRadioButton
                        :label="labels.statut_position + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.statut_position"
                        :value="form.statut_position"
                        size="small"
                        @update:value="
                            (newValue) => saveData('statut_position', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.rec_audit + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.rec_audit"
                        :value="form.rec_audit"
                        @update:value="
                            (newValue) => saveData('rec_audit', newValue)
                        "
                    />
                    <CustomInputTextarea
                        v-show="condition_rec_audit_com()"
                        :label="labels.rec_audit_com + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.rec_audit_com"
                        @update:value="
                            (newValue) => saveData('rec_audit_com', newValue)
                        "
                    />
                    <CustomDatePicker
                        :label="labels.date_visite + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.date_visite"
                        @update:value="
                            (newValue) => saveData('date_visite', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_env + ' *'"
                        :images="images.img_env"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('img_env', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commune + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.commune"
                        :maxlength="255"
                        @update:value="
                            (newValue) => saveData('commune', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.num_rue + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.num_rue"
                        @update:value="
                            (newValue) => saveData('num_rue', newValue)
                        "
                    />
                </el-col>
                <el-col
                    v-show="selectedTab === 'Armoire'"
                    :span="24"
                >
                    <CustomInputSwitch
                        v-show="condition_ver_porte()"
                        :label="labels.ver_porte + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.ver_porte"
                        :value="form.ver_porte"
                        @update:value="
                            (newValue) => saveData('ver_porte', newValue)
                        "
                    />
                    <InputCamera
                        v-show="condition_img_armoire_porte()"
                        :label="labels.img_armoire_porte + ' *'"
                        :images="images.img_armoire_porte"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_armoire_porte', newValue)
                        "
                    />
                    <CustomInputSwitch
                        v-show="condition_pres_alim_locale()"
                        :label="labels.pres_alim_locale + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.pres_alim_locale"
                        :value="form.pres_alim_locale"
                        @update:value="
                            (newValue) => saveData('pres_alim_locale', newValue)
                        "
                    />
                    <InputCamera
                        v-show="condition_img_pres_alim_locale()"
                        :label="labels.img_pres_alim_locale + ' *'"
                        :images="images.img_pres_alim_locale"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_pres_alim_locale', newValue)
                        "
                    />
                    <CustomInputSwitch
                        v-show="condition_pres_fibre()"
                        :label="labels.pres_fibre + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.pres_fibre"
                        :value="form.pres_fibre"
                        @update:value="
                            (newValue) => saveData('pres_fibre', newValue)
                        "
                    />
                    <InputCamera
                        v-show="condition_img_fo()"
                        :label="labels.img_fo + ' *'"
                        :images="images.img_fo"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('img_fo', newValue)
                        "
                    />
                    <CustomInputSwitch
                        v-show="condition_pres_tiroir_fo()"
                        :label="labels.pres_tiroir_fo + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.pres_tiroir_fo"
                        :value="form.pres_tiroir_fo"
                        @update:value="
                            (newValue) => saveData('pres_tiroir_fo', newValue)
                        "
                    />
                    <InputCamera
                        v-show="condition_img_tiroir_fo()"
                        :label="labels.img_tiroir_fo + ' *'"
                        :images="images.img_tiroir_fo"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('img_tiroir_fo', newValue)
                        "
                    />
                    <CustomInputSwitch
                        v-show="condition_pres_ampli()"
                        :label="labels.pres_ampli + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.pres_ampli"
                        :value="form.pres_ampli"
                        @update:value="
                            (newValue) => saveData('pres_ampli', newValue)
                        "
                    />
                    <InputCamera
                        v-show="condition_img_amplis()"
                        :label="labels.img_amplis + ' *'"
                        :images="images.img_amplis"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('img_amplis', newValue)
                        "
                    />
                    <CustomInputSwitch
                        v-show="condition_pres_coupleur()"
                        :label="labels.pres_coupleur + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.pres_coupleur"
                        :value="form.pres_coupleur"
                        @update:value="
                            (newValue) => saveData('pres_coupleur', newValue)
                        "
                    />
                    <InputCamera
                        v-show="condition_img_coupleur()"
                        :label="labels.img_coupleur + ' *'"
                        :images="images.img_coupleur"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('img_coupleur', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.statut_audit + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.statut_audit"
                        :value="form.statut_audit"
                        @update:value="
                            (newValue) => saveData('statut_audit', newValue)
                        "
                    />
                    <CustomInputTextarea
                        :label="labels.com_audit + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.com_audit"
                        @update:value="
                            (newValue) => saveData('com_audit', newValue)
                        "
                    />
                </el-col>
            </el-row>
        </template>
    </FormWrapper>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import {
    labels,
    options,
} from '@connect-field/client/components/forms/Dotic/SIPPEREC/ReleveArmoire.data';
import type { FormDataInterface } from '@connect-field/client/components/forms/form.types';
import FormTemplate from '@connect-field/client/components/forms/FormTemplate.vue';

interface DataInterface extends FormDataInterface {}

export default defineComponent({
    extends: FormTemplate,
    data(): DataInterface {
        return {
            changes: false,
            form: {},
            images: {
                img_amplis: [],
                img_armoire_porte: [],
                img_coupleur: [],
                img_env: [],
                img_fo: [],
                img_pres_alim_locale: [],
                img_tiroir_fo: [],
            },
            labels,
            loading: true,
            mandatoryFields: [
                'statut_position',
                'position',
                'rec_audit',
                'rec_audit_com',
                'date_visite',
                'img_env',
                'commune',
                'statut_audit',
            ],
            options,
            selectedTab: 'Général',
            valueMissing: false,
        };
    },
    methods: {
        condition_img_amplis() {
            const { rec_audit } = this.form;
            return rec_audit === 'Réalisable';
        },
        condition_img_armoire_porte() {
            const { rec_audit } = this.form;
            return rec_audit === 'Réalisable';
        },
        condition_img_coupleur() {
            const { rec_audit } = this.form;
            return rec_audit === 'Réalisable';
        },
        condition_img_fo() {
            const { rec_audit } = this.form;
            return rec_audit === 'Réalisable';
        },
        condition_img_pres_alim_locale() {
            const { rec_audit } = this.form;
            return rec_audit === 'Réalisable';
        },
        condition_img_tiroir_fo() {
            const { rec_audit } = this.form;
            return rec_audit === 'Réalisable';
        },
        condition_pres_alim_locale() {
            const { rec_audit } = this.form;
            return rec_audit === 'Réalisable';
        },
        condition_pres_ampli() {
            const { rec_audit } = this.form;
            return rec_audit === 'Réalisable';
        },
        condition_pres_coupleur() {
            const { rec_audit } = this.form;
            return rec_audit === 'Réalisable';
        },
        condition_pres_fibre() {
            const { rec_audit } = this.form;
            return rec_audit === 'Réalisable';
        },
        condition_pres_tiroir_fo() {
            const { rec_audit } = this.form;
            return rec_audit === 'Réalisable';
        },
        condition_rec_audit_com() {
            const { rec_audit } = this.form;
            return rec_audit !== 'Réalisable';
        },
        condition_ver_porte() {
            const { rec_audit } = this.form;
            return rec_audit === 'Réalisable';
        },
    },
});
</script>
