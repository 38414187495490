<template>
    <div>
        <BasePanelItemHeader :title="$t('globals.basemaps')" />
        <div class="slider_container">
            <OpacitySlider
                class="slider"
                :current-opacity="currentOpacity"
                :target="target"
                @value-change="changeOpacity"
            />
        </div>
        <main>
            <div
                v-for="layer in getBackgroundLayersData"
                :key="layer.name"
                class="button_container"
            >
                <BackgroundLayerButton
                    :layer-alias="layer.alias"
                    :layer-type="layer.type"
                    :layer-name="layer.name"
                    :is-active="getLayerVisibility(layer.name)"
                    :click-action="setLayerVisible"
                />
            </div>
        </main>
    </div>
</template>

<script lang="ts">
import type BaseLayer from 'ol/layer/Base';
import { defineComponent } from 'vue';

import BackgroundLayerButton from '@connect-field/client/components/atoms/BackgroundLayerButton.vue';
import BasePanelItemHeader from '@connect-field/client/components/ui/BasePanelItemHeader.vue';
import OpacitySlider from '@connect-field/client/components/layer/OpacitySlider.vue';
import useMapStore from '@connect-field/client/stores/map';

interface DataInterface {
    currentOpacity: number;
    target: 'Défaut' | 'Satellite';
}

export default defineComponent({
    components: {
        BackgroundLayerButton,
        BasePanelItemHeader,
        OpacitySlider,
    },
    setup() {
        return {
            mapStore: useMapStore(),
        };
    },
    data(): DataInterface {
        return {
            currentOpacity: 100,
            target: 'Défaut',
        };
    },
    computed: {
        getBackgroundLayers(): Array<BaseLayer> {
            return this.map
                .getLayers()
                .getArray()
                .filter(
                    (layer: BaseLayer) =>
                        layer.getProperties().isBackgroundLayer,
                );
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getBackgroundLayersData(): Record<string, any> {
            return this.map
                .getLayers()
                .getArray()
                .filter(
                    (layer: BaseLayer) =>
                        layer.getProperties().isBackgroundLayer,
                )
                .map((layer: BaseLayer) => {
                    const { name, alias, type } = layer.getProperties();

                    return { alias, name, type };
                });
        },
        map() {
            if (!this.mapStore.map) {
                throw new Error('Map is undefined');
            }

            return this.mapStore.map;
        },
    },
    mounted() {
        const layer = this.getBackgroundLayers.find((layer: BaseLayer) =>
            layer.get('visible'),
        );
        if (layer) {
            this.target =
                layer.get('name') === 'background_IGN' ? 'Satellite' : 'Défaut';
            this.currentOpacity = Math.trunc(layer.getOpacity() * 100);
        }
    },
    methods: {
        changeOpacity(value: string): void {
            const layer = this.getBackgroundLayers.find((layer: BaseLayer) =>
                layer.get('visible'),
            );
            if (layer) {
                layer.setOpacity(parseInt(value) / 100);
            }
        },
        getLayerVisibility(name: string): boolean {
            const layer = this.getBackgroundLayers.find(
                (layer: BaseLayer) => layer.get('name') === name,
            );

            if (layer) {
                return layer.getVisible();
            }

            return false;
        },
        setLayerVisible(name: string): void {
            this.getBackgroundLayers.forEach((layer: BaseLayer) => {
                if (layer.get('name') === name) {
                    layer.setVisible(true);
                    this.currentOpacity = Math.trunc(layer.getOpacity() * 100);
                } else {
                    layer.setVisible(false);
                }
            });
        },
    },
});
</script>

<style scoped lang="scss">
main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.slider_container {
    display: flex;
    padding: 1rem;
    color: white;
    font-size: 14px;
    width: 100%;
}
</style>
