<template>
    <FormWrapper
        v-if="!loading"
        ref="customFormRef"
        :images-model="images"
        :form-model="form"
        :mandatory-fields="mandatoryFields"
        :changes-model="changes"
        :layer-name="layerName"
        :object-id="objectId"
        :project-id="projectId"
        :latitude="latitude"
        :longitude="longitude"
        @update:images-model="
            (e) => {
                images = e;
            }
        "
        @update:value-missing="
            (e) => {
                valueMissing = e;
            }
        "
        @update:form-model="
            (e) => {
                form = e;
            }
        "
        @update:changes-model="
            (e) => {
                changes = e;
            }
        "
    >
        <template #menu_tabs>
            <el-row class="scroll-menu">
                <el-col :span="10">
                    <div
                        @click="selectTab('GENERAL')"
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'GENERAL' }"
                    >
                        GENERAL
                    </div>
                </el-col>

                <el-col :span="10">
                    <div
                        @click="selectTab('SITE SRO')"
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'SITE SRO' }"
                    >
                        SITE SRO
                    </div>
                </el-col>

                <el-col :span="12">
                    <div
                        @click="selectTab('Tiroir Transport')"
                        class="tabs"
                        :class="{
                            selectedTab: selectedTab === 'Tiroir Transport',
                        }"
                    >
                        Tiroir Transport
                    </div>
                </el-col>

                <el-col :span="12">
                    <div
                        @click="selectTab('Tiroir Coupleur')"
                        class="tabs"
                        :class="{
                            selectedTab: selectedTab === 'Tiroir Coupleur',
                        }"
                    >
                        Tiroir Coupleur
                    </div>
                </el-col>

                <el-col :span="10">
                    <div
                        @click="selectTab('SITE NRO')"
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'SITE NRO' }"
                    >
                        SITE NRO
                    </div>
                </el-col>

                <el-col :span="10">
                    <div
                        @click="selectTab('ACTIVATION SRO')"
                        class="tabs"
                        :class="{
                            selectedTab: selectedTab === 'ACTIVATION SRO',
                        }"
                    >
                        ACTIVATION SRO
                    </div>
                </el-col>

                <el-col :span="12">
                    <div
                        @click="selectTab('ALTILINE')"
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'ALTILINE' }"
                    >
                        ALTILINE
                    </div>
                </el-col>

                <el-col :span="12">
                    <div
                        @click="selectTab('NETGEO NRO TTR')"
                        class="tabs"
                        :class="{
                            selectedTab: selectedTab === 'NETGEO NRO TTR',
                        }"
                    >
                        NETGEO NRO TTR
                    </div>
                </el-col>

                <el-col :span="12">
                    <div
                        @click="selectTab('NETGEO SRO')"
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'NETGEO SRO' }"
                    >
                        NETGEO SRO
                    </div>
                </el-col>
            </el-row>
        </template>
        <template #main_form>
            <el-row>
                <el-col
                    :span="24"
                    v-show="selectedTab === 'GENERAL'"
                >
                    <CustomInput
                        :label="labels.ref_sro + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.ref_sro"
                        :maxlength="254"
                        :disabled="true"
                    />
                    <CustomDatePicker
                        :label="labels.date_audit + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.date_audit"
                        @update:value="
                            (newValue) => saveData('date_audit', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.adresse + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.adresse"
                        :maxlength="254"
                        :disabled="true"
                    />
                    <CustomInputSelect
                        :label="labels.statut_audit_terrain + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.statut_audit_terrain"
                        :value="form.statut_audit_terrain"
                        @update:value="
                            (newValue) =>
                                saveData('statut_audit_terrain', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.statut_audit_netgeo + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.statut_audit_netgeo"
                        :value="form.statut_audit_netgeo"
                        @update:value="
                            (newValue) =>
                                saveData('statut_audit_netgeo', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.statut_audit_altiline + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.statut_audit_altiline"
                        :value="form.statut_audit_altiline"
                        @update:value="
                            (newValue) =>
                                saveData('statut_audit_altiline', newValue)
                        "
                    />
                </el-col>
                <el-col
                    :span="24"
                    v-show="selectedTab === 'SITE SRO'"
                >
                    <CustomInputSelect
                        :label="labels.ss_type"
                        :options="options.ss_type"
                        :value="form.ss_type"
                        @update:value="
                            (newValue) => saveData('ss_type', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.ss_environnement"
                        :options="options.ss_environnement"
                        :value="form.ss_environnement"
                        @update:value="
                            (newValue) => saveData('ss_environnement', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.environnement"
                        :images="images.environnement"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('environnement', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.ss_clef"
                        :options="options.ss_clef"
                        :value="form.ss_clef"
                        @update:value="
                            (newValue) => saveData('ss_clef', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.ss_type_clef"
                        :options="options.ss_type_clef"
                        :value="form.ss_type_clef"
                        @update:value="
                            (newValue) => saveData('ss_type_clef', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.ss_site"
                        :options="options.ss_site"
                        :value="form.ss_site"
                        @update:value="
                            (newValue) => saveData('ss_site', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.ss_acces"
                        :options="options.ss_acces"
                        :value="form.ss_acces"
                        @update:value="
                            (newValue) => saveData('ss_acces', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.ss_plaque_pe"
                        :options="options.ss_plaque_pe"
                        :value="form.ss_plaque_pe"
                        @update:value="
                            (newValue) => saveData('ss_plaque_pe', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.baie_fermee"
                        :images="images.baie_fermee"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('baie_fermee', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.ss_etat_general"
                        :options="options.ss_etat_general"
                        :value="form.ss_etat_general"
                        @update:value="
                            (newValue) => saveData('ss_etat_general', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.ss_fermeture"
                        :options="options.ss_fermeture"
                        :value="form.ss_fermeture"
                        @update:value="
                            (newValue) => saveData('ss_fermeture', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.baie_ouverte"
                        :images="images.baie_ouverte"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('baie_ouverte', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.ss_abaque"
                        :options="options.ss_abaque"
                        :value="form.ss_abaque"
                        @update:value="
                            (newValue) => saveData('ss_abaque', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.abaque"
                        :images="images.abaque"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('abaque', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.ss_plaquebaie_pe"
                        :options="options.ss_plaquebaie_pe"
                        :value="form.ss_plaquebaie_pe"
                        @update:value="
                            (newValue) => saveData('ss_plaquebaie_pe', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.baiea1"
                        :images="images.baiea1"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('baiea1', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.baiea2"
                        :images="images.baiea2"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('baiea2', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.ss_plot_pe"
                        :options="options.ss_plot_pe"
                        :value="form.ss_plot_pe"
                        @update:value="
                            (newValue) => saveData('ss_plot_pe', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.ss_obturationfourreau"
                        :options="options.ss_obturationfourreau"
                        :value="form.ss_obturationfourreau"
                        @update:value="
                            (newValue) =>
                                saveData('ss_obturationfourreau', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.ss_eti_cablectr_pe"
                        :options="options.ss_eti_cablectr_pe"
                        :value="form.ss_eti_cablectr_pe"
                        @update:value="
                            (newValue) =>
                                saveData('ss_eti_cablectr_pe', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.etiquette_cable"
                        :images="images.etiquette_cable"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('etiquette_cable', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.ss_terre"
                        :options="options.ss_terre"
                        :value="form.ss_terre"
                        @update:value="
                            (newValue) => saveData('ss_terre', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.ss_eclateur_cc"
                        :options="options.ss_eclateur_cc"
                        :value="form.ss_eclateur_cc"
                        @update:value="
                            (newValue) => saveData('ss_eclateur_cc', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_sro_eclateur"
                        :images="images.img_sro_eclateur"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_sro_eclateur', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.ss_ch0"
                        :options="options.ss_ch0"
                        :value="form.ss_ch0"
                        @update:value="
                            (newValue) => saveData('ss_ch0', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_sro_chambre0"
                        :images="images.img_sro_chambre0"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_sro_chambre0', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.ss_obs"
                        :value="form.ss_obs"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('ss_obs', newValue)
                        "
                    />
                </el-col>
                <el-col
                    :span="24"
                    v-show="selectedTab === 'Tiroir Transport'"
                >
                    <CustomInputSwitch
                        :label="labels.tt_pos"
                        :options="options.tt_pos"
                        :value="form.tt_pos"
                        @update:value="
                            (newValue) => saveData('tt_pos', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.tt_etat"
                        :options="options.tt_etat"
                        :value="form.tt_etat"
                        @update:value="
                            (newValue) => saveData('tt_etat', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.tt_eti_pe"
                        :options="options.tt_eti_pe"
                        :value="form.tt_eti_pe"
                        @update:value="
                            (newValue) => saveData('tt_eti_pe', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.ttr"
                        :images="images.ttr"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('ttr', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.tt_capuchon_pe"
                        :options="options.tt_capuchon_pe"
                        :value="form.tt_capuchon_pe"
                        @update:value="
                            (newValue) => saveData('tt_capuchon_pe', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.tt_connecteur"
                        :options="options.tt_connecteur"
                        :value="form.tt_connecteur"
                        @update:value="
                            (newValue) => saveData('tt_connecteur', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.tt_ri"
                        :options="options.tt_ri"
                        :value="form.tt_ri"
                        @update:value="
                            (newValue) => saveData('tt_ri', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.plateaux_ttr"
                        :images="images.plateaux_ttr"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('plateaux_ttr', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.tt_terrain_sig"
                        :options="options.tt_terrain_sig"
                        :value="form.tt_terrain_sig"
                        @update:value="
                            (newValue) => saveData('tt_terrain_sig', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.tt_mesure"
                        :options="options.tt_mesure"
                        :value="form.tt_mesure"
                        @update:value="
                            (newValue) => saveData('tt_mesure', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.tt_obs"
                        :value="form.tt_obs"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('tt_obs', newValue)
                        "
                    />
                </el-col>
                <el-col
                    :span="24"
                    v-show="selectedTab === 'Tiroir Coupleur'"
                >
                    <CustomInputSwitch
                        :label="labels.tc_position"
                        :options="options.tc_position"
                        :value="form.tc_position"
                        @update:value="
                            (newValue) => saveData('tc_position', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.tc_etat"
                        :options="options.tc_etat"
                        :value="form.tc_etat"
                        @update:value="
                            (newValue) => saveData('tc_etat', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.tc_eti_pe"
                        :options="options.tc_eti_pe"
                        :value="form.tc_eti_pe"
                        @update:value="
                            (newValue) => saveData('tc_eti_pe', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.cpl"
                        :images="images.cpl"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('cpl', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.tc_capuchon_pe"
                        :options="options.tc_capuchon_pe"
                        :value="form.tc_capuchon_pe"
                        @update:value="
                            (newValue) => saveData('tc_capuchon_pe', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.tc_connecteur"
                        :options="options.tc_connecteur"
                        :value="form.tc_connecteur"
                        @update:value="
                            (newValue) => saveData('tc_connecteur', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.tc_plaque_pe"
                        :options="options.tc_plaque_pe"
                        :value="form.tc_plaque_pe"
                        @update:value="
                            (newValue) => saveData('tc_plaque_pe', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.tc_jarretiere_pe"
                        :options="options.tc_jarretiere_pe"
                        :value="form.tc_jarretiere_pe"
                        @update:value="
                            (newValue) => saveData('tc_jarretiere_pe', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.tc_eti_jarretiere_pe"
                        :options="options.tc_eti_jarretiere_pe"
                        :value="form.tc_eti_jarretiere_pe"
                        @update:value="
                            (newValue) =>
                                saveData('tc_eti_jarretiere_pe', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.etiquette_jarretiere"
                        :images="images.etiquette_jarretiere"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('etiquette_jarretiere', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.tc_obs"
                        :value="form.tc_obs"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('tc_obs', newValue)
                        "
                    />
                </el-col>
                <el-col
                    :span="24"
                    v-show="selectedTab === 'SITE NRO'"
                >
                    <CustomInput
                        :label="labels.ref_nro"
                        :value="form.ref_nro"
                        :disabled="true"
                    />
                    <CustomInputSwitch
                        :label="labels.sn_obturation"
                        :options="options.sn_obturation"
                        :value="form.sn_obturation"
                        @update:value="
                            (newValue) => saveData('sn_obturation', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.sn_eti_pe_ctr"
                        :options="options.sn_eti_pe_ctr"
                        :value="form.sn_eti_pe_ctr"
                        @update:value="
                            (newValue) => saveData('sn_eti_pe_ctr', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.etiquette_ctr_nro"
                        :images="images.etiquette_ctr_nro"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('etiquette_ctr_nro', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.sn_position"
                        :options="options.sn_position"
                        :value="form.sn_position"
                        @update:value="
                            (newValue) => saveData('sn_position', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.sn_etat"
                        :options="options.sn_etat"
                        :value="form.sn_etat"
                        @update:value="
                            (newValue) => saveData('sn_etat', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.sn_eti_pe_ttr"
                        :options="options.sn_eti_pe_ttr"
                        :value="form.sn_eti_pe_ttr"
                        @update:value="
                            (newValue) => saveData('sn_eti_pe_ttr', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.ttr_nro"
                        :images="images.ttr_nro"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('ttr_nro', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.sn_capuchon_pe"
                        :options="options.sn_capuchon_pe"
                        :value="form.sn_capuchon_pe"
                        @update:value="
                            (newValue) => saveData('sn_capuchon_pe', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.sn_connecteur"
                        :options="options.sn_connecteur"
                        :value="form.sn_connecteur"
                        @update:value="
                            (newValue) => saveData('sn_connecteur', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.sn_ri"
                        :options="options.sn_ri"
                        :value="form.sn_ri"
                        @update:value="
                            (newValue) => saveData('sn_ri', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.plateaux_ttr_nro"
                        :images="images.plateaux_ttr_nro"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('plateaux_ttr_nro', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.sn_terrain_sig"
                        :options="options.sn_terrain_sig"
                        :value="form.sn_terrain_sig"
                        @update:value="
                            (newValue) => saveData('sn_terrain_sig', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.sn_obs"
                        :value="form.sn_obs"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('sn_obs', newValue)
                        "
                    />
                </el-col>
                <el-col
                    :span="24"
                    v-show="selectedTab === 'ACTIVATION SRO'"
                >
                    <CustomInputSwitch
                        :label="labels.as_activ_coupleur"
                        :options="options.as_activ_coupleur"
                        :value="form.as_activ_coupleur"
                        @update:value="
                            (newValue) =>
                                saveData('as_activ_coupleur', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.as_branchement_jarretiere"
                        :options="options.as_branchement_jarretiere"
                        :value="form.as_branchement_jarretiere"
                        @update:value="
                            (newValue) =>
                                saveData('as_branchement_jarretiere', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.as_nom_jarretiere"
                        :options="options.as_nom_jarretiere"
                        :value="form.as_nom_jarretiere"
                        @update:value="
                            (newValue) =>
                                saveData('as_nom_jarretiere', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.branchement_jarretiere"
                        :images="images.branchement_jarretiere"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('branchement_jarretiere', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.as_nom_ttr"
                        :value="form.as_nom_ttr"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('as_nom_ttr', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.as_ligne_connect"
                        :value="form.as_ligne_connect"
                        @update:value="
                            (newValue) => saveData('as_ligne_connect', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.as_colonne_connect"
                        :value="form.as_colonne_connect"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData('as_colonne_connect', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.as_puissance_cpl"
                        :options="options.as_puissance_cpl"
                        :value="form.as_puissance_cpl"
                        @update:value="
                            (newValue) => saveData('as_puissance_cpl', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.puissance_metre"
                        :images="images.puissance_metre"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('puissance_metre', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.as_obs"
                        :value="form.as_obs"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('as_obs', newValue)
                        "
                    />
                </el-col>
                <el-col
                    :span="24"
                    v-show="selectedTab === 'ALTILINE'"
                >
                    <CustomInputSwitch
                        :label="labels.al_position_gps"
                        :options="options.al_position_gps"
                        :value="form.al_position_gps"
                        @update:value="
                            (newValue) => saveData('al_position_gps', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.al_adresse"
                        :options="options.al_adresse"
                        :value="form.al_adresse"
                        @update:value="
                            (newValue) => saveData('al_adresse', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.al_acces"
                        :options="options.al_acces"
                        :value="form.al_acces"
                        @update:value="
                            (newValue) => saveData('al_acces', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.al_chainage"
                        :options="options.al_chainage"
                        :value="form.al_chainage"
                        @update:value="
                            (newValue) => saveData('al_chainage', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.al_date_construction"
                        :options="options.al_date_construction"
                        :value="form.al_date_construction"
                        @update:value="
                            (newValue) =>
                                saveData('al_date_construction', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.al_date_maintenance"
                        :options="options.al_date_maintenance"
                        :value="form.al_date_maintenance"
                        @update:value="
                            (newValue) =>
                                saveData('al_date_maintenance', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.al_obs"
                        :value="form.al_obs"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('al_obs', newValue)
                        "
                    />
                </el-col>
                <el-col
                    :span="24"
                    v-show="selectedTab === 'NETGEO NRO TTR'"
                >
                    <CustomInputSwitch
                        :label="labels.nt_nom"
                        :options="options.nt_nom"
                        :value="form.nt_nom"
                        @update:value="
                            (newValue) => saveData('nt_nom', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nt_code"
                        :options="options.nt_code"
                        :value="form.nt_code"
                        @update:value="
                            (newValue) => saveData('nt_code', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nt_gestionnaire"
                        :options="options.nt_gestionnaire"
                        :value="form.nt_gestionnaire"
                        @update:value="
                            (newValue) => saveData('nt_gestionnaire', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nt_proprietaire"
                        :options="options.nt_proprietaire"
                        :value="form.nt_proprietaire"
                        @update:value="
                            (newValue) => saveData('nt_proprietaire', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nt_typestruc"
                        :options="options.nt_typestruc"
                        :value="form.nt_typestruc"
                        @update:value="
                            (newValue) => saveData('nt_typestruc', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nt_typefonc"
                        :options="options.nt_typefonc"
                        :value="form.nt_typefonc"
                        @update:value="
                            (newValue) => saveData('nt_typefonc', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nt_etat"
                        :options="options.nt_etat"
                        :value="form.nt_etat"
                        @update:value="
                            (newValue) => saveData('nt_etat', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nt_capacite_tiroir"
                        :options="options.nt_capacite_tiroir"
                        :value="form.nt_capacite_tiroir"
                        @update:value="
                            (newValue) =>
                                saveData('nt_capacite_tiroir', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nt_cable"
                        :options="options.nt_cable"
                        :value="form.nt_cable"
                        @update:value="
                            (newValue) => saveData('nt_cable', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nt_terrain_sig"
                        :options="options.nt_terrain_sig"
                        :value="form.nt_terrain_sig"
                        @update:value="
                            (newValue) => saveData('nt_terrain_sig', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.nt_obs"
                        :value="form.nt_obs"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('nt_obs', newValue)
                        "
                    />
                </el-col>
                <el-col
                    :span="24"
                    v-show="selectedTab === 'NETGEO SRO'"
                >
                    <CustomInputSwitch
                        :label="labels.nss_nom"
                        :options="options.nss_nom"
                        :value="form.nss_nom"
                        @update:value="
                            (newValue) => saveData('nss_nom', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nss_code"
                        :options="options.nss_code"
                        :value="form.nss_code"
                        @update:value="
                            (newValue) => saveData('nss_code', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nss_adresse"
                        :options="options.nss_adresse"
                        :value="form.nss_adresse"
                        @update:value="
                            (newValue) => saveData('nss_adresse', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nss_gestionnaire"
                        :options="options.nss_gestionnaire"
                        :value="form.nss_gestionnaire"
                        @update:value="
                            (newValue) => saveData('nss_gestionnaire', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nss_proprietaire"
                        :options="options.nss_proprietaire"
                        :value="form.nss_proprietaire"
                        @update:value="
                            (newValue) => saveData('nss_proprietaire', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nss_typefonc"
                        :options="options.nss_typefonc"
                        :value="form.nss_typefonc"
                        @update:value="
                            (newValue) => saveData('nss_typefonc', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nss_typestruc"
                        :options="options.nss_typestruc"
                        :value="form.nss_typestruc"
                        @update:value="
                            (newValue) => saveData('nss_typestruc', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nss_etat"
                        :options="options.nss_etat"
                        :value="form.nss_etat"
                        @update:value="
                            (newValue) => saveData('nss_etat', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nsbc_nom"
                        :options="options.nsbc_nom"
                        :value="form.nsbc_nom"
                        @update:value="
                            (newValue) => saveData('nsbc_nom', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nsbc_gestionnaire"
                        :options="options.nsbc_gestionnaire"
                        :value="form.nsbc_gestionnaire"
                        @update:value="
                            (newValue) =>
                                saveData('nsbc_gestionnaire', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nsbc_proprietaire"
                        :options="options.nsbc_proprietaire"
                        :value="form.nsbc_proprietaire"
                        @update:value="
                            (newValue) =>
                                saveData('nsbc_proprietaire', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nsbc_etat"
                        :options="options.nsbc_etat"
                        :value="form.nsbc_etat"
                        @update:value="
                            (newValue) => saveData('nsbc_etat', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nsbc_type"
                        :options="options.nsbc_type"
                        :value="form.nsbc_type"
                        @update:value="
                            (newValue) => saveData('nsbc_type', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nsbc_capacite"
                        :options="options.nsbc_capacite"
                        :value="form.nsbc_capacite"
                        @update:value="
                            (newValue) => saveData('nsbc_capacite', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nsbt_nom"
                        :options="options.nsbt_nom"
                        :value="form.nsbt_nom"
                        @update:value="
                            (newValue) => saveData('nsbt_nom', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nsbt_code"
                        :options="options.nsbt_code"
                        :value="form.nsbt_code"
                        @update:value="
                            (newValue) => saveData('nsbt_code', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nsbt_gestionnaire"
                        :options="options.nsbt_gestionnaire"
                        :value="form.nsbt_gestionnaire"
                        @update:value="
                            (newValue) =>
                                saveData('nsbt_gestionnaire', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nsbt_proprietaire"
                        :options="options.nsbt_proprietaire"
                        :value="form.nsbt_proprietaire"
                        @update:value="
                            (newValue) =>
                                saveData('nsbt_proprietaire', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nsbt_etat"
                        :options="options.nsbt_etat"
                        :value="form.nsbt_etat"
                        @update:value="
                            (newValue) => saveData('nsbt_etat', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nsbt_type"
                        :options="options.nsbt_type"
                        :value="form.nsbt_type"
                        @update:value="
                            (newValue) => saveData('nsbt_type', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nsbt_capacite"
                        :options="options.nsbt_capacite"
                        :value="form.nsbt_capacite"
                        @update:value="
                            (newValue) => saveData('nsbt_capacite', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nstt_nom"
                        :options="options.nstt_nom"
                        :value="form.nstt_nom"
                        @update:value="
                            (newValue) => saveData('nstt_nom', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nstt_code"
                        :options="options.nstt_code"
                        :value="form.nstt_code"
                        @update:value="
                            (newValue) => saveData('nstt_code', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nstt_gestionnaire"
                        :options="options.nstt_gestionnaire"
                        :value="form.nstt_gestionnaire"
                        @update:value="
                            (newValue) =>
                                saveData('nstt_gestionnaire', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nstt_proprietaire"
                        :options="options.nstt_proprietaire"
                        :value="form.nstt_proprietaire"
                        @update:value="
                            (newValue) =>
                                saveData('nstt_proprietaire', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nstt_typefonc"
                        :options="options.nstt_typefonc"
                        :value="form.nstt_typefonc"
                        @update:value="
                            (newValue) => saveData('nstt_typefonc', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nstt_typestruc"
                        :options="options.nstt_typestruc"
                        :value="form.nstt_typestruc"
                        @update:value="
                            (newValue) => saveData('nstt_typestruc', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nstt_etat"
                        :options="options.nstt_etat"
                        :value="form.nstt_etat"
                        @update:value="
                            (newValue) => saveData('nstt_etat', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nstt_capacite"
                        :options="options.nstt_capacite"
                        :value="form.nstt_capacite"
                        @update:value="
                            (newValue) => saveData('nstt_capacite', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nstt_cable_racco"
                        :options="options.nstt_cable_racco"
                        :value="form.nstt_cable_racco"
                        @update:value="
                            (newValue) => saveData('nstt_cable_racco', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.nstt_terrain_sig"
                        :options="options.nstt_terrain_sig"
                        :value="form.nstt_terrain_sig"
                        @update:value="
                            (newValue) => saveData('nstt_terrain_sig', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.ns_reserve_rop"
                        :options="options.ns_reserve_rop"
                        :value="form.ns_reserve_rop"
                        @update:value="
                            (newValue) => saveData('ns_reserve_rop', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.ns_obs"
                        :value="form.ns_obs"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('ns_obs', newValue)
                        "
                    />
                </el-col>
            </el-row>
        </template>
    </FormWrapper>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import {
    labels,
    options,
} from '@connect-field/client/components/forms/AIC/QR/t_qr_sro.data';
import type { FormDataInterface } from '@connect-field/client/components/forms/form.types';
import FormTemplate from '@connect-field/client/components/forms/FormTemplate.vue';

interface DataInterface extends FormDataInterface {}

export default defineComponent({
    extends: FormTemplate,
    data(): DataInterface {
        return {
            changes: false,
            form: {},
            images: {
                environnement: [],
                baie_fermee: [],
                baie_ouverte: [],
                abaque: [],
                baiea1: [],
                baiea2: [],
                etiquette_cable: [],
                img_sro_eclateur: [],
                img_sro_chambre0: [],
                ttr: [],
                plateaux_ttr: [],
                cpl: [],
                etiquette_jarretiere: [],
                etiquette_ctr_nro: [],
                ttr_nro: [],
                plateaux_ttr_nro: [],
                branchement_jarretiere: [],
                puissance_metre: [],
            },
            labels,
            loading: true,
            mandatoryFields: [
                'ref_sro',
                'date_audit',
                'adresse',
                'statut_audit_terrain',
                'statut_audit_netgeo',
                'statut_audit_altiline',
            ],
            options,
            selectedTab: 'GENERAL',
            valueMissing: false,
        };
    },
    methods: {},
});
</script>
