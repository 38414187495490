<template>
    <FormWrapper
        v-if="!loading"
        ref="customFormRef"
        :images-model="images"
        :form-model="form"
        :mandatory-fields="mandatoryFields"
        :changes-model="changes"
        :layer-name="layerName"
        :object-id="objectId"
        :project-id="projectId"
        :latitude="latitude"
        :longitude="longitude"
        @update:images-model="
            (e) => {
                images = e;
            }
        "
        @update:value-missing="
            (e) => {
                valueMissing = e;
            }
        "
        @update:form-model="
            (e) => {
                form = e;
            }
        "
        @update:changes-model="
            (e) => {
                changes = e;
            }
        "
    >
        <template #menu_tabs>
            <el-row class="scroll-menu">
                <el-col :span="8">
                    <div
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'environment' }"
                        @click="selectTab('environment')"
                    >
                        <FontAwesomeIcon
                            size="xs"
                            icon="leaf"
                        />
                    </div>
                </el-col>
                <el-col :span="8">
                    <div
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'box' }"
                        @click="selectTab('box')"
                    >
                        <FontAwesomeIcon
                            size="xs"
                            icon="box"
                        />
                    </div>
                </el-col>
                <el-col :span="8">
                    <div
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'optical' }"
                        @click="selectTab('optical')"
                    >
                        <FontAwesomeIcon
                            size="xs"
                            icon="diagram-project"
                        />
                    </div>
                </el-col>
            </el-row>
        </template>
        <template #main_form>
            <el-row>
                <el-col
                    v-show="selectedTab === 'environment'"
                    :span="24"
                >
                    <CustomInput
                        :label="labels.code"
                        :value="form.code"
                        :disabled="true"
                        :maxlength="255"
                    />
                    <CustomInput
                        :label="labels.adresse"
                        :value="form.adresse"
                        :disabled="true"
                        :maxlength="255"
                    />
                    <CustomDatePicker
                        :label="labels.date_audit"
                        :value="form.date_audit"
                        @update:value="
                            (newValue) => saveData('date_audit', newValue)
                        "
                    />
                    <CustomInputSelect
                        :options="options.rec_boitier_real"
                        :label="labels.rec_boitier_real + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.rec_boitier_real"
                        @update:value="
                            (newValue) => {
                                saveData('rec_boitier_real', newValue);
                                if (newValue === 'CONFORME') {
                                    level = 0;
                                } else {
                                    level = 1;
                                }
                            }
                        "
                    />
                    <InputCamera
                        :images="images.environnement"
                        :label="labels.environnement"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('environnement', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.support"
                        :value="form.support"
                        :disabled="true"
                        :maxlength="255"
                    />
                    <CustomInput
                        :label="labels.id_parent"
                        :value="form.id_parent"
                        :disabled="true"
                        :maxlength="255"
                    />
                    <CustomInput
                        :label="labels.parent_etiquet"
                        :value="form.parent_etiquet"
                        :disabled="true"
                        :maxlength="255"
                    />
                    <CustomInput
                        :label="labels.parent_nature"
                        :value="form.parent_nature"
                        :disabled="true"
                        :maxlength="255"
                    />
                    <CustomInput
                        :label="labels.type_struc"
                        :value="form.type_struc"
                        :disabled="true"
                        :maxlength="255"
                    />
                    <CustomInput
                        :label="labels.proprietai"
                        :value="form.proprietai"
                        :disabled="true"
                        :maxlength="255"
                    />
                    <CustomInput
                        :label="labels.emprise"
                        :value="form.emprise"
                        :disabled="true"
                        :maxlength="255"
                        @update:value="
                            (newValue) => saveData('emprise', newValue)
                        "
                    />
                    <CustomInputSelect
                        v-show="level === 0"
                        :options="options.type_struc_pt"
                        :label="labels.type_struc_pt"
                        :value="form.type_struc_pt"
                        @update:value="
                            (newValue) => saveData('type_struc_pt', newValue)
                        "
                    />
                    <CustomInputSelect
                        v-show="level === 0"
                        :options="options.type_gest_pt"
                        :label="labels.type_gest_pt"
                        :value="form.type_gest_pt"
                        @update:value="
                            (newValue) => saveData('type_gest_pt', newValue)
                        "
                    />
                    <CustomInputMultiSelect
                        v-show="level === 0"
                        :options="options.tampon_cadre"
                        :label="labels.tampon_cadre"
                        :value="form.tampon_cadre"
                        :disabled-option-value="'CONFORME'"
                        @update:value="
                            (newValue) => saveData('tampon_cadre', newValue)
                        "
                    />
                    <div v-show="level === 0">
                        <InputCamera
                            :images="images.infrastructure"
                            :label="labels.infrastructure"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage('infrastructure', newValue)
                            "
                        />
                    </div>
                </el-col>
                <el-col
                    v-show="selectedTab === 'box'"
                    :span="24"
                >
                    <CustomInputMultiSelect
                        v-show="level === 0"
                        :options="options.cable_terrain"
                        :label="labels.cable_terrain"
                        :value="form.cable_terrain"
                        :disabled-option-value="'CONFORME'"
                        @update:value="
                            (newValue) => saveData('cable_terrain', newValue)
                        "
                    />
                    <div v-show="level === 0">
                        <InputCamera
                            :images="images.cb_love"
                            :label="labels.cb_love"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) => saveImage('cb_love', newValue)
                            "
                        />
                    </div>
                    <CustomInputMultiSelect
                        v-show="level === 0"
                        :options="options.fixation_boitier"
                        :label="labels.fixation_boitier"
                        :value="form.fixation_boitier"
                        :disabled-option-value="'CONFORME'"
                        @update:value="
                            (newValue) => saveData('fixation_boitier', newValue)
                        "
                    />
                    <div v-show="level === 0">
                        <InputCamera
                            :images="images.cb_fix"
                            :label="labels.cb_fix"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) => saveImage('cb_fix', newValue)
                            "
                        />
                    </div>
                    <CustomInput
                        :label="labels.amont"
                        :value="form.amont"
                        :disabled="true"
                        :maxlength="255"
                    />
                    <CustomInputMultiSelect
                        v-show="level === 0"
                        :options="options.etiquette_cable"
                        :label="labels.etiquette_cable"
                        :value="form.etiquette_cable"
                        :disabled-option-value="'CONFORME'"
                        @update:value="
                            (newValue) => saveData('etiquette_cable', newValue)
                        "
                    />
                    <div v-show="level === 0">
                        <InputCamera
                            :images="images.eti_cable"
                            :label="labels.eti_cable"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) => saveImage('eti_cable', newValue)
                            "
                        />
                    </div>
                    <CustomInputMultiSelect
                        v-show="level === 0"
                        :options="options.etiquette_boitier"
                        :label="labels.etiquette_boitier"
                        :value="form.etiquette_boitier"
                        :disabled-option-value="'CONFORME'"
                        @update:value="
                            (newValue) =>
                                saveData('etiquette_boitier', newValue)
                        "
                    />
                    <div v-show="level === 0">
                        <InputCamera
                            :images="images.eti_boite"
                            :label="labels.eti_boite"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) => saveImage('eti_boite', newValue)
                            "
                        />
                    </div>
                    <CustomInputMultiSelect
                        v-show="level === 0"
                        :options="options.etat_boitier"
                        :label="labels.etat_boitier"
                        :value="form.etat_boitier"
                        :disabled-option-value="'CONFORME'"
                        @update:value="
                            (newValue) => saveData('etat_boitier', newValue)
                        "
                    />
                    <div v-show="level === 0">
                        <InputCamera
                            :images="images.intb_ens"
                            :label="labels.intb_ens"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) => saveImage('intb_ens', newValue)
                            "
                        />
                    </div>
                    <CustomInputMultiSelect
                        v-show="level === 0"
                        :options="options.entree_cable"
                        :label="labels.entree_cable"
                        :value="form.entree_cable"
                        :disabled-option-value="'CONFORME'"
                        @update:value="
                            (newValue) => saveData('entree_cable', newValue)
                        "
                    />
                    <CustomInputMultiSelect
                        v-show="level === 0"
                        :options="options.arrimage_cable"
                        :label="labels.arrimage_cable"
                        :value="form.arrimage_cable"
                        :disabled-option-value="'CONFORME'"
                        @update:value="
                            (newValue) => saveData('arrimage_cable', newValue)
                        "
                    />
                    <div v-show="level === 0">
                        <InputCamera
                            :images="images.cb_entree"
                            :label="labels.cb_entree"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) => saveImage('cb_entree', newValue)
                            "
                        />
                    </div>
                </el-col>
                <el-col
                    v-show="selectedTab === 'optical'"
                    :span="24"
                >
                    <CustomInputMultiSelect
                        v-show="level === 0"
                        :options="options.tubes"
                        :label="labels.tubes"
                        :value="form.tubes"
                        :disabled-option-value="'CONFORME'"
                        @update:value="
                            (newValue) => saveData('tubes', newValue)
                        "
                    />
                    <div v-show="level === 0">
                        <InputCamera
                            :images="images.intb_tubes"
                            :label="labels.intb_tubes"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) => saveImage('intb_tubes', newValue)
                            "
                        />
                    </div>
                    <CustomInputMultiSelect
                        v-show="level === 0"
                        :options="options.cassette"
                        :label="labels.cassette"
                        :value="form.cassette"
                        :disabled-option-value="'CONFORME'"
                        @update:value="
                            (newValue) => saveData('cassette', newValue)
                        "
                    />
                    <div v-show="level === 0">
                        <InputCamera
                            :images="images.intb_k7"
                            :label="labels.intb_k7"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) => saveImage('intb_k7', newValue)
                            "
                        />
                    </div>
                    <CustomInputMultiSelect
                        v-show="level === 0"
                        :options="options.fibres"
                        :label="labels.fibres"
                        :value="form.fibres"
                        :disabled-option-value="'CONFORME'"
                        @update:value="
                            (newValue) => saveData('fibres', newValue)
                        "
                    />
                    <CustomInputMultiSelect
                        v-show="level === 0"
                        :options="options.plan_boite"
                        :label="labels.plan_boite + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.plan_boite"
                        :disabled-option-value="'CONFORME'"
                        @update:value="
                            (newValue) => saveData('plan_boite', newValue)
                        "
                    />
                    <CustomInputMultiSelect
                        v-show="level === 0"
                        :options="options.continuite_optique"
                        :label="labels.continuite_optique + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.continuite_optique"
                        :disabled-option-value="'CONFORME'"
                        @update:value="
                            (newValue) =>
                                saveData('continuite_optique', newValue)
                        "
                    />
                    <div v-show="level === 0">
                        <InputCamera
                            :images="images.intb_optique"
                            :label="labels.intb_optique"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage('intb_optique', newValue)
                            "
                        />
                    </div>
                    <CustomInputSelect
                        v-show="level === 0"
                        :options="options.criticite_remarque"
                        :label="labels.criticite_remarque"
                        :value="form.criticite_remarque"
                        :disabled-option-value="'CONFORME'"
                        @update:value="
                            (newValue) =>
                                saveData('criticite_remarque', newValue)
                        "
                    />
                    <CustomInputTextarea
                        v-show="level === 0"
                        :label="labels.remarque_supplementaire"
                        :value="form.remarque_supplementaire"
                        :maxlength="255"
                        @update:value="
                            (newValue) =>
                                saveData('remarque_supplementaire', newValue)
                        "
                    />
                    <div v-show="level === 0">
                        <InputCamera
                            :images="images.autres_remarques"
                            :label="labels.autres_remarques"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage('autres_remarques', newValue)
                            "
                        />
                    </div>
                    <CustomInputSelect
                        :options="options.statut"
                        :label="labels.statut + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.statut"
                        @update:value="
                            (newValue) => saveData('statut', newValue)
                        "
                    />
                </el-col>
            </el-row>
        </template>
    </FormWrapper>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import {
    labels,
    options,
} from '@connect-field/client/components/forms/Dotic/GraceTHD-v2/PTO.data';
import type { FormDataInterface } from '@connect-field/client/components/forms/form.types';
import FormTemplate from '@connect-field/client/components/forms/FormTemplate.vue';

interface DataInterface extends FormDataInterface {}

export default defineComponent({
    extends: FormTemplate,
    data(): DataInterface {
        return {
            changes: false,
            form: {},
            images: {
                autres_remarques: [],
                cb_entree: [],
                cb_fix: [],
                cb_love: [],
                environnement: [],
                eti_boite: [],
                eti_cable: [],
                infrastructure: [],
                intb_ens: [],
                intb_k7: [],
                intb_optique: [],
                intb_tubes: [],
            },
            labels,
            level: 0,
            loading: true,
            mandatoryFields: [
                'rec_boitier_real',
                'plan_boite',
                'continuite_optique',
                'statut',
            ],
            options,
            selectedTab: 'environment',
            valueMissing: false,
        };
    },
});
</script>

<style lang="scss">
.text-warning .custom_label_input {
    color: red;
}
</style>
