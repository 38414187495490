<template>
    <BaseDialog
        :show="showReturnAlert"
        :title="$t('projectDetail.backToProjects')"
        @close="cancelBackToProjects"
    >
        <p>{{ $t('projectDetail.backToProjectsQuestion') }}</p>
        <template #actions>
            <BaseDialogButton
                :label="$t('globals.cancel')"
                button-type="default"
                @click="cancelBackToProjects"
            />
            <BaseDialogButton
                :label="$t('projectDetail.backToProjectsAction')"
                button-type="danger"
                @click="backToProjects"
            />
        </template>
    </BaseDialog>

    <header v-if="currentRoute.name === 'project'">
        <h3 class="title">
            {{ projectTitle }}
        </h3>
        <button
            class="btn--back custom_link"
            @click="projectLink"
        >
            {{ $t('projectDetail.title') }}
        </button>
    </header>
    <main
        v-loading="loading"
        class="main"
        :element-loading-text="$t('projectDetail.backToProjectsAction')"
        element-loading-spinner="el-icon-loading"
        element-loading-background="transparent"
    >
        <RouterView
            v-if="!loading"
            v-slot="slotProps"
        >
            <Component
                :is="slotProps.Component"
                :key="slotProps.route"
            />
        </RouterView>
    </main>
</template>

<script lang="ts">
import type BaseLayer from 'ol/layer/Base';
import { defineComponent } from 'vue';
import type Map from 'ol/Map';
import { mapState } from 'pinia';

import * as ProjectApi from '@connect-field/client/services/api/project';
import {
    removeLayers,
    removePinOnMap,
    setPinOnMap,
    zoomOnBbox,
} from '@connect-field/client/services/map.service';
import BaseDialog from '@connect-field/client/components/ui/BaseDialog.vue';
import BaseDialogButton from '@connect-field/client/components/ui/BaseDialogButton.vue';
import { generateLayer } from '@connect-field/client/services/layers/onlineLayers.service';
import { isStringUniqueInObject } from '@connect-field/client/utilities/validators';
import OpacitySlider from '@connect-field/client/components/layer/OpacitySlider.vue';
import type { ProjectConfigurationLayer } from '@connect-field/client/sdk/generated';
import useBannerStore from '@connect-field/client/stores/banner';
import useCoordinateStore from '@connect-field/client/stores/coordinate';
import useLayersStore from '@connect-field/client/stores/layers';
import useMapStore from '@connect-field/client/stores/map';
import useMenuStore from '@connect-field/client/stores/menu';
import useNavigationStore from '@connect-field/client/stores/navigation';
import useProjectsStore from '@connect-field/client/stores/projects';

const projectCustomNameErrors = {
    alreadyUsed: 'Ce nom est déjà utilisé',
    emptyName: 'Veuillez saisir un nom',
};

interface DataInterface {
    controller?: AbortController;
    isProjectCustomNameValid: boolean;
    loading: boolean;
    nbOfLoadedLayers: 0;
    projectCustomName: string;
    projectCustomNameError: string;
    showReturnAlert: boolean;
    signal?: AbortSignal;
}

export default defineComponent({
    components: {
        BaseDialog,
        BaseDialogButton,
        OpacitySlider,
    },
    props: {
        projectId: {
            type: String,
            required: true,
        },
    },
    setup() {
        return {
            bannerStore: useBannerStore(),
            coordinateStore: useCoordinateStore(),
            layersStore: useLayersStore(),
            mapStore: useMapStore(),
            menuStore: useMenuStore(),
            navigationStore: useNavigationStore(),
            projectsStore: useProjectsStore(),
        };
    },
    data(): DataInterface {
        return {
            isProjectCustomNameValid: false,
            loading: false,
            nbOfLoadedLayers: 0,
            projectCustomName: '',
            projectCustomNameError: '',
            showReturnAlert: false,
        };
    },
    computed: {
        ...mapState(useProjectsStore, {
            offlineProjects: 'offlineProjects',
            selectedProject: 'selectedProject',
            selectedProjectId: 'selectedProjectId',
        }),
        coordinate() {
            return this.coordinateStore.coordinate;
        },
        currentRoute() {
            return this.navigationStore.currentRoute;
        },
        map() {
            if (!this.mapStore.map) {
                throw new Error('Map is undefined');
            }

            return this.mapStore.map;
        },
        projectTitle() {
            return this.projectsStore.selectedProject?.name;
        },
    },
    watch: {
        projectCustomName() {
            if (this.projectCustomName.length < 1) {
                this.projectCustomNameError = projectCustomNameErrors.emptyName;
                this.isProjectCustomNameValid = false;

                return;
            }

            const isCustomNameUnique = isStringUniqueInObject(
                this.projectCustomName,
                this.offlineProjects,
                'customName',
            );
            if (!isCustomNameUnique) {
                this.projectCustomNameError =
                    projectCustomNameErrors.alreadyUsed;
                this.isProjectCustomNameValid = false;

                return;
            }

            this.isProjectCustomNameValid = true;
            this.projectCustomNameError = '';
        },
    },
    async mounted() {
        this.loading = true;

        this.controller = new AbortController();
        this.signal = this.controller.signal;

        removePinOnMap();
        this.menuStore.displayMenu = true;

        // Here we need to reload the layers only if the page is refresh or if it's a new project loaded
        if (
            !this.selectedProjectId ||
            this.selectedProjectId.toString() !== this.projectId.toString()
        ) {
            this.projectsStore.isOffline = false;
            const configuration = await ProjectApi.getProjectConfiguration(
                parseInt(this.projectId, 10),
            );
            this.projectsStore.setProject(configuration, this.projectId, true);
            removeLayers();
            await this.applyLayersProject();
            if (this.selectedProject) {
                zoomOnBbox(this.selectedProject.bbox);
            }
        }

        if (this.coordinate) {
            setPinOnMap(this.coordinate);
        }
        this.loading = false;
    },
    methods: {
        async applyLayersProject(): Promise<void> {
            this.map.getLayers().forEach((layer: BaseLayer) => {
                if (
                    layer.getProperties().online &&
                    layer.getProperties().name !== 'background_IGN'
                ) {
                    layer.setVisible(true);
                }
            });

            if (!this.selectedProject || !this.selectedProject.layers) {
                console.error(
                    'SelectedProject is undefined',
                    this.selectedProject,
                );
                await this.bannerStore.showBannerAction({
                    message: 'Projet introuvable',
                    type: 'error',
                });
                this.backToProjects();

                return;
            }

            await Promise.all(
                this.selectedProject.layers.map(
                    (layer: ProjectConfigurationLayer) => {
                        const _layer = generateLayer(layer);

                        _layer.once('postrender', () => {
                            this.nbOfLoadedLayers++;
                        });
                        this.map?.addLayer(_layer);
                    },
                ),
            );

            const layersMap = this.getLayersMap(this.map as Map);
            this.map?.getView().changed();
            this.layersStore.$patch({ layers: layersMap });
        },
        backToProjects(): void {
            this.projectsStore.$patch({
                zoneSelection: false,
            });
            this.$router.push({ name: 'projects' });
        },
        cancelBackToProjects(): void {
            this.showReturnAlert = false;
        },
        getLayersMap(map: Map): Array<BaseLayer> {
            return map
                .getLayers()
                .getArray()
                .filter(
                    (layer: BaseLayer) =>
                        !layer.getProperties().global &&
                        layer.getProperties().name,
                );
        },
        projectLink(): void {
            this.showReturnAlert = true;
        },
    },
});
</script>

<style scoped lang="scss">
@import 'ProjectDetail.scss';
</style>
