export const labels = {
    amont: 'Amont',
    arrimage_cable: 'Arrimage de câble',
    autres_remarques: 'Autres',
    cable_sig: 'Câble SIG',
    cable_terrain: 'Câble terrain',
    capaamont: 'Capacité',
    cassette: 'Cassette',
    cb_entree: 'Entrée de cable',
    cb_fix: 'Fixation du boitier',
    cb_love: 'Cable Lovage',
    code: 'Code de la boîte',
    continuite_optique: 'Continuité optique',
    coord_gps: 'Coordonnée GPS',
    criticite_remarque: 'Criticité remarque',
    date_emission_rsv: 'Date émission',
    emprise: 'Emprise',
    entree_cable: 'Entrée de câble',
    environnement: 'Environnement',
    etat_boitier: 'Etat du boîtier',
    eti_boite: 'Etiquette de boite',
    eti_cable: 'Etiquette de cable',
    etiquette_boitier: 'Etiquette de boîtier',
    etiquette_cable: 'Etiquette de câble',
    fibres: 'Fibre',
    fixation_boitier: 'Fixation du boîtier',
    gestionnai: 'Gestionnaire',
    hauteur_boitier: 'Photo du poteau aérien',
    id_parent: 'Id Parent',
    info_boitier_sig: 'Information boîtier SIG',
    infrastructure: 'Infrastructure',
    intb_ens: 'Etat du boitier',
    intb_k7: 'Intérieur boitiers - Cassettes',
    intb_optique: 'Continuité optique',
    intb_tubes: 'Intérieur boitiers - Tubes',
    plan_boite: 'Plan de boite',
    proprietai: 'Propriétaire',
    rec_boitier_real: 'Recette du boîtier réalisable',
    remarque_supplementaire: 'Remarque supplémentaire',
    sid: 'Identifiant unique',
    statut_audit: 'Statut audit',
    support: 'Support',
    tampon_cadre: 'Infra Ptech',
    tubes: 'Tubes',
    type_gest_pt: 'Type gestionnaire du Ptech',
    type_struc: 'Type structure',
    type_struc_pt: 'Type Structure Ptech',
    urlexcel: 'URL Excel',
};
export const options = {
    arrimage_cable: [
        { label: 'CONFORME', value: 'CONFORME' },
        { label: 'Câble mal/non arrimé', value: 'Câble mal/non arrimé' },
        { label: 'Porteurs/kevlar HS', value: 'Porteurs/kevlar HS' },
    ],
    cable_sig: [
        { label: 'CONFORME', value: 'CONFORME' },
        {
            label: 'Capacité de câble incohérente entre terrain/SIG',
            value: 'Capacité de câble incohérente entre terrain/SIG',
        },
        {
            label: 'Nom et/ou code du câble erroné(s) sur SIG',
            value: 'Nom et/ou code du câble erroné(s) sur SIG',
        },
        {
            label: 'Type fonctionnel du câble erroné sur SIG',
            value: 'Type fonctionnel du câble erroné sur SIG',
        },
        {
            label: 'Propriétaire et/ou gestionnaire du câble erroné(s) sur SIG',
            value: 'Propriétaire et/ou gestionnaire du câble erroné(s) sur SIG',
        },
        {
            label: 'Câble pas en "MAINTIEN EN CONDITIONS OPÉRATIONNELLES" sur SIG',
            value: 'Câble pas en "MAINTIEN EN CONDITIONS OPÉRATIONNELLES" sur SIG',
        },
    ],
    cable_terrain: [
        { label: 'CONFORME', value: 'CONFORME' },
        { label: 'Câble non fixé', value: 'Câble non fixé' },
        {
            label: 'Lovage de câble non conforme',
            value: 'Lovage de câble non conforme',
        },
        {
            label: 'Lovage de câble trop court (impossible de sortir le boîtier)',
            value: 'Lovage de câble trop court (impossible de sortir le boîtier)',
        },
        {
            label: 'Cable détérioré / mauvais rayon de courbure (si contrainte réflecto)',
            value: 'Cable détérioré / mauvais rayon de courbure (si contrainte réflecto)',
        },
        {
            label: 'Câble croisé avec un autre',
            value: 'Câble croisé avec un autre',
        },
        {
            label: 'Goulotte demi-lune absente/détériorée',
            value: 'Goulotte demi-lune absente/détériorée',
        },
    ],
    cassette: [
        { label: 'CONFORME', value: 'CONFORME' },
        {
            label: 'Cassette(s) détériorée(s)',
            value: 'Cassette(s) détériorée(s)',
        },
        {
            label: 'Fibres de raccordement hors cassette de raccordement',
            value: 'Fibres de raccordement hors cassette de raccordement',
        },
        {
            label: `Cassette(s) installée(s) à l'envers`,
            value: `Cassette(s) installée(s) à l'envers`,
        },
        {
            label: 'Pas de repérage cassette',
            value: 'Pas de repérage cassette',
        },
        {
            label: 'Cassette(s) manquante(s)',
            value: 'Cassette(s) manquante(s)',
        },
        {
            label: 'Visière(s) de protection de cassette manquante(s)',
            value: 'Visière(s) de protection de cassette manquante(s)',
        },
    ],
    continuite_optique: [
        { label: 'CONFORME', value: 'CONFORME' },
        {
            label: 'Pas de Continuité Optique',
            value: 'Pas de Continuité Optique',
        },
        { label: 'Route Optique erronée', value: 'Route Optique erronée' },
    ],
    coord_gps: [
        { label: 'CONFORME', value: 'CONFORME' },
        { label: 'Mauvaise position GPS', value: 'Mauvaise position GPS' },
    ],
    criticite_remarque: [
        { label: 'Critique', value: 'Critique' },
        { label: 'Majeur', value: 'Majeur' },
        { label: 'Mineur', value: 'Mineur' },
        { label: 'Remarque', value: 'Remarque' },
    ],
    entree_cable: [
        { label: 'CONFORME', value: 'CONFORME' },
        { label: 'ECAM mal/non serrés', value: 'ECAM mal/non serrés' },
        {
            label: 'Entrées de câble mal/non réalisées',
            value: 'Entrées de câble mal/non réalisées',
        },
        {
            label: "Scotchs d'identification de câble absents",
            value: "Scotchs d'identification de câble absents",
        },
        {
            label: 'Entrées de câble boitier aérien mal/non réalisées',
            value: 'Entrées de câble boitier aérien mal/non réalisées',
        },
        {
            label: 'Câble entrant mal positionné',
            value: 'Câble entrant mal positionné',
        },
    ],
    etat_boitier: [
        { label: 'CONFORME', value: 'CONFORME' },
        { label: 'Boîtier détérioré', value: 'Boîtier détérioré' },
        {
            label: 'Système de fermeture du boîtier HS',
            value: 'Système de fermeture du boîtier HS',
        },
        {
            label: `Boitier non adapté à l'infra`,
            value: `Boitier non adapté à l'infra`,
        },
        {
            label: `Boitier non adapté au raccordement`,
            value: `Boitier non adapté au raccordement`,
        },
        { label: 'Boîtier non étanche', value: 'Boîtier non étanche' },
    ],
    etiquette_boitier: [
        { label: 'CONFORME', value: 'CONFORME' },
        {
            label: 'Étiquette boîtier absente/détériorée',
            value: 'Étiquette boîtier absente/détériorée',
        },
        {
            label: 'Étiquette boîtier incohérente entre terrain/PdB',
            value: 'Étiquette boîtier incohérente entre terrain/PdB',
        },
        {
            label: 'Étiquetage du boîtier incomplet (4 derniers digits CONFORME)',
            value: 'Étiquetage du boîtier incomplet (4 derniers digits CONFORME)',
        },
        {
            label: `Type d'étiquette du boîtier non conforme`,
            value: `Type d'étiquette du boîtier non conforme`,
        },
        {
            label: `Etiquette boîtier temporaire posée (pose QR)`,
            value: `Etiquette boîtier temporaire posée (pose QR)`,
        },
    ],
    etiquette_cable: [
        { label: 'CONFORME', value: 'CONFORME' },
        {
            label: 'Une étiquette de câble absente/détériorée',
            value: 'Une étiquette de câble absente/détériorée',
        },
        {
            label: 'Plusieurs étiquettes de câble absentes/détériorées',
            value: 'Plusieurs étiquettes de câble absentes/détériorées',
        },
        {
            label: 'Étiquette(s) de câble incohérente(s) entre terrain/PdB',
            value: 'Étiquette(s) de câble incohérente(s) entre terrain/PdB',
        },
        {
            label: 'Étiquette(s) de câble incomplète(s) (4 derniers digits CONFORME)',
            value: 'Étiquette(s) de câble incomplète(s) (4 derniers digits CONFORME)',
        },
        {
            label: `Type d'étiquette de câble non conforme`,
            value: `Type d'étiquette de câble non conforme`,
        },
        {
            label: 'Étiquette(s) câble(s) temporaire(s) posée(s) (pose QR)',
            value: 'Étiquette(s) câble(s) temporaire(s) posée(s) (pose QR)',
        },
        {
            label: 'Étiquettes de câble inversées (correction QR)',
            value: 'Étiquettes de câble inversées (correction QR)',
        },
        {
            label: 'Capa de câble incohérente entre terrain/PdB',
            value: 'Capa de câble incohérente entre terrain/PdB',
        },
    ],
    fibres: [
        { label: 'CONFORME', value: 'CONFORME' },
        {
            label: 'Fibre(s) cassée(s)/trop courte(s)/inexploitable(s)',
            value: 'Fibre(s) cassée(s)/trop courte(s)/inexploitable(s)',
        },
        {
            label: 'Épissure(s) non conforme(s)',
            value: 'Épissure(s) non conforme(s)',
        },
        {
            label: 'Fibre(s) nue(s) en zone de lovage boite',
            value: 'Fibre(s) nue(s) en zone de lovage boite',
        },
        {
            label: 'Protection de soudure non conforme',
            value: 'Protection de soudure non conforme',
        },
        {
            label: `Lovage de fibre ne respecte pas les règles de l'art`,
            value: `Lovage de fibre ne respecte pas les règles de l'art`,
        },
        { label: 'Fibres non dégraissées', value: 'Fibres non dégraissées' },
    ],
    fixation_boitier: [
        { label: 'CONFORME', value: 'CONFORME' },
        {
            label: 'Boîtier mal/non fixé en souterrain',
            value: 'Boîtier mal/non fixé en souterrain',
        },
        {
            label: 'Boîtier mal/non fixé en aérien',
            value: 'Boîtier mal/non fixé en aérien',
        },
        {
            label: `Boîtier aérien n'est pas entre 2m et 2m50`,
            value: `Boîtier aérien n'est pas entre 2m et 2m50`,
        },
        {
            label: `Boîtier aérien n'est pas entre 2m et 4m`,
            value: `Boîtier aérien n'est pas entre 2m et 4m`,
        },
    ],
    info_boitier_sig: [
        { label: 'CONFORME', value: 'CONFORME' },
        {
            label: 'Type fonctionnel (PBO, BET, etc…) du boîtier erroné sur SIG',
            value: 'Type fonctionnel (PBO, BET, etc…) du boîtier erroné sur SIG',
        },
        {
            label: 'Référence (OFMC, 3M T0, etc…) du boîtier erronée sur SIG',
            value: 'Référence (OFMC, 3M T0, etc…) du boîtier erronée sur SIG',
        },
    ],
    plan_boite: [
        { label: 'CONFORME', value: 'CONFORME' },
        {
            label: 'Plan de boite incohérent terrain / PdB (stockée/passage/épisurée)',
            value: 'Plan de boite incohérent terrain / PdB (stockée/passage/épisurée)',
        },
        {
            label: 'Incohérence SOUDURE terrain / PdB',
            value: 'Incohérence SOUDURE terrain / PdB',
        },
        {
            label: 'Fibres mortes non modélisées',
            value: 'Fibres mortes non modélisées',
        },
        {
            label: 'Câble supplémentaire dans la boite',
            value: 'Câble supplémentaire dans la boite',
        },
        {
            label: 'Incohérence POSITION FIBRE en cassette terrain / PdB',
            value: 'Incohérence POSITION FIBRE en cassette terrain / PdB',
        },
        { label: 'Incohérence DTI/Terrain', value: 'Incohérence DTI/Terrain' },
    ],
    rec_boitier_real: [
        { label: 'CONFORME', value: 'CONFORME' },
        {
            label: `Demande d'accès nécessaire`,
            value: `Demande d'accès nécessaire`,
        },
        {
            label: 'Arrêté de circulation nécessaire',
            value: 'Arrêté de circulation nécessaire',
        },
        {
            label: `Nécessité d'être deux/nacelle`,
            value: `Nécessité d'être deux/nacelle`,
        },
        {
            label: `Nécessité d'être deux pour ouvrir la chambre`,
            value: `Nécessité d'être deux pour ouvrir la chambre`,
        },
        {
            label: 'Véhicule stationné sur la chambre',
            value: 'Véhicule stationné sur la chambre',
        },
        {
            label: 'Point technique non trouvé',
            value: 'Point technique non trouvé',
        },
        {
            label: 'Chambre sous enrobée sur chaussée (chambre Tierce)',
            value: 'Chambre sous enrobée sur chaussée (chambre Tierce)',
        },
        {
            label: 'Chambre sous enrobée sur trottoir (chambre Tierce)',
            value: 'Chambre sous enrobée sur trottoir (chambre Tierce)',
        },
        {
            label: 'Chambre Collectivité non exploitable (sous enrobée/ fourreaux écrasées...)',
            value: 'Chambre Collectivité non exploitable (sous enrobée/ fourreaux écrasées...)',
        },
        {
            label: 'Audit non réalisable - validé par photo sst',
            value: 'Audit non réalisable - validé par photo sst',
        },
        {
            label: 'Audit non réalisable - validé sans contrôle',
            value: 'Audit non réalisable - validé sans contrôle',
        },
        { label: 'Boîtier non posé', value: 'Boîtier non posé' },
    ],
    statut_audit: [
        { label: 'NON REALISE', value: 'NON REALISE' },
        { label: 'PARTIELLEMENT', value: 'PARTIELLEMENT' },
        { label: 'TERMINE', value: 'TERMINE' },
    ],
    tampon_cadre: [
        { label: 'CONFORME', value: 'CONFORME' },
        {
            label: 'Tampon scellé/impossible à ouvrir (chambre Collectivité)',
            value: 'Tampon scellé/impossible à ouvrir (chambre Collectivité)',
        },
        {
            label: 'Scellement du cadre non conforme (chambre Collectivité)',
            value: 'Scellement du cadre non conforme (chambre Collectivité)',
        },
        {
            label: 'Tampon absent/détérioré (chambre Collectivité)',
            value: 'Tampon absent/détérioré (chambre Collectivité)',
        },
        {
            label: 'Tampon absent/détérioré/scellé (chambre Tierce)',
            value: 'Tampon absent/détérioré/scellé (chambre Tierce)',
        },
        {
            label: 'Tampon non conforme (chambre Collectivité)',
            value: 'Tampon non conforme (chambre Collectivité)',
        },
        {
            label: `Manque Bandeau Vert - Etude + pose à réaliser sur chemin d'adduction`,
            value: `Manque Bandeau Vert - Etude + pose à réaliser sur chemin d'adduction`,
        },
        {
            label: `Bandeau Vert posé par la QR - Etude + pose à réaliser sur chemin d'adduction`,
            value: `Bandeau Vert posé par la QR - Etude + pose à réaliser sur chemin d'adduction`,
        },
        {
            label: `Appui non conforme (infra Collectivité)`,
            value: `Appui non conforme (infra Collectivité)`,
        },
    ],
    tubes: [
        { label: 'CONFORME', value: 'CONFORME' },
        {
            label: 'Tube(s) détérioré(s)/pincé(s)/inexploitable(s)',
            value: 'Tube(s) détérioré(s)/pincé(s)/inexploitable(s)',
        },
        {
            label: `Lovage de tube ne respecte pas les règles de l'art`,
            value: `Lovage de tube ne respecte pas les règles de l'art`,
        },
        { label: 'Détubage hors cassette', value: 'Détubage hors cassette' },
        {
            label: 'PEC : Identification des tubes en cassette absente',
            value: 'PEC : Identification des tubes en cassette absente',
        },
    ],
    type_gest_pt: [
        { label: 'CONFORME', value: 'CONFORME' },
        {
            label: 'Propriétaire et/ou gestionnaire du point technique erroné(s)',
            value: 'Propriétaire et/ou gestionnaire du point technique erroné(s)',
        },
    ],
    type_struc_pt: [
        { label: 'CONFORME', value: 'CONFORME' },
        { label: 'CHAMBRE sur le terrain', value: 'CHAMBRE sur le terrain' },
        {
            label: 'APPUI AERIEN sur le terrain',
            value: 'APPUI AERIEN sur le terrain',
        },
        {
            label: 'ANCRAGE FACADE sur le terrain',
            value: 'ANCRAGE FACADE sur le terrain',
        },
        { label: 'IMMEUBLE sur le terrain', value: 'IMMEUBLE sur le terrain' },
    ],
};
