export const searchParameters = {
    maxNumberOfResults: 50,
    minLengthOfSearchInput: 3,
    searchDelay: 500, //Delay time in millisecond before search
    searchPropertyKey: 'code', //Name of the feature property used by the search
};

export const searchErrors = {
    noEnoughCharactersInSearchInput: `Veuillez entrer ${searchParameters.minLengthOfSearchInput} caractères minimum pour lancer la recherche`,
    noProjectSelected: 'Veuillez choisir un projet',
    noResults: 'Aucun résultat',
    tooMuchResults: `Veuillez préciser votre recherche. La recherche a été limitée à ${searchParameters.maxNumberOfResults} résultats`,
};
