/* tslint:disable */
/* eslint-disable */
/**
 * Connect Field
 * Connect Field API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface AllHealthResponse
 */
export interface AllHealthResponse {
    /**
     *
     * @type {HealthResponse}
     * @memberof AllHealthResponse
     */
    api: HealthResponse;
    /**
     *
     * @type {HealthResponse}
     * @memberof AllHealthResponse
     */
    db: HealthResponse;
    /**
     *
     * @type {HealthResponse}
     * @memberof AllHealthResponse
     */
    martin: HealthResponse;
    /**
     *
     * @type {HealthResponse}
     * @memberof AllHealthResponse
     */
    metier: HealthResponse;
    /**
     *
     * @type {HealthResponse}
     * @memberof AllHealthResponse
     */
    tippeCanoe: HealthResponse;
}
/**
 *
 * @export
 * @interface CountObjectZoneProperties
 */
export interface CountObjectZoneProperties {
    /**
     *
     * @type {string}
     * @memberof CountObjectZoneProperties
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof CountObjectZoneProperties
     */
    total: number;
}
/**
 *
 * @export
 * @interface CreateDomainDto
 */
export interface CreateDomainDto {
    /**
     *
     * @type {string}
     * @memberof CreateDomainDto
     */
    code: string;
    /**
     *
     * @type {string}
     * @memberof CreateDomainDto
     */
    name: string;
}
/**
 *
 * @export
 * @interface CreateGroupDto
 */
export interface CreateGroupDto {
    /**
     *
     * @type {number}
     * @memberof CreateGroupDto
     */
    domain: number;
    /**
     *
     * @type {string}
     * @memberof CreateGroupDto
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof CreateGroupDto
     */
    role: number;
}
/**
 *
 * @export
 * @interface CreateLayerDto
 */
export interface CreateLayerDto {
    /**
     *
     * @type {string}
     * @memberof CreateLayerDto
     */
    aggregateField?: string;
    /**
     *
     * @type {boolean}
     * @memberof CreateLayerDto
     */
    ai?: boolean;
    /**
     *
     * @type {string}
     * @memberof CreateLayerDto
     */
    alias?: string;
    /**
     *
     * @type {string}
     * @memberof CreateLayerDto
     */
    category?: string;
    /**
     *
     * @type {string}
     * @memberof CreateLayerDto
     */
    customAggregateOptions?: string;
    /**
     *
     * @type {string}
     * @memberof CreateLayerDto
     */
    customSid?: string;
    /**
     *
     * @type {string}
     * @memberof CreateLayerDto
     */
    form?: string;
    /**
     *
     * @type {boolean}
     * @memberof CreateLayerDto
     */
    hiddenByDefault?: boolean;
    /**
     *
     * @type {string}
     * @memberof CreateLayerDto
     */
    imgTable?: string;
    /**
     *
     * @type {number}
     * @memberof CreateLayerDto
     */
    maxZoom?: number;
    /**
     *
     * @type {number}
     * @memberof CreateLayerDto
     */
    minZoom?: number;
    /**
     *
     * @type {string}
     * @memberof CreateLayerDto
     */
    schema?: string;
    /**
     *
     * @type {string}
     * @memberof CreateLayerDto
     */
    table?: string;
    /**
     *
     * @type {boolean}
     * @memberof CreateLayerDto
     */
    enableCreation: boolean;
    /**
     *
     * @type {boolean}
     * @memberof CreateLayerDto
     */
    enableEdition: boolean;
    /**
     *
     * @type {string}
     * @memberof CreateLayerDto
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof CreateLayerDto
     */
    renderFormat: CreateLayerDtoRenderFormatEnum;
    /**
     *
     * @type {string}
     * @memberof CreateLayerDto
     */
    type: CreateLayerDtoTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum CreateLayerDtoRenderFormatEnum {
    GEOJSON = 'geojson',
    PBF = 'pbf',
}
/**
 * @export
 * @enum {string}
 */
export enum CreateLayerDtoTypeEnum {
    AGGREGATE = 'AGGREGATE',
    BOX_PLAN_V2 = 'BOX_PLAN_V2',
    BOX_PLAN_V3 = 'BOX_PLAN_V3',
    DEFAULT = 'DEFAULT',
    INVISIBLE = 'INVISIBLE',
    MATERIALIZED_BOX_PLAN_V2 = 'MATERIALIZED_BOX_PLAN_V2',
    MATERIALIZED_BOX_PLAN_V3 = 'MATERIALIZED_BOX_PLAN_V3',
    VIRTUAL = 'VIRTUAL',
}

/**
 *
 * @export
 * @interface CreateRoleDto
 */
export interface CreateRoleDto {
    /**
     *
     * @type {string}
     * @memberof CreateRoleDto
     */
    name: string;
}
/**
 *
 * @export
 * @interface CreateStyleDto
 */
export interface CreateStyleDto {
    /**
     *
     * @type {Array<LayerStyleSpecification>}
     * @memberof CreateStyleDto
     */
    definition: Array<LayerStyleSpecification>;
    /**
     *
     * @type {string}
     * @memberof CreateStyleDto
     */
    name: string;
}
/**
 *
 * @export
 * @interface CreateZoneDto
 */
export interface CreateZoneDto {
    /**
     *
     * @type {string}
     * @memberof CreateZoneDto
     */
    geomColumn: string;
    /**
     *
     * @type {string}
     * @memberof CreateZoneDto
     */
    indexColumn: string;
    /**
     *
     * @type {string}
     * @memberof CreateZoneDto
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof CreateZoneDto
     */
    nameColumn: string;
    /**
     *
     * @type {number}
     * @memberof CreateZoneDto
     */
    restrictedLayer: number;
    /**
     *
     * @type {Array<number>}
     * @memberof CreateZoneDto
     */
    users?: Array<number>;
    /**
     *
     * @type {number}
     * @memberof CreateZoneDto
     */
    zoneLayer: number;
}
/**
 *
 * @export
 * @interface Domain
 */
export interface Domain {
    /**
     *
     * @type {number}
     * @memberof Domain
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof Domain
     */
    code: string;
    /**
     *
     * @type {Date}
     * @memberof Domain
     */
    createdAt: Date;
    /**
     *
     * @type {Array<Project>}
     * @memberof Domain
     */
    domainsProjects: Array<Project>;
    /**
     *
     * @type {Array<User>}
     * @memberof Domain
     */
    domainsUsers: Array<User>;
    /**
     *
     * @type {Array<Group>}
     * @memberof Domain
     */
    groups: Array<Group>;
    /**
     *
     * @type {string}
     * @memberof Domain
     */
    name: string;
    /**
     *
     * @type {Array<Project>}
     * @memberof Domain
     */
    projects: Array<Project>;
    /**
     *
     * @type {Date}
     * @memberof Domain
     */
    updatedAt: Date;
}
/**
 *
 * @export
 * @interface DowntimeResponseDto
 */
export interface DowntimeResponseDto {
    /**
     *
     * @type {string}
     * @memberof DowntimeResponseDto
     */
    message: string;
    /**
     *
     * @type {Date}
     * @memberof DowntimeResponseDto
     */
    time: Date;
}
/**
 *
 * @export
 * @interface EtiquettePostProcess
 */
export interface EtiquettePostProcess {
    /**
     *
     * @type {string}
     * @memberof EtiquettePostProcess
     */
    id_etiquette: string;
    /**
     *
     * @type {Array<string>}
     * @memberof EtiquettePostProcess
     */
    text: Array<string>;
}
/**
 *
 * @export
 * @interface ExtractGeoJSONBodyDto
 */
export interface ExtractGeoJSONBodyDto {
    /**
     *
     * @type {Array<LayerStyleSpecificationFilterInner>}
     * @memberof ExtractGeoJSONBodyDto
     */
    zoneIds?: Array<LayerStyleSpecificationFilterInner>;
    /**
     *
     * @type {Array<number>}
     * @memberof ExtractGeoJSONBodyDto
     */
    bbox: Array<number>;
    /**
     *
     * @type {number}
     * @memberof ExtractGeoJSONBodyDto
     */
    projectId: number;
}
/**
 *
 * @export
 * @interface GeoJSON
 */
export interface GeoJSON {
    /**
     *
     * @type {Array<string>}
     * @memberof GeoJSON
     */
    features: Array<string>;
    /**
     *
     * @type {string}
     * @memberof GeoJSON
     */
    type: GeoJSONTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum GeoJSONTypeEnum {
    FEATURE_COLLECTION = 'FeatureCollection',
}

/**
 *
 * @export
 * @interface GeoJSONCountObjectZoneProperties
 */
export interface GeoJSONCountObjectZoneProperties {
    /**
     *
     * @type {Array<GeoJSONCountObjectZonePropertiesAllOfFeaturesInner>}
     * @memberof GeoJSONCountObjectZoneProperties
     */
    features: Array<GeoJSONCountObjectZonePropertiesAllOfFeaturesInner>;
    /**
     *
     * @type {string}
     * @memberof GeoJSONCountObjectZoneProperties
     */
    type: GeoJSONCountObjectZonePropertiesTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum GeoJSONCountObjectZonePropertiesTypeEnum {
    FEATURE_COLLECTION = 'FeatureCollection',
}

/**
 *
 * @export
 * @interface GeoJSONCountObjectZonePropertiesAllOf
 */
export interface GeoJSONCountObjectZonePropertiesAllOf {
    /**
     *
     * @type {Array<GeoJSONCountObjectZonePropertiesAllOfFeaturesInner>}
     * @memberof GeoJSONCountObjectZonePropertiesAllOf
     */
    features?: Array<GeoJSONCountObjectZonePropertiesAllOfFeaturesInner>;
}
/**
 *
 * @export
 * @interface GeoJSONCountObjectZonePropertiesAllOfFeaturesInner
 */
export interface GeoJSONCountObjectZonePropertiesAllOfFeaturesInner {
    /**
     *
     * @type {CountObjectZoneProperties}
     * @memberof GeoJSONCountObjectZonePropertiesAllOfFeaturesInner
     */
    properties?: CountObjectZoneProperties;
    /**
     *
     * @type {Array<number>}
     * @memberof GeoJSONCountObjectZonePropertiesAllOfFeaturesInner
     */
    bbox: Array<number>;
    /**
     *
     * @type {GeoJSONGeometry}
     * @memberof GeoJSONCountObjectZonePropertiesAllOfFeaturesInner
     */
    geometry: GeoJSONGeometry;
    /**
     *
     * @type {string}
     * @memberof GeoJSONCountObjectZonePropertiesAllOfFeaturesInner
     */
    type: GeoJSONCountObjectZonePropertiesAllOfFeaturesInnerTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum GeoJSONCountObjectZonePropertiesAllOfFeaturesInnerTypeEnum {
    FEATURE = 'Feature',
    FEATURE_COLLECTION = 'FeatureCollection',
    GEOMETRY_COLLECTION = 'GeometryCollection',
    LINE_STRING = 'LineString',
    MULTI_LINE_STRING = 'MultiLineString',
    MULTI_POINT = 'MultiPoint',
    MULTI_POLYGON = 'MultiPolygon',
    POINT = 'Point',
    POLYGON = 'Polygon',
}

/**
 *
 * @export
 * @interface GeoJSONCountObjectZonePropertiesAllOfFeaturesInnerAllOf
 */
export interface GeoJSONCountObjectZonePropertiesAllOfFeaturesInnerAllOf {
    /**
     *
     * @type {CountObjectZoneProperties}
     * @memberof GeoJSONCountObjectZonePropertiesAllOfFeaturesInnerAllOf
     */
    properties?: CountObjectZoneProperties;
}
/**
 *
 * @export
 * @interface GeoJSONFeature
 */
export interface GeoJSONFeature {
    /**
     *
     * @type {object}
     * @memberof GeoJSONFeature
     */
    properties?: object;
    /**
     *
     * @type {Array<number>}
     * @memberof GeoJSONFeature
     */
    bbox: Array<number>;
    /**
     *
     * @type {GeoJSONGeometry}
     * @memberof GeoJSONFeature
     */
    geometry: GeoJSONGeometry;
    /**
     *
     * @type {string}
     * @memberof GeoJSONFeature
     */
    type: GeoJSONFeatureTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum GeoJSONFeatureTypeEnum {
    FEATURE = 'Feature',
    FEATURE_COLLECTION = 'FeatureCollection',
    GEOMETRY_COLLECTION = 'GeometryCollection',
    LINE_STRING = 'LineString',
    MULTI_LINE_STRING = 'MultiLineString',
    MULTI_POINT = 'MultiPoint',
    MULTI_POLYGON = 'MultiPolygon',
    POINT = 'Point',
    POLYGON = 'Polygon',
}

/**
 *
 * @export
 * @interface GeoJSONGeoJSONPropertiesDto
 */
export interface GeoJSONGeoJSONPropertiesDto {
    /**
     *
     * @type {Array<GeoJSONGeoJSONPropertiesDtoAllOfFeaturesInner>}
     * @memberof GeoJSONGeoJSONPropertiesDto
     */
    features: Array<GeoJSONGeoJSONPropertiesDtoAllOfFeaturesInner>;
    /**
     *
     * @type {string}
     * @memberof GeoJSONGeoJSONPropertiesDto
     */
    type: GeoJSONGeoJSONPropertiesDtoTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum GeoJSONGeoJSONPropertiesDtoTypeEnum {
    FEATURE_COLLECTION = 'FeatureCollection',
}

/**
 *
 * @export
 * @interface GeoJSONGeoJSONPropertiesDtoAllOf
 */
export interface GeoJSONGeoJSONPropertiesDtoAllOf {
    /**
     *
     * @type {Array<GeoJSONGeoJSONPropertiesDtoAllOfFeaturesInner>}
     * @memberof GeoJSONGeoJSONPropertiesDtoAllOf
     */
    features?: Array<GeoJSONGeoJSONPropertiesDtoAllOfFeaturesInner>;
}
/**
 *
 * @export
 * @interface GeoJSONGeoJSONPropertiesDtoAllOfFeaturesInner
 */
export interface GeoJSONGeoJSONPropertiesDtoAllOfFeaturesInner {
    /**
     *
     * @type {object}
     * @memberof GeoJSONGeoJSONPropertiesDtoAllOfFeaturesInner
     */
    properties?: object;
    /**
     *
     * @type {Array<number>}
     * @memberof GeoJSONGeoJSONPropertiesDtoAllOfFeaturesInner
     */
    bbox: Array<number>;
    /**
     *
     * @type {GeoJSONGeometry}
     * @memberof GeoJSONGeoJSONPropertiesDtoAllOfFeaturesInner
     */
    geometry: GeoJSONGeometry;
    /**
     *
     * @type {string}
     * @memberof GeoJSONGeoJSONPropertiesDtoAllOfFeaturesInner
     */
    type: GeoJSONGeoJSONPropertiesDtoAllOfFeaturesInnerTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum GeoJSONGeoJSONPropertiesDtoAllOfFeaturesInnerTypeEnum {
    FEATURE = 'Feature',
    FEATURE_COLLECTION = 'FeatureCollection',
    GEOMETRY_COLLECTION = 'GeometryCollection',
    LINE_STRING = 'LineString',
    MULTI_LINE_STRING = 'MultiLineString',
    MULTI_POINT = 'MultiPoint',
    MULTI_POLYGON = 'MultiPolygon',
    POINT = 'Point',
    POLYGON = 'Polygon',
}

/**
 *
 * @export
 * @interface GeoJSONGeoJSONPropertiesDtoAllOfFeaturesInnerAllOf
 */
export interface GeoJSONGeoJSONPropertiesDtoAllOfFeaturesInnerAllOf {
    /**
     *
     * @type {object}
     * @memberof GeoJSONGeoJSONPropertiesDtoAllOfFeaturesInnerAllOf
     */
    properties?: object;
}
/**
 *
 * @export
 * @interface GeoJSONGeometry
 */
export interface GeoJSONGeometry {
    /**
     *
     * @type {Array<number>}
     * @memberof GeoJSONGeometry
     */
    coordinates: Array<number>;
    /**
     *
     * @type {string}
     * @memberof GeoJSONGeometry
     */
    type: GeoJSONGeometryTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum GeoJSONGeometryTypeEnum {
    GEOMETRY_COLLECTION = 'GeometryCollection',
    LINE_STRING = 'LineString',
    MULTI_LINE_STRING = 'MultiLineString',
    MULTI_POINT = 'MultiPoint',
    MULTI_POLYGON = 'MultiPolygon',
    POINT = 'Point',
    POLYGON = 'Polygon',
}

/**
 *
 * @export
 * @interface GeoJSONReverseBANDto
 */
export interface GeoJSONReverseBANDto {
    /**
     *
     * @type {Array<GeoJSONReverseBANDtoAllOfFeaturesInner>}
     * @memberof GeoJSONReverseBANDto
     */
    features: Array<GeoJSONReverseBANDtoAllOfFeaturesInner>;
    /**
     *
     * @type {string}
     * @memberof GeoJSONReverseBANDto
     */
    type: GeoJSONReverseBANDtoTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum GeoJSONReverseBANDtoTypeEnum {
    FEATURE_COLLECTION = 'FeatureCollection',
}

/**
 *
 * @export
 * @interface GeoJSONReverseBANDtoAllOf
 */
export interface GeoJSONReverseBANDtoAllOf {
    /**
     *
     * @type {Array<GeoJSONReverseBANDtoAllOfFeaturesInner>}
     * @memberof GeoJSONReverseBANDtoAllOf
     */
    features?: Array<GeoJSONReverseBANDtoAllOfFeaturesInner>;
}
/**
 *
 * @export
 * @interface GeoJSONReverseBANDtoAllOfFeaturesInner
 */
export interface GeoJSONReverseBANDtoAllOfFeaturesInner {
    /**
     *
     * @type {ReverseBANDto}
     * @memberof GeoJSONReverseBANDtoAllOfFeaturesInner
     */
    properties?: ReverseBANDto;
    /**
     *
     * @type {Array<number>}
     * @memberof GeoJSONReverseBANDtoAllOfFeaturesInner
     */
    bbox: Array<number>;
    /**
     *
     * @type {GeoJSONGeometry}
     * @memberof GeoJSONReverseBANDtoAllOfFeaturesInner
     */
    geometry: GeoJSONGeometry;
    /**
     *
     * @type {string}
     * @memberof GeoJSONReverseBANDtoAllOfFeaturesInner
     */
    type: GeoJSONReverseBANDtoAllOfFeaturesInnerTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum GeoJSONReverseBANDtoAllOfFeaturesInnerTypeEnum {
    FEATURE = 'Feature',
    FEATURE_COLLECTION = 'FeatureCollection',
    GEOMETRY_COLLECTION = 'GeometryCollection',
    LINE_STRING = 'LineString',
    MULTI_LINE_STRING = 'MultiLineString',
    MULTI_POINT = 'MultiPoint',
    MULTI_POLYGON = 'MultiPolygon',
    POINT = 'Point',
    POLYGON = 'Polygon',
}

/**
 *
 * @export
 * @interface GeoJSONReverseBANDtoAllOfFeaturesInnerAllOf
 */
export interface GeoJSONReverseBANDtoAllOfFeaturesInnerAllOf {
    /**
     *
     * @type {ReverseBANDto}
     * @memberof GeoJSONReverseBANDtoAllOfFeaturesInnerAllOf
     */
    properties?: ReverseBANDto;
}
/**
 *
 * @export
 * @interface GeoJSONZoneGeoJSONPropertiesDto
 */
export interface GeoJSONZoneGeoJSONPropertiesDto {
    /**
     *
     * @type {Array<GeoJSONZoneGeoJSONPropertiesDtoAllOfFeaturesInner>}
     * @memberof GeoJSONZoneGeoJSONPropertiesDto
     */
    features: Array<GeoJSONZoneGeoJSONPropertiesDtoAllOfFeaturesInner>;
    /**
     *
     * @type {string}
     * @memberof GeoJSONZoneGeoJSONPropertiesDto
     */
    type: GeoJSONZoneGeoJSONPropertiesDtoTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum GeoJSONZoneGeoJSONPropertiesDtoTypeEnum {
    FEATURE_COLLECTION = 'FeatureCollection',
}

/**
 *
 * @export
 * @interface GeoJSONZoneGeoJSONPropertiesDtoAllOf
 */
export interface GeoJSONZoneGeoJSONPropertiesDtoAllOf {
    /**
     *
     * @type {Array<GeoJSONZoneGeoJSONPropertiesDtoAllOfFeaturesInner>}
     * @memberof GeoJSONZoneGeoJSONPropertiesDtoAllOf
     */
    features?: Array<GeoJSONZoneGeoJSONPropertiesDtoAllOfFeaturesInner>;
}
/**
 *
 * @export
 * @interface GeoJSONZoneGeoJSONPropertiesDtoAllOfFeaturesInner
 */
export interface GeoJSONZoneGeoJSONPropertiesDtoAllOfFeaturesInner {
    /**
     *
     * @type {ZoneGeoJSONPropertiesDto}
     * @memberof GeoJSONZoneGeoJSONPropertiesDtoAllOfFeaturesInner
     */
    properties?: ZoneGeoJSONPropertiesDto;
    /**
     *
     * @type {Array<number>}
     * @memberof GeoJSONZoneGeoJSONPropertiesDtoAllOfFeaturesInner
     */
    bbox: Array<number>;
    /**
     *
     * @type {GeoJSONGeometry}
     * @memberof GeoJSONZoneGeoJSONPropertiesDtoAllOfFeaturesInner
     */
    geometry: GeoJSONGeometry;
    /**
     *
     * @type {string}
     * @memberof GeoJSONZoneGeoJSONPropertiesDtoAllOfFeaturesInner
     */
    type: GeoJSONZoneGeoJSONPropertiesDtoAllOfFeaturesInnerTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum GeoJSONZoneGeoJSONPropertiesDtoAllOfFeaturesInnerTypeEnum {
    FEATURE = 'Feature',
    FEATURE_COLLECTION = 'FeatureCollection',
    GEOMETRY_COLLECTION = 'GeometryCollection',
    LINE_STRING = 'LineString',
    MULTI_LINE_STRING = 'MultiLineString',
    MULTI_POINT = 'MultiPoint',
    MULTI_POLYGON = 'MultiPolygon',
    POINT = 'Point',
    POLYGON = 'Polygon',
}

/**
 *
 * @export
 * @interface GeoJSONZoneGeoJSONPropertiesDtoAllOfFeaturesInnerAllOf
 */
export interface GeoJSONZoneGeoJSONPropertiesDtoAllOfFeaturesInnerAllOf {
    /**
     *
     * @type {ZoneGeoJSONPropertiesDto}
     * @memberof GeoJSONZoneGeoJSONPropertiesDtoAllOfFeaturesInnerAllOf
     */
    properties?: ZoneGeoJSONPropertiesDto;
}
/**
 *
 * @export
 * @interface GetBaseMapDto
 */
export interface GetBaseMapDto {
    /**
     *
     * @type {Array<number>}
     * @memberof GetBaseMapDto
     */
    bbox: Array<number>;
    /**
     *
     * @type {Array<LayerStyleSpecificationFilterInner>}
     * @memberof GetBaseMapDto
     */
    zoneIds?: Array<LayerStyleSpecificationFilterInner>;
}
/**
 *
 * @export
 * @interface GetMBTilesQueryDto
 */
export interface GetMBTilesQueryDto {
    /**
     *
     * @type {Array<number>}
     * @memberof GetMBTilesQueryDto
     */
    bbox?: Array<number>;
    /**
     *
     * @type {Zones}
     * @memberof GetMBTilesQueryDto
     */
    zones?: Zones;
}
/**
 *
 * @export
 * @interface GetZoneBboxDto
 */
export interface GetZoneBboxDto {
    /**
     *
     * @type {Array<LayerStyleSpecificationFilterInner>}
     * @memberof GetZoneBboxDto
     */
    zoneIds: Array<LayerStyleSpecificationFilterInner>;
}
/**
 *
 * @export
 * @interface GetZoneEnvelopeDto
 */
export interface GetZoneEnvelopeDto {
    /**
     *
     * @type {Array<LayerStyleSpecificationFilterInner>}
     * @memberof GetZoneEnvelopeDto
     */
    zoneIds: Array<LayerStyleSpecificationFilterInner>;
}
/**
 *
 * @export
 * @interface Group
 */
export interface Group {
    /**
     *
     * @type {number}
     * @memberof Group
     */
    id: number;
    /**
     *
     * @type {Domain}
     * @memberof Group
     */
    domain: Domain;
    /**
     *
     * @type {string}
     * @memberof Group
     */
    name: string;
    /**
     *
     * @type {Array<Project>}
     * @memberof Group
     */
    projects: Array<Project>;
    /**
     *
     * @type {Role}
     * @memberof Group
     */
    role: Role;
    /**
     *
     * @type {Array<User>}
     * @memberof Group
     */
    users: Array<User>;
}
/**
 *
 * @export
 * @interface HealthResponse
 */
export interface HealthResponse {
    /**
     *
     * @type {string}
     * @memberof HealthResponse
     */
    status: HealthResponseStatusEnum;
    /**
     *
     * @type {string}
     * @memberof HealthResponse
     */
    version?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum HealthResponseStatusEnum {
    OK = 'OK',
    KO = 'KO',
}

/**
 *
 * @export
 * @interface ImageDuplicateDto
 */
export interface ImageDuplicateDto {
    /**
     *
     * @type {string}
     * @memberof ImageDuplicateDto
     */
    attribute: string;
    /**
     *
     * @type {string}
     * @memberof ImageDuplicateDto
     */
    image: string;
    /**
     *
     * @type {string}
     * @memberof ImageDuplicateDto
     */
    layer: string;
    /**
     *
     * @type {string}
     * @memberof ImageDuplicateDto
     */
    sid: string;
    /**
     *
     * @type {string}
     * @memberof ImageDuplicateDto
     */
    uuid: string;
}
/**
 *
 * @export
 * @interface ImageDuplicateResponseDto
 */
export interface ImageDuplicateResponseDto {
    /**
     *
     * @type {boolean}
     * @memberof ImageDuplicateResponseDto
     */
    duplicate: boolean;
}
/**
 *
 * @export
 * @interface ImageResponseDto
 */
export interface ImageResponseDto {
    /**
     *
     * @type {string}
     * @memberof ImageResponseDto
     */
    data_type: string;
    /**
     *
     * @type {number}
     * @memberof ImageResponseDto
     */
    fk_sid: number;
    /**
     *
     * @type {string}
     * @memberof ImageResponseDto
     */
    nom_img: string;
    /**
     *
     * @type {string}
     * @memberof ImageResponseDto
     */
    path: string;
    /**
     *
     * @type {number}
     * @memberof ImageResponseDto
     */
    sid: number;
    /**
     *
     * @type {string}
     * @memberof ImageResponseDto
     */
    type_img: string;
    /**
     *
     * @type {URLsImageResponseDto}
     * @memberof ImageResponseDto
     */
    urls?: URLsImageResponseDto;
    /**
     *
     * @type {string}
     * @memberof ImageResponseDto
     */
    user: string;
}
/**
 *
 * @export
 * @interface ImagesDumpDto
 */
export interface ImagesDumpDto {
    /**
     *
     * @type {Array<number>}
     * @memberof ImagesDumpDto
     */
    bbox: Array<number>;
    /**
     *
     * @type {boolean}
     * @memberof ImagesDumpDto
     */
    disableImages: boolean;
    /**
     *
     * @type {Array<LayerStyleSpecificationFilterInner>}
     * @memberof ImagesDumpDto
     */
    zoneIds?: Array<LayerStyleSpecificationFilterInner>;
}
/**
 *
 * @export
 * @interface LabelCord
 */
export interface LabelCord {
    /**
     *
     * @type {number}
     * @memberof LabelCord
     */
    bottom: number;
    /**
     *
     * @type {number}
     * @memberof LabelCord
     */
    confidence: number;
    /**
     *
     * @type {string}
     * @memberof LabelCord
     */
    label: LabelCordLabelEnum;
    /**
     *
     * @type {number}
     * @memberof LabelCord
     */
    left: number;
    /**
     *
     * @type {number}
     * @memberof LabelCord
     */
    right: number;
    /**
     *
     * @type {number}
     * @memberof LabelCord
     */
    top: number;
}

/**
 * @export
 * @enum {string}
 */
export enum LabelCordLabelEnum {
    CONTINUITE_OPTIQUE = 'continuite_optique',
    ETIQUETTE_FINALE = 'etiquette_finale',
    ETIQUETTE_PROVISOIRE = 'etiquette_provisoire',
    FACADE = 'facade',
    LOVAGE_CORRECT = 'lovage_correct',
    LOVAGE_INCORRECT = 'lovage_incorrect',
    PBO = 'pbo',
    PBO_SOUTERRAIN = 'pbo_souterrain',
    PLAQUE_IMMATRICULATION = 'plaque_immatriculation',
    POTEAU = 'poteau',
    TAMPON_FERME = 'tampon_ferme',
    TAMPON_OUVERT = 'tampon_ouvert',
    TUYAU = 'tuyau',
    VISAGE = 'visage',
}

/**
 *
 * @export
 * @interface Layer
 */
export interface Layer {
    /**
     *
     * @type {number}
     * @memberof Layer
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof Layer
     */
    aggregateField: string;
    /**
     *
     * @type {boolean}
     * @memberof Layer
     */
    ai: boolean;
    /**
     *
     * @type {string}
     * @memberof Layer
     */
    alias: string;
    /**
     *
     * @type {Array<LayerAttribute>}
     * @memberof Layer
     */
    attributes: Array<LayerAttribute>;
    /**
     *
     * @type {string}
     * @memberof Layer
     */
    category: string | null;
    /**
     *
     * @type {Array<Layer>}
     * @memberof Layer
     */
    childrenLayer: Array<Layer>;
    /**
     *
     * @type {Date}
     * @memberof Layer
     */
    createdAt: Date;
    /**
     *
     * @type {string}
     * @memberof Layer
     */
    customAggregateOptions: string;
    /**
     *
     * @type {string}
     * @memberof Layer
     */
    customSid: string;
    /**
     *
     * @type {boolean}
     * @memberof Layer
     */
    enableCreation: boolean;
    /**
     *
     * @type {boolean}
     * @memberof Layer
     */
    enableEdition: boolean;
    /**
     *
     * @type {string}
     * @memberof Layer
     */
    form: string | null;
    /**
     *
     * @type {boolean}
     * @memberof Layer
     */
    hiddenByDefault: boolean;
    /**
     *
     * @type {string}
     * @memberof Layer
     */
    imgTable: string | null;
    /**
     *
     * @type {number}
     * @memberof Layer
     */
    maxZoom: number;
    /**
     *
     * @type {number}
     * @memberof Layer
     */
    minZoom: number;
    /**
     *
     * @type {string}
     * @memberof Layer
     */
    name: string;
    /**
     *
     * @type {Layer}
     * @memberof Layer
     */
    parentLayer: Layer;
    /**
     *
     * @type {Array<Project>}
     * @memberof Layer
     */
    project: Array<Project>;
    /**
     *
     * @type {Array<ProjectLayer>}
     * @memberof Layer
     */
    projectsLayers: Array<ProjectLayer>;
    /**
     *
     * @type {string}
     * @memberof Layer
     */
    renderFormat: LayerRenderFormatEnum;
    /**
     *
     * @type {string}
     * @memberof Layer
     */
    schema: string;
    /**
     *
     * @type {Style}
     * @memberof Layer
     */
    style: Style;
    /**
     *
     * @type {string}
     * @memberof Layer
     */
    table: string;
    /**
     *
     * @type {string}
     * @memberof Layer
     */
    type: LayerTypeEnum;
    /**
     *
     * @type {Date}
     * @memberof Layer
     */
    updatedAt: Date;
    /**
     *
     * @type {Array<Zone>}
     * @memberof Layer
     */
    zones: Array<Zone>;
}

/**
 * @export
 * @enum {string}
 */
export enum LayerRenderFormatEnum {
    GEOJSON = 'geojson',
    PBF = 'pbf',
}
/**
 * @export
 * @enum {string}
 */
export enum LayerTypeEnum {
    AGGREGATE = 'AGGREGATE',
    BOX_PLAN_V2 = 'BOX_PLAN_V2',
    BOX_PLAN_V3 = 'BOX_PLAN_V3',
    DEFAULT = 'DEFAULT',
    INVISIBLE = 'INVISIBLE',
    MATERIALIZED_BOX_PLAN_V2 = 'MATERIALIZED_BOX_PLAN_V2',
    MATERIALIZED_BOX_PLAN_V3 = 'MATERIALIZED_BOX_PLAN_V3',
    VIRTUAL = 'VIRTUAL',
}

/**
 *
 * @export
 * @interface LayerAttribute
 */
export interface LayerAttribute {
    /**
     *
     * @type {string}
     * @memberof LayerAttribute
     */
    columnName: string;
    /**
     *
     * @type {boolean}
     * @memberof LayerAttribute
     */
    isDisplayInSummary: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LayerAttribute
     */
    isKey: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LayerAttribute
     */
    isSearchable: boolean;
    /**
     *
     * @type {string}
     * @memberof LayerAttribute
     */
    label: string;
    /**
     *
     * @type {Layer}
     * @memberof LayerAttribute
     */
    layer: Layer;
}
/**
 *
 * @export
 * @interface LayerStyleSpecification
 */
export interface LayerStyleSpecification {
    /**
     *
     * @type {Array<LayerStyleSpecificationFilterInner>}
     * @memberof LayerStyleSpecification
     */
    filter?: Array<LayerStyleSpecificationFilterInner>;
    /**
     *
     * @type {string}
     * @memberof LayerStyleSpecification
     */
    id: string;
    /**
     *
     * @type {LayoutSpecification}
     * @memberof LayerStyleSpecification
     */
    layout?: LayoutSpecification;
    /**
     *
     * @type {number}
     * @memberof LayerStyleSpecification
     */
    maxzoom?: number;
    /**
     *
     * @type {object}
     * @memberof LayerStyleSpecification
     */
    metadata?: object;
    /**
     *
     * @type {number}
     * @memberof LayerStyleSpecification
     */
    minzoom?: number;
    /**
     *
     * @type {PaintSpecification}
     * @memberof LayerStyleSpecification
     */
    paint?: PaintSpecification;
    /**
     *
     * @type {string}
     * @memberof LayerStyleSpecification
     */
    source: string;
    /**
     *
     * @type {string}
     * @memberof LayerStyleSpecification
     */
    'source-layer'?: string;
    /**
     *
     * @type {string}
     * @memberof LayerStyleSpecification
     */
    type: LayerStyleSpecificationTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum LayerStyleSpecificationTypeEnum {
    SKY = 'sky',
    BACKGROUND = 'background',
    SYMBOL = 'symbol',
    LINE = 'line',
    FILL = 'fill',
    CIRCLE = 'circle',
    HEATMAP = 'heatmap',
    FILL_EXTRUSION = 'fill-extrusion',
    RASTER = 'raster',
    HILLSHADE = 'hillshade',
}

/**
 * @type LayerStyleSpecificationFilterInner
 * @export
 */
export type LayerStyleSpecificationFilterInner = number | string;

/**
 *
 * @export
 * @interface LayoutSpecification
 */
export interface LayoutSpecification {
    /**
     *
     * @type {Array<number>}
     * @memberof LayoutSpecification
     */
    'icon-offset'?: Array<number>;
    /**
     *
     * @type {Array<number>}
     * @memberof LayoutSpecification
     */
    'icon-text-fit-padding'?: Array<number>;
    /**
     *
     * @type {Array<number>}
     * @memberof LayoutSpecification
     */
    'text-offset'?: Array<number>;
    /**
     *
     * @type {number}
     * @memberof LayoutSpecification
     */
    'circle-sort-key'?: number;
    /**
     *
     * @type {number}
     * @memberof LayoutSpecification
     */
    'fill-extrusion-edge-radius'?: number;
    /**
     *
     * @type {Array<number>}
     * @memberof LayoutSpecification
     */
    'fill-sort-key'?: Array<number>;
    /**
     *
     * @type {boolean}
     * @memberof LayoutSpecification
     */
    'icon-allow-overlap'?: boolean;
    /**
     *
     * @type {string}
     * @memberof LayoutSpecification
     */
    'icon-anchor'?: LayoutSpecificationIconAnchorEnum;
    /**
     *
     * @type {boolean}
     * @memberof LayoutSpecification
     */
    'icon-ignore-placement'?: boolean;
    /**
     *
     * @type {string}
     * @memberof LayoutSpecification
     */
    'icon-image'?: string;
    /**
     *
     * @type {boolean}
     * @memberof LayoutSpecification
     */
    'icon-keep-upright'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LayoutSpecification
     */
    'icon-optional'?: boolean;
    /**
     *
     * @type {number}
     * @memberof LayoutSpecification
     */
    'icon-padding'?: number;
    /**
     *
     * @type {string}
     * @memberof LayoutSpecification
     */
    'icon-pitch-alignment'?: LayoutSpecificationIconPitchAlignmentEnum;
    /**
     *
     * @type {number}
     * @memberof LayoutSpecification
     */
    'icon-rotate'?: number;
    /**
     *
     * @type {string}
     * @memberof LayoutSpecification
     */
    'icon-rotation-alignment'?: LayoutSpecificationIconRotationAlignmentEnum;
    /**
     *
     * @type {number}
     * @memberof LayoutSpecification
     */
    'icon-size'?: number;
    /**
     *
     * @type {string}
     * @memberof LayoutSpecification
     */
    'icon-text-fit'?: LayoutSpecificationIconTextFitEnum;
    /**
     *
     * @type {string}
     * @memberof LayoutSpecification
     */
    'line-cap'?: LayoutSpecificationLineCapEnum;
    /**
     *
     * @type {string}
     * @memberof LayoutSpecification
     */
    'line-join'?: LayoutSpecificationLineJoinEnum;
    /**
     *
     * @type {number}
     * @memberof LayoutSpecification
     */
    'line-miter-limit'?: number;
    /**
     *
     * @type {number}
     * @memberof LayoutSpecification
     */
    'line-round-limit'?: number;
    /**
     *
     * @type {number}
     * @memberof LayoutSpecification
     */
    'line-sort-key'?: number;
    /**
     *
     * @type {boolean}
     * @memberof LayoutSpecification
     */
    'symbol-avoid-edges'?: boolean;
    /**
     *
     * @type {string}
     * @memberof LayoutSpecification
     */
    'symbol-placement'?: LayoutSpecificationSymbolPlacementEnum;
    /**
     *
     * @type {number}
     * @memberof LayoutSpecification
     */
    'symbol-sort-key'?: number;
    /**
     *
     * @type {number}
     * @memberof LayoutSpecification
     */
    'symbol-spacing'?: number;
    /**
     *
     * @type {string}
     * @memberof LayoutSpecification
     */
    'symbol-z-order'?: LayoutSpecificationSymbolZOrderEnum;
    /**
     *
     * @type {boolean}
     * @memberof LayoutSpecification
     */
    'text-allow-overlap'?: boolean;
    /**
     *
     * @type {string}
     * @memberof LayoutSpecification
     */
    'text-anchor'?: LayoutSpecificationTextAnchorEnum;
    /**
     *
     * @type {string}
     * @memberof LayoutSpecification
     */
    'text-field'?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof LayoutSpecification
     */
    'text-font'?: Array<string>;
    /**
     *
     * @type {boolean}
     * @memberof LayoutSpecification
     */
    'text-ignore-placement'?: boolean;
    /**
     *
     * @type {string}
     * @memberof LayoutSpecification
     */
    'text-justify'?: LayoutSpecificationTextJustifyEnum;
    /**
     *
     * @type {boolean}
     * @memberof LayoutSpecification
     */
    'text-keep-upright'?: boolean;
    /**
     *
     * @type {number}
     * @memberof LayoutSpecification
     */
    'text-letter-spacing'?: number;
    /**
     *
     * @type {number}
     * @memberof LayoutSpecification
     */
    'text-line-height'?: number;
    /**
     *
     * @type {number}
     * @memberof LayoutSpecification
     */
    'text-max-angle'?: number;
    /**
     *
     * @type {number}
     * @memberof LayoutSpecification
     */
    'text-max-width'?: number;
    /**
     *
     * @type {boolean}
     * @memberof LayoutSpecification
     */
    'text-optional'?: boolean;
    /**
     *
     * @type {number}
     * @memberof LayoutSpecification
     */
    'text-padding'?: number;
    /**
     *
     * @type {string}
     * @memberof LayoutSpecification
     */
    'text-pitch-alignment'?: LayoutSpecificationTextPitchAlignmentEnum;
    /**
     *
     * @type {number}
     * @memberof LayoutSpecification
     */
    'text-radial-offset'?: number;
    /**
     *
     * @type {number}
     * @memberof LayoutSpecification
     */
    'text-rotate'?: number;
    /**
     *
     * @type {string}
     * @memberof LayoutSpecification
     */
    'text-rotation-alignment'?: LayoutSpecificationTextRotationAlignmentEnum;
    /**
     *
     * @type {number}
     * @memberof LayoutSpecification
     */
    'text-size'?: number;
    /**
     *
     * @type {string}
     * @memberof LayoutSpecification
     */
    'text-transform'?: LayoutSpecificationTextTransformEnum;
    /**
     *
     * @type {string}
     * @memberof LayoutSpecification
     */
    'text-variable-anchor'?: LayoutSpecificationTextVariableAnchorEnum;
    /**
     *
     * @type {string}
     * @memberof LayoutSpecification
     */
    'text-writing-mode'?: LayoutSpecificationTextWritingModeEnum;
    /**
     *
     * @type {string}
     * @memberof LayoutSpecification
     */
    visibility?: LayoutSpecificationVisibilityEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum LayoutSpecificationIconAnchorEnum {
    CENTER = 'center',
    LEFT = 'left',
    RIGHT = 'right',
    TOP = 'top',
    BOTTOM = 'bottom',
    TOP_LEFT = 'top-left',
    TOP_RIGHT = 'top-right',
    BOTTOM_LEFT = 'bottom-left',
    BOTTOM_RIGHT = 'bottom-right',
}
/**
 * @export
 * @enum {string}
 */
export enum LayoutSpecificationIconPitchAlignmentEnum {
    AUTO = 'auto',
    MAP = 'map',
    VIEWPORT = 'viewport',
}
/**
 * @export
 * @enum {string}
 */
export enum LayoutSpecificationIconRotationAlignmentEnum {
    AUTO = 'auto',
    MAP = 'map',
    VIEWPORT = 'viewport',
}
/**
 * @export
 * @enum {string}
 */
export enum LayoutSpecificationIconTextFitEnum {
    BOTH = 'both',
    HEIGHT = 'height',
    NONE = 'none',
    WIDTH = 'width',
}
/**
 * @export
 * @enum {string}
 */
export enum LayoutSpecificationLineCapEnum {
    BUTT = 'butt',
    ROUND = 'round',
    SQUARE = 'square',
}
/**
 * @export
 * @enum {string}
 */
export enum LayoutSpecificationLineJoinEnum {
    BEVEL = 'bevel',
    ROUND = 'round',
    MITER = 'miter',
}
/**
 * @export
 * @enum {string}
 */
export enum LayoutSpecificationSymbolPlacementEnum {
    POINT = 'point',
    LINE = 'line',
    LINE_CENTER = 'line-center',
}
/**
 * @export
 * @enum {string}
 */
export enum LayoutSpecificationSymbolZOrderEnum {
    AUTO = 'auto',
    VIEWPORT_Y = 'viewport-y',
    SOURCE = 'source',
}
/**
 * @export
 * @enum {string}
 */
export enum LayoutSpecificationTextAnchorEnum {
    CENTER = 'center',
    LEFT = 'left',
    RIGHT = 'right',
    TOP = 'top',
    BOTTOM = 'bottom',
    TOP_LEFT = 'top-left',
    TOP_RIGHT = 'top-right',
    BOTTOM_LEFT = 'bottom-left',
    BOTTOM_RIGHT = 'bottom-right',
}
/**
 * @export
 * @enum {string}
 */
export enum LayoutSpecificationTextJustifyEnum {
    AUTO = 'auto',
    CENTER = 'center',
    LEFT = 'left',
    RIGHT = 'right',
}
/**
 * @export
 * @enum {string}
 */
export enum LayoutSpecificationTextPitchAlignmentEnum {
    AUTO = 'auto',
    MAP = 'map',
    VIEWPORT = 'viewport',
}
/**
 * @export
 * @enum {string}
 */
export enum LayoutSpecificationTextRotationAlignmentEnum {
    AUTO = 'auto',
    MAP = 'map',
    VIEWPORT = 'viewport',
}
/**
 * @export
 * @enum {string}
 */
export enum LayoutSpecificationTextTransformEnum {
    NONE = 'none',
    UPPERCASE = 'uppercase',
    LOWERCASE = 'lowercase',
}
/**
 * @export
 * @enum {string}
 */
export enum LayoutSpecificationTextVariableAnchorEnum {
    CENTER = 'center',
    LEFT = 'left',
    RIGHT = 'right',
    TOP = 'top',
    BOTTOM = 'bottom',
    TOP_LEFT = 'top-left',
    TOP_RIGHT = 'top-right',
    BOTTOM_LEFT = 'bottom-left',
    BOTTOM_RIGHT = 'bottom-right',
}
/**
 * @export
 * @enum {string}
 */
export enum LayoutSpecificationTextWritingModeEnum {
    HORIZONTAL = 'horizontal',
    VERTICAL = 'vertical',
}
/**
 * @export
 * @enum {string}
 */
export enum LayoutSpecificationVisibilityEnum {
    NONE = 'none',
    VISIBLE = 'visible',
}

/**
 *
 * @export
 * @interface LoginDto
 */
export interface LoginDto {
    /**
     *
     * @type {string}
     * @memberof LoginDto
     */
    password: string;
    /**
     *
     * @type {string}
     * @memberof LoginDto
     */
    email: string;
}
/**
 *
 * @export
 * @interface LoginLdapDto
 */
export interface LoginLdapDto {
    /**
     *
     * @type {string}
     * @memberof LoginLdapDto
     */
    password: string;
    /**
     *
     * @type {string}
     * @memberof LoginLdapDto
     */
    username: string;
}
/**
 *
 * @export
 * @interface LoginResponseDto
 */
export interface LoginResponseDto {
    /**
     *
     * @type {string}
     * @memberof LoginResponseDto
     */
    token: string;
    /**
     *
     * @type {string}
     * @memberof LoginResponseDto
     */
    userEmail: string;
    /**
     *
     * @type {number}
     * @memberof LoginResponseDto
     */
    userId: number;
    /**
     *
     * @type {string}
     * @memberof LoginResponseDto
     */
    userName: string;
}
/**
 *
 * @export
 * @interface Metrics
 */
export interface Metrics {
    /**
     *
     * @type {number}
     * @memberof Metrics
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof Metrics
     */
    action: string;
    /**
     *
     * @type {Date}
     * @memberof Metrics
     */
    createdAt: Date;
    /**
     *
     * @type {string}
     * @memberof Metrics
     */
    method: MetricsMethodEnum;
    /**
     *
     * @type {string}
     * @memberof Metrics
     */
    path: string;
    /**
     *
     * @type {User}
     * @memberof Metrics
     */
    user: User;
}

/**
 * @export
 * @enum {string}
 */
export enum MetricsMethodEnum {
    GET = 'GET',
    POST = 'POST',
    DELETE = 'DELETE',
    PUT = 'PUT',
}

/**
 *
 * @export
 * @interface OperatorsDto
 */
export interface OperatorsDto {
    /**
     *
     * @type {string}
     * @memberof OperatorsDto
     */
    operators?: OperatorsDtoOperatorsEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum OperatorsDtoOperatorsEnum {
    EQ = '$eq',
    LIKE = '$like',
}

/**
 *
 * @export
 * @interface PBOPostProcess
 */
export interface PBOPostProcess {
    /**
     *
     * @type {number}
     * @memberof PBOPostProcess
     */
    approximate_distance_from_ground_cm: number;
    /**
     *
     * @type {number}
     * @memberof PBOPostProcess
     */
    approximate_distance_from_ground_pixels: number;
    /**
     *
     * @type {boolean}
     * @memberof PBOPostProcess
     */
    correct_height: boolean;
    /**
     *
     * @type {string}
     * @memberof PBOPostProcess
     */
    id_pbo: string;
}
/**
 *
 * @export
 * @interface PaintSpecification
 */
export interface PaintSpecification {
    /**
     *
     * @type {Array<number>}
     * @memberof PaintSpecification
     */
    'circle-translate'?: Array<number>;
    /**
     *
     * @type {Array<number>}
     * @memberof PaintSpecification
     */
    'fill-translate'?: Array<number>;
    /**
     *
     * @type {Array<number>}
     * @memberof PaintSpecification
     */
    'icon-translate'?: Array<number>;
    /**
     *
     * @type {Array<number>}
     * @memberof PaintSpecification
     */
    'line-trim-offset'?: Array<number>;
    /**
     *
     * @type {Array<number>}
     * @memberof PaintSpecification
     */
    'sky-gradient-center'?: Array<number>;
    /**
     *
     * @type {Array<number>}
     * @memberof PaintSpecification
     */
    'text-translate'?: Array<number>;
    /**
     *
     * @type {string}
     * @memberof PaintSpecification
     */
    'background-color'?: string;
    /**
     *
     * @type {number}
     * @memberof PaintSpecification
     */
    'background-opacity'?: number;
    /**
     *
     * @type {string}
     * @memberof PaintSpecification
     */
    'background-pattern'?: string;
    /**
     *
     * @type {number}
     * @memberof PaintSpecification
     */
    'circle-blur'?: number;
    /**
     *
     * @type {string}
     * @memberof PaintSpecification
     */
    'circle-color'?: string;
    /**
     *
     * @type {number}
     * @memberof PaintSpecification
     */
    'circle-opacity'?: number;
    /**
     *
     * @type {string}
     * @memberof PaintSpecification
     */
    'circle-pitch-alignment'?: PaintSpecificationCirclePitchAlignmentEnum;
    /**
     *
     * @type {string}
     * @memberof PaintSpecification
     */
    'circle-pitch-scale'?: PaintSpecificationCirclePitchScaleEnum;
    /**
     *
     * @type {number}
     * @memberof PaintSpecification
     */
    'circle-radius'?: number;
    /**
     *
     * @type {string}
     * @memberof PaintSpecification
     */
    'circle-stroke-color'?: string;
    /**
     *
     * @type {number}
     * @memberof PaintSpecification
     */
    'circle-stroke-opacity'?: number;
    /**
     *
     * @type {number}
     * @memberof PaintSpecification
     */
    'circle-stroke-width'?: number;
    /**
     *
     * @type {string}
     * @memberof PaintSpecification
     */
    'circle-translate-anchor'?: PaintSpecificationCircleTranslateAnchorEnum;
    /**
     *
     * @type {boolean}
     * @memberof PaintSpecification
     */
    'fill-antialias'?: boolean;
    /**
     *
     * @type {string}
     * @memberof PaintSpecification
     */
    'fill-color'?: string;
    /**
     *
     * @type {number}
     * @memberof PaintSpecification
     */
    'fill-extrusion-ambient-occlusion-intensity'?: number;
    /**
     *
     * @type {number}
     * @memberof PaintSpecification
     */
    'fill-extrusion-ambient-occlusion-radius'?: number;
    /**
     *
     * @type {number}
     * @memberof PaintSpecification
     */
    'fill-extrusion-base'?: number;
    /**
     *
     * @type {string}
     * @memberof PaintSpecification
     */
    'fill-extrusion-color'?: string;
    /**
     *
     * @type {number}
     * @memberof PaintSpecification
     */
    'fill-extrusion-height'?: number;
    /**
     *
     * @type {number}
     * @memberof PaintSpecification
     */
    'fill-extrusion-opacity'?: number;
    /**
     *
     * @type {string}
     * @memberof PaintSpecification
     */
    'fill-extrusion-pattern'?: string;
    /**
     *
     * @type {boolean}
     * @memberof PaintSpecification
     */
    'fill-extrusion-rounded-roof'?: boolean;
    /**
     *
     * @type {Array<number>}
     * @memberof PaintSpecification
     */
    'fill-extrusion-translate'?: Array<number>;
    /**
     *
     * @type {string}
     * @memberof PaintSpecification
     */
    'fill-extrusion-translate-anchor'?: PaintSpecificationFillExtrusionTranslateAnchorEnum;
    /**
     *
     * @type {boolean}
     * @memberof PaintSpecification
     */
    'fill-extrusion-vertical-gradient'?: boolean;
    /**
     *
     * @type {number}
     * @memberof PaintSpecification
     */
    'fill-opacity'?: number;
    /**
     *
     * @type {string}
     * @memberof PaintSpecification
     */
    'fill-outline-color'?: string;
    /**
     *
     * @type {string}
     * @memberof PaintSpecification
     */
    'fill-pattern'?: string;
    /**
     *
     * @type {string}
     * @memberof PaintSpecification
     */
    'fill-translate-anchor'?: PaintSpecificationFillTranslateAnchorEnum;
    /**
     *
     * @type {string}
     * @memberof PaintSpecification
     */
    'heatmap-color'?: string;
    /**
     *
     * @type {number}
     * @memberof PaintSpecification
     */
    'heatmap-intensity'?: number;
    /**
     *
     * @type {number}
     * @memberof PaintSpecification
     */
    'heatmap-opacity'?: number;
    /**
     *
     * @type {number}
     * @memberof PaintSpecification
     */
    'heatmap-radius'?: number;
    /**
     *
     * @type {number}
     * @memberof PaintSpecification
     */
    'heatmap-weight'?: number;
    /**
     *
     * @type {string}
     * @memberof PaintSpecification
     */
    'hillshade-accent-color'?: string;
    /**
     *
     * @type {number}
     * @memberof PaintSpecification
     */
    'hillshade-exaggeration'?: number;
    /**
     *
     * @type {string}
     * @memberof PaintSpecification
     */
    'hillshade-highlight-color'?: string;
    /**
     *
     * @type {string}
     * @memberof PaintSpecification
     */
    'hillshade-illumination-anchor'?: PaintSpecificationHillshadeIlluminationAnchorEnum;
    /**
     *
     * @type {number}
     * @memberof PaintSpecification
     */
    'hillshade-illumination-direction'?: number;
    /**
     *
     * @type {string}
     * @memberof PaintSpecification
     */
    'hillshade-shadow-color'?: string;
    /**
     *
     * @type {string}
     * @memberof PaintSpecification
     */
    'icon-color'?: string;
    /**
     *
     * @type {number}
     * @memberof PaintSpecification
     */
    'icon-halo-blur'?: number;
    /**
     *
     * @type {string}
     * @memberof PaintSpecification
     */
    'icon-halo-color'?: string;
    /**
     *
     * @type {number}
     * @memberof PaintSpecification
     */
    'icon-halo-width'?: number;
    /**
     *
     * @type {number}
     * @memberof PaintSpecification
     */
    'icon-opacity'?: number;
    /**
     *
     * @type {string}
     * @memberof PaintSpecification
     */
    'icon-translate-anchor'?: PaintSpecificationIconTranslateAnchorEnum;
    /**
     *
     * @type {number}
     * @memberof PaintSpecification
     */
    'line-blur'?: number;
    /**
     *
     * @type {string}
     * @memberof PaintSpecification
     */
    'line-color'?: string;
    /**
     *
     * @type {Array<number>}
     * @memberof PaintSpecification
     */
    'line-dasharray'?: Array<number>;
    /**
     *
     * @type {number}
     * @memberof PaintSpecification
     */
    'line-gap-width'?: number;
    /**
     *
     * @type {object}
     * @memberof PaintSpecification
     */
    'line-gradient'?: object;
    /**
     *
     * @type {number}
     * @memberof PaintSpecification
     */
    'line-offset'?: number;
    /**
     *
     * @type {number}
     * @memberof PaintSpecification
     */
    'line-opacity'?: number;
    /**
     *
     * @type {string}
     * @memberof PaintSpecification
     */
    'line-pattern'?: string;
    /**
     *
     * @type {Array<number>}
     * @memberof PaintSpecification
     */
    'line-translate'?: Array<number>;
    /**
     *
     * @type {string}
     * @memberof PaintSpecification
     */
    'line-translate-anchor'?: PaintSpecificationLineTranslateAnchorEnum;
    /**
     *
     * @type {number}
     * @memberof PaintSpecification
     */
    'line-width'?: number;
    /**
     *
     * @type {number}
     * @memberof PaintSpecification
     */
    'raster-brightness-max'?: number;
    /**
     *
     * @type {number}
     * @memberof PaintSpecification
     */
    'raster-brightness-min'?: number;
    /**
     *
     * @type {number}
     * @memberof PaintSpecification
     */
    'raster-contrast'?: number;
    /**
     *
     * @type {number}
     * @memberof PaintSpecification
     */
    'raster-fade-duration'?: number;
    /**
     *
     * @type {number}
     * @memberof PaintSpecification
     */
    'raster-hue-rotate'?: number;
    /**
     *
     * @type {number}
     * @memberof PaintSpecification
     */
    'raster-opacity'?: number;
    /**
     *
     * @type {string}
     * @memberof PaintSpecification
     */
    'raster-resampling'?: PaintSpecificationRasterResamplingEnum;
    /**
     *
     * @type {number}
     * @memberof PaintSpecification
     */
    'raster-saturation'?: number;
    /**
     *
     * @type {string}
     * @memberof PaintSpecification
     */
    'sky-atmosphere-color'?: string;
    /**
     *
     * @type {string}
     * @memberof PaintSpecification
     */
    'sky-atmosphere-halo-color'?: string;
    /**
     *
     * @type {Array<number>}
     * @memberof PaintSpecification
     */
    'sky-atmosphere-sun'?: Array<number>;
    /**
     *
     * @type {number}
     * @memberof PaintSpecification
     */
    'sky-atmosphere-sun-intensity'?: number;
    /**
     *
     * @type {object}
     * @memberof PaintSpecification
     */
    'sky-gradient'?: object;
    /**
     *
     * @type {number}
     * @memberof PaintSpecification
     */
    'sky-gradient-radius'?: number;
    /**
     *
     * @type {number}
     * @memberof PaintSpecification
     */
    'sky-opacity'?: number;
    /**
     *
     * @type {string}
     * @memberof PaintSpecification
     */
    'sky-type'?: PaintSpecificationSkyTypeEnum;
    /**
     *
     * @type {string}
     * @memberof PaintSpecification
     */
    'text-color'?: string;
    /**
     *
     * @type {number}
     * @memberof PaintSpecification
     */
    'text-halo-blur'?: number;
    /**
     *
     * @type {string}
     * @memberof PaintSpecification
     */
    'text-halo-color'?: string;
    /**
     *
     * @type {number}
     * @memberof PaintSpecification
     */
    'text-halo-width'?: number;
    /**
     *
     * @type {number}
     * @memberof PaintSpecification
     */
    'text-opacity'?: number;
    /**
     *
     * @type {string}
     * @memberof PaintSpecification
     */
    'text-translate-anchor'?: PaintSpecificationTextTranslateAnchorEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum PaintSpecificationCirclePitchAlignmentEnum {
    MAP = 'map',
    VIEWPORT = 'viewport',
}
/**
 * @export
 * @enum {string}
 */
export enum PaintSpecificationCirclePitchScaleEnum {
    MAP = 'map',
    VIEWPORT = 'viewport',
}
/**
 * @export
 * @enum {string}
 */
export enum PaintSpecificationCircleTranslateAnchorEnum {
    MAP = 'map',
    VIEWPORT = 'viewport',
}
/**
 * @export
 * @enum {string}
 */
export enum PaintSpecificationFillExtrusionTranslateAnchorEnum {
    MAP = 'map',
    VIEWPORT = 'viewport',
}
/**
 * @export
 * @enum {string}
 */
export enum PaintSpecificationFillTranslateAnchorEnum {
    MAP = 'map',
    VIEWPORT = 'viewport',
}
/**
 * @export
 * @enum {string}
 */
export enum PaintSpecificationHillshadeIlluminationAnchorEnum {
    MAP = 'map',
    VIEWPORT = 'viewport',
}
/**
 * @export
 * @enum {string}
 */
export enum PaintSpecificationIconTranslateAnchorEnum {
    MAP = 'map',
    VIEWPORT = 'viewport',
}
/**
 * @export
 * @enum {string}
 */
export enum PaintSpecificationLineTranslateAnchorEnum {
    MAP = 'map',
    VIEWPORT = 'viewport',
}
/**
 * @export
 * @enum {string}
 */
export enum PaintSpecificationRasterResamplingEnum {
    LINEAR = 'linear',
    NEAREST = 'nearest',
}
/**
 * @export
 * @enum {string}
 */
export enum PaintSpecificationSkyTypeEnum {
    ATMOSPHERE = 'atmosphere',
    GRADIENT = 'gradient',
}
/**
 * @export
 * @enum {string}
 */
export enum PaintSpecificationTextTranslateAnchorEnum {
    MAP = 'map',
    VIEWPORT = 'viewport',
}

/**
 *
 * @export
 * @interface PostProcess
 */
export interface PostProcess {
    /**
     *
     * @type {Array<EtiquettePostProcess>}
     * @memberof PostProcess
     */
    etiquette_post_processing?: Array<EtiquettePostProcess>;
    /**
     *
     * @type {Array<PBOPostProcess>}
     * @memberof PostProcess
     */
    pbo_post_processing?: Array<PBOPostProcess>;
}
/**
 *
 * @export
 * @interface PredictImageDto
 */
export interface PredictImageDto {
    /**
     *
     * @type {string}
     * @memberof PredictImageDto
     */
    image: string;
}
/**
 *
 * @export
 * @interface PredictImageResponseDto
 */
export interface PredictImageResponseDto {
    /**
     *
     * @type {Array<LabelCord>}
     * @memberof PredictImageResponseDto
     */
    label_coord: Array<LabelCord>;
    /**
     *
     * @type {PostProcess}
     * @memberof PredictImageResponseDto
     */
    post_process: PostProcess;
}
/**
 *
 * @export
 * @interface Project
 */
export interface Project {
    /**
     *
     * @type {Array<number>}
     * @memberof Project
     */
    bbox: Array<number>;
    /**
     *
     * @type {Date}
     * @memberof Project
     */
    createdAt: Date;
    /**
     *
     * @type {Domain}
     * @memberof Project
     */
    domain: Domain;
    /**
     *
     * @type {Array<Domain>}
     * @memberof Project
     */
    domainsProjects: Array<Domain>;
    /**
     *
     * @type {Array<Group>}
     * @memberof Project
     */
    groups: Array<Group>;
    /**
     *
     * @type {number}
     * @memberof Project
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof Project
     */
    img: string;
    /**
     *
     * @type {string}
     * @memberof Project
     */
    name: string;
    /**
     *
     * @type {Array<ProjectLayer>}
     * @memberof Project
     */
    projectsLayers: Array<ProjectLayer>;
    /**
     *
     * @type {Layer}
     * @memberof Project
     */
    selectionLayer: Layer;
    /**
     *
     * @type {Date}
     * @memberof Project
     */
    updatedAt: Date;
    /**
     *
     * @type {Array<User>}
     * @memberof Project
     */
    users: Array<User>;
}
/**
 *
 * @export
 * @interface ProjectConfigurationDto
 */
export interface ProjectConfigurationDto {
    /**
     *
     * @type {Array<number>}
     * @memberof ProjectConfigurationDto
     */
    bbox: Array<number>;
    /**
     *
     * @type {object}
     * @memberof ProjectConfigurationDto
     */
    domain: object;
    /**
     *
     * @type {Array<ProjectConfigurationLayer>}
     * @memberof ProjectConfigurationDto
     */
    layers: Array<ProjectConfigurationLayer>;
    /**
     *
     * @type {string}
     * @memberof ProjectConfigurationDto
     */
    name: string;
    /**
     *
     * @type {SelectionLayerConfiguration}
     * @memberof ProjectConfigurationDto
     */
    selectionLayer?: SelectionLayerConfiguration;
}
/**
 *
 * @export
 * @interface ProjectConfigurationLayer
 */
export interface ProjectConfigurationLayer {
    /**
     *
     * @type {string}
     * @memberof ProjectConfigurationLayer
     */
    alias: string;
    /**
     *
     * @type {string}
     * @memberof ProjectConfigurationLayer
     */
    category: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProjectConfigurationLayer
     */
    enableCreation: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProjectConfigurationLayer
     */
    enableEdition: boolean;
    /**
     *
     * @type {string}
     * @memberof ProjectConfigurationLayer
     */
    form: string | null;
    /**
     *
     * @type {string}
     * @memberof ProjectConfigurationLayer
     */
    format: ProjectConfigurationLayerFormatEnum;
    /**
     *
     * @type {boolean}
     * @memberof ProjectConfigurationLayer
     */
    hiddenByDefault: boolean;
    /**
     *
     * @type {Array<string>}
     * @memberof ProjectConfigurationLayer
     */
    hiddenFields: Array<string>;
    /**
     *
     * @type {boolean}
     * @memberof ProjectConfigurationLayer
     */
    hiddenLegend: boolean;
    /**
     *
     * @type {number}
     * @memberof ProjectConfigurationLayer
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof ProjectConfigurationLayer
     */
    idField?: string;
    /**
     *
     * @type {object}
     * @memberof ProjectConfigurationLayer
     */
    labelFields: object;
    /**
     *
     * @type {number}
     * @memberof ProjectConfigurationLayer
     */
    maxZoom: number;
    /**
     *
     * @type {number}
     * @memberof ProjectConfigurationLayer
     */
    minZoom: number;
    /**
     *
     * @type {string}
     * @memberof ProjectConfigurationLayer
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof ProjectConfigurationLayer
     */
    parentLayerId?: number;
    /**
     *
     * @type {string}
     * @memberof ProjectConfigurationLayer
     */
    searchField?: string;
    /**
     *
     * @type {string}
     * @memberof ProjectConfigurationLayer
     */
    sourceLayer?: string;
    /**
     *
     * @type {Array<LayerStyleSpecification>}
     * @memberof ProjectConfigurationLayer
     */
    style: Array<LayerStyleSpecification>;
    /**
     *
     * @type {string}
     * @memberof ProjectConfigurationLayer
     */
    styleName: string;
    /**
     *
     * @type {Array<string>}
     * @memberof ProjectConfigurationLayer
     */
    summaryFields: Array<string>;
    /**
     *
     * @type {string}
     * @memberof ProjectConfigurationLayer
     */
    table: string;
    /**
     *
     * @type {string}
     * @memberof ProjectConfigurationLayer
     */
    type: ProjectConfigurationLayerTypeEnum;
    /**
     *
     * @type {string}
     * @memberof ProjectConfigurationLayer
     */
    url: string;
    /**
     *
     * @type {number}
     * @memberof ProjectConfigurationLayer
     */
    zIndex: number;
}

/**
 * @export
 * @enum {string}
 */
export enum ProjectConfigurationLayerFormatEnum {
    GEOJSON = 'geojson',
    PBF = 'pbf',
}
/**
 * @export
 * @enum {string}
 */
export enum ProjectConfigurationLayerTypeEnum {
    AGGREGATE = 'AGGREGATE',
    BOX_PLAN_V2 = 'BOX_PLAN_V2',
    BOX_PLAN_V3 = 'BOX_PLAN_V3',
    DEFAULT = 'DEFAULT',
    INVISIBLE = 'INVISIBLE',
    MATERIALIZED_BOX_PLAN_V2 = 'MATERIALIZED_BOX_PLAN_V2',
    MATERIALIZED_BOX_PLAN_V3 = 'MATERIALIZED_BOX_PLAN_V3',
    VIRTUAL = 'VIRTUAL',
}

/**
 *
 * @export
 * @interface ProjectLayer
 */
export interface ProjectLayer {
    /**
     *
     * @type {Layer}
     * @memberof ProjectLayer
     */
    layer: Layer;
    /**
     *
     * @type {Project}
     * @memberof ProjectLayer
     */
    project: Project;
    /**
     *
     * @type {number}
     * @memberof ProjectLayer
     */
    zIndex: number;
}
/**
 *
 * @export
 * @interface ProjectParamsDto
 */
export interface ProjectParamsDto {
    /**
     *
     * @type {Array<number>}
     * @memberof ProjectParamsDto
     */
    bbox: Array<number>;
    /**
     *
     * @type {Array<LayerStyleSpecificationFilterInner>}
     * @memberof ProjectParamsDto
     */
    zoneIds?: Array<LayerStyleSpecificationFilterInner>;
}
/**
 *
 * @export
 * @interface RegisterDto
 */
export interface RegisterDto {
    /**
     *
     * @type {string}
     * @memberof RegisterDto
     */
    password: string;
    /**
     *
     * @type {string}
     * @memberof RegisterDto
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof RegisterDto
     */
    name: string;
}
/**
 *
 * @export
 * @interface ReverseBANDto
 */
export interface ReverseBANDto {
    /**
     *
     * @type {string}
     * @memberof ReverseBANDto
     */
    city: string;
    /**
     *
     * @type {string}
     * @memberof ReverseBANDto
     */
    citycode: string;
    /**
     *
     * @type {string}
     * @memberof ReverseBANDto
     */
    context: string;
    /**
     *
     * @type {number}
     * @memberof ReverseBANDto
     */
    distance: number;
    /**
     *
     * @type {string}
     * @memberof ReverseBANDto
     */
    housenumber: string;
    /**
     *
     * @type {string}
     * @memberof ReverseBANDto
     */
    id: string;
    /**
     *
     * @type {number}
     * @memberof ReverseBANDto
     */
    importance: number;
    /**
     *
     * @type {string}
     * @memberof ReverseBANDto
     */
    label: string;
    /**
     *
     * @type {string}
     * @memberof ReverseBANDto
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof ReverseBANDto
     */
    postcode: string;
    /**
     *
     * @type {number}
     * @memberof ReverseBANDto
     */
    score: number;
    /**
     *
     * @type {string}
     * @memberof ReverseBANDto
     */
    street: string;
    /**
     *
     * @type {string}
     * @memberof ReverseBANDto
     */
    type: string;
    /**
     *
     * @type {number}
     * @memberof ReverseBANDto
     */
    x: number;
    /**
     *
     * @type {number}
     * @memberof ReverseBANDto
     */
    y: number;
}
/**
 *
 * @export
 * @interface Role
 */
export interface Role {
    /**
     *
     * @type {number}
     * @memberof Role
     */
    id: number;
    /**
     *
     * @type {Date}
     * @memberof Role
     */
    createdAt: Date;
    /**
     *
     * @type {Array<Group>}
     * @memberof Role
     */
    groups: Array<Group>;
    /**
     *
     * @type {string}
     * @memberof Role
     */
    name: string;
    /**
     *
     * @type {Date}
     * @memberof Role
     */
    updatedAt: Date;
    /**
     *
     * @type {Array<User>}
     * @memberof Role
     */
    users: Array<User>;
}
/**
 *
 * @export
 * @interface SaveDebugDto
 */
export interface SaveDebugDto {
    /**
     *
     * @type {object}
     * @memberof SaveDebugDto
     */
    data: object;
    /**
     *
     * @type {string}
     * @memberof SaveDebugDto
     */
    store: string;
    /**
     *
     * @type {string}
     * @memberof SaveDebugDto
     */
    uuid: string;
}
/**
 *
 * @export
 * @interface SearchDumpDto
 */
export interface SearchDumpDto {
    /**
     *
     * @type {Array<number>}
     * @memberof SearchDumpDto
     */
    bbox: Array<number>;
    /**
     *
     * @type {Array<LayerStyleSpecificationFilterInner>}
     * @memberof SearchDumpDto
     */
    zoneIds?: Array<LayerStyleSpecificationFilterInner>;
}
/**
 *
 * @export
 * @interface SearchGeoJSON
 */
export interface SearchGeoJSON {
    /**
     *
     * @type {Array<SearchGeoJSONFeature>}
     * @memberof SearchGeoJSON
     */
    features: Array<SearchGeoJSONFeature>;
    /**
     *
     * @type {string}
     * @memberof SearchGeoJSON
     */
    layerAlias: string;
    /**
     *
     * @type {string}
     * @memberof SearchGeoJSON
     */
    layerFormat: SearchGeoJSONLayerFormatEnum;
    /**
     *
     * @type {number}
     * @memberof SearchGeoJSON
     */
    layerId: number;
    /**
     *
     * @type {string}
     * @memberof SearchGeoJSON
     */
    type: SearchGeoJSONTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum SearchGeoJSONLayerFormatEnum {
    GEOJSON = 'geojson',
    PBF = 'pbf',
}
/**
 * @export
 * @enum {string}
 */
export enum SearchGeoJSONTypeEnum {
    FEATURE_COLLECTION = 'FeatureCollection',
}

/**
 *
 * @export
 * @interface SearchGeoJSONFeature
 */
export interface SearchGeoJSONFeature {
    /**
     *
     * @type {SearchGeoJSONGeometry}
     * @memberof SearchGeoJSONFeature
     */
    geometry: SearchGeoJSONGeometry;
    /**
     *
     * @type {number}
     * @memberof SearchGeoJSONFeature
     */
    id: number;
    /**
     *
     * @type {object}
     * @memberof SearchGeoJSONFeature
     */
    properties?: object;
    /**
     *
     * @type {string}
     * @memberof SearchGeoJSONFeature
     */
    searchAttribute: string;
    /**
     *
     * @type {string}
     * @memberof SearchGeoJSONFeature
     */
    type: SearchGeoJSONFeatureTypeEnum;
    /**
     *
     * @type {number}
     * @memberof SearchGeoJSONFeature
     */
    uniqueLayerFeatureId: number;
}

/**
 * @export
 * @enum {string}
 */
export enum SearchGeoJSONFeatureTypeEnum {
    FEATURE = 'Feature',
    FEATURE_COLLECTION = 'FeatureCollection',
    GEOMETRY_COLLECTION = 'GeometryCollection',
    LINE_STRING = 'LineString',
    MULTI_LINE_STRING = 'MultiLineString',
    MULTI_POINT = 'MultiPoint',
    MULTI_POLYGON = 'MultiPolygon',
    POINT = 'Point',
    POLYGON = 'Polygon',
}

/**
 *
 * @export
 * @interface SearchGeoJSONGeometry
 */
export interface SearchGeoJSONGeometry {
    /**
     *
     * @type {Array<number>}
     * @memberof SearchGeoJSONGeometry
     */
    coordinates: Array<number>;
    /**
     *
     * @type {string}
     * @memberof SearchGeoJSONGeometry
     */
    type: SearchGeoJSONGeometryTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum SearchGeoJSONGeometryTypeEnum {
    GEOMETRY_COLLECTION = 'GeometryCollection',
    LINE_STRING = 'LineString',
    MULTI_LINE_STRING = 'MultiLineString',
    MULTI_POINT = 'MultiPoint',
    MULTI_POLYGON = 'MultiPolygon',
    POINT = 'Point',
    POLYGON = 'Polygon',
}

/**
 *
 * @export
 * @interface SelectionLayerConfiguration
 */
export interface SelectionLayerConfiguration {
    /**
     *
     * @type {string}
     * @memberof SelectionLayerConfiguration
     */
    keyColumnName: string;
    /**
     *
     * @type {string}
     * @memberof SelectionLayerConfiguration
     */
    url: string;
}
/**
 *
 * @export
 * @interface Style
 */
export interface Style {
    /**
     *
     * @type {Array<LayerStyleSpecification>}
     * @memberof Style
     */
    definition: Array<LayerStyleSpecification>;
    /**
     *
     * @type {number}
     * @memberof Style
     */
    id: number;
    /**
     *
     * @type {Date}
     * @memberof Style
     */
    createdAt: Date;
    /**
     *
     * @type {Array<Layer>}
     * @memberof Style
     */
    layers: Array<Layer>;
    /**
     *
     * @type {string}
     * @memberof Style
     */
    name: string;
    /**
     *
     * @type {Date}
     * @memberof Style
     */
    updatedAt: Date;
}
/**
 *
 * @export
 * @interface URLsImageResponseDto
 */
export interface URLsImageResponseDto {
    /**
     *
     * @type {string}
     * @memberof URLsImageResponseDto
     */
    full?: string;
    /**
     *
     * @type {string}
     * @memberof URLsImageResponseDto
     */
    metadata?: string;
    /**
     *
     * @type {string}
     * @memberof URLsImageResponseDto
     */
    small?: string;
}
/**
 *
 * @export
 * @interface UpdateDataBodyDto
 */
export interface UpdateDataBodyDto {
    /**
     *
     * @type {Array<object>}
     * @memberof UpdateDataBodyDto
     */
    data: Array<object>;
}
/**
 *
 * @export
 * @interface UpdateDomainDto
 */
export interface UpdateDomainDto {
    /**
     *
     * @type {string}
     * @memberof UpdateDomainDto
     */
    code?: string;
    /**
     *
     * @type {string}
     * @memberof UpdateDomainDto
     */
    name?: string;
}
/**
 *
 * @export
 * @interface UpdateGroupDto
 */
export interface UpdateGroupDto {
    /**
     *
     * @type {number}
     * @memberof UpdateGroupDto
     */
    domain?: number;
    /**
     *
     * @type {string}
     * @memberof UpdateGroupDto
     */
    name?: string;
    /**
     *
     * @type {number}
     * @memberof UpdateGroupDto
     */
    role?: number;
}
/**
 *
 * @export
 * @interface UpdateLayerDto
 */
export interface UpdateLayerDto {
    /**
     *
     * @type {string}
     * @memberof UpdateLayerDto
     */
    aggregateField?: string;
    /**
     *
     * @type {boolean}
     * @memberof UpdateLayerDto
     */
    ai?: boolean;
    /**
     *
     * @type {string}
     * @memberof UpdateLayerDto
     */
    alias?: string;
    /**
     *
     * @type {string}
     * @memberof UpdateLayerDto
     */
    category?: string;
    /**
     *
     * @type {string}
     * @memberof UpdateLayerDto
     */
    customAggregateOptions?: string;
    /**
     *
     * @type {string}
     * @memberof UpdateLayerDto
     */
    customSid?: string;
    /**
     *
     * @type {boolean}
     * @memberof UpdateLayerDto
     */
    enableCreation?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof UpdateLayerDto
     */
    enableEdition?: boolean;
    /**
     *
     * @type {string}
     * @memberof UpdateLayerDto
     */
    form?: string;
    /**
     *
     * @type {boolean}
     * @memberof UpdateLayerDto
     */
    hiddenByDefault?: boolean;
    /**
     *
     * @type {string}
     * @memberof UpdateLayerDto
     */
    imgTable?: string;
    /**
     *
     * @type {number}
     * @memberof UpdateLayerDto
     */
    maxZoom?: number;
    /**
     *
     * @type {number}
     * @memberof UpdateLayerDto
     */
    minZoom?: number;
    /**
     *
     * @type {string}
     * @memberof UpdateLayerDto
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof UpdateLayerDto
     */
    renderFormat?: UpdateLayerDtoRenderFormatEnum;
    /**
     *
     * @type {string}
     * @memberof UpdateLayerDto
     */
    schema?: string;
    /**
     *
     * @type {string}
     * @memberof UpdateLayerDto
     */
    table?: string;
    /**
     *
     * @type {string}
     * @memberof UpdateLayerDto
     */
    type?: UpdateLayerDtoTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum UpdateLayerDtoRenderFormatEnum {
    GEOJSON = 'geojson',
    PBF = 'pbf',
}
/**
 * @export
 * @enum {string}
 */
export enum UpdateLayerDtoTypeEnum {
    AGGREGATE = 'AGGREGATE',
    BOX_PLAN_V2 = 'BOX_PLAN_V2',
    BOX_PLAN_V3 = 'BOX_PLAN_V3',
    DEFAULT = 'DEFAULT',
    INVISIBLE = 'INVISIBLE',
    MATERIALIZED_BOX_PLAN_V2 = 'MATERIALIZED_BOX_PLAN_V2',
    MATERIALIZED_BOX_PLAN_V3 = 'MATERIALIZED_BOX_PLAN_V3',
    VIRTUAL = 'VIRTUAL',
}

/**
 *
 * @export
 * @interface UpdateRoleDto
 */
export interface UpdateRoleDto {
    /**
     *
     * @type {string}
     * @memberof UpdateRoleDto
     */
    name?: string;
}
/**
 *
 * @export
 * @interface UpdateStyleDto
 */
export interface UpdateStyleDto {
    /**
     *
     * @type {Array<LayerStyleSpecification>}
     * @memberof UpdateStyleDto
     */
    definition?: Array<LayerStyleSpecification>;
    /**
     *
     * @type {string}
     * @memberof UpdateStyleDto
     */
    name?: string;
}
/**
 *
 * @export
 * @interface UpdateUserDto
 */
export interface UpdateUserDto {
    /**
     *
     * @type {string}
     * @memberof UpdateUserDto
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof UpdateUserDto
     */
    name?: string;
}
/**
 *
 * @export
 * @interface UpdateZoneDto
 */
export interface UpdateZoneDto {
    /**
     *
     * @type {string}
     * @memberof UpdateZoneDto
     */
    geomColumn?: string;
    /**
     *
     * @type {string}
     * @memberof UpdateZoneDto
     */
    indexColumn?: string;
    /**
     *
     * @type {string}
     * @memberof UpdateZoneDto
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof UpdateZoneDto
     */
    nameColumn?: string;
    /**
     *
     * @type {Array<number>}
     * @memberof UpdateZoneDto
     */
    users?: Array<number>;
}
/**
 *
 * @export
 * @interface User
 */
export interface User {
    /**
     *
     * @type {string}
     * @memberof User
     */
    apiKey: string | null;
    /**
     *
     * @type {Date}
     * @memberof User
     */
    apiKeyExp: Date | null;
    /**
     *
     * @type {Date}
     * @memberof User
     */
    createdAt: Date;
    /**
     *
     * @type {Array<Domain>}
     * @memberof User
     */
    domains: Array<Domain>;
    /**
     *
     * @type {string}
     * @memberof User
     */
    email: string;
    /**
     *
     * @type {Array<Group>}
     * @memberof User
     */
    groups: Array<Group>;
    /**
     *
     * @type {number}
     * @memberof User
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof User
     */
    loginType: UserLoginTypeEnum;
    /**
     *
     * @type {Array<Metrics>}
     * @memberof User
     */
    metrics: Array<Metrics>;
    /**
     *
     * @type {string}
     * @memberof User
     */
    name: string;
    /**
     *
     * @type {Array<Project>}
     * @memberof User
     */
    projects: Array<Project>;
    /**
     *
     * @type {Date}
     * @memberof User
     */
    requestResetPasswordDate: Date | null;
    /**
     *
     * @type {Role}
     * @memberof User
     */
    role: Role;
    /**
     *
     * @type {Date}
     * @memberof User
     */
    updatedAt: Date;
    /**
     *
     * @type {Array<Zone>}
     * @memberof User
     */
    zones: Array<Zone>;
}

/**
 * @export
 * @enum {string}
 */
export enum UserLoginTypeEnum {
    BASIC = 'BASIC',
    LDAP = 'LDAP',
}

/**
 *
 * @export
 * @interface VersionResponseDto
 */
export interface VersionResponseDto {
    /**
     *
     * @type {string}
     * @memberof VersionResponseDto
     */
    version: string;
}
/**
 *
 * @export
 * @interface Zone
 */
export interface Zone {
    /**
     *
     * @type {Date}
     * @memberof Zone
     */
    createdAt: Date;
    /**
     *
     * @type {string}
     * @memberof Zone
     */
    geomColumn: string;
    /**
     *
     * @type {number}
     * @memberof Zone
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof Zone
     */
    indexColumn: string;
    /**
     *
     * @type {string}
     * @memberof Zone
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof Zone
     */
    nameColumn: string;
    /**
     *
     * @type {Layer}
     * @memberof Zone
     */
    restrictedLayer: Layer;
    /**
     *
     * @type {Date}
     * @memberof Zone
     */
    updatedAt: Date;
    /**
     *
     * @type {Array<User>}
     * @memberof Zone
     */
    users: Array<User>;
    /**
     *
     * @type {Layer}
     * @memberof Zone
     */
    zoneLayer: Layer;
}
/**
 *
 * @export
 * @interface ZoneGeoJSONPropertiesDto
 */
export interface ZoneGeoJSONPropertiesDto {
    /**
     *
     * @type {string}
     * @memberof ZoneGeoJSONPropertiesDto
     */
    name: string;
}
/**
 *
 * @export
 * @interface Zones
 */
export interface Zones {
    /**
     *
     * @type {Array<LayerStyleSpecificationFilterInner>}
     * @memberof Zones
     */
    ids: Array<LayerStyleSpecificationFilterInner>;
    /**
     *
     * @type {number}
     * @memberof Zones
     */
    projectId: number;
}

/**
 * AddressApi - axios parameter creator
 * @export
 */
export const AddressApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} lat
         * @param {string} lon
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerReverseBAN: async (
            lat: string,
            lon: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'lat' is not null or undefined
            assertParamExists('addressControllerReverseBAN', 'lat', lat);
            // verify required parameter 'lon' is not null or undefined
            assertParamExists('addressControllerReverseBAN', 'lon', lon);
            const localVarPath = `/api/v1/address/reverse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            if (lat !== undefined) {
                localVarQueryParameter['lat'] = lat;
            }

            if (lon !== undefined) {
                localVarQueryParameter['lon'] = lon;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * AddressApi - functional programming interface
 * @export
 */
export const AddressApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = AddressApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {string} lat
         * @param {string} lon
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressControllerReverseBAN(
            lat: string,
            lon: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeoJSONReverseBANDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addressControllerReverseBAN(lat, lon, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * AddressApi - factory interface
 * @export
 */
export const AddressApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AddressApiFp(configuration);
    return {
        /**
         *
         * @param {string} lat
         * @param {string} lon
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerReverseBAN(lat: string, lon: string, options?: any): AxiosPromise<GeoJSONReverseBANDto> {
            return localVarFp
                .addressControllerReverseBAN(lat, lon, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * AddressApi - interface
 * @export
 * @interface AddressApi
 */
export interface AddressApiInterface {
    /**
     *
     * @param {string} lat
     * @param {string} lon
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApiInterface
     */
    addressControllerReverseBAN(
        lat: string,
        lon: string,
        options?: AxiosRequestConfig,
    ): AxiosPromise<GeoJSONReverseBANDto>;
}

/**
 * Request parameters for addressControllerReverseBAN operation in AddressApi.
 * @export
 * @interface AddressApiAddressControllerReverseBANRequest
 */
export interface AddressApiAddressControllerReverseBANRequest {
    /**
     *
     * @type {string}
     * @memberof AddressApiAddressControllerReverseBAN
     */
    readonly lat: string;

    /**
     *
     * @type {string}
     * @memberof AddressApiAddressControllerReverseBAN
     */
    readonly lon: string;
}

/**
 * AddressApi - object-oriented interface
 * @export
 * @class AddressApi
 * @extends {BaseAPI}
 */
export class AddressApi extends BaseAPI implements AddressApiInterface {
    /**
     *
     * @param {AddressApiAddressControllerReverseBANRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    public addressControllerReverseBAN(
        requestParameters: AddressApiAddressControllerReverseBANRequest,
        options?: AxiosRequestConfig,
    ) {
        return AddressApiFp(this.configuration)
            .addressControllerReverseBAN(requestParameters.lat, requestParameters.lon, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * AiApi - axios parameter creator
 * @export
 */
export const AiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {ImageDuplicateDto} imageDuplicateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aiControllerFindImageDuplicate: async (
            imageDuplicateDto: ImageDuplicateDto,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'imageDuplicateDto' is not null or undefined
            assertParamExists('aiControllerFindImageDuplicate', 'imageDuplicateDto', imageDuplicateDto);
            const localVarPath = `/api/v1/ai/find-image-duplicate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                imageDuplicateDto,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {PredictImageDto} predictImageDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aiControllerPredictImage: async (
            predictImageDto: PredictImageDto,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'predictImageDto' is not null or undefined
            assertParamExists('aiControllerPredictImage', 'predictImageDto', predictImageDto);
            const localVarPath = `/api/v1/ai/predict-image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(predictImageDto, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * AiApi - functional programming interface
 * @export
 */
export const AiApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = AiApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {ImageDuplicateDto} imageDuplicateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aiControllerFindImageDuplicate(
            imageDuplicateDto: ImageDuplicateDto,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageDuplicateResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aiControllerFindImageDuplicate(
                imageDuplicateDto,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {PredictImageDto} predictImageDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aiControllerPredictImage(
            predictImageDto: PredictImageDto,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PredictImageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aiControllerPredictImage(
                predictImageDto,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * AiApi - factory interface
 * @export
 */
export const AiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AiApiFp(configuration);
    return {
        /**
         *
         * @param {ImageDuplicateDto} imageDuplicateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aiControllerFindImageDuplicate(
            imageDuplicateDto: ImageDuplicateDto,
            options?: any,
        ): AxiosPromise<ImageDuplicateResponseDto> {
            return localVarFp
                .aiControllerFindImageDuplicate(imageDuplicateDto, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PredictImageDto} predictImageDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aiControllerPredictImage(
            predictImageDto: PredictImageDto,
            options?: any,
        ): AxiosPromise<PredictImageResponseDto> {
            return localVarFp
                .aiControllerPredictImage(predictImageDto, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * AiApi - interface
 * @export
 * @interface AiApi
 */
export interface AiApiInterface {
    /**
     *
     * @param {ImageDuplicateDto} imageDuplicateDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiApiInterface
     */
    aiControllerFindImageDuplicate(
        imageDuplicateDto: ImageDuplicateDto,
        options?: AxiosRequestConfig,
    ): AxiosPromise<ImageDuplicateResponseDto>;

    /**
     *
     * @param {PredictImageDto} predictImageDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiApiInterface
     */
    aiControllerPredictImage(
        predictImageDto: PredictImageDto,
        options?: AxiosRequestConfig,
    ): AxiosPromise<PredictImageResponseDto>;
}

/**
 * Request parameters for aiControllerFindImageDuplicate operation in AiApi.
 * @export
 * @interface AiApiAiControllerFindImageDuplicateRequest
 */
export interface AiApiAiControllerFindImageDuplicateRequest {
    /**
     *
     * @type {ImageDuplicateDto}
     * @memberof AiApiAiControllerFindImageDuplicate
     */
    readonly imageDuplicateDto: ImageDuplicateDto;
}

/**
 * Request parameters for aiControllerPredictImage operation in AiApi.
 * @export
 * @interface AiApiAiControllerPredictImageRequest
 */
export interface AiApiAiControllerPredictImageRequest {
    /**
     *
     * @type {PredictImageDto}
     * @memberof AiApiAiControllerPredictImage
     */
    readonly predictImageDto: PredictImageDto;
}

/**
 * AiApi - object-oriented interface
 * @export
 * @class AiApi
 * @extends {BaseAPI}
 */
export class AiApi extends BaseAPI implements AiApiInterface {
    /**
     *
     * @param {AiApiAiControllerFindImageDuplicateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiApi
     */
    public aiControllerFindImageDuplicate(
        requestParameters: AiApiAiControllerFindImageDuplicateRequest,
        options?: AxiosRequestConfig,
    ) {
        return AiApiFp(this.configuration)
            .aiControllerFindImageDuplicate(requestParameters.imageDuplicateDto, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {AiApiAiControllerPredictImageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiApi
     */
    public aiControllerPredictImage(
        requestParameters: AiApiAiControllerPredictImageRequest,
        options?: AxiosRequestConfig,
    ) {
        return AiApiFp(this.configuration)
            .aiControllerPredictImage(requestParameters.predictImageDto, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerGenerateApiKey: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/auth/generate-api-key`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {LoginDto} loginDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogin: async (loginDto: LoginDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginDto' is not null or undefined
            assertParamExists('authControllerLogin', 'loginDto', loginDto);
            const localVarPath = `/api/v1/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(loginDto, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {LoginLdapDto} loginLdapDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLoginLDAP: async (
            loginLdapDto: LoginLdapDto,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'loginLdapDto' is not null or undefined
            assertParamExists('authControllerLoginLDAP', 'loginLdapDto', loginLdapDto);
            const localVarPath = `/api/v1/auth/login-ldap`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(loginLdapDto, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {RegisterDto} registerDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRegister: async (
            registerDto: RegisterDto,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'registerDto' is not null or undefined
            assertParamExists('authControllerRegister', 'registerDto', registerDto);
            const localVarPath = `/api/v1/auth/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(registerDto, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerGenerateApiKey(
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerGenerateApiKey(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {LoginDto} loginDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerLogin(
            loginDto: LoginDto,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerLogin(loginDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {LoginLdapDto} loginLdapDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerLoginLDAP(
            loginLdapDto: LoginLdapDto,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerLoginLDAP(loginLdapDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {RegisterDto} registerDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerRegister(
            registerDto: RegisterDto,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerRegister(registerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerGenerateApiKey(options?: any): AxiosPromise<User> {
            return localVarFp.authControllerGenerateApiKey(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {LoginDto} loginDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogin(loginDto: LoginDto, options?: any): AxiosPromise<LoginResponseDto> {
            return localVarFp.authControllerLogin(loginDto, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {LoginLdapDto} loginLdapDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLoginLDAP(loginLdapDto: LoginLdapDto, options?: any): AxiosPromise<LoginResponseDto> {
            return localVarFp
                .authControllerLoginLDAP(loginLdapDto, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {RegisterDto} registerDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRegister(registerDto: RegisterDto, options?: any): AxiosPromise<User> {
            return localVarFp.authControllerRegister(registerDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - interface
 * @export
 * @interface AuthApi
 */
export interface AuthApiInterface {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    authControllerGenerateApiKey(options?: AxiosRequestConfig): AxiosPromise<User>;

    /**
     *
     * @param {LoginDto} loginDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    authControllerLogin(loginDto: LoginDto, options?: AxiosRequestConfig): AxiosPromise<LoginResponseDto>;

    /**
     *
     * @param {LoginLdapDto} loginLdapDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    authControllerLoginLDAP(loginLdapDto: LoginLdapDto, options?: AxiosRequestConfig): AxiosPromise<LoginResponseDto>;

    /**
     *
     * @param {RegisterDto} registerDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    authControllerRegister(registerDto: RegisterDto, options?: AxiosRequestConfig): AxiosPromise<User>;
}

/**
 * Request parameters for authControllerLogin operation in AuthApi.
 * @export
 * @interface AuthApiAuthControllerLoginRequest
 */
export interface AuthApiAuthControllerLoginRequest {
    /**
     *
     * @type {LoginDto}
     * @memberof AuthApiAuthControllerLogin
     */
    readonly loginDto: LoginDto;
}

/**
 * Request parameters for authControllerLoginLDAP operation in AuthApi.
 * @export
 * @interface AuthApiAuthControllerLoginLDAPRequest
 */
export interface AuthApiAuthControllerLoginLDAPRequest {
    /**
     *
     * @type {LoginLdapDto}
     * @memberof AuthApiAuthControllerLoginLDAP
     */
    readonly loginLdapDto: LoginLdapDto;
}

/**
 * Request parameters for authControllerRegister operation in AuthApi.
 * @export
 * @interface AuthApiAuthControllerRegisterRequest
 */
export interface AuthApiAuthControllerRegisterRequest {
    /**
     *
     * @type {RegisterDto}
     * @memberof AuthApiAuthControllerRegister
     */
    readonly registerDto: RegisterDto;
}

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI implements AuthApiInterface {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerGenerateApiKey(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration)
            .authControllerGenerateApiKey(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {AuthApiAuthControllerLoginRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerLogin(requestParameters: AuthApiAuthControllerLoginRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration)
            .authControllerLogin(requestParameters.loginDto, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {AuthApiAuthControllerLoginLDAPRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerLoginLDAP(
        requestParameters: AuthApiAuthControllerLoginLDAPRequest,
        options?: AxiosRequestConfig,
    ) {
        return AuthApiFp(this.configuration)
            .authControllerLoginLDAP(requestParameters.loginLdapDto, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {AuthApiAuthControllerRegisterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerRegister(
        requestParameters: AuthApiAuthControllerRegisterRequest,
        options?: AxiosRequestConfig,
    ) {
        return AuthApiFp(this.configuration)
            .authControllerRegister(requestParameters.registerDto, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * ClientSettingsApi - axios parameter creator
 * @export
 */
export const ClientSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientSettingsControllerGetDownTime: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/client-settings/downtime`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientSettingsControllerGetVersion: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/client-settings/version`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * ClientSettingsApi - functional programming interface
 * @export
 */
export const ClientSettingsApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = ClientSettingsApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientSettingsControllerGetDownTime(
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DowntimeResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientSettingsControllerGetDownTime(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientSettingsControllerGetVersion(
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VersionResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientSettingsControllerGetVersion(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * ClientSettingsApi - factory interface
 * @export
 */
export const ClientSettingsApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance,
) {
    const localVarFp = ClientSettingsApiFp(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientSettingsControllerGetDownTime(options?: any): AxiosPromise<DowntimeResponseDto> {
            return localVarFp.clientSettingsControllerGetDownTime(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientSettingsControllerGetVersion(options?: any): AxiosPromise<VersionResponseDto> {
            return localVarFp.clientSettingsControllerGetVersion(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClientSettingsApi - interface
 * @export
 * @interface ClientSettingsApi
 */
export interface ClientSettingsApiInterface {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientSettingsApiInterface
     */
    clientSettingsControllerGetDownTime(options?: AxiosRequestConfig): AxiosPromise<DowntimeResponseDto>;

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientSettingsApiInterface
     */
    clientSettingsControllerGetVersion(options?: AxiosRequestConfig): AxiosPromise<VersionResponseDto>;
}

/**
 * ClientSettingsApi - object-oriented interface
 * @export
 * @class ClientSettingsApi
 * @extends {BaseAPI}
 */
export class ClientSettingsApi extends BaseAPI implements ClientSettingsApiInterface {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientSettingsApi
     */
    public clientSettingsControllerGetDownTime(options?: AxiosRequestConfig) {
        return ClientSettingsApiFp(this.configuration)
            .clientSettingsControllerGetDownTime(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientSettingsApi
     */
    public clientSettingsControllerGetVersion(options?: AxiosRequestConfig) {
        return ClientSettingsApiFp(this.configuration)
            .clientSettingsControllerGetVersion(options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * DebugApi - axios parameter creator
 * @export
 */
export const DebugApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {SaveDebugDto} saveDebugDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        debugControllerSave: async (
            saveDebugDto: SaveDebugDto,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'saveDebugDto' is not null or undefined
            assertParamExists('debugControllerSave', 'saveDebugDto', saveDebugDto);
            const localVarPath = `/api/v1/debug`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(saveDebugDto, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * DebugApi - functional programming interface
 * @export
 */
export const DebugApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = DebugApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {SaveDebugDto} saveDebugDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async debugControllerSave(
            saveDebugDto: SaveDebugDto,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.debugControllerSave(saveDebugDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * DebugApi - factory interface
 * @export
 */
export const DebugApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DebugApiFp(configuration);
    return {
        /**
         *
         * @param {SaveDebugDto} saveDebugDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        debugControllerSave(saveDebugDto: SaveDebugDto, options?: any): AxiosPromise<void> {
            return localVarFp.debugControllerSave(saveDebugDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DebugApi - interface
 * @export
 * @interface DebugApi
 */
export interface DebugApiInterface {
    /**
     *
     * @param {SaveDebugDto} saveDebugDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DebugApiInterface
     */
    debugControllerSave(saveDebugDto: SaveDebugDto, options?: AxiosRequestConfig): AxiosPromise<void>;
}

/**
 * Request parameters for debugControllerSave operation in DebugApi.
 * @export
 * @interface DebugApiDebugControllerSaveRequest
 */
export interface DebugApiDebugControllerSaveRequest {
    /**
     *
     * @type {SaveDebugDto}
     * @memberof DebugApiDebugControllerSave
     */
    readonly saveDebugDto: SaveDebugDto;
}

/**
 * DebugApi - object-oriented interface
 * @export
 * @class DebugApi
 * @extends {BaseAPI}
 */
export class DebugApi extends BaseAPI implements DebugApiInterface {
    /**
     *
     * @param {DebugApiDebugControllerSaveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DebugApi
     */
    public debugControllerSave(requestParameters: DebugApiDebugControllerSaveRequest, options?: AxiosRequestConfig) {
        return DebugApiFp(this.configuration)
            .debugControllerSave(requestParameters.saveDebugDto, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * DomainApi - axios parameter creator
 * @export
 */
export const DomainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {CreateDomainDto} createDomainDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domainControllerCreate: async (
            createDomainDto: CreateDomainDto,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'createDomainDto' is not null or undefined
            assertParamExists('domainControllerCreate', 'createDomainDto', createDomainDto);
            const localVarPath = `/api/v1/domain`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(createDomainDto, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} domainId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domainControllerDelete: async (domainId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'domainId' is not null or undefined
            assertParamExists('domainControllerDelete', 'domainId', domainId);
            const localVarPath = `/api/v1/domain/{domainId}`.replace(
                `{${'domainId'}}`,
                encodeURIComponent(String(domainId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} domainId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domainControllerGetById: async (domainId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'domainId' is not null or undefined
            assertParamExists('domainControllerGetById', 'domainId', domainId);
            const localVarPath = `/api/v1/domain/{domainId}`.replace(
                `{${'domainId'}}`,
                encodeURIComponent(String(domainId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domainControllerGetDomains: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/domain`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} domainId
         * @param {UpdateDomainDto} updateDomainDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domainControllerUpdate: async (
            domainId: number,
            updateDomainDto: UpdateDomainDto,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'domainId' is not null or undefined
            assertParamExists('domainControllerUpdate', 'domainId', domainId);
            // verify required parameter 'updateDomainDto' is not null or undefined
            assertParamExists('domainControllerUpdate', 'updateDomainDto', updateDomainDto);
            const localVarPath = `/api/v1/domain/{domainId}`.replace(
                `{${'domainId'}}`,
                encodeURIComponent(String(domainId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(updateDomainDto, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * DomainApi - functional programming interface
 * @export
 */
export const DomainApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = DomainApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {CreateDomainDto} createDomainDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domainControllerCreate(
            createDomainDto: CreateDomainDto,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Domain>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domainControllerCreate(createDomainDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} domainId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domainControllerDelete(
            domainId: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domainControllerDelete(domainId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} domainId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domainControllerGetById(
            domainId: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Domain>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domainControllerGetById(domainId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domainControllerGetDomains(
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Domain>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domainControllerGetDomains(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} domainId
         * @param {UpdateDomainDto} updateDomainDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domainControllerUpdate(
            domainId: number,
            updateDomainDto: UpdateDomainDto,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Domain>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domainControllerUpdate(
                domainId,
                updateDomainDto,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * DomainApi - factory interface
 * @export
 */
export const DomainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DomainApiFp(configuration);
    return {
        /**
         *
         * @param {CreateDomainDto} createDomainDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domainControllerCreate(createDomainDto: CreateDomainDto, options?: any): AxiosPromise<Domain> {
            return localVarFp
                .domainControllerCreate(createDomainDto, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} domainId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domainControllerDelete(domainId: number, options?: any): AxiosPromise<void> {
            return localVarFp.domainControllerDelete(domainId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} domainId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domainControllerGetById(domainId: number, options?: any): AxiosPromise<Domain> {
            return localVarFp.domainControllerGetById(domainId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domainControllerGetDomains(options?: any): AxiosPromise<Array<Domain>> {
            return localVarFp.domainControllerGetDomains(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} domainId
         * @param {UpdateDomainDto} updateDomainDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domainControllerUpdate(
            domainId: number,
            updateDomainDto: UpdateDomainDto,
            options?: any,
        ): AxiosPromise<Domain> {
            return localVarFp
                .domainControllerUpdate(domainId, updateDomainDto, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * DomainApi - interface
 * @export
 * @interface DomainApi
 */
export interface DomainApiInterface {
    /**
     *
     * @param {CreateDomainDto} createDomainDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DomainApiInterface
     */
    domainControllerCreate(createDomainDto: CreateDomainDto, options?: AxiosRequestConfig): AxiosPromise<Domain>;

    /**
     *
     * @param {number} domainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DomainApiInterface
     */
    domainControllerDelete(domainId: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     *
     * @param {number} domainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DomainApiInterface
     */
    domainControllerGetById(domainId: number, options?: AxiosRequestConfig): AxiosPromise<Domain>;

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DomainApiInterface
     */
    domainControllerGetDomains(options?: AxiosRequestConfig): AxiosPromise<Array<Domain>>;

    /**
     *
     * @param {number} domainId
     * @param {UpdateDomainDto} updateDomainDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DomainApiInterface
     */
    domainControllerUpdate(
        domainId: number,
        updateDomainDto: UpdateDomainDto,
        options?: AxiosRequestConfig,
    ): AxiosPromise<Domain>;
}

/**
 * Request parameters for domainControllerCreate operation in DomainApi.
 * @export
 * @interface DomainApiDomainControllerCreateRequest
 */
export interface DomainApiDomainControllerCreateRequest {
    /**
     *
     * @type {CreateDomainDto}
     * @memberof DomainApiDomainControllerCreate
     */
    readonly createDomainDto: CreateDomainDto;
}

/**
 * Request parameters for domainControllerDelete operation in DomainApi.
 * @export
 * @interface DomainApiDomainControllerDeleteRequest
 */
export interface DomainApiDomainControllerDeleteRequest {
    /**
     *
     * @type {number}
     * @memberof DomainApiDomainControllerDelete
     */
    readonly domainId: number;
}

/**
 * Request parameters for domainControllerGetById operation in DomainApi.
 * @export
 * @interface DomainApiDomainControllerGetByIdRequest
 */
export interface DomainApiDomainControllerGetByIdRequest {
    /**
     *
     * @type {number}
     * @memberof DomainApiDomainControllerGetById
     */
    readonly domainId: number;
}

/**
 * Request parameters for domainControllerUpdate operation in DomainApi.
 * @export
 * @interface DomainApiDomainControllerUpdateRequest
 */
export interface DomainApiDomainControllerUpdateRequest {
    /**
     *
     * @type {number}
     * @memberof DomainApiDomainControllerUpdate
     */
    readonly domainId: number;

    /**
     *
     * @type {UpdateDomainDto}
     * @memberof DomainApiDomainControllerUpdate
     */
    readonly updateDomainDto: UpdateDomainDto;
}

/**
 * DomainApi - object-oriented interface
 * @export
 * @class DomainApi
 * @extends {BaseAPI}
 */
export class DomainApi extends BaseAPI implements DomainApiInterface {
    /**
     *
     * @param {DomainApiDomainControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DomainApi
     */
    public domainControllerCreate(
        requestParameters: DomainApiDomainControllerCreateRequest,
        options?: AxiosRequestConfig,
    ) {
        return DomainApiFp(this.configuration)
            .domainControllerCreate(requestParameters.createDomainDto, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {DomainApiDomainControllerDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DomainApi
     */
    public domainControllerDelete(
        requestParameters: DomainApiDomainControllerDeleteRequest,
        options?: AxiosRequestConfig,
    ) {
        return DomainApiFp(this.configuration)
            .domainControllerDelete(requestParameters.domainId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {DomainApiDomainControllerGetByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DomainApi
     */
    public domainControllerGetById(
        requestParameters: DomainApiDomainControllerGetByIdRequest,
        options?: AxiosRequestConfig,
    ) {
        return DomainApiFp(this.configuration)
            .domainControllerGetById(requestParameters.domainId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DomainApi
     */
    public domainControllerGetDomains(options?: AxiosRequestConfig) {
        return DomainApiFp(this.configuration)
            .domainControllerGetDomains(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {DomainApiDomainControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DomainApi
     */
    public domainControllerUpdate(
        requestParameters: DomainApiDomainControllerUpdateRequest,
        options?: AxiosRequestConfig,
    ) {
        return DomainApiFp(this.configuration)
            .domainControllerUpdate(requestParameters.domainId, requestParameters.updateDomainDto, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * GroupApi - axios parameter creator
 * @export
 */
export const GroupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {CreateGroupDto} createGroupDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupControllerCreate: async (
            createGroupDto: CreateGroupDto,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'createGroupDto' is not null or undefined
            assertParamExists('groupControllerCreate', 'createGroupDto', createGroupDto);
            const localVarPath = `/api/v1/group`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(createGroupDto, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} groupId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupControllerDelete: async (groupId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('groupControllerDelete', 'groupId', groupId);
            const localVarPath = `/api/v1/group/{groupId}`.replace(
                `{${'groupId'}}`,
                encodeURIComponent(String(groupId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupControllerGetAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/group`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} groupId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupControllerGetById: async (groupId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('groupControllerGetById', 'groupId', groupId);
            const localVarPath = `/api/v1/group/{groupId}`.replace(
                `{${'groupId'}}`,
                encodeURIComponent(String(groupId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} groupId
         * @param {UpdateGroupDto} updateGroupDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupControllerUpdate: async (
            groupId: number,
            updateGroupDto: UpdateGroupDto,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('groupControllerUpdate', 'groupId', groupId);
            // verify required parameter 'updateGroupDto' is not null or undefined
            assertParamExists('groupControllerUpdate', 'updateGroupDto', updateGroupDto);
            const localVarPath = `/api/v1/group/{groupId}`.replace(
                `{${'groupId'}}`,
                encodeURIComponent(String(groupId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(updateGroupDto, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * GroupApi - functional programming interface
 * @export
 */
export const GroupApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = GroupApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {CreateGroupDto} createGroupDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async groupControllerCreate(
            createGroupDto: CreateGroupDto,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Group>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.groupControllerCreate(createGroupDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} groupId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async groupControllerDelete(
            groupId: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.groupControllerDelete(groupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async groupControllerGetAll(
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Group>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.groupControllerGetAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} groupId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async groupControllerGetById(
            groupId: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Group>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.groupControllerGetById(groupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} groupId
         * @param {UpdateGroupDto} updateGroupDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async groupControllerUpdate(
            groupId: number,
            updateGroupDto: UpdateGroupDto,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Group>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.groupControllerUpdate(
                groupId,
                updateGroupDto,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * GroupApi - factory interface
 * @export
 */
export const GroupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GroupApiFp(configuration);
    return {
        /**
         *
         * @param {CreateGroupDto} createGroupDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupControllerCreate(createGroupDto: CreateGroupDto, options?: any): AxiosPromise<Group> {
            return localVarFp
                .groupControllerCreate(createGroupDto, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} groupId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupControllerDelete(groupId: number, options?: any): AxiosPromise<void> {
            return localVarFp.groupControllerDelete(groupId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupControllerGetAll(options?: any): AxiosPromise<Array<Group>> {
            return localVarFp.groupControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} groupId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupControllerGetById(groupId: number, options?: any): AxiosPromise<Group> {
            return localVarFp.groupControllerGetById(groupId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} groupId
         * @param {UpdateGroupDto} updateGroupDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupControllerUpdate(groupId: number, updateGroupDto: UpdateGroupDto, options?: any): AxiosPromise<Group> {
            return localVarFp
                .groupControllerUpdate(groupId, updateGroupDto, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * GroupApi - interface
 * @export
 * @interface GroupApi
 */
export interface GroupApiInterface {
    /**
     *
     * @param {CreateGroupDto} createGroupDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApiInterface
     */
    groupControllerCreate(createGroupDto: CreateGroupDto, options?: AxiosRequestConfig): AxiosPromise<Group>;

    /**
     *
     * @param {number} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApiInterface
     */
    groupControllerDelete(groupId: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApiInterface
     */
    groupControllerGetAll(options?: AxiosRequestConfig): AxiosPromise<Array<Group>>;

    /**
     *
     * @param {number} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApiInterface
     */
    groupControllerGetById(groupId: number, options?: AxiosRequestConfig): AxiosPromise<Group>;

    /**
     *
     * @param {number} groupId
     * @param {UpdateGroupDto} updateGroupDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApiInterface
     */
    groupControllerUpdate(
        groupId: number,
        updateGroupDto: UpdateGroupDto,
        options?: AxiosRequestConfig,
    ): AxiosPromise<Group>;
}

/**
 * Request parameters for groupControllerCreate operation in GroupApi.
 * @export
 * @interface GroupApiGroupControllerCreateRequest
 */
export interface GroupApiGroupControllerCreateRequest {
    /**
     *
     * @type {CreateGroupDto}
     * @memberof GroupApiGroupControllerCreate
     */
    readonly createGroupDto: CreateGroupDto;
}

/**
 * Request parameters for groupControllerDelete operation in GroupApi.
 * @export
 * @interface GroupApiGroupControllerDeleteRequest
 */
export interface GroupApiGroupControllerDeleteRequest {
    /**
     *
     * @type {number}
     * @memberof GroupApiGroupControllerDelete
     */
    readonly groupId: number;
}

/**
 * Request parameters for groupControllerGetById operation in GroupApi.
 * @export
 * @interface GroupApiGroupControllerGetByIdRequest
 */
export interface GroupApiGroupControllerGetByIdRequest {
    /**
     *
     * @type {number}
     * @memberof GroupApiGroupControllerGetById
     */
    readonly groupId: number;
}

/**
 * Request parameters for groupControllerUpdate operation in GroupApi.
 * @export
 * @interface GroupApiGroupControllerUpdateRequest
 */
export interface GroupApiGroupControllerUpdateRequest {
    /**
     *
     * @type {number}
     * @memberof GroupApiGroupControllerUpdate
     */
    readonly groupId: number;

    /**
     *
     * @type {UpdateGroupDto}
     * @memberof GroupApiGroupControllerUpdate
     */
    readonly updateGroupDto: UpdateGroupDto;
}

/**
 * GroupApi - object-oriented interface
 * @export
 * @class GroupApi
 * @extends {BaseAPI}
 */
export class GroupApi extends BaseAPI implements GroupApiInterface {
    /**
     *
     * @param {GroupApiGroupControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public groupControllerCreate(
        requestParameters: GroupApiGroupControllerCreateRequest,
        options?: AxiosRequestConfig,
    ) {
        return GroupApiFp(this.configuration)
            .groupControllerCreate(requestParameters.createGroupDto, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {GroupApiGroupControllerDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public groupControllerDelete(
        requestParameters: GroupApiGroupControllerDeleteRequest,
        options?: AxiosRequestConfig,
    ) {
        return GroupApiFp(this.configuration)
            .groupControllerDelete(requestParameters.groupId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public groupControllerGetAll(options?: AxiosRequestConfig) {
        return GroupApiFp(this.configuration)
            .groupControllerGetAll(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {GroupApiGroupControllerGetByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public groupControllerGetById(
        requestParameters: GroupApiGroupControllerGetByIdRequest,
        options?: AxiosRequestConfig,
    ) {
        return GroupApiFp(this.configuration)
            .groupControllerGetById(requestParameters.groupId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {GroupApiGroupControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public groupControllerUpdate(
        requestParameters: GroupApiGroupControllerUpdateRequest,
        options?: AxiosRequestConfig,
    ) {
        return GroupApiFp(this.configuration)
            .groupControllerUpdate(requestParameters.groupId, requestParameters.updateGroupDto, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * HealthzApi - axios parameter creator
 * @export
 */
export const HealthzApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerCheckApi: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/healthz/api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerCheckApiDb: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/healthz/api-db`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerCheckApiMetier: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/healthz/api-metier`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerCheckMartin: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/healthz/martin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerCheckTippecanoe: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/healthz/tippecanoe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerGetHealth: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/healthz`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * HealthzApi - functional programming interface
 * @export
 */
export const HealthzApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthzApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthControllerCheckApi(
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HealthResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthControllerCheckApi(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthControllerCheckApiDb(
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HealthResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthControllerCheckApiDb(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthControllerCheckApiMetier(
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HealthResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthControllerCheckApiMetier(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthControllerCheckMartin(
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HealthResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthControllerCheckMartin(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthControllerCheckTippecanoe(
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HealthResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthControllerCheckTippecanoe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthControllerGetHealth(
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllHealthResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthControllerGetHealth(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * HealthzApi - factory interface
 * @export
 */
export const HealthzApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthzApiFp(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerCheckApi(options?: any): AxiosPromise<HealthResponse> {
            return localVarFp.healthControllerCheckApi(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerCheckApiDb(options?: any): AxiosPromise<HealthResponse> {
            return localVarFp.healthControllerCheckApiDb(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerCheckApiMetier(options?: any): AxiosPromise<HealthResponse> {
            return localVarFp.healthControllerCheckApiMetier(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerCheckMartin(options?: any): AxiosPromise<HealthResponse> {
            return localVarFp.healthControllerCheckMartin(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerCheckTippecanoe(options?: any): AxiosPromise<HealthResponse> {
            return localVarFp.healthControllerCheckTippecanoe(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerGetHealth(options?: any): AxiosPromise<AllHealthResponse> {
            return localVarFp.healthControllerGetHealth(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthzApi - interface
 * @export
 * @interface HealthzApi
 */
export interface HealthzApiInterface {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthzApiInterface
     */
    healthControllerCheckApi(options?: AxiosRequestConfig): AxiosPromise<HealthResponse>;

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthzApiInterface
     */
    healthControllerCheckApiDb(options?: AxiosRequestConfig): AxiosPromise<HealthResponse>;

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthzApiInterface
     */
    healthControllerCheckApiMetier(options?: AxiosRequestConfig): AxiosPromise<HealthResponse>;

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthzApiInterface
     */
    healthControllerCheckMartin(options?: AxiosRequestConfig): AxiosPromise<HealthResponse>;

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthzApiInterface
     */
    healthControllerCheckTippecanoe(options?: AxiosRequestConfig): AxiosPromise<HealthResponse>;

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthzApiInterface
     */
    healthControllerGetHealth(options?: AxiosRequestConfig): AxiosPromise<AllHealthResponse>;
}

/**
 * HealthzApi - object-oriented interface
 * @export
 * @class HealthzApi
 * @extends {BaseAPI}
 */
export class HealthzApi extends BaseAPI implements HealthzApiInterface {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthzApi
     */
    public healthControllerCheckApi(options?: AxiosRequestConfig) {
        return HealthzApiFp(this.configuration)
            .healthControllerCheckApi(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthzApi
     */
    public healthControllerCheckApiDb(options?: AxiosRequestConfig) {
        return HealthzApiFp(this.configuration)
            .healthControllerCheckApiDb(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthzApi
     */
    public healthControllerCheckApiMetier(options?: AxiosRequestConfig) {
        return HealthzApiFp(this.configuration)
            .healthControllerCheckApiMetier(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthzApi
     */
    public healthControllerCheckMartin(options?: AxiosRequestConfig) {
        return HealthzApiFp(this.configuration)
            .healthControllerCheckMartin(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthzApi
     */
    public healthControllerCheckTippecanoe(options?: AxiosRequestConfig) {
        return HealthzApiFp(this.configuration)
            .healthControllerCheckTippecanoe(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthzApi
     */
    public healthControllerGetHealth(options?: AxiosRequestConfig) {
        return HealthzApiFp(this.configuration)
            .healthControllerGetHealth(options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * LayerApi - axios parameter creator
 * @export
 */
export const LayerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {number} zoneLayerId
         * @param {number} objectLayerId
         * @param {string} objectCountColumn
         * @param {string} objectCountValue1
         * @param {string} objectCountValue2
         * @param {string} objectJoinColumn
         * @param {string} objectNullColumn
         * @param {string} objectWhereColumn
         * @param {string} objectWhereValue
         * @param {string} zoneGeomColumn
         * @param {string} zoneIdColumn
         * @param {string} zoneJoinColumn
         * @param {string} zoneNameColumn
         * @param {string} zoneWhereColumn
         * @param {string} zoneWhereValue
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layerControllerCountObjectInZoneGeoJSON: async (
            zoneLayerId: number,
            objectLayerId: number,
            objectCountColumn: string,
            objectCountValue1: string,
            objectCountValue2: string,
            objectJoinColumn: string,
            objectNullColumn: string,
            objectWhereColumn: string,
            objectWhereValue: string,
            zoneGeomColumn: string,
            zoneIdColumn: string,
            zoneJoinColumn: string,
            zoneNameColumn: string,
            zoneWhereColumn: string,
            zoneWhereValue: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'zoneLayerId' is not null or undefined
            assertParamExists('layerControllerCountObjectInZoneGeoJSON', 'zoneLayerId', zoneLayerId);
            // verify required parameter 'objectLayerId' is not null or undefined
            assertParamExists('layerControllerCountObjectInZoneGeoJSON', 'objectLayerId', objectLayerId);
            // verify required parameter 'objectCountColumn' is not null or undefined
            assertParamExists('layerControllerCountObjectInZoneGeoJSON', 'objectCountColumn', objectCountColumn);
            // verify required parameter 'objectCountValue1' is not null or undefined
            assertParamExists('layerControllerCountObjectInZoneGeoJSON', 'objectCountValue1', objectCountValue1);
            // verify required parameter 'objectCountValue2' is not null or undefined
            assertParamExists('layerControllerCountObjectInZoneGeoJSON', 'objectCountValue2', objectCountValue2);
            // verify required parameter 'objectJoinColumn' is not null or undefined
            assertParamExists('layerControllerCountObjectInZoneGeoJSON', 'objectJoinColumn', objectJoinColumn);
            // verify required parameter 'objectNullColumn' is not null or undefined
            assertParamExists('layerControllerCountObjectInZoneGeoJSON', 'objectNullColumn', objectNullColumn);
            // verify required parameter 'objectWhereColumn' is not null or undefined
            assertParamExists('layerControllerCountObjectInZoneGeoJSON', 'objectWhereColumn', objectWhereColumn);
            // verify required parameter 'objectWhereValue' is not null or undefined
            assertParamExists('layerControllerCountObjectInZoneGeoJSON', 'objectWhereValue', objectWhereValue);
            // verify required parameter 'zoneGeomColumn' is not null or undefined
            assertParamExists('layerControllerCountObjectInZoneGeoJSON', 'zoneGeomColumn', zoneGeomColumn);
            // verify required parameter 'zoneIdColumn' is not null or undefined
            assertParamExists('layerControllerCountObjectInZoneGeoJSON', 'zoneIdColumn', zoneIdColumn);
            // verify required parameter 'zoneJoinColumn' is not null or undefined
            assertParamExists('layerControllerCountObjectInZoneGeoJSON', 'zoneJoinColumn', zoneJoinColumn);
            // verify required parameter 'zoneNameColumn' is not null or undefined
            assertParamExists('layerControllerCountObjectInZoneGeoJSON', 'zoneNameColumn', zoneNameColumn);
            // verify required parameter 'zoneWhereColumn' is not null or undefined
            assertParamExists('layerControllerCountObjectInZoneGeoJSON', 'zoneWhereColumn', zoneWhereColumn);
            // verify required parameter 'zoneWhereValue' is not null or undefined
            assertParamExists('layerControllerCountObjectInZoneGeoJSON', 'zoneWhereValue', zoneWhereValue);
            const localVarPath = `/api/v1/layer/{zoneLayerId}/count/{objectLayerId}/geojson`
                .replace(`{${'zoneLayerId'}}`, encodeURIComponent(String(zoneLayerId)))
                .replace(`{${'objectLayerId'}}`, encodeURIComponent(String(objectLayerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            if (objectCountColumn !== undefined) {
                localVarQueryParameter['objectCountColumn'] = objectCountColumn;
            }

            if (objectCountValue1 !== undefined) {
                localVarQueryParameter['objectCountValue1'] = objectCountValue1;
            }

            if (objectCountValue2 !== undefined) {
                localVarQueryParameter['objectCountValue2'] = objectCountValue2;
            }

            if (objectJoinColumn !== undefined) {
                localVarQueryParameter['objectJoinColumn'] = objectJoinColumn;
            }

            if (objectNullColumn !== undefined) {
                localVarQueryParameter['objectNullColumn'] = objectNullColumn;
            }

            if (objectWhereColumn !== undefined) {
                localVarQueryParameter['objectWhereColumn'] = objectWhereColumn;
            }

            if (objectWhereValue !== undefined) {
                localVarQueryParameter['objectWhereValue'] = objectWhereValue;
            }

            if (zoneGeomColumn !== undefined) {
                localVarQueryParameter['zoneGeomColumn'] = zoneGeomColumn;
            }

            if (zoneIdColumn !== undefined) {
                localVarQueryParameter['zoneIdColumn'] = zoneIdColumn;
            }

            if (zoneJoinColumn !== undefined) {
                localVarQueryParameter['zoneJoinColumn'] = zoneJoinColumn;
            }

            if (zoneNameColumn !== undefined) {
                localVarQueryParameter['zoneNameColumn'] = zoneNameColumn;
            }

            if (zoneWhereColumn !== undefined) {
                localVarQueryParameter['zoneWhereColumn'] = zoneWhereColumn;
            }

            if (zoneWhereValue !== undefined) {
                localVarQueryParameter['zoneWhereValue'] = zoneWhereValue;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {CreateLayerDto} createLayerDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layerControllerCreate: async (
            createLayerDto: CreateLayerDto,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'createLayerDto' is not null or undefined
            assertParamExists('layerControllerCreate', 'createLayerDto', createLayerDto);
            const localVarPath = `/api/v1/layer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(createLayerDto, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} layerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layerControllerDelete: async (layerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'layerId' is not null or undefined
            assertParamExists('layerControllerDelete', 'layerId', layerId);
            const localVarPath = `/api/v1/layer/{layerId}`.replace(
                `{${'layerId'}}`,
                encodeURIComponent(String(layerId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} layerId
         * @param {ExtractGeoJSONBodyDto} extractGeoJSONBodyDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layerControllerExtractGeoJSON: async (
            layerId: number,
            extractGeoJSONBodyDto: ExtractGeoJSONBodyDto,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'layerId' is not null or undefined
            assertParamExists('layerControllerExtractGeoJSON', 'layerId', layerId);
            // verify required parameter 'extractGeoJSONBodyDto' is not null or undefined
            assertParamExists('layerControllerExtractGeoJSON', 'extractGeoJSONBodyDto', extractGeoJSONBodyDto);
            const localVarPath = `/api/v1/layer/{layerId}/geojson`.replace(
                `{${'layerId'}}`,
                encodeURIComponent(String(layerId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                extractGeoJSONBodyDto,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} layerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layerControllerGetById: async (layerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'layerId' is not null or undefined
            assertParamExists('layerControllerGetById', 'layerId', layerId);
            const localVarPath = `/api/v1/layer/{layerId}`.replace(
                `{${'layerId'}}`,
                encodeURIComponent(String(layerId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} layerName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layerControllerGetByName: async (layerName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'layerName' is not null or undefined
            assertParamExists('layerControllerGetByName', 'layerName', layerName);
            const localVarPath = `/api/v1/layer/{layerName}/get-by-name`.replace(
                `{${'layerName'}}`,
                encodeURIComponent(String(layerName)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} layerId
         * @param {Array<number>} [bbox]
         * @param {string} [projection]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layerControllerGetGeoJSON: async (
            layerId: number,
            bbox?: Array<number>,
            projection?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'layerId' is not null or undefined
            assertParamExists('layerControllerGetGeoJSON', 'layerId', layerId);
            const localVarPath = `/api/v1/layer/{layerId}/geojson`.replace(
                `{${'layerId'}}`,
                encodeURIComponent(String(layerId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            if (bbox) {
                localVarQueryParameter['bbox'] = bbox;
            }

            if (projection !== undefined) {
                localVarQueryParameter['projection'] = projection;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} [project]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layerControllerGetLayers: async (project?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/layer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            if (project !== undefined) {
                localVarQueryParameter['project'] = project;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layerControllerGetLayersInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/layer/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} layerId
         * @param {GetMBTilesQueryDto} getMBTilesQueryDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layerControllerGetMBTiles: async (
            layerId: number,
            getMBTilesQueryDto: GetMBTilesQueryDto,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'layerId' is not null or undefined
            assertParamExists('layerControllerGetMBTiles', 'layerId', layerId);
            // verify required parameter 'getMBTilesQueryDto' is not null or undefined
            assertParamExists('layerControllerGetMBTiles', 'getMBTilesQueryDto', getMBTilesQueryDto);
            const localVarPath = `/api/v1/layer/{layerId}/mbtiles`.replace(
                `{${'layerId'}}`,
                encodeURIComponent(String(layerId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                getMBTilesQueryDto,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} layerId
         * @param {number} x
         * @param {number} y
         * @param {number} z
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layerControllerGetPBF: async (
            layerId: number,
            x: number,
            y: number,
            z: number,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'layerId' is not null or undefined
            assertParamExists('layerControllerGetPBF', 'layerId', layerId);
            // verify required parameter 'x' is not null or undefined
            assertParamExists('layerControllerGetPBF', 'x', x);
            // verify required parameter 'y' is not null or undefined
            assertParamExists('layerControllerGetPBF', 'y', y);
            // verify required parameter 'z' is not null or undefined
            assertParamExists('layerControllerGetPBF', 'z', z);
            const localVarPath = `/api/v1/layer/{layerId}/{z}/{x}/{y}.pbf`
                .replace(`{${'layerId'}}`, encodeURIComponent(String(layerId)))
                .replace(`{${'x'}}`, encodeURIComponent(String(x)))
                .replace(`{${'y'}}`, encodeURIComponent(String(y)))
                .replace(`{${'z'}}`, encodeURIComponent(String(z)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} layerId
         * @param {string} q
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layerControllerSearchObjects: async (
            layerId: number,
            q: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'layerId' is not null or undefined
            assertParamExists('layerControllerSearchObjects', 'layerId', layerId);
            // verify required parameter 'q' is not null or undefined
            assertParamExists('layerControllerSearchObjects', 'q', q);
            const localVarPath = `/api/v1/layer/{layerId}/search`.replace(
                `{${'layerId'}}`,
                encodeURIComponent(String(layerId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} layerId
         * @param {UpdateLayerDto} updateLayerDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layerControllerUpdate: async (
            layerId: number,
            updateLayerDto: UpdateLayerDto,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'layerId' is not null or undefined
            assertParamExists('layerControllerUpdate', 'layerId', layerId);
            // verify required parameter 'updateLayerDto' is not null or undefined
            assertParamExists('layerControllerUpdate', 'updateLayerDto', updateLayerDto);
            const localVarPath = `/api/v1/layer/{layerId}`.replace(
                `{${'layerId'}}`,
                encodeURIComponent(String(layerId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(updateLayerDto, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} layerId
         * @param {UpdateDataBodyDto} updateDataBodyDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layerControllerUpdateData: async (
            layerId: string,
            updateDataBodyDto: UpdateDataBodyDto,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'layerId' is not null or undefined
            assertParamExists('layerControllerUpdateData', 'layerId', layerId);
            // verify required parameter 'updateDataBodyDto' is not null or undefined
            assertParamExists('layerControllerUpdateData', 'updateDataBodyDto', updateDataBodyDto);
            const localVarPath = `/api/v1/layer/{layerId}/geojson`.replace(
                `{${'layerId'}}`,
                encodeURIComponent(String(layerId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                updateDataBodyDto,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * LayerApi - functional programming interface
 * @export
 */
export const LayerApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = LayerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {number} zoneLayerId
         * @param {number} objectLayerId
         * @param {string} objectCountColumn
         * @param {string} objectCountValue1
         * @param {string} objectCountValue2
         * @param {string} objectJoinColumn
         * @param {string} objectNullColumn
         * @param {string} objectWhereColumn
         * @param {string} objectWhereValue
         * @param {string} zoneGeomColumn
         * @param {string} zoneIdColumn
         * @param {string} zoneJoinColumn
         * @param {string} zoneNameColumn
         * @param {string} zoneWhereColumn
         * @param {string} zoneWhereValue
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async layerControllerCountObjectInZoneGeoJSON(
            zoneLayerId: number,
            objectLayerId: number,
            objectCountColumn: string,
            objectCountValue1: string,
            objectCountValue2: string,
            objectJoinColumn: string,
            objectNullColumn: string,
            objectWhereColumn: string,
            objectWhereValue: string,
            zoneGeomColumn: string,
            zoneIdColumn: string,
            zoneJoinColumn: string,
            zoneNameColumn: string,
            zoneWhereColumn: string,
            zoneWhereValue: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeoJSONCountObjectZoneProperties>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.layerControllerCountObjectInZoneGeoJSON(
                zoneLayerId,
                objectLayerId,
                objectCountColumn,
                objectCountValue1,
                objectCountValue2,
                objectJoinColumn,
                objectNullColumn,
                objectWhereColumn,
                objectWhereValue,
                zoneGeomColumn,
                zoneIdColumn,
                zoneJoinColumn,
                zoneNameColumn,
                zoneWhereColumn,
                zoneWhereValue,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {CreateLayerDto} createLayerDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async layerControllerCreate(
            createLayerDto: CreateLayerDto,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Layer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.layerControllerCreate(createLayerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} layerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async layerControllerDelete(
            layerId: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.layerControllerDelete(layerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} layerId
         * @param {ExtractGeoJSONBodyDto} extractGeoJSONBodyDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async layerControllerExtractGeoJSON(
            layerId: number,
            extractGeoJSONBodyDto: ExtractGeoJSONBodyDto,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeoJSONGeoJSONPropertiesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.layerControllerExtractGeoJSON(
                layerId,
                extractGeoJSONBodyDto,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} layerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async layerControllerGetById(
            layerId: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Layer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.layerControllerGetById(layerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} layerName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async layerControllerGetByName(
            layerName: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Layer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.layerControllerGetByName(layerName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} layerId
         * @param {Array<number>} [bbox]
         * @param {string} [projection]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async layerControllerGetGeoJSON(
            layerId: number,
            bbox?: Array<number>,
            projection?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeoJSONGeoJSONPropertiesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.layerControllerGetGeoJSON(
                layerId,
                bbox,
                projection,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} [project]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async layerControllerGetLayers(
            project?: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Layer>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.layerControllerGetLayers(project, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async layerControllerGetLayersInfo(
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Layer>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.layerControllerGetLayersInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} layerId
         * @param {GetMBTilesQueryDto} getMBTilesQueryDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async layerControllerGetMBTiles(
            layerId: number,
            getMBTilesQueryDto: GetMBTilesQueryDto,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.layerControllerGetMBTiles(
                layerId,
                getMBTilesQueryDto,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} layerId
         * @param {number} x
         * @param {number} y
         * @param {number} z
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async layerControllerGetPBF(
            layerId: number,
            x: number,
            y: number,
            z: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.layerControllerGetPBF(layerId, x, y, z, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} layerId
         * @param {string} q
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async layerControllerSearchObjects(
            layerId: number,
            q: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchGeoJSON>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.layerControllerSearchObjects(layerId, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} layerId
         * @param {UpdateLayerDto} updateLayerDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async layerControllerUpdate(
            layerId: number,
            updateLayerDto: UpdateLayerDto,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Layer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.layerControllerUpdate(
                layerId,
                updateLayerDto,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} layerId
         * @param {UpdateDataBodyDto} updateDataBodyDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async layerControllerUpdateData(
            layerId: string,
            updateDataBodyDto: UpdateDataBodyDto,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.layerControllerUpdateData(
                layerId,
                updateDataBodyDto,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * LayerApi - factory interface
 * @export
 */
export const LayerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LayerApiFp(configuration);
    return {
        /**
         *
         * @param {number} zoneLayerId
         * @param {number} objectLayerId
         * @param {string} objectCountColumn
         * @param {string} objectCountValue1
         * @param {string} objectCountValue2
         * @param {string} objectJoinColumn
         * @param {string} objectNullColumn
         * @param {string} objectWhereColumn
         * @param {string} objectWhereValue
         * @param {string} zoneGeomColumn
         * @param {string} zoneIdColumn
         * @param {string} zoneJoinColumn
         * @param {string} zoneNameColumn
         * @param {string} zoneWhereColumn
         * @param {string} zoneWhereValue
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layerControllerCountObjectInZoneGeoJSON(
            zoneLayerId: number,
            objectLayerId: number,
            objectCountColumn: string,
            objectCountValue1: string,
            objectCountValue2: string,
            objectJoinColumn: string,
            objectNullColumn: string,
            objectWhereColumn: string,
            objectWhereValue: string,
            zoneGeomColumn: string,
            zoneIdColumn: string,
            zoneJoinColumn: string,
            zoneNameColumn: string,
            zoneWhereColumn: string,
            zoneWhereValue: string,
            options?: any,
        ): AxiosPromise<GeoJSONCountObjectZoneProperties> {
            return localVarFp
                .layerControllerCountObjectInZoneGeoJSON(
                    zoneLayerId,
                    objectLayerId,
                    objectCountColumn,
                    objectCountValue1,
                    objectCountValue2,
                    objectJoinColumn,
                    objectNullColumn,
                    objectWhereColumn,
                    objectWhereValue,
                    zoneGeomColumn,
                    zoneIdColumn,
                    zoneJoinColumn,
                    zoneNameColumn,
                    zoneWhereColumn,
                    zoneWhereValue,
                    options,
                )
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {CreateLayerDto} createLayerDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layerControllerCreate(createLayerDto: CreateLayerDto, options?: any): AxiosPromise<Layer> {
            return localVarFp
                .layerControllerCreate(createLayerDto, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} layerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layerControllerDelete(layerId: number, options?: any): AxiosPromise<void> {
            return localVarFp.layerControllerDelete(layerId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} layerId
         * @param {ExtractGeoJSONBodyDto} extractGeoJSONBodyDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layerControllerExtractGeoJSON(
            layerId: number,
            extractGeoJSONBodyDto: ExtractGeoJSONBodyDto,
            options?: any,
        ): AxiosPromise<GeoJSONGeoJSONPropertiesDto> {
            return localVarFp
                .layerControllerExtractGeoJSON(layerId, extractGeoJSONBodyDto, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} layerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layerControllerGetById(layerId: number, options?: any): AxiosPromise<Layer> {
            return localVarFp.layerControllerGetById(layerId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} layerName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layerControllerGetByName(layerName: string, options?: any): AxiosPromise<Layer> {
            return localVarFp.layerControllerGetByName(layerName, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} layerId
         * @param {Array<number>} [bbox]
         * @param {string} [projection]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layerControllerGetGeoJSON(
            layerId: number,
            bbox?: Array<number>,
            projection?: string,
            options?: any,
        ): AxiosPromise<GeoJSONGeoJSONPropertiesDto> {
            return localVarFp
                .layerControllerGetGeoJSON(layerId, bbox, projection, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} [project]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layerControllerGetLayers(project?: number, options?: any): AxiosPromise<Array<Layer>> {
            return localVarFp.layerControllerGetLayers(project, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layerControllerGetLayersInfo(options?: any): AxiosPromise<Array<Layer>> {
            return localVarFp.layerControllerGetLayersInfo(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} layerId
         * @param {GetMBTilesQueryDto} getMBTilesQueryDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layerControllerGetMBTiles(
            layerId: number,
            getMBTilesQueryDto: GetMBTilesQueryDto,
            options?: any,
        ): AxiosPromise<File> {
            return localVarFp
                .layerControllerGetMBTiles(layerId, getMBTilesQueryDto, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} layerId
         * @param {number} x
         * @param {number} y
         * @param {number} z
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layerControllerGetPBF(layerId: number, x: number, y: number, z: number, options?: any): AxiosPromise<File> {
            return localVarFp
                .layerControllerGetPBF(layerId, x, y, z, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} layerId
         * @param {string} q
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layerControllerSearchObjects(layerId: number, q: string, options?: any): AxiosPromise<SearchGeoJSON> {
            return localVarFp
                .layerControllerSearchObjects(layerId, q, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} layerId
         * @param {UpdateLayerDto} updateLayerDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layerControllerUpdate(layerId: number, updateLayerDto: UpdateLayerDto, options?: any): AxiosPromise<Layer> {
            return localVarFp
                .layerControllerUpdate(layerId, updateLayerDto, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} layerId
         * @param {UpdateDataBodyDto} updateDataBodyDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layerControllerUpdateData(
            layerId: string,
            updateDataBodyDto: UpdateDataBodyDto,
            options?: any,
        ): AxiosPromise<void> {
            return localVarFp
                .layerControllerUpdateData(layerId, updateDataBodyDto, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * LayerApi - interface
 * @export
 * @interface LayerApi
 */
export interface LayerApiInterface {
    /**
     *
     * @param {number} zoneLayerId
     * @param {number} objectLayerId
     * @param {string} objectCountColumn
     * @param {string} objectCountValue1
     * @param {string} objectCountValue2
     * @param {string} objectJoinColumn
     * @param {string} objectNullColumn
     * @param {string} objectWhereColumn
     * @param {string} objectWhereValue
     * @param {string} zoneGeomColumn
     * @param {string} zoneIdColumn
     * @param {string} zoneJoinColumn
     * @param {string} zoneNameColumn
     * @param {string} zoneWhereColumn
     * @param {string} zoneWhereValue
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayerApiInterface
     */
    layerControllerCountObjectInZoneGeoJSON(
        zoneLayerId: number,
        objectLayerId: number,
        objectCountColumn: string,
        objectCountValue1: string,
        objectCountValue2: string,
        objectJoinColumn: string,
        objectNullColumn: string,
        objectWhereColumn: string,
        objectWhereValue: string,
        zoneGeomColumn: string,
        zoneIdColumn: string,
        zoneJoinColumn: string,
        zoneNameColumn: string,
        zoneWhereColumn: string,
        zoneWhereValue: string,
        options?: AxiosRequestConfig,
    ): AxiosPromise<GeoJSONCountObjectZoneProperties>;

    /**
     *
     * @param {CreateLayerDto} createLayerDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayerApiInterface
     */
    layerControllerCreate(createLayerDto: CreateLayerDto, options?: AxiosRequestConfig): AxiosPromise<Layer>;

    /**
     *
     * @param {number} layerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayerApiInterface
     */
    layerControllerDelete(layerId: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     *
     * @param {number} layerId
     * @param {ExtractGeoJSONBodyDto} extractGeoJSONBodyDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayerApiInterface
     */
    layerControllerExtractGeoJSON(
        layerId: number,
        extractGeoJSONBodyDto: ExtractGeoJSONBodyDto,
        options?: AxiosRequestConfig,
    ): AxiosPromise<GeoJSONGeoJSONPropertiesDto>;

    /**
     *
     * @param {number} layerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayerApiInterface
     */
    layerControllerGetById(layerId: number, options?: AxiosRequestConfig): AxiosPromise<Layer>;

    /**
     *
     * @param {string} layerName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayerApiInterface
     */
    layerControllerGetByName(layerName: string, options?: AxiosRequestConfig): AxiosPromise<Layer>;

    /**
     *
     * @param {number} layerId
     * @param {Array<number>} [bbox]
     * @param {string} [projection]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayerApiInterface
     */
    layerControllerGetGeoJSON(
        layerId: number,
        bbox?: Array<number>,
        projection?: string,
        options?: AxiosRequestConfig,
    ): AxiosPromise<GeoJSONGeoJSONPropertiesDto>;

    /**
     *
     * @param {number} [project]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayerApiInterface
     */
    layerControllerGetLayers(project?: number, options?: AxiosRequestConfig): AxiosPromise<Array<Layer>>;

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayerApiInterface
     */
    layerControllerGetLayersInfo(options?: AxiosRequestConfig): AxiosPromise<Array<Layer>>;

    /**
     *
     * @param {number} layerId
     * @param {GetMBTilesQueryDto} getMBTilesQueryDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayerApiInterface
     */
    layerControllerGetMBTiles(
        layerId: number,
        getMBTilesQueryDto: GetMBTilesQueryDto,
        options?: AxiosRequestConfig,
    ): AxiosPromise<File>;

    /**
     *
     * @param {number} layerId
     * @param {number} x
     * @param {number} y
     * @param {number} z
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayerApiInterface
     */
    layerControllerGetPBF(
        layerId: number,
        x: number,
        y: number,
        z: number,
        options?: AxiosRequestConfig,
    ): AxiosPromise<File>;

    /**
     *
     * @param {number} layerId
     * @param {string} q
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayerApiInterface
     */
    layerControllerSearchObjects(layerId: number, q: string, options?: AxiosRequestConfig): AxiosPromise<SearchGeoJSON>;

    /**
     *
     * @param {number} layerId
     * @param {UpdateLayerDto} updateLayerDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayerApiInterface
     */
    layerControllerUpdate(
        layerId: number,
        updateLayerDto: UpdateLayerDto,
        options?: AxiosRequestConfig,
    ): AxiosPromise<Layer>;

    /**
     *
     * @param {string} layerId
     * @param {UpdateDataBodyDto} updateDataBodyDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayerApiInterface
     */
    layerControllerUpdateData(
        layerId: string,
        updateDataBodyDto: UpdateDataBodyDto,
        options?: AxiosRequestConfig,
    ): AxiosPromise<void>;
}

/**
 * Request parameters for layerControllerCountObjectInZoneGeoJSON operation in LayerApi.
 * @export
 * @interface LayerApiLayerControllerCountObjectInZoneGeoJSONRequest
 */
export interface LayerApiLayerControllerCountObjectInZoneGeoJSONRequest {
    /**
     *
     * @type {number}
     * @memberof LayerApiLayerControllerCountObjectInZoneGeoJSON
     */
    readonly zoneLayerId: number;

    /**
     *
     * @type {number}
     * @memberof LayerApiLayerControllerCountObjectInZoneGeoJSON
     */
    readonly objectLayerId: number;

    /**
     *
     * @type {string}
     * @memberof LayerApiLayerControllerCountObjectInZoneGeoJSON
     */
    readonly objectCountColumn: string;

    /**
     *
     * @type {string}
     * @memberof LayerApiLayerControllerCountObjectInZoneGeoJSON
     */
    readonly objectCountValue1: string;

    /**
     *
     * @type {string}
     * @memberof LayerApiLayerControllerCountObjectInZoneGeoJSON
     */
    readonly objectCountValue2: string;

    /**
     *
     * @type {string}
     * @memberof LayerApiLayerControllerCountObjectInZoneGeoJSON
     */
    readonly objectJoinColumn: string;

    /**
     *
     * @type {string}
     * @memberof LayerApiLayerControllerCountObjectInZoneGeoJSON
     */
    readonly objectNullColumn: string;

    /**
     *
     * @type {string}
     * @memberof LayerApiLayerControllerCountObjectInZoneGeoJSON
     */
    readonly objectWhereColumn: string;

    /**
     *
     * @type {string}
     * @memberof LayerApiLayerControllerCountObjectInZoneGeoJSON
     */
    readonly objectWhereValue: string;

    /**
     *
     * @type {string}
     * @memberof LayerApiLayerControllerCountObjectInZoneGeoJSON
     */
    readonly zoneGeomColumn: string;

    /**
     *
     * @type {string}
     * @memberof LayerApiLayerControllerCountObjectInZoneGeoJSON
     */
    readonly zoneIdColumn: string;

    /**
     *
     * @type {string}
     * @memberof LayerApiLayerControllerCountObjectInZoneGeoJSON
     */
    readonly zoneJoinColumn: string;

    /**
     *
     * @type {string}
     * @memberof LayerApiLayerControllerCountObjectInZoneGeoJSON
     */
    readonly zoneNameColumn: string;

    /**
     *
     * @type {string}
     * @memberof LayerApiLayerControllerCountObjectInZoneGeoJSON
     */
    readonly zoneWhereColumn: string;

    /**
     *
     * @type {string}
     * @memberof LayerApiLayerControllerCountObjectInZoneGeoJSON
     */
    readonly zoneWhereValue: string;
}

/**
 * Request parameters for layerControllerCreate operation in LayerApi.
 * @export
 * @interface LayerApiLayerControllerCreateRequest
 */
export interface LayerApiLayerControllerCreateRequest {
    /**
     *
     * @type {CreateLayerDto}
     * @memberof LayerApiLayerControllerCreate
     */
    readonly createLayerDto: CreateLayerDto;
}

/**
 * Request parameters for layerControllerDelete operation in LayerApi.
 * @export
 * @interface LayerApiLayerControllerDeleteRequest
 */
export interface LayerApiLayerControllerDeleteRequest {
    /**
     *
     * @type {number}
     * @memberof LayerApiLayerControllerDelete
     */
    readonly layerId: number;
}

/**
 * Request parameters for layerControllerExtractGeoJSON operation in LayerApi.
 * @export
 * @interface LayerApiLayerControllerExtractGeoJSONRequest
 */
export interface LayerApiLayerControllerExtractGeoJSONRequest {
    /**
     *
     * @type {number}
     * @memberof LayerApiLayerControllerExtractGeoJSON
     */
    readonly layerId: number;

    /**
     *
     * @type {ExtractGeoJSONBodyDto}
     * @memberof LayerApiLayerControllerExtractGeoJSON
     */
    readonly extractGeoJSONBodyDto: ExtractGeoJSONBodyDto;
}

/**
 * Request parameters for layerControllerGetById operation in LayerApi.
 * @export
 * @interface LayerApiLayerControllerGetByIdRequest
 */
export interface LayerApiLayerControllerGetByIdRequest {
    /**
     *
     * @type {number}
     * @memberof LayerApiLayerControllerGetById
     */
    readonly layerId: number;
}

/**
 * Request parameters for layerControllerGetByName operation in LayerApi.
 * @export
 * @interface LayerApiLayerControllerGetByNameRequest
 */
export interface LayerApiLayerControllerGetByNameRequest {
    /**
     *
     * @type {string}
     * @memberof LayerApiLayerControllerGetByName
     */
    readonly layerName: string;
}

/**
 * Request parameters for layerControllerGetGeoJSON operation in LayerApi.
 * @export
 * @interface LayerApiLayerControllerGetGeoJSONRequest
 */
export interface LayerApiLayerControllerGetGeoJSONRequest {
    /**
     *
     * @type {number}
     * @memberof LayerApiLayerControllerGetGeoJSON
     */
    readonly layerId: number;

    /**
     *
     * @type {Array<number>}
     * @memberof LayerApiLayerControllerGetGeoJSON
     */
    readonly bbox?: Array<number>;

    /**
     *
     * @type {string}
     * @memberof LayerApiLayerControllerGetGeoJSON
     */
    readonly projection?: string;
}

/**
 * Request parameters for layerControllerGetLayers operation in LayerApi.
 * @export
 * @interface LayerApiLayerControllerGetLayersRequest
 */
export interface LayerApiLayerControllerGetLayersRequest {
    /**
     *
     * @type {number}
     * @memberof LayerApiLayerControllerGetLayers
     */
    readonly project?: number;
}

/**
 * Request parameters for layerControllerGetMBTiles operation in LayerApi.
 * @export
 * @interface LayerApiLayerControllerGetMBTilesRequest
 */
export interface LayerApiLayerControllerGetMBTilesRequest {
    /**
     *
     * @type {number}
     * @memberof LayerApiLayerControllerGetMBTiles
     */
    readonly layerId: number;

    /**
     *
     * @type {GetMBTilesQueryDto}
     * @memberof LayerApiLayerControllerGetMBTiles
     */
    readonly getMBTilesQueryDto: GetMBTilesQueryDto;
}

/**
 * Request parameters for layerControllerGetPBF operation in LayerApi.
 * @export
 * @interface LayerApiLayerControllerGetPBFRequest
 */
export interface LayerApiLayerControllerGetPBFRequest {
    /**
     *
     * @type {number}
     * @memberof LayerApiLayerControllerGetPBF
     */
    readonly layerId: number;

    /**
     *
     * @type {number}
     * @memberof LayerApiLayerControllerGetPBF
     */
    readonly x: number;

    /**
     *
     * @type {number}
     * @memberof LayerApiLayerControllerGetPBF
     */
    readonly y: number;

    /**
     *
     * @type {number}
     * @memberof LayerApiLayerControllerGetPBF
     */
    readonly z: number;
}

/**
 * Request parameters for layerControllerSearchObjects operation in LayerApi.
 * @export
 * @interface LayerApiLayerControllerSearchObjectsRequest
 */
export interface LayerApiLayerControllerSearchObjectsRequest {
    /**
     *
     * @type {number}
     * @memberof LayerApiLayerControllerSearchObjects
     */
    readonly layerId: number;

    /**
     *
     * @type {string}
     * @memberof LayerApiLayerControllerSearchObjects
     */
    readonly q: string;
}

/**
 * Request parameters for layerControllerUpdate operation in LayerApi.
 * @export
 * @interface LayerApiLayerControllerUpdateRequest
 */
export interface LayerApiLayerControllerUpdateRequest {
    /**
     *
     * @type {number}
     * @memberof LayerApiLayerControllerUpdate
     */
    readonly layerId: number;

    /**
     *
     * @type {UpdateLayerDto}
     * @memberof LayerApiLayerControllerUpdate
     */
    readonly updateLayerDto: UpdateLayerDto;
}

/**
 * Request parameters for layerControllerUpdateData operation in LayerApi.
 * @export
 * @interface LayerApiLayerControllerUpdateDataRequest
 */
export interface LayerApiLayerControllerUpdateDataRequest {
    /**
     *
     * @type {string}
     * @memberof LayerApiLayerControllerUpdateData
     */
    readonly layerId: string;

    /**
     *
     * @type {UpdateDataBodyDto}
     * @memberof LayerApiLayerControllerUpdateData
     */
    readonly updateDataBodyDto: UpdateDataBodyDto;
}

/**
 * LayerApi - object-oriented interface
 * @export
 * @class LayerApi
 * @extends {BaseAPI}
 */
export class LayerApi extends BaseAPI implements LayerApiInterface {
    /**
     *
     * @param {LayerApiLayerControllerCountObjectInZoneGeoJSONRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayerApi
     */
    public layerControllerCountObjectInZoneGeoJSON(
        requestParameters: LayerApiLayerControllerCountObjectInZoneGeoJSONRequest,
        options?: AxiosRequestConfig,
    ) {
        return LayerApiFp(this.configuration)
            .layerControllerCountObjectInZoneGeoJSON(
                requestParameters.zoneLayerId,
                requestParameters.objectLayerId,
                requestParameters.objectCountColumn,
                requestParameters.objectCountValue1,
                requestParameters.objectCountValue2,
                requestParameters.objectJoinColumn,
                requestParameters.objectNullColumn,
                requestParameters.objectWhereColumn,
                requestParameters.objectWhereValue,
                requestParameters.zoneGeomColumn,
                requestParameters.zoneIdColumn,
                requestParameters.zoneJoinColumn,
                requestParameters.zoneNameColumn,
                requestParameters.zoneWhereColumn,
                requestParameters.zoneWhereValue,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {LayerApiLayerControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayerApi
     */
    public layerControllerCreate(
        requestParameters: LayerApiLayerControllerCreateRequest,
        options?: AxiosRequestConfig,
    ) {
        return LayerApiFp(this.configuration)
            .layerControllerCreate(requestParameters.createLayerDto, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {LayerApiLayerControllerDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayerApi
     */
    public layerControllerDelete(
        requestParameters: LayerApiLayerControllerDeleteRequest,
        options?: AxiosRequestConfig,
    ) {
        return LayerApiFp(this.configuration)
            .layerControllerDelete(requestParameters.layerId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {LayerApiLayerControllerExtractGeoJSONRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayerApi
     */
    public layerControllerExtractGeoJSON(
        requestParameters: LayerApiLayerControllerExtractGeoJSONRequest,
        options?: AxiosRequestConfig,
    ) {
        return LayerApiFp(this.configuration)
            .layerControllerExtractGeoJSON(requestParameters.layerId, requestParameters.extractGeoJSONBodyDto, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {LayerApiLayerControllerGetByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayerApi
     */
    public layerControllerGetById(
        requestParameters: LayerApiLayerControllerGetByIdRequest,
        options?: AxiosRequestConfig,
    ) {
        return LayerApiFp(this.configuration)
            .layerControllerGetById(requestParameters.layerId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {LayerApiLayerControllerGetByNameRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayerApi
     */
    public layerControllerGetByName(
        requestParameters: LayerApiLayerControllerGetByNameRequest,
        options?: AxiosRequestConfig,
    ) {
        return LayerApiFp(this.configuration)
            .layerControllerGetByName(requestParameters.layerName, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {LayerApiLayerControllerGetGeoJSONRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayerApi
     */
    public layerControllerGetGeoJSON(
        requestParameters: LayerApiLayerControllerGetGeoJSONRequest,
        options?: AxiosRequestConfig,
    ) {
        return LayerApiFp(this.configuration)
            .layerControllerGetGeoJSON(
                requestParameters.layerId,
                requestParameters.bbox,
                requestParameters.projection,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {LayerApiLayerControllerGetLayersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayerApi
     */
    public layerControllerGetLayers(
        requestParameters: LayerApiLayerControllerGetLayersRequest = {},
        options?: AxiosRequestConfig,
    ) {
        return LayerApiFp(this.configuration)
            .layerControllerGetLayers(requestParameters.project, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayerApi
     */
    public layerControllerGetLayersInfo(options?: AxiosRequestConfig) {
        return LayerApiFp(this.configuration)
            .layerControllerGetLayersInfo(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {LayerApiLayerControllerGetMBTilesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayerApi
     */
    public layerControllerGetMBTiles(
        requestParameters: LayerApiLayerControllerGetMBTilesRequest,
        options?: AxiosRequestConfig,
    ) {
        return LayerApiFp(this.configuration)
            .layerControllerGetMBTiles(requestParameters.layerId, requestParameters.getMBTilesQueryDto, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {LayerApiLayerControllerGetPBFRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayerApi
     */
    public layerControllerGetPBF(
        requestParameters: LayerApiLayerControllerGetPBFRequest,
        options?: AxiosRequestConfig,
    ) {
        return LayerApiFp(this.configuration)
            .layerControllerGetPBF(
                requestParameters.layerId,
                requestParameters.x,
                requestParameters.y,
                requestParameters.z,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {LayerApiLayerControllerSearchObjectsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayerApi
     */
    public layerControllerSearchObjects(
        requestParameters: LayerApiLayerControllerSearchObjectsRequest,
        options?: AxiosRequestConfig,
    ) {
        return LayerApiFp(this.configuration)
            .layerControllerSearchObjects(requestParameters.layerId, requestParameters.q, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {LayerApiLayerControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayerApi
     */
    public layerControllerUpdate(
        requestParameters: LayerApiLayerControllerUpdateRequest,
        options?: AxiosRequestConfig,
    ) {
        return LayerApiFp(this.configuration)
            .layerControllerUpdate(requestParameters.layerId, requestParameters.updateLayerDto, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {LayerApiLayerControllerUpdateDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayerApi
     */
    public layerControllerUpdateData(
        requestParameters: LayerApiLayerControllerUpdateDataRequest,
        options?: AxiosRequestConfig,
    ) {
        return LayerApiFp(this.configuration)
            .layerControllerUpdateData(requestParameters.layerId, requestParameters.updateDataBodyDto, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * MetricsApi - axios parameter creator
 * @export
 */
export const MetricsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} [action]
         * @param {Date} [from]
         * @param {'GET' | 'POST' | 'DELETE' | 'PUT'} [method]
         * @param {string} [path]
         * @param {Date} [to]
         * @param {number} [userId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metricsControllerGetMetrics: async (
            action?: string,
            from?: Date,
            method?: 'GET' | 'POST' | 'DELETE' | 'PUT',
            path?: string,
            to?: Date,
            userId?: number,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/metrics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            if (action !== undefined) {
                localVarQueryParameter['action'] = action;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any) instanceof Date ? (from as any).toISOString() : from;
            }

            if (method !== undefined) {
                localVarQueryParameter['method'] = method;
            }

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any) instanceof Date ? (to as any).toISOString() : to;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} action
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metricsControllerGetMetricsByAction: async (
            action: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'action' is not null or undefined
            assertParamExists('metricsControllerGetMetricsByAction', 'action', action);
            const localVarPath = `/api/v1/metrics/action/{action}`.replace(
                `{${'action'}}`,
                encodeURIComponent(String(action)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} metricsId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metricsControllerGetMetricsById: async (
            metricsId: number,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'metricsId' is not null or undefined
            assertParamExists('metricsControllerGetMetricsById', 'metricsId', metricsId);
            const localVarPath = `/api/v1/metrics/{metricsId}`.replace(
                `{${'metricsId'}}`,
                encodeURIComponent(String(metricsId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metricsControllerGetMetricsByUserId: async (
            userId: number,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('metricsControllerGetMetricsByUserId', 'userId', userId);
            const localVarPath = `/api/v1/metrics/user/{userId}`.replace(
                `{${'userId'}}`,
                encodeURIComponent(String(userId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * MetricsApi - functional programming interface
 * @export
 */
export const MetricsApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = MetricsApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {string} [action]
         * @param {Date} [from]
         * @param {'GET' | 'POST' | 'DELETE' | 'PUT'} [method]
         * @param {string} [path]
         * @param {Date} [to]
         * @param {number} [userId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metricsControllerGetMetrics(
            action?: string,
            from?: Date,
            method?: 'GET' | 'POST' | 'DELETE' | 'PUT',
            path?: string,
            to?: Date,
            userId?: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Metrics>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.metricsControllerGetMetrics(
                action,
                from,
                method,
                path,
                to,
                userId,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} action
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metricsControllerGetMetricsByAction(
            action: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Metrics>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.metricsControllerGetMetricsByAction(
                action,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} metricsId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metricsControllerGetMetricsById(
            metricsId: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Metrics>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.metricsControllerGetMetricsById(
                metricsId,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metricsControllerGetMetricsByUserId(
            userId: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Metrics>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.metricsControllerGetMetricsByUserId(
                userId,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * MetricsApi - factory interface
 * @export
 */
export const MetricsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MetricsApiFp(configuration);
    return {
        /**
         *
         * @param {string} [action]
         * @param {Date} [from]
         * @param {'GET' | 'POST' | 'DELETE' | 'PUT'} [method]
         * @param {string} [path]
         * @param {Date} [to]
         * @param {number} [userId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metricsControllerGetMetrics(
            action?: string,
            from?: Date,
            method?: 'GET' | 'POST' | 'DELETE' | 'PUT',
            path?: string,
            to?: Date,
            userId?: number,
            options?: any,
        ): AxiosPromise<Array<Metrics>> {
            return localVarFp
                .metricsControllerGetMetrics(action, from, method, path, to, userId, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} action
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metricsControllerGetMetricsByAction(action: string, options?: any): AxiosPromise<Array<Metrics>> {
            return localVarFp
                .metricsControllerGetMetricsByAction(action, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} metricsId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metricsControllerGetMetricsById(metricsId: number, options?: any): AxiosPromise<Metrics> {
            return localVarFp
                .metricsControllerGetMetricsById(metricsId, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metricsControllerGetMetricsByUserId(userId: number, options?: any): AxiosPromise<Array<Metrics>> {
            return localVarFp
                .metricsControllerGetMetricsByUserId(userId, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * MetricsApi - interface
 * @export
 * @interface MetricsApi
 */
export interface MetricsApiInterface {
    /**
     *
     * @param {string} [action]
     * @param {Date} [from]
     * @param {'GET' | 'POST' | 'DELETE' | 'PUT'} [method]
     * @param {string} [path]
     * @param {Date} [to]
     * @param {number} [userId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApiInterface
     */
    metricsControllerGetMetrics(
        action?: string,
        from?: Date,
        method?: 'GET' | 'POST' | 'DELETE' | 'PUT',
        path?: string,
        to?: Date,
        userId?: number,
        options?: AxiosRequestConfig,
    ): AxiosPromise<Array<Metrics>>;

    /**
     *
     * @param {string} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApiInterface
     */
    metricsControllerGetMetricsByAction(action: string, options?: AxiosRequestConfig): AxiosPromise<Array<Metrics>>;

    /**
     *
     * @param {number} metricsId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApiInterface
     */
    metricsControllerGetMetricsById(metricsId: number, options?: AxiosRequestConfig): AxiosPromise<Metrics>;

    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApiInterface
     */
    metricsControllerGetMetricsByUserId(userId: number, options?: AxiosRequestConfig): AxiosPromise<Array<Metrics>>;
}

/**
 * Request parameters for metricsControllerGetMetrics operation in MetricsApi.
 * @export
 * @interface MetricsApiMetricsControllerGetMetricsRequest
 */
export interface MetricsApiMetricsControllerGetMetricsRequest {
    /**
     *
     * @type {string}
     * @memberof MetricsApiMetricsControllerGetMetrics
     */
    readonly action?: string;

    /**
     *
     * @type {Date}
     * @memberof MetricsApiMetricsControllerGetMetrics
     */
    readonly from?: Date;

    /**
     *
     * @type {'GET' | 'POST' | 'DELETE' | 'PUT'}
     * @memberof MetricsApiMetricsControllerGetMetrics
     */
    readonly method?: 'GET' | 'POST' | 'DELETE' | 'PUT';

    /**
     *
     * @type {string}
     * @memberof MetricsApiMetricsControllerGetMetrics
     */
    readonly path?: string;

    /**
     *
     * @type {Date}
     * @memberof MetricsApiMetricsControllerGetMetrics
     */
    readonly to?: Date;

    /**
     *
     * @type {number}
     * @memberof MetricsApiMetricsControllerGetMetrics
     */
    readonly userId?: number;
}

/**
 * Request parameters for metricsControllerGetMetricsByAction operation in MetricsApi.
 * @export
 * @interface MetricsApiMetricsControllerGetMetricsByActionRequest
 */
export interface MetricsApiMetricsControllerGetMetricsByActionRequest {
    /**
     *
     * @type {string}
     * @memberof MetricsApiMetricsControllerGetMetricsByAction
     */
    readonly action: string;
}

/**
 * Request parameters for metricsControllerGetMetricsById operation in MetricsApi.
 * @export
 * @interface MetricsApiMetricsControllerGetMetricsByIdRequest
 */
export interface MetricsApiMetricsControllerGetMetricsByIdRequest {
    /**
     *
     * @type {number}
     * @memberof MetricsApiMetricsControllerGetMetricsById
     */
    readonly metricsId: number;
}

/**
 * Request parameters for metricsControllerGetMetricsByUserId operation in MetricsApi.
 * @export
 * @interface MetricsApiMetricsControllerGetMetricsByUserIdRequest
 */
export interface MetricsApiMetricsControllerGetMetricsByUserIdRequest {
    /**
     *
     * @type {number}
     * @memberof MetricsApiMetricsControllerGetMetricsByUserId
     */
    readonly userId: number;
}

/**
 * MetricsApi - object-oriented interface
 * @export
 * @class MetricsApi
 * @extends {BaseAPI}
 */
export class MetricsApi extends BaseAPI implements MetricsApiInterface {
    /**
     *
     * @param {MetricsApiMetricsControllerGetMetricsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public metricsControllerGetMetrics(
        requestParameters: MetricsApiMetricsControllerGetMetricsRequest = {},
        options?: AxiosRequestConfig,
    ) {
        return MetricsApiFp(this.configuration)
            .metricsControllerGetMetrics(
                requestParameters.action,
                requestParameters.from,
                requestParameters.method,
                requestParameters.path,
                requestParameters.to,
                requestParameters.userId,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {MetricsApiMetricsControllerGetMetricsByActionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public metricsControllerGetMetricsByAction(
        requestParameters: MetricsApiMetricsControllerGetMetricsByActionRequest,
        options?: AxiosRequestConfig,
    ) {
        return MetricsApiFp(this.configuration)
            .metricsControllerGetMetricsByAction(requestParameters.action, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {MetricsApiMetricsControllerGetMetricsByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public metricsControllerGetMetricsById(
        requestParameters: MetricsApiMetricsControllerGetMetricsByIdRequest,
        options?: AxiosRequestConfig,
    ) {
        return MetricsApiFp(this.configuration)
            .metricsControllerGetMetricsById(requestParameters.metricsId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {MetricsApiMetricsControllerGetMetricsByUserIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public metricsControllerGetMetricsByUserId(
        requestParameters: MetricsApiMetricsControllerGetMetricsByUserIdRequest,
        options?: AxiosRequestConfig,
    ) {
        return MetricsApiFp(this.configuration)
            .metricsControllerGetMetricsByUserId(requestParameters.userId, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * ObjectApi - axios parameter creator
 * @export
 */
export const ObjectApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {number} layerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectControllerCreate: async (layerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'layerId' is not null or undefined
            assertParamExists('objectControllerCreate', 'layerId', layerId);
            const localVarPath = `/api/v1/object/layer/{layerId}`.replace(
                `{${'layerId'}}`,
                encodeURIComponent(String(layerId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} layerId
         * @param {string} objectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectControllerDelete: async (
            layerId: number,
            objectId: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'layerId' is not null or undefined
            assertParamExists('objectControllerDelete', 'layerId', layerId);
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('objectControllerDelete', 'objectId', objectId);
            const localVarPath = `/api/v1/object/{objectId}/layer/{layerId}`
                .replace(`{${'layerId'}}`, encodeURIComponent(String(layerId)))
                .replace(`{${'objectId'}}`, encodeURIComponent(String(objectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} layer
         * @param {Array<string>} [where]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectControllerDeleteObject: async (
            layer: number,
            where?: Array<string>,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'layer' is not null or undefined
            assertParamExists('objectControllerDeleteObject', 'layer', layer);
            const localVarPath = `/api/v1/object`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            if (where) {
                localVarQueryParameter['where'] = where;
            }

            if (layer !== undefined) {
                localVarQueryParameter['layer'] = layer;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {'BPE' | 'BPI' | 'CHAMBRE' | 'NRA' | 'NRO' | 'PBO' | 'PTO' | 'SRO' | 'SROL'} layerName
         * @param {string} deliveryZone
         * @param {string} objectId
         * @param {string} projectName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectControllerExtractObject: async (
            layerName: 'BPE' | 'BPI' | 'CHAMBRE' | 'NRA' | 'NRO' | 'PBO' | 'PTO' | 'SRO' | 'SROL',
            deliveryZone: string,
            objectId: string,
            projectName: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'layerName' is not null or undefined
            assertParamExists('objectControllerExtractObject', 'layerName', layerName);
            // verify required parameter 'deliveryZone' is not null or undefined
            assertParamExists('objectControllerExtractObject', 'deliveryZone', deliveryZone);
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('objectControllerExtractObject', 'objectId', objectId);
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('objectControllerExtractObject', 'projectName', projectName);
            const localVarPath = `/api/v1/object/extract`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            if (layerName !== undefined) {
                localVarQueryParameter['layerName'] = layerName;
            }

            if (deliveryZone !== undefined) {
                localVarQueryParameter['deliveryZone'] = deliveryZone;
            }

            if (objectId !== undefined) {
                localVarQueryParameter['objectId'] = objectId;
            }

            if (projectName !== undefined) {
                localVarQueryParameter['projectName'] = projectName;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} layer
         * @param {Array<string>} [where]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectControllerGet: async (
            layer: number,
            where?: Array<string>,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'layer' is not null or undefined
            assertParamExists('objectControllerGet', 'layer', layer);
            const localVarPath = `/api/v1/object`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            if (where) {
                localVarQueryParameter['where'] = where;
            }

            if (layer !== undefined) {
                localVarQueryParameter['layer'] = layer;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} layerName
         * @param {string} objectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectControllerGetGeoJSON: async (
            layerName: string,
            objectId: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'layerName' is not null or undefined
            assertParamExists('objectControllerGetGeoJSON', 'layerName', layerName);
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('objectControllerGetGeoJSON', 'objectId', objectId);
            const localVarPath = `/api/v1/object/{objectId}/layer/{layerName}/geojson`
                .replace(`{${'layerName'}}`, encodeURIComponent(String(layerName)))
                .replace(`{${'objectId'}}`, encodeURIComponent(String(objectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} layerName
         * @param {string} objectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectControllerGetImages: async (
            layerName: string,
            objectId: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'layerName' is not null or undefined
            assertParamExists('objectControllerGetImages', 'layerName', layerName);
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('objectControllerGetImages', 'objectId', objectId);
            const localVarPath = `/api/v1/object/{objectId}/layer/{layerName}/images`
                .replace(`{${'layerName'}}`, encodeURIComponent(String(layerName)))
                .replace(`{${'objectId'}}`, encodeURIComponent(String(objectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} layer
         * @param {Array<string>} [where]
         * @param {number} [page]
         * @param {number} [take]
         * @param {'ASC' | 'DESC'} [order]
         * @param {string} [orderBy]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectControllerGetPaginated: async (
            layer: number,
            where?: Array<string>,
            page?: number,
            take?: number,
            order?: 'ASC' | 'DESC',
            orderBy?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'layer' is not null or undefined
            assertParamExists('objectControllerGetPaginated', 'layer', layer);
            const localVarPath = `/api/v1/object/paginated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            if (where) {
                localVarQueryParameter['where'] = where;
            }

            if (layer !== undefined) {
                localVarQueryParameter['layer'] = layer;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} condition
         * @param {string} form
         * @param {string} layerName
         * @param {string} where
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectControllerQuerySpecificImages: async (
            condition: string,
            form: string,
            layerName: string,
            where: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'condition' is not null or undefined
            assertParamExists('objectControllerQuerySpecificImages', 'condition', condition);
            // verify required parameter 'form' is not null or undefined
            assertParamExists('objectControllerQuerySpecificImages', 'form', form);
            // verify required parameter 'layerName' is not null or undefined
            assertParamExists('objectControllerQuerySpecificImages', 'layerName', layerName);
            // verify required parameter 'where' is not null or undefined
            assertParamExists('objectControllerQuerySpecificImages', 'where', where);
            const localVarPath = `/api/v1/object/images`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            if (condition !== undefined) {
                localVarQueryParameter['condition'] = condition;
            }

            if (form !== undefined) {
                localVarQueryParameter['form'] = form;
            }

            if (layerName !== undefined) {
                localVarQueryParameter['layerName'] = layerName;
            }

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} layerId
         * @param {string} objectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectControllerUpdate: async (
            layerId: number,
            objectId: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'layerId' is not null or undefined
            assertParamExists('objectControllerUpdate', 'layerId', layerId);
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('objectControllerUpdate', 'objectId', objectId);
            const localVarPath = `/api/v1/object/{objectId}/layer/{layerId}`
                .replace(`{${'layerId'}}`, encodeURIComponent(String(layerId)))
                .replace(`{${'objectId'}}`, encodeURIComponent(String(objectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} layer
         * @param {Array<string>} set
         * @param {Array<string>} [where]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectControllerUpdateObjects: async (
            layer: number,
            set: Array<string>,
            where?: Array<string>,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'layer' is not null or undefined
            assertParamExists('objectControllerUpdateObjects', 'layer', layer);
            // verify required parameter 'set' is not null or undefined
            assertParamExists('objectControllerUpdateObjects', 'set', set);
            const localVarPath = `/api/v1/object`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            if (where) {
                localVarQueryParameter['where'] = where;
            }

            if (layer !== undefined) {
                localVarQueryParameter['layer'] = layer;
            }

            if (set) {
                localVarQueryParameter['set'] = set;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * ObjectApi - functional programming interface
 * @export
 */
export const ObjectApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = ObjectApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {number} layerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async objectControllerCreate(
            layerId: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.objectControllerCreate(layerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} layerId
         * @param {string} objectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async objectControllerDelete(
            layerId: number,
            objectId: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.objectControllerDelete(
                layerId,
                objectId,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} layer
         * @param {Array<string>} [where]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async objectControllerDeleteObject(
            layer: number,
            where?: Array<string>,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.objectControllerDeleteObject(
                layer,
                where,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {'BPE' | 'BPI' | 'CHAMBRE' | 'NRA' | 'NRO' | 'PBO' | 'PTO' | 'SRO' | 'SROL'} layerName
         * @param {string} deliveryZone
         * @param {string} objectId
         * @param {string} projectName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async objectControllerExtractObject(
            layerName: 'BPE' | 'BPI' | 'CHAMBRE' | 'NRA' | 'NRO' | 'PBO' | 'PTO' | 'SRO' | 'SROL',
            deliveryZone: string,
            objectId: string,
            projectName: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.objectControllerExtractObject(
                layerName,
                deliveryZone,
                objectId,
                projectName,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} layer
         * @param {Array<string>} [where]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async objectControllerGet(
            layer: number,
            where?: Array<string>,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.objectControllerGet(layer, where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} layerName
         * @param {string} objectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async objectControllerGetGeoJSON(
            layerName: string,
            objectId: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeoJSONGeoJSONPropertiesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.objectControllerGetGeoJSON(
                layerName,
                objectId,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} layerName
         * @param {string} objectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async objectControllerGetImages(
            layerName: string,
            objectId: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImageResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.objectControllerGetImages(
                layerName,
                objectId,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} layer
         * @param {Array<string>} [where]
         * @param {number} [page]
         * @param {number} [take]
         * @param {'ASC' | 'DESC'} [order]
         * @param {string} [orderBy]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async objectControllerGetPaginated(
            layer: number,
            where?: Array<string>,
            page?: number,
            take?: number,
            order?: 'ASC' | 'DESC',
            orderBy?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.objectControllerGetPaginated(
                layer,
                where,
                page,
                take,
                order,
                orderBy,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} condition
         * @param {string} form
         * @param {string} layerName
         * @param {string} where
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async objectControllerQuerySpecificImages(
            condition: string,
            form: string,
            layerName: string,
            where: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImageResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.objectControllerQuerySpecificImages(
                condition,
                form,
                layerName,
                where,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} layerId
         * @param {string} objectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async objectControllerUpdate(
            layerId: number,
            objectId: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.objectControllerUpdate(
                layerId,
                objectId,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} layer
         * @param {Array<string>} set
         * @param {Array<string>} [where]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async objectControllerUpdateObjects(
            layer: number,
            set: Array<string>,
            where?: Array<string>,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.objectControllerUpdateObjects(
                layer,
                set,
                where,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * ObjectApi - factory interface
 * @export
 */
export const ObjectApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ObjectApiFp(configuration);
    return {
        /**
         *
         * @param {number} layerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectControllerCreate(layerId: number, options?: any): AxiosPromise<object> {
            return localVarFp.objectControllerCreate(layerId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} layerId
         * @param {string} objectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectControllerDelete(layerId: number, objectId: string, options?: any): AxiosPromise<void> {
            return localVarFp
                .objectControllerDelete(layerId, objectId, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} layer
         * @param {Array<string>} [where]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectControllerDeleteObject(layer: number, where?: Array<string>, options?: any): AxiosPromise<number> {
            return localVarFp
                .objectControllerDeleteObject(layer, where, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {'BPE' | 'BPI' | 'CHAMBRE' | 'NRA' | 'NRO' | 'PBO' | 'PTO' | 'SRO' | 'SROL'} layerName
         * @param {string} deliveryZone
         * @param {string} objectId
         * @param {string} projectName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectControllerExtractObject(
            layerName: 'BPE' | 'BPI' | 'CHAMBRE' | 'NRA' | 'NRO' | 'PBO' | 'PTO' | 'SRO' | 'SROL',
            deliveryZone: string,
            objectId: string,
            projectName: string,
            options?: any,
        ): AxiosPromise<object> {
            return localVarFp
                .objectControllerExtractObject(layerName, deliveryZone, objectId, projectName, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} layer
         * @param {Array<string>} [where]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectControllerGet(layer: number, where?: Array<string>, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.objectControllerGet(layer, where, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} layerName
         * @param {string} objectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectControllerGetGeoJSON(
            layerName: string,
            objectId: string,
            options?: any,
        ): AxiosPromise<GeoJSONGeoJSONPropertiesDto> {
            return localVarFp
                .objectControllerGetGeoJSON(layerName, objectId, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} layerName
         * @param {string} objectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectControllerGetImages(
            layerName: string,
            objectId: string,
            options?: any,
        ): AxiosPromise<Array<ImageResponseDto>> {
            return localVarFp
                .objectControllerGetImages(layerName, objectId, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} layer
         * @param {Array<string>} [where]
         * @param {number} [page]
         * @param {number} [take]
         * @param {'ASC' | 'DESC'} [order]
         * @param {string} [orderBy]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectControllerGetPaginated(
            layer: number,
            where?: Array<string>,
            page?: number,
            take?: number,
            order?: 'ASC' | 'DESC',
            orderBy?: string,
            options?: any,
        ): AxiosPromise<object> {
            return localVarFp
                .objectControllerGetPaginated(layer, where, page, take, order, orderBy, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} condition
         * @param {string} form
         * @param {string} layerName
         * @param {string} where
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectControllerQuerySpecificImages(
            condition: string,
            form: string,
            layerName: string,
            where: string,
            options?: any,
        ): AxiosPromise<Array<ImageResponseDto>> {
            return localVarFp
                .objectControllerQuerySpecificImages(condition, form, layerName, where, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} layerId
         * @param {string} objectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectControllerUpdate(layerId: number, objectId: string, options?: any): AxiosPromise<object> {
            return localVarFp
                .objectControllerUpdate(layerId, objectId, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} layer
         * @param {Array<string>} set
         * @param {Array<string>} [where]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectControllerUpdateObjects(
            layer: number,
            set: Array<string>,
            where?: Array<string>,
            options?: any,
        ): AxiosPromise<Array<object>> {
            return localVarFp
                .objectControllerUpdateObjects(layer, set, where, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * ObjectApi - interface
 * @export
 * @interface ObjectApi
 */
export interface ObjectApiInterface {
    /**
     *
     * @param {number} layerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApiInterface
     */
    objectControllerCreate(layerId: number, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     *
     * @param {number} layerId
     * @param {string} objectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApiInterface
     */
    objectControllerDelete(layerId: number, objectId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     *
     * @param {number} layer
     * @param {Array<string>} [where]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApiInterface
     */
    objectControllerDeleteObject(
        layer: number,
        where?: Array<string>,
        options?: AxiosRequestConfig,
    ): AxiosPromise<number>;

    /**
     *
     * @param {'BPE' | 'BPI' | 'CHAMBRE' | 'NRA' | 'NRO' | 'PBO' | 'PTO' | 'SRO' | 'SROL'} layerName
     * @param {string} deliveryZone
     * @param {string} objectId
     * @param {string} projectName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApiInterface
     */
    objectControllerExtractObject(
        layerName: 'BPE' | 'BPI' | 'CHAMBRE' | 'NRA' | 'NRO' | 'PBO' | 'PTO' | 'SRO' | 'SROL',
        deliveryZone: string,
        objectId: string,
        projectName: string,
        options?: AxiosRequestConfig,
    ): AxiosPromise<object>;

    /**
     *
     * @param {number} layer
     * @param {Array<string>} [where]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApiInterface
     */
    objectControllerGet(
        layer: number,
        where?: Array<string>,
        options?: AxiosRequestConfig,
    ): AxiosPromise<Array<object>>;

    /**
     *
     * @param {string} layerName
     * @param {string} objectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApiInterface
     */
    objectControllerGetGeoJSON(
        layerName: string,
        objectId: string,
        options?: AxiosRequestConfig,
    ): AxiosPromise<GeoJSONGeoJSONPropertiesDto>;

    /**
     *
     * @param {string} layerName
     * @param {string} objectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApiInterface
     */
    objectControllerGetImages(
        layerName: string,
        objectId: string,
        options?: AxiosRequestConfig,
    ): AxiosPromise<Array<ImageResponseDto>>;

    /**
     *
     * @param {number} layer
     * @param {Array<string>} [where]
     * @param {number} [page]
     * @param {number} [take]
     * @param {'ASC' | 'DESC'} [order]
     * @param {string} [orderBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApiInterface
     */
    objectControllerGetPaginated(
        layer: number,
        where?: Array<string>,
        page?: number,
        take?: number,
        order?: 'ASC' | 'DESC',
        orderBy?: string,
        options?: AxiosRequestConfig,
    ): AxiosPromise<object>;

    /**
     *
     * @param {string} condition
     * @param {string} form
     * @param {string} layerName
     * @param {string} where
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApiInterface
     */
    objectControllerQuerySpecificImages(
        condition: string,
        form: string,
        layerName: string,
        where: string,
        options?: AxiosRequestConfig,
    ): AxiosPromise<Array<ImageResponseDto>>;

    /**
     *
     * @param {number} layerId
     * @param {string} objectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApiInterface
     */
    objectControllerUpdate(layerId: number, objectId: string, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     *
     * @param {number} layer
     * @param {Array<string>} set
     * @param {Array<string>} [where]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApiInterface
     */
    objectControllerUpdateObjects(
        layer: number,
        set: Array<string>,
        where?: Array<string>,
        options?: AxiosRequestConfig,
    ): AxiosPromise<Array<object>>;
}

/**
 * Request parameters for objectControllerCreate operation in ObjectApi.
 * @export
 * @interface ObjectApiObjectControllerCreateRequest
 */
export interface ObjectApiObjectControllerCreateRequest {
    /**
     *
     * @type {number}
     * @memberof ObjectApiObjectControllerCreate
     */
    readonly layerId: number;
}

/**
 * Request parameters for objectControllerDelete operation in ObjectApi.
 * @export
 * @interface ObjectApiObjectControllerDeleteRequest
 */
export interface ObjectApiObjectControllerDeleteRequest {
    /**
     *
     * @type {number}
     * @memberof ObjectApiObjectControllerDelete
     */
    readonly layerId: number;

    /**
     *
     * @type {string}
     * @memberof ObjectApiObjectControllerDelete
     */
    readonly objectId: string;
}

/**
 * Request parameters for objectControllerDeleteObject operation in ObjectApi.
 * @export
 * @interface ObjectApiObjectControllerDeleteObjectRequest
 */
export interface ObjectApiObjectControllerDeleteObjectRequest {
    /**
     *
     * @type {number}
     * @memberof ObjectApiObjectControllerDeleteObject
     */
    readonly layer: number;

    /**
     *
     * @type {Array<string>}
     * @memberof ObjectApiObjectControllerDeleteObject
     */
    readonly where?: Array<string>;
}

/**
 * Request parameters for objectControllerExtractObject operation in ObjectApi.
 * @export
 * @interface ObjectApiObjectControllerExtractObjectRequest
 */
export interface ObjectApiObjectControllerExtractObjectRequest {
    /**
     *
     * @type {'BPE' | 'BPI' | 'CHAMBRE' | 'NRA' | 'NRO' | 'PBO' | 'PTO' | 'SRO' | 'SROL'}
     * @memberof ObjectApiObjectControllerExtractObject
     */
    readonly layerName: 'BPE' | 'BPI' | 'CHAMBRE' | 'NRA' | 'NRO' | 'PBO' | 'PTO' | 'SRO' | 'SROL';

    /**
     *
     * @type {string}
     * @memberof ObjectApiObjectControllerExtractObject
     */
    readonly deliveryZone: string;

    /**
     *
     * @type {string}
     * @memberof ObjectApiObjectControllerExtractObject
     */
    readonly objectId: string;

    /**
     *
     * @type {string}
     * @memberof ObjectApiObjectControllerExtractObject
     */
    readonly projectName: string;
}

/**
 * Request parameters for objectControllerGet operation in ObjectApi.
 * @export
 * @interface ObjectApiObjectControllerGetRequest
 */
export interface ObjectApiObjectControllerGetRequest {
    /**
     *
     * @type {number}
     * @memberof ObjectApiObjectControllerGet
     */
    readonly layer: number;

    /**
     *
     * @type {Array<string>}
     * @memberof ObjectApiObjectControllerGet
     */
    readonly where?: Array<string>;
}

/**
 * Request parameters for objectControllerGetGeoJSON operation in ObjectApi.
 * @export
 * @interface ObjectApiObjectControllerGetGeoJSONRequest
 */
export interface ObjectApiObjectControllerGetGeoJSONRequest {
    /**
     *
     * @type {string}
     * @memberof ObjectApiObjectControllerGetGeoJSON
     */
    readonly layerName: string;

    /**
     *
     * @type {string}
     * @memberof ObjectApiObjectControllerGetGeoJSON
     */
    readonly objectId: string;
}

/**
 * Request parameters for objectControllerGetImages operation in ObjectApi.
 * @export
 * @interface ObjectApiObjectControllerGetImagesRequest
 */
export interface ObjectApiObjectControllerGetImagesRequest {
    /**
     *
     * @type {string}
     * @memberof ObjectApiObjectControllerGetImages
     */
    readonly layerName: string;

    /**
     *
     * @type {string}
     * @memberof ObjectApiObjectControllerGetImages
     */
    readonly objectId: string;
}

/**
 * Request parameters for objectControllerGetPaginated operation in ObjectApi.
 * @export
 * @interface ObjectApiObjectControllerGetPaginatedRequest
 */
export interface ObjectApiObjectControllerGetPaginatedRequest {
    /**
     *
     * @type {number}
     * @memberof ObjectApiObjectControllerGetPaginated
     */
    readonly layer: number;

    /**
     *
     * @type {Array<string>}
     * @memberof ObjectApiObjectControllerGetPaginated
     */
    readonly where?: Array<string>;

    /**
     *
     * @type {number}
     * @memberof ObjectApiObjectControllerGetPaginated
     */
    readonly page?: number;

    /**
     *
     * @type {number}
     * @memberof ObjectApiObjectControllerGetPaginated
     */
    readonly take?: number;

    /**
     *
     * @type {'ASC' | 'DESC'}
     * @memberof ObjectApiObjectControllerGetPaginated
     */
    readonly order?: 'ASC' | 'DESC';

    /**
     *
     * @type {string}
     * @memberof ObjectApiObjectControllerGetPaginated
     */
    readonly orderBy?: string;
}

/**
 * Request parameters for objectControllerQuerySpecificImages operation in ObjectApi.
 * @export
 * @interface ObjectApiObjectControllerQuerySpecificImagesRequest
 */
export interface ObjectApiObjectControllerQuerySpecificImagesRequest {
    /**
     *
     * @type {string}
     * @memberof ObjectApiObjectControllerQuerySpecificImages
     */
    readonly condition: string;

    /**
     *
     * @type {string}
     * @memberof ObjectApiObjectControllerQuerySpecificImages
     */
    readonly form: string;

    /**
     *
     * @type {string}
     * @memberof ObjectApiObjectControllerQuerySpecificImages
     */
    readonly layerName: string;

    /**
     *
     * @type {string}
     * @memberof ObjectApiObjectControllerQuerySpecificImages
     */
    readonly where: string;
}

/**
 * Request parameters for objectControllerUpdate operation in ObjectApi.
 * @export
 * @interface ObjectApiObjectControllerUpdateRequest
 */
export interface ObjectApiObjectControllerUpdateRequest {
    /**
     *
     * @type {number}
     * @memberof ObjectApiObjectControllerUpdate
     */
    readonly layerId: number;

    /**
     *
     * @type {string}
     * @memberof ObjectApiObjectControllerUpdate
     */
    readonly objectId: string;
}

/**
 * Request parameters for objectControllerUpdateObjects operation in ObjectApi.
 * @export
 * @interface ObjectApiObjectControllerUpdateObjectsRequest
 */
export interface ObjectApiObjectControllerUpdateObjectsRequest {
    /**
     *
     * @type {number}
     * @memberof ObjectApiObjectControllerUpdateObjects
     */
    readonly layer: number;

    /**
     *
     * @type {Array<string>}
     * @memberof ObjectApiObjectControllerUpdateObjects
     */
    readonly set: Array<string>;

    /**
     *
     * @type {Array<string>}
     * @memberof ObjectApiObjectControllerUpdateObjects
     */
    readonly where?: Array<string>;
}

/**
 * ObjectApi - object-oriented interface
 * @export
 * @class ObjectApi
 * @extends {BaseAPI}
 */
export class ObjectApi extends BaseAPI implements ObjectApiInterface {
    /**
     *
     * @param {ObjectApiObjectControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApi
     */
    public objectControllerCreate(
        requestParameters: ObjectApiObjectControllerCreateRequest,
        options?: AxiosRequestConfig,
    ) {
        return ObjectApiFp(this.configuration)
            .objectControllerCreate(requestParameters.layerId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {ObjectApiObjectControllerDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApi
     */
    public objectControllerDelete(
        requestParameters: ObjectApiObjectControllerDeleteRequest,
        options?: AxiosRequestConfig,
    ) {
        return ObjectApiFp(this.configuration)
            .objectControllerDelete(requestParameters.layerId, requestParameters.objectId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {ObjectApiObjectControllerDeleteObjectRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApi
     */
    public objectControllerDeleteObject(
        requestParameters: ObjectApiObjectControllerDeleteObjectRequest,
        options?: AxiosRequestConfig,
    ) {
        return ObjectApiFp(this.configuration)
            .objectControllerDeleteObject(requestParameters.layer, requestParameters.where, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {ObjectApiObjectControllerExtractObjectRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApi
     */
    public objectControllerExtractObject(
        requestParameters: ObjectApiObjectControllerExtractObjectRequest,
        options?: AxiosRequestConfig,
    ) {
        return ObjectApiFp(this.configuration)
            .objectControllerExtractObject(
                requestParameters.layerName,
                requestParameters.deliveryZone,
                requestParameters.objectId,
                requestParameters.projectName,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {ObjectApiObjectControllerGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApi
     */
    public objectControllerGet(requestParameters: ObjectApiObjectControllerGetRequest, options?: AxiosRequestConfig) {
        return ObjectApiFp(this.configuration)
            .objectControllerGet(requestParameters.layer, requestParameters.where, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {ObjectApiObjectControllerGetGeoJSONRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApi
     */
    public objectControllerGetGeoJSON(
        requestParameters: ObjectApiObjectControllerGetGeoJSONRequest,
        options?: AxiosRequestConfig,
    ) {
        return ObjectApiFp(this.configuration)
            .objectControllerGetGeoJSON(requestParameters.layerName, requestParameters.objectId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {ObjectApiObjectControllerGetImagesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApi
     */
    public objectControllerGetImages(
        requestParameters: ObjectApiObjectControllerGetImagesRequest,
        options?: AxiosRequestConfig,
    ) {
        return ObjectApiFp(this.configuration)
            .objectControllerGetImages(requestParameters.layerName, requestParameters.objectId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {ObjectApiObjectControllerGetPaginatedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApi
     */
    public objectControllerGetPaginated(
        requestParameters: ObjectApiObjectControllerGetPaginatedRequest,
        options?: AxiosRequestConfig,
    ) {
        return ObjectApiFp(this.configuration)
            .objectControllerGetPaginated(
                requestParameters.layer,
                requestParameters.where,
                requestParameters.page,
                requestParameters.take,
                requestParameters.order,
                requestParameters.orderBy,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {ObjectApiObjectControllerQuerySpecificImagesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApi
     */
    public objectControllerQuerySpecificImages(
        requestParameters: ObjectApiObjectControllerQuerySpecificImagesRequest,
        options?: AxiosRequestConfig,
    ) {
        return ObjectApiFp(this.configuration)
            .objectControllerQuerySpecificImages(
                requestParameters.condition,
                requestParameters.form,
                requestParameters.layerName,
                requestParameters.where,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {ObjectApiObjectControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApi
     */
    public objectControllerUpdate(
        requestParameters: ObjectApiObjectControllerUpdateRequest,
        options?: AxiosRequestConfig,
    ) {
        return ObjectApiFp(this.configuration)
            .objectControllerUpdate(requestParameters.layerId, requestParameters.objectId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {ObjectApiObjectControllerUpdateObjectsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApi
     */
    public objectControllerUpdateObjects(
        requestParameters: ObjectApiObjectControllerUpdateObjectsRequest,
        options?: AxiosRequestConfig,
    ) {
        return ObjectApiFp(this.configuration)
            .objectControllerUpdateObjects(
                requestParameters.layer,
                requestParameters.set,
                requestParameters.where,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * ProjectApi - axios parameter creator
 * @export
 */
export const ProjectApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {number} domain
         * @param {string} name
         * @param {Array<number>} [bbox]
         * @param {File} [img]
         * @param {number} [selectionLayer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectControllerCreate: async (
            domain: number,
            name: string,
            bbox?: Array<number>,
            img?: File,
            selectionLayer?: number,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'domain' is not null or undefined
            assertParamExists('projectControllerCreate', 'domain', domain);
            // verify required parameter 'name' is not null or undefined
            assertParamExists('projectControllerCreate', 'name', name);
            const localVarPath = `/api/v1/project`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            if (bbox) {
                localVarFormParams.append('bbox', bbox.join(COLLECTION_FORMATS.csv));
            }

            if (domain !== undefined) {
                localVarFormParams.append('domain', domain as any);
            }

            if (img !== undefined) {
                localVarFormParams.append('img', img as any);
            }

            if (name !== undefined) {
                localVarFormParams.append('name', name as any);
            }

            if (selectionLayer !== undefined) {
                localVarFormParams.append('selectionLayer', selectionLayer as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} projectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectControllerDelete: async (projectId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectControllerDelete', 'projectId', projectId);
            const localVarPath = `/api/v1/project/{projectId}`.replace(
                `{${'projectId'}}`,
                encodeURIComponent(String(projectId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} projectId
         * @param {ProjectParamsDto} projectParamsDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectControllerExtractData: async (
            projectId: number,
            projectParamsDto: ProjectParamsDto,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectControllerExtractData', 'projectId', projectId);
            // verify required parameter 'projectParamsDto' is not null or undefined
            assertParamExists('projectControllerExtractData', 'projectParamsDto', projectParamsDto);
            const localVarPath = `/api/v1/project/{projectId}/extract-data`.replace(
                `{${'projectId'}}`,
                encodeURIComponent(String(projectId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                projectParamsDto,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} projectId
         * @param {ImagesDumpDto} imagesDumpDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectControllerGenerateProjectImageDump: async (
            projectId: number,
            imagesDumpDto: ImagesDumpDto,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectControllerGenerateProjectImageDump', 'projectId', projectId);
            // verify required parameter 'imagesDumpDto' is not null or undefined
            assertParamExists('projectControllerGenerateProjectImageDump', 'imagesDumpDto', imagesDumpDto);
            const localVarPath = `/api/v1/project/{projectId}/images-dump`.replace(
                `{${'projectId'}}`,
                encodeURIComponent(String(projectId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(imagesDumpDto, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} projectId
         * @param {SearchDumpDto} searchDumpDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectControllerGenerateProjectSearchDump: async (
            projectId: number,
            searchDumpDto: SearchDumpDto,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectControllerGenerateProjectSearchDump', 'projectId', projectId);
            // verify required parameter 'searchDumpDto' is not null or undefined
            assertParamExists('projectControllerGenerateProjectSearchDump', 'searchDumpDto', searchDumpDto);
            const localVarPath = `/api/v1/project/{projectId}/search-dump`.replace(
                `{${'projectId'}}`,
                encodeURIComponent(String(projectId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(searchDumpDto, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} projectId
         * @param {GetBaseMapDto} getBaseMapDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectControllerGetBaseMap: async (
            projectId: number,
            getBaseMapDto: GetBaseMapDto,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectControllerGetBaseMap', 'projectId', projectId);
            // verify required parameter 'getBaseMapDto' is not null or undefined
            assertParamExists('projectControllerGetBaseMap', 'getBaseMapDto', getBaseMapDto);
            const localVarPath = `/api/v1/project/{projectId}/basemap`.replace(
                `{${'projectId'}}`,
                encodeURIComponent(String(projectId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(getBaseMapDto, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} projectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectControllerGetById: async (projectId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectControllerGetById', 'projectId', projectId);
            const localVarPath = `/api/v1/project/{projectId}`.replace(
                `{${'projectId'}}`,
                encodeURIComponent(String(projectId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} projectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectControllerGetConfiguration: async (
            projectId: number,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectControllerGetConfiguration', 'projectId', projectId);
            const localVarPath = `/api/v1/project/{projectId}/configuration`.replace(
                `{${'projectId'}}`,
                encodeURIComponent(String(projectId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} projectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectControllerGetImage: async (
            projectId: number,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectControllerGetImage', 'projectId', projectId);
            const localVarPath = `/api/v1/project/{projectId}/image`.replace(
                `{${'projectId'}}`,
                encodeURIComponent(String(projectId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [groupName]
         * @param {string} [name]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectControllerGetProjects: async (
            groupName?: string,
            name?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/project`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            if (groupName !== undefined) {
                localVarQueryParameter['groupName'] = groupName;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} projectId
         * @param {GetZoneBboxDto} getZoneBboxDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectControllerGetZoneBbox: async (
            projectId: number,
            getZoneBboxDto: GetZoneBboxDto,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectControllerGetZoneBbox', 'projectId', projectId);
            // verify required parameter 'getZoneBboxDto' is not null or undefined
            assertParamExists('projectControllerGetZoneBbox', 'getZoneBboxDto', getZoneBboxDto);
            const localVarPath = `/api/v1/project/{projectId}/zone-bbox`.replace(
                `{${'projectId'}}`,
                encodeURIComponent(String(projectId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(getZoneBboxDto, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} projectId
         * @param {GetZoneEnvelopeDto} getZoneEnvelopeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectControllerGetZoneEnvelope: async (
            projectId: number,
            getZoneEnvelopeDto: GetZoneEnvelopeDto,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectControllerGetZoneEnvelope', 'projectId', projectId);
            // verify required parameter 'getZoneEnvelopeDto' is not null or undefined
            assertParamExists('projectControllerGetZoneEnvelope', 'getZoneEnvelopeDto', getZoneEnvelopeDto);
            const localVarPath = `/api/v1/project/{projectId}/zone-envelope`.replace(
                `{${'projectId'}}`,
                encodeURIComponent(String(projectId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                getZoneEnvelopeDto,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} projectId
         * @param {Array<number>} [bbox]
         * @param {File} [img]
         * @param {number} [domain]
         * @param {string} [name]
         * @param {number} [selectionLayer]
         * @param {string} [zone]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectControllerUpdate: async (
            projectId: number,
            bbox?: Array<number>,
            img?: File,
            domain?: number,
            name?: string,
            selectionLayer?: number,
            zone?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectControllerUpdate', 'projectId', projectId);
            const localVarPath = `/api/v1/project/{projectId}`.replace(
                `{${'projectId'}}`,
                encodeURIComponent(String(projectId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            if (bbox) {
                localVarFormParams.append('bbox', bbox.join(COLLECTION_FORMATS.csv));
            }

            if (img !== undefined) {
                localVarFormParams.append('img', img as any);
            }

            if (domain !== undefined) {
                localVarFormParams.append('domain', domain as any);
            }

            if (name !== undefined) {
                localVarFormParams.append('name', name as any);
            }

            if (selectionLayer !== undefined) {
                localVarFormParams.append('selectionLayer', selectionLayer as any);
            }

            if (zone !== undefined) {
                localVarFormParams.append('zone', zone as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * ProjectApi - functional programming interface
 * @export
 */
export const ProjectApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {number} domain
         * @param {string} name
         * @param {Array<number>} [bbox]
         * @param {File} [img]
         * @param {number} [selectionLayer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectControllerCreate(
            domain: number,
            name: string,
            bbox?: Array<number>,
            img?: File,
            selectionLayer?: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectControllerCreate(
                domain,
                name,
                bbox,
                img,
                selectionLayer,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} projectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectControllerDelete(
            projectId: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectControllerDelete(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} projectId
         * @param {ProjectParamsDto} projectParamsDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectControllerExtractData(
            projectId: number,
            projectParamsDto: ProjectParamsDto,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectControllerExtractData(
                projectId,
                projectParamsDto,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} projectId
         * @param {ImagesDumpDto} imagesDumpDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectControllerGenerateProjectImageDump(
            projectId: number,
            imagesDumpDto: ImagesDumpDto,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectControllerGenerateProjectImageDump(
                projectId,
                imagesDumpDto,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} projectId
         * @param {SearchDumpDto} searchDumpDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectControllerGenerateProjectSearchDump(
            projectId: number,
            searchDumpDto: SearchDumpDto,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectControllerGenerateProjectSearchDump(
                projectId,
                searchDumpDto,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} projectId
         * @param {GetBaseMapDto} getBaseMapDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectControllerGetBaseMap(
            projectId: number,
            getBaseMapDto: GetBaseMapDto,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectControllerGetBaseMap(
                projectId,
                getBaseMapDto,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} projectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectControllerGetById(
            projectId: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectControllerGetById(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} projectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectControllerGetConfiguration(
            projectId: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectConfigurationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectControllerGetConfiguration(
                projectId,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} projectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectControllerGetImage(
            projectId: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectControllerGetImage(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} [groupName]
         * @param {string} [name]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectControllerGetProjects(
            groupName?: string,
            name?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Project>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectControllerGetProjects(
                groupName,
                name,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} projectId
         * @param {GetZoneBboxDto} getZoneBboxDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectControllerGetZoneBbox(
            projectId: number,
            getZoneBboxDto: GetZoneBboxDto,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectControllerGetZoneBbox(
                projectId,
                getZoneBboxDto,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} projectId
         * @param {GetZoneEnvelopeDto} getZoneEnvelopeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectControllerGetZoneEnvelope(
            projectId: number,
            getZoneEnvelopeDto: GetZoneEnvelopeDto,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectControllerGetZoneEnvelope(
                projectId,
                getZoneEnvelopeDto,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} projectId
         * @param {Array<number>} [bbox]
         * @param {File} [img]
         * @param {number} [domain]
         * @param {string} [name]
         * @param {number} [selectionLayer]
         * @param {string} [zone]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectControllerUpdate(
            projectId: number,
            bbox?: Array<number>,
            img?: File,
            domain?: number,
            name?: string,
            selectionLayer?: number,
            zone?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectControllerUpdate(
                projectId,
                bbox,
                img,
                domain,
                name,
                selectionLayer,
                zone,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * ProjectApi - factory interface
 * @export
 */
export const ProjectApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectApiFp(configuration);
    return {
        /**
         *
         * @param {number} domain
         * @param {string} name
         * @param {Array<number>} [bbox]
         * @param {File} [img]
         * @param {number} [selectionLayer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectControllerCreate(
            domain: number,
            name: string,
            bbox?: Array<number>,
            img?: File,
            selectionLayer?: number,
            options?: any,
        ): AxiosPromise<Project> {
            return localVarFp
                .projectControllerCreate(domain, name, bbox, img, selectionLayer, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} projectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectControllerDelete(projectId: number, options?: any): AxiosPromise<void> {
            return localVarFp.projectControllerDelete(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} projectId
         * @param {ProjectParamsDto} projectParamsDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectControllerExtractData(
            projectId: number,
            projectParamsDto: ProjectParamsDto,
            options?: any,
        ): AxiosPromise<File> {
            return localVarFp
                .projectControllerExtractData(projectId, projectParamsDto, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} projectId
         * @param {ImagesDumpDto} imagesDumpDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectControllerGenerateProjectImageDump(
            projectId: number,
            imagesDumpDto: ImagesDumpDto,
            options?: any,
        ): AxiosPromise<File> {
            return localVarFp
                .projectControllerGenerateProjectImageDump(projectId, imagesDumpDto, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} projectId
         * @param {SearchDumpDto} searchDumpDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectControllerGenerateProjectSearchDump(
            projectId: number,
            searchDumpDto: SearchDumpDto,
            options?: any,
        ): AxiosPromise<File> {
            return localVarFp
                .projectControllerGenerateProjectSearchDump(projectId, searchDumpDto, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} projectId
         * @param {GetBaseMapDto} getBaseMapDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectControllerGetBaseMap(
            projectId: number,
            getBaseMapDto: GetBaseMapDto,
            options?: any,
        ): AxiosPromise<File> {
            return localVarFp
                .projectControllerGetBaseMap(projectId, getBaseMapDto, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} projectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectControllerGetById(projectId: number, options?: any): AxiosPromise<Project> {
            return localVarFp.projectControllerGetById(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} projectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectControllerGetConfiguration(projectId: number, options?: any): AxiosPromise<ProjectConfigurationDto> {
            return localVarFp
                .projectControllerGetConfiguration(projectId, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} projectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectControllerGetImage(projectId: number, options?: any): AxiosPromise<string> {
            return localVarFp.projectControllerGetImage(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} [groupName]
         * @param {string} [name]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectControllerGetProjects(groupName?: string, name?: string, options?: any): AxiosPromise<Array<Project>> {
            return localVarFp
                .projectControllerGetProjects(groupName, name, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} projectId
         * @param {GetZoneBboxDto} getZoneBboxDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectControllerGetZoneBbox(
            projectId: number,
            getZoneBboxDto: GetZoneBboxDto,
            options?: any,
        ): AxiosPromise<Array<number>> {
            return localVarFp
                .projectControllerGetZoneBbox(projectId, getZoneBboxDto, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} projectId
         * @param {GetZoneEnvelopeDto} getZoneEnvelopeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectControllerGetZoneEnvelope(
            projectId: number,
            getZoneEnvelopeDto: GetZoneEnvelopeDto,
            options?: any,
        ): AxiosPromise<string> {
            return localVarFp
                .projectControllerGetZoneEnvelope(projectId, getZoneEnvelopeDto, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} projectId
         * @param {Array<number>} [bbox]
         * @param {File} [img]
         * @param {number} [domain]
         * @param {string} [name]
         * @param {number} [selectionLayer]
         * @param {string} [zone]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectControllerUpdate(
            projectId: number,
            bbox?: Array<number>,
            img?: File,
            domain?: number,
            name?: string,
            selectionLayer?: number,
            zone?: string,
            options?: any,
        ): AxiosPromise<Project> {
            return localVarFp
                .projectControllerUpdate(projectId, bbox, img, domain, name, selectionLayer, zone, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectApi - interface
 * @export
 * @interface ProjectApi
 */
export interface ProjectApiInterface {
    /**
     *
     * @param {number} domain
     * @param {string} name
     * @param {Array<number>} [bbox]
     * @param {File} [img]
     * @param {number} [selectionLayer]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApiInterface
     */
    projectControllerCreate(
        domain: number,
        name: string,
        bbox?: Array<number>,
        img?: File,
        selectionLayer?: number,
        options?: AxiosRequestConfig,
    ): AxiosPromise<Project>;

    /**
     *
     * @param {number} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApiInterface
     */
    projectControllerDelete(projectId: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     *
     * @param {number} projectId
     * @param {ProjectParamsDto} projectParamsDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApiInterface
     */
    projectControllerExtractData(
        projectId: number,
        projectParamsDto: ProjectParamsDto,
        options?: AxiosRequestConfig,
    ): AxiosPromise<File>;

    /**
     *
     * @param {number} projectId
     * @param {ImagesDumpDto} imagesDumpDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApiInterface
     */
    projectControllerGenerateProjectImageDump(
        projectId: number,
        imagesDumpDto: ImagesDumpDto,
        options?: AxiosRequestConfig,
    ): AxiosPromise<File>;

    /**
     *
     * @param {number} projectId
     * @param {SearchDumpDto} searchDumpDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApiInterface
     */
    projectControllerGenerateProjectSearchDump(
        projectId: number,
        searchDumpDto: SearchDumpDto,
        options?: AxiosRequestConfig,
    ): AxiosPromise<File>;

    /**
     *
     * @param {number} projectId
     * @param {GetBaseMapDto} getBaseMapDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApiInterface
     */
    projectControllerGetBaseMap(
        projectId: number,
        getBaseMapDto: GetBaseMapDto,
        options?: AxiosRequestConfig,
    ): AxiosPromise<File>;

    /**
     *
     * @param {number} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApiInterface
     */
    projectControllerGetById(projectId: number, options?: AxiosRequestConfig): AxiosPromise<Project>;

    /**
     *
     * @param {number} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApiInterface
     */
    projectControllerGetConfiguration(
        projectId: number,
        options?: AxiosRequestConfig,
    ): AxiosPromise<ProjectConfigurationDto>;

    /**
     *
     * @param {number} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApiInterface
     */
    projectControllerGetImage(projectId: number, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     *
     * @param {string} [groupName]
     * @param {string} [name]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApiInterface
     */
    projectControllerGetProjects(
        groupName?: string,
        name?: string,
        options?: AxiosRequestConfig,
    ): AxiosPromise<Array<Project>>;

    /**
     *
     * @param {number} projectId
     * @param {GetZoneBboxDto} getZoneBboxDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApiInterface
     */
    projectControllerGetZoneBbox(
        projectId: number,
        getZoneBboxDto: GetZoneBboxDto,
        options?: AxiosRequestConfig,
    ): AxiosPromise<Array<number>>;

    /**
     *
     * @param {number} projectId
     * @param {GetZoneEnvelopeDto} getZoneEnvelopeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApiInterface
     */
    projectControllerGetZoneEnvelope(
        projectId: number,
        getZoneEnvelopeDto: GetZoneEnvelopeDto,
        options?: AxiosRequestConfig,
    ): AxiosPromise<string>;

    /**
     *
     * @param {number} projectId
     * @param {Array<number>} [bbox]
     * @param {File} [img]
     * @param {number} [domain]
     * @param {string} [name]
     * @param {number} [selectionLayer]
     * @param {string} [zone]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApiInterface
     */
    projectControllerUpdate(
        projectId: number,
        bbox?: Array<number>,
        img?: File,
        domain?: number,
        name?: string,
        selectionLayer?: number,
        zone?: string,
        options?: AxiosRequestConfig,
    ): AxiosPromise<Project>;
}

/**
 * Request parameters for projectControllerCreate operation in ProjectApi.
 * @export
 * @interface ProjectApiProjectControllerCreateRequest
 */
export interface ProjectApiProjectControllerCreateRequest {
    /**
     *
     * @type {number}
     * @memberof ProjectApiProjectControllerCreate
     */
    readonly domain: number;

    /**
     *
     * @type {string}
     * @memberof ProjectApiProjectControllerCreate
     */
    readonly name: string;

    /**
     *
     * @type {Array<number>}
     * @memberof ProjectApiProjectControllerCreate
     */
    readonly bbox?: Array<number>;

    /**
     *
     * @type {File}
     * @memberof ProjectApiProjectControllerCreate
     */
    readonly img?: File;

    /**
     *
     * @type {number}
     * @memberof ProjectApiProjectControllerCreate
     */
    readonly selectionLayer?: number;
}

/**
 * Request parameters for projectControllerDelete operation in ProjectApi.
 * @export
 * @interface ProjectApiProjectControllerDeleteRequest
 */
export interface ProjectApiProjectControllerDeleteRequest {
    /**
     *
     * @type {number}
     * @memberof ProjectApiProjectControllerDelete
     */
    readonly projectId: number;
}

/**
 * Request parameters for projectControllerExtractData operation in ProjectApi.
 * @export
 * @interface ProjectApiProjectControllerExtractDataRequest
 */
export interface ProjectApiProjectControllerExtractDataRequest {
    /**
     *
     * @type {number}
     * @memberof ProjectApiProjectControllerExtractData
     */
    readonly projectId: number;

    /**
     *
     * @type {ProjectParamsDto}
     * @memberof ProjectApiProjectControllerExtractData
     */
    readonly projectParamsDto: ProjectParamsDto;
}

/**
 * Request parameters for projectControllerGenerateProjectImageDump operation in ProjectApi.
 * @export
 * @interface ProjectApiProjectControllerGenerateProjectImageDumpRequest
 */
export interface ProjectApiProjectControllerGenerateProjectImageDumpRequest {
    /**
     *
     * @type {number}
     * @memberof ProjectApiProjectControllerGenerateProjectImageDump
     */
    readonly projectId: number;

    /**
     *
     * @type {ImagesDumpDto}
     * @memberof ProjectApiProjectControllerGenerateProjectImageDump
     */
    readonly imagesDumpDto: ImagesDumpDto;
}

/**
 * Request parameters for projectControllerGenerateProjectSearchDump operation in ProjectApi.
 * @export
 * @interface ProjectApiProjectControllerGenerateProjectSearchDumpRequest
 */
export interface ProjectApiProjectControllerGenerateProjectSearchDumpRequest {
    /**
     *
     * @type {number}
     * @memberof ProjectApiProjectControllerGenerateProjectSearchDump
     */
    readonly projectId: number;

    /**
     *
     * @type {SearchDumpDto}
     * @memberof ProjectApiProjectControllerGenerateProjectSearchDump
     */
    readonly searchDumpDto: SearchDumpDto;
}

/**
 * Request parameters for projectControllerGetBaseMap operation in ProjectApi.
 * @export
 * @interface ProjectApiProjectControllerGetBaseMapRequest
 */
export interface ProjectApiProjectControllerGetBaseMapRequest {
    /**
     *
     * @type {number}
     * @memberof ProjectApiProjectControllerGetBaseMap
     */
    readonly projectId: number;

    /**
     *
     * @type {GetBaseMapDto}
     * @memberof ProjectApiProjectControllerGetBaseMap
     */
    readonly getBaseMapDto: GetBaseMapDto;
}

/**
 * Request parameters for projectControllerGetById operation in ProjectApi.
 * @export
 * @interface ProjectApiProjectControllerGetByIdRequest
 */
export interface ProjectApiProjectControllerGetByIdRequest {
    /**
     *
     * @type {number}
     * @memberof ProjectApiProjectControllerGetById
     */
    readonly projectId: number;
}

/**
 * Request parameters for projectControllerGetConfiguration operation in ProjectApi.
 * @export
 * @interface ProjectApiProjectControllerGetConfigurationRequest
 */
export interface ProjectApiProjectControllerGetConfigurationRequest {
    /**
     *
     * @type {number}
     * @memberof ProjectApiProjectControllerGetConfiguration
     */
    readonly projectId: number;
}

/**
 * Request parameters for projectControllerGetImage operation in ProjectApi.
 * @export
 * @interface ProjectApiProjectControllerGetImageRequest
 */
export interface ProjectApiProjectControllerGetImageRequest {
    /**
     *
     * @type {number}
     * @memberof ProjectApiProjectControllerGetImage
     */
    readonly projectId: number;
}

/**
 * Request parameters for projectControllerGetProjects operation in ProjectApi.
 * @export
 * @interface ProjectApiProjectControllerGetProjectsRequest
 */
export interface ProjectApiProjectControllerGetProjectsRequest {
    /**
     *
     * @type {string}
     * @memberof ProjectApiProjectControllerGetProjects
     */
    readonly groupName?: string;

    /**
     *
     * @type {string}
     * @memberof ProjectApiProjectControllerGetProjects
     */
    readonly name?: string;
}

/**
 * Request parameters for projectControllerGetZoneBbox operation in ProjectApi.
 * @export
 * @interface ProjectApiProjectControllerGetZoneBboxRequest
 */
export interface ProjectApiProjectControllerGetZoneBboxRequest {
    /**
     *
     * @type {number}
     * @memberof ProjectApiProjectControllerGetZoneBbox
     */
    readonly projectId: number;

    /**
     *
     * @type {GetZoneBboxDto}
     * @memberof ProjectApiProjectControllerGetZoneBbox
     */
    readonly getZoneBboxDto: GetZoneBboxDto;
}

/**
 * Request parameters for projectControllerGetZoneEnvelope operation in ProjectApi.
 * @export
 * @interface ProjectApiProjectControllerGetZoneEnvelopeRequest
 */
export interface ProjectApiProjectControllerGetZoneEnvelopeRequest {
    /**
     *
     * @type {number}
     * @memberof ProjectApiProjectControllerGetZoneEnvelope
     */
    readonly projectId: number;

    /**
     *
     * @type {GetZoneEnvelopeDto}
     * @memberof ProjectApiProjectControllerGetZoneEnvelope
     */
    readonly getZoneEnvelopeDto: GetZoneEnvelopeDto;
}

/**
 * Request parameters for projectControllerUpdate operation in ProjectApi.
 * @export
 * @interface ProjectApiProjectControllerUpdateRequest
 */
export interface ProjectApiProjectControllerUpdateRequest {
    /**
     *
     * @type {number}
     * @memberof ProjectApiProjectControllerUpdate
     */
    readonly projectId: number;

    /**
     *
     * @type {Array<number>}
     * @memberof ProjectApiProjectControllerUpdate
     */
    readonly bbox?: Array<number>;

    /**
     *
     * @type {File}
     * @memberof ProjectApiProjectControllerUpdate
     */
    readonly img?: File;

    /**
     *
     * @type {number}
     * @memberof ProjectApiProjectControllerUpdate
     */
    readonly domain?: number;

    /**
     *
     * @type {string}
     * @memberof ProjectApiProjectControllerUpdate
     */
    readonly name?: string;

    /**
     *
     * @type {number}
     * @memberof ProjectApiProjectControllerUpdate
     */
    readonly selectionLayer?: number;

    /**
     *
     * @type {string}
     * @memberof ProjectApiProjectControllerUpdate
     */
    readonly zone?: string;
}

/**
 * ProjectApi - object-oriented interface
 * @export
 * @class ProjectApi
 * @extends {BaseAPI}
 */
export class ProjectApi extends BaseAPI implements ProjectApiInterface {
    /**
     *
     * @param {ProjectApiProjectControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectControllerCreate(
        requestParameters: ProjectApiProjectControllerCreateRequest,
        options?: AxiosRequestConfig,
    ) {
        return ProjectApiFp(this.configuration)
            .projectControllerCreate(
                requestParameters.domain,
                requestParameters.name,
                requestParameters.bbox,
                requestParameters.img,
                requestParameters.selectionLayer,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {ProjectApiProjectControllerDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectControllerDelete(
        requestParameters: ProjectApiProjectControllerDeleteRequest,
        options?: AxiosRequestConfig,
    ) {
        return ProjectApiFp(this.configuration)
            .projectControllerDelete(requestParameters.projectId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {ProjectApiProjectControllerExtractDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectControllerExtractData(
        requestParameters: ProjectApiProjectControllerExtractDataRequest,
        options?: AxiosRequestConfig,
    ) {
        return ProjectApiFp(this.configuration)
            .projectControllerExtractData(requestParameters.projectId, requestParameters.projectParamsDto, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {ProjectApiProjectControllerGenerateProjectImageDumpRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectControllerGenerateProjectImageDump(
        requestParameters: ProjectApiProjectControllerGenerateProjectImageDumpRequest,
        options?: AxiosRequestConfig,
    ) {
        return ProjectApiFp(this.configuration)
            .projectControllerGenerateProjectImageDump(
                requestParameters.projectId,
                requestParameters.imagesDumpDto,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {ProjectApiProjectControllerGenerateProjectSearchDumpRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectControllerGenerateProjectSearchDump(
        requestParameters: ProjectApiProjectControllerGenerateProjectSearchDumpRequest,
        options?: AxiosRequestConfig,
    ) {
        return ProjectApiFp(this.configuration)
            .projectControllerGenerateProjectSearchDump(
                requestParameters.projectId,
                requestParameters.searchDumpDto,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {ProjectApiProjectControllerGetBaseMapRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectControllerGetBaseMap(
        requestParameters: ProjectApiProjectControllerGetBaseMapRequest,
        options?: AxiosRequestConfig,
    ) {
        return ProjectApiFp(this.configuration)
            .projectControllerGetBaseMap(requestParameters.projectId, requestParameters.getBaseMapDto, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {ProjectApiProjectControllerGetByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectControllerGetById(
        requestParameters: ProjectApiProjectControllerGetByIdRequest,
        options?: AxiosRequestConfig,
    ) {
        return ProjectApiFp(this.configuration)
            .projectControllerGetById(requestParameters.projectId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {ProjectApiProjectControllerGetConfigurationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectControllerGetConfiguration(
        requestParameters: ProjectApiProjectControllerGetConfigurationRequest,
        options?: AxiosRequestConfig,
    ) {
        return ProjectApiFp(this.configuration)
            .projectControllerGetConfiguration(requestParameters.projectId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {ProjectApiProjectControllerGetImageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectControllerGetImage(
        requestParameters: ProjectApiProjectControllerGetImageRequest,
        options?: AxiosRequestConfig,
    ) {
        return ProjectApiFp(this.configuration)
            .projectControllerGetImage(requestParameters.projectId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {ProjectApiProjectControllerGetProjectsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectControllerGetProjects(
        requestParameters: ProjectApiProjectControllerGetProjectsRequest = {},
        options?: AxiosRequestConfig,
    ) {
        return ProjectApiFp(this.configuration)
            .projectControllerGetProjects(requestParameters.groupName, requestParameters.name, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {ProjectApiProjectControllerGetZoneBboxRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectControllerGetZoneBbox(
        requestParameters: ProjectApiProjectControllerGetZoneBboxRequest,
        options?: AxiosRequestConfig,
    ) {
        return ProjectApiFp(this.configuration)
            .projectControllerGetZoneBbox(requestParameters.projectId, requestParameters.getZoneBboxDto, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {ProjectApiProjectControllerGetZoneEnvelopeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectControllerGetZoneEnvelope(
        requestParameters: ProjectApiProjectControllerGetZoneEnvelopeRequest,
        options?: AxiosRequestConfig,
    ) {
        return ProjectApiFp(this.configuration)
            .projectControllerGetZoneEnvelope(
                requestParameters.projectId,
                requestParameters.getZoneEnvelopeDto,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {ProjectApiProjectControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectControllerUpdate(
        requestParameters: ProjectApiProjectControllerUpdateRequest,
        options?: AxiosRequestConfig,
    ) {
        return ProjectApiFp(this.configuration)
            .projectControllerUpdate(
                requestParameters.projectId,
                requestParameters.bbox,
                requestParameters.img,
                requestParameters.domain,
                requestParameters.name,
                requestParameters.selectionLayer,
                requestParameters.zone,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * RoleApi - axios parameter creator
 * @export
 */
export const RoleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {CreateRoleDto} createRoleDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerCreate: async (
            createRoleDto: CreateRoleDto,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'createRoleDto' is not null or undefined
            assertParamExists('roleControllerCreate', 'createRoleDto', createRoleDto);
            const localVarPath = `/api/v1/role`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(createRoleDto, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} roleId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerDelete: async (roleId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('roleControllerDelete', 'roleId', roleId);
            const localVarPath = `/api/v1/role/{roleId}`.replace(`{${'roleId'}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} roleId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerGetById: async (roleId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('roleControllerGetById', 'roleId', roleId);
            const localVarPath = `/api/v1/role/{roleId}`.replace(`{${'roleId'}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerGetRoles: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/role`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} roleId
         * @param {UpdateRoleDto} updateRoleDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerUpdate: async (
            roleId: number,
            updateRoleDto: UpdateRoleDto,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('roleControllerUpdate', 'roleId', roleId);
            // verify required parameter 'updateRoleDto' is not null or undefined
            assertParamExists('roleControllerUpdate', 'updateRoleDto', updateRoleDto);
            const localVarPath = `/api/v1/role/{roleId}`.replace(`{${'roleId'}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(updateRoleDto, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * RoleApi - functional programming interface
 * @export
 */
export const RoleApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = RoleApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {CreateRoleDto} createRoleDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roleControllerCreate(
            createRoleDto: CreateRoleDto,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Role>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roleControllerCreate(createRoleDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} roleId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roleControllerDelete(
            roleId: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roleControllerDelete(roleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} roleId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roleControllerGetById(
            roleId: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Role>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roleControllerGetById(roleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roleControllerGetRoles(
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Role>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roleControllerGetRoles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} roleId
         * @param {UpdateRoleDto} updateRoleDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roleControllerUpdate(
            roleId: number,
            updateRoleDto: UpdateRoleDto,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Role>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roleControllerUpdate(
                roleId,
                updateRoleDto,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * RoleApi - factory interface
 * @export
 */
export const RoleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoleApiFp(configuration);
    return {
        /**
         *
         * @param {CreateRoleDto} createRoleDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerCreate(createRoleDto: CreateRoleDto, options?: any): AxiosPromise<Role> {
            return localVarFp.roleControllerCreate(createRoleDto, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} roleId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerDelete(roleId: number, options?: any): AxiosPromise<void> {
            return localVarFp.roleControllerDelete(roleId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} roleId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerGetById(roleId: number, options?: any): AxiosPromise<Role> {
            return localVarFp.roleControllerGetById(roleId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerGetRoles(options?: any): AxiosPromise<Array<Role>> {
            return localVarFp.roleControllerGetRoles(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} roleId
         * @param {UpdateRoleDto} updateRoleDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerUpdate(roleId: number, updateRoleDto: UpdateRoleDto, options?: any): AxiosPromise<Role> {
            return localVarFp
                .roleControllerUpdate(roleId, updateRoleDto, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoleApi - interface
 * @export
 * @interface RoleApi
 */
export interface RoleApiInterface {
    /**
     *
     * @param {CreateRoleDto} createRoleDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApiInterface
     */
    roleControllerCreate(createRoleDto: CreateRoleDto, options?: AxiosRequestConfig): AxiosPromise<Role>;

    /**
     *
     * @param {number} roleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApiInterface
     */
    roleControllerDelete(roleId: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     *
     * @param {number} roleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApiInterface
     */
    roleControllerGetById(roleId: number, options?: AxiosRequestConfig): AxiosPromise<Role>;

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApiInterface
     */
    roleControllerGetRoles(options?: AxiosRequestConfig): AxiosPromise<Array<Role>>;

    /**
     *
     * @param {number} roleId
     * @param {UpdateRoleDto} updateRoleDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApiInterface
     */
    roleControllerUpdate(
        roleId: number,
        updateRoleDto: UpdateRoleDto,
        options?: AxiosRequestConfig,
    ): AxiosPromise<Role>;
}

/**
 * Request parameters for roleControllerCreate operation in RoleApi.
 * @export
 * @interface RoleApiRoleControllerCreateRequest
 */
export interface RoleApiRoleControllerCreateRequest {
    /**
     *
     * @type {CreateRoleDto}
     * @memberof RoleApiRoleControllerCreate
     */
    readonly createRoleDto: CreateRoleDto;
}

/**
 * Request parameters for roleControllerDelete operation in RoleApi.
 * @export
 * @interface RoleApiRoleControllerDeleteRequest
 */
export interface RoleApiRoleControllerDeleteRequest {
    /**
     *
     * @type {number}
     * @memberof RoleApiRoleControllerDelete
     */
    readonly roleId: number;
}

/**
 * Request parameters for roleControllerGetById operation in RoleApi.
 * @export
 * @interface RoleApiRoleControllerGetByIdRequest
 */
export interface RoleApiRoleControllerGetByIdRequest {
    /**
     *
     * @type {number}
     * @memberof RoleApiRoleControllerGetById
     */
    readonly roleId: number;
}

/**
 * Request parameters for roleControllerUpdate operation in RoleApi.
 * @export
 * @interface RoleApiRoleControllerUpdateRequest
 */
export interface RoleApiRoleControllerUpdateRequest {
    /**
     *
     * @type {number}
     * @memberof RoleApiRoleControllerUpdate
     */
    readonly roleId: number;

    /**
     *
     * @type {UpdateRoleDto}
     * @memberof RoleApiRoleControllerUpdate
     */
    readonly updateRoleDto: UpdateRoleDto;
}

/**
 * RoleApi - object-oriented interface
 * @export
 * @class RoleApi
 * @extends {BaseAPI}
 */
export class RoleApi extends BaseAPI implements RoleApiInterface {
    /**
     *
     * @param {RoleApiRoleControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public roleControllerCreate(requestParameters: RoleApiRoleControllerCreateRequest, options?: AxiosRequestConfig) {
        return RoleApiFp(this.configuration)
            .roleControllerCreate(requestParameters.createRoleDto, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {RoleApiRoleControllerDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public roleControllerDelete(requestParameters: RoleApiRoleControllerDeleteRequest, options?: AxiosRequestConfig) {
        return RoleApiFp(this.configuration)
            .roleControllerDelete(requestParameters.roleId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {RoleApiRoleControllerGetByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public roleControllerGetById(requestParameters: RoleApiRoleControllerGetByIdRequest, options?: AxiosRequestConfig) {
        return RoleApiFp(this.configuration)
            .roleControllerGetById(requestParameters.roleId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public roleControllerGetRoles(options?: AxiosRequestConfig) {
        return RoleApiFp(this.configuration)
            .roleControllerGetRoles(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {RoleApiRoleControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public roleControllerUpdate(requestParameters: RoleApiRoleControllerUpdateRequest, options?: AxiosRequestConfig) {
        return RoleApiFp(this.configuration)
            .roleControllerUpdate(requestParameters.roleId, requestParameters.updateRoleDto, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * StyleApi - axios parameter creator
 * @export
 */
export const StyleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {CreateStyleDto} createStyleDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        styleControllerCreate: async (
            createStyleDto: CreateStyleDto,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'createStyleDto' is not null or undefined
            assertParamExists('styleControllerCreate', 'createStyleDto', createStyleDto);
            const localVarPath = `/api/v1/style`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(createStyleDto, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} styleId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        styleControllerDelete: async (styleId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'styleId' is not null or undefined
            assertParamExists('styleControllerDelete', 'styleId', styleId);
            const localVarPath = `/api/v1/style/{styleId}`.replace(
                `{${'styleId'}}`,
                encodeURIComponent(String(styleId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} styleId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        styleControllerGetById: async (styleId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'styleId' is not null or undefined
            assertParamExists('styleControllerGetById', 'styleId', styleId);
            const localVarPath = `/api/v1/style/{styleId}`.replace(
                `{${'styleId'}}`,
                encodeURIComponent(String(styleId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} styleId
         * @param {UpdateStyleDto} updateStyleDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        styleControllerUpdate: async (
            styleId: number,
            updateStyleDto: UpdateStyleDto,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'styleId' is not null or undefined
            assertParamExists('styleControllerUpdate', 'styleId', styleId);
            // verify required parameter 'updateStyleDto' is not null or undefined
            assertParamExists('styleControllerUpdate', 'updateStyleDto', updateStyleDto);
            const localVarPath = `/api/v1/style/{styleId}`.replace(
                `{${'styleId'}}`,
                encodeURIComponent(String(styleId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(updateStyleDto, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * StyleApi - functional programming interface
 * @export
 */
export const StyleApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = StyleApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {CreateStyleDto} createStyleDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async styleControllerCreate(
            createStyleDto: CreateStyleDto,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Style>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.styleControllerCreate(createStyleDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} styleId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async styleControllerDelete(
            styleId: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.styleControllerDelete(styleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} styleId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async styleControllerGetById(
            styleId: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Style>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.styleControllerGetById(styleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} styleId
         * @param {UpdateStyleDto} updateStyleDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async styleControllerUpdate(
            styleId: number,
            updateStyleDto: UpdateStyleDto,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Style>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.styleControllerUpdate(
                styleId,
                updateStyleDto,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * StyleApi - factory interface
 * @export
 */
export const StyleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StyleApiFp(configuration);
    return {
        /**
         *
         * @param {CreateStyleDto} createStyleDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        styleControllerCreate(createStyleDto: CreateStyleDto, options?: any): AxiosPromise<Style> {
            return localVarFp
                .styleControllerCreate(createStyleDto, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} styleId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        styleControllerDelete(styleId: number, options?: any): AxiosPromise<void> {
            return localVarFp.styleControllerDelete(styleId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} styleId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        styleControllerGetById(styleId: number, options?: any): AxiosPromise<Style> {
            return localVarFp.styleControllerGetById(styleId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} styleId
         * @param {UpdateStyleDto} updateStyleDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        styleControllerUpdate(styleId: number, updateStyleDto: UpdateStyleDto, options?: any): AxiosPromise<Style> {
            return localVarFp
                .styleControllerUpdate(styleId, updateStyleDto, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * StyleApi - interface
 * @export
 * @interface StyleApi
 */
export interface StyleApiInterface {
    /**
     *
     * @param {CreateStyleDto} createStyleDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StyleApiInterface
     */
    styleControllerCreate(createStyleDto: CreateStyleDto, options?: AxiosRequestConfig): AxiosPromise<Style>;

    /**
     *
     * @param {number} styleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StyleApiInterface
     */
    styleControllerDelete(styleId: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     *
     * @param {number} styleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StyleApiInterface
     */
    styleControllerGetById(styleId: number, options?: AxiosRequestConfig): AxiosPromise<Style>;

    /**
     *
     * @param {number} styleId
     * @param {UpdateStyleDto} updateStyleDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StyleApiInterface
     */
    styleControllerUpdate(
        styleId: number,
        updateStyleDto: UpdateStyleDto,
        options?: AxiosRequestConfig,
    ): AxiosPromise<Style>;
}

/**
 * Request parameters for styleControllerCreate operation in StyleApi.
 * @export
 * @interface StyleApiStyleControllerCreateRequest
 */
export interface StyleApiStyleControllerCreateRequest {
    /**
     *
     * @type {CreateStyleDto}
     * @memberof StyleApiStyleControllerCreate
     */
    readonly createStyleDto: CreateStyleDto;
}

/**
 * Request parameters for styleControllerDelete operation in StyleApi.
 * @export
 * @interface StyleApiStyleControllerDeleteRequest
 */
export interface StyleApiStyleControllerDeleteRequest {
    /**
     *
     * @type {number}
     * @memberof StyleApiStyleControllerDelete
     */
    readonly styleId: number;
}

/**
 * Request parameters for styleControllerGetById operation in StyleApi.
 * @export
 * @interface StyleApiStyleControllerGetByIdRequest
 */
export interface StyleApiStyleControllerGetByIdRequest {
    /**
     *
     * @type {number}
     * @memberof StyleApiStyleControllerGetById
     */
    readonly styleId: number;
}

/**
 * Request parameters for styleControllerUpdate operation in StyleApi.
 * @export
 * @interface StyleApiStyleControllerUpdateRequest
 */
export interface StyleApiStyleControllerUpdateRequest {
    /**
     *
     * @type {number}
     * @memberof StyleApiStyleControllerUpdate
     */
    readonly styleId: number;

    /**
     *
     * @type {UpdateStyleDto}
     * @memberof StyleApiStyleControllerUpdate
     */
    readonly updateStyleDto: UpdateStyleDto;
}

/**
 * StyleApi - object-oriented interface
 * @export
 * @class StyleApi
 * @extends {BaseAPI}
 */
export class StyleApi extends BaseAPI implements StyleApiInterface {
    /**
     *
     * @param {StyleApiStyleControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StyleApi
     */
    public styleControllerCreate(
        requestParameters: StyleApiStyleControllerCreateRequest,
        options?: AxiosRequestConfig,
    ) {
        return StyleApiFp(this.configuration)
            .styleControllerCreate(requestParameters.createStyleDto, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {StyleApiStyleControllerDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StyleApi
     */
    public styleControllerDelete(
        requestParameters: StyleApiStyleControllerDeleteRequest,
        options?: AxiosRequestConfig,
    ) {
        return StyleApiFp(this.configuration)
            .styleControllerDelete(requestParameters.styleId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {StyleApiStyleControllerGetByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StyleApi
     */
    public styleControllerGetById(
        requestParameters: StyleApiStyleControllerGetByIdRequest,
        options?: AxiosRequestConfig,
    ) {
        return StyleApiFp(this.configuration)
            .styleControllerGetById(requestParameters.styleId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {StyleApiStyleControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StyleApi
     */
    public styleControllerUpdate(
        requestParameters: StyleApiStyleControllerUpdateRequest,
        options?: AxiosRequestConfig,
    ) {
        return StyleApiFp(this.configuration)
            .styleControllerUpdate(requestParameters.styleId, requestParameters.updateStyleDto, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {number} groupId
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerAddGroup: async (
            groupId: number,
            userId: number,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('userControllerAddGroup', 'groupId', groupId);
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userControllerAddGroup', 'userId', userId);
            const localVarPath = `/api/v1/user/{userId}/addGroup/{groupId}`
                .replace(`{${'groupId'}}`, encodeURIComponent(String(groupId)))
                .replace(`{${'userId'}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerDelete: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userControllerDelete', 'userId', userId);
            const localVarPath = `/api/v1/user/{userId}`.replace(`{${'userId'}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [email]
         * @param {string} [groupName]
         * @param {'BASIC' | 'LDAP'} [loginType]
         * @param {string} [name]
         * @param {string} [zone]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGet: async (
            email?: string,
            groupName?: string,
            loginType?: 'BASIC' | 'LDAP',
            name?: string,
            zone?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (groupName !== undefined) {
                localVarQueryParameter['groupName'] = groupName;
            }

            if (loginType !== undefined) {
                localVarQueryParameter['loginType'] = loginType;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (zone !== undefined) {
                localVarQueryParameter['zone'] = zone;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetGroups: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userControllerGetGroups', 'userId', userId);
            const localVarPath = `/api/v1/user/{userId}/groups`.replace(
                `{${'userId'}}`,
                encodeURIComponent(String(userId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerMe: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} userId
         * @param {UpdateUserDto} updateUserDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdate: async (
            userId: string,
            updateUserDto: UpdateUserDto,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userControllerUpdate', 'userId', userId);
            // verify required parameter 'updateUserDto' is not null or undefined
            assertParamExists('userControllerUpdate', 'updateUserDto', updateUserDto);
            const localVarPath = `/api/v1/user/{userId}`.replace(`{${'userId'}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserDto, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {number} groupId
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerAddGroup(
            groupId: number,
            userId: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerAddGroup(groupId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerDelete(
            userId: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerDelete(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} [email]
         * @param {string} [groupName]
         * @param {'BASIC' | 'LDAP'} [loginType]
         * @param {string} [name]
         * @param {string} [zone]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerGet(
            email?: string,
            groupName?: string,
            loginType?: 'BASIC' | 'LDAP',
            name?: string,
            zone?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGet(
                email,
                groupName,
                loginType,
                name,
                zone,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerGetGroups(
            userId: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGetGroups(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerMe(
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} userId
         * @param {UpdateUserDto} updateUserDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerUpdate(
            userId: string,
            updateUserDto: UpdateUserDto,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerUpdate(
                userId,
                updateUserDto,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration);
    return {
        /**
         *
         * @param {number} groupId
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerAddGroup(groupId: number, userId: number, options?: any): AxiosPromise<User> {
            return localVarFp
                .userControllerAddGroup(groupId, userId, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerDelete(userId: number, options?: any): AxiosPromise<void> {
            return localVarFp.userControllerDelete(userId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} [email]
         * @param {string} [groupName]
         * @param {'BASIC' | 'LDAP'} [loginType]
         * @param {string} [name]
         * @param {string} [zone]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGet(
            email?: string,
            groupName?: string,
            loginType?: 'BASIC' | 'LDAP',
            name?: string,
            zone?: string,
            options?: any,
        ): AxiosPromise<Array<User>> {
            return localVarFp
                .userControllerGet(email, groupName, loginType, name, zone, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetGroups(userId: number, options?: any): AxiosPromise<User> {
            return localVarFp.userControllerGetGroups(userId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerMe(options?: any): AxiosPromise<User> {
            return localVarFp.userControllerMe(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} userId
         * @param {UpdateUserDto} updateUserDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdate(userId: string, updateUserDto: UpdateUserDto, options?: any): AxiosPromise<User> {
            return localVarFp
                .userControllerUpdate(userId, updateUserDto, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - interface
 * @export
 * @interface UserApi
 */
export interface UserApiInterface {
    /**
     *
     * @param {number} groupId
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userControllerAddGroup(groupId: number, userId: number, options?: AxiosRequestConfig): AxiosPromise<User>;

    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userControllerDelete(userId: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     *
     * @param {string} [email]
     * @param {string} [groupName]
     * @param {'BASIC' | 'LDAP'} [loginType]
     * @param {string} [name]
     * @param {string} [zone]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userControllerGet(
        email?: string,
        groupName?: string,
        loginType?: 'BASIC' | 'LDAP',
        name?: string,
        zone?: string,
        options?: AxiosRequestConfig,
    ): AxiosPromise<Array<User>>;

    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userControllerGetGroups(userId: number, options?: AxiosRequestConfig): AxiosPromise<User>;

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userControllerMe(options?: AxiosRequestConfig): AxiosPromise<User>;

    /**
     *
     * @param {string} userId
     * @param {UpdateUserDto} updateUserDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userControllerUpdate(
        userId: string,
        updateUserDto: UpdateUserDto,
        options?: AxiosRequestConfig,
    ): AxiosPromise<User>;
}

/**
 * Request parameters for userControllerAddGroup operation in UserApi.
 * @export
 * @interface UserApiUserControllerAddGroupRequest
 */
export interface UserApiUserControllerAddGroupRequest {
    /**
     *
     * @type {number}
     * @memberof UserApiUserControllerAddGroup
     */
    readonly groupId: number;

    /**
     *
     * @type {number}
     * @memberof UserApiUserControllerAddGroup
     */
    readonly userId: number;
}

/**
 * Request parameters for userControllerDelete operation in UserApi.
 * @export
 * @interface UserApiUserControllerDeleteRequest
 */
export interface UserApiUserControllerDeleteRequest {
    /**
     *
     * @type {number}
     * @memberof UserApiUserControllerDelete
     */
    readonly userId: number;
}

/**
 * Request parameters for userControllerGet operation in UserApi.
 * @export
 * @interface UserApiUserControllerGetRequest
 */
export interface UserApiUserControllerGetRequest {
    /**
     *
     * @type {string}
     * @memberof UserApiUserControllerGet
     */
    readonly email?: string;

    /**
     *
     * @type {string}
     * @memberof UserApiUserControllerGet
     */
    readonly groupName?: string;

    /**
     *
     * @type {'BASIC' | 'LDAP'}
     * @memberof UserApiUserControllerGet
     */
    readonly loginType?: 'BASIC' | 'LDAP';

    /**
     *
     * @type {string}
     * @memberof UserApiUserControllerGet
     */
    readonly name?: string;

    /**
     *
     * @type {string}
     * @memberof UserApiUserControllerGet
     */
    readonly zone?: string;
}

/**
 * Request parameters for userControllerGetGroups operation in UserApi.
 * @export
 * @interface UserApiUserControllerGetGroupsRequest
 */
export interface UserApiUserControllerGetGroupsRequest {
    /**
     *
     * @type {number}
     * @memberof UserApiUserControllerGetGroups
     */
    readonly userId: number;
}

/**
 * Request parameters for userControllerUpdate operation in UserApi.
 * @export
 * @interface UserApiUserControllerUpdateRequest
 */
export interface UserApiUserControllerUpdateRequest {
    /**
     *
     * @type {string}
     * @memberof UserApiUserControllerUpdate
     */
    readonly userId: string;

    /**
     *
     * @type {UpdateUserDto}
     * @memberof UserApiUserControllerUpdate
     */
    readonly updateUserDto: UpdateUserDto;
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI implements UserApiInterface {
    /**
     *
     * @param {UserApiUserControllerAddGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerAddGroup(
        requestParameters: UserApiUserControllerAddGroupRequest,
        options?: AxiosRequestConfig,
    ) {
        return UserApiFp(this.configuration)
            .userControllerAddGroup(requestParameters.groupId, requestParameters.userId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {UserApiUserControllerDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerDelete(requestParameters: UserApiUserControllerDeleteRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration)
            .userControllerDelete(requestParameters.userId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {UserApiUserControllerGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerGet(requestParameters: UserApiUserControllerGetRequest = {}, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration)
            .userControllerGet(
                requestParameters.email,
                requestParameters.groupName,
                requestParameters.loginType,
                requestParameters.name,
                requestParameters.zone,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {UserApiUserControllerGetGroupsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerGetGroups(
        requestParameters: UserApiUserControllerGetGroupsRequest,
        options?: AxiosRequestConfig,
    ) {
        return UserApiFp(this.configuration)
            .userControllerGetGroups(requestParameters.userId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerMe(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration)
            .userControllerMe(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {UserApiUserControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerUpdate(requestParameters: UserApiUserControllerUpdateRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration)
            .userControllerUpdate(requestParameters.userId, requestParameters.updateUserDto, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * ZoneApi - axios parameter creator
 * @export
 */
export const ZoneApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneControllerDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('zoneControllerDelete', 'id', id);
            const localVarPath = `/api/v1/zone/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [name]
         * @param {number} [restrictedLayer]
         * @param {number} [user]
         * @param {number} [zoneLayer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneControllerGet: async (
            name?: string,
            restrictedLayer?: number,
            user?: number,
            zoneLayer?: number,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/zone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (restrictedLayer !== undefined) {
                localVarQueryParameter['restrictedLayer'] = restrictedLayer;
            }

            if (user !== undefined) {
                localVarQueryParameter['user'] = user;
            }

            if (zoneLayer !== undefined) {
                localVarQueryParameter['zoneLayer'] = zoneLayer;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneControllerGetOne: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('zoneControllerGetOne', 'id', id);
            const localVarPath = `/api/v1/zone/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneControllerGetZoneGeoJSON: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('zoneControllerGetZoneGeoJSON', 'id', id);
            const localVarPath = `/api/v1/zone/{id}/geojson`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {CreateZoneDto} createZoneDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneControllerPost: async (
            createZoneDto: CreateZoneDto,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'createZoneDto' is not null or undefined
            assertParamExists('zoneControllerPost', 'createZoneDto', createZoneDto);
            const localVarPath = `/api/v1/zone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(createZoneDto, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id
         * @param {UpdateZoneDto} updateZoneDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneControllerUpdate: async (
            id: number,
            updateZoneDto: UpdateZoneDto,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('zoneControllerUpdate', 'id', id);
            // verify required parameter 'updateZoneDto' is not null or undefined
            assertParamExists('zoneControllerUpdate', 'updateZoneDto', updateZoneDto);
            const localVarPath = `/api/v1/zone/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Api-Key', configuration);

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(updateZoneDto, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * ZoneApi - functional programming interface
 * @export
 */
export const ZoneApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = ZoneApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zoneControllerDelete(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zoneControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} [name]
         * @param {number} [restrictedLayer]
         * @param {number} [user]
         * @param {number} [zoneLayer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zoneControllerGet(
            name?: string,
            restrictedLayer?: number,
            user?: number,
            zoneLayer?: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Zone>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zoneControllerGet(
                name,
                restrictedLayer,
                user,
                zoneLayer,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zoneControllerGetOne(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Zone>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zoneControllerGetOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zoneControllerGetZoneGeoJSON(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeoJSONZoneGeoJSONPropertiesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zoneControllerGetZoneGeoJSON(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {CreateZoneDto} createZoneDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zoneControllerPost(
            createZoneDto: CreateZoneDto,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Zone>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zoneControllerPost(createZoneDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id
         * @param {UpdateZoneDto} updateZoneDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zoneControllerUpdate(
            id: number,
            updateZoneDto: UpdateZoneDto,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Zone>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zoneControllerUpdate(id, updateZoneDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * ZoneApi - factory interface
 * @export
 */
export const ZoneApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ZoneApiFp(configuration);
    return {
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneControllerDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.zoneControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} [name]
         * @param {number} [restrictedLayer]
         * @param {number} [user]
         * @param {number} [zoneLayer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneControllerGet(
            name?: string,
            restrictedLayer?: number,
            user?: number,
            zoneLayer?: number,
            options?: any,
        ): AxiosPromise<Array<Zone>> {
            return localVarFp
                .zoneControllerGet(name, restrictedLayer, user, zoneLayer, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneControllerGetOne(id: number, options?: any): AxiosPromise<Zone> {
            return localVarFp.zoneControllerGetOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneControllerGetZoneGeoJSON(id: number, options?: any): AxiosPromise<GeoJSONZoneGeoJSONPropertiesDto> {
            return localVarFp.zoneControllerGetZoneGeoJSON(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {CreateZoneDto} createZoneDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneControllerPost(createZoneDto: CreateZoneDto, options?: any): AxiosPromise<Zone> {
            return localVarFp.zoneControllerPost(createZoneDto, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id
         * @param {UpdateZoneDto} updateZoneDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneControllerUpdate(id: number, updateZoneDto: UpdateZoneDto, options?: any): AxiosPromise<Zone> {
            return localVarFp
                .zoneControllerUpdate(id, updateZoneDto, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * ZoneApi - interface
 * @export
 * @interface ZoneApi
 */
export interface ZoneApiInterface {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZoneApiInterface
     */
    zoneControllerDelete(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     *
     * @param {string} [name]
     * @param {number} [restrictedLayer]
     * @param {number} [user]
     * @param {number} [zoneLayer]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZoneApiInterface
     */
    zoneControllerGet(
        name?: string,
        restrictedLayer?: number,
        user?: number,
        zoneLayer?: number,
        options?: AxiosRequestConfig,
    ): AxiosPromise<Array<Zone>>;

    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZoneApiInterface
     */
    zoneControllerGetOne(id: number, options?: AxiosRequestConfig): AxiosPromise<Zone>;

    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZoneApiInterface
     */
    zoneControllerGetZoneGeoJSON(
        id: number,
        options?: AxiosRequestConfig,
    ): AxiosPromise<GeoJSONZoneGeoJSONPropertiesDto>;

    /**
     *
     * @param {CreateZoneDto} createZoneDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZoneApiInterface
     */
    zoneControllerPost(createZoneDto: CreateZoneDto, options?: AxiosRequestConfig): AxiosPromise<Zone>;

    /**
     *
     * @param {number} id
     * @param {UpdateZoneDto} updateZoneDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZoneApiInterface
     */
    zoneControllerUpdate(id: number, updateZoneDto: UpdateZoneDto, options?: AxiosRequestConfig): AxiosPromise<Zone>;
}

/**
 * Request parameters for zoneControllerDelete operation in ZoneApi.
 * @export
 * @interface ZoneApiZoneControllerDeleteRequest
 */
export interface ZoneApiZoneControllerDeleteRequest {
    /**
     *
     * @type {number}
     * @memberof ZoneApiZoneControllerDelete
     */
    readonly id: number;
}

/**
 * Request parameters for zoneControllerGet operation in ZoneApi.
 * @export
 * @interface ZoneApiZoneControllerGetRequest
 */
export interface ZoneApiZoneControllerGetRequest {
    /**
     *
     * @type {string}
     * @memberof ZoneApiZoneControllerGet
     */
    readonly name?: string;

    /**
     *
     * @type {number}
     * @memberof ZoneApiZoneControllerGet
     */
    readonly restrictedLayer?: number;

    /**
     *
     * @type {number}
     * @memberof ZoneApiZoneControllerGet
     */
    readonly user?: number;

    /**
     *
     * @type {number}
     * @memberof ZoneApiZoneControllerGet
     */
    readonly zoneLayer?: number;
}

/**
 * Request parameters for zoneControllerGetOne operation in ZoneApi.
 * @export
 * @interface ZoneApiZoneControllerGetOneRequest
 */
export interface ZoneApiZoneControllerGetOneRequest {
    /**
     *
     * @type {number}
     * @memberof ZoneApiZoneControllerGetOne
     */
    readonly id: number;
}

/**
 * Request parameters for zoneControllerGetZoneGeoJSON operation in ZoneApi.
 * @export
 * @interface ZoneApiZoneControllerGetZoneGeoJSONRequest
 */
export interface ZoneApiZoneControllerGetZoneGeoJSONRequest {
    /**
     *
     * @type {number}
     * @memberof ZoneApiZoneControllerGetZoneGeoJSON
     */
    readonly id: number;
}

/**
 * Request parameters for zoneControllerPost operation in ZoneApi.
 * @export
 * @interface ZoneApiZoneControllerPostRequest
 */
export interface ZoneApiZoneControllerPostRequest {
    /**
     *
     * @type {CreateZoneDto}
     * @memberof ZoneApiZoneControllerPost
     */
    readonly createZoneDto: CreateZoneDto;
}

/**
 * Request parameters for zoneControllerUpdate operation in ZoneApi.
 * @export
 * @interface ZoneApiZoneControllerUpdateRequest
 */
export interface ZoneApiZoneControllerUpdateRequest {
    /**
     *
     * @type {number}
     * @memberof ZoneApiZoneControllerUpdate
     */
    readonly id: number;

    /**
     *
     * @type {UpdateZoneDto}
     * @memberof ZoneApiZoneControllerUpdate
     */
    readonly updateZoneDto: UpdateZoneDto;
}

/**
 * ZoneApi - object-oriented interface
 * @export
 * @class ZoneApi
 * @extends {BaseAPI}
 */
export class ZoneApi extends BaseAPI implements ZoneApiInterface {
    /**
     *
     * @param {ZoneApiZoneControllerDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZoneApi
     */
    public zoneControllerDelete(requestParameters: ZoneApiZoneControllerDeleteRequest, options?: AxiosRequestConfig) {
        return ZoneApiFp(this.configuration)
            .zoneControllerDelete(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {ZoneApiZoneControllerGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZoneApi
     */
    public zoneControllerGet(requestParameters: ZoneApiZoneControllerGetRequest = {}, options?: AxiosRequestConfig) {
        return ZoneApiFp(this.configuration)
            .zoneControllerGet(
                requestParameters.name,
                requestParameters.restrictedLayer,
                requestParameters.user,
                requestParameters.zoneLayer,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {ZoneApiZoneControllerGetOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZoneApi
     */
    public zoneControllerGetOne(requestParameters: ZoneApiZoneControllerGetOneRequest, options?: AxiosRequestConfig) {
        return ZoneApiFp(this.configuration)
            .zoneControllerGetOne(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {ZoneApiZoneControllerGetZoneGeoJSONRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZoneApi
     */
    public zoneControllerGetZoneGeoJSON(
        requestParameters: ZoneApiZoneControllerGetZoneGeoJSONRequest,
        options?: AxiosRequestConfig,
    ) {
        return ZoneApiFp(this.configuration)
            .zoneControllerGetZoneGeoJSON(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {ZoneApiZoneControllerPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZoneApi
     */
    public zoneControllerPost(requestParameters: ZoneApiZoneControllerPostRequest, options?: AxiosRequestConfig) {
        return ZoneApiFp(this.configuration)
            .zoneControllerPost(requestParameters.createZoneDto, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {ZoneApiZoneControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZoneApi
     */
    public zoneControllerUpdate(requestParameters: ZoneApiZoneControllerUpdateRequest, options?: AxiosRequestConfig) {
        return ZoneApiFp(this.configuration)
            .zoneControllerUpdate(requestParameters.id, requestParameters.updateZoneDto, options)
            .then((request) => request(this.axios, this.basePath));
    }
}
