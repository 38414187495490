export const colors = ['#ff0000', '#2d9cfd', '#a8fe1c', '#ebff00', '#ff47cb', '#ffc635', '#782b00', '', '', '', '', ''];

export const fibres = {
    fotag: [
        '#0070C0',
        '#FFC000',
        '#92D050',
        '#993300',
        '#C1C1C1',
        '#FFFFFF',
        '#FF0000',
        '#000000',
        '#7638A3',
        '#FFFF00',
        '#FF65CC',
        '#00B0F0',
    ],
    orange: [
        '#FF0000',
        '#0070C0',
        '#92D050',
        '#FFFF00',
        '#7638A3',
        '#FFFFFF',
        '#FFC000',
        '#C1C1C1',
        '#993300',
        '#000000',
        '#00B0F0',
        '#FF65CC',
    ],
};

export const tubes = [
    '#FF0000',
    '#0070C0',
    '#92D050',
    '#FFFF00',
    '#7638A3',
    '#FFFFFF',
    '#FFC000',
    '#C1C1C1',
    '#993300',
    '#000000',
    '#00B0F0',
];
