import type { OptionsInterface, SelectInterface } from '@connect-field/client/components/forms/form.types';

export const labels: Record<string, string> = {
    code: 'Code',
    comment_gb: 'Commentaire Global',
    compo: 'Composition',
    conf_nb_fourreau: 'Conformité nombre de fourreaux / Plynox',
    criticite: 'Criticité',
    date_audit: 'Date audit',
    date_deb_realisation: 'Date début réalisation',
    date_fin_realisation: 'Date fin réalisation',
    gestionnaire: 'Gestionnaire',
    img_gc_autres: 'Photo Autres',
    img_gc_environnement: 'Photo Environnement',
    img_gc_refection: 'Photo Réfection',
    lg_reelle: 'Longueur réelle',
    mode_pose: 'Mode de pose',
    proprietaire: 'Propriétaire',
    qualite_compactage: 'Qualité visuelle du compactage',
    qualite_refection: 'Qualité de la réfection',
    respect_coupe: 'Respect de la coupe type',
    respect_pgc: 'Respect PGC / PMV',
    statut_audit: 'Statut audit',
    structure: 'Structure',
    type_struc: 'Type de structure',
    utilisation: 'Utilisation',
};

export const options: Record<string, OptionsInterface | SelectInterface> = {
    conf_nb_fourreau: [
        {
            label: 'Conforme',
            value: 'Conforme',
        },
        { label: 'Non conforme', value: 'Non conforme' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    criticite: [
        { label: 'MAJEUR', value: 'MAJEUR' },
        { label: 'MINEUR', value: 'MINEUR' },
    ],
    qualite_compactage: [
        { label: 'Conforme', value: 'Conforme' },
        {
            label: 'Non conforme',
            value: 'Non conforme',
        },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    qualite_refection: [
        { label: 'Conforme', value: 'Conforme' },
        {
            label: 'Non conforme',
            value: 'Non conforme',
        },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    respect_coupe: [
        { label: 'Conforme', value: 'Conforme' },
        {
            label: 'Non conforme',
            value: 'Non conforme',
        },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    respect_pgc: [
        { label: 'Conforme', value: 'Conforme' },
        {
            label: 'Non conforme',
            value: 'Non conforme',
        },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    statut_audit: [
        { label: 'NON REALISE', value: 'NON REALISE' },
        {
            label: 'PARTIELLEMENT',
            value: 'PARTIELLEMENT',
        },
        { label: 'TERMINE', value: 'TERMINE' },
    ],
};
