<template>
    <div class="status_api">
        {{ $t('apiStatus.title') }}
        <div
            class="status"
            :class="{ success: status === 'OK', error: status === 'KO' }"
        >
            {{ transformStatus() }}
        </div>
        <div class="latency">- {{ $t('apiStatus.latency') }} : {{ time }}</div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import * as HealthzApi from '@connect-field/client/services/api/healthz';

interface DataInterface {
    status: string;
    time: string;
}

export default defineComponent({
    data(): DataInterface {
        return {
            status: 'KO',
            time: '',
        };
    },
    mounted() {
        this.getStatus();
    },
    methods: {
        async getStatus(): Promise<void> {
            try {
                const start = Date.now();
                const data = await HealthzApi.getHealthzApi();
                const end = Date.now();

                this.time = `${end - start} ms`;

                this.status = data.status ?? 'KO';
            } catch (error: unknown) {
                console.error('[ApiStatus]', error);
                this.status = 'KO';
            }
        },
        // TODO : i18n
        transformStatus(): string {
            switch (this.status) {
                case 'OK':
                    return 'Connecté';
                case 'KO':
                default:
                    return 'Non accessible';
            }
        },
    },
});
</script>

<style lang="scss" scoped>
.status_api {
    color: white;
    opacity: 0.7;
    font-size: 14px;
    display: flex;
}

.status {
    margin-left: 5px;
}

.success {
    color: greenyellow;
}

.error {
    color: rgb(255, 49, 22);
}

.latency {
    margin-left: 5px;
}
</style>
