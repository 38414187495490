<template>
    <el-button @click="backToCables()">&lt; Revenir aux cables</el-button>

    <div v-if="cable">
        <div class="cable">
            <h4>Câble {{ cable.id }}</h4>
            <div>{{ cable.data.codeext }}</div>
            <div>{{ cable.data.boiteBaie }}</div>
            <div>{{ cable.data.boiteBaieCodeext }}</div>
        </div>
        <div class="tubes">
            <div
                v-for="(tube, key) in cable.data.tubes"
                :key="key"
                :style="{ backgroundColor: tube.color }"
                class="tube"
            >
                <div>Tube {{ key }}</div>
                <div>{{ tube.typeFibre }}</div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { mapState, mapWritableState } from 'pinia';
import { defineComponent } from 'vue';

import useBoxPlanStore from '@connect-field/client/stores/boxPlan';

export default defineComponent({
    computed: {
        ...mapState(useBoxPlanStore, {
            cable: 'cableData',
        }),
        ...mapWritableState(useBoxPlanStore, {
            currentTab: 'currentTab',
        }),
    },
    methods: {
        backToCables() {
            this.currentTab = 'cables';
        },
    },
});
</script>

<style scoped lang="scss">
.cable {
    color: white;
    background: rgba(17, 25, 40, 0.65);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    padding: 8px;
    margin: 0;
    justify-content: space-between;
}

.tubes {
    margin: 0 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .tube {
        color: black;
        padding: 2px;
        border-radius: 0 0 5px 5px;
    }
}
</style>
