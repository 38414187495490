import type { OptionsInterface } from '@connect-field/client/components/forms/form.types';

export const labels = {
    cb_abddate: "Date d'abandon",
    cb_abdsrc: "Cause de l'abandon",
    cb_avct: "Attribut synthétisant l'avancement",
    cb_capafo: 'Capacité du câble',
    cb_code: 'Code câble',
    cb_codeext: 'Code chez un tiers ou dans une autre base de données.',
    cb_color: 'Couleur du câble',
    cb_comment: 'commentaire',
    cb_creadat: "Date de création de l'objet en base",
    cb_dateins: 'Date de pose',
    cb_datemes: 'Date de mise en service',
    cb_diam: 'Diamètre du câble (mm)',
    cb_etat: 'Etat du câble',
    cb_etiquet: 'Etiquette sur le terrain',
    cb_fo_disp: 'Nombre de fibres disponibles',
    cb_fo_util: 'Nombre de fibres utiles',
    cb_gest: 'Gestionnaire',
    cb_lgreel: 'Longueur réelle (m)',
    cb_localis: 'Localisation (cablage intrasite)',
    cb_majdate: 'Date de mise à jour en base',
    cb_majsrc: 'Source de mise à jour',
    cb_modulo: 'Nombre de fibres par tube (6, 12)',
    cb_nd1: "Code du noeud à l'extrêmité 1",
    cb_nd2: "Code du noeud à l'extrêmité 2",
    cb_prop: 'Propriétaire',
    cb_proptyp: 'Type de propriété',
    cb_r1_code: "Code d'un référencement du réseau 1",
    cb_r2_code: "Code d'un référencement du réseau 2",
    cb_r3_code: "Code d'un référencement du réseau 3",
    cb_r4_code: "Code d'un référencement du réseau 4",
    cb_rf_code: 'Référence du câble',
    cb_statut: "Phase d'avancement",
    cb_tech: 'Technologie du câble',
    cb_typelog: 'Type logique du câble',
    cb_typephy: 'Type physique du câble',
    cb_user: 'Utilisateur',
    commentaire: 'Commentaires',
    img_cable: "Photo de l'étiquette du cable",
    real_audit: '',
};

export const options: Record<string, OptionsInterface> = {
    cb_avct: [
        { label: 'EXISTANT', value: 'E' },
        { label: 'A CREER', value: 'C' },
        { label: 'TRAVAUX', value: 'T' },
        { label: 'EN SERVICE', value: 'S' },
        { label: 'HORS SERVICE', value: 'H' },
        { label: 'ABANDONNE', value: 'A' },
    ],
    cb_etat: [
        { label: 'A CHANGER', value: 'HS' },
        { label: 'MAUVAIS ETAT', value: 'ME' },
        { label: 'BON ETAT', value: 'OK' },
        { label: 'NON CONCERNE', value: 'NC' },
    ],
    cb_proptyp: [
        { label: 'CONSTRUCTION', value: 'CST' },
        { label: 'RACHAT', value: 'RAC' },
        { label: 'CESSION', value: 'CES' },
        { label: 'IRU', value: 'IRU' },
        { label: 'LOCATION', value: 'LOC' },
        { label: 'OCCUPATION', value: 'OCC' },
    ],
    cb_statut: [
        { label: 'ETUDE PRELIMINAIRE', value: 'PRE' },
        { label: 'ETUDE DE DIAGNOSTIC', value: 'DIA' },
        { label: 'AVANT-PROJET', value: 'AVP' },
        { label: 'PROJET', value: 'PRO' },
        { label: 'PASSATION DES MARCHES DE TRAVAUX', value: 'ACT' },
        { label: 'ETUDE D EXECUTION', value: 'EXE' },
        { label: 'TRAVAUX', value: 'TVX' },
        { label: 'RECOLEMENT', value: 'REC' },
        { label: 'MAINTIENT EN CONDITIONS OPERATIONNELLES', value: 'MCO' },
    ],
    cb_tech: [
        { label: 'CUIVRE TELECOM', value: 'CUT' },
        { label: 'OPTIQUE', value: 'OPT' },
        { label: 'COAXIAL', value: 'COA' },
        { label: 'ECLAIRAGE', value: 'ECL' },
        { label: 'ELECTRICITE', value: 'ELE' },
        { label: 'VIDEO PROTECTION', value: 'VID' },
        { label: 'RADIO', value: 'RAD' },
    ],
    cb_typelog: [
        { label: 'COLLECTE TRANSPORT DISTRIBUTION', value: 'CX' },
        { label: 'COLLECTE', value: 'CO' },
        { label: 'COLLECTE TRANSPORT', value: 'CT' },
        { label: 'COLLECTE DISTRIBUTION', value: 'CD' },
        { label: 'TRANSPORT DISTRIBUTION', value: 'TD' },
        { label: 'TRANSPORT', value: 'TR' },
        { label: 'DISTRIBUTION', value: 'DI' },
        { label: 'RACCORDEMENT FINAL', value: 'RA' },
        { label: 'BOUCLE METROPOLITAINE', value: 'BM' },
        { label: 'LONGUE DISTANCE (LONG HAUL)', value: 'LH' },
        { label: 'NON COMMUNIQUE', value: 'NC' },
    ],
    cb_typephy: [
        { label: 'CABLE', value: 'C' },
        { label: 'BREAKOUT', value: 'B' },
        { label: 'JARRETIERE', value: 'J' },
    ],
    real_audit: [
        { label: 'OUI', value: 'OUI' },
        { label: 'NON', value: 'NON' },
    ],
};
