import HTTP from '@connect-field/client/utilities/http';

export async function saveDebugData(payload: {
    data: { key: string; value: unknown };
    store: string;
    uuid: string;
}): Promise<void> {
    try {
        await HTTP.client().DebugApi.debugControllerSave({
            saveDebugDto: payload,
        });
    } catch (error: unknown) {
        console.error(error);
    }
}
