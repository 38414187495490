import type { OptionsInterface } from '@connect-field/client/components/forms/form.types';

export const labels = {
    ba_abddate: "Date d'abandon de l'objet",
    ba_abdsrc: "Cause de l'abandon de l'objet",
    ba_code: 'Code baie ou ferme',
    ba_codeext: 'Code chez un tiers ou dans une autre base de données.',
    ba_comment: 'Commentaire',
    ba_creadat: "Date de création de l'objet en base",
    ba_etat: 'Etat de la BAIE',
    ba_etiquet: 'Etiquette sur le terrain',
    ba_gest: 'Identifiant unique du gestionnaire.',
    ba_haut: 'Hauteur en mm',
    ba_larg: 'Largeur en mm',
    ba_lt_code: 'Code du local technique',
    ba_majdate: "Date de la mise à jour de l'objet en base",
    ba_majsrc: 'Source utilisée pour la mise à jour',
    ba_nb_u: 'Taille de la baie en nombre de U',
    ba_prof: 'Profondeur en mm',
    ba_prop: 'Identifiant du propriétaire du tiroir.',
    ba_proptyp: 'Type de propriété',
    ba_rf_code: 'Référence de la baie dans la table référence.',
    ba_statut: 'Identifiant unique du statut de déploiement.',
    ba_type: 'Type du contenant',
    ba_user: 'Utilisateur',
    commentaire: 'Commentaires',
    img_baie: 'Photo de la baie',
    real_audit: 'Réalisation Audit',
};

export const options: Record<string, OptionsInterface> = {
    ba_etat: [
        { label: 'A CHANGER', value: 'HS' },
        { label: 'MAUVAIS ETAT', value: 'ME' },
        { label: 'BON ETAT', value: 'OK' },
        { label: 'NON CONCERNE', value: 'NC' },
    ],
    ba_proptyp: [
        { label: 'CONSTRUCTION', value: 'CST' },
        { label: 'RACHAT', value: 'RAC' },
        { label: 'CESSION', value: 'CES' },
        { label: 'IRU', value: 'IRU' },
        { label: 'LOCATION', value: 'LOC' },
        { label: 'OCCUPATION', value: 'OCC' },
    ],
    ba_statut: [
        { label: 'ETUDE PRELIMINAIRE', value: 'PRE' },
        { label: 'ETUDE DE DIAGNOSTIC', value: 'DIA' },
        { label: 'AVANT-PROJET', value: 'AVP' },
        { label: 'PROJET', value: 'PRO' },
        { label: 'PASSATION DES MARCHES DE TRAVAUX', value: 'ACT' },
        { label: 'ETUDE D EXECUTION', value: 'EXE' },
        { label: 'TRAVAUX', value: 'TVX' },
        { label: 'RECOLEMENT', value: 'REC' },
        { label: 'MAINTIENT EN CONDITIONS OPERATIONNELLES', value: 'MCO' },
    ],
    ba_type: [
        { label: 'BAIE', value: 'BAIE' },
        { label: 'FERME', value: 'FERME' },
    ],
    real_audit: [
        { label: 'OUI', value: 'OUI' },
        { label: 'NON', value: 'NON' },
    ],
};
