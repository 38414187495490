<template>
    <FormWrapper
        v-if="!loading"
        ref="customFormRef"
        :images-model="images"
        :form-model="form"
        :mandatory-fields="mandatoryFields"
        :changes-model="changes"
        :layer-name="layerName"
        :object-id="objectId"
        :project-id="projectId"
        :latitude="latitude"
        :longitude="longitude"
        @update:images-model="
            (e) => {
                images = e;
            }
        "
        @update:value-missing="
            (e) => {
                valueMissing = e;
            }
        "
        @update:form-model="
            (e) => {
                form = e;
            }
        "
        @update:changes-model="
            (e) => {
                changes = e;
            }
        "
    >
        <template #menu_tabs> </template>
        <template #main_form>
            <el-row>
                <el-col :span="24">
                    <CustomInput
                        :label="labels.ba_code + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.ba_code"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('ba_code', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.real_audit + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.real_audit"
                        :value="form.real_audit"
                        @update:value="
                            (newValue) => saveData('real_audit', newValue)
                        "
                    />

                    <div v-show="condition_img_baie()">
                        <InputCamera
                            :label="labels.img_baie"
                            :images="images.img_baie"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) => saveImage('img_baie', newValue)
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="condition_ba_codeext()"
                        :label="labels.ba_codeext + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.ba_codeext"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('ba_codeext', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_ba_etiquet()"
                        :label="labels.ba_etiquet + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.ba_etiquet"
                        @update:value="
                            (newValue) => saveData('ba_etiquet', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_ba_lt_code()"
                        :label="labels.ba_lt_code + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.ba_lt_code"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('ba_lt_code', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_ba_prop()"
                        :label="labels.ba_prop + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.ba_prop"
                        @update:value="
                            (newValue) => saveData('ba_prop', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_ba_gest()"
                        :label="labels.ba_gest + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.ba_gest"
                        :maxlength="20"
                        @update:value="
                            (newValue) => saveData('ba_gest', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_ba_user()"
                        :label="labels.ba_user + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.ba_user"
                        @update:value="
                            (newValue) => saveData('ba_user', newValue)
                        "
                    />
                    <CustomInputSelect
                        v-show="condition_ba_proptyp()"
                        :label="labels.ba_proptyp"
                        :options="options.ba_proptyp"
                        :value="form.ba_proptyp"
                        @update:value="
                            (newValue) => saveData('ba_proptyp', newValue)
                        "
                    />
                    <CustomInputSelect
                        v-show="condition_ba_statut()"
                        :label="labels.ba_statut"
                        :options="options.ba_statut"
                        :value="form.ba_statut"
                        @update:value="
                            (newValue) => saveData('ba_statut', newValue)
                        "
                    />
                    <CustomInputSelect
                        v-show="condition_ba_etat()"
                        :label="labels.ba_etat + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.ba_etat"
                        :value="form.ba_etat"
                        @update:value="
                            (newValue) => saveData('ba_etat', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_ba_rf_code()"
                        :label="labels.ba_rf_code + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.ba_rf_code"
                        @update:value="
                            (newValue) => saveData('ba_rf_code', newValue)
                        "
                    />
                    <CustomInputSelect
                        v-show="condition_ba_type()"
                        :label="labels.ba_type"
                        :options="options.ba_type"
                        :value="form.ba_type"
                        @update:value="
                            (newValue) => saveData('ba_type', newValue)
                        "
                    />
                    <InputNumber
                        v-show="condition_ba_nb_u()"
                        :label="labels.ba_nb_u + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.ba_nb_u"
                        @update:value="
                            (newValue) => saveData('ba_nb_u', newValue)
                        "
                    />
                    <InputNumber
                        v-show="condition_ba_haut()"
                        :label="labels.ba_haut + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.ba_haut"
                        @update:value="
                            (newValue) => saveData('ba_haut', newValue)
                        "
                    />
                    <InputNumber
                        v-show="condition_ba_larg()"
                        :label="labels.ba_larg + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.ba_larg"
                        @update:value="
                            (newValue) => saveData('ba_larg', newValue)
                        "
                    />
                    <InputNumber
                        v-show="condition_ba_prof()"
                        :label="labels.ba_prof + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.ba_prof"
                        @update:value="
                            (newValue) => saveData('ba_prof', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_ba_comment()"
                        :label="labels.ba_comment + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.ba_comment"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('ba_comment', newValue)
                        "
                    />
                    <CustomDatePicker
                        v-show="condition_ba_creadat()"
                        :label="labels.ba_creadat + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.ba_creadat"
                        :disabled="true"
                    />
                    <CustomDatePicker
                        v-show="condition_ba_majdate()"
                        :label="labels.ba_majdate + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.ba_majdate"
                        :disabled="true"
                    />
                    <CustomInput
                        v-show="condition_ba_majsrc()"
                        :label="labels.ba_majsrc + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.ba_majsrc"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('ba_majsrc', newValue)
                        "
                    />
                    <CustomDatePicker
                        v-show="condition_ba_abddate()"
                        :label="labels.ba_abddate + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.ba_abddate"
                        @update:value="
                            (newValue) => saveData('ba_abddate', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_ba_abdsrc()"
                        :label="labels.ba_abdsrc + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.ba_abdsrc"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('ba_abdsrc', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_commentaire()"
                        :label="labels.commentaire"
                        :value="form.commentaire"
                        @update:value="
                            (newValue) => saveData('commentaire', newValue)
                        "
                    />
                </el-col>
            </el-row>
        </template>
    </FormWrapper>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import type { FormDataInterface } from '@connect-field/client/components/forms/form.types';
import FormTemplate from '@connect-field/client/components/forms/FormTemplate.vue';

import {
    labels,
    options,
} from '@connect-field/client/components/forms/Dotic/INEO/form_baie.data';

interface DataInterface extends FormDataInterface {}

export default defineComponent({
    extends: FormTemplate,
    data(): DataInterface {
        return {
            changes: false,
            form: {},
            images: {
                img_baie: [],
            },
            labels,
            loading: true,
            mandatoryFields: [
                'ba_code',
                'real_audit',
                'ba_codeext',
                'ba_etiquet',
                'ba_lt_code',
                'ba_prop',
                'ba_gest',
                'ba_user',
                'ba_etat',
                'ba_rf_code',
                'ba_nb_u',
                'ba_haut',
                'ba_larg',
                'ba_prof',
                'ba_comment',
                'ba_creadat',
                'ba_majdate',
                'ba_majsrc',
                'ba_abddate',
                'ba_abdsrc',
            ],
            options,

            valueMissing: false,
        };
    },
    methods: {
        condition_ba_abddate() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_ba_abdsrc() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_ba_codeext() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_ba_comment() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_ba_creadat() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_ba_etat() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_ba_etiquet() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_ba_gest() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_ba_haut() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_ba_larg() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_ba_lt_code() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_ba_majdate() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_ba_majsrc() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_ba_nb_u() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_ba_prof() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_ba_prop() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_ba_proptyp() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_ba_rf_code() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_ba_statut() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_ba_type() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_ba_user() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_commentaire() {
            const { real_audit } = this.form;
            return real_audit === 'NON';
        },
        condition_img_baie() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
    },
});
</script>
