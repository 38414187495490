import {
    faAngleDown,
    faAngleRight,
    faAngleUp,
    faBox,
    faCheck,
    faCheckCircle,
    faChevronDown,
    faChevronRight,
    faCircle,
    faCircleNotch,
    faCircleQuestion,
    faCloud,
    faCompass,
    faDiagramProject,
    faDownload,
    faDrawPolygon,
    faEllipsisH,
    faEraser,
    faExclamationCircle,
    faEye,
    faEyeSlash,
    faFileMedicalAlt,
    faFileSignature,
    faGripLines,
    faHammer,
    faHelmetSafety,
    faImage,
    faKitMedical,
    faLayerGroup,
    faLeaf,
    faLeftFromLine,
    faLocationArrow,
    faMap,
    faMapMarkerAlt,
    faMinus,
    faPenToSquare,
    faPlus,
    faProjectDiagram,
    faRightFromLine,
    faRuler,
    faRulerVertical,
    faScrewdriverWrench,
    faSearch,
    faSignOutAlt,
    faSync,
    faSyncAlt,
    faThLarge,
    faThList,
    faTimes,
    faTrafficCone,
    faTrashAlt,
    faUserCog,
    faUserHelmetSafety,
    faUtilityPole,
} from '@fortawesome/pro-solid-svg-icons';
import type { App } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(
    faAngleDown,
    faAngleRight,
    faAngleUp,
    faBox,
    faCheck,
    faCheckCircle,
    faChevronDown,
    faChevronRight,
    faCircle,
    faCircleNotch,
    faCircleQuestion,
    faCloud,
    faCompass,
    faDiagramProject,
    faDownload,
    faDrawPolygon,
    faEllipsisH,
    faEraser,
    faExclamationCircle,
    faEye,
    faEyeSlash,
    faFileMedicalAlt,
    faFileSignature,
    faGripLines,
    faHammer,
    faHelmetSafety,
    faImage,
    faKitMedical,
    faLayerGroup,
    faLeaf,
    faLeftFromLine,
    faLocationArrow,
    faMap,
    faMapMarkerAlt,
    faMinus,
    faPenToSquare,
    faPlus,
    faProjectDiagram,
    faRightFromLine,
    faRuler,
    faRulerVertical,
    faScrewdriverWrench,
    faSearch,
    faSignOutAlt,
    faSync,
    faSyncAlt,
    faThLarge,
    faThList,
    faTimes,
    faTrafficCone,
    faTrashAlt,
    faUserCog,
    faUserHelmetSafety,
    faUtilityPole,
);

export default (app: App<Element>): void => {
    app.component('FontAwesomeIcon', FontAwesomeIcon);
};
