import initSqlJs, { type ParamsObject } from 'sql.js';

import { setStore } from '@connect-field/client/utilities/idb-utility';

interface HTMLImageElementEvent extends GlobalEventHandlers {
    height: number;
    width: number;
}

export async function importImages(array: Uint8Array): Promise<void> {
    const SQL = await initSqlJs({
        locateFile: () => `/sql-wasm-1.8.0.wasm`,
    });

    const db = new SQL.Database(array);
    console.debug('[image] Import starting');
    db.each(
        'SELECT key, value FROM images',
        null,
        (row: ParamsObject) => {
            if (row.value && row.key) {
                setStore('projectImages_v2', JSON.parse(row.value.toString()), row.key.toString());
            }
        },
        () => {},
    );
    console.debug('[image] Import finished');
    db.close();
}

export function isHTMLImageElementEvent(e: GlobalEventHandlers | HTMLImageElementEvent): e is HTMLImageElementEvent {
    return (
        typeof (e as HTMLImageElementEvent).width !== 'undefined' &&
        typeof (e as HTMLImageElementEvent).height !== 'undefined'
    );
}
