<template>
    <router-view v-slot="slotProps">
        <component :is="slotProps.Component" />
    </router-view>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Edit } from '@element-plus/icons-vue';
import type Map from 'ol/Map';

import useAuthStore from '@connect-field/client/stores/auth';
import useMapStore from '@connect-field/client/stores/map';
import usePanelStore from '@connect-field/client/stores/panel';

export default defineComponent({
    name: 'App',
    components: {
        Edit,
    },
    emits: ['reload'],
    setup() {
        return {
            authStore: useAuthStore(),
            mapStore: useMapStore(),
            panelStore: usePanelStore(),
        };
    },
    created() {
        this.authStore.tryLogin();
        window.addEventListener('resize', this.resizeEventHandler);
    },
    unmounted() {
        window.removeEventListener('resize', this.resizeEventHandler);
    },
    methods: {
        resizeEventHandler(): void {
            setTimeout(() => {
                const map = this.mapStore.map;
                this.panelStore.setPanelNewHeight();

                if (map) {
                    (map as Map).updateSize();
                }
            }, 500);
        },
    },
});
</script>

<style lang="scss">
@import 'app.scss';
</style>
