import { toRaw } from 'vue';

import { type RecordOfArrays, sortArrayInObject } from '@connect-field/client/utilities/tools';

export interface BoxPlanPositionInterface {
    boite: string;
    boite_baie_codeext_entrante: string;
    boite_baie_codeext_sortante: string | null;
    boite_baie_entrante: string;
    boite_baie_sortante: string | null;
    cable_codeext_entrant: string;
    cable_codeext_sortant: string | null;
    cable_entrant: string;
    cable_sortant: string | null;
    cassette: string;
    connectorisation_entrante: string;
    connectorisation_sortante: string | null;
    couleur_fibre_entrante: string;
    couleur_fibre_sortante: string | null;
    couleur_tube_entrant: string;
    couleur_tube_sortant: string | null;
    fibre_entrante: string;
    fibre_sortante: string | null;
    numero_cassette: string;
    numero_fibre_entrante: string | number;
    numero_fibre_sortante: string | null;
    numero_tube_entrant: string | number;
    numero_tube_sortant: string | null;
    type_epissurage: string;
    type_fibre_entrante: string;
    type_fibre_sortante: string | null;
}

export interface CableInterface {
    boiteBaie: string | null;
    boiteBaieCodeext: string | null;
    codeext: string | null;
    tubes: Record<string, TubeInterface>;
}

export interface TubeInterface {
    color: string | null;
    typeFibre: string | null;
}

export function groupByCables(cassettes: Record<string, Array<BoxPlanPositionInterface>>): Record<string, unknown> {
    const cables: Record<string, unknown> = {};

    Object.entries(cassettes).forEach(([key, cassette]: [string, Array<BoxPlanPositionInterface>]) => {
        const cablesEntrants = cassette.reduce(
            (cables: Record<string, CableInterface>, current: BoxPlanPositionInterface) => {
                const cable = current.cable_entrant;

                if (cable && !cables[cable]) {
                    cables[cable] = {
                        boiteBaie: current.boite_baie_entrante,
                        boiteBaieCodeext: current.boite_baie_codeext_entrante || null,
                        codeext: current.cable_codeext_entrant,
                        tubes: {},
                    };
                }

                return cables;
            },
            {},
        );

        Object.keys(cablesEntrants).forEach((cableKey: string) => {
            const tubesEntrants = cassette.filter(
                (boxPlanPosition: BoxPlanPositionInterface) => boxPlanPosition.cable_entrant === cableKey,
            );

            try {
                cablesEntrants[cableKey].tubes = tubesEntrants.reduce(
                    (tubes: Record<string, TubeInterface>, tube: BoxPlanPositionInterface) => {
                        if (!tube.numero_tube_entrant) {
                            return tubes;
                        }

                        const tubeId = tube.numero_tube_entrant.toString();
                        const tubeObject = {
                            color: tube.couleur_tube_entrant,
                            typeFibre: tube.type_fibre_entrante,
                        };

                        if (!tubes[tubeId]) {
                            tubes[tubeId] = tubeObject;
                        }

                        return tubes;
                    },
                    {},
                );
            } catch {
                // Silent error
            }
        });

        const cablesSortants = cassette.reduce(
            (cables: Record<string, CableInterface>, current: BoxPlanPositionInterface) => {
                const cable = current.cable_sortant;

                if (cable && !cables[cable]) {
                    cables[cable] = {
                        boiteBaie: current.boite_baie_sortante,
                        boiteBaieCodeext: current.boite_baie_codeext_sortante || null,
                        codeext: current.cable_codeext_sortant,
                        tubes: {},
                    };
                }

                return cables;
            },
            {},
        );

        Object.keys(cablesSortants).forEach((cableKey: string) => {
            const tubesSortants = cassette.filter(
                (boxPlanPosition: BoxPlanPositionInterface) => boxPlanPosition.cable_sortant === cableKey,
            );

            cablesSortants[cableKey].tubes = tubesSortants.reduce(
                (tubes: Record<string, TubeInterface>, tube: BoxPlanPositionInterface) => {
                    if (!tube.numero_tube_sortant) {
                        return tubes;
                    }

                    const tubeId = tube.numero_tube_sortant.toString();
                    const tubeObject = {
                        color: tube.couleur_tube_sortant,
                        typeFibre: tube.type_fibre_sortante,
                    };

                    if (!tubes[tubeId]) {
                        tubes[tubeId] = tubeObject;
                    }

                    return tubes;
                },
                {},
            );
        });

        cables[key] = { entrant: cablesEntrants, sortant: cablesSortants };
    });

    return cables;
}

export function groupByCassette(
    rows: Array<BoxPlanPositionInterface>,
): RecordOfArrays<string, BoxPlanPositionInterface> {
    const cassettes: RecordOfArrays<string, BoxPlanPositionInterface> = rows.reduce(
        (cassettes: RecordOfArrays<string, BoxPlanPositionInterface>, row: BoxPlanPositionInterface) => {
            if (!cassettes[row.cassette]) {
                cassettes[row.cassette] = [];
            }
            cassettes[row.cassette].push(toRaw(row));

            return cassettes;
        },
        {},
    );

    return sortArrayInObject(cassettes, (first: BoxPlanPositionInterface, second: BoxPlanPositionInterface) => {
        if (typeof first.numero_tube_entrant === 'number' && typeof second.numero_tube_entrant === 'number') {
            return first.numero_tube_entrant - second.numero_tube_entrant;
        }

        return (
            first?.numero_tube_entrant?.toString().localeCompare(second?.numero_tube_entrant?.toString() ?? '') ?? -1
        );
    });
}
