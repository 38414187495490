<template>
    <div :class="{ 'w-full': fullWidth }">
        <el-button
            v-if="buttonType === 'danger'"
            round
            :disabled="isDisabled"
            type="danger"
            class="buttonClass"
            :class="[
                isDisabled ? 'disabledButton' : 'dangerButton',
                fullWidth ? 'fullwidth' : '',
            ]"
        >
            {{ label }}
        </el-button>
        <el-button
            v-else-if="buttonType === 'action'"
            round
            :disabled="isDisabled"
            class="buttonClass"
            :class="[
                isDisabled ? 'disabledButton' : 'actionButton',
                fullWidth ? 'fullwidth' : '',
            ]"
        >
            {{ label }}
        </el-button>
        <el-button
            v-else
            round
            :disabled="isDisabled"
            class="buttonClass"
            :class="[
                isDisabled ? 'disabledButton' : 'cancelButton',
                fullWidth ? 'fullwidth' : '',
            ]"
        >
            {{ label }}
        </el-button>
    </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';

export default defineComponent({
    props: {
        buttonType: {
            type: String as PropType<'default' | 'action' | 'danger'>,
            default: 'default',
        },
        fullWidth: { type: Boolean, default: false },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: 'Bouton',
        },
    },
});
</script>

<style scoped lang="scss">
.buttonClass {
    border: none;
    font-weight: bold;
    color: var(--grey-dark);
    margin-top: 0.5rem;
}

.disabledButton {
    color: #c0c4cc;
    background-color: transparent;
}

.cancelButton {
    background-color: #edf1f7;
}

.actionButton {
    background-color: var(--color-primary);
    color: white;
}

.dangerButton {
    color: white;
    background-color: var(--el-button-bg-color);

    &:hover {
        background-color: var(--el-button-hover-bg-color);
    }
}

div + div {
    margin-left: 0.5rem;
}
</style>
