<template>
    <div class="slider">
        <p class="title">{{ $t('globals.opacity') }} :</p>
        <input
            v-model="slideValue"
            type="range"
            min="0"
            max="100"
            :style="{ backgroundSize: slideValue + '% 100%' }"
            @input="$emit('valueChange', slideValue)"
        />
        <p>{{ slideValue }}%</p>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

interface DataInterface {
    slideValue: number;
}

export default defineComponent({
    props: {
        currentOpacity: { type: Number, default: 1 },
        target: { type: String, default: '' },
    },
    emits: ['valueChange'],
    data(): DataInterface {
        return {
            slideValue: this.currentOpacity,
        };
    },
    watch: {
        target: function () {
            this.slideValue = this.currentOpacity;
        },
    },
    mounted() {
        this.$nextTick(function () {
            this.slideValue = this.currentOpacity;
        });
    },
});
</script>

<style scoped lang="scss">
.slider {
    color: white;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: baseline;
}

input[type='range'] {
    -webkit-appearance: none;
    margin-right: 5px;
    flex: 1;
    height: 10px;
    border-radius: 5px;
    background-image: linear-gradient(#2d9cfd, #2d9cfd);
    background-repeat: no-repeat;
}

input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 12px;
    width: 10px;
    border-radius: 50%;
    background: #2d9cfd;
}

input[type='range']::-moz-range-thumb {
    -webkit-appearance: none;
    height: 14px;
    width: 12px;
    border-radius: 50%;
    background: #2d9cfd;
}

input[type='range']::-ms-thumb {
    -webkit-appearance: none;
    height: 14px;
    width: 12px;
    border-radius: 50%;
    background: #2d9cfd;
}

.title {
    padding-right: 5px;
}
</style>
