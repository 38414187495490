<template>
    <div class="main-wrapper">
        <el-alert
            v-show="showBanner"
            :title="bannerMessage"
            :type="bannerType"
            effect="dark"
            :closable="isClosableBanner"
        />

        <BaseDialog
            :show="showGeolocModal"
            title="Géolocalisation"
        >
            <p>{{ $t('geolocationModal.toActivate') }}</p>
            <div class="highlight">
                {{ $t('geolocationModal.iosPath') }}
            </div>
            <br />
            <p>{{ $t('geolocationModal.toVerify') }}</p>
            <div class="highlight">
                {{ $t('geolocationModal.iosPath2') }}
            </div>
            <el-tree
                :data="geolocModalError"
                :props="{ children: 'children', label: 'label' }"
                class="color-gray"
            />
            <template #actions>
                <BaseDialogButton
                    :label="$t('globals.close')"
                    button-type="default"
                    @click="handleClose"
                />
                <BaseDialogButton
                    :label="$t('geolocationModal.openSettingsApp')"
                    button-type="action"
                    @click="openIOSSettingsApp"
                />
            </template>
        </BaseDialog>

        <FormCreationDialog
            :show="showFormCreationModal"
            @close-dialog="closeCreationDialog"
        />

        <hr
            v-if="offlineSelectionStatus && !zoneSelection"
            class="line top_selector"
        />
        <hr
            v-if="offlineSelectionStatus && !zoneSelection"
            class="line right_selector"
        />
        <hr
            v-if="offlineSelectionStatus && !zoneSelection"
            class="line bottom_selector"
        />
        <hr
            v-if="offlineSelectionStatus && !zoneSelection"
            class="line left_selector"
        />
        <div
            v-show="!isPanelFullyOpened"
            v-if="!loading"
            ref="topRightBtnBar"
            class="topRightBtnBar btnBar"
        >
            <IconButton
                v-if="zoomDisplay"
                icon-name="plus"
                @click="zoom('in')"
            />
            <IconButton
                v-if="zoomDisplay"
                icon-name="minus"
                @click="zoom('out')"
            />
            <IconButton
                v-if="isRotated"
                icon-name="compass"
                @click="rotateToNorth"
            />
            <IconButton
                v-if="rulerDisplay"
                icon-name="ruler"
                :class="{ activeRuler: isActiveRuler }"
                @click="toggleIsActiveRuler"
            />
            <div
                v-show="isActiveRuler && rulerDisplay"
                class="rulerBtnBar btnBar"
            >
                <IconButton
                    :is-glazed="!isDrawingLine"
                    icon-name="project-diagram"
                    class="text-sm"
                    @click="drawMeasurement('LineString')"
                />
                <IconButton
                    :is-glazed="!isDrawingPolygon"
                    class="text-lg mb-0"
                    icon-name="draw-polygon"
                    @click="drawMeasurement('Polygon')"
                />
                <IconButton
                    class="text-lg mb-0"
                    icon-name="eraser"
                    @click="eraseMeasurement()"
                />
            </div>
        </div>
        <div
            v-if="offlineSelectionStatus"
            class="topMiddleBar"
        >
            <div class="switchMode glass">
                <div class="switch_element">
                    <el-switch
                        v-if="layerSelectionList.length"
                        v-model="zoneSelection"
                        :active-text="$t('offlineSelection.zoneSelection')"
                        :inactive-text="$t('offlineSelection.areaSelection')"
                        @change="handleOfflineModeChange"
                    />
                    <p
                        v-if="!layerSelectionList.length"
                        class="custom_label_input"
                    >
                        {{ $t('offlineSelection.areaSelection') }}
                    </p>
                </div>
                <div
                    v-if="zoneSelection"
                    v-show="zoneSelection"
                    class="select_element"
                >
                    <el-select
                        v-if="layerSelectionList.length"
                        v-model="layersSelection"
                        :collapse-tags="true"
                        class="optionButton bbox_selection"
                        multiple
                        :placeholder="$t('offlineSelection.max5Zones')"
                        @change="applySelection"
                    >
                        <el-option
                            v-for="item in layerSelectionList"
                            :key="item.id"
                            :label="item.label"
                            :value="item.id"
                        />
                    </el-select>
                </div>
            </div>
        </div>
        <div class="panelContainer">
            <div
                v-show="
                    !isPanelFullyOpened && !loading && !offlineSelectionStatus
                "
                class="bottomRightBtnBar btnBar"
                :style="getBottomBtnBarPosition"
            >
                <IconButton
                    id="geolocationBtn"
                    icon-name="location-arrow"
                    :is-active="isTrackingPosition"
                    @click="toggleLocationTracking"
                />
                <IconButton
                    v-if="isCreationEnabled()"
                    icon-name="plus"
                    :is-glazed="false"
                    :is-round="true"
                    @click="openFormCreationDialog"
                />
            </div>
            <div
                v-show="
                    !isPanelFullyOpened &&
                    !loading &&
                    offlineSelectionStatus &&
                    disableButton
                "
                class="bottomMiddleBtnBar"
                :style="getBottomBtnBarPosition"
            >
                <el-button
                    type="primary"
                    class="optionButton"
                    round
                    @click="validateOfflineSelection"
                >
                    {{ $t('globals.save') }}
                </el-button>
                <el-button
                    type="info"
                    class="optionButton"
                    round
                    @click="resetOfflineSelection"
                >
                    {{ $t('globals.cancel') }}
                </el-button>
            </div>
            <div
                v-show="
                    !isPanelFullyOpened &&
                    !loading &&
                    offlineSelectionStatus &&
                    !disableButton
                "
                class="bottomMiddleBtnBar"
                :style="getBottomBtnBarPosition"
            >
                <el-button
                    type="danger"
                    class="optionButton"
                    round
                    @click="showAlertMessage"
                >
                    Sauvegarder
                </el-button>
                <el-button
                    type="info"
                    class="optionButton"
                    round
                    @click="resetOfflineSelection"
                >
                    Annuler
                </el-button>
            </div>
            <!-- TODO:  Add project skeleton -->
            <Panel v-if="!loading" />
        </div>
        <MapInstance :on-loaded="onMapLoaded" />
    </div>
</template>

<script lang="ts">
import type BaseEvent from 'ol/events/Event';
import type BaseLayer from 'ol/layer/Base';
import { defineComponent } from 'vue';
import { easeOut } from 'ol/easing';
import { ElMessage } from 'element-plus';
import Feature from 'ol/Feature';
import { fromLonLat } from 'ol/proj';
import type Map from 'ol/Map';
import { mapState } from 'pinia';
import { Point } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';

import useProjectsStore, {
    type LayerSelection,
} from '@connect-field/client/stores/projects';
import BaseDialog from '@connect-field/client/components/ui/BaseDialog.vue';
import BaseDialogButton from '@connect-field/client/components/ui/BaseDialogButton.vue';
import { detectDevice } from '@connect-field/client/utilities/tools';
import FormCreationDialog from '@connect-field/client/components/dialogs/FormCreationDialog.vue';
import IconButton from '@connect-field/client/components/atoms/IconButton.vue';
import MapInstance from '@connect-field/client/components/MapInstance.vue';
import MeasureService from '@connect-field/client/services/measure.service';
import Panel from '@connect-field/client/components/PanelInstance.vue';
import type { ProjectConfigurationLayer } from '@connect-field/client/sdk/generated';
import useBannerStore from '@connect-field/client/stores/banner';
import useMapStore from '@connect-field/client/stores/map';
import usePanelStore from '@connect-field/client/stores/panel';

interface DataInterface {
    geolocModalError: Array<Record<string, unknown>>;
    isActiveRuler: boolean;
    isClosableBanner: boolean;
    isDrawingLine: boolean;
    isDrawingPolygon: boolean;
    isRotated: boolean;
    isTrackingPosition: boolean;
    loading: boolean;
    maxNbSelectedZone: boolean;
    showFormCreationModal: boolean;
    showGeolocModal: boolean;
    trackingId?: number;
    zoneSelection: boolean;
}

export default defineComponent({
    $refs: { topRightBtnBar: HTMLElement },
    components: {
        BaseDialog,
        BaseDialogButton,
        FormCreationDialog,
        IconButton,
        MapInstance,
        Panel,
    },
    setup() {
        return {
            mapStore: useMapStore(),
            panelStore: usePanelStore(),
            projectsStore: useProjectsStore(),
        };
    },
    data(): DataInterface {
        return {
            geolocModalError: [
                { children: [{ label: '' }], label: "Détail de l'erreur" },
            ],
            isActiveRuler: false,
            isClosableBanner: false,
            isDrawingLine: false,
            isDrawingPolygon: false,
            isRotated: false,
            isTrackingPosition: false,
            loading: true,
            maxNbSelectedZone: false,
            showFormCreationModal: false,
            showGeolocModal: false,
            zoneSelection: false,
        };
    },
    computed: {
        ...mapState(useBannerStore, {
            bannerMessage: 'bannerMessage',
            bannerType: 'bannerType',
            isClosable: 'isClosable',
            showBanner: 'showBanner',
        }),
        ...mapState(useMapStore, {
            rulerDisplay: 'rulerDisplay',
            zoomDisplay: 'zoomDisplay',
            zoomLevel: 'zoomLevel',
        }),
        ...mapState(usePanelStore, {
            panelHeight: 'panelHeight',
            panelState: 'panelState',
        }),
        ...mapState(useProjectsStore, {
            selectedProject: 'selectedProject',
        }),
        device(): string {
            return detectDevice();
        },
        disableButton(): boolean {
            return (
                (this.zoneSelection &&
                    this.maxNbSelectedZone &&
                    this.layersSelection &&
                    Boolean(this.layersSelection.length)) ||
                (this.zoomLevel > 12 && !this.zoneSelection)
            );
        },
        getBottomBtnBarPosition(): { bottom: string } {
            return { bottom: this.panelHeight };
        },
        isPanelFullyOpened(): boolean {
            return this.panelState === 'fully-opened';
        },
        layerSelectionList(): Array<LayerSelection> {
            return this.sortLayers(this.projectsStore.layerSelectionList);
        },
        layersSelection: {
            get(): Array<string | number> {
                return this.projectsStore.selectedLayersOffline;
            },
            set(value: Array<string | number>) {
                this.projectsStore.$patch((state) => {
                    state.selectedLayersOffline = value;
                });
            },
        },
        map(): Map | undefined {
            if (!this.mapStore.map) {
                return undefined;
            }

            return this.mapStore.map as Map;
        },
        offlineSelectionStatus(): boolean {
            return this.$route.name === 'projectDownload';
        },
    },
    watch: {
        offlineSelectionStatus(newVal) {
            if (newVal) {
                (this.$refs['topRightBtnBar'] as HTMLElement).style.top =
                    '60px';
            } else {
                (this.$refs['topRightBtnBar'] as HTMLElement).style.top =
                    '10px';
            }
        },
        /**
         * Reset states if the rulerDisplay parameter is disabled while the isActiveRuler div is displayed
         */
        rulerDisplay() {
            this.resetActiveRuler();
        },
    },
    updated() {
        this.maxNbSelectedZone = this.layersSelection?.length < 6;
    },
    methods: {
        applySelection(): void {
            if (!this.map) {
                throw new Error('Map is undefined');
            }

            const [layerFiltered] = this.map
                .getLayers()
                .getArray()
                .filter((layer: BaseLayer) => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const [$name, type] = layer.getProperties().name
                        ? layer.getProperties().name.split('.')
                        : [null, null];
                    if (type === 'selection_layer') {
                        return layer;
                    }
                });

            if (layerFiltered && layerFiltered instanceof VectorLayer) {
                layerFiltered.getSource().forEachFeature(function (
                    feature: Feature,
                ) {
                    feature.setProperties({ _isSelected: false });
                });
                this.layersSelection.forEach((layer: BaseLayer) => {
                    layerFiltered.getSource().forEachFeature(function (
                        feature: Feature,
                    ) {
                        if (feature.getProperties().sid === layer) {
                            feature.setProperties({ _isSelected: true });
                        }
                    });
                });
            }
        },
        async callGeolocation(): Promise<void> {
            if (!this.map) {
                throw new Error('Map is undefined');
            }
            this.map.changed();

            if (this.trackingId) {
                await this.removeLocationTracking();
            }

            let hasMadeFirstZoom = false;

            this.trackingId = navigator.geolocation.watchPosition(
                (position) => {
                    if (!position) {
                        console.error('[geolocation] Position is null');

                        return this.removeLocationTracking();
                    }
                    console.debug(`[geolocation] Position found`, position);

                    const center = new Point(
                        fromLonLat([
                            position.coords.longitude,
                            position.coords.latitude,
                        ]),
                    );

                    if (this.map) {
                        if (!hasMadeFirstZoom) {
                            this.map.getView().fit(center, {
                                duration: 300,
                                maxZoom: 18,
                                padding: [0, 0, 200, 0],
                            });

                            hasMadeFirstZoom = true;
                        }

                        console.debug(`[geolocation] Animation finished`);

                        this.map
                            .getLayers()
                            .getArray()
                            .filter(
                                (layer: BaseLayer) =>
                                    layer.getProperties().geolocation,
                            )
                            .forEach((layer: BaseLayer) => {
                                if (layer instanceof VectorLayer) {
                                    const point = layer
                                        .getSource()
                                        .getFeatureById('point');
                                    if (point) {
                                        point.setGeometry(
                                            new Point(
                                                fromLonLat([
                                                    position.coords.longitude,
                                                    position.coords.latitude,
                                                ]),
                                            ),
                                        );
                                    } else {
                                        const feature = new Feature(
                                            new Point(
                                                fromLonLat([
                                                    position.coords.longitude,
                                                    position.coords.latitude,
                                                ]),
                                            ),
                                        );
                                        feature.setId('point');

                                        layer.getSource().addFeature(feature);
                                    }
                                }
                            });
                        this.map.changed();
                        console.debug(`[geolocation] Location point displayed`);
                        this.mapStore.$patch((state) => {
                            state.geolocation = position.coords;
                        });
                    }
                },
                (err: unknown) => {
                    console.error(err);
                    if (err && err.code) {
                        if (err.code === 1) {
                            this.userDeniedGeolocationError(err);
                        } else if (err.code === 2) {
                            this.positionUnavailableGeolocationError(err);
                        } else if (err.code === 3) {
                            this.timeoutGeolocationError(err);
                        }
                    }

                    return this.removeLocationTracking();
                },
                { enableHighAccuracy: true, maximumAge: 30000, timeout: 20000 },
            );
        },
        closeCreationDialog(): void {
            this.showFormCreationModal = false;
        },
        drawMeasurement(type: 'LineString' | 'Polygon'): void {
            if (!this.map) {
                throw new Error('Map instance is undefined');
            }

            MeasureService.map = this.map;
            MeasureService.addMapInteraction(type);

            if (type === 'LineString') {
                this.isDrawingLine = true;
                this.isDrawingPolygon = false;
            } else if (type === 'Polygon') {
                this.isDrawingLine = false;
                this.isDrawingPolygon = true;
            }
        },
        eraseMeasurement(): void {
            MeasureService.removeMeasurementLayer();
        },
        handleClose(): void {
            this.showGeolocModal = false;
        },
        handleOfflineModeChange(event: boolean): void {
            this.projectsStore.$patch({ zoneSelection: event });
            if (!event) {
                (this.$refs.topRightBtnBar as HTMLElement).style.top = '60px';
                this.projectsStore.$patch({ selectedLayersOffline: [] });

                this.applySelection();
            }
            if (event) {
                (this.$refs.topRightBtnBar as HTMLElement).style.top = '120px';
            }
        },
        isCreationEnabled(): boolean {
            return (
                (this.selectedProject &&
                    this.selectedProject.layers &&
                    this.selectedProject.layers.some(
                        (layer: ProjectConfigurationLayer) =>
                            layer?.enableCreation,
                    )) ??
                false
            );
        },
        onMapLoaded(): void {
            this.loading = false;
            this.onRotationHandler();
        },
        onRotationHandler(): void {
            if (this.map) {
                this.map.getView().on('change:rotation', (event: BaseEvent) => {
                    this.isRotated = event.target.getRotation() !== 0;
                });
            }
        },
        openFormCreationDialog(): void {
            this.showFormCreationModal = true;
        },
        /**
         * Open the general menu of the iOS settings app
         */
        openIOSSettingsApp(): void {
            document.location.href = 'App-prefs://prefs:root=General';
        },
        positionUnavailableGeolocationError(e: Error): void {
            console.error(e);
            alert(
                "Votre appareil n'a pas réussi à se positionner. Peut-être que la géolocalisation de votre téléphone est désactivée.",
            );
        },
        removeLocationTracking(): void {
            if (!this.trackingId) {
                return;
            }

            navigator.geolocation.clearWatch(this.trackingId);
            this.trackingId = undefined;
            this.isTrackingPosition = false;
            this.mapStore.resetGeolocation();

            console.debug(`[geolocation] Location tracking finished`);
        },
        resetActiveRuler(): void {
            this.isDrawingLine = false;
            this.isDrawingPolygon = false;
        },
        resetOfflineSelection(): void {
            this.zoneSelection = false;
            this.panelStore.setPanelHalfOpened();
            this.$router.push({ name: 'project' });
        },
        rotateToNorth(): void {
            if (this.map) {
                this.map.getView().animate({
                    easing: easeOut,
                    rotation: 0,
                });
                this.isRotated = false;
            }
        },
        showAlertMessage(): void {
            if (!this.zoneSelection) {
                ElMessage({
                    message: 'Veuillez zoomer afin de pouvoir sauvegarder',
                    offset: 60,
                    type: 'warning',
                });

                return;
            }

            ElMessage({
                message:
                    this.layersSelection.length === 0
                        ? 'Veuillez sélectionner au moins une zone'
                        : 'Veuillez ne sélectionner que 5 zones maximum',
                offset: 100,
                type: 'warning',
            });
        },
        sortLayers(layers: Array<LayerSelection>): Array<LayerSelection> {
            return layers.sort((a: LayerSelection, b: LayerSelection) =>
                a.label.localeCompare(b.label),
            );
        },
        timeoutGeolocationError(e: Error): void {
            console.error(e);
            alert(
                "Votre appareil n'a pas réussi à se positionner dans le temps imparti. Veuillez réessayer.\n" +
                    'Si le problème subsiste, veuillez contacter un adminisatrateur',
            );
        },
        toggleIsActiveRuler(): void {
            if (this.isActiveRuler) {
                this.isActiveRuler = false;
                this.resetActiveRuler();
            } else {
                this.isActiveRuler = true;
            }
        },
        async toggleLocationTracking(): Promise<void> {
            if (this.isTrackingPosition) {
                await this.removeLocationTracking();
            } else {
                await this.turnOnLocationTracking();
            }
        },
        async turnOnLocationTracking(): Promise<void> {
            this.isTrackingPosition = true;
            await this.callGeolocation();
        },
        userDeniedGeolocationError(e: Error): void {
            if (this.device === 'ios') {
                this.geolocModalError[0].children[0].label = e.message;
                this.showGeolocModal = true;
            } else {
                alert(
                    'Nous ne pouvons pas récupérer votre géolocalisation à cause des paramètres de votre appareil.\n' +
                        "Veuillez vous rapprocher d'un responsable pour qu'il vous donne la procédure à suivre",
                );
            }
        },
        validateOfflineSelection(): void {
            this.projectsStore.$patch({
                saveFullProject: false,
                validationOfflineDownload: true,
            });
        },
        zoom(direction: 'in' | 'out'): void {
            if (this.map) {
                const view = this.map.getView();
                const zoom = view.getZoom();
                if (zoom) {
                    if (direction === 'in') {
                        view.setZoom(zoom + 1);
                    }
                    if (direction === 'out') {
                        view.setZoom(zoom - 1);
                    }
                }
            }
        },
    },
});
</script>

<style scoped lang="scss">
@import 'main';

.color-gray {
    color: var(--grey-mid-light);
}
</style>
