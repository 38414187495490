/*
Store to save form data.
 */
// TODO : Move all FormWrapper logic here

import { acceptHMRUpdate, defineStore } from 'pinia';

export interface DataInterface {
    changes?: unknown;
    form?: unknown;
    images?: unknown;
    latitude?: number;
    layerName?: string;
    longitude?: number;
    mandatoryFields?: Array<string>;
    objectId?: string | number;
    prevRoute?: unknown;
    projectId?: string;
    valueMissing: boolean;
}

const useFormStore = defineStore('form', {
    actions: {
        // saveTemporaryDataForm(key: string, newValue: string | boolean | number): void {},
    },
    state(): DataInterface {
        return {
            changes: undefined,
            form: undefined,
            images: undefined,
            latitude: undefined,
            layerName: undefined,
            longitude: undefined,
            mandatoryFields: undefined,
            objectId: undefined,
            prevRoute: undefined,
            projectId: undefined,
            valueMissing: false,
        };
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useFormStore, import.meta.hot));
}

export default useFormStore;
