<template>
    <FormWrapper
        v-if="!loading"
        ref="customFormRef"
        :images-model="images"
        :form-model="form"
        :mandatory-fields="mandatoryFields"
        :changes-model="changes"
        :layer-name="layerName"
        :object-id="objectId"
        :project-id="projectId"
        :latitude="latitude"
        :longitude="longitude"
        @update:images-model="
            (e) => {
                images = e;
            }
        "
        @update:value-missing="
            (e) => {
                valueMissing = e;
            }
        "
        @update:form-model="
            (e) => {
                form = e;
            }
        "
        @update:changes-model="
            (e) => {
                changes = e;
            }
        "
    >
        <template #menu_tabs>
            <el-row class="scroll-menu">
                <el-col :span="8">
                    <div
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'environment' }"
                        @click="selectTab('environment')"
                    >
                        <FontAwesomeIcon
                            size="xs"
                            icon="leaf"
                        />
                    </div>
                </el-col>
                <el-col :span="8">
                    <div
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'box' }"
                        @click="selectTab('box')"
                    >
                        <FontAwesomeIcon
                            size="xs"
                            icon="box"
                        />
                    </div>
                </el-col>
                <el-col :span="8">
                    <div
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'optical' }"
                        @click="selectTab('optical')"
                    >
                        <FontAwesomeIcon icon="diagram-project" />
                    </div>
                </el-col>
            </el-row>
        </template>
        <template #main_form>
            <el-row>
                <el-col
                    v-show="selectedTab === 'environment'"
                    :span="24"
                >
                    <CustomInput
                        :label="labels.code"
                        :value="form.code"
                        :disabled="true"
                        :maxlength="255"
                    />
                    <CustomInput
                        :label="labels.urlexcel"
                        :value="form.urlexcel"
                        :disabled="true"
                        :maxlength="255"
                    />
                    <CustomDatePicker
                        :label="labels.date_emission_rsv"
                        :value="form.date_emission_rsv"
                        @update:value="
                            (newValue) =>
                                saveData('date_emission_rsv', newValue)
                        "
                    />
                    <CustomInputSelect
                        :options="options.rec_boitier_real"
                        :class="{ 'text-warning': valueMissing }"
                        :label="labels.rec_boitier_real + ' *'"
                        :value="form.rec_boitier_real"
                        @update:value="
                            (newValue) => {
                                saveData('rec_boitier_real', newValue);
                                if (newValue === 'CONFORME') {
                                    level = 0;
                                } else {
                                    level = 1;
                                }
                            }
                        "
                    />
                    <CustomInputSelect
                        :options="options.coord_gps"
                        :label="labels.coord_gps"
                        :value="form.coord_gps"
                        @update:value="
                            (newValue) => saveData('coord_gps', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.environnement"
                        :label="labels.environnement"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('environnement', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.support"
                        :value="form.support"
                        :disabled="true"
                        :maxlength="255"
                    />
                    <CustomInput
                        :label="labels.id_parent"
                        :value="form.id_parent"
                        :disabled="true"
                        :maxlength="255"
                    />
                    <CustomInput
                        :label="labels.type_struc"
                        :value="form.type_struc"
                        :disabled="true"
                        :maxlength="255"
                    />
                    <CustomInput
                        :label="labels.proprietai"
                        :value="form.proprietai"
                        :disabled="true"
                        :maxlength="255"
                    />
                    <CustomInput
                        :label="labels.gestionnai"
                        :value="form.gestionnai"
                        :disabled="true"
                        :maxlength="255"
                    />
                    <CustomInput
                        :label="labels.emprise"
                        :value="form.emprise"
                        :disabled="true"
                        :maxlength="255"
                    />
                    <CustomInputSelect
                        v-show="level === 0"
                        :options="options.type_struc_pt"
                        :label="labels.type_struc_pt"
                        :value="form.type_struc_pt"
                        @update:value="
                            (newValue) => saveData('type_struc_pt', newValue)
                        "
                    />
                    <CustomInputSelect
                        v-show="level === 0"
                        :options="options.type_gest_pt"
                        :label="labels.type_gest_pt"
                        :value="form.type_gest_pt"
                        @update:value="
                            (newValue) => saveData('type_gest_pt', newValue)
                        "
                    />
                    <CustomInputMultiSelect
                        v-show="level === 0"
                        :options="options.tampon_cadre"
                        :label="labels.tampon_cadre"
                        :value="form.tampon_cadre"
                        :disabled-option-value="'CONFORME'"
                        @update:value="
                            (newValue) => saveData('tampon_cadre', newValue)
                        "
                    />
                    <InputCamera
                        v-show="level === 0"
                        :images="images.infrastructure"
                        :label="labels.infrastructure"
                        :layer-name="layerName"
                        :ai="true"
                        :ai-expected-elements="[
                            'tampon_ouvert',
                            'tampon_ferme',
                            'facade',
                            'tuyau',
                            'poteau',
                        ]"
                        @update:images="
                            (newValue) => saveImage('infrastructure', newValue)
                        "
                    />
                </el-col>
                <el-col
                    v-show="selectedTab === 'box'"
                    :span="24"
                >
                    <CustomInputMultiSelect
                        v-show="level === 0"
                        :options="options.cable_terrain"
                        :label="labels.cable_terrain"
                        :value="form.cable_terrain"
                        :disabled-option-value="'CONFORME'"
                        @update:value="
                            (newValue) => saveData('cable_terrain', newValue)
                        "
                    />
                    <InputCamera
                        v-show="level === 0"
                        :images="images.cb_love"
                        :label="labels.cb_love"
                        :layer-name="layerName"
                        :ai="true"
                        :ai-expected-elements="[
                            'lovage_correct',
                            'lovage_incorrect',
                        ]"
                        @update:images="
                            (newValue) => saveImage('cb_love', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.hauteur_boitier"
                        :label="labels.hauteur_boitier"
                        :layer-name="layerName"
                        :ai="true"
                        @update:images="
                            (newValue) => saveImage('hauteur_boitier', newValue)
                        "
                        @ai-prediction="hauteurBoitierDetection"
                    />
                    <CustomInputMultiSelect
                        v-show="level === 0"
                        :options="options.fixation_boitier"
                        :label="labels.fixation_boitier"
                        :value="form.fixation_boitier"
                        :disabled-option-value="'CONFORME'"
                        @update:value="
                            (newValue) => saveData('fixation_boitier', newValue)
                        "
                    />
                    <InputCamera
                        v-show="level === 0"
                        :images="images.cb_fix"
                        :label="labels.cb_fix"
                        :layer-name="layerName"
                        :ai="true"
                        :ai-expected-elements="['pbo_souterrain', 'pbo']"
                        @update:images="
                            (newValue) => saveImage('cb_fix', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.amont"
                        :value="form.amont"
                        :disabled="true"
                        :maxlength="255"
                    />
                    <CustomInput
                        :label="labels.capaamont"
                        :value="form.capaamont"
                        :disabled="true"
                        :maxlength="255"
                    />
                    <CustomInputMultiSelect
                        v-show="level === 0"
                        :options="options.cable_sig"
                        :label="labels.cable_sig"
                        :value="form.cable_sig"
                        :disabled-option-value="'CONFORME'"
                        @update:value="
                            (newValue) => saveData('cable_sig', newValue)
                        "
                    />
                    <InputCamera
                        v-show="level === 0"
                        :images="images.eti_cable"
                        :label="labels.eti_cable"
                        :layer-name="layerName"
                        :ai="true"
                        :ai-expected-elements="[
                            'etiquette_finale',
                            'etiquette_provisoire',
                        ]"
                        :ai-expected-text="{
                            conforme: String(form.amont),
                            incomplet: String(form.amont).slice(-4),
                        }"
                        :ai-text-case-sensitive="true"
                        @update:images="
                            (newValue) => saveImage('eti_cable', newValue)
                        "
                        @ai-text-recognition="etiquetteCableDetection"
                    />
                    <CustomInputMultiSelect
                        v-show="level === 0"
                        :options="options.etiquette_cable"
                        :label="labels.etiquette_cable"
                        :value="form.etiquette_cable"
                        :disabled-option-value="'CONFORME'"
                        @update:value="
                            (newValue) => saveData('etiquette_cable', newValue)
                        "
                    />
                    <InputCamera
                        v-show="level === 0"
                        :images="images.eti_boite"
                        :label="labels.eti_boite"
                        :layer-name="layerName"
                        :ai="true"
                        :ai-expected-elements="[
                            'etiquette_finale',
                            'etiquette_provisoire',
                        ]"
                        :ai-text-case-sensitive="true"
                        :ai-expected-text="{
                            conforme: String(form.code),
                            incomplet: String(form.code).slice(-4),
                        }"
                        @update:images="
                            (newValue) => saveImage('eti_boite', newValue)
                        "
                        @ai-text-recognition="etiquetteBoiteDetection"
                    />
                    <CustomInputMultiSelect
                        v-show="level === 0"
                        :options="options.etiquette_boitier"
                        :label="labels.etiquette_boitier"
                        :value="form.etiquette_boitier"
                        :disabled-option-value="'CONFORME'"
                        :class="{ 'text-warning': valueMissing }"
                        @update:value="
                            (newValue) =>
                                saveData('etiquette_boitier', newValue)
                        "
                    />
                    <CustomInputMultiSelect
                        v-show="level === 0"
                        :options="options.info_boitier_sig"
                        :label="labels.info_boitier_sig"
                        :value="form.info_boitier_sig"
                        :disabled-option-value="'CONFORME'"
                        @update:value="
                            (newValue) => saveData('info_boitier_sig', newValue)
                        "
                    />
                    <CustomInputMultiSelect
                        v-show="level === 0"
                        :options="options.etat_boitier"
                        :label="labels.etat_boitier"
                        :value="form.etat_boitier"
                        :disabled-option-value="'CONFORME'"
                        @update:value="
                            (newValue) => saveData('etat_boitier', newValue)
                        "
                    />
                    <InputCamera
                        v-show="level === 0"
                        :images="images.intb_ens"
                        :label="labels.intb_ens"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('intb_ens', newValue)
                        "
                    />
                    <CustomInputMultiSelect
                        v-show="level === 0"
                        :options="options.entree_cable"
                        :label="labels.entree_cable"
                        :value="form.entree_cable"
                        :disabled-option-value="'CONFORME'"
                        @update:value="
                            (newValue) => saveData('entree_cable', newValue)
                        "
                    />
                    <CustomInputMultiSelect
                        v-show="level === 0"
                        :options="options.arrimage_cable"
                        :label="labels.arrimage_cable"
                        :value="form.arrimage_cable"
                        :disabled-option-value="'CONFORME'"
                        @update:value="
                            (newValue) => saveData('arrimage_cable', newValue)
                        "
                    />
                    <InputCamera
                        v-show="level === 0"
                        :images="images.cb_entree"
                        :label="labels.cb_entree"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('cb_entree', newValue)
                        "
                    />
                </el-col>
                <el-col
                    v-show="selectedTab === 'optical'"
                    :span="24"
                >
                    <CustomInputMultiSelect
                        v-show="level === 0"
                        :options="options.tubes"
                        :label="labels.tubes"
                        :value="form.tubes"
                        :disabled-option-value="'CONFORME'"
                        @update:value="
                            (newValue) => saveData('tubes', newValue)
                        "
                    />
                    <InputCamera
                        v-show="level === 0"
                        :images="images.intb_tubes"
                        :label="labels.intb_tubes"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('intb_tubes', newValue)
                        "
                    />
                    <CustomInputMultiSelect
                        v-show="level === 0"
                        :options="options.cassette"
                        :label="labels.cassette"
                        :value="form.cassette"
                        :disabled-option-value="'CONFORME'"
                        @update:value="
                            (newValue) => saveData('cassette', newValue)
                        "
                    />
                    <InputCamera
                        v-show="level === 0"
                        :images="images.intb_k7"
                        :label="labels.intb_k7"
                        :layer-name="layerName"
                        :ai="true"
                        @update:images="
                            (newValue) => saveImage('intb_k7', newValue)
                        "
                        @ai-prediction="lovageDetection"
                    />
                    <CustomInputMultiSelect
                        v-show="level === 0"
                        :options="options.fibres"
                        :label="labels.fibres"
                        :value="form.fibres"
                        :disabled-option-value="'CONFORME'"
                        @update:value="
                            (newValue) => saveData('fibres', newValue)
                        "
                    />
                    <CustomInputMultiSelect
                        v-show="level === 0"
                        :options="options.plan_boite"
                        :label="labels.plan_boite + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.plan_boite"
                        :disabled-option-value="'CONFORME'"
                        @update:value="
                            (newValue) => saveData('plan_boite', newValue)
                        "
                    />
                    <InputCamera
                        v-show="level === 0"
                        :images="images.intb_optique"
                        :label="labels.intb_optique"
                        :layer-name="layerName"
                        :ai="true"
                        :ai-expected-elements="['continuite_optique']"
                        @update:images="
                            (newValue) => saveImage('intb_optique', newValue)
                        "
                        @ai-prediction="continuiteOptiqueDetection"
                    />
                    <CustomInputMultiSelect
                        v-show="level === 0"
                        :options="options.continuite_optique"
                        :label="labels.continuite_optique + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.continuite_optique"
                        :disabled-option-value="'CONFORME'"
                        @update:value="
                            (newValue) =>
                                saveData('continuite_optique', newValue)
                        "
                    />
                    <CustomInputSelect
                        v-show="level === 0"
                        :options="options.criticite_remarque"
                        :label="labels.criticite_remarque"
                        :value="form.criticite_remarque"
                        :disabled-option-value="'CONFORME'"
                        @update:value="
                            (newValue) =>
                                saveData('criticite_remarque', newValue)
                        "
                    />
                    <CustomInputTextarea
                        v-show="level === 0 && form.criticite_remarque"
                        :label="labels.remarque_supplementaire"
                        :value="form.remarque_supplementaire"
                        :maxlength="255"
                        @update:value="
                            (newValue) =>
                                saveData('remarque_supplementaire', newValue)
                        "
                    />
                    <InputCamera
                        v-show="level === 0"
                        :images="images.autres_remarques"
                        :label="labels.autres_remarques"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('autres_remarques', newValue)
                        "
                    />
                    <CustomInputSelect
                        :options="options.statut_audit"
                        :label="labels.statut_audit + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.statut_audit"
                        @update:value="
                            (newValue) => saveData('statut_audit', newValue)
                        "
                    />
                </el-col>
            </el-row>
        </template>
    </FormWrapper>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import {
    labels,
    options,
} from '@connect-field/client/components/forms/demo/Boite_IA.data';
import type { FormDataInterface } from '@connect-field/client/components/forms/form.types';
import FormTemplate from '@connect-field/client/components/forms/FormTemplate.vue';
import type {
    LabelCord,
    PBOPostProcess,
    PredictImageResponseDto,
} from '@connect-field/client/sdk/generated';
import { LabelCordLabelEnum } from '@connect-field/client/sdk/generated';

interface DataInterface extends FormDataInterface {}

export default defineComponent({
    extends: FormTemplate,
    data(): DataInterface {
        return {
            changes: false,
            form: {},
            images: {
                autres_remarques: [],
                cb_entree: [],
                cb_fix: [],
                cb_love: [],
                environnement: [],
                eti_boite: [],
                eti_cable: [],
                hauteur_boitier: [],
                infrastructure: [],
                intb_ens: [],
                intb_k7: [],
                intb_optique: [],
                intb_tubes: [],
            },
            labels,
            level: 0,
            loading: true,
            mandatoryFields: [
                'continuite_optique',
                'etiquette_boitier',
                'plan_boite',
                'rec_boitier_real',
                'statut_audit',
            ],
            options,
            selectedTab: 'environment',
            valueMissing: false,
        };
    },
    methods: {
        continuiteOptiqueDetection(predictImage: PredictImageResponseDto) {
            predictImage.label_coord.forEach((label: LabelCord) => {
                if (
                    label.confidence <= 0.3 ||
                    label.label !== LabelCordLabelEnum.CONTINUITE_OPTIQUE
                ) {
                    return;
                }

                this.saveData('continuite_optique', [
                    options.continuite_optique[0].value,
                ]);
            });
        },
        etiquetteBoiteDetection(textResults: Record<string, boolean>) {
            if (textResults.conforme) {
                return this.saveData('etiquette_boitier', [
                    options.etiquette_boitier[0].value,
                ]);
            }
            if (textResults.incomplet) {
                return this.saveData('etiquette_boitier', [
                    options.etiquette_boitier[3].value,
                ]);
            }
        },
        etiquetteCableDetection(textResults: Record<string, boolean>) {
            if (textResults.conforme) {
                return this.saveData('etiquette_cable', [
                    options.etiquette_cable[0].value,
                ]);
            }
            if (textResults.incomplet) {
                return this.saveData('etiquette_cable', [
                    options.etiquette_cable[4].value,
                ]);
            }
        },
        hauteurBoitierDetection(predictImage: PredictImageResponseDto) {
            predictImage?.post_process?.pbo_post_processing?.forEach(
                (pbo: PBOPostProcess) => {
                    if (pbo.correct_height) {
                        return this.saveData('fixation_boitier', [
                            options.fixation_boitier[0].value,
                        ]);
                    }
                    if (pbo.approximate_distance_from_ground_cm < 400) {
                        return this.saveData('fixation_boitier', [
                            options.fixation_boitier[3].value,
                            options.fixation_boitier[4].value,
                        ]);
                    }

                    return this.saveData('fixation_boitier', [
                        options.fixation_boitier[4].value,
                    ]);
                },
            );
        },
        lovageDetection(predictImage: PredictImageResponseDto) {
            predictImage.label_coord.forEach((label: LabelCord) => {
                if (label.confidence <= 0.3) {
                    return;
                }

                if (label.label === LabelCordLabelEnum.LOVAGE_CORRECT) {
                    this.saveData('fibres', [options.fibres[0].value]);
                } else if (
                    label.label === LabelCordLabelEnum.LOVAGE_INCORRECT
                ) {
                    this.saveData('fibres', [options.fibres[5].value]);
                }
            });
        },
    },
});
</script>

<style lang="scss" scoped>
.text-warning .custom_label_input {
    color: red;
}
</style>
