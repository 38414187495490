import type { DowntimeResponseDto, VersionResponseDto } from '@connect-field/client/sdk/generated';
import HTTP from '@connect-field/client/utilities/http';

export async function getClientDownTime(): Promise<DowntimeResponseDto> {
    const response = await HTTP.client().ClientSettingsApi.clientSettingsControllerGetDownTime();

    if (!response.data?.time || !response.data?.message) {
        throw new Error('downtime undefined');
    }

    return response.data;
}

export async function getClientVersion(): Promise<VersionResponseDto> {
    const response = await HTTP.client().ClientSettingsApi.clientSettingsControllerGetVersion();

    if (!response?.data?.version) {
        throw new Error('version undefined');
    }

    return response.data;
}
