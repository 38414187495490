<template>
    <div>
        <BasePanelItemHeader :title="$t('settings.title')" />
        <main class="main">
            <div class="profile">
                <el-icon class="profile__icon">
                    <user-filled />
                </el-icon>
                <div class="profile__info">
                    <p
                        v-if="!error"
                        class="profile__info--name"
                    >
                        {{ user.name }}
                    </p>
                    <p
                        v-if="!error"
                        class="profile__info--mail"
                    >
                        {{ user.email }}
                    </p>
                    <p
                        v-else
                        class="profile__info--error"
                    >
                        {{ errorContent }}
                    </p>
                </div>
            </div>
            <div class="category">
                <p>{{ $t('settings.displayZoom') }}</p>
                <el-switch
                    :model-value="zoomDisplay"
                    @change="toggleZoomDisplay"
                />
            </div>
            <div class="category">
                <p>{{ $t('settings.displayRuler') }}</p>
                <el-switch
                    :model-value="rulerDisplay"
                    @change="toggleRulerDisplay"
                />
            </div>
            <div class="category">
                <button
                    v-show="!isDisabled()"
                    class="btn--link"
                    @click="dumpData"
                >
                    <FontAwesomeIcon icon="file-medical-alt" />
                    {{ $t('settings.sendDebug') }}
                </button>
                <button
                    v-show="isDisabled()"
                    class="btn--link"
                    disabled="disabled"
                >
                    <FontAwesomeIcon icon="file-medical-alt" />
                    {{ $t('settings.debugIP') }}
                </button>
            </div>

            <BaseDialog
                :show="showDisconnectAlert"
                :title="$t('settings.logout')"
                @close="cancelLogout"
            >
                <p>{{ $t('settings.leaveConfirmation') }}</p>
                <template #actions>
                    <BaseDialogButton
                        :label="$t('globals.cancel')"
                        button-type="default"
                        @click="cancelLogout"
                    />
                    <BaseDialogButton
                        :label="$t('settings.logout')"
                        button-type="danger"
                        @click="confirmLogout"
                    />
                </template>
            </BaseDialog>

            <button
                class="btn--link"
                @click="onLogout"
            >
                <FontAwesomeIcon icon="sign-out-alt" />{{
                    $t('settings.logout')
                }}
            </button>
            <div class="build_version">
                <api-status />
                <build-version />
                <estimate-usage />
            </div>
        </main>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import { UserFilled } from '@element-plus/icons-vue';

import * as DebugApi from '@connect-field/client/services/api/debug';
import {
    getAllKeys,
    getItem,
} from '@connect-field/client/utilities/idb-utility';
import ApiStatus from '@connect-field/client/components/atoms/ApiStatus.vue';
import BaseDialog from '@connect-field/client/components/ui/BaseDialog.vue';
import BaseDialogButton from '@connect-field/client/components/ui/BaseDialogButton.vue';
import BasePanelItemHeader from '@connect-field/client/components/ui/BasePanelItemHeader.vue';
import BuildVersion from '@connect-field/client/components/atoms/BuildVersion.vue';
import EstimateUsage from '@connect-field/client/components/atoms/EstimateUsage.vue';
import useAuthStore from '@connect-field/client/stores/auth';
import useBannerStore from '@connect-field/client/stores/banner';
import useMapStore from '@connect-field/client/stores/map';
import useUserStore from '@connect-field/client/stores/user';

export default defineComponent({
    components: {
        ApiStatus,
        BaseDialog,
        BaseDialogButton,
        BasePanelItemHeader,
        BuildVersion,
        EstimateUsage,
        UserFilled,
    },
    setup() {
        return {
            authStore: useAuthStore(),
            bannerStore: useBannerStore(),
            mapStore: useMapStore(),
        };
    },
    data() {
        return {
            error: false,
            errorContent: 'Une erreur est survenue',
            showDisconnectAlert: false,
            uploading: false,
        };
    },
    computed: {
        ...mapState(useUserStore, {
            user: 'user',
        }),
        ...mapState(useMapStore, {
            rulerDisplay: 'rulerDisplay',
            zoomDisplay: 'zoomDisplay',
        }),
    },
    methods: {
        cancelLogout(): void {
            this.showDisconnectAlert = false;
        },
        confirmLogout(): void {
            this.authStore.logout();
            this.$router.replace('/auth');
            this.showDisconnectAlert = false;
        },
        async dumpData(): Promise<void> {
            try {
                this.uploading = true;
                this.bannerStore.resetBanner();
                this.bannerStore.showBannerAction({
                    isClosable: false,
                    message: 'Transfert des données de debug en cours', // TODO : i18n
                    type: 'info',
                });
                const uuid = `${new Date().toISOString()}-${Math.random()
                    .toString()
                    .slice(2)}`;
                try {
                    await [
                        'projectImages_v2',
                        'projectGeojsons',
                        'offlineProjects',
                        'draftDataForms',
                    ].reduce(async (previousPromise, store) => {
                        await previousPromise;

                        const keys = await getAllKeys(store);

                        return keys.reduce(async (_previousPromise, key) => {
                            await _previousPromise;

                            const value = await getItem(key, store);
                            const data = { key, value };

                            return DebugApi.saveDebugData({
                                data,
                                store,
                                uuid,
                            });
                        }, Promise.resolve());
                    }, Promise.resolve());

                    this.bannerStore.showBannerAction({
                        message: 'Les données ont bien été envoyées', // TODO : i18n
                        type: 'success',
                    });
                } catch (error: unknown) {
                    this.bannerStore.showBannerAction({
                        message: 'Problème avec le transfert des données', // TODO : i18n
                        type: 'error',
                    });
                } finally {
                    this.uploading = false;
                }
            } catch (error: unknown) {
                this.uploading = false;
                throw error;
            }
        },
        isDisabled(): string {
            return this.uploading ? 'disabled' : '';
        },
        onLogout(): void {
            this.showDisconnectAlert = true;
        },
        toggleRulerDisplay(): void {
            this.mapStore.toggleRulerDisplay();
        },
        toggleZoomDisplay(): void {
            this.mapStore.toggleZoomDisplay();
        },
    },
});
</script>

<style scoped lang="scss">
@import 'Settings.scss';
</style>
