<template>
    <FormWrapper
        v-if="!loading"
        ref="customFormRef"
        :images-model="images"
        :form-model="form"
        :mandatory-fields="mandatoryFields"
        :changes-model="changes"
        :layer-name="layerName"
        :object-id="objectId"
        :project-id="projectId"
        :latitude="latitude"
        :longitude="longitude"
        @update:images-model="
            (e) => {
                images = e;
            }
        "
        @update:value-missing="
            (e) => {
                valueMissing = e;
            }
        "
        @update:form-model="
            (e) => {
                form = e;
            }
        "
        @update:changes-model="
            (e) => {
                changes = e;
            }
        "
    >
        <template #menu_tabs>
            <el-row class="scroll-menu">
                <el-col :span="12">
                    <div
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'general' }"
                        @click="selectTab('general')"
                    >
                        Général
                    </div>
                </el-col>
                <el-col :span="12">
                    <div
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'site_sro' }"
                        @click="selectTab('site_sro')"
                    >
                        Site SRO
                    </div>
                </el-col>
                <el-col :span="12">
                    <div
                        class="tabs"
                        :class="{
                            selectedTab: selectedTab === 'tiroir_transport',
                        }"
                        @click="selectTab('tiroir_transport')"
                    >
                        Tiroir Transport
                    </div>
                </el-col>
                <el-col :span="12">
                    <div
                        class="tabs"
                        :class="{
                            selectedTab: selectedTab === 'tiroir_coupleur',
                        }"
                        @click="selectTab('tiroir_coupleur')"
                    >
                        Tiroir Coupleur
                    </div>
                </el-col>
            </el-row>
        </template>
        <template #main_form>
            <el-row>
                <el-col
                    v-show="selectedTab === 'general'"
                    :span="24"
                >
                    <CustomInput
                        :label="labels.code"
                        :value="form.code"
                        :disabled="true"
                        :maxlength="255"
                    />
                    <CustomDatePicker
                        :label="labels.date_audit + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.date_audit"
                        @update:value="
                            (newValue) => saveData('date_audit', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.adresse + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.adresse"
                        :disabled="true"
                        :maxlength="255"
                    />
                    <CustomInputSelect
                        :options="options.statut"
                        :label="labels.statut + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.statut"
                        @update:value="
                            (newValue) => saveData('statut', newValue)
                        "
                    />
                </el-col>
                <el-col
                    v-show="selectedTab === 'site_sro'"
                    :span="24"
                >
                    <CustomInputSelect
                        :options="options.ss_type"
                        :label="labels.ss_type"
                        :value="form.ss_type"
                        @update:value="
                            (newValue) => saveData('ss_type', newValue)
                        "
                    />

                    <CustomInputSwitch
                        :options="options.ss_clef"
                        :label="labels.ss_clef"
                        :value="form.ss_clef"
                        @update:value="
                            (newValue) => saveData('ss_clef', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :options="options.ss_type_clef"
                        :label="labels.ss_type_clef"
                        :value="form.ss_type_clef"
                        @update:value="
                            (newValue) => saveData('ss_type_clef', newValue)
                        "
                    />
                    <CustomInputSelect
                        :options="options.ss_site"
                        :label="labels.ss_site"
                        :value="form.ss_site"
                        @update:value="
                            (newValue) => saveData('ss_site', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :options="options.ss_acces"
                        :label="labels.ss_acces"
                        :value="form.ss_acces"
                        @update:value="
                            (newValue) => saveData('ss_acces', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :options="options.ss_etat_general"
                        :label="labels.ss_etat_general"
                        :value="form.ss_etat_general"
                        @update:value="
                            (newValue) => saveData('ss_etat_general', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :options="options.ss_fermeture"
                        :label="labels.ss_fermeture"
                        :value="form.ss_fermeture"
                        @update:value="
                            (newValue) => saveData('ss_fermeture', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.baie_ouverte"
                        :label="labels.baie_ouverte"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('baie_ouverte', newValue)
                        "
                    />

                    <CustomInputSwitch
                        :options="options.ss_plaquebaie_pe"
                        :label="labels.ss_plaquebaie_pe"
                        :value="form.ss_plaquebaie_pe"
                        @update:value="
                            (newValue) => saveData('ss_plaquebaie_pe', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.baiea1"
                        :label="labels.baiea1"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('baiea1', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.baiea2"
                        :label="labels.baiea2"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('baiea2', newValue)
                        "
                    />

                    <CustomInputSwitch
                        :options="options.ss_obturationfourreau"
                        :label="labels.ss_obturationfourreau"
                        :value="form.ss_obturationfourreau"
                        @update:value="
                            (newValue) =>
                                saveData('ss_obturationfourreau', newValue)
                        "
                    />

                    <CustomInput
                        :label="labels.ss_obs"
                        :value="form.ss_obs"
                        :maxlength="255"
                        @update:value="
                            (newValue) => saveData('ss_obs', newValue)
                        "
                    />
                </el-col>
                <el-col
                    v-show="selectedTab === 'tiroir_transport'"
                    :span="24"
                >
                    <CustomInputSwitch
                        :options="options.tt_pos"
                        :label="labels.tt_pos"
                        :value="form.tt_pos"
                        @update:value="
                            (newValue) => saveData('tt_pos', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :options="options.tt_etat"
                        :label="labels.tt_etat"
                        :value="form.tt_etat"
                        @update:value="
                            (newValue) => saveData('tt_etat', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :options="options.tt_eti_pe"
                        :label="labels.tt_eti_pe"
                        :value="form.tt_eti_pe"
                        @update:value="
                            (newValue) => saveData('tt_eti_pe', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.ttr"
                        :label="labels.ttr"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('ttr', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :options="options.tt_capuchon_pe"
                        :label="labels.tt_capuchon_pe"
                        :value="form.tt_capuchon_pe"
                        @update:value="
                            (newValue) => saveData('tt_capuchon_pe', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :options="options.tt_connecteur"
                        :label="labels.tt_connecteur"
                        :value="form.tt_connecteur"
                        @update:value="
                            (newValue) => saveData('tt_connecteur', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :options="options.tt_ri"
                        :label="labels.tt_ri"
                        :value="form.tt_ri"
                        @update:value="
                            (newValue) => saveData('tt_ri', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.plateaux_ttr"
                        :label="labels.plateaux_ttr"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('plateaux_ttr', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :options="options.tt_terrain_sig"
                        :label="labels.tt_terrain_sig"
                        :value="form.tt_terrain_sig"
                        @update:value="
                            (newValue) => saveData('tt_terrain_sig', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :options="options.tt_mesure"
                        :label="labels.tt_mesure"
                        :value="form.tt_mesure"
                        @update:value="
                            (newValue) => saveData('tt_mesure', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.tt_obs"
                        :value="form.tt_obs"
                        :maxlength="255"
                        @update:value="
                            (newValue) => saveData('tt_obs', newValue)
                        "
                    />
                </el-col>
                <el-col
                    v-show="selectedTab === 'tiroir_coupleur'"
                    :span="24"
                >
                    <CustomInputSwitch
                        :options="options.tc_position"
                        :label="labels.tc_position"
                        :value="form.tc_position"
                        @update:value="
                            (newValue) => saveData('tc_position', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :options="options.tc_etat"
                        :label="labels.tc_etat"
                        :value="form.tc_etat"
                        @update:value="
                            (newValue) => saveData('tc_etat', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :options="options.tc_eti_pe"
                        :label="labels.tc_eti_pe"
                        :value="form.tc_eti_pe"
                        @update:value="
                            (newValue) => saveData('tc_eti_pe', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.cpl"
                        :label="labels.cpl"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('cpl', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :options="options.tc_capuchon_pe"
                        :label="labels.tc_capuchon_pe"
                        :value="form.tc_capuchon_pe"
                        @update:value="
                            (newValue) => saveData('tc_capuchon_pe', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :options="options.tc_connecteur"
                        :label="labels.tc_connecteur"
                        :value="form.tc_connecteur"
                        @update:value="
                            (newValue) => saveData('tc_connecteur', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :options="options.tc_plaque_pe"
                        :label="labels.tc_plaque_pe"
                        :value="form.tc_plaque_pe"
                        @update:value="
                            (newValue) => saveData('tc_plaque_pe', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :options="options.tc_jarretiere_pe"
                        :label="labels.tc_jarretiere_pe"
                        :value="form.tc_jarretiere_pe"
                        @update:value="
                            (newValue) => saveData('tc_jarretiere_pe', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :options="options.tc_eti_jarretiere_pe"
                        :label="labels.tc_eti_jarretiere_pe"
                        :value="form.tc_eti_jarretiere_pe"
                        @update:value="
                            (newValue) =>
                                saveData('tc_eti_jarretiere_pe', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.etiquette_jarretiere"
                        :label="labels.etiquette_jarretiere"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('etiquette_jarretiere', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.tc_obs"
                        :value="form.tc_obs"
                        :maxlength="255"
                        @update:value="
                            (newValue) => saveData('tc_obs', newValue)
                        "
                    />
                </el-col>
            </el-row>
        </template>
    </FormWrapper>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import {
    labels,
    options,
} from '@connect-field/client/components/forms/Dotic/GraceTHD-v2/SR.data';
import type { FormDataInterface } from '@connect-field/client/components/forms/form.types';
import FormTemplate from '@connect-field/client/components/forms/FormTemplate.vue';

interface DataInterface extends FormDataInterface {}

export default defineComponent({
    extends: FormTemplate,
    data(): DataInterface {
        return {
            changes: false,
            form: {},
            images: {
                abaque: [],
                baie_fermee: [],
                baie_ouverte: [],
                baiea1: [],
                baiea2: [],
                branchement_jarretiere: [],
                cpl: [],
                environnement: [],
                etiquette_cable: [],
                etiquette_ctr_nro: [],
                etiquette_jarretiere: [],
                plateaux_ttr: [],
                plateaux_ttr_nro: [],
                puissance_metre: [],
                ttr: [],
                ttr_nro: [],
            },
            labels,
            loading: true,
            mandatoryFields: ['date_audit', 'statut', 'adresse'],
            options,
            selectedTab: 'general',
            valueMissing: false,
        };
    },
});
</script>

<style lang="scss">
.el-row {
    margin: 10px;
}
.text-warning .custom_label_input {
    color: red;
}
</style>
