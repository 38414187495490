/*
Store for banner.
 */

import { acceptHMRUpdate, defineStore } from 'pinia';

const bannerOptions = {
    delayBeforeDisappear: 5000,
};

export interface StateInterface {
    bannerMessage: string;
    bannerType: string;
    hasTimeout: boolean;
    isClosable: boolean;
    showBanner: boolean;
}

const useBannerStore = defineStore('banner', {
    actions: {
        initBannerDisplay(payload: {
            hasTimeout?: boolean;
            isClosable?: boolean;
            message: string;
            type: string;
        }): void {
            const { message, type, isClosable, hasTimeout } = payload;

            if (!message) {
                console.error('A message is required when showing a banner');

                return;
            }

            if (!type) {
                console.error('A type is required when showing a banner');

                return;
            }

            if (isClosable) {
                this.isClosable = isClosable;
            }

            if (hasTimeout) {
                this.hasTimeout = hasTimeout;
            }
        },
        resetBanner(): void {
            this.bannerMessage = '';
            this.bannerType = 'info';
            this.hasTimeout = true;
            this.isClosable = true;
            this.showBanner = false;
        },
        showBannerAction(payload: { isClosable?: boolean; message: string; type: string }): void {
            this.initBannerDisplay(payload);

            const { message, type, isClosable } = payload;

            this.$patch({
                bannerMessage: message,
                bannerType: type,
                isClosable,
                showBanner: true,
            });

            if (this.hasTimeout) {
                setTimeout(() => {
                    this.$reset();
                }, bannerOptions.delayBeforeDisappear);
            }
        },
    },
    state(): StateInterface {
        return {
            bannerMessage: '',
            bannerType: 'info',
            hasTimeout: true,
            isClosable: true,
            showBanner: false,
        };
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useBannerStore, import.meta.hot));
}

export default useBannerStore;
