<template>
    <el-row>
        <el-col
            :sm="24"
            :md="12"
        >
            <div class="custom_label_input">
                {{ label }}
            </div>
        </el-col>
        <el-col
            :sm="24"
            :md="12"
        >
            <el-radio-group
                v-model="model"
                :disabled="disabled"
                :size="size"
            >
                <el-radio-button
                    v-for="option in options"
                    :key="option.value"
                    :label="option.value"
                >
                    {{ option.label }}
                </el-radio-button>
            </el-radio-group>
        </el-col>
    </el-row>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';

import type { OptionsInterface } from '@connect-field/client/components/forms/form.types';

export default defineComponent({
    name: 'CustomInputRadioButton',
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: '',
        },
        options: {
            type: Array as PropType<OptionsInterface>,
            required: true,
        },
        size: {
            type: String as PropType<'large' | 'default' | 'small'>,
            values: ['large', 'default', 'small'],
            default: 'default',
        },
        value: {
            type: String,
            default: undefined,
        },
    },
    emits: ['update:value'],
    computed: {
        model: {
            get(): string | undefined {
                return this.value;
            },
            set(value: string): void {
                this.$emit('update:value', value);
            },
        },
    },
});
</script>
