<template>
    <div v-if="cables">
        <div
            v-for="(cable, key) in cables"
            :key="key"
            class="flex flex-row"
        >
            <div class="flex flex-1 flex-column p-1">
                <CableData
                    v-if="isNotEmpty(cable.entrant)"
                    :id="key"
                    :data="cable.entrant"
                    title="Câble entrant"
                />
            </div>
            <div class="flex flex-1 flex-col p-1">
                <CableData
                    v-if="isNotEmpty(cable.sortant)"
                    :id="key"
                    :data="cable.sortant"
                    title="Câble sortant"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Check, Close, Plus } from '@element-plus/icons-vue';
import { defineComponent } from 'vue';
import { mapState } from 'pinia';

import {
    labels,
    options,
} from '@connect-field/client/components/forms/PlanBoite/PlanBoite.data';
import CableData from '@connect-field/client/components/atoms/CableData.vue';
import useBoxPlanStore from '@connect-field/client/stores/boxPlan';

export default defineComponent({
    components: {
        CableData,
    },
    setup() {
        const boxPlanStore = useBoxPlanStore();
        return {
            boxPlanStore,
            Check,
            Close,
            Plus,
        };
    },
    data() {
        return { labels, options };
    },
    computed: {
        ...mapState(useBoxPlanStore, {
            cables: 'cablesData',
        }),
    },
    methods: {
        isNotEmpty(obj: object): boolean {
            return Object.keys(obj).length > 0;
        },
    },
});
</script>

<style scoped lang="scss">
h4 {
    color: white;
    display: block;
    width: 33vw;
    margin-bottom: 8px;
    border-bottom: 1px solid grey;
}
</style>
