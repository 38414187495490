<template>
    <FormWrapper
        v-if="!loading"
        ref="customFormRef"
        :images-model="images"
        :form-model="form"
        :mandatory-fields="mandatoryFields"
        :changes-model="changes"
        :layer-name="layerName"
        :object-id="objectId"
        :project-id="projectId"
        :latitude="latitude"
        :longitude="longitude"
        @update:images-model="
            (e) => {
                images = e;
            }
        "
        @update:value-missing="
            (e) => {
                valueMissing = e;
            }
        "
        @update:form-model="
            (e) => {
                form = e;
            }
        "
        @update:changes-model="
            (e) => {
                changes = e;
            }
        "
    >
        <template #menu_tabs>
            <el-row class="scroll-menu">
                <el-col :span="12">
                    <div
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'GENERAL' }"
                        @click="selectTab('GENERAL')"
                    >
                        GENERAL
                    </div>
                </el-col>

                <el-col :span="12">
                    <div
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'BPE' }"
                        @click="selectTab('BPE')"
                    >
                        BPE
                    </div>
                </el-col>
            </el-row>
        </template>
        <template #main_form>
            <el-row>
                <el-col
                    v-show="selectedTab === 'GENERAL'"
                    :span="24"
                >
                    <CustomInput
                        :label="labels.bp_code"
                        :value="form.bp_code"
                        :disabled="true"
                    />
                    <CustomInputSelect
                        :label="labels.statut + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.statut"
                        :value="form.statut"
                        @update:value="
                            (newValue) => saveData('statut', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.proprete"
                        :options="options.proprete"
                        :value="form.proprete"
                        @update:value="
                            (newValue) => saveData('proprete', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.bp_pt_code"
                        :value="form.bp_pt_code"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('bp_pt_code', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.bp_perirec"
                        :value="form.bp_perirec"
                        @update:value="
                            (newValue) => saveData('bp_perirec', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.bp_etiquet"
                        :value="form.bp_etiquet"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('bp_etiquet', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.bp_codeext"
                        :value="form.bp_codeext"
                        @update:value="
                            (newValue) => saveData('bp_codeext', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.bp_abandon"
                        :options="options.bp_abandon"
                        :value="form.bp_abandon"
                        @update:value="
                            (newValue) => saveData('bp_abandon', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.bp_lc_code"
                        :value="form.bp_lc_code"
                        @update:value="
                            (newValue) => saveData('bp_lc_code', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.bp_prop"
                        :value="form.bp_prop"
                        :maxlength="20"
                        @update:value="
                            (newValue) => saveData('bp_prop', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.bp_gest"
                        :value="form.bp_gest"
                        @update:value="
                            (newValue) => saveData('bp_gest', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.bp_user"
                        :value="form.bp_user"
                        :maxlength="20"
                        @update:value="
                            (newValue) => saveData('bp_user', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.bp_proptyp"
                        :options="options.bp_proptyp"
                        :value="form.bp_proptyp"
                        @update:value="
                            (newValue) => saveData('bp_proptyp', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.bp_statut"
                        :options="options.bp_statut"
                        :value="form.bp_statut"
                        @update:value="
                            (newValue) => saveData('bp_statut', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.bp_etat"
                        :options="options.bp_etat"
                        :value="form.bp_etat"
                        @update:value="
                            (newValue) => saveData('bp_etat', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.bp_occp"
                        :options="options.bp_occp"
                        :value="form.bp_occp"
                        @update:value="
                            (newValue) => saveData('bp_occp', newValue)
                        "
                    />
                    <CustomDatePicker
                        :label="labels.bp_dateins"
                        :value="form.bp_dateins"
                        @update:value="
                            (newValue) => saveData('bp_dateins', newValue)
                        "
                    />
                    <CustomDatePicker
                        :label="labels.bp_datemes"
                        :value="form.bp_datemes"
                        @update:value="
                            (newValue) => saveData('bp_datemes', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.bp_avct"
                        :options="options.bp_avct"
                        :value="form.bp_avct"
                        @update:value="
                            (newValue) => saveData('bp_avct', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.bp_typephy"
                        :options="options.bp_typephy"
                        :value="form.bp_typephy"
                        @update:value="
                            (newValue) => saveData('bp_typephy', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.bp_typelog"
                        :value="form.bp_typelog"
                        :maxlength="3"
                        @update:value="
                            (newValue) => saveData('bp_typelog', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.bp_rf_code"
                        :value="form.bp_rf_code"
                        @update:value="
                            (newValue) => saveData('bp_rf_code', newValue)
                        "
                    />
                    <InputNumber
                        :label="labels.bp_entrees"
                        :value="form.bp_entrees"
                        @update:value="
                            (newValue) => saveData('bp_entrees', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.bp_ref_kit"
                        :value="form.bp_ref_kit"
                        :maxlength="30"
                        @update:value="
                            (newValue) => saveData('bp_ref_kit', newValue)
                        "
                    />
                    <InputNumber
                        :label="labels.bp_ca_nb"
                        :value="form.bp_ca_nb"
                        @update:value="
                            (newValue) => saveData('bp_ca_nb', newValue)
                        "
                    />
                    <InputNumber
                        :label="labels.bp_nb_pas"
                        :value="form.bp_nb_pas"
                        @update:value="
                            (newValue) => saveData('bp_nb_pas', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.bp_linecod"
                        :value="form.bp_linecod"
                        :maxlength="12"
                        @update:value="
                            (newValue) => saveData('bp_linecod', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.bp_oc_code"
                        :value="form.bp_oc_code"
                        @update:value="
                            (newValue) => saveData('bp_oc_code', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.bp_racco"
                        :options="options.bp_racco"
                        :value="form.bp_racco"
                        @update:value="
                            (newValue) => saveData('bp_racco', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.bp_comment"
                        :value="form.bp_comment"
                        @update:value="
                            (newValue) => saveData('bp_comment', newValue)
                        "
                    />
                    <CustomDatePicker
                        :label="labels.bp_creadat"
                        :value="form.bp_creadat"
                        @update:value="
                            (newValue) => saveData('bp_creadat', newValue)
                        "
                    />
                    <CustomDatePicker
                        :label="labels.bp_majdate"
                        :value="form.bp_majdate"
                        @update:value="
                            (newValue) => saveData('bp_majdate', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.bp_majsrc"
                        :value="form.bp_majsrc"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('bp_majsrc', newValue)
                        "
                    />
                    <CustomDatePicker
                        :label="labels.bp_abddate"
                        :value="form.bp_abddate"
                        @update:value="
                            (newValue) => saveData('bp_abddate', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.bp_abdsrc"
                        :value="form.bp_abdsrc"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('bp_abdsrc', newValue)
                        "
                    />
                </el-col>
                <el-col
                    v-show="selectedTab === 'BPE'"
                    :span="24"
                >
                    <CustomRadioButton
                        :label="labels.arrimage_cables"
                        :options="options.arrimage_cables"
                        :value="form.arrimage_cables"
                        size="small"
                        @update:value="
                            (newValue) => saveData('arrimage_cables', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_arrimage_cables"
                        :images="images.img_arrimage_cables"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_arrimage_cables', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_arrimage_cables"
                        :value="form.commentaire_arrimage_cables"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_arrimage_cables',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.diametre_ecam"
                        :options="options.diametre_ecam"
                        :value="form.diametre_ecam"
                        size="small"
                        @update:value="
                            (newValue) => saveData('diametre_ecam', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_diametre_ecam"
                        :images="images.img_diametre_ecam"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_diametre_ecam', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_diametre_ecam"
                        :value="form.commentaire_diametre_ecam"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData('commentaire_diametre_ecam', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.plan_cablage"
                        :options="options.plan_cablage"
                        :value="form.plan_cablage"
                        size="small"
                        @update:value="
                            (newValue) => saveData('plan_cablage', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_plan_cablage"
                        :images="images.img_plan_cablage"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_plan_cablage', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_plan_cablage"
                        :value="form.commentaire_plan_cablage"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData('commentaire_plan_cablage', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.degraissage_fibres"
                        :options="options.degraissage_fibres"
                        :value="form.degraissage_fibres"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('degraissage_fibres', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_degraissage_fibres"
                        :images="images.img_degraissage_fibres"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_degraissage_fibres', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_degraissage_fibres"
                        :value="form.commentaire_degraissage_fibres"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_degraissage_fibres',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.detubage_fibres"
                        :options="options.detubage_fibres"
                        :value="form.detubage_fibres"
                        size="small"
                        @update:value="
                            (newValue) => saveData('detubage_fibres', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_detubage_fibres"
                        :images="images.img_detubage_fibres"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_detubage_fibres', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_detubage_fibres"
                        :value="form.commentaire_detubage_fibres"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_detubage_fibres',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.emplacement_boitier"
                        :options="options.emplacement_boitier"
                        :value="form.emplacement_boitier"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('emplacement_boitier', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_emplacement_boitier"
                        :images="images.img_emplacement_boitier"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_emplacement_boitier', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_emplacement_boitier"
                        :value="form.commentaire_emplacement_boitier"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_emplacement_boitier',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.etancheite_bpe"
                        :options="options.etancheite_bpe"
                        :value="form.etancheite_bpe"
                        size="small"
                        @update:value="
                            (newValue) => saveData('etancheite_bpe', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_etancheite_bpe"
                        :images="images.img_etancheite_bpe"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_etancheite_bpe', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_etancheite_bpe"
                        :value="form.commentaire_etancheite_bpe"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData('commentaire_etancheite_bpe', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.etiquette_bpe"
                        :options="options.etiquette_bpe"
                        :value="form.etiquette_bpe"
                        size="small"
                        @update:value="
                            (newValue) => saveData('etiquette_bpe', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_etiquette_bpe"
                        :images="images.img_etiquette_bpe"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_etiquette_bpe', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_etiquette_bpe"
                        :value="form.commentaire_etiquette_bpe"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData('commentaire_etiquette_bpe', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.fixation_boitier"
                        :options="options.fixation_boitier"
                        :value="form.fixation_boitier"
                        size="small"
                        @update:value="
                            (newValue) => saveData('fixation_boitier', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_fixation_boitier"
                        :images="images.img_fixation_boitier"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_fixation_boitier', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_fixation_boitier"
                        :value="form.commentaire_fixation_boitier"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_fixation_boitier',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.presence_cache"
                        :options="options.presence_cache"
                        :value="form.presence_cache"
                        size="small"
                        @update:value="
                            (newValue) => saveData('presence_cache', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_presence_cache"
                        :images="images.img_presence_cache"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_presence_cache', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_presence_cache"
                        :value="form.commentaire_presence_cache"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData('commentaire_presence_cache', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.protection_fibres"
                        :options="options.protection_fibres"
                        :value="form.protection_fibres"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('protection_fibres', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_protection_fibres"
                        :images="images.img_protection_fibres"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_protection_fibres', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_protection_fibres"
                        :value="form.commentaire_protection_fibres"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_protection_fibres',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.rangement_attentes"
                        :options="options.rangement_attentes"
                        :value="form.rangement_attentes"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('rangement_attentes', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_rangement_attentes"
                        :images="images.img_rangement_attentes"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_rangement_attentes', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_rangement_attentes"
                        :value="form.commentaire_rangement_attentes"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_rangement_attentes',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.longueurs_love"
                        :options="options.longueurs_love"
                        :value="form.longueurs_love"
                        size="small"
                        @update:value="
                            (newValue) => saveData('longueurs_love', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_longueurs_love"
                        :images="images.img_longueurs_love"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_longueurs_love', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_longueurs_love"
                        :value="form.commentaire_longueurs_love"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData('commentaire_longueurs_love', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.rayon_courbure"
                        :options="options.rayon_courbure"
                        :value="form.rayon_courbure"
                        size="small"
                        @update:value="
                            (newValue) => saveData('rayon_courbure', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_rayon_courbure"
                        :images="images.img_rayon_courbure"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_rayon_courbure', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_rayon_courbure"
                        :value="form.commentaire_rayon_courbure"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData('commentaire_rayon_courbure', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.type_cable"
                        :options="options.type_cable"
                        :value="form.type_cable"
                        size="small"
                        @update:value="
                            (newValue) => saveData('type_cable', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_type_cable"
                        :images="images.img_type_cable"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('img_type_cable', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_type_cable"
                        :value="form.commentaire_type_cable"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData('commentaire_type_cable', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.separation_abonnes"
                        :options="options.separation_abonnes"
                        :value="form.separation_abonnes"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('separation_abonnes', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_separation_abonnes"
                        :images="images.img_separation_abonnes"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_separation_abonnes', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_separation_abonnes"
                        :value="form.commentaire_separation_abonnes"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_separation_abonnes',
                                    newValue,
                                )
                        "
                    />
                </el-col>
            </el-row>
        </template>
    </FormWrapper>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import type { FormDataInterface } from '@connect-field/client/components/forms/form.types';
import FormTemplate from '@connect-field/client/components/forms/FormTemplate.vue';

import {
    labels,
    options,
} from '@connect-field/client/components/forms/Axione/form_creation_bpe_v3_axione.data';

interface DataInterface extends FormDataInterface {}

export default defineComponent({
    extends: FormTemplate,
    data(): DataInterface {
        return {
            changes: false,
            form: {},
            images: {
                img_arrimage_cables: [],
                img_degraissage_fibres: [],
                img_detubage_fibres: [],
                img_diametre_ecam: [],
                img_emplacement_boitier: [],
                img_etancheite_bpe: [],
                img_etiquette_bpe: [],
                img_fixation_boitier: [],
                img_longueurs_love: [],
                img_plan_cablage: [],
                img_presence_cache: [],
                img_protection_fibres: [],
                img_rangement_attentes: [],
                img_rayon_courbure: [],
                img_separation_abonnes: [],
                img_type_cable: [],
            },
            labels,
            loading: true,
            mandatoryFields: ['statut'],
            options,
            selectedTab: 'GENERAL',
            valueMissing: false,
        };
    },
    methods: {},
});
</script>
