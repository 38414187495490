<template>
    <main>
        <div
            v-for="layer in layers"
            :key="layer"
            class="collapse-layer"
        >
            <div
                class="collapse__header"
                :class="{
                    visible: true,
                    'line-through': !isLayerInRenderRange(layer),
                }"
            >
                <div
                    class="collapse__btn custom_link"
                    @click="() => openDropDown(layer)"
                >
                    <span>
                        <FontAwesomeIcon
                            icon="chevron-right"
                            class="text-sm text-grey"
                            :class="{
                                dropdownOpened: showSettings[layer.get('name')],
                            }"
                        />
                    </span>
                    <span class="collapse__title">
                        {{ layer.get('alias') ?? 'Couche' }}
                    </span>
                </div>
                <LayerVisibility
                    :disabled="!isLayerInRenderRange(layer)"
                    :layer="layer"
                />
            </div>
            <div
                v-show="showSettings[layer.get('name')]"
                class="settings_container"
            >
                <div class="slider_container">
                    <OpacitySlider
                        :current-opacity="currentOpacity"
                        class="slider"
                        @value-change="changeOpacity(layer, $event)"
                    />
                </div>
                <div
                    v-if="layer.get('name') && styles[layer.get('name')]"
                    class="mapbox-layers"
                >
                    <LayerLegend
                        :layer="layer"
                        :styles="styles"
                    />
                </div>
            </div>
        </div>
    </main>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';
import type BaseLayer from 'ol/layer/Base';
import type Layer from 'ol/layer/Layer';
import type Map from 'ol/Map';

import LayerLegend from '@connect-field/client/components/layer/LayerLegend.vue';
import LayerVisibility from '@connect-field/client/components/layer/LayerVisibility.vue';
import OpacitySlider from '@connect-field/client/components/layer/OpacitySlider.vue';
import useMapStore from '@connect-field/client/stores/map';

interface DataInterface {
    currentOpacity: number;
    showSettings: Record<string, unknown>;
    styles: Record<string, unknown>;
}

export default defineComponent({
    components: {
        LayerLegend,
        LayerVisibility,
        OpacitySlider,
    },
    props: {
        layers: { type: Array as PropType<Array<Layer>>, required: true },
    },
    setup() {
        return {
            mapStore: useMapStore(),
        };
    },
    data(): DataInterface {
        return {
            currentOpacity: 100,
            showSettings: {},
            styles: {},
        };
    },
    computed: {
        map(): Map {
            if (!this.mapStore.map) {
                throw new Error('Map is undefined');
            }

            return this.mapStore.map as Map;
        },
        zoomLevel(): number {
            return this.mapStore.zoomLevel;
        },
    },

    async mounted() {},
    methods: {
        changeOpacity(layer: BaseLayer, slide_value: string): void {
            layer.setOpacity(parseInt(slide_value) / 100);
        },
        getLayersMap(): Array<BaseLayer> {
            return this.map
                .getLayers()
                .getArray()
                .filter(
                    (layer: BaseLayer) =>
                        !layer.getProperties().global &&
                        layer.getProperties().name,
                );
        },
        getLayersStyles(layersMap: Array<BaseLayer>): Record<string, unknown> {
            return layersMap.reduce(
                (acc: Record<string, unknown>, l: BaseLayer) => {
                    if (!l.getProperties()['mapbox-layers']) {
                        return acc;
                    }
                    acc[l.getProperties().name] = l
                        .getProperties()
                        ['mapbox-layers'].map((ml: string) => {
                            return { name: ml, visible: true };
                        });

                    return acc;
                },
                {},
            );
        },
        isLayerInRenderRange(layer: BaseLayer): boolean {
            const minZoom = layer.getMinZoom();
            const maxZoom = layer.getMaxZoom();

            return this.zoomLevel > minZoom && this.zoomLevel <= maxZoom;
        },
        openDropDown(layer: BaseLayer): void {
            const layersMap = this.getLayersMap();
            this.styles = this.getLayersStyles(layersMap);
            if (layer.getOpacity() !== this.currentOpacity) {
                this.currentOpacity = Math.trunc(layer.getOpacity() * 100);
            }
            this.showSettings[layer.get('name')] =
                !this.showSettings[layer.get('name')];
        },
    },
});
</script>

<style scoped lang="scss">
@import 'LayersList.scss';
</style>
