<template>
    <FormWrapper
        v-if="!loading"
        ref="customFormRef"
        :images-model="images"
        :form-model="form"
        :mandatory-fields="mandatoryFields"
        :changes-model="changes"
        :layer-name="layerName"
        :object-id="objectId"
        :project-id="projectId"
        :latitude="latitude"
        :longitude="longitude"
        @update:images-model="
            (e) => {
                images = e;
            }
        "
        @update:value-missing="
            (e) => {
                valueMissing = e;
            }
        "
        @update:form-model="
            (e) => {
                form = e;
            }
        "
        @update:changes-model="
            (e) => {
                changes = e;
            }
        "
    >
        <template #menu_tabs>
            <el-row class="scroll-menu">
                <el-col :span="8">
                    <div
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'GENERAL' }"
                        @click="selectTab('GENERAL')"
                    >
                        GENERAL
                    </div>
                </el-col>

                <el-col :span="8">
                    <div
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'BAIE' }"
                        @click="selectTab('BAIE')"
                    >
                        BAIE
                    </div>
                </el-col>

                <el-col :span="8">
                    <div
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'CABLES' }"
                        @click="selectTab('CABLES')"
                    >
                        CABLES
                    </div>
                </el-col>

                <el-col :span="8">
                    <div
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'ECLATEUR' }"
                        @click="selectTab('ECLATEUR')"
                    >
                        ECLATEUR
                    </div>
                </el-col>

                <el-col :span="8">
                    <div
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'INFRA' }"
                        @click="selectTab('INFRA')"
                    >
                        INFRA
                    </div>
                </el-col>

                <el-col :span="8">
                    <div
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'TIROIR' }"
                        @click="selectTab('TIROIR')"
                    >
                        TIROIR
                    </div>
                </el-col>
            </el-row>
        </template>
        <template #main_form>
            <el-row>
                <el-col
                    v-show="selectedTab === 'GENERAL'"
                    :span="24"
                >
                    <CustomInput
                        :label="labels.lc_code"
                        :value="form.lc_code"
                        :maxlength="254"
                        :disabled="true"
                    />
                    <CustomInputSelect
                        :label="labels.statut + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.statut"
                        :value="form.statut"
                        @update:value="
                            (newValue) => saveData('statut', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.proprete"
                        :options="options.proprete"
                        :value="form.proprete"
                        @update:value="
                            (newValue) => saveData('proprete', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.lc_bp_codf"
                        :value="form.lc_bp_codf"
                        :maxlength="254"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.lc_bp_codp"
                        :value="form.lc_bp_codp"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.lc_codeext"
                        :value="form.lc_codeext"
                        :maxlength="254"
                        :disabled="true"
                    />
                    <CustomInputSelect
                        :label="labels.lc_abandon"
                        :options="options.lc_abandon"
                        :value="form.lc_abandon"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.lc_prop"
                        :value="form.lc_prop"
                        :maxlength="20"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.lc_gest"
                        :value="form.lc_gest"
                        :disabled="true"
                    />
                    <CustomInputSelect
                        :label="labels.lc_statut"
                        :options="options.lc_statut"
                        :value="form.lc_statut"
                        :disabled="true"
                    />
                    <CustomInputSelect
                        :label="labels.lc_avct"
                        :options="options.lc_avct"
                        :value="form.lc_avct"
                        :disabled="true"
                    />
                    <CustomDatePicker
                        :label="labels.lc_dateins"
                        :value="form.lc_dateins"
                        :disabled="true"
                    />
                    <CustomInputSelect
                        :label="labels.lc_elec"
                        :options="options.lc_elec"
                        :value="form.lc_elec"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.lc_bat"
                        :value="form.lc_bat"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.lc_escal"
                        :value="form.lc_escal"
                        :maxlength="20"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.lc_etage"
                        :value="form.lc_etage"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.lc_perirec"
                        :value="form.lc_perirec"
                        :maxlength="254"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.lc_etiquet"
                        :value="form.lc_etiquet"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.lc_st_code"
                        :value="form.lc_st_code"
                        :maxlength="254"
                        :disabled="true"
                    />
                    <CustomInputSelect
                        :label="labels.lc_typelog"
                        :options="options.lc_typelog"
                        :value="form.lc_typelog"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.lc_user"
                        :value="form.lc_user"
                        :maxlength="20"
                        :disabled="true"
                    />
                    <CustomInputSelect
                        :label="labels.lc_proptyp"
                        :options="options.lc_proptyp"
                        :value="form.lc_proptyp"
                        :disabled="true"
                    />
                    <CustomInputSelect
                        :label="labels.lc_etat"
                        :options="options.lc_etat"
                        :value="form.lc_etat"
                        :disabled="true"
                    />
                    <CustomDatePicker
                        :label="labels.lc_datemes"
                        :value="form.lc_datemes"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.lc_local"
                        :value="form.lc_local"
                        :maxlength="254"
                        :disabled="true"
                    />
                    <CustomInputSelect
                        :label="labels.lc_clim"
                        :options="options.lc_clim"
                        :value="form.lc_clim"
                        :disabled="true"
                    />
                    <CustomInputSelect
                        :label="labels.lc_occp"
                        :options="options.lc_occp"
                        :value="form.lc_occp"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.lc_idmajic"
                        :value="form.lc_idmajic"
                        :disabled="true"
                    />
                    <CustomDatePicker
                        :label="labels.lc_abddate"
                        :value="form.lc_abddate"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.lc_abdsrc"
                        :value="form.lc_abdsrc"
                        :maxlength="254"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.lc_comment"
                        :value="form.lc_comment"
                        :disabled="true"
                    />
                    <CustomDatePicker
                        :label="labels.lc_creadat"
                        :value="form.lc_creadat"
                        :disabled="true"
                    />
                    <CustomDatePicker
                        :label="labels.lc_majdate"
                        :value="form.lc_majdate"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.lc_majsrc"
                        :value="form.lc_majsrc"
                        :maxlength="254"
                        :disabled="true"
                    />
                </el-col>
                <el-col
                    v-show="selectedTab === 'BAIE'"
                    :span="24"
                >
                    <CustomRadioButton
                        :label="labels.emplacement_lt"
                        :options="options.emplacement_lt"
                        :value="form.emplacement_lt"
                        size="small"
                        @update:value="
                            (newValue) => saveData('emplacement_lt', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_emplacement_lt"
                        :images="images.img_emplacement_lt"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_emplacement_lt', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_emplacement_lt"
                        :value="form.commentaire_emplacement_lt"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData('commentaire_emplacement_lt', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.etiquette_baie"
                        :options="options.etiquette_baie"
                        :value="form.etiquette_baie"
                        size="small"
                        @update:value="
                            (newValue) => saveData('etiquette_baie', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_etiquette_baie"
                        :images="images.img_etiquette_baie"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_etiquette_baie', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_etiquette_baie"
                        :value="form.commentaire_etiquette_baie"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData('commentaire_etiquette_baie', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.position_traverses"
                        :options="options.position_traverses"
                        :value="form.position_traverses"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('position_traverses', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_position_traverses"
                        :images="images.img_position_traverses"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_position_traverses', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_position_traverses"
                        :value="form.commentaire_position_traverses"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_position_traverses',
                                    newValue,
                                )
                        "
                    />
                </el-col>
                <el-col
                    v-show="selectedTab === 'CABLES'"
                    :span="24"
                >
                    <CustomRadioButton
                        :label="labels.cheminement_lovage"
                        :options="options.cheminement_lovage"
                        :value="form.cheminement_lovage"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('cheminement_lovage', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_cheminement_lovage"
                        :images="images.img_cheminement_lovage"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_cheminement_lovage', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_cheminement_lovage"
                        :value="form.commentaire_cheminement_lovage"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_cheminement_lovage',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.etiquette_cable"
                        :options="options.etiquette_cable"
                        :value="form.etiquette_cable"
                        size="small"
                        @update:value="
                            (newValue) => saveData('etiquette_cable', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_etiquette_cable"
                        :images="images.img_etiquette_cable"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_etiquette_cable', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_etiquette_cable"
                        :value="form.commentaire_etiquette_cable"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_etiquette_cable',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.fixation_entree"
                        :options="options.fixation_entree"
                        :value="form.fixation_entree"
                        size="small"
                        @update:value="
                            (newValue) => saveData('fixation_entree', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_fixation_entree"
                        :images="images.img_fixation_entree"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_fixation_entree', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_fixation_entree"
                        :value="form.commentaire_fixation_entree"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_fixation_entree',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.nombre_fibre"
                        :options="options.nombre_fibre"
                        :value="form.nombre_fibre"
                        size="small"
                        @update:value="
                            (newValue) => saveData('nombre_fibre', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_nombre_fibre"
                        :images="images.img_nombre_fibre"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_nombre_fibre', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_nombre_fibre"
                        :value="form.commentaire_nombre_fibre"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData('commentaire_nombre_fibre', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.rayon_courbure"
                        :options="options.rayon_courbure"
                        :value="form.rayon_courbure"
                        size="small"
                        @update:value="
                            (newValue) => saveData('rayon_courbure', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_rayon_courbure"
                        :images="images.img_rayon_courbure"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_rayon_courbure', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_rayon_courbure"
                        :value="form.commentaire_rayon_courbure"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData('commentaire_rayon_courbure', newValue)
                        "
                    />
                </el-col>
                <el-col
                    v-show="selectedTab === 'ECLATEUR'"
                    :span="24"
                >
                    <CustomRadioButton
                        :label="labels.fixation_eclateur"
                        :options="options.fixation_eclateur"
                        :value="form.fixation_eclateur"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('fixation_eclateur', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_fixation_eclateur"
                        :images="images.img_fixation_eclateur"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_fixation_eclateur', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_fixation_eclateur"
                        :value="form.commentaire_fixation_eclateur"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_fixation_eclateur',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.epanouissement"
                        :options="options.epanouissement"
                        :value="form.epanouissement"
                        size="small"
                        @update:value="
                            (newValue) => saveData('epanouissement', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_epanouissement"
                        :images="images.img_epanouissement"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_epanouissement', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_epanouissement"
                        :value="form.commentaire_epanouissement"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData('commentaire_epanouissement', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.presence_gaine"
                        :options="options.presence_gaine"
                        :value="form.presence_gaine"
                        size="small"
                        @update:value="
                            (newValue) => saveData('presence_gaine', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_presence_gaine"
                        :images="images.img_presence_gaine"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_presence_gaine', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_presence_gaine"
                        :value="form.commentaire_presence_gaine"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData('commentaire_presence_gaine', newValue)
                        "
                    />
                </el-col>
                <el-col
                    v-show="selectedTab === 'INFRA'"
                    :span="24"
                >
                    <CustomRadioButton
                        :label="labels.emplacement_geographique"
                        :options="options.emplacement_geographique"
                        :value="form.emplacement_geographique"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('emplacement_geographique', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_emplacement_geographique"
                        :images="images.img_emplacement_geographique"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage(
                                    'img_emplacement_geographique',
                                    newValue,
                                )
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_emplacement_geographique"
                        :value="form.commentaire_emplacement_geographique"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_emplacement_geographique',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.obturation_fourreaux"
                        :options="options.obturation_fourreaux"
                        :value="form.obturation_fourreaux"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('obturation_fourreaux', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_obturation_fourreaux"
                        :images="images.img_obturation_fourreaux"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_obturation_fourreaux', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_obturation_fourreaux"
                        :value="form.commentaire_obturation_fourreaux"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_obturation_fourreaux',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.vabf_acces"
                        :options="options.vabf_acces"
                        :value="form.vabf_acces"
                        size="small"
                        @update:value="
                            (newValue) => saveData('vabf_acces', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_vabf_acces"
                        :images="images.img_vabf_acces"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('img_vabf_acces', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_vabf_acces"
                        :value="form.commentaire_vabf_acces"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData('commentaire_vabf_acces', newValue)
                        "
                    />
                </el-col>
                <el-col
                    v-show="selectedTab === 'TIROIR'"
                    :span="24"
                >
                    <CustomRadioButton
                        :label="labels.arrimage_gaine"
                        :options="options.arrimage_gaine"
                        :value="form.arrimage_gaine"
                        size="small"
                        @update:value="
                            (newValue) => saveData('arrimage_gaine', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_arrimage_gaine"
                        :images="images.img_arrimage_gaine"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_arrimage_gaine', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_arrimage_gaine"
                        :value="form.commentaire_arrimage_gaine"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData('commentaire_arrimage_gaine', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.detubage_fibres"
                        :options="options.detubage_fibres"
                        :value="form.detubage_fibres"
                        size="small"
                        @update:value="
                            (newValue) => saveData('detubage_fibres', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_detubage_fibres"
                        :images="images.img_detubage_fibres"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_detubage_fibres', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_detubage_fibres"
                        :value="form.commentaire_detubage_fibres"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_detubage_fibres',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.etiquette_tiroir"
                        :options="options.etiquette_tiroir"
                        :value="form.etiquette_tiroir"
                        size="small"
                        @update:value="
                            (newValue) => saveData('etiquette_tiroir', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_etiquette_tiroir"
                        :images="images.img_etiquette_tiroir"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_etiquette_tiroir', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_etiquette_tiroir"
                        :value="form.commentaire_etiquette_tiroir"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_etiquette_tiroir',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.fixation_tiroir"
                        :options="options.fixation_tiroir"
                        :value="form.fixation_tiroir"
                        size="small"
                        @update:value="
                            (newValue) => saveData('fixation_tiroir', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_fixation_tiroir"
                        :images="images.img_fixation_tiroir"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_fixation_tiroir', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_fixation_tiroir"
                        :value="form.commentaire_fixation_tiroir"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_fixation_tiroir',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.longueur_gaine"
                        :options="options.longueur_gaine"
                        :value="form.longueur_gaine"
                        size="small"
                        @update:value="
                            (newValue) => saveData('longueur_gaine', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_longueur_gaine"
                        :images="images.img_longueur_gaine"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_longueur_gaine', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_longueur_gaine"
                        :value="form.commentaire_longueur_gaine"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData('commentaire_longueur_gaine', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.ouverture_tiroirs"
                        :options="options.ouverture_tiroirs"
                        :value="form.ouverture_tiroirs"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('ouverture_tiroirs', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_ouverture_tiroirs"
                        :images="images.img_ouverture_tiroirs"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_ouverture_tiroirs', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_ouverture_tiroirs"
                        :value="form.commentaire_ouverture_tiroirs"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_ouverture_tiroirs',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.presence_bouchons"
                        :options="options.presence_bouchons"
                        :value="form.presence_bouchons"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('presence_bouchons', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_presence_bouchons"
                        :images="images.img_presence_bouchons"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_presence_bouchons', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_presence_bouchons"
                        :value="form.commentaire_presence_bouchons"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_presence_bouchons',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.rayon_courbure_tubes"
                        :options="options.rayon_courbure_tubes"
                        :value="form.rayon_courbure_tubes"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('rayon_courbure_tubes', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_rayon_courbure_tubes"
                        :images="images.img_rayon_courbure_tubes"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_rayon_courbure_tubes', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_rayon_courbure_tubes"
                        :value="form.commentaire_rayon_courbure_tubes"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_rayon_courbure_tubes',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.positions_tiroirs"
                        :options="options.positions_tiroirs"
                        :value="form.positions_tiroirs"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('positions_tiroirs', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_positions_tiroirs"
                        :images="images.img_positions_tiroirs"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_positions_tiroirs', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_positions_tiroirs"
                        :value="form.commentaire_positions_tiroirs"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_positions_tiroirs',
                                    newValue,
                                )
                        "
                    />
                </el-col>
            </el-row>
        </template>
    </FormWrapper>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import type { FormDataInterface } from '@connect-field/client/components/forms/form.types';
import FormTemplate from '@connect-field/client/components/forms/FormTemplate.vue';

import {
    labels,
    options,
} from '@connect-field/client/components/forms/Axione/form_audit_sro_v3_axione.data';

interface DataInterface extends FormDataInterface {}

export default defineComponent({
    extends: FormTemplate,
    data(): DataInterface {
        return {
            changes: false,
            form: {},
            images: {
                img_arrimage_gaine: [],
                img_cheminement_lovage: [],
                img_detubage_fibres: [],
                img_emplacement_geographique: [],
                img_emplacement_lt: [],
                img_epanouissement: [],
                img_etiquette_baie: [],
                img_etiquette_cable: [],
                img_etiquette_tiroir: [],
                img_fixation_eclateur: [],
                img_fixation_entree: [],
                img_fixation_tiroir: [],
                img_longueur_gaine: [],
                img_nombre_fibre: [],
                img_obturation_fourreaux: [],
                img_ouverture_tiroirs: [],
                img_position_traverses: [],
                img_positions_tiroirs: [],
                img_presence_bouchons: [],
                img_presence_gaine: [],
                img_rayon_courbure: [],
                img_rayon_courbure_tubes: [],
                img_vabf_acces: [],
            },
            labels,
            loading: true,
            mandatoryFields: ['statut'],
            options,
            selectedTab: 'GENERAL',
            valueMissing: false,
        };
    },
    methods: {},
});
</script>
