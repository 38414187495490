<template>
    <div
        v-for="(value, key) in data"
        :key="key"
        class="box"
        @click="openCable(value, key)"
    >
        <div>
            {{ title }}
            <div class="row-label">{{ key }}</div>
            <div class="row-value">Code externe : {{ value.codeext }}</div>
        </div>
        <div class="seperator">&nbsp;</div>
        <div>
            <div class="row-value">Boite : {{ value.boiteBaie }}</div>
            <div class="row-value">
                Code externe : {{ value.boiteBaieCodeext }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { mapActions, mapWritableState } from 'pinia';
import { defineComponent } from 'vue';

import useBoxPlanStore from '@connect-field/client/stores/boxPlan';

export default defineComponent({
    props: {
        data: { type: Array, required: true },
        title: { type: String, required: true },
    },
    computed: {
        ...mapWritableState(useBoxPlanStore, {
            cableData: 'cableData',
        }),
    },
    methods: {
        ...mapActions(useBoxPlanStore, {
            goToCable: 'goToCable',
        }),
        openCable(data: Record<string, unknown>, key: string): void {
            this.cableData = { data, key };
            this.goToCable();
        },
    },
});
</script>

<style scoped lang="scss">
.box {
    color: white;
    background: rgba(17, 25, 40, 0.65);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    padding: 8px;
    margin: 8px 0;
    justify-content: space-between;
}

.seperator {
    height: 10px;
    width: 100%;
    border-bottom: white 1px solid;
    margin-bottom: 10px;
}
</style>
