import type { OptionsInterface, SelectInterface } from '@connect-field/client/components/forms/form.types';

export const labels: Record<string, string> = {
    armoire_ral: 'Conformité de la taille armoire et RAL',
    comment_cablage: 'Commentaire Global Cablage',
    comment_etiquetage: 'Commentaire Étiquetage',
    comment_gb: 'Commentaire Global Pose Armoire',
    criticite: 'Criticité',
    criticite_cablage: 'Criticité',
    dalle: 'Dalle de propreté',
    date_audit: 'Date audit',
    etancheite: 'Étanchéité',
    etat_general: 'État général',
    etiquetage: 'Étiquetage',
    img_armoire_autres: 'Photo Autres',
    img_armoire_environnement: 'Photo Environnement',
    img_armoire_ouverte: 'Photo Armoire ouverte',
    img_refection: 'Photo Refection',
    nb_pos_tiroir: 'Nombre et positionnement des tiroirs',
    plynox: 'Présence Plynox et terre',
    pose_fourreau: 'Qualité de la pose des fourreaux et obturation',
    presence_coupleur: 'Présence coupleur',
    ral: 'RAL',
    ref_sro: 'Référence SRO',
    refection: 'Qualité de la réfection',
    respect_pose: 'Respect des techniques de pose',
    statut_audit: 'Statut audit',
    statut_audit_cablage: 'Statut audit',
    taille: 'Taille',
};

export const options: Record<string, OptionsInterface | SelectInterface> = {
    armoire_ral: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non conforme', value: 'Non conforme' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    criticite: [
        { label: 'MAJEUR', value: 'MAJEUR' },
        { label: 'MINEUR', value: 'MINEUR' },
    ],
    criticite_cablage: [
        { label: 'MAJEUR', value: 'MAJEUR' },
        { label: 'MINEUR', value: 'MINEUR' },
    ],
    dalle: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non conforme', value: 'Non conforme' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    etancheite: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non conforme', value: 'Non conforme' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    etat_general: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non conforme', value: 'Non conforme' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    etiquetage: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non conforme', value: 'Non conforme' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    nb_pos_tiroir: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non conforme', value: 'Non conforme' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    plynox: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non conforme', value: 'Non conforme' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    pose_fourreau: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non conforme', value: 'Non conforme' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    presence_coupleur: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non conforme', value: 'Non conforme' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    refection: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non conforme', value: 'Non conforme' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    respect_pose: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non conforme', value: 'Non conforme' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    statut_audit: [
        { label: 'NON REALISE', value: 'NON REALISE' },
        { label: 'PARTIELLEMENT', value: 'PARTIELLEMENT' },
        { label: 'TERMINE', value: 'TERMINE' },
    ],
    statut_audit_cablage: [
        { label: 'NON REALISE', value: 'NON REALISE' },
        { label: 'PARTIELLEMENT', value: 'PARTIELLEMENT' },
        { label: 'TERMINE', value: 'TERMINE' },
    ],
};
