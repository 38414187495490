import { Draw, Interaction, Modify } from 'ol/interaction';
import { Geometry, LineString, Point, Polygon } from 'ol/geom';
import type BaseLayer from 'ol/layer/Base';
import type { FeatureLike } from 'ol/Feature';
import { getUid } from 'ol/util';
import type Map from 'ol/Map';
import type { Style } from 'ol/style';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';

import { formatArea, formatLength } from '@connect-field/client/utilities/geometry';
import {
    labelStyle,
    modifyStyle,
    polygonStyle,
    segmentStyle,
    segmentStyles,
    tipStyle,
    verticePolygonStyle,
} from '@connect-field/client/assets/styles/measure.styles';

class MeasureService {
    public draw?: Draw;
    public map?: Map;
    public measurementLayer?: BaseLayer;
    public modify?: Modify;
    public source?: VectorSource<Geometry>;
    public tipPoint?: Geometry;

    private readonly options = {
        clearPrevious: false,
        showSegmentsLength: false,
    };

    public constructor() {
        this.measurementLayer = undefined;
        this.draw = undefined;
        this.map = undefined;
        this.modify = undefined;
        this.source = undefined;
        this.tipPoint = undefined;
    }

    public addMapInteraction(drawType: 'LineString' | 'Polygon'): void {
        if (!this.map) {
            throw new Error('Map instance undefined');
        }

        const activeTip = 'Cliquez pour continuer de dessiner ' + (drawType === 'Polygon' ? 'le polygone' : 'la ligne');
        const idleTip = 'Cliquez pour commencer à mesurer';
        let tip = idleTip;

        if (!this.measurementLayer) {
            this.createMeasurementLayer();
        }
        this.removeMapInteraction('draw');

        this.draw = new Draw({
            source: this.source,
            style: (feature): Array<Style> => {
                return this.styleFunction(feature, this.options.showSegmentsLength, drawType, tip);
            },
            type: drawType,
        });

        this.draw.on('drawstart', () => {
            if (this.options.clearPrevious) {
                this.source?.clear();
            }
            this.modify?.setActive(false);
            tip = activeTip;
        });

        this.draw.on('drawend', () => {
            if (this.tipPoint) {
                modifyStyle.setGeometry(this.tipPoint);
            }
            this.modify?.setActive(true);
            this.map?.once('pointermove', () => {
                modifyStyle.setGeometry('');
            });
            tip = idleTip;
        });

        this.modify?.setActive(true);
        this.map.addInteraction(this.draw);
    }

    public removeMeasurementLayer(): void {
        if (!this.measurementLayer || !this.map) {
            return;
        }

        const currentLayerId = getUid(this.measurementLayer);
        const measureLayer = this.map
            .getLayers()
            .getArray()
            .find((layer: BaseLayer) => getUid(layer) === currentLayerId);

        if (measureLayer) {
            const removedLayer = this.map.removeLayer(measureLayer);

            if (!removedLayer) {
                // Layer hasn't been found
                console.error("removeGeometryLayer : The layer hasn't been removed");

                return;
            }
        }

        this.measurementLayer = undefined;
        this.removeMapInteraction('draw');
        this.removeMapInteraction('modify');
    }

    private createMeasurementLayer(): void {
        if (!this.map) {
            throw new Error('Map instance is undefined');
        }

        this.source = new VectorSource();
        this.modify = new Modify({
            pixelTolerance: 20,
            source: this.source,
            style: modifyStyle,
        });

        this.measurementLayer = new VectorLayer({
            source: this.source,
            style: (feature: FeatureLike): void | Style | Array<Style> =>
                this.styleFunction(feature, this.options.showSegmentsLength),
        });

        this.map.addLayer(this.measurementLayer);

        if (this.modify) {
            this.map.addInteraction(this.modify);
        }
    }

    private removeMapInteraction(interactionType: string): void {
        if (!this.map) {
            return;
        }

        let interactionToRemove;

        switch (interactionType) {
            case 'draw':
                interactionToRemove = this.draw;
                break;
            case 'modify':
                interactionToRemove = this.modify;
                break;

            default:
                console.error(`removeMapInteraction : Interaction type ${interactionType} is unknown`, interactionType);
                break;
        }

        if (!interactionToRemove) {
            return;
        }

        const currentInteraction = getUid(interactionToRemove);
        const interactions = this.map.getInteractions().getArray();
        const theInteraction = interactions.find(
            (interaction: Interaction) => currentInteraction === getUid(interaction),
        );

        // Useless ?
        if (theInteraction) {
            const res = this.map.removeInteraction(theInteraction);

            if (!res) {
                // Interaction hasn't been found
                console.error(
                    `removeMapInteraction : The ${interactionType} interaction ${interactionToRemove} hasn't been removed`,
                    interactionToRemove,
                );
            }
        }

        switch (interactionType) {
            case 'draw':
                this.draw = undefined;
                break;
            case 'modify':
                this.modify = undefined;
                break;

            default:
                console.error(`removeMapInteraction : Interaction type ${interactionType} is unknown`, interactionType);
                break;
        }
    }

    private styleFunction(
        feature: FeatureLike,
        segments?: boolean,
        drawType?: string,
        tip?: string | string[] | undefined,
    ): Array<Style> {
        const styles = [polygonStyle, verticePolygonStyle];
        const geometry = feature.getGeometry();
        let point: Point | undefined;
        let label: string | undefined;
        let line: LineString | undefined;

        if (!geometry) {
            throw new Error('geometry is undefined');
        }

        const type = geometry.getType();

        if (!drawType || drawType === type) {
            if (geometry instanceof Geometry) {
                if (geometry instanceof Polygon) {
                    point = geometry.getInteriorPoint();
                    label = formatArea(geometry);
                    line = new LineString(geometry.getCoordinates()[0]);
                } else if (geometry instanceof LineString) {
                    point = new Point(geometry.getLastCoordinate());
                    label = formatLength(geometry);
                    line = geometry;
                }
            }
        }

        if (segments && line) {
            let count = 0;
            line.forEachSegment((pointA: Array<number>, pointB: Array<number>) => {
                const segment = new LineString([pointA, pointB]);
                const label = formatLength(segment);
                if (segmentStyles.length - 1 < count) {
                    segmentStyles.push(segmentStyle.clone());
                }
                const segmentPoint = new Point(segment.getCoordinateAt(0.5));
                segmentStyles[count].setGeometry(segmentPoint);
                segmentStyles[count].getText().setText(label);
                styles.push(segmentStyles[count]);
                count++;
            });
        }

        if (label && point) {
            labelStyle.setGeometry(point);
            labelStyle.getText().setText(label);
            styles.push(labelStyle);
        }

        if (
            tip &&
            type === 'Point' &&
            geometry instanceof Geometry &&
            this.modify &&
            !this.modify.getOverlay().getSource().getFeatures().length
        ) {
            this.tipPoint = geometry;
            tipStyle.getText().setText(tip);
            styles.push(tipStyle);
        }

        return styles;
    }
}

export default new MeasureService();
