<template>
    <FormWrapper
        v-if="!loading"
        ref="customFormRef"
        :images-model="images"
        :form-model="form"
        :mandatory-fields="mandatoryFields"
        :changes-model="changes"
        :layer-name="layerName"
        :object-id="objectId"
        :project-id="projectId"
        :latitude="latitude"
        :longitude="longitude"
        @update:images-model="
            (e) => {
                images = e;
            }
        "
        @update:value-missing="
            (e) => {
                valueMissing = e;
            }
        "
        @update:form-model="
            (e) => {
                form = e;
            }
        "
        @update:changes-model="
            (e) => {
                changes = e;
            }
        "
    >
        <template #menu_tabs>
            <el-row class="scroll-menu">
                <el-col :span="8">
                    <div
                        @click="selectTab('General')"
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'General' }"
                    >
                        General
                    </div>
                </el-col>

                <el-col
                    :span="8"
                    v-show="condition_tab_chambre()"
                >
                    <div
                        @click="selectTab('Chambre')"
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'Chambre' }"
                    >
                        Chambre
                    </div>
                </el-col>

                <el-col
                    :span="8"
                    v-show="condition_tab_poteau()"
                >
                    <div
                        @click="selectTab('Poteau')"
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'Poteau' }"
                    >
                        Poteau
                    </div>
                </el-col>
            </el-row>
        </template>
        <template #main_form>
            <el-row>
                <el-col
                    :span="24"
                    v-show="selectedTab === 'General'"
                >
                    <CustomInput
                        :label="labels.code"
                        :value="form.code"
                        :maxlength="254"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.gestionnaire"
                        :value="form.gestionnaire"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.type_fonc"
                        :value="form.type_fonc"
                        :maxlength="254"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.type_struc"
                        :value="form.type_struc"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.modele"
                        :value="form.modele"
                        :maxlength="254"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.date_pose"
                        :value="form.date_pose"
                        :disabled="true"
                    />
                    <CustomDatePicker
                        :label="labels.date_audit"
                        :value="form.date_audit"
                        @update:value="
                            (newValue) => saveData('date_audit', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.respect_pose"
                        :options="options.respect_pose"
                        :value="form.respect_pose"
                        size="small"
                        @update:value="
                            (newValue) => saveData('respect_pose', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.comment_pose"
                        :value="form.comment_pose"
                        @update:value="
                            (newValue) => saveData('comment_pose', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.respect_pgc"
                        :options="options.respect_pgc"
                        :value="form.respect_pgc"
                        size="small"
                        @update:value="
                            (newValue) => saveData('respect_pgc', newValue)
                        "
                    />
                </el-col>
                <el-col
                    :span="24"
                    v-show="selectedTab === 'Chambre'"
                >
                    <div v-show="condition_img_chambre_environnement()">
                        <InputCamera
                            :label="labels.img_chambre_environnement"
                            :images="images.img_chambre_environnement"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage(
                                        'img_chambre_environnement',
                                        newValue,
                                    )
                            "
                        />
                    </div>
                    <CustomRadioButton
                        v-show="condition_scellement_cadre()"
                        :label="labels.scellement_cadre"
                        :options="options.scellement_cadre"
                        :value="form.scellement_cadre"
                        size="small"
                        @update:value="
                            (newValue) => saveData('scellement_cadre', newValue)
                        "
                    />

                    <div v-show="condition_img_chambre_dessus()">
                        <InputCamera
                            :label="labels.img_chambre_dessus"
                            :images="images.img_chambre_dessus"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage('img_chambre_dessus', newValue)
                            "
                        />
                    </div>
                    <CustomRadioButton
                        v-show="condition_masque_percute()"
                        :label="labels.masque_percute"
                        :options="options.masque_percute"
                        :value="form.masque_percute"
                        size="small"
                        @update:value="
                            (newValue) => saveData('masque_percute', newValue)
                        "
                    />

                    <div v-show="condition_img_chambre_masques()">
                        <InputCamera
                            :label="labels.img_chambre_masques"
                            :images="images.img_chambre_masques"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage('img_chambre_masques', newValue)
                            "
                        />
                    </div>
                    <CustomRadioButton
                        v-show="condition_nb_fourreaux()"
                        :label="labels.nb_fourreaux"
                        :options="options.nb_fourreaux"
                        :value="form.nb_fourreaux"
                        size="small"
                        @update:value="
                            (newValue) => saveData('nb_fourreaux', newValue)
                        "
                    />
                    <CustomRadioButton
                        v-show="condition_qualite_refection()"
                        :label="labels.qualite_refection"
                        :options="options.qualite_refection"
                        :value="form.qualite_refection"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('qualite_refection', newValue)
                        "
                    />

                    <div v-show="condition_img_refection()">
                        <InputCamera
                            :label="labels.img_refection"
                            :images="images.img_refection"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage('img_refection', newValue)
                            "
                        />
                    </div>
                    <CustomRadioButton
                        v-show="condition_etiquette_chambre()"
                        :label="labels.etiquette_chambre"
                        :options="options.etiquette_chambre"
                        :value="form.etiquette_chambre"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('etiquette_chambre', newValue)
                        "
                    />
                    <CustomRadioButton
                        v-show="condition_type_chambre()"
                        :label="labels.type_chambre"
                        :options="options.type_chambre"
                        :value="form.type_chambre"
                        size="small"
                        @update:value="
                            (newValue) => saveData('type_chambre', newValue)
                        "
                    />
                    <CustomRadioButton
                        v-show="condition_respect_coupe()"
                        :label="labels.respect_coupe"
                        :options="options.respect_coupe"
                        :value="form.respect_coupe"
                        size="small"
                        @update:value="
                            (newValue) => saveData('respect_coupe', newValue)
                        "
                    />
                    <CustomRadioButton
                        v-show="condition_percement_chambre()"
                        :label="labels.percement_chambre"
                        :options="options.percement_chambre"
                        :value="form.percement_chambre"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('percement_chambre', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_autres"
                        :images="images.img_autres"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('img_autres', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.criticite"
                        :options="options.criticite"
                        :value="form.criticite"
                        @update:value="
                            (newValue) => saveData('criticite', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_comment_gb()"
                        :label="labels.comment_gb"
                        :value="form.comment_gb"
                        @update:value="
                            (newValue) => saveData('comment_gb', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.statut_audit + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.statut_audit"
                        :value="form.statut_audit"
                        @update:value="
                            (newValue) => saveData('statut_audit', newValue)
                        "
                    />
                </el-col>
                <el-col
                    :span="24"
                    v-show="selectedTab === 'Poteau'"
                >
                    <CustomRadioButton
                        v-show="condition_aplomb()"
                        :label="labels.aplomb"
                        :options="options.aplomb"
                        :value="form.aplomb"
                        size="small"
                        @update:value="
                            (newValue) => saveData('aplomb', newValue)
                        "
                    />

                    <div v-show="condition_img_poteau_environnement()">
                        <InputCamera
                            :label="labels.img_poteau_environnement"
                            :images="images.img_poteau_environnement"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage(
                                        'img_poteau_environnement',
                                        newValue,
                                    )
                            "
                        />
                    </div>
                    <CustomRadioButton
                        v-show="condition_appui()"
                        :label="labels.appui"
                        :options="options.appui"
                        :value="form.appui"
                        size="small"
                        @update:value="
                            (newValue) => saveData('appui', newValue)
                        "
                    />
                    <CustomRadioButton
                        v-show="condition_prof_implantation()"
                        :label="labels.prof_implantation"
                        :options="options.prof_implantation"
                        :value="form.prof_implantation"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('prof_implantation', newValue)
                        "
                    />
                    <CustomRadioButton
                        v-show="condition_etiquette_pastille()"
                        :label="labels.etiquette_pastille"
                        :options="options.etiquette_pastille"
                        :value="form.etiquette_pastille"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('etiquette_pastille', newValue)
                        "
                    />

                    <div v-show="condition_img_poteau_etiquette()">
                        <InputCamera
                            :label="labels.img_poteau_etiquette"
                            :images="images.img_poteau_etiquette"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage('img_poteau_etiquette', newValue)
                            "
                        />
                    </div>
                    <CustomRadioButton
                        v-show="condition_etat_voirie()"
                        :label="labels.etat_voirie"
                        :options="options.etat_voirie"
                        :value="form.etat_voirie"
                        size="small"
                        @update:value="
                            (newValue) => saveData('etat_voirie', newValue)
                        "
                    />
                    <CustomRadioButton
                        v-show="condition_ras()"
                        :label="labels.ras"
                        :options="options.ras"
                        :value="form.ras"
                        size="small"
                        @update:value="(newValue) => saveData('ras', newValue)"
                    />
                    <CustomRadioButton
                        v-show="condition_armement_cable()"
                        :label="labels.armement_cable"
                        :options="options.armement_cable"
                        :value="form.armement_cable"
                        size="small"
                        @update:value="
                            (newValue) => saveData('armement_cable', newValue)
                        "
                    />

                    <div v-show="condition_img_poteau_tete_armement()">
                        <InputCamera
                            :label="labels.img_poteau_tete_armement"
                            :images="images.img_poteau_tete_armement"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage(
                                        'img_poteau_tete_armement',
                                        newValue,
                                    )
                            "
                        />
                    </div>
                    <InputCamera
                        :label="labels.img_autres"
                        :images="images.img_autres"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('img_autres', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.criticite"
                        :options="options.criticite"
                        :value="form.criticite"
                        @update:value="
                            (newValue) => saveData('criticite', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_comment_gb()"
                        :label="labels.comment_gb"
                        :value="form.comment_gb"
                        @update:value="
                            (newValue) => saveData('comment_gb', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.statut_audit + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.statut_audit"
                        :value="form.statut_audit"
                        @update:value="
                            (newValue) => saveData('statut_audit', newValue)
                        "
                    />
                </el-col>
            </el-row>
        </template>
    </FormWrapper>
</template>

<script lang="ts">
/* eslint-disable */
import { defineComponent } from 'vue';

import type { FormDataInterface } from '@connect-field/client/components/forms/form.types';
import FormTemplate from '@connect-field/client/components/forms/FormTemplate.vue';

import { labels, options } from './t_tvx_implantation_ptech.data';

interface DataInterface extends FormDataInterface {}

export default defineComponent({
    extends: FormTemplate,
    data(): DataInterface {
        return {
            changes: false,
            form: {},
            images: {
                img_chambre_environnement: [],
                img_chambre_dessus: [],
                img_chambre_masques: [],
                img_refection: [],
                img_autres: [],
                img_poteau_environnement: [],
                img_poteau_etiquette: [],
                img_poteau_tete_armement: [],
            },
            labels,
            loading: true,
            mandatoryFields: ['statut_audit', 'statut_audit'],
            options,
            selectedTab: 'General',
            valueMissing: false,
        };
    },
    methods: {
        condition_tab_chambre(): boolean {
            const { type_struc } = this.form;
            return type_struc === 'CHAMBRE';
        },
        condition_tab_poteau(): boolean {
            const { type_struc } = this.form;
            return type_struc === 'APPUI';
        },
        condition_img_chambre_environnement(): boolean {
            const { type_struc } = this.form;
            return type_struc === 'CHAMBRE';
        },
        condition_scellement_cadre(): boolean {
            const { type_struc } = this.form;
            return type_struc === 'CHAMBRE';
        },
        condition_img_chambre_dessus(): boolean {
            const { type_struc } = this.form;
            return type_struc === 'CHAMBRE';
        },
        condition_masque_percute(): boolean {
            const { type_struc } = this.form;
            return type_struc === 'CHAMBRE';
        },
        condition_img_chambre_masques(): boolean {
            const { type_struc } = this.form;
            return type_struc === 'CHAMBRE';
        },
        condition_nb_fourreaux(): boolean {
            const { type_struc } = this.form;
            return type_struc === 'CHAMBRE';
        },
        condition_qualite_refection(): boolean {
            const { type_struc } = this.form;
            return type_struc === 'CHAMBRE';
        },
        condition_img_refection(): boolean {
            const { type_struc } = this.form;
            return type_struc === 'CHAMBRE';
        },
        condition_etiquette_chambre(): boolean {
            const { type_struc } = this.form;
            return type_struc === 'CHAMBRE';
        },
        condition_type_chambre(): boolean {
            const { type_struc } = this.form;
            return type_struc === 'CHAMBRE';
        },
        condition_respect_coupe(): boolean {
            const { type_struc } = this.form;
            return type_struc === 'CHAMBRE';
        },
        condition_percement_chambre(): boolean {
            const { type_struc } = this.form;
            return type_struc === 'CHAMBRE';
        },
        condition_comment_gb(): boolean {
            const { criticite } = this.form;
            return !!criticite;
        },
        condition_aplomb(): boolean {
            const { type_struc } = this.form;
            return type_struc === 'APPUI';
        },
        condition_img_poteau_environnement(): boolean {
            const { type_struc } = this.form;
            return type_struc === 'APPUI';
        },
        condition_appui(): boolean {
            const { type_struc } = this.form;
            return type_struc === 'APPUI';
        },
        condition_prof_implantation(): boolean {
            const { type_struc } = this.form;
            return type_struc === 'APPUI';
        },
        condition_etiquette_pastille(): boolean {
            const { type_struc } = this.form;
            return type_struc === 'APPUI';
        },
        condition_img_poteau_etiquette(): boolean {
            const { type_struc } = this.form;
            return type_struc === 'APPUI';
        },
        condition_etat_voirie(): boolean {
            const { type_struc } = this.form;
            return type_struc === 'APPUI';
        },
        condition_ras(): boolean {
            const { type_struc } = this.form;
            return type_struc === 'APPUI';
        },
        condition_armement_cable(): boolean {
            const { type_struc } = this.form;
            return type_struc === 'APPUI';
        },
        condition_img_poteau_tete_armement(): boolean {
            const { type_struc } = this.form;
            return type_struc === 'APPUI';
        },
        condition_comment_gb(): boolean {
            const { criticite } = this.form;
            return !!criticite;
        },
    },
});
</script>
