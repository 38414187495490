<template>
    <div>
        <BasePanelItemHeader :title="$t('search.title')" />
        <main class="search__container">
            <InputSearch
                :placeholder="$t('search.searchOnMap')"
                @update:value="updateSearchValue"
            />
            <FontAwesomeIcon
                v-show="isSearching"
                class="search__spinner"
                icon="circle-notch"
                spin
            />

            <span class="search__error">{{ searchError }}</span>

            <div>
                <SearchRow
                    v-for="result in searchResults"
                    :key="result.uniqueLayerFeatureId"
                    :project-id="projectId"
                    :row="result"
                />
            </div>
        </main>
    </div>
</template>

<script lang="ts">
import type BaseLayer from 'ol/layer/Base';
import { defineComponent } from 'vue';
import type Layer from 'ol/layer/Layer';
import type Map from 'ol/Map';
import VectorLayer from 'ol/layer/Vector';
import type VectorSource from 'ol/source/Vector';

import * as LayerApi from '@connect-field/client/services/api/layer';
import {
    getFeaturesFromSearch,
    type RawResultInterface,
    search,
} from '@connect-field/client/services/offlineSearch.service';
import {
    type ProjectConfigurationDto,
    type ProjectConfigurationLayer,
    ProjectConfigurationLayerFormatEnum,
    type SearchGeoJSONFeature,
    SearchGeoJSONLayerFormatEnum,
} from '@connect-field/client/sdk/generated';
import {
    searchErrors,
    searchParameters,
} from '@connect-field/client/pages/home/search/SearchData';
import BasePanelItemHeader from '@connect-field/client/components/ui/BasePanelItemHeader.vue';
import { getMap } from '@connect-field/client/services/map.service';
import InputSearch from '@connect-field/client/components/inputs/InputSearch.vue';
import SearchRow from '@connect-field/client/pages/home/search/SearchRow.vue';
import useMapStore from '@connect-field/client/stores/map';
import usePanelStore from '@connect-field/client/stores/panel';
import useProjectsStore from '@connect-field/client/stores/projects';

interface DataInterface {
    isSearching: boolean;
    searchError: string;
    searchModel: string;
    searchResults: Array<SearchResult>;
    searchTimeout?: ReturnType<typeof setTimeout>;
}

export interface SearchResult {
    feature: SearchGeoJSONFeature;
    layerAlias: string;
    layerFormat: SearchGeoJSONLayerFormatEnum;
    layerId: number;
}

export default defineComponent({
    components: {
        BasePanelItemHeader,
        InputSearch,
        SearchRow,
    },

    props: {
        projectId: {
            type: String,
            required: true,
        },
    },

    setup() {
        return {
            mapStore: useMapStore(),
            panelStore: usePanelStore(),
            projectsStore: useProjectsStore(),
        };
    },

    data(): DataInterface {
        return {
            isSearching: false,
            searchError: '',
            searchModel: '',
            searchResults: [],
        };
    },

    computed: {
        isProjectOffline(): boolean {
            return this.projectsStore.isOffline;
        },
        map(): Map {
            if (!this.mapStore.map) {
                throw new Error('map is undefined');
            }

            return this.mapStore.map as Map;
        },
        selectedProject(): ProjectConfigurationDto | undefined {
            return this.projectsStore.selectedProject;
        },
    },
    mounted() {
        this.panelStore.setPanelFullyOpened();
    },
    unmounted() {
        if (!this.map) {
            return;
        }

        const pbfLayerSource = this.getSearchResultPbfLayer();
        if (pbfLayerSource) {
            pbfLayerSource.clear();
        }
    },
    methods: {
        getLayerIds(arrayOfLayer: Array<BaseLayer>): Array<number> {
            const arrayOfId: Array<number> = [];
            arrayOfLayer.forEach((layer: BaseLayer) => {
                const properties = layer.getProperties();
                if (properties.id) {
                    const layerId = properties.id;
                    if (
                        typeof layerId === 'number' ||
                        !isNaN(parseInt(layerId, 10))
                    ) {
                        arrayOfId.push(layerId);
                    }
                }
            });

            return arrayOfId;
        },

        async getOnlineSearchResults(
            arrayOfLayerIds: Array<number>,
            keyword: string,
        ): Promise<Array<SearchResult>> {
            const searchResults: Array<SearchResult> = [];

            await Promise.all(
                arrayOfLayerIds.map(async (layerId: number) => {
                    const data = await LayerApi.searchLayerObjects(
                        layerId,
                        keyword,
                    );
                    if (data?.features) {
                        data.features.forEach(
                            (feature: SearchGeoJSONFeature) => {
                                const result: SearchResult = {
                                    feature,
                                    layerAlias: data.layerAlias,
                                    layerFormat: data.layerFormat,
                                    layerId,
                                };
                                searchResults.push(result);
                            },
                        );
                    }
                }),
            );

            return searchResults;
        },

        getProjectLayer(arrayOfLayerTables: Array<string>): Array<BaseLayer> {
            return getMap()
                .getLayers()
                .getArray()
                .filter(
                    (layer: BaseLayer) =>
                        arrayOfLayerTables.includes(
                            layer.getProperties().table,
                        ) &&
                        layer.getVisible() &&
                        !layer.getProperties()?.global,
                );
        },

        getProjectLayerTables(): Array<string> {
            if (!this.selectedProject) {
                throw new Error('No selected project');
            }

            return this.selectedProject.layers.reduce(
                (
                    layerTables: Array<string>,
                    layer: ProjectConfigurationLayer,
                ) => {
                    if (
                        this.isProjectOffline &&
                        layer.format !==
                            ProjectConfigurationLayerFormatEnum.GEOJSON
                    ) {
                        // TODO : Support search on offline PBF layers
                        return layerTables;
                    }
                    layerTables.push(layer.table);

                    return layerTables;
                },
                [],
            );
        },

        getSearchResultPbfLayer(): VectorSource | undefined {
            const searchResultPbfLayer = this.map
                .getLayers()
                .getArray()
                .find(
                    (layer: BaseLayer) =>
                        layer instanceof VectorLayer &&
                        layer.getProperties().name === 'searchResultPbfLayer',
                );

            if (
                searchResultPbfLayer &&
                searchResultPbfLayer instanceof VectorLayer
            ) {
                return searchResultPbfLayer.getSource();
            }

            return undefined;
        },

        async searchKeyword(): Promise<void> {
            const layerTables = this.getProjectLayerTables();
            const layersList = this.getProjectLayer(layerTables);

            if (this.isProjectOffline) {
                const tableNames: Array<string> = layersList.map(
                    (layer: BaseLayer) => {
                        return layer.getProperties().table ?? '';
                    },
                );

                const rawResults: Array<RawResultInterface> = tableNames.reduce(
                    (acc: Array<RawResultInterface>, tableName: string) => {
                        const result = search(tableName, this.searchModel);
                        if (result) {
                            acc.push(...result);
                        }

                        return acc;
                    },
                    [],
                );

                this.searchResults = getFeaturesFromSearch(
                    layersList,
                    rawResults,
                );
            } else {
                const layerIds = this.getLayerIds(layersList as Array<Layer>);

                if (
                    !Array.isArray(layerIds) ||
                    (Array.isArray(layerIds) && layerIds.length < 1) ||
                    !this.searchModel
                ) {
                    return;
                }

                const searchResults = await this.getOnlineSearchResults(
                    layerIds,
                    this.searchModel,
                );
                // this.preparePbfResults(pbfResults);

                this.searchResults = searchResults.sort(this.sortSearchResults);
            }

            if (this.searchResults.length < 1) {
                this.searchError = searchErrors.noResults;
            }

            if (
                this.searchResults.length >= searchParameters.maxNumberOfResults
            ) {
                this.searchError = searchErrors.tooMuchResults;
            }
        },

        sortSearchResults(
            firstResult: SearchResult,
            secondResult: SearchResult,
        ): number {
            if (firstResult.layerFormat === secondResult.layerFormat) {
                return 0;
            }
            if (
                firstResult.layerFormat === SearchGeoJSONLayerFormatEnum.GEOJSON
            ) {
                return -1;
            }

            return 1;
        },

        updateSearchValue(newValue: string): void {
            this.searchModel = newValue;

            if (!this.selectedProject) {
                this.searchError = searchErrors.noProjectSelected;

                return;
            }

            this.searchError = '';
            this.searchResults = [];

            if (newValue.length < searchParameters.minLengthOfSearchInput) {
                if (newValue.length > 0) {
                    this.searchError =
                        searchErrors.noEnoughCharactersInSearchInput;
                } else {
                    this.searchError = '';
                }

                return;
            }

            this.isSearching = true;

            this.searchKeyword().then(() => {
                this.isSearching = false;
            });
        },
    },
});
</script>

<style scoped lang="scss">
@import 'SearchView.scss';
</style>
