<template>
    <div
        v-if="showModal"
        class="modal-mask"
    >
        <div class="modal-wrapper">
            <div class="modal-container">
                <div class="modal-header text-right">
                    <slot name="header">
                        <el-button
                            type="primary"
                            :icon="Close"
                            size="small"
                            @click="closeModal"
                        />
                    </slot>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        <img
                            class="pictureFromCamera"
                            alt="Photo"
                            :src="pictureToShow"
                        />
                    </slot>
                </div>
            </div>
        </div>
    </div>

    <div v-if="!display">
        <div
            class="button-view"
            @click="toggleDisplayImages"
        >
            <FontAwesomeIcon icon="eye" />
        </div>
    </div>

    <div v-if="display">
        <div
            class="button-view"
            @click="toggleDisplayImages"
        >
            <FontAwesomeIcon icon="eye-slash" />
        </div>
    </div>

    <div
        v-if="!loading && display"
        class="card-photos"
    >
        <div class="text-white">Photos originales :</div>
        <div
            v-for="image in images"
            :key="image"
        >
            <div class="column">
                <div
                    v-if="!image.urls"
                    class="offline-images"
                    :title="getImageAlt(image)"
                >
                    <FontAwesomeIcon
                        icon="image"
                        size="2x"
                        color="white"
                    />
                </div>
                <div
                    v-if="image.urls"
                    :title="getImageAlt(image)"
                >
                    <img
                        class="preview"
                        :alt="getImageAlt(image)"
                        :src="getImageURL(image, 'small', 'full')"
                        @click="() => displayModal(image)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Close } from '@element-plus/icons-vue';
import { defineComponent } from 'vue';
import type { ImageResponseDto } from '@connect-field/client/sdk/generated';

import * as ObjectApi from '@connect-field/client/services/api/object';

interface DataInterface {
    display: boolean;
    images: Array<ImageResponseDto>;
    loading: boolean;
    pictureToShow?: string;
    showModal: boolean;
}

export default defineComponent({
    components: {},
    props: {
        condition: {
            type: String,
            required: true,
        },
        form: {
            type: String,
            required: true,
        },
        layerName: {
            type: String,
            required: true,
        },
        where: {
            type: String,
            required: true,
        },
    },
    setup() {
        return {
            Close,
        };
    },
    data(): DataInterface {
        return {
            display: false,
            images: [],
            loading: true,
            pictureToShow: '',
            showModal: false,
        };
    },

    async mounted() {
        const { condition, form, layerName, where } = this;
        this.images = await ObjectApi.getObjectImages({
            condition,
            form,
            layerName,
            where,
        });
        this.loading = false;
    },
    methods: {
        closeModal(): void {
            this.pictureToShow = undefined;
            this.showModal = false;
        },
        displayModal(image: ImageResponseDto): void {
            this.pictureToShow = this.getImageURL(image, 'full', 'small');
            this.showModal = true;
        },
        getImageAlt(image: Record<string, string>): string {
            return `\n${image.alt}` || 'Photo';
        },
        getImageURL(
            image: ImageResponseDto,
            format: 'full' | 'small',
            fallbackFormat: 'full' | 'small',
        ): string {
            return image.urls?.[format] ?? image.urls?.[fallbackFormat] ?? '';
        },
        toggleDisplayImages(): void {
            this.display = !this.display;
        },
    },
});
</script>

<style scoped lang="scss">
.card-photos {
    border-radius: 5px;
    border: white solid 1px;
    padding: 10px;
    margin: 5px;
}

.button-view {
    background: #2d9cfd;
    padding: 5px 10px;
    border: 1px solid black;
    color: white;
    max-width: 40px;
    border-radius: 5px;
    display: inline-block;
    cursor: pointer;
}

.preview {
    max-width: 80px;
}

.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.modal-container {
    width: 90%;
    margin: 0 auto;
    padding: 10px 10px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
    margin-top: 0;
    color: #42b983;
}

.modal-body {
    margin: 20px 0;
    text-align: center;
}

.display-text {
    color: white;
}
</style>
