<template>
    <el-row>
        <el-col :span="24">
            <div class="custom_label_input">{{ label }}</div>
        </el-col>
        <el-col :span="24">
            <el-form-item>
                <el-select
                    v-model="model"
                    :disabled="disabled"
                    multiple
                    :size="size"
                    :placeholder="placeholder"
                    class="customInput"
                >
                    <el-option
                        v-for="option in options"
                        :key="option.value"
                        :disabled="disabledOption(option.value)"
                        :label="option.label"
                        :value="option.value"
                    />
                </el-select>
            </el-form-item>
        </el-col>
    </el-row>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';

export default defineComponent({
    name: 'CustomInputMultiSelect',
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        disabledOptionValue: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
        options: {
            type: Array as PropType<Array<{ label: string; value: string }>>,
            required: true,
        },
        placeholder: {
            type: String,
            default: 'Veuillez choisir une option',
        },
        size: {
            type: String,
            values: ['large', 'default', 'small'],
            default: 'default',
        },
        value: {
            // TODO: Define type
            type: Array as PropType<Array<unknown>>,
            default: undefined,
        },
    },
    emits: ['update:value'],
    computed: {
        model: {
            get(): Array<unknown> {
                if (!this.value) {
                    return [];
                } else return this.value;
            },
            set(value: Array<unknown>): void {
                if (!value) {
                    value = [];
                }
                this.$emit('update:value', value);
            },
        },
    },
    methods: {
        disabledOption(option: string): boolean {
            if (this.disabledOptionValue && this.model) {
                if (option != this.disabledOptionValue) {
                    if (this.model.includes(this.disabledOptionValue)) {
                        return true;
                    }
                }
                if (option === this.disabledOptionValue) {
                    if (
                        this.model.length > 0 &&
                        !this.model.includes(this.disabledOptionValue)
                    ) {
                        return true;
                    }
                }

                return false;
            }

            return false;
        },
    },
});
</script>
