<template>
    <FormWrapper
        v-if="!loading"
        ref="customFormRef"
        :images-model="images"
        :form-model="form"
        :mandatory-fields="mandatoryFields"
        :changes-model="changes"
        :layer-name="layerName"
        :object-id="objectId"
        :project-id="projectId"
        :latitude="latitude"
        :longitude="longitude"
        @update:images-model="
            (e) => {
                images = e;
            }
        "
        @update:value-missing="
            (e) => {
                valueMissing = e;
            }
        "
        @update:form-model="
            (e) => {
                form = e;
            }
        "
        @update:changes-model="
            (e) => {
                changes = e;
            }
        "
    >
        <template #menu_tabs>
            <el-row class="scroll-menu">
                <el-col :span="6">
                    <div
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'Général' }"
                        @click="selectTab('Général')"
                    >
                        Général
                    </div>
                </el-col>
                <el-col :span="6">
                    <div
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'Chambre' }"
                        @click="selectTab('Chambre')"
                    >
                        Chambre
                    </div>
                </el-col>
                <el-col :span="6">
                    <div
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'Fourreaux' }"
                        @click="selectTab('Fourreaux')"
                    >
                        Fourreaux
                    </div>
                </el-col>
                <el-col :span="6">
                    <div
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'Optique' }"
                        @click="selectTab('Optique')"
                    >
                        Optique
                    </div>
                </el-col>
                <el-col :span="6">
                    <div
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'Audit' }"
                        @click="selectTab('Audit')"
                    >
                        Audit
                    </div>
                </el-col>
            </el-row>
        </template>
        <template #main_form>
            <el-row>
                <el-col
                    v-show="selectedTab === 'Général'"
                    :span="24"
                >
                    <CustomInput
                        :label="labels.code"
                        :value="form.code"
                        :maxlength="255"
                        @update:value="(newValue) => saveData('code', newValue)"
                    />
                    <CustomInput
                        :label="labels.adresse_chambre"
                        :value="form.adresse_chambre"
                        @update:value="
                            (newValue) => saveData('adresse_chambre', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.type_chambre + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.type_chambre"
                        :maxlength="255"
                        @update:value="
                            (newValue) => saveData('type_chambre', newValue)
                        "
                    />
                    <CustomDatePicker
                        :label="labels.date_construction"
                        :value="form.date_construction"
                        @update:value="
                            (newValue) =>
                                saveData('date_construction', newValue)
                        "
                    />
                    <CustomDatePicker
                        :label="labels.date_visite + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.date_visite"
                        @update:value="
                            (newValue) => saveData('date_visite', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.propriete"
                        :value="form.propriete"
                        :maxlength="255"
                        @update:value="
                            (newValue) => saveData('propriete', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.gestionnaire"
                        :value="form.gestionnaire"
                        @update:value="
                            (newValue) => saveData('gestionnaire', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_env_ext + ' *'"
                        :images="images.img_env_ext"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('img_env_ext', newValue)
                        "
                    />
                </el-col>
                <el-col
                    v-show="selectedTab === 'Chambre'"
                    :span="24"
                >
                    <InputCamera
                        :label="labels.img_etat_chambre + ' *'"
                        :images="images.img_etat_chambre"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_etat_chambre', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.etat_chambre + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.etat_chambre"
                        :value="form.etat_chambre"
                        @update:value="
                            (newValue) => saveData('etat_chambre', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_etat_tampon + ' *'"
                        :images="images.img_etat_tampon"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('img_etat_tampon', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.etat_tampon + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.etat_tampon"
                        :value="form.etat_tampon"
                        @update:value="
                            (newValue) => saveData('etat_tampon', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_etat_cadre + ' *'"
                        :images="images.img_etat_cadre"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('img_etat_cadre', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.etat_cadre + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.etat_cadre"
                        :value="form.etat_cadre"
                        @update:value="
                            (newValue) => saveData('etat_cadre', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.presence_grille + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.presence_grille"
                        :value="form.presence_grille"
                        @update:value="
                            (newValue) => saveData('presence_grille', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_etat_masque + ' *'"
                        :images="images.img_etat_masque"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('img_etat_masque', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.etat_masque + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.etat_masque"
                        :value="form.etat_masque"
                        @update:value="
                            (newValue) => saveData('etat_masque', newValue)
                        "
                    />
                </el-col>
                <el-col
                    v-show="selectedTab === 'Fourreaux'"
                    :span="24"
                >
                    <InputCamera
                        :label="labels.img_etat_fourreaux + ' *'"
                        :images="images.img_etat_fourreaux"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_etat_fourreaux', newValue)
                        "
                    />
                    <CustomInputMultiSelect
                        :label="labels.etat_fourreaux + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.etat_fourreaux"
                        :value="form.etat_fourreaux"
                        @update:value="
                            (newValue) => saveData('etat_fourreaux', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_etiquetage_fourreaux + ' *'"
                        :images="images.img_etiquetage_fourreaux"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_etiquetage_fourreaux', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.etiquetage_fourreaux + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.etiquetage_fourreaux"
                        :value="form.etiquetage_fourreaux"
                        @update:value="
                            (newValue) =>
                                saveData('etiquetage_fourreaux', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_occupation_fourreaux + ' *'"
                        :images="images.img_occupation_fourreaux"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_occupation_fourreaux', newValue)
                        "
                    />
                    <CustomInputMultiSelect
                        :label="labels.occupation_fourreaux + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.occupation_fourreaux"
                        :value="form.occupation_fourreaux"
                        @update:value="
                            (newValue) =>
                                saveData('occupation_fourreaux', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_nb_alv_a + ' *'"
                        :images="images.img_nb_alv_a"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('img_nb_alv_a', newValue)
                        "
                    />
                    <InputNumber
                        :label="labels.nb_alv_a + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.nb_alv_a"
                        @update:value="
                            (newValue) => saveData('nb_alv_a', newValue)
                        "
                    />
                    <InputNumber
                        :label="labels.nb_alv_ocp_a + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.nb_alv_ocp_a"
                        @update:value="
                            (newValue) => saveData('nb_alv_ocp_a', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_nb_alv_b + ' *'"
                        :images="images.img_nb_alv_b"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('img_nb_alv_b', newValue)
                        "
                    />
                    <InputNumber
                        :label="labels.nb_alv_b + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.nb_alv_b"
                        @update:value="
                            (newValue) => saveData('nb_alv_b', newValue)
                        "
                    />
                    <InputNumber
                        :label="labels.nb_alv_ocp_b + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.nb_alv_ocp_b"
                        @update:value="
                            (newValue) => saveData('nb_alv_ocp_b', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_nb_alv_c + ' *'"
                        :images="images.img_nb_alv_c"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('img_nb_alv_c', newValue)
                        "
                    />
                    <InputNumber
                        :label="labels.nb_alv_c + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.nb_alv_c"
                        @update:value="
                            (newValue) => saveData('nb_alv_c', newValue)
                        "
                    />
                    <InputNumber
                        :label="labels.nb_alv_ocp_c + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.nb_alv_ocp_c"
                        @update:value="
                            (newValue) => saveData('nb_alv_ocp_c', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_nb_alv_d + ' *'"
                        :images="images.img_nb_alv_d"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('img_nb_alv_d', newValue)
                        "
                    />
                    <InputNumber
                        :label="labels.nb_alv_d + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.nb_alv_d"
                        @update:value="
                            (newValue) => saveData('nb_alv_d', newValue)
                        "
                    />
                    <InputNumber
                        :label="labels.nb_alv_ocp_d + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.nb_alv_ocp_d"
                        @update:value="
                            (newValue) => saveData('nb_alv_ocp_d', newValue)
                        "
                    />
                </el-col>
                <el-col
                    v-show="selectedTab === 'Optique'"
                    :span="24"
                >
                    <InputNumber
                        :label="labels.nb_BPE + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.nb_BPE"
                        @update:value="
                            (newValue) => saveData('nb_BPE', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.fixation_BPE + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.fixation_BPE"
                        :maxlength="255"
                        @update:value="
                            (newValue) => saveData('fixation_BPE', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_BPE + ' *'"
                        :images="images.img_BPE"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('img_BPE', newValue)
                        "
                    />
                    <InputNumber
                        :label="labels.nb_cables + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.nb_cables"
                        @update:value="
                            (newValue) => saveData('nb_cables', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.etiquetage_cables + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.etiquetage_cables"
                        :maxlength="255"
                        @update:value="
                            (newValue) =>
                                saveData('etiquetage_cables', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_cables + ' *'"
                        :images="images.img_cables"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('img_cables', newValue)
                        "
                    />
                </el-col>
                <el-col
                    v-show="selectedTab === 'Audit'"
                    :span="24"
                >
                    <CustomInputSelect
                        :label="labels.statut_visite + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.statut_visite"
                        :value="form.statut_visite"
                        @update:value="
                            (newValue) => saveData('statut_visite', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.degre_intervention + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.degre_intervention"
                        :value="form.degre_intervention"
                        @update:value="
                            (newValue) =>
                                saveData('degre_intervention', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.commentaire"
                        @update:value="
                            (newValue) => saveData('commentaire', newValue)
                        "
                    />
                </el-col>
            </el-row>
        </template>
    </FormWrapper>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import {
    labels,
    options,
} from '@connect-field/client/components/forms/Dotic/GraceTHD-v2/ChambreForm.data';
import type { FormDataInterface } from '@connect-field/client/components/forms/form.types';
import FormTemplate from '@connect-field/client/components/forms/FormTemplate.vue';

interface DataInterface extends FormDataInterface {}

export default defineComponent({
    extends: FormTemplate,
    data(): DataInterface {
        return {
            changes: false,
            form: {},
            images: {
                img_BPE: [],
                img_cables: [],
                img_env_ext: [],
                img_etat_cadre: [],
                img_etat_chambre: [],
                img_etat_fourreaux: [],
                img_etat_masque: [],
                img_etat_tampon: [],
                img_etiquetage_fourreaux: [],
                img_nb_alv_a: [],
                img_nb_alv_b: [],
                img_nb_alv_c: [],
                img_nb_alv_d: [],
                img_occupation_fourreaux: [],
            },
            labels,
            loading: true,
            mandatoryFields: [
                'type_chambre',
                'date_visite',
                'img_env_ext',
                'img_etat_chambre',
                'etat_chambre',
                'img_etat_tampon',
                'etat_tampon',
                'img_etat_cadre',
                'etat_cadre',
                'presence_grille',
                'img_etat_masque',
                'etat_masque',
                'img_etat_fourreaux',
                'etat_fourreaux',
                'img_etiquetage_fourreaux',
                'etiquetage_fourreaux',
                'img_occupation_fourreaux',
                'occupation_fourreaux',
                'img_nb_alv_a',
                'nb_alv_a',
                'nb_alv_ocp_a',
                'img_nb_alv_b',
                'nb_alv_b',
                'nb_alv_ocp_b',
                'img_nb_alv_c',
                'nb_alv_c',
                'nb_alv_ocp_c',
                'img_nb_alv_d',
                'nb_alv_d',
                'nb_alv_ocp_d',
                'nb_BPE',
                'fixation_BPE',
                'img_BPE',
                'nb_cables',
                'etiquetage_cables',
                'img_cables',
                'statut_visite',
                'degre_intervention',
                'commentaire',
            ],
            options,
            selectedTab: 'Général',
            valueMissing: false,
        };
    },
});
</script>
