export class TokenError {
    public static expiredToken = '/docs/api/errors/authentication/token-expired-error';
    public static wrongToken = '/docs/api/errors/authentication/wrong-token-error';
}

export function redirectToLogin(): void {
    const { protocol, host } = window.location;

    return window.location.replace(`${protocol}//${host}/#/logout/token-expired`);
}
