<template>
    <FormWrapper
        v-if="!loading"
        ref="customFormRef"
        :changes-model="changes"
        :form-model="form"
        :images-model="images"
        :latitude="latitude"
        :layer-name="layerName"
        :longitude="longitude"
        :mandatory-fields="mandatoryFields"
        :object-id="objectId"
        :project-id="projectId"
        @update:images-model="
            (e) => {
                images = e;
            }
        "
        @update:value-missing="
            (e) => {
                valueMissing = e;
            }
        "
        @update:form-model="
            (e) => {
                form = e;
            }
        "
        @update:changes-model="
            (e) => {
                changes = e;
            }
        "
    >
        <template #menu_tabs>
            <el-row class="scroll-menu">
                <el-col :span="12">
                    <div
                        :class="{ selectedTab: selectedTab === 'General' }"
                        class="tabs"
                        @click="selectTab('General')"
                    >
                        General
                    </div>
                </el-col>

                <el-col :span="12">
                    <div
                        :class="{ selectedTab: selectedTab === 'Poteau' }"
                        class="tabs"
                        @click="selectTab('Poteau')"
                    >
                        Poteau
                    </div>
                </el-col>
            </el-row>
        </template>
        <template #main_form>
            <el-row>
                <el-col
                    v-show="selectedTab === 'General'"
                    :span="24"
                >
                    <CustomInput
                        :label="labels.code"
                        :value="form.code"
                        @update:value="(newValue) => saveData('code', newValue)"
                    />
                    <CustomInput
                        :label="labels.gestionnaire"
                        :maxlength="254"
                        :value="form.gestionnaire"
                        @update:value="
                            (newValue) => saveData('gestionnaire', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.type_fonc"
                        :value="form.type_fonc"
                        @update:value="
                            (newValue) => saveData('type_fonc', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.type_struc"
                        :maxlength="254"
                        :value="form.type_struc"
                        @update:value="
                            (newValue) => saveData('type_struc', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.modele"
                        :value="form.modele"
                        @update:value="
                            (newValue) => saveData('modele', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.date_pose"
                        :maxlength="254"
                        :value="form.date_pose"
                        @update:value="
                            (newValue) => saveData('date_pose', newValue)
                        "
                    />
                    <CustomDatePicker
                        :label="labels.date_audit"
                        :value="form.date_audit"
                        @update:value="
                            (newValue) => saveData('date_audit', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.respect_pose"
                        :options="options.respect_pose"
                        :value="form.respect_pose"
                        size="small"
                        @update:value="
                            (newValue) => saveData('respect_pose', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.comment_pose"
                        :value="form.comment_pose"
                        @update:value="
                            (newValue) => saveData('comment_pose', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.respect_pgc"
                        :options="options.respect_pgc"
                        :value="form.respect_pgc"
                        size="small"
                        @update:value="
                            (newValue) => saveData('respect_pgc', newValue)
                        "
                    />
                </el-col>
                <el-col
                    v-show="selectedTab === 'Poteau'"
                    :span="24"
                >
                    <CustomRadioButton
                        :label="labels.aplomb"
                        :options="options.aplomb"
                        :value="form.aplomb"
                        size="small"
                        @update:value="
                            (newValue) => saveData('aplomb', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.img_poteau_environnement"
                        :label="labels.img_poteau_environnement"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_poteau_environnement', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.appui"
                        :options="options.appui"
                        :value="form.appui"
                        size="small"
                        @update:value="
                            (newValue) => saveData('appui', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.prof_implantation"
                        :options="options.prof_implantation"
                        :value="form.prof_implantation"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('prof_implantation', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.etiquette_pastille"
                        :options="options.etiquette_pastille"
                        :value="form.etiquette_pastille"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('etiquette_pastille', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.img_poteau_etiquette"
                        :label="labels.img_poteau_etiquette"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_poteau_etiquette', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.etat_voirie"
                        :options="options.etat_voirie"
                        :value="form.etat_voirie"
                        size="small"
                        @update:value="
                            (newValue) => saveData('etat_voirie', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.ras"
                        :options="options.ras"
                        :value="form.ras"
                        size="small"
                        @update:value="(newValue) => saveData('ras', newValue)"
                    />
                    <CustomRadioButton
                        :label="labels.armement_cable"
                        :options="options.armement_cable"
                        :value="form.armement_cable"
                        size="small"
                        @update:value="
                            (newValue) => saveData('armement_cable', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.img_poteau_tete_armement"
                        :label="labels.img_poteau_tete_armement"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_poteau_tete_armement', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.img_autres"
                        :label="labels.img_autres"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('img_autres', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.criticite"
                        :options="options.criticite"
                        :value="form.criticite"
                        @update:value="
                            (newValue) => saveData('criticite', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_comment()"
                        :label="labels.comment"
                        :value="form.comment"
                        @update:value="
                            (newValue) => saveData('comment', newValue)
                        "
                    />
                    <CustomInputSelect
                        :class="{ 'text-warning': valueMissing }"
                        :label="labels.statut_audit + ' *'"
                        :options="options.statut_audit"
                        :value="form.statut_audit"
                        @update:value="
                            (newValue) => saveData('statut_audit', newValue)
                        "
                    />
                </el-col>
            </el-row>
        </template>
    </FormWrapper>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import {
    labels,
    options,
} from '@connect-field/client/components/forms/NGE/form_concept_poteau_nge.data';
import type { FormDataInterface } from '@connect-field/client/components/forms/form.types';
import FormTemplate from '@connect-field/client/components/forms/FormTemplate.vue';

interface DataInterface extends FormDataInterface {}

export default defineComponent({
    extends: FormTemplate,
    data(): DataInterface {
        return {
            changes: false,
            form: {},
            images: {
                img_poteau_environnement: [],
                img_poteau_etiquette: [],
                img_poteau_tete_armement: [],
                img_autres: [],
            },
            labels,
            loading: true,
            mandatoryFields: ['statut_audit'],
            options,
            selectedTab: 'General',
            valueMissing: false,
        };
    },
    methods: {
        condition_comment(): boolean {
            const { criticite } = this.form;
            return !!criticite;
        },
    },
});
</script>
