import type Feature from 'ol/Feature';

import * as ObjectApi from '@connect-field/client/services/api/object';
import { arrayIntersect, deepCopy } from '@connect-field/client/utilities/tools';
import { deleteItem, getAllKeysFromIndex, getItem, setStore } from '@connect-field/client/utilities/idb-utility';
import type {
    ImageResponseDto,
    ProjectConfigurationLayer,
    ProjectConfigurationDto,
} from '@connect-field/client/sdk/generated';
import useObjectStore, { type LayerDataInterface } from '@connect-field/client/stores/object';
import type { ObjectDataInterface } from '@connect-field/client/services/map.service';
import useProjectsStore from '@connect-field/client/stores/projects';

export interface ProjectGeoJSONInterface {
    alias: string;
    data: {
        features: Array<Feature>;
        type: 'FeatureCollection';
    };
    id: number;
    name: string;
    type: 'geojson';
}

export class OfflineFormService {
    public static async addFeatureToGeojson(
        offlineProjectId: string,
        layerId: string,
        newFeature: Feature,
    ): Promise<void> {
        const projectGeojsons = await getItem<Array<ProjectGeoJSONInterface>>(offlineProjectId, 'projectGeojsons');
        if (!projectGeojsons) {
            console.error('The layer cannot be updated because the project geojson cannot be found');

            return;
        }

        const geojsonLayer = projectGeojsons.find((layer: ProjectGeoJSONInterface) => {
            return layer?.id.toString() === layerId.toString();
        });
        geojsonLayer?.data.features.push(deepCopy(newFeature));

        const updatedGeojsons = deepCopy(projectGeojsons);

        await setStore('projectGeojsons', updatedGeojsons, offlineProjectId);
    }

    public static async fetchImages(
        objectId: number | string,
        layerName: string,
    ): Promise<Record<string, Array<unknown>>> {
        const [imageKeysByLayer, imageKeysByForeignKey] = await Promise.all([
            getAllKeysFromIndex('projectImages_v2', 'layer', layerName),
            getAllKeysFromIndex('projectImages_v2', 'fk_sid', objectId),
        ]);
        const imageIndexes: Array<string> = arrayIntersect(imageKeysByLayer, imageKeysByForeignKey);

        const record: Record<string, Array<unknown>> = {};

        await Promise.all(
            imageIndexes.map(async (id: string) => {
                const img = await getItem<Record<string, unknown>>(id, 'projectImages_v2');
                const _type = (img.type_img as string).toLowerCase();
                const urls = {
                    full: `data:${img.data_type};base64,${img.img}`,
                    small: `data:${img.data_type};base64,${img.img}`,
                };

                if (!record[_type]) {
                    record[_type] = [];
                }
                record[_type].push({
                    alt: img.nom_img,
                    sid: img.sid,
                    urls: urls,
                });
            }),
        );

        return record;
    }

    public static async updateFeatureProperties(
        offlineProjectId: string,
        layerId: number | string,
        featureIdToUpdate: string,
        updatedObject: ObjectDataInterface,
    ): Promise<void> {
        const projectGeojsons = await getItem<Array<ProjectGeoJSONInterface>>(offlineProjectId, 'projectGeojsons');
        if (!projectGeojsons) {
            console.error('The layer cannot be updated because the project geojson cannot be found');

            return;
        }

        const geojsonLayer = projectGeojsons.find((geojson: ProjectGeoJSONInterface) => {
            return geojson?.id.toString() === layerId.toString();
        });

        if (geojsonLayer) {
            // TODO : Fix type with feature
            const geojsonFeature = geojsonLayer.data.features.find(
                (feature: Feature) => feature?.id?.toString() === featureIdToUpdate,
            );

            if (geojsonFeature) {
                // TODO : Fix type with feature
                Object.keys(geojsonFeature.properties).forEach((propertyKey: string) => {
                    if (updatedObject.dataForm[propertyKey]) {
                        geojsonFeature.properties[propertyKey] = updatedObject.dataForm[propertyKey];
                    }
                });
            } else {
                console.warn('[form/updateFeatureProperties] geojsonFeature is undefined');
            }

            const updatedGeojsons = deepCopy(projectGeojsons);

            await setStore('projectGeojsons', updatedGeojsons, offlineProjectId);
        } else {
            console.warn('[form/updateFeatureProperties] geojsonLayer is undefined');
        }
    }
}

export class DraftFormService {
    public static apply<T>(initialDataForm: Record<string, T>, draftForm: Record<string, T>): Record<string, T> {
        const objectStore = useObjectStore();

        Object.keys(draftForm.dataForm).forEach((key: string, index: number) => {
            initialDataForm[key] = Object.values(draftForm.dataForm)[index];
        });
        objectStore.$patch({ dataForm: initialDataForm });

        return initialDataForm;
    }

    public static async get(layerName: string, sid: string | number): Promise<Record<string, unknown> | null> {
        const key = `${layerName}-${sid}`;
        try {
            return await getItem<Record<string, unknown>>(key, 'draftDataForms');
        } catch {
            return null;
        }
    }

    public static async remove(key: string): Promise<void> {
        if (!key) {
            return;
        }

        await deleteItem('draftDataForms', key);
    }

    public static async save(key: string, newValue: unknown): Promise<string | undefined> {
        const objectStore = useObjectStore();

        const layerData = objectStore.layerData;
        const dataForm = objectStore.dataForm;
        if (layerData && dataForm) {
            const layerName = layerData.name;
            const sid = dataForm.sid;

            const tempKey = `${layerName}-${sid}`;
            const oldObject = await getItem<ObjectDataInterface>(tempKey, 'draftDataForms');

            const objectDataForm = {
                ...(oldObject && { ...oldObject.dataForm }),
                [key]: newValue,
            };

            const tempObject = {
                dataForm: objectDataForm,
                layerName,
                sid,
            };
            await setStore('draftDataForms', tempObject, tempKey);

            return tempKey;
        }
        console.error('[saveTemporaryDataForm] No layerData nor dataForm');

        return undefined;
    }

    public static async saveOnCreation(data: {
        key: string;
        newValue: unknown;
        sid: string | number;
    }): Promise<string> {
        const { key, newValue, sid } = data;
        const objectStore = useObjectStore();

        const layerData = objectStore.layerData;
        if (!layerData) {
            throw new Error('layerData is undefined');
        }
        const layerName = layerData.name;

        const tempKey = `${layerName}-${sid}`;
        const oldObject = await getItem<ObjectDataInterface>(tempKey, 'draftDataForms');

        let objectDataForm = {};
        if (oldObject) {
            objectDataForm = { ...oldObject.dataForm, [key]: newValue };
        } else {
            objectDataForm = { [key]: newValue };
        }

        const tempObject = {
            dataForm: objectDataForm,
            layerName,
            sid,
        };
        await setStore('draftDataForms', tempObject, tempKey);

        return tempKey;
    }
}

export function getLayerFromObjectData(layerData: LayerDataInterface): ProjectConfigurationLayer | undefined {
    const projectsStore = useProjectsStore();

    if (!layerData.name) {
        console.warn('No layerData name', layerData);

        return undefined;
    }

    const layerName = layerData.name;

    const projectLayers = projectsStore.selectedProject as ProjectConfigurationDto | undefined;
    if (!projectLayers) {
        throw new Error('selectedProject is undefined');
    }

    const layers = projectLayers.layers?.filter?.((layer: ProjectConfigurationLayer) => {
        return layer.name === layerName;
    });

    if (!layers || layers.length === 0) {
        console.warn('No layer found');

        return undefined;
    }

    const [layer] = layers;

    if (!layer) {
        console.warn('No layer found');

        return undefined;
    }

    return layer;
}

export function getObjectFromForm(
    objects: Array<ObjectDataInterface>,
    layerName: string,
    formSid: string,
): ObjectDataInterface | undefined {
    for (let i = 0; i < objects.length; i++) {
        const object = objects[i];

        if (object.layerData.name === layerName) {
            if (object.dataForm.sid.toString() === formSid) {
                return object;
            }
        }
    }
}

export function removeLastActionLocalStorage(): void {
    localStorage.setItem('lastAction', JSON.stringify(null));
    localStorage.setItem('lastDataForm', JSON.stringify(null));
    localStorage.setItem('lastFormInfo', JSON.stringify(null));
    localStorage.setItem('lastFeature', JSON.stringify(null));
}

export async function fetchObjectImageOnline(
    layerName: string,
    objectId: string,
    images: Record<string, Array<Partial<ImageResponseDto>>>,
): Promise<Record<string, Array<Partial<ImageResponseDto>>>> {
    const data: Array<ImageResponseDto> = await ObjectApi.getObjectImagesFromLayer(objectId, layerName);

    data.forEach((img: ImageResponseDto) => {
        if (img.urls) {
            const _type = img.type_img.toLowerCase();
            images[_type].push({ sid: img.sid, urls: img.urls });
        }
    });

    return images;
}

export function removeImagesDuplicate(images: Record<string, Array<unknown>>): Record<string, Array<unknown>> {
    const cleanedImages: Record<string, Array<unknown>> = {};

    Object.keys(images).forEach((key: string) => {
        const jsonImagesArray = images[key].map((images: unknown) => JSON.stringify(images));
        const imageSet = new Set(jsonImagesArray);
        cleanedImages[key] = Array.from(imageSet).map((jsonImages: string) => JSON.parse(jsonImages));
    });

    return cleanedImages;
}

export function mergeImages(
    imagesFromForm: Record<string, Array<unknown>>,
    imagesFromDump: Record<string, Array<unknown>>,
    imagesFromUnsyncForms?: Record<string, Array<unknown>>,
): Record<string, Array<unknown>> {
    Object.keys(imagesFromForm).forEach((key: string) => {
        if (Array.isArray(imagesFromDump[key])) {
            imagesFromDump[key].forEach((image: unknown) => imagesFromForm[key].push(image));
        }
        if (imagesFromUnsyncForms && Array.isArray(imagesFromUnsyncForms[key])) {
            imagesFromUnsyncForms[key].forEach((image: unknown) => imagesFromForm[key].push(image));
        }
    });

    return imagesFromForm;
}
