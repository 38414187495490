export const labels = {
    cable_capa: 'Audit - Capacité du câble',
    cable_capa_thd: 'GRACE THD - Capacité du câble',
    cable_code: 'Code du cable',
    cable_codeext: 'Code externe du cable',
    cable_sta: 'Audit - Statut du câble',
    cable_sta_thd: 'GRACE THD - Statut du câble',
    com_global: 'Commentaire global',
    date_audit: "Date de l'audit",
    etat_etq_cab: 'Etat etiquetage du cable',
    etq_cab: 'GRACE THD - Etiquette cable',
    img_cable: 'Photo Cable',
    img_environnement: 'Photo Environnement',
    img_etiquette: 'Photo Etiquette',
    statut_audit: "Statut de l'audit",
};

export const options = {
    etat_etq_cab: [
        { label: 'ABSENT', value: 'ABSENT' },
        { label: 'DEFAUT COULEUR', value: 'DEFAUT COULEUR' },
        { label: 'DEFAUT ETIQUETTE', value: 'DEFAUT ETIQUETTE' },
        { label: 'ETIQUETTE PROVISOIRE', value: 'ETIQUETTE PROVISOIRE' },
        { label: 'INCOHERENCE', value: 'INCOHERENCE' },
        { label: 'NON CONCERNE', value: 'NON CONCERNE' },
        { label: 'OK', value: 'OK' },
    ],
    statut_audit: { false: 'NON CONFORME', true: 'CONFORME' },
};
