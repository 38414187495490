export const labels = {
    adresse_post: 'Adresse postale d’implantation du site ',
    adresse_use: 'Adresse d’usage d’implantation du site',
    altitude: 'Altitude au sol exacte du site',
    code_insee: 'Code insee de la commune du lieu d’implantation du site',
    code_postal: 'Code postal de la commune du lieu d’implantation du site',
    commune: 'Commune du lieu d’implantation du site',
    departement: 'Département du lieu d’implantation du site',
    hauteur: 'Hauteur totale au-dessus du sol du support  (en mètres)',
    identifiant: 'Identifiant du lieu d’implantation du site',
    latitude: 'Latitude exacte du site (WGS-84)',
    lieudit: 'Lieudit ',
    longitude: 'Longitude exacte du site (WGS-84)',
    nature: 'Nature représentative du support',
    origine_coordonnees: 'Origine des coordonnées GPS',
    photo_site: 'Photographie du site',
};

export const options = {
    nature: [
        { label: 'Bâtiment', value: 'Bâtiment' },
        { label: "Château d'Eau", value: "Château d'Eau" },
        { label: 'Immeuble', value: 'Immeuble' },
        { label: 'Monument religieux', value: 'Monument religieux' },
        { label: 'Silo', value: 'Silo' },
        { label: 'Pylône autoportant', value: 'Pylône autoportant' },
        { label: 'Pylône haubané', value: 'Pylône haubané' },
        { label: 'Pylône tubulaire', value: 'Pylône tubulaire' },
        { label: 'Tour hertzienne', value: 'Tour hertzienne' },
        { label: 'Terrain vide', value: 'Terrain vide' },
    ],
    origine_coordonnees: [
        { label: 'Relevé par GPS', value: 'Relevé par GPS' },
        { label: 'Relevé par carte', value: 'Relevé par carte' },
        { label: 'Fourni par client', value: 'Fourni par client' },
    ],
};
