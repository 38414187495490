{
    "apiStatus": {
        "latency": "Latence",
        "title": "API status:"
    },
    "estimateUsage": {
        "savedObjects": "Total objects in database:",
        "storageUsed": "Storage used:"
    },
    "form": {
        "changesWillBeLost": "Changes will be lost",
        "closeWithoutSaving": "The form contains unsaved changes.",
        "confirmClosing": "Exit confirmation",
        "confirmClosingQuestion": "Are you sure you want to quit?",
        "confirmDraft": "Confirmation of resumption of the form",
        "draftExists": "The form has pending changes.",
        "getDraftQuestion": "Would you like to recover them?",
        "removeDraft": "Lose changes"
    },
    "formCreation": {
        "currentGeolocation": "Current geolocation:",
        "error": "Geolocation is not enabled on your device. It is not possible to create a point.",
        "title": "Create a point",
        "type": "Choose the type of point"
    },
    "geolocationModal": {
        "iosPath": "Settings app > Privacy > Locations Services > Safari Websites > Ask Next Time Or When I Share.",
        "iosPath2": "Settings app > Safari > Location > Ask",
        "openSettingsApp": "Open Settings app",
        "toActivate": "To active geolocation:",
        "toVerify": "To verify that location for Connect-Field is not blocked:"
    },
    "globals": {
        "back": "Back",
        "basemaps": "Backgrounds",
        "cancel": "Cancel",
        "close": "Close",
        "confirm": "Confirm",
        "download": "Download",
        "edit": "Edit",
        "error": "An error has occurred",
        "from": "from",
        "keep": "Keep",
        "layer": "Layer",
        "layers": "Layers",
        "loading": "Loading",
        "object": "object | objects",
        "opacity": "Opacity",
        "open": "Open",
        "project": "project",
        "save": "Save",
        "submit": "Submit",
        "synchronisation": "Syncing"
    },
    "informations": {
        "downtime": {
            "title": "A maintenance is planned the"
        },
        "update": {
            "reload": "Feel free to reload ConnectField to enjoy the latest features.",
            "title": "New version available"
        }
    },
    "inputCamera": {
        "delete": "Delete picture",
        "deleteQuestion": "Do you want to delete the photo?",
        "deleteTitle": "Delete Confirmation",
        "noIADetection": "Our AI did not detect the expected element in this photo.",
        "noIADetectionTitle": "No detection"
    },
    "login": {
        "LDAP": "LDAP connection",
        "email": "Email",
        "login": "Sign in",
        "password": "Password",
        "register": "Sign up",
        "username": "Username"
    },
    "logout": {
        "logoutIP": "Log out in progress..."
    },
    "notFound": {
        "error": "Sorry, the page you are looking for does not exist..."
    },
    "objectData": {
        "error": "Error : This object has no coordinate",
        "loadingAddress": "Loading address...",
        "objectSelection": "Object selection"
    },
    "objectList": {
        "layersList": "Layers list",
        "loadingAddress": "Loading address...",
        "loadingObjects": "Loading objects...",
        "noObject": "No object on this point",
        "objectSelection": "Objects selection"
    },
    "offlineProjectItem": {
        "aboutDelete": "You are about to delete",
        "cancelSync": "Cancel sync",
        "changesToSync": "change to sync| changes to sync",
        "definitiveAction": "This action is final.",
        "delete": "Delete confirmation",
        "deleteProject": "Delete project",
        "someDataAlert": "Some data has not been synchronized and will be deleted."
    },
    "offlineProjectSyncSteps": {
        "step0": "Data synchronization"
    },
    "offlineSelection": {
        "areaSelection": "Select area",
        "max5Zones": "Please select only 5 zones max.",
        "zoneSelection": "Select zones"
    },
    "projectDetail": {
        "backToProjects": "Back to projects?",
        "backToProjectsAction": "Back to projects",
        "backToProjectsQuestion": "Do you want to return to the list of projects?",
        "loadingProject": "Loading project...",
        "title": "Projects"
    },
    "projectDownload": {
        "title": "Downloading project",
        "cancelDownload": "Cancel download",
        "downloadName": "Name of the offline project",
        "pleaseNameIt": "Please type a name"
    },
    "projectDownloadSteps": {
        "step1": "Downloading configuration 1/7",
        "step2": "Downloading forms 2/7",
        "step3": "Downloading data 3/7",
        "step4": "Downloading basemap 4/7",
        "step5": "Downloading searchable data 5/7",
        "step6": "Downloading images 6/7",
        "step7": "Adding offline project 7/7"
    },
    "projectList": {
        "allOfflineProjects": "All offline project",
        "allProjects": "All projects",
        "choose": "Choose a project",
        "lastProject": "Last project opened",
        "loading": "Loading projects...",
        "noProjectFound": "No project found..."
    },
    "restoreDialog": {
        "question": "Do you want to return to the last actions performed?",
        "title": "Restore previous session?"
    },
    "search": {
        "aim": "Aim",
        "searchOnMap": "Search on map",
        "title": "Search"
    },
    "settings": {
        "debugIP": "Transferring debug data...",
        "displayRuler": "Show measurement tool:",
        "displayZoom": "Show zoom buttons:",
        "leaveConfirmation": "Are you sure you want to exit?",
        "logout": "Logout",
        "sendDebug": "Send debug data",
        "title": "Settings"
    },
    "softwareDialog": {
        "choose": "Choose the app",
        "title": "Open navigation on"
    },
    "version": {
        "build": "build:",
        "version": "Version:"
    }
}
