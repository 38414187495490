import type { OptionsInterface, SelectInterface } from '@connect-field/client/components/forms/form.types';

export const labels = {
    arrimage_cables: 'Arrimage des câbles',
    autres_observations: 'Observations',
    bouchon_connecteur: 'Bouchons de connecteurs',
    commentaire_arrimage_cables: 'Commentaire',
    commentaire_autres_observations: 'Commentaire',
    commentaire_bouchon_connecteur: 'Commentaire',
    commentaire_etat_fibre_soudure: 'Commentaire',
    commentaire_etiquetage_tiroir_optique: 'Commentaire',
    commentaire_etiquette_cable: 'Commentaire',
    commentaire_etiquette_pmz: 'Commentaire',
    commentaire_etiquettes_definitives_des_ca2: 'Commentaire',
    commentaire_fermeture_pmz: 'Commentaire',
    commentaire_fixation_pmz: 'Commentaire',
    commentaire_lovage_cassette: 'Commentaire',
    commentaire_mise_terre: 'Commentaire',
    commentaire_passage_cable: 'Commentaire',
    commentaire_position_fixation_tiroir: 'Commentaire',
    commentaire_protection_tube: 'Commentaire',
    donnees_metier: 'Affichage des données métier',
    etat_fibre_soudure: 'Etat fibres / soudures',
    etiquetage_tiroir_optique: 'Etiquetage des tiroirs optiques',
    etiquette_cable: 'Etiquettes définitives des câbles',
    etiquette_pmz: 'Etiquetage / identification du PMZ',
    etiquettes_definitives_des_ca2: 'Etiquettes définitives des câbles',
    fermeture_pmz: 'Fermeture du PMZ sans blocage et état de la serrure',
    fixation_pmz: "Fixation du PMZ (armoire au sol, portes à l'armoire)",
    img_arrimage_cables: 'Photos',
    img_autres_observations: 'Photos',
    img_bouchon_connecteur: 'Photos',
    img_etat_fibre_soudure: 'Photos',
    img_etiquetage_tiroir_optique: 'Photos',
    img_etiquette_cable: 'Photos',
    img_etiquette_pmz: 'Photos',
    img_etiquettes_definitives_des_ca2: 'Photos',
    img_fermeture_pmz: 'Photos',
    img_fixation_pmz: 'Photos',
    img_lovage_cassette: 'Photos',
    img_mise_terre: 'Photos',
    img_passage_cable: 'Photos',
    img_position_fixation_tiroir: 'Photos',
    img_protection_tube: 'Photos',
    lovage_cassette: 'Lovage dans les cassettes et rangement des soudures',
    mise_terre: 'Mise à la terre',
    passage_cable: 'Passage des câbles dans les presse-étoupes',
    position_fixation_tiroir: 'Position et fixation des tiroirs optiques',
    protection_tube: 'Protection des tubes dénudés avec bloolite sur toute la longueur',
    st_abddate: "Date d'abandon de l'objet",
    st_abdsrc: "Cause de l'abandon de l'objet",
    st_ad_code: 'Identifiant unique contenu dans la table ADRESSE',
    st_avct: "Attribut synthétisant l'avancement",
    st_code: 'Code du site',
    st_codeext: 'Code chez un tiers',
    st_comment: 'Commentaire',
    st_creadat: "Date de création de l'objet en base",
    st_dateins: "Date d'installation",
    st_datemes: 'Date de mise en service',
    st_etat: 'Etat du site',
    st_gest: 'Identifiant du gestionnaire',
    st_majdate: "Date de la mise à jour de l'objet en base",
    st_majsrc: 'Source utilisée pour la mise à jour',
    st_nblines: 'Nombre de lignes du site',
    st_nd_code: 'Identifiant unique contenu dans la table Noeud',
    st_nom: 'Nom du site',
    st_prop: 'Identifiant du propriétaire',
    st_proptyp: 'Type de propriété',
    st_statut: "Phase d'avancement",
    st_typelog: 'Type logique du site',
    st_typephy: 'Type physique du site',
    st_user: 'Utilisateur du site',
    statut: 'Statut audit',
};

export const options: Record<string, OptionsInterface | SelectInterface> = {
    arrimage_cables: [
        { label: 'OK', value: 'OK' },
        { label: 'NOK (min)', value: 'NOK_min' },
        { label: 'NOK (MAJ)', value: 'NOK_maj' },
    ],
    autres_observations: [
        { label: 'OUI', value: 'OUI' },
        { label: 'NON', value: 'NON' },
    ],
    bouchon_connecteur: [
        { label: 'OK', value: 'OK' },
        { label: 'NOK (min)', value: 'NOK_min' },
        { label: 'NOK (MAJ)', value: 'NOK_maj' },
    ],
    donnees_metier: [
        { label: 'OUI', value: 'OUI' },
        { label: 'NON', value: 'NON' },
    ],
    etat_fibre_soudure: [
        { label: 'OK', value: 'OK' },
        { label: 'NOK (min)', value: 'NOK_min' },
        { label: 'NOK (MAJ)', value: 'NOK_maj' },
    ],
    etiquetage_tiroir_optique: [
        { label: 'OK', value: 'OK' },
        { label: 'NOK (min)', value: 'NOK_min' },
        { label: 'NOK (MAJ)', value: 'NOK_maj' },
    ],
    etiquette_cable: [
        { label: 'OK', value: 'OK' },
        { label: 'NOK (min)', value: 'NOK_min' },
        { label: 'NOK (MAJ)', value: 'NOK_maj' },
    ],
    etiquette_pmz: [
        { label: 'OK', value: 'OK' },
        { label: 'NOK (min)', value: 'NOK_min' },
        { label: 'NOK (MAJ)', value: 'NOK_maj' },
    ],
    etiquettes_definitives_des_ca2: [
        { label: 'OK', value: 'OK' },
        { label: 'NOK (min)', value: 'NOK_min' },
        { label: 'NOK (MAJ)', value: 'NOK_maj' },
    ],
    fermeture_pmz: [
        { label: 'OK', value: 'OK' },
        { label: 'NOK (min)', value: 'NOK_min' },
        { label: 'NOK (MAJ)', value: 'NOK_maj' },
    ],
    fixation_pmz: [
        { label: 'OK', value: 'OK' },
        { label: 'NOK (min)', value: 'NOK_min' },
        { label: 'NOK (MAJ)', value: 'NOK_maj' },
    ],
    lovage_cassette: [
        { label: 'OK', value: 'OK' },
        { label: 'NOK (min)', value: 'NOK_min' },
        { label: 'NOK (MAJ)', value: 'NOK_maj' },
    ],
    mise_terre: [
        { label: 'OK', value: 'OK' },
        { label: 'NOK (min)', value: 'NOK_min' },
        { label: 'NOK (MAJ)', value: 'NOK_maj' },
    ],
    passage_cable: [
        { label: 'OK', value: 'OK' },
        { label: 'NOK (min)', value: 'NOK_min' },
        { label: 'NOK (MAJ)', value: 'NOK_maj' },
    ],
    position_fixation_tiroir: [
        { label: 'OK', value: 'OK' },
        { label: 'NOK (min)', value: 'NOK_min' },
        { label: 'NOK (MAJ)', value: 'NOK_maj' },
    ],
    protection_tube: [
        { label: 'OK', value: 'OK' },
        { label: 'NOK (min)', value: 'NOK_min' },
        { label: 'NOK (MAJ)', value: 'NOK_maj' },
    ],
    st_etat: [
        { label: 'A CHANGER', value: 'HS' },
        { label: 'MAUVAIS ETAT', value: 'ME' },
        { label: 'BON ETAT', value: 'OK' },
        { label: 'NON CONCERNE', value: 'NC' },
    ],
    st_proptyp: [
        { label: 'CONSTRUCTION', value: 'CST' },
        { label: 'RACHAT', value: 'RAC' },
        { label: 'CESSION', value: 'CES' },
        { label: 'IRU', value: 'IRU' },
        { label: 'LOCATION', value: 'LOC' },
        { label: 'OCCUPATION', value: 'OCC' },
    ],
    st_statut: [
        { label: 'ETUDE PRELIMINAIRE', value: 'PRE' },
        { label: 'ETUDE DE DIAGNOSTIC', value: 'DIA' },
        { label: 'AVANT-PROJET', value: 'AVP' },
        { label: 'PROJET', value: 'PRO' },
        { label: 'PASSATION DES MARCHES DE TRAVAUX', value: 'ACT' },
        { label: 'ETUDE D EXECUTION', value: 'EXE' },
        { label: 'TRAVAUX', value: 'TVX' },
        { label: 'RECOLEMENT', value: 'REC' },
        { label: 'MAINTIENT EN CONDITIONS OPERATIONNELLES', value: 'MCO' },
    ],
    statut: [
        { label: 'Audit à réaliser', value: 'NON EFFECTUE' },
        { label: 'Audit partiellement réalisé', value: 'PARTIEL' },
        { label: 'Audit réalisé', value: 'TERMINE' },
        { label: 'Non concerné par un audit', value: 'NON CONCERNE' },
    ],
};
