import useAuthStore from '@connect-field/client/stores/auth';
import useBannerStore from '@connect-field/client/stores/banner';
import useCoordinateStore from '@connect-field/client/stores/coordinate';
import useFormStore from '@connect-field/client/stores/form';
import useLayersStore from '@connect-field/client/stores/layers';
import useMapStore from '@connect-field/client/stores/map';
import useMenuStore from '@connect-field/client/stores/menu';
import useNavigationStore from '@connect-field/client/stores/navigation';
import useObjectStore from '@connect-field/client/stores/object';
import usePanelStore from '@connect-field/client/stores/panel';
import useProjectsStore from '@connect-field/client/stores/projects';
import useUserStore from '@connect-field/client/stores/user';

export function resetAllStores(): void {
    useAuthStore().$reset();
    useBannerStore().$reset();
    useCoordinateStore().$reset();
    useFormStore().$reset();
    useLayersStore().$reset();
    useMapStore().$reset();
    useMenuStore().$reset();
    useNavigationStore().$reset();
    useObjectStore().$reset();
    usePanelStore().$reset();
    useProjectsStore().$reset();
    useUserStore().$reset();
}
