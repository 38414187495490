export const labels = {
    abaque: 'Photo abbaque',
    adresse: 'adresse',
    al_acces: 'Accès site renseigné (GTR, site, clés, badges, accès routier, etc…) ',
    al_adresse: 'Adresse SRO',
    al_chainage: 'Présence chainage équipement',
    al_date_construction: 'Date de construction renseignée',
    al_date_maintenance: 'Date "Recette maintenance" renseignée par QR',
    al_obs: 'Observations Altiline',
    al_position_gps: 'Positionnement GPS du SRO ',
    as_activ_coupleur: 'Activation coupleur réalisable',
    as_branchement_jarretiere: 'Branchement Jarretière au NRO par QR',
    as_colonne_connect: 'Colonne connectée (jarretière)',
    as_ligne_connect: 'Ligne connectée (jarretière)',
    as_nom_jarretiere: 'Nommage Jarretière au NRO par QR',
    as_nom_ttr: 'Nom TTR NRO connecté',
    as_obs: 'Observations Activation SRO',
    as_puissance_cpl: 'Puissance sortie coupleur validée (entre -10 et -22dbm) ',
    baie_fermee: 'Photo baie fermée',
    baie_ouverte: 'Photo baie ouverte',
    baiea1: 'Photo baie A1',
    baiea2: 'Photo baie A2',
    branchement_jarretiere: 'Photo branchement jarretière',
    code: 'Code',
    cpl: 'Photo CPL',
    date_audit: 'Date audit',
    environnement: 'Photo environnement',
    etiquette_cable: 'Photo étiquette câble',
    etiquette_ctr_nro: 'Photo étiquette CTR NRO',
    etiquette_jarretiere: 'Photo étiquette jarretière',
    ns_obs: 'Observations NETGEO SRO',
    ns_reserve_rop: 'Réservation ROP par QR',
    nsbc_capacite: 'Capacité tiroir Baie CPL',
    nsbc_etat: 'État en service Baie CPL',
    nsbc_gestionnaire: 'Gestionnaire  Baie CPL',
    nsbc_nom: 'Nom / Code Baie CPL',
    nsbc_proprietaire: 'Propriétaire Baie CPL',
    nsbc_type: 'Type Baie CPL',
    nsbt_capacite: 'Capacité tiroirs Baie TTR',
    nsbt_code: 'Code Baie TTR',
    nsbt_etat: 'État en service Baie TTR',
    nsbt_gestionnaire: 'Gestionnaire  Baie TTR',
    nsbt_nom: 'Nom Baie TTR',
    nsbt_proprietaire: 'Propriétaire Baie TTR',
    nsbt_type: 'Type Baie TTR',
    nss_adresse: 'Adresse Site SRO',
    nss_code: 'Code Site SRO',
    nss_etat: 'État en service Site SRO',
    nss_gestionnaire: 'Gestionnaire Site SRO',
    nss_nom: 'Nom Site SRO',
    nss_proprietaire: 'Propriétaire Site SRO',
    nss_typefonc: 'Type fonctionnel  Site SRO',
    nss_typestruc: 'Type structurel  Site SRO',
    nstt_cable_racco: 'Câbles raccordés aux tiroirs TTR',
    nstt_capacite: 'Capacité tiroirs TTR',
    nstt_code: 'Code TTR',
    nstt_etat: 'État en service TTR',
    nstt_gestionnaire: 'Gestionnaire  TTR',
    nstt_nom: 'Nom TTR',
    nstt_proprietaire: 'Propriétaire TTR',
    nstt_terrain_sig: 'Correspondance Câblage TTR SRO Terrain / SIG ',
    nstt_typefonc: 'Type fonctionnel TTR',
    nstt_typestruc: 'Type structurel TTR',
    nt_cable: 'Câbles raccordés aux tiroirs TTR NRO',
    nt_capacite_tiroir: 'Capacité tiroirs TTR NRO',
    nt_code: 'Code TTR NRO',
    nt_etat: 'État en service TTR NRO',
    nt_gestionnaire: 'Gestionnaire TTR NRO',
    nt_nom: 'Nom TTR NRO',
    nt_obs: 'Observation NETGEO NRO',
    nt_proprietaire: 'Propriétaire TTR NRO',
    nt_terrain_sig: 'Correspondance Câblage TTR NRO Terrain / SIG ',
    nt_typefonc: 'Type fonctionnel TTR NRO',
    nt_typestruc: 'Type structurel TTR NRO',
    plateaux_ttr: 'Photo plateaux TTR',
    plateaux_ttr_nro: 'Photo plateaux TTR NRO',
    puissance_metre: 'Photo puissance mètre',
    ref_nro: 'Référence du NRO',
    ref_sro: 'Référence SRO',
    sn_capuchon_pe: 'Présence de capuchons sur TTR NRO',
    sn_connecteur: 'Connecteurs TTR NRO Type SC-APC ',
    sn_etat: 'État des tiroirs TTR NRO',
    sn_eti_pe_ctr: 'Etiquette Câbles CTR NRO Présence / État / Nommage',
    sn_eti_pe_ttr: 'Étiquette Tiroirs TTR NRO Présence / État / Nommage',
    sn_obs: 'Observations Site NRO',
    sn_obturation: 'Presse-étoupes NRO Présence / État',
    sn_position: 'Positionnement des tiroirs TTR NRO',
    sn_ri: 'Règle ingénierie câblage TTR NRO',
    sn_terrain_sig: 'Correspondance Câblage TTR NRO Terrain / SIG ',
    ss_abaque: 'Abaque Présence / État',
    ss_acces: 'Accès 24/24h 7/7j ',
    ss_clef: 'Ouverture via badge ou clé',
    ss_environnement: 'Environnement',
    ss_etat_general: 'Etat général / Propreté',
    ss_eti_cablectr_pe: 'Etiquette Câbles CTR Présence / État / Nommage',
    ss_fermeture: 'Fermeture  / ouverture porte',
    ss_obs: 'Observations SITE SRO',
    ss_obturationfourreau: 'Presse-étoupes Présence / État',
    ss_plaque_pe: 'Plaque ID PM Extérieur Présence / État /Nommage',
    ss_plaquebaie_pe: 'Plaque ID N°Baie Présence / État / Nommage',
    ss_plot_pe: 'Plot Présence / État',
    ss_site: 'Accès site',
    ss_type: 'Type de site',
    ss_type_clef: 'Type de clé Triangle',
    statut: 'Statut audit Terrain',
    statut_audit_altiline: 'Statut audit Altiline',
    statut_audit_netgeo: 'Statut audit NETGEO',
    tc_capuchon_pe: 'Présence de capuchons sur CPL',
    tc_connecteur: 'Connecteurs CPL Type SC-APC ',
    tc_etat: 'État du tiroir CPL',
    tc_eti_jarretiere_pe: 'Étiquette jarretière CPL Présence / État / Nommage',
    tc_eti_pe: 'Étiquette Tiroir CPL Présence / État / Nommage',
    tc_jarretiere_pe: 'Jarretière CPL Présence / État',
    tc_obs: 'Observations CPL',
    tc_plaque_pe: 'Plaque réserve CPL Présence / Position',
    tc_position: 'Positionnement tiroirs CPL',
    tt_capuchon_pe: 'Présence de capuchons sur TTR',
    tt_connecteur: 'Connecteurs TTR Type SC-APC ',
    tt_etat: 'État des tiroirs TTR',
    tt_eti_pe: 'Étiquette Tiroirs TTR Présence / État / Nommage',
    tt_mesure: 'Mesure réflecto CTR',
    tt_obs: 'Observations TTR',
    tt_pos: 'Positionnement des tiroirs TTR',
    tt_ri: 'Règle ingénierie câblage TTR ',
    tt_terrain_sig: 'Correspondance Câblage TTR Terrain / SIG ',
    ttr: 'Photo TTR',
    ttr_nro: 'Photo TTR NRO',
};

// TODO : Duplication avec NR.js
export const options = {
    al_acces: { false: 'NON CONFORME', true: 'CONFORME' },
    al_adresse: { false: 'NON CONFORME', true: 'CONFORME' },
    al_chainage: { false: 'NON CONFORME', true: 'CONFORME' },
    al_date_construction: { false: 'NON CONFORME', true: 'CONFORME' },
    al_date_maintenance: { false: 'NON CONFORME', true: 'CONFORME' },
    al_position_gps: { false: 'NON CONFORME', true: 'CONFORME' },
    as_activ_coupleur: { false: 'NON CONFORME', true: 'CONFORME' },
    as_branchement_jarretiere: { false: 'NON CONFORME', true: 'CONFORME' },
    as_nom_jarretiere: { false: 'NON CONFORME', true: 'CONFORME' },
    as_puissance_cpl: { false: 'NON CONFORME', true: 'CONFORME' },
    ns_reserve_rop: { false: 'NON CONFORME', true: 'CONFORME' },
    nsbc_capacite: { false: 'NON CONFORME', true: 'CONFORME' },
    nsbc_etat: { false: 'NON CONFORME', true: 'CONFORME' },
    nsbc_gestionnaire: { false: 'NON CONFORME', true: 'CONFORME' },
    nsbc_nom: { false: 'NON CONFORME', true: 'CONFORME' },
    nsbc_proprietaire: { false: 'NON CONFORME', true: 'CONFORME' },
    nsbc_type: { false: 'NON CONFORME', true: 'CONFORME' },
    nsbt_capacite: { false: 'NON CONFORME', true: 'CONFORME' },
    nsbt_code: { false: 'NON CONFORME', true: 'CONFORME' },
    nsbt_etat: { false: 'NON CONFORME', true: 'CONFORME' },
    nsbt_gestionnaire: { false: 'NON CONFORME', true: 'CONFORME' },
    nsbt_nom: { false: 'NON CONFORME', true: 'CONFORME' },
    nsbt_proprietaire: { false: 'NON CONFORME', true: 'CONFORME' },
    nsbt_type: { false: 'NON CONFORME', true: 'CONFORME' },
    nss_adresse: { false: 'NON CONFORME', true: 'CONFORME' },
    nss_code: { false: 'NON CONFORME', true: 'CONFORME' },
    nss_etat: { false: 'NON CONFORME', true: 'CONFORME' },
    nss_gestionnaire: { false: 'NON CONFORME', true: 'CONFORME' },
    nss_nom: { false: 'NON CONFORME', true: 'CONFORME' },
    nss_proprietaire: { false: 'NON CONFORME', true: 'CONFORME' },
    nss_typefonc: { false: 'NON CONFORME', true: 'CONFORME' },
    nss_typestruc: { false: 'NON CONFORME', true: 'CONFORME' },
    nstt_cable_racco: { false: 'NON CONFORME', true: 'CONFORME' },
    nstt_capacite: { false: 'NON CONFORME', true: 'CONFORME' },
    nstt_code: { false: 'NON CONFORME', true: 'CONFORME' },
    nstt_etat: { false: 'NON CONFORME', true: 'CONFORME' },
    nstt_gestionnaire: { false: 'NON CONFORME', true: 'CONFORME' },
    nstt_nom: { false: 'NON CONFORME', true: 'CONFORME' },
    nstt_proprietaire: { false: 'NON CONFORME', true: 'CONFORME' },
    nstt_terrain_sig: { false: 'NON CONFORME', true: 'CONFORME' },
    nstt_typefonc: { false: 'NON CONFORME', true: 'CONFORME' },
    nstt_typestruc: { false: 'NON CONFORME', true: 'CONFORME' },
    nt_cable: { false: 'NON CONFORME', true: 'CONFORME' },
    nt_capacite_tiroir: { false: 'NON CONFORME', true: 'CONFORME' },
    nt_code: { false: 'NON CONFORME', true: 'CONFORME' },
    nt_etat: { false: 'NON CONFORME', true: 'CONFORME' },
    nt_gestionnaire: { false: 'NON CONFORME', true: 'CONFORME' },
    nt_nom: { false: 'NON CONFORME', true: 'CONFORME' },
    nt_proprietaire: { false: 'NON CONFORME', true: 'CONFORME' },
    nt_terrain_sig: { false: 'NON CONFORME', true: 'CONFORME' },
    nt_typefonc: { false: 'NON CONFORME', true: 'CONFORME' },
    nt_typestruc: { false: 'NON CONFORME', true: 'CONFORME' },
    sn_capuchon_pe: { false: 'NON CONFORME', true: 'CONFORME' },
    sn_connecteur: { false: 'NON CONFORME', true: 'CONFORME' },
    sn_etat: { false: 'NON CONFORME', true: 'CONFORME' },
    sn_eti_pe_ctr: { false: 'NON CONFORME', true: 'CONFORME' },
    sn_eti_pe_ttr: { false: 'NON CONFORME', true: 'CONFORME' },
    sn_obturation: { false: 'NON CONFORME', true: 'CONFORME' },
    sn_position: { false: 'NON CONFORME', true: 'CONFORME' },
    sn_ri: { false: 'NON CONFORME', true: 'CONFORME' },
    sn_terrain_sig: { false: 'NON CONFORME', true: 'CONFORME' },
    ss_abaque: { false: 'NON CONFORME', true: 'CONFORME' },
    ss_acces: { false: 'NON CONFORME', true: 'CONFORME' },
    ss_clef: { false: 'NON CONFORME', true: 'CONFORME' },
    ss_environnement: [
        { label: 'PM OUTDOOR', value: 'PM OUTDOOR' },
        { label: 'PM INDOOR', value: 'PM INDOOR' },
    ],
    ss_etat_general: { false: 'NON CONFORME', true: 'CONFORME' },
    ss_eti_cablectr_pe: { false: 'NON CONFORME', true: 'CONFORME' },
    ss_fermeture: { false: 'NON CONFORME', true: 'CONFORME' },
    ss_obturationfourreau: { false: 'NON CONFORME', true: 'CONFORME' },
    ss_plaque_pe: { false: 'NON CONFORME', true: 'CONFORME' },
    ss_plaquebaie_pe: { false: 'NON CONFORME', true: 'CONFORME' },
    ss_plot_pe: { false: 'NON CONFORME', true: 'CONFORME' },
    ss_site: [
        { label: 'PUBLIC', value: 'PUBLIC' },
        { label: 'PRIVE', value: 'PRIVE' },
    ],
    ss_type: [
        { label: 'SHELTER', value: 'SHELTER' },
        { label: 'BAIE', value: 'BAIE' },
    ],
    ss_type_clef: { false: 'NON CONFORME', true: 'CONFORME' },
    statut: [
        { label: 'NON REALISE', value: 'NON REALISE' },
        { label: 'PARTIELLEMENT', value: 'PARTIELLEMENT' },
        { label: 'TERMINE', value: 'TERMINE' },
    ],
    statut_audit_altiline: [
        { label: 'NON REALISE', value: 'NON REALISE' },
        { label: 'PARTIELLEMENT', value: 'PARTIELLEMENT' },
        { label: 'TERMINE', value: 'TERMINE' },
    ],
    statut_audit_netgeo: [
        { label: 'NON REALISE', value: 'NON REALISE' },
        { label: 'PARTIELLEMENT', value: 'PARTIELLEMENT' },
        { label: 'TERMINE', value: 'TERMINE' },
    ],
    tc_capuchon_pe: { false: 'NON CONFORME', true: 'CONFORME' },
    tc_connecteur: { false: 'NON CONFORME', true: 'CONFORME' },
    tc_etat: { false: 'NON CONFORME', true: 'CONFORME' },
    tc_eti_jarretiere_pe: { false: 'NON CONFORME', true: 'CONFORME' },
    tc_eti_pe: { false: 'NON CONFORME', true: 'CONFORME' },
    tc_jarretiere_pe: { false: 'NON CONFORME', true: 'CONFORME' },
    tc_plaque_pe: { false: 'NON CONFORME', true: 'CONFORME' },
    tc_position: { false: 'NON CONFORME', true: 'CONFORME' },
    tt_capuchon_pe: { false: 'NON CONFORME', true: 'CONFORME' },
    tt_connecteur: { false: 'NON CONFORME', true: 'CONFORME' },
    tt_etat: { false: 'NON CONFORME', true: 'CONFORME' },
    tt_eti_pe: { false: 'NON CONFORME', true: 'CONFORME' },
    tt_mesure: { false: 'NON CONFORME', true: 'CONFORME' },
    tt_pos: { false: 'NON CONFORME', true: 'CONFORME' },
    tt_ri: { false: 'NON CONFORME', true: 'CONFORME' },
    tt_terrain_sig: { false: 'NON CONFORME', true: 'CONFORME' },
};
