<template>
    <FormWrapper
        v-if="!loading"
        ref="customFormRef"
        :images-model="images"
        :form-model="form"
        :mandatory-fields="mandatoryFields"
        :changes-model="changes"
        :layer-name="layerName"
        :object-id="objectId"
        :project-id="projectId"
        :latitude="latitude"
        :longitude="longitude"
        @update:images-model="
            (e) => {
                images = e;
            }
        "
        @update:value-missing="
            (e) => {
                valueMissing = e;
            }
        "
        @update:form-model="
            (e) => {
                form = e;
            }
        "
        @update:changes-model="
            (e) => {
                changes = e;
            }
        "
    >
        <template #menu_tabs>
            <el-row class="scroll-menu">
                <el-col :span="12">
                    <div
                        @click="selectTab('Pose Armoire')"
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'Pose Armoire' }"
                    >
                        Pose Armoire
                    </div>
                </el-col>

                <el-col :span="12">
                    <div
                        @click="selectTab('Cablage Armoire')"
                        class="tabs"
                        :class="{
                            selectedTab: selectedTab === 'Cablage Armoire',
                        }"
                    >
                        Cablage Armoire
                    </div>
                </el-col>
            </el-row>
        </template>
        <template #main_form>
            <el-row>
                <el-col
                    :span="24"
                    v-show="selectedTab === 'Pose Armoire'"
                >
                    <CustomInput
                        :label="labels.ref_sro"
                        :value="form.ref_sro"
                        :disabled="true"
                    />
                    <CustomDatePicker
                        :label="labels.date_audit"
                        :value="form.date_audit"
                        @update:value="
                            (newValue) => saveData('date_audit', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.etat_general"
                        :options="options.etat_general"
                        :value="form.etat_general"
                        size="small"
                        @update:value="
                            (newValue) => saveData('etat_general', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_armoire_environnement"
                        :images="images.img_armoire_environnement"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_armoire_environnement', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.taille"
                        :value="form.taille"
                        :maxlength="254"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.ral"
                        :value="form.ral"
                        :disabled="true"
                    />
                    <CustomRadioButton
                        :label="labels.armoire_ral"
                        :options="options.armoire_ral"
                        :value="form.armoire_ral"
                        size="small"
                        @update:value="
                            (newValue) => saveData('armoire_ral', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.respect_pose"
                        :options="options.respect_pose"
                        :value="form.respect_pose"
                        size="small"
                        @update:value="
                            (newValue) => saveData('respect_pose', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.pose_fourreau"
                        :options="options.pose_fourreau"
                        :value="form.pose_fourreau"
                        size="small"
                        @update:value="
                            (newValue) => saveData('pose_fourreau', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_armoire_ouverte"
                        :images="images.img_armoire_ouverte"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_armoire_ouverte', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.plynox"
                        :options="options.plynox"
                        :value="form.plynox"
                        size="small"
                        @update:value="
                            (newValue) => saveData('plynox', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.refection"
                        :options="options.refection"
                        :value="form.refection"
                        size="small"
                        @update:value="
                            (newValue) => saveData('refection', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_refection"
                        :images="images.img_refection"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('img_refection', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.dalle"
                        :options="options.dalle"
                        :value="form.dalle"
                        size="small"
                        @update:value="
                            (newValue) => saveData('dalle', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.criticite"
                        :options="options.criticite"
                        :value="form.criticite"
                        @update:value="
                            (newValue) => saveData('criticite', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_comment_gb()"
                        :label="labels.comment_gb"
                        :value="form.comment_gb"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('comment_gb', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.statut_audit + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.statut_audit"
                        :value="form.statut_audit"
                        @update:value="
                            (newValue) => saveData('statut_audit', newValue)
                        "
                    />
                </el-col>
                <el-col
                    :span="24"
                    v-show="selectedTab === 'Cablage Armoire'"
                >
                    <CustomRadioButton
                        :label="labels.nb_pos_tiroir"
                        :options="options.nb_pos_tiroir"
                        :value="form.nb_pos_tiroir"
                        size="small"
                        @update:value="
                            (newValue) => saveData('nb_pos_tiroir', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.presence_coupleur"
                        :options="options.presence_coupleur"
                        :value="form.presence_coupleur"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('presence_coupleur', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.etiquetage"
                        :options="options.etiquetage"
                        :value="form.etiquetage"
                        size="small"
                        @update:value="
                            (newValue) => saveData('etiquetage', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.comment_etiquetage"
                        :value="form.comment_etiquetage"
                        @update:value="
                            (newValue) =>
                                saveData('comment_etiquetage', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.etancheite"
                        :options="options.etancheite"
                        :value="form.etancheite"
                        size="small"
                        @update:value="
                            (newValue) => saveData('etancheite', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_armoire_autres"
                        :images="images.img_armoire_autres"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_armoire_autres', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.criticite_cablage"
                        :options="options.criticite_cablage"
                        :value="form.criticite_cablage"
                        @update:value="
                            (newValue) =>
                                saveData('criticite_cablage', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_comment_cablage()"
                        :label="labels.comment_cablage"
                        :value="form.comment_cablage"
                        @update:value="
                            (newValue) => saveData('comment_cablage', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.statut_audit_cablage + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.statut_audit_cablage"
                        :value="form.statut_audit_cablage"
                        @update:value="
                            (newValue) =>
                                saveData('statut_audit_cablage', newValue)
                        "
                    />
                </el-col>
            </el-row>
        </template>
    </FormWrapper>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import {
    labels,
    options,
} from '@connect-field/client/components/forms/AIC/GC/t_tvx_armoire.data';
import type { FormDataInterface } from '@connect-field/client/components/forms/form.types';
import FormTemplate from '@connect-field/client/components/forms/FormTemplate.vue';

interface DataInterface extends FormDataInterface {}

export default defineComponent({
    extends: FormTemplate,
    data(): DataInterface {
        return {
            changes: false,
            form: {},
            images: {
                img_armoire_environnement: [],
                img_armoire_ouverte: [],
                img_refection: [],
                img_armoire_autres: [],
            },
            labels,
            loading: true,
            mandatoryFields: ['statut_audit', 'statut_audit_cablage'],
            options,
            selectedTab: 'Pose Armoire',
            valueMissing: false,
        };
    },
    methods: {
        condition_comment_gb(): boolean {
            const { criticite } = this.form;
            return !!criticite;
        },
        condition_comment_cablage(): boolean {
            const { criticite_cablage } = this.form;
            return !!criticite_cablage;
        },
    },
});
</script>
