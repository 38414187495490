import { AxiosError } from 'axios';

import ApiError, { type ApiErrorInterface } from '@connect-field/client/services/apiError.service';
import type { GeoJSONGeoJSONPropertiesDto, SearchGeoJSON, Zones } from '@connect-field/client/sdk/generated';
import { convertBufferToJSON } from '@connect-field/client/utilities/tools';
import HTTP from '@connect-field/client/utilities/http';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function updateLayerData(layerId: number | string, form: any): Promise<boolean> {
    const response = await HTTP.client().LayerApi.layerControllerUpdateData({
        layerId: layerId.toString(),
        updateDataBodyDto: {
            data: [form],
        },
    });

    if (response.status !== 200) {
        throw new Error(`putLayerGeojson: ${response.status}: ${JSON.stringify(response?.data?.error)}`);
    }

    return true;
}

export async function searchLayerObjects(layerId: number, q: string): Promise<SearchGeoJSON | undefined> {
    try {
        const response = await HTTP.client().LayerApi.layerControllerSearchObjects({
            layerId,
            q,
        });

        return response.data;
    } catch (e) {
        return undefined;
    }
}

export async function extractGeoJSON(
    layerId: number,
    projectId: number,
    bbox: Array<number>,
    zoneIds?: (number | string)[],
): Promise<GeoJSONGeoJSONPropertiesDto> {
    const response = await HTTP.client().LayerApi.layerControllerExtractGeoJSON({
        extractGeoJSONBodyDto: { bbox, projectId, zoneIds },
        layerId,
    });

    if (!response.data) {
        throw new Error('No geoJSON with theses parameters');
    }

    return response.data;
}

export async function getGeoJSON(
    layerId: number,
    bbox?: Array<number>,
    projection?: string,
): Promise<GeoJSONGeoJSONPropertiesDto> {
    const response = await HTTP.client().LayerApi.layerControllerGetGeoJSON({
        bbox,
        layerId,
        projection,
    });

    if (!response.data) {
        throw new Error('No geoJSON with theses parameters');
    }

    return response.data;
}

export async function extractMBTiles(
    layerId: number,
    projectId: number,
    bbox: Array<number>,
    zoneIds?: Array<number | string>,
): Promise<Uint8Array> {
    try {
        let zones: Zones | undefined;
        if (zoneIds) {
            zones = {
                ids: zoneIds,
                projectId: projectId,
            };
        }

        const response = await HTTP.client().LayerApi.layerControllerGetMBTiles(
            {
                getMBTilesQueryDto: {
                    bbox,
                    zones,
                },
                layerId,
            },
            { responseType: 'arraybuffer' },
        );

        if (!response.data) {
            throw new Error('[getProjectExtract] extract data is empty');
        }

        return new Uint8Array(response.data as unknown as ArrayBuffer);
    } catch (error: unknown) {
        if (error instanceof AxiosError) {
            if (error?.response?.data instanceof ArrayBuffer) {
                const serializedError = convertBufferToJSON<ApiErrorInterface>(error.response.data);
                throw new ApiError(serializedError);
            }
        }

        throw error;
    }
}
