<template>
    <div>
        <table
            v-for="(cassette, cassetteId) in cassettesData"
            :key="cassetteId"
        >
            <tr>
                <th>
                    <FontAwesomeIcon
                        size="1x"
                        icon="left-from-line"
                    />
                    Cb
                </th>
                <th>
                    <FontAwesomeIcon
                        size="1x"
                        icon="left-from-line"
                    />
                    Tb
                </th>
                <th>
                    <FontAwesomeIcon
                        size="1x"
                        icon="left-from-line"
                    />
                    Fo
                </th>
                <th>Co</th>
                <th>
                    <FontAwesomeIcon
                        size="1x"
                        icon="right-from-line"
                    />
                    Fo
                </th>
                <th>
                    <FontAwesomeIcon
                        size="1x"
                        icon="right-from-line"
                    />
                    Tb
                </th>
                <th>
                    <FontAwesomeIcon
                        size="1x"
                        icon="right-from-line"
                    />
                    Cb
                </th>
            </tr>
            <tr
                v-for="(cable, index) in cassette"
                :key="index"
                class="cable"
            >
                <td>{{ cable.cable_entrant.slice(-4) }}</td>
                <td>
                    <TubeEntrant
                        :position="cable.numero_tube_entrant"
                        :color="cable.couleur_tube_entrant"
                    />
                </td>
                <td>
                    <FibreEntrante
                        :position="cable.numero_fibre_entrante"
                        :color="cable.couleur_fibre_entrante"
                    />
                </td>
                <td class="text-center">
                    {{
                        cable.connectorisation_entrante ||
                        cable.connectorisation_sortante
                    }}
                </td>
                <td>
                    <FibreSortante
                        v-if="cable.numero_fibre_sortante"
                        :position="cable.numero_fibre_sortante"
                        :color="cable.couleur_fibre_sortante"
                    />
                </td>
                <td>
                    <TubeSortant
                        v-if="cable.numero_tube_sortant"
                        :position="cable.numero_tube_sortant"
                        :color="cable.couleur_tube_sortant"
                    />
                </td>
                <td>{{ cable.cable_sortant?.slice(-4) }}</td>
            </tr>
        </table>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'pinia';

import FibreEntrante from '@connect-field/client/components/forms/PlanBoite/FibreEntrante.vue';
import FibreSortante from '@connect-field/client/components/forms/PlanBoite/FibreSortante.vue';
import TubeEntrant from '@connect-field/client/components/forms/PlanBoite/TubeEntrant.vue';
import TubeSortant from '@connect-field/client/components/forms/PlanBoite/TubeSortant.vue';

import useBoxPlanStore from '@connect-field/client/stores/boxPlan';

export default defineComponent({
    components: {
        FibreEntrante,
        FibreSortante,
        TubeEntrant,
        TubeSortant,
    },
    computed: {
        ...mapState(useBoxPlanStore, {
            cassettesData: 'cassettesData',
        }),
    },
});
</script>

<style scoped lang="scss">
table {
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;

    td {
        padding: 4px;
    }

    th {
        padding: 4px;
        text-align: center;
    }
}
.upright {
    writing-mode: vertical-lr;
    text-orientation: upright;
}
</style>
