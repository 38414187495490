<template>
    <el-row>
        <el-col :span="24">
            <div class="custom_label_input">{{ label }}</div>
        </el-col>
        <el-col
            :span="24"
            class="text-right"
        >
            <el-form-item>
                <el-switch
                    v-model="model"
                    :disabled="disabled"
                    :active-text="options.true"
                    :inactive-text="options.false"
                    inactive-color="#989899"
                />
            </el-form-item>
        </el-col>
    </el-row>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'CustomInputSwitch',
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: '',
        },
        options: {
            type: Object,
            required: true,
        },
        value: {
            type: Boolean,
            required: true,
        },
    },
    emits: ['update:value'],
    computed: {
        model: {
            get(): boolean {
                return this.value;
            },
            set(value: boolean) {
                this.$emit('update:value', value);
            },
        },
    },
});
</script>
