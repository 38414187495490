<template>
    <div>
        <div
            v-for="styleLayer of subLayers"
            :key="styleLayer.name"
            class="mapbox-layer"
        >
            <el-checkbox
                v-model.value="styleLayer.visible"
                class="collapse__checkbox"
                @click.prevent="() => switchMapboxLayer(layer, styleLayer.name)"
            >
                <span class="legend_name">{{
                    getLegendAlias(layer, styleLayer.name)
                }}</span>
            </el-checkbox>

            <div class="img-container">
                <div :style="getLegendStyle(layer, styleLayer.name)" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';
import type BaseLayer from 'ol/layer/Base';
import type Layer from 'ol/layer/Layer';
import VectorLayer from 'ol/layer/Vector';
import VectorTileLayer from 'ol/layer/VectorTile';

import {
    applyStyle,
    getLegendAlias,
    getLegendStyle,
} from '@connect-field/client/services/styles.service';

interface DataInterface {
    layerName?: string;
    subLayers: Array<{ name: string; visible: boolean }>;
}

export default defineComponent({
    props: {
        layer: {
            type: Object as PropType<Layer>,
            required: true,
        },
        styles: {
            type: Object as PropType<
                Record<string, Array<{ name: string; visible: boolean }>>
            >,
            required: true,
        },
    },
    data(): DataInterface {
        return { subLayers: [] };
    },
    mounted() {
        const layerName = this.layer.getProperties().name;
        if (!layerName || !this.styles[layerName]) {
            return;
        }
        this.layerName = layerName;

        this.subLayers = [...this.styles[layerName]];
    },
    methods: {
        getLegendAlias(layer: BaseLayer, mapboxLayer: string): string {
            return getLegendAlias(layer, mapboxLayer) || mapboxLayer;
        },

        getLegendStyle(
            layer: BaseLayer,
            mapboxLayer: string,
        ): Partial<CSSStyleDeclaration> {
            return getLegendStyle(layer, mapboxLayer);
        },

        switchMapboxLayer(layer: Layer, subLayerName: string): void {
            if (!this.layerName) {
                throw new Error('[LayerLegend] layerName is undefined');
            }

            this.subLayers = this.subLayers.map(
                (layer: { name: string; visible: boolean }) => {
                    if (layer.name === subLayerName) {
                        layer.visible = !layer.visible;
                    }

                    return layer;
                },
            );

            const hiddenLayers = this.subLayers.reduce(
                (
                    acc: Array<string>,
                    layer: { name: string; visible: boolean },
                ) => {
                    if (!layer.visible) {
                        acc.push(layer.name);
                    }

                    return acc;
                },
                [],
            );

            if (
                layer instanceof VectorLayer ||
                layer instanceof VectorTileLayer
            ) {
                applyStyle(layer, {
                    hiddenLayers,
                });
            }
        },
    },
});
</script>

<style scoped lang="scss">
.collapse__checkbox {
    margin: 0 0 0 1rem;
}

.mapbox-layer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 4px;
}

.img-container {
    zoom: 0.18;
}
.legend_name {
    color: white;
}
</style>
