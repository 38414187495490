import { createXYZ } from 'ol/tilegrid';
import type { Extent } from 'ol/extent';
import type Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';
import type { Geometry } from 'ol/geom';
import ImageTile from 'ol/ImageTile';
import MVT from 'ol/format/MVT';
import pako from 'pako';
import type { Projection } from 'ol/proj';
import { pick as radashPick } from 'radash';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import VectorTile from 'ol/VectorTile';
import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import XYZ from 'ol/source/XYZ';

import { applyStyle, applyStyleBasemap } from '@connect-field/client/services/styles.service';
import type { GeoJSONGeoJSONPropertiesDto, ProjectConfigurationLayer } from '@connect-field/client/sdk/generated';
import { MBTiles, type OptionsInterface } from '@connect-field/client/services/mbtiles';
import type { LayerProperties } from '@connect-field/client/stores/layers';

export interface OfflineGeoJSONLayerConfiguration extends ProjectConfigurationLayer {
    data: GeoJSONGeoJSONPropertiesDto;
}

export interface OfflineMBTilesLayerConfiguration extends ProjectConfigurationLayer {
    data: Uint8Array;
}

export function generateGeoJsonOfflineLayer(layer: OfflineGeoJSONLayerConfiguration): VectorLayer<VectorSource> {
    const vectorSource = new VectorSource({
        format: new GeoJSON(),
        loader: (
            extent: Extent,
            resolution: number,
            projection: Projection,
            success?: (args: Array<Feature<Geometry>>) => void,
            failure?: () => void,
        ): void => {
            try {
                if (!vectorSource) {
                    return;
                }

                const format = vectorSource.getFormat();
                if (!format) {
                    throw new Error('format is null');
                }
                const features = format.readFeatures(layer.data, {
                    dataProjection: projection, // Data is in EPSG:3857
                    featureProjection: projection, // Map is in EPSG:3857
                }) as Array<Feature>;
                vectorSource.addFeatures(features);
                if (!success) {
                    throw new Error('success callback undefined');
                }
                success(features);
            } catch (error: unknown) {
                console.error(error);
                vectorSource.removeLoadedExtent(extent);
                if (failure) {
                    failure();
                }
            }
        },
    });

    const properties: LayerProperties = {
        ...radashPick(layer, [
            'alias',
            'category',
            'enableCreation',
            'enableEdition',
            'form',
            'format',
            'hiddenLegend',
            'id',
            'idField',
            'name',
            'sourceLayer',
            'style',
            'table',
            'type',
        ]),
        global: false,
        online: false,
    };

    return new VectorLayer({
        declutter: false,
        properties,
        source: vectorSource,
        visible: !layer.hiddenByDefault,
        zIndex: layer.zIndex,
    });
}

export async function generateMBTilesLayer(
    array: Uint8Array,
    options: OptionsInterface,
    properties: LayerProperties,
): Promise<VectorTileLayer | TileLayer<XYZ> | undefined> {
    const mbTiles = new MBTiles();
    await mbTiles.openDB(array);

    let layer;

    switch (mbTiles.format) {
        case 'application/vnd.mapbox-vector-tile':
            // extent = db.bounds;
            layer = new VectorTileLayer({
                declutter: options.declutter,
                preload: 10,
                properties: properties,
                renderMode: 'hybrid',
                source: new VectorTileSource({
                    format: new MVT({
                        idProperty: 'sid',
                    }),
                    maxZoom: mbTiles.maxZoom,
                    minZoom: mbTiles.minZoom,
                    tileGrid: createXYZ({
                        maxZoom: mbTiles.maxZoom,
                        tileSize: [256, 256],
                    }),
                    tileLoadFunction: (tile, url): void => {
                        if (!(tile instanceof VectorTile)) {
                            throw new Error('tile is not a VectorTile');
                        }

                        return tile.setLoader(function (extent, resolution, projection) {
                            const [z, x, y] = url.split(',').map(Number);
                            const row = mbTiles.query.getAsObject({
                                ':x': x,
                                ':y': y,
                                ':z': z,
                            });

                            if (row?.tile_data) {
                                const format = tile.getFormat(); // ol/format/MVT configured as source format
                                const ungzippedData = pako.ungzip(row.tile_data);
                                const features = format.readFeatures(ungzippedData, {
                                    extent: extent,
                                    featureProjection: projection,
                                });
                                tile.setFeatures(features);
                            } else {
                                tile.setFeatures([]);
                            }
                        });
                    },
                    url: '{z},{x},{-y}',
                    wrapX: false,
                }),
                visible: true,
                zIndex: options.zIndex,
            });

            if (options.basemap) {
                applyStyleBasemap(layer);
            } else {
                applyStyle(layer);
            }
            break;
        case 'image/png':
            layer = new TileLayer({
                properties: { id: 'background' },
                source: new XYZ({
                    maxZoom: mbTiles.maxZoom,
                    minZoom: mbTiles.minZoom,
                    tileLoadFunction: (tile, src): void => {
                        if (tile instanceof ImageTile) {
                            const [z, x, y] = src.split(',').map(Number);

                            const row = mbTiles.query.getAsObject({
                                ':x': x,
                                ':y': y,
                                ':z': z,
                            });

                            const image = tile.getImage();

                            if (image instanceof HTMLImageElement) {
                                if (row && 'tile_data' in row) {
                                    image.src = URL.createObjectURL(
                                        new Blob([row.tile_data], {
                                            type: 'image/png',
                                        }),
                                    );
                                } else {
                                    image.src =
                                        'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';
                                }
                            }
                        }
                    },
                    tileSize: [256, 256],
                    url: '{z},{x},{-y}',
                    wrapX: false,
                }),
                visible: true,
                zIndex: options.zIndex,
            });
            break;
    }

    return layer;
}
