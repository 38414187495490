<template>
    <div v-if="empty" />
    <div
        v-else
        class="tube-a"
        :style="{ backgroundColor: color ?? tubes[position] }"
    >
        <div class="tube-title">{{ String(position).padStart(2, '0') }}</div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { tubes } from '@connect-field/client/components/forms/PlanBoite/colors.data';

export default defineComponent({
    props: {
        color: {
            type: String,
            default: '#FFFFFF',
        },
        empty: {
            type: Boolean,
            default: false,
        },
        position: {
            type: Number,
            required: true,
        },
    },
    data() {
        return { tubes };
    },
});
</script>

<style lang="scss" scoped>
.tube-a {
    border-radius: 0 8px 8px 0;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.tube-title {
    background: white;
    border: 2px solid black;
    border-radius: 20px;
    padding: 2px 5px;
    color: black;
    margin: 4px;
}
</style>
