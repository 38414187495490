<template>
    <div
        v-if="estimate"
        class="estimate"
    >
        {{ $t('estimateUsage.storageUsed') }} {{ usageEstimation }} % (
        {{ formatSize(usage) }} / {{ formatSize(quota) }} )
    </div>

    <div
        v-if="count.length > 0"
        class="counter"
    >
        <div>{{ $t('estimateUsage.savedObjects') }}</div>
        <ul>
            <li
                v-for="c in count"
                :key="c.table"
            >
                {{ c.table }} : {{ c.count }}
                {{ $t('globals.object', c.count) }}
                <span v-if="size[c.table]">
                    / {{ formatSize(size[c.table]) }}
                </span>
            </li>
        </ul>
    </div>

    <el-button
        type="primary"
        :icon="DataAnalysis"
        @click="calculate"
    />
</template>

<script lang="ts">
import { DataAnalysis } from '@element-plus/icons-vue';
import { defineComponent } from 'vue';

import {
    count as countItems,
    getItems,
    stores,
} from '@connect-field/client/utilities/idb-utility';
import { formatBytes } from '@connect-field/client/utilities/tools';
import type { Store } from '@connect-field/client/utilities/idb-utility';

interface CountInterface {
    count: number;
    table: string;
}

interface DataInterface {
    count: Array<CountInterface>;
    estimate: boolean;
    quota?: number;
    size: Record<string, number>;
    usage?: number;
    usageEstimation: string;
}

export default defineComponent({
    setup() {
        return {
            DataAnalysis,
        };
    },
    data(): DataInterface {
        return {
            count: [],
            estimate: false,
            size: {},
            usageEstimation: '',
        };
    },
    async mounted() {
        await this.getUsageEstimation();
        await this.getCount();
    },
    methods: {
        async calculate(): Promise<void> {
            await Promise.all(
                Object.values(stores).map(async (store: Store) => {
                    const table = store.name;

                    switch (table) {
                        case 'projectBasemaps':
                        case 'projectMbtiles':
                        case 'projectSearch': {
                            const res = await getItems<Uint8Array>(table);

                            this.size[table] = res.reduce(
                                (acc: number, item: Uint8Array) => {
                                    return acc + item.length;
                                },
                                0,
                            );
                            break;
                        }
                        default: {
                            const res = await getItems<unknown>(table);

                            this.size[table] = JSON.stringify(res).length;
                            break;
                        }
                    }
                }),
            );
        },
        formatSize(size: number): string {
            return formatBytes(size);
        },
        async getCount(): Promise<void> {
            this.count = await Promise.all(
                Object.values(stores).map(async (store: Store) => {
                    const count = await countItems(store.name);

                    return { count, table: store.name };
                }),
            );
        },
        async getUsageEstimation(): Promise<void> {
            if (navigator.storage && navigator.storage.estimate) {
                const estimate = await navigator.storage.estimate();
                if (estimate) {
                    this.estimate = true;
                    this.usage = estimate.usage;
                    this.quota = estimate.quota;
                    this.usageEstimation = (
                        ((estimate?.usage ?? 0) / (estimate?.quota ?? 1)) *
                        100
                    ).toFixed(2);
                }
            }
        },
    },
});
</script>

<style scoped lang="scss">
.estimate {
    color: white;
    opacity: 0.7;
    font-size: 14px;
    display: flex;
}

.counter {
    color: white;
    opacity: 0.7;
    font-size: 14px;

    ul {
        li::before {
            content: ' - ';
        }
    }
}

.click-estimate {
    cursor: pointer;
    text-decoration: underline #2d9cfd;
    color: #4fadff;
}
</style>
