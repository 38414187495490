<template>
    <button
        class="aspect"
        :class="[
            isGlazed ? 'glass' : 'blueBckg',
            size === 'normal' ? 'sizeNormal' : 'sizeBig',
        ]"
        :style="[
            isRound ? 'border-radius: 360px;' : 'border-radius: 3px;',
            isActive ? 'color:var(--color-primary)' : 'color:white',
        ]"
        type="button"
    >
        <FontAwesomeIcon
            :class="[size === 'big' ? 'bigIcon' : '']"
            :icon="iconName"
        />
    </button>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';

export default defineComponent({
    props: {
        iconName: { type: String, required: true },
        isActive: { type: Boolean, default: false },
        isGlazed: { type: Boolean, default: true },
        isRound: { type: Boolean, default: false },
        size: { type: String as PropType<'normal' | 'big'>, default: 'normal' },
    },
});
</script>

<style scoped lang="scss">
.blueBckg {
    background: var(--color-primary);
}

.sizeNormal {
    width: 2.25rem;
    height: 2.25rem;
}

.sizeBig {
    width: 3rem;
    height: 3rem;
}

.bigIcon {
    font-size: 1.5rem;
}

.aspect {
    cursor: pointer;
    outline: 0;
    margin-bottom: 5px;
    border: none;
    box-shadow: var(--shadow);
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
