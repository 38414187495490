<template>
    <FormWrapper
        v-if="!loading"
        ref="customFormRef"
        :images-model="images"
        :form-model="form"
        :mandatory-fields="mandatoryFields"
        :changes-model="changes"
        :layer-name="layerName"
        :object-id="objectId"
        :project-id="projectId"
        :latitude="latitude"
        :longitude="longitude"
        @update:images-model="
            (e) => {
                images = e;
            }
        "
        @update:value-missing="
            (e) => {
                valueMissing = e;
            }
        "
        @update:form-model="
            (e) => {
                form = e;
            }
        "
        @update:changes-model="
            (e) => {
                changes = e;
            }
        "
    >
        <template #menu_tabs>
            <el-row class="scroll-menu">
                <el-col :span="8">
                    <div
                        @click="selectTab('Général')"
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'Général' }"
                    >
                        <FontAwesomeIcon
                            size="xs"
                            icon="user-helmet-safety"
                        />
                    </div>
                </el-col>

                <el-col :span="8">
                    <div
                        @click="selectTab('EPI')"
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'EPI' }"
                    >
                        <FontAwesomeIcon
                            size="xs"
                            icon="helmet-safety"
                        />
                    </div>
                </el-col>

                <el-col :span="8">
                    <div
                        @click="selectTab('Sécurité Chantier')"
                        class="tabs"
                        :class="{
                            selectedTab: selectedTab === 'Sécurité Chantier',
                        }"
                    >
                        <FontAwesomeIcon
                            size="xs"
                            icon="traffic-cone"
                        />
                    </div>
                </el-col>

                <el-col :span="8">
                    <div
                        @click="selectTab('Equipement')"
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'Equipement' }"
                    >
                        <FontAwesomeIcon
                            size="xs"
                            icon="screwdriver-wrench"
                        />
                    </div>
                </el-col>

                <el-col :span="8">
                    <div
                        @click="selectTab('TVX Hauteur')"
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'TVX Hauteur' }"
                    >
                        <FontAwesomeIcon
                            size="xs"
                            icon="utility-pole"
                        />
                    </div>
                </el-col>

                <el-col :span="8">
                    <div
                        @click="selectTab('Document')"
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'Document' }"
                    >
                        <FontAwesomeIcon
                            size="xs"
                            icon="file-signature"
                        />
                    </div>
                </el-col>

                <el-col :span="8">
                    <div
                        @click="selectTab('Sanitaire')"
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'Sanitaire' }"
                    >
                        <FontAwesomeIcon
                            size="xs"
                            icon="kit-medical"
                        />
                    </div>
                </el-col>
            </el-row>
        </template>
        <template #main_form>
            <el-row>
                <el-col
                    :span="24"
                    v-show="selectedTab === 'Général'"
                >
                    <CustomInput
                        :label="labels.sst_rang1 + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.sst_rang1"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('sst_rang1', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.sst_rang2"
                        :value="form.sst_rang2"
                        @update:value="
                            (newValue) => saveData('sst_rang2', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.sst_rang3"
                        :value="form.sst_rang3"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('sst_rang3', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.phase_tvx + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.phase_tvx"
                        :value="form.phase_tvx"
                        size="small"
                        @update:value="
                            (newValue) => saveData('phase_tvx', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.activite + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.activite"
                        :value="form.activite"
                        @update:value="
                            (newValue) => saveData('activite', newValue)
                        "
                    />
                    <CustomDatePicker
                        :label="labels.date_audit + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.date_audit"
                        @update:value="
                            (newValue) => saveData('date_audit', newValue)
                        "
                    />
                    <InputNumber
                        :label="labels.nb_personnel_present + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.nb_personnel_present"
                        @update:value="
                            (newValue) =>
                                saveData('nb_personnel_present', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.observation_globale"
                        :value="form.observation_globale"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData('observation_globale', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.visite_effectue_aic + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.visite_effectue_aic"
                        @update:value="
                            (newValue) =>
                                saveData('visite_effectue_aic', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.agent_controle + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.agent_controle"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('agent_controle', newValue)
                        "
                    />
                    <CustomDatePicker
                        :label="labels.date_arret_chantier"
                        :value="form.date_arret_chantier"
                        @update:value="
                            (newValue) =>
                                saveData('date_arret_chantier', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.statut_audit + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.statut_audit"
                        :value="form.statut_audit"
                        @update:value="
                            (newValue) => saveData('statut_audit', newValue)
                        "
                    />
                </el-col>
                <el-col
                    :span="24"
                    v-show="selectedTab === 'EPI'"
                >
                    <CustomRadioButton
                        :label="labels.epi_gilet"
                        :options="options.epi_gilet"
                        :value="form.epi_gilet"
                        size="small"
                        @update:value="
                            (newValue) => saveData('epi_gilet', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.epi_chaussures"
                        :options="options.epi_chaussures"
                        :value="form.epi_chaussures"
                        size="small"
                        @update:value="
                            (newValue) => saveData('epi_chaussures', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.epi_casque"
                        :options="options.epi_casque"
                        :value="form.epi_casque"
                        size="small"
                        @update:value="
                            (newValue) => saveData('epi_casque', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.epi_gants"
                        :options="options.epi_gants"
                        :value="form.epi_gants"
                        size="small"
                        @update:value="
                            (newValue) => saveData('epi_gants', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.epi_lunette_soudure"
                        :options="options.epi_lunette_soudure"
                        :value="form.epi_lunette_soudure"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('epi_lunette_soudure', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.epi_lunette_gc"
                        :options="options.epi_lunette_gc"
                        :value="form.epi_lunette_gc"
                        size="small"
                        @update:value="
                            (newValue) => saveData('epi_lunette_gc', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.epi_protec_auditive"
                        :options="options.epi_protec_auditive"
                        :value="form.epi_protec_auditive"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('epi_protec_auditive', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.epi_observations"
                        :value="form.epi_observations"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('epi_observations', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_as_epi"
                        :images="images.img_as_epi"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('img_as_epi', newValue)
                        "
                    />
                </el-col>
                <el-col
                    :span="24"
                    v-show="selectedTab === 'Sécurité Chantier'"
                >
                    <CustomRadioButton
                        :label="labels.sc_panneau_approche"
                        :options="options.sc_panneau_approche"
                        :value="form.sc_panneau_approche"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('sc_panneau_approche', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.sc_panneau_position"
                        :options="options.sc_panneau_position"
                        :value="form.sc_panneau_position"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('sc_panneau_position', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.sc_amenagement"
                        :options="options.sc_amenagement"
                        :value="form.sc_amenagement"
                        size="small"
                        @update:value="
                            (newValue) => saveData('sc_amenagement', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.sc_visibilite_vehicule"
                        :options="options.sc_visibilite_vehicule"
                        :value="form.sc_visibilite_vehicule"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('sc_visibilite_vehicule', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.sc_marquage_reseau"
                        :options="options.sc_marquage_reseau"
                        :value="form.sc_marquage_reseau"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('sc_marquage_reseau', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.sc_proprete"
                        :options="options.sc_proprete"
                        :value="form.sc_proprete"
                        size="small"
                        @update:value="
                            (newValue) => saveData('sc_proprete', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.sc_observations"
                        :value="form.sc_observations"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('sc_observations', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_as_securite_chantier"
                        :images="images.img_as_securite_chantier"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_as_securite_chantier', newValue)
                        "
                    />
                </el-col>
                <el-col
                    :span="24"
                    v-show="selectedTab === 'Equipement'"
                >
                    <CustomRadioButton
                        :label="labels.eqp_marteau_leve_plaque"
                        :options="options.eqp_marteau_leve_plaque"
                        :value="form.eqp_marteau_leve_plaque"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('eqp_marteau_leve_plaque', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.eqp_engins"
                        :options="options.eqp_engins"
                        :value="form.eqp_engins"
                        size="small"
                        @update:value="
                            (newValue) => saveData('eqp_engins', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.eqp_materiel"
                        :options="options.eqp_materiel"
                        :value="form.eqp_materiel"
                        size="small"
                        @update:value="
                            (newValue) => saveData('eqp_materiel', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.eqp_clef_triangle"
                        :options="options.eqp_clef_triangle"
                        :value="form.eqp_clef_triangle"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('eqp_clef_triangle', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.eqp_clef_nfc"
                        :options="options.eqp_clef_nfc"
                        :value="form.eqp_clef_nfc"
                        size="small"
                        @update:value="
                            (newValue) => saveData('eqp_clef_nfc', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.eqp_detecteur_gaz"
                        :options="options.eqp_detecteur_gaz"
                        :value="form.eqp_detecteur_gaz"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('eqp_detecteur_gaz', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.eqp_blindage_fouille"
                        :options="options.eqp_blindage_fouille"
                        :value="form.eqp_blindage_fouille"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('eqp_blindage_fouille', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.eqp_observations"
                        :value="form.eqp_observations"
                        @update:value="
                            (newValue) => saveData('eqp_observations', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_as_equipement"
                        :images="images.img_as_equipement"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_as_equipement', newValue)
                        "
                    />
                </el-col>
                <el-col
                    :span="24"
                    v-show="selectedTab === 'TVX Hauteur'"
                >
                    <CustomRadioButton
                        :label="labels.th_nacelle"
                        :options="options.th_nacelle"
                        :value="form.th_nacelle"
                        size="small"
                        @update:value="
                            (newValue) => saveData('th_nacelle', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.th_binome"
                        :options="options.th_binome"
                        :value="form.th_binome"
                        size="small"
                        @update:value="
                            (newValue) => saveData('th_binome', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.th_ceinture_ligne_vie"
                        :options="options.th_ceinture_ligne_vie"
                        :value="form.th_ceinture_ligne_vie"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('th_ceinture_ligne_vie', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.th_harnais"
                        :options="options.th_harnais"
                        :value="form.th_harnais"
                        size="small"
                        @update:value="
                            (newValue) => saveData('th_harnais', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.th_systeme_arret_chute"
                        :options="options.th_systeme_arret_chute"
                        :value="form.th_systeme_arret_chute"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('th_systeme_arret_chute', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.th_point_ancrage"
                        :options="options.th_point_ancrage"
                        :value="form.th_point_ancrage"
                        size="small"
                        @update:value="
                            (newValue) => saveData('th_point_ancrage', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.th_controle_appui"
                        :options="options.th_controle_appui"
                        :value="form.th_controle_appui"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('th_controle_appui', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.th_echelle"
                        :options="options.th_echelle"
                        :value="form.th_echelle"
                        size="small"
                        @update:value="
                            (newValue) => saveData('th_echelle', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.th_observations"
                        :value="form.th_observations"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('th_observations', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_as_travaux_hauteur"
                        :images="images.img_as_travaux_hauteur"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_as_travaux_hauteur', newValue)
                        "
                    />
                </el-col>
                <el-col
                    :span="24"
                    v-show="selectedTab === 'Document'"
                >
                    <CustomRadioButton
                        :label="labels.doc_carte_pro"
                        :options="options.doc_carte_pro"
                        :value="form.doc_carte_pro"
                        size="small"
                        @update:value="
                            (newValue) => saveData('doc_carte_pro', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.doc_dict"
                        :options="options.doc_dict"
                        :value="form.doc_dict"
                        size="small"
                        @update:value="
                            (newValue) => saveData('doc_dict', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.doc_arrete_circu"
                        :options="options.doc_arrete_circu"
                        :value="form.doc_arrete_circu"
                        size="small"
                        @update:value="
                            (newValue) => saveData('doc_arrete_circu', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.doc_perm_voirie"
                        :options="options.doc_perm_voirie"
                        :value="form.doc_perm_voirie"
                        size="small"
                        @update:value="
                            (newValue) => saveData('doc_perm_voirie', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.doc_ppsps"
                        :options="options.doc_ppsps"
                        :value="form.doc_ppsps"
                        size="small"
                        @update:value="
                            (newValue) => saveData('doc_ppsps', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.doc_caces"
                        :options="options.doc_caces"
                        :value="form.doc_caces"
                        size="small"
                        @update:value="
                            (newValue) => saveData('doc_caces', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.doc_habilitation_elect"
                        :options="options.doc_habilitation_elect"
                        :value="form.doc_habilitation_elect"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('doc_habilitation_elect', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.doc_tvx_enedis"
                        :options="options.doc_tvx_enedis"
                        :value="form.doc_tvx_enedis"
                        size="small"
                        @update:value="
                            (newValue) => saveData('doc_tvx_enedis', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.doc_habilitation_aipr"
                        :options="options.doc_habilitation_aipr"
                        :value="form.doc_habilitation_aipr"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('doc_habilitation_aipr', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.doc_amiante"
                        :options="options.doc_amiante"
                        :value="form.doc_amiante"
                        size="small"
                        @update:value="
                            (newValue) => saveData('doc_amiante', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.doc_visite_levage"
                        :options="options.doc_visite_levage"
                        :value="form.doc_visite_levage"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('doc_visite_levage', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.doc_observations"
                        :value="form.doc_observations"
                        @update:value="
                            (newValue) => saveData('doc_observations', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_as_documents"
                        :images="images.img_as_documents"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_as_documents', newValue)
                        "
                    />
                </el-col>
                <el-col
                    :span="24"
                    v-show="selectedTab === 'Sanitaire'"
                >
                    <CustomRadioButton
                        :label="labels.covid_masque"
                        :options="options.covid_masque"
                        :value="form.covid_masque"
                        size="small"
                        @update:value="
                            (newValue) => saveData('covid_masque', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.covid_gel"
                        :options="options.covid_gel"
                        :value="form.covid_gel"
                        size="small"
                        @update:value="
                            (newValue) => saveData('covid_gel', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.covid_gestes_barriere"
                        :options="options.covid_gestes_barriere"
                        :value="form.covid_gestes_barriere"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('covid_gestes_barriere', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.covid_observations"
                        :value="form.covid_observations"
                        @update:value="
                            (newValue) =>
                                saveData('covid_observations', newValue)
                        "
                    />
                </el-col>
            </el-row>
        </template>
    </FormWrapper>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import {
    labels,
    options,
} from '@connect-field/client/components/forms/AIC/Audit/t_audit_securite.data';
import type { FormDataInterface } from '@connect-field/client/components/forms/form.types';
import FormTemplate from '@connect-field/client/components/forms/FormTemplate.vue';

interface DataInterface extends FormDataInterface {}

export default defineComponent({
    extends: FormTemplate,
    data(): DataInterface {
        return {
            changes: false,
            form: {},
            images: {
                img_as_epi: [],
                img_as_securite_chantier: [],
                img_as_equipement: [],
                img_as_travaux_hauteur: [],
                img_as_documents: [],
            },
            labels,
            loading: true,
            mandatoryFields: [
                'sst_rang1',
                'phase_tvx',
                'activite',
                'date_audit',
                'nb_personnel_present',
                'visite_effectue_aic',
                'agent_controle',
                'statut_audit',
            ],
            options,
            selectedTab: 'Général',
            valueMissing: false,
        };
    },
    methods: {},
});
</script>
