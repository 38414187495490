<template>
    <FormWrapper
        v-if="!loading"
        ref="customFormRef"
        :images-model="images"
        :form-model="form"
        :mandatory-fields="mandatoryFields"
        :changes-model="changes"
        :layer-name="layerName"
        :object-id="objectId"
        :project-id="projectId"
        :latitude="latitude"
        :longitude="longitude"
        @update:images-model="
            (e) => {
                images = e;
            }
        "
        @update:value-missing="
            (e) => {
                valueMissing = e;
            }
        "
        @update:form-model="
            (e) => {
                form = e;
            }
        "
        @update:changes-model="
            (e) => {
                changes = e;
            }
        "
    >
        <template #menu_tabs>
            <el-row class="scroll-menu">
                <el-col :span="12">
                    <div
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'Général' }"
                        @click="selectTab('Général')"
                    >
                        Général
                    </div>
                </el-col>
                <el-col :span="12">
                    <div
                        class="tabs"
                        :class="{
                            selectedTab: selectedTab === 'Environnement',
                        }"
                        @click="selectTab('Environnement')"
                    >
                        Environnement
                    </div>
                </el-col>
                <el-col :span="12">
                    <div
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'Chambre' }"
                        @click="selectTab('Chambre')"
                    >
                        Chambre
                    </div>
                </el-col>
            </el-row>
        </template>
        <template #main_form>
            <el-row>
                <el-col
                    v-show="selectedTab === 'Général'"
                    :span="24"
                >
                    <CustomInput
                        :label="labels.id_corolle + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.id_corolle"
                        :maxlength="255"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.etiquette_ + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.etiquette_"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.etiquett00 + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.etiquett00"
                        :maxlength="255"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.type_equip + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.type_equip"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.designatio + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.designatio"
                        :maxlength="255"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.constructe + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.constructe"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.reference + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.reference"
                        :maxlength="255"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.adresse + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.adresse"
                        :disabled="true"
                    />
                    <InputNumber
                        :label="labels.code_insee + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.code_insee"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.nom_commun + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.nom_commun"
                        :maxlength="255"
                        :disabled="true"
                    />
                    <InputNumber
                        :label="labels.code_rivol + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.code_rivol"
                        :disabled="true"
                    />
                </el-col>
                <el-col
                    v-show="selectedTab === 'Environnement'"
                    :span="24"
                >
                    <CustomRadioButton
                        :label="labels.statut_position + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.statut_position"
                        :value="form.statut_position"
                        size="small"
                        @update:value="
                            (newValue) => saveData('statut_position', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.rec_audit + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.rec_audit"
                        :value="form.rec_audit"
                        @update:value="
                            (newValue) => saveData('rec_audit', newValue)
                        "
                    />
                    <CustomInputTextarea
                        v-show="condition_rec_audit_com()"
                        :label="labels.rec_audit_com + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.rec_audit_com"
                        @update:value="
                            (newValue) => saveData('rec_audit_com', newValue)
                        "
                    />
                    <CustomDatePicker
                        :label="labels.date_visite + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.date_visite"
                        @update:value="
                            (newValue) => saveData('date_visite', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_env + ' *'"
                        :images="images.img_env"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('img_env', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commune + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.commune"
                        :maxlength="255"
                        @update:value="
                            (newValue) => saveData('commune', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.num_rue + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.num_rue"
                        @update:value="
                            (newValue) => saveData('num_rue', newValue)
                        "
                    />
                </el-col>
                <el-col
                    v-show="selectedTab === 'Chambre'"
                    :span="24"
                >
                    <CustomInputSwitch
                        v-show="condition_presence_tampon()"
                        :label="labels.presence_tampon + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.presence_tampon"
                        :value="form.presence_tampon"
                        @update:value="
                            (newValue) => saveData('presence_tampon', newValue)
                        "
                    />
                    <InputCamera
                        v-show="condition_img_tampon()"
                        :label="labels.img_tampon + ' *'"
                        :images="images.img_tampon"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('img_tampon', newValue)
                        "
                    />
                    <CustomInputSwitch
                        v-show="condition_presence_grille()"
                        :label="labels.presence_grille + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.presence_grille"
                        :value="form.presence_grille"
                        @update:value="
                            (newValue) => saveData('presence_grille', newValue)
                        "
                    />
                    <InputCamera
                        v-show="condition_img_grille()"
                        :label="labels.img_grille + ' *'"
                        :images="images.img_grille"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('img_grille', newValue)
                        "
                    />
                    <CustomInputSwitch
                        v-show="condition_presence_etiquette()"
                        :label="labels.presence_etiquette + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.presence_etiquette"
                        :value="form.presence_etiquette"
                        @update:value="
                            (newValue) =>
                                saveData('presence_etiquette', newValue)
                        "
                    />
                    <InputCamera
                        v-show="condition_img_etiquette()"
                        :label="labels.img_etiquette + ' *'"
                        :images="images.img_etiquette"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('img_etiquette', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_info_etiquette()"
                        :label="labels.info_etiquette + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.info_etiquette"
                        :maxlength="255"
                        @update:value="
                            (newValue) => saveData('info_etiquette', newValue)
                        "
                    />
                    <CustomInputSwitch
                        v-show="condition_fixation_cable()"
                        :label="labels.fixation_cable + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.fixation_cable"
                        :value="form.fixation_cable"
                        @update:value="
                            (newValue) => saveData('fixation_cable', newValue)
                        "
                    />
                    <InputCamera
                        v-show="condition_img_cables()"
                        :label="labels.img_cables + ' *'"
                        :images="images.img_cables"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('img_cables', newValue)
                        "
                    />
                    <CustomInputSwitch
                        v-show="condition_etiq_cable()"
                        :label="labels.etiq_cable + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.etiq_cable"
                        :value="form.etiq_cable"
                        @update:value="
                            (newValue) => saveData('etiq_cable', newValue)
                        "
                    />
                    <InputCamera
                        v-show="condition_img_etiq_cable()"
                        :label="labels.img_etiq_cable + ' *'"
                        :images="images.img_etiq_cable"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('img_etiq_cable', newValue)
                        "
                    />
                    <CustomInputSwitch
                        v-show="condition_presence_boite()"
                        :label="labels.presence_boite + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.presence_boite"
                        :value="form.presence_boite"
                        @update:value="
                            (newValue) => saveData('presence_boite', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.statut_audit + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.statut_audit"
                        :value="form.statut_audit"
                        @update:value="
                            (newValue) => saveData('statut_audit', newValue)
                        "
                    />
                    <CustomInputTextarea
                        :label="labels.com_audit + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.com_audit"
                        @update:value="
                            (newValue) => saveData('com_audit', newValue)
                        "
                    />
                </el-col>
            </el-row>
        </template>
    </FormWrapper>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import {
    labels,
    options,
} from '@connect-field/client/components/forms/Dotic/SIPPEREC/ReleveChambre.data';
import type { FormDataInterface } from '@connect-field/client/components/forms/form.types';
import FormTemplate from '@connect-field/client/components/forms/FormTemplate.vue';

interface DataInterface extends FormDataInterface {}

export default defineComponent({
    extends: FormTemplate,
    data(): DataInterface {
        return {
            changes: false,
            form: {},
            images: {
                img_cables: [],
                img_env: [],
                img_etiq_cable: [],
                img_etiquette: [],
                img_grille: [],
                img_tampon: [],
            },
            labels,
            level: 0,
            loading: true,
            mandatoryFields: [
                'statut_position',
                'position',
                'rec_audit',
                'rec_audit_com',
                'date_visite',
                'img_env',
                'commune',
                'statut_audit',
            ],
            options,
            selectedTab: 'Général',
            toRoute: null,
            valueMissing: false,
        };
    },
    methods: {
        condition_etiq_cable() {
            const { rec_audit } = this.form;
            return rec_audit === 'Réalisable';
        },
        condition_fixation_cable() {
            const { rec_audit } = this.form;
            return rec_audit === 'Réalisable';
        },
        condition_img_cables() {
            const { rec_audit } = this.form;
            return rec_audit === 'Réalisable';
        },
        condition_img_etiq_cable() {
            const { rec_audit } = this.form;
            return rec_audit === 'Réalisable';
        },
        condition_img_etiquette() {
            const { rec_audit } = this.form;
            return rec_audit === 'Réalisable';
        },
        condition_img_grille() {
            const { rec_audit } = this.form;
            return rec_audit === 'Réalisable';
        },
        condition_img_tampon() {
            const { rec_audit } = this.form;
            return rec_audit === 'Réalisable';
        },
        condition_info_etiquette() {
            const { rec_audit, presence_etiquette } = this.form;
            return rec_audit === 'Réalisable' && presence_etiquette === true;
        },
        condition_presence_boite() {
            const { rec_audit } = this.form;
            return rec_audit === 'Réalisable';
        },
        condition_presence_etiquette() {
            const { rec_audit } = this.form;
            return rec_audit === 'Réalisable';
        },
        condition_presence_grille() {
            const { rec_audit } = this.form;
            return rec_audit === 'Réalisable';
        },
        condition_presence_tampon() {
            const { rec_audit } = this.form;
            return rec_audit === 'Réalisable';
        },
        condition_rec_audit_com() {
            const { rec_audit } = this.form;
            return rec_audit !== 'Réalisable';
        },
    },
});
</script>
