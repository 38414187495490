<template>
    <div class="result__item">
        <div class="result__title">
            <p>{{ row.feature.searchAttribute }}</p>
            <p>{{ row.layerAlias }}</p>
        </div>
        <div>
            <el-button
                v-if="isLayerEditable(row.layerFormat)"
                :disabled="loadingForm"
                type="primary"
                @click.stop="() => zoomIn(row, 'openForm')"
            >
                <el-icon class="el-icon">
                    <Edit />
                </el-icon>
            </el-button>

            <el-button
                type="primary"
                size="default"
                @click.stop="() => zoomIn(row)"
            >
                {{ $t('search.aim') }}
                <el-icon class="el-icon--right">
                    <Aim />
                </el-icon>
            </el-button>
            <el-button
                type="primary"
                size="default"
                @click.stop="() => zoomIn(row, 'openSummary')"
            >
                {{ $t('globals.open') }}
                <el-icon class="el-icon--right">
                    <IconView />
                </el-icon>
            </el-button>
        </div>
    </div>
</template>

<script lang="ts">
import { Aim, Edit, View as IconView } from '@element-plus/icons-vue';
import { defineComponent, type PropType } from 'vue';

import {
    buildObjectDataFromFeature,
    generateGeometryFromGeoJSON,
    highlightPoint,
    type ObjectDataInterface,
    removeHighlightedFeature,
    removePinOnMap,
    zoomToFeature,
    zoomToGeometry,
} from '@connect-field/client/services/map.service';
import {
    getFeatureByIdFromSearchResult,
    getRenderFeatureByIdFromSearchResult,
    getVectorLayerFromSearchResult,
    getVectorTileLayerFromSearchResult,
    highlightFeatureFromGeometry,
} from '@connect-field/client/services/search.service';
import { redirectToForm } from '@connect-field/client/services/formRouter.service';
import { SearchGeoJSONLayerFormatEnum } from '@connect-field/client/sdk/generated';
import type { SearchResult } from '@connect-field/client/pages/home/search/SearchView.vue';
import useNavigationStore from '@connect-field/client/stores/navigation';
import useObjectStore from '@connect-field/client/stores/object';
import usePanelStore from '@connect-field/client/stores/panel';
import useProjectsStore from '@connect-field/client/stores/projects';

interface DataInterface {
    loadingForm: boolean;
}

export default defineComponent({
    components: {
        Aim,
        Edit,
        IconView,
    },
    props: {
        projectId: {
            type: String,
            required: true,
        },
        row: {
            type: Object as PropType<SearchResult>,
            required: true,
        },
    },
    setup() {
        return {
            navigationStore: useNavigationStore(),
            objectStore: useObjectStore(),
            panelStore: usePanelStore(),
            projectsStore: useProjectsStore(),
        };
    },
    data(): DataInterface {
        return { loadingForm: false };
    },
    computed: {
        isProjectOffline(): boolean {
            return this.projectsStore.isOffline;
        },
        selectedProjectId(): string | undefined {
            return this.projectsStore.selectedProjectId;
        },
    },
    methods: {
        isLayerEditable(layerFormat: SearchGeoJSONLayerFormatEnum) {
            return layerFormat === SearchGeoJSONLayerFormatEnum.GEOJSON;
        },
        async openForm(searchResult: SearchResult): Promise<void> {
            try {
                this.loadingForm = true;

                const layer = getVectorLayerFromSearchResult(searchResult);
                const feature = getFeatureByIdFromSearchResult(searchResult);

                if (!layer || !feature) {
                    throw new Error('Feature or layer are undefined');
                }

                let objectData: ObjectDataInterface | undefined;
                const featureId = feature.getId();
                objectData = buildObjectDataFromFeature(layer, feature);

                if (!featureId || !this.selectedProjectId || !objectData) {
                    throw new Error('Missing data');
                }

                this.objectStore.$patch((state) => {
                    if (objectData) {
                        state.dataForm = objectData.dataForm;
                        state.Feature = objectData.Feature;
                        state.layerData = objectData.layerData;
                    }
                });

                // TODO : Move this when search results are generated
                this.navigationStore.$patch((state) => {
                    if (objectData) {
                        state.currentObjects = [objectData];
                    }
                });

                await redirectToForm({
                    form: objectData.layerData.form,
                    isOffline: this.isProjectOffline,
                    layerName: objectData.layerData.name,
                    objectId: featureId.toString(),
                    projectId: this.selectedProjectId,
                    router: this.$router,
                });
            } catch (error: unknown) {
                console.error(error);

                throw error;
            } finally {
                this.loadingForm = false;
            }
        },
        openSummary(searchResult: SearchResult): void {
            let object: ObjectDataInterface | undefined;

            if (
                searchResult.layerFormat ===
                SearchGeoJSONLayerFormatEnum.GEOJSON
            ) {
                const feature = getFeatureByIdFromSearchResult(searchResult);
                const layer = getVectorLayerFromSearchResult(searchResult);
                object = buildObjectDataFromFeature(layer, feature);
            }

            if (searchResult.layerFormat === SearchGeoJSONLayerFormatEnum.PBF) {
                const feature =
                    getRenderFeatureByIdFromSearchResult(searchResult);
                const layer = getVectorTileLayerFromSearchResult(searchResult);
                object = buildObjectDataFromFeature(layer, feature);
            }

            if (!object || !object.Feature) {
                throw new Error('Feature is incorrect');
            }

            const featureId = object.Feature.getId();

            if (!featureId) {
                throw new Error('Feature has no id');
            }

            this.objectStore.setObject({
                dataForm: object.dataForm,
                Feature: object.Feature,
                layerData: object.layerData,
            });

            this.$router.push({
                name: this.isProjectOffline
                    ? 'objectDataOffline'
                    : 'objectData',
                params: {
                    layerName: object.layerData.name,
                    objectId: featureId.toString(),
                    projectId: this.projectId,
                },
            });
        },
        zoomIn(
            searchResult: SearchResult,
            nextAction?: 'openForm' | 'openSummary',
        ): void {
            removeHighlightedFeature();
            removePinOnMap();

            this.panelStore.setPanelHalfOpened();

            if (
                searchResult.layerFormat ===
                SearchGeoJSONLayerFormatEnum.GEOJSON
            ) {
                const feature = getFeatureByIdFromSearchResult(searchResult);

                if (!feature) {
                    throw new Error('Feature is undefined');
                }

                highlightPoint(feature);
                zoomToFeature(feature);
            }
            if (searchResult.layerFormat === SearchGeoJSONLayerFormatEnum.PBF) {
                zoomToGeometry(searchResult.feature.geometry);
                const geometry = generateGeometryFromGeoJSON(
                    searchResult.feature.geometry,
                );
                highlightFeatureFromGeometry(geometry);
            }

            switch (nextAction) {
                case 'openForm':
                    this.openForm(searchResult);
                    break;
                case 'openSummary':
                    this.openSummary(searchResult);
                    break;
            }
        },
    },
});
</script>
