export const labels = {
    adresse: 'Adresse',
    code_insee: 'Code INSEE',
    code_rivol: 'Code RIVOLI',
    com_audit: 'Commentaire',
    commune: 'Commune Equipement',
    constructe: 'Constructeur',
    date_visite: 'Date Visite Equipement',
    designatio: 'Désignation',
    etiq_cable: 'Câble Etiquetté',
    etiquett00: "Complément d'étiquette",
    etiquette_: 'Etiquette',
    fixation_cable: 'Câble Fixé',
    id_corolle: 'Identifiant',
    img_cables: 'Photo câbles',
    img_env: 'Photo Environnement',
    img_etiq_cable: 'Photo Etiquette cable',
    img_etiquette: 'Photo Etiquette',
    img_grille: 'Photo grille',
    img_tampon: 'Photo tampon',
    info_etiquette: 'Info Etiquette',
    nom_commun: 'Ville',
    num_rue: 'Rue Equipement',
    position: 'Position Equipement',
    presence_boite: 'Présence Boite',
    presence_etiquette: 'Présence Etiquette',
    presence_grille: 'Présence Grille',
    presence_tampon: 'Présence Tampon',
    rec_audit: 'Audit réalisable',
    rec_audit_com: 'Commentaire',
    reference: 'Référence',
    statut_audit: "Statut de l'audit",
    statut_position: 'Statut position Equipement',
    type_equip: "Type d'équipement",
};

export const options = {
    etiq_cable: { false: 'Non', true: 'Oui' },
    fixation_cable: { false: 'Non', true: 'Oui' },
    presence_boite: { false: 'Non', true: 'Oui' },
    presence_etiquette: { false: 'Non', true: 'Oui' },
    presence_grille: { false: 'Non', true: 'Oui' },
    presence_tampon: { false: 'Non', true: 'Oui' },
    rec_audit: [
        { label: 'Réalisable', value: 'Réalisable' },
        { label: "Problème d'accès", value: "Problème d'accès" },
        { label: 'Problème équipement', value: 'Problème équipement' },
    ],
    statut_audit: [
        { label: 'NON REALISE', value: 'NON REALISE' },
        { label: 'PARTIELLEMENT', value: 'PARTIELLEMENT' },
        { label: 'TERMINE', value: 'TERMINE' },
    ],
    statut_position: [
        { label: 'Correcte', value: 'Correcte' },
        { label: 'Incorrecte', value: 'Incorrecte' },
    ],
};
