import type { ImageDuplicateResponseDto, PredictImageResponseDto } from '@connect-field/client/sdk/generated';
import HTTP from '@connect-field/client/utilities/http';

export async function findImageDuplicates(
    attribute: string,
    image: string,
    layer: string,
    sid: string,
    uuid: string,
): Promise<ImageDuplicateResponseDto> {
    const response = await HTTP.client().AiApi.aiControllerFindImageDuplicate({
        imageDuplicateDto: { attribute, image, layer, sid, uuid },
    });

    if (!response.data) {
        throw new Error('findDuplicates got no data in response');
    }

    return response.data;
}

export async function predictImage(image: string): Promise<PredictImageResponseDto> {
    const response = await HTTP.client().AiApi.aiControllerPredictImage({
        predictImageDto: { image },
    });

    if (!response.data) {
        throw new Error('predictImage got no data in response');
    }

    return response.data;
}
