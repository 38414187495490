import { AxiosError } from 'axios';

import ApiError, { type ApiErrorInterface } from '@connect-field/client/services/apiError.service';
import type { Project, ProjectConfigurationDto } from '@connect-field/client/sdk/generated';
import { convertBufferToJSON } from '@connect-field/client/utilities/tools';
import HTTP from '@connect-field/client/utilities/http';

export interface OptionsInterface {
    bbox: Array<number>;
}

export async function getProjects(): Promise<Array<Project>> {
    const response = await HTTP.client().ProjectApi.projectControllerGetProjects();

    if (!response.data) {
        throw new Error('[getProjects] projects list is empty');
    }

    return response.data;
}

export async function getProjectConfiguration(projectId: number): Promise<ProjectConfigurationDto> {
    const response = await HTTP.client().ProjectApi.projectControllerGetConfiguration({
        projectId,
    });

    if (!response.data) {
        throw new Error('[getProjectConfiguration] project configuration is empty');
    }

    return response.data;
}

export async function getProjectImage(projectId: number): Promise<string | undefined> {
    const response = await HTTP.client().ProjectApi.projectControllerGetImage({
        projectId,
    });

    if (!response.data || response.data.length < 10) {
        return undefined;
    }

    return `data:image/jpeg;base64,${response.data}`;
}

export async function getProjectBasemap(
    projectId: number,
    bbox: Array<number>,
    zoneIds?: Array<number | string>,
): Promise<Uint8Array> {
    try {
        const response = await HTTP.client().ProjectApi.projectControllerGetBaseMap(
            { getBaseMapDto: { bbox, zoneIds }, projectId },
            { responseType: 'arraybuffer' },
        );

        if (!response.data) {
            throw new Error('[getProjectBasemap] basemap is empty');
        }

        return new Uint8Array(response.data as unknown as ArrayBuffer);
    } catch (error: unknown) {
        if (error instanceof AxiosError) {
            if (error?.response?.data instanceof ArrayBuffer) {
                const serializedError = convertBufferToJSON<ApiErrorInterface>(error.response.data);

                throw new ApiError(serializedError);
            }
        }

        throw error;
    }
}

export async function getProjectBbox(projectId: number, zoneIds: Array<number | string>): Promise<Array<number>> {
    const response = await HTTP.client().ProjectApi.projectControllerGetZoneBbox({
        getZoneBboxDto: { zoneIds },
        projectId,
    });

    if (!response.data) {
        throw new Error('[getProjectBbox] Project bbox is empty');
    }

    return response.data as Array<number>;
}

export async function getProjectExtract(
    projectId: number,
    bbox: Array<number>,
    zoneIds?: Array<number | string>,
): Promise<Uint8Array> {
    try {
        const response = await HTTP.client().ProjectApi.projectControllerExtractData(
            { projectId, projectParamsDto: { bbox, zoneIds } },
            { responseType: 'arraybuffer' },
        );

        if (!response.data) {
            throw new Error('[getProjectExtract] extract data is empty');
        }

        return new Uint8Array(response.data as unknown as ArrayBuffer);
    } catch (error: unknown) {
        if (error instanceof AxiosError) {
            if (error?.response?.data instanceof ArrayBuffer) {
                const serializedError = convertBufferToJSON<ApiErrorInterface>(error.response.data);
                throw new ApiError(serializedError);
            }
        }

        throw error;
    }
}

export async function getProjectImageDump(
    projectId: number,
    disableImages: boolean,
    bbox: Array<number>,
    zoneIds?: Array<number | string>,
): Promise<Uint8Array> {
    try {
        const response = await HTTP.client().ProjectApi.projectControllerGenerateProjectImageDump(
            {
                imagesDumpDto: {
                    bbox,
                    disableImages,
                    zoneIds,
                },
                projectId,
            },
            { responseType: 'arraybuffer' },
        );

        if (!response.data) {
            throw new Error('[getProjectImageDump] image dump is empty');
        }

        return new Uint8Array(response.data as unknown as ArrayBuffer);
    } catch (error: unknown) {
        if (error instanceof AxiosError) {
            if (error?.response?.data instanceof ArrayBuffer) {
                const serializedError = convertBufferToJSON<ApiErrorInterface>(error.response.data);
                throw new ApiError(serializedError);
            }
        }

        throw error;
    }
}

export async function getProjectSearchDump(
    projectId: number,
    bbox: Array<number>,
    zoneIds?: Array<number | string>,
): Promise<Uint8Array> {
    try {
        const response = await HTTP.client().ProjectApi.projectControllerGenerateProjectSearchDump(
            { projectId, searchDumpDto: { bbox, zoneIds } },
            { responseType: 'arraybuffer' },
        );

        if (!response.data) {
            throw new Error('[getProjectSearchDump] search dump is empty');
        }

        return new Uint8Array(response.data as unknown as ArrayBuffer);
    } catch (error: unknown) {
        if (error instanceof AxiosError) {
            if (error?.response?.data instanceof ArrayBuffer) {
                const serializedError = convertBufferToJSON<ApiErrorInterface>(error.response.data);
                throw new ApiError(serializedError);
            }
        }

        throw error;
    }
}
