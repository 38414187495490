/*
Store projects configurations
 */

import { acceptHMRUpdate, defineStore } from 'pinia';

import * as ProjectApi from '@connect-field/client/services/api/project';
import { clearStore, getAllKeys, getItem, setStore } from '@connect-field/client/utilities/idb-utility';
import type { Project, ProjectConfigurationDto } from '@connect-field/client/sdk/generated';

const PROJECTS_STORE = 'projects';

export type DisplayModeType = 'grid' | 'list';

// TODO : Need to fix this
export interface ProjectInterface extends Omit<Project, 'id'> {
    id: string;
    online: boolean;
}

export interface LastProjectViewedInterface {
    customName?: string;
    id: string;
    name: string;
    online: boolean;
}

export interface LayerSelection {
    id: number;
    label: string;
    value: string | number;
}

export interface StateInterface {
    displayMode: DisplayModeType;
    displayModeGrid: 'grid';
    displayModeList: 'list';
    isOffline: boolean;
    lastProjectSelected: unknown;
    lastProjectViewed?: LastProjectViewedInterface;
    layerSelectionList: Array<LayerSelection>;
    offlineProjects: Array<OfflineProjectInterface>;
    offlineSelectionStatus: unknown;
    projects: Array<ProjectInterface>;
    projectsLoading: boolean;
    projImage: string | null;
    saveFullProject: boolean;
    selectedLayersOffline: Array<string | number>;
    selectedProject?: ProjectConfigurationDto;
    selectedProjectId?: string;
    validationOfflineDownload: boolean;
    zoneSelection?: unknown;
}

export interface OfflineProjectInterface {
    customName: string;
    id: string;
    name: string;
    offlineBbox: Array<number>;
    parentId: string;
    zoneIds?: Array<number | string>;
}

const useProjectsStore = defineStore('projects', {
    actions: {
        addLayerSelectedLayersOffline(selectedZoneId: number | string): void {
            this.selectedLayersOffline.push(selectedZoneId);
        },

        async addOfflineProject(offlineProject: OfflineProjectInterface): Promise<void> {
            await setStore('offlineProjects', offlineProject, offlineProject.id);
            this.offlineProjects.push(offlineProject);
        },

        async fetchOfflineProject(offlineProjectId: string): Promise<ProjectConfigurationDto | undefined> {
            const project = await getItem<OfflineProjectInterface>(offlineProjectId, 'offlineProjects');
            if (project) {
                const configuration = await getItem<ProjectConfigurationDto>(project.parentId, 'projectConfigurations');

                if (configuration) {
                    this.setProject(configuration, offlineProjectId);
                    // TODO : Ici on reprend la configuration du projet original mais à terme il faudra définir la bbox de la nouvelle zone
                    this.isOffline = true;

                    return configuration;
                }
            }
        },

        async fetchOfflineProjects(): Promise<void> {
            const keys = await getAllKeys('offlineProjects');
            this.offlineProjects = await Promise.all(
                keys.map((key: string) => getItem<OfflineProjectInterface>(key, 'offlineProjects')),
            );
        },

        async fetchProjects(): Promise<void> {
            this.projectsLoading = true;

            try {
                const projectsFromApi = await ProjectApi.getProjects();

                const projects: Array<ProjectInterface> = projectsFromApi.map((project: Project) => {
                    return {
                        ...project,
                        id: project.id.toString(),
                        online: true,
                    };
                });

                this.setProjects(projects);
                this.projectsLoading = false;
                // Setting up the store in indexedDB
                await clearStore(PROJECTS_STORE);
                await Promise.all(
                    projects.map((project: ProjectInterface) => setStore(PROJECTS_STORE, project, project.id)),
                );
                this.setProjects(projects);
            } catch (error: unknown) {
                console.error(error);
                if (error === 'QuotaExceededError') {
                    alert('Attention votre quota de stockage a été dépassé. Contactez votre administrateur');
                }
                this.projectsLoading = false;
            }
        },
        removeLayerSelectedLayersOffline(unselectedZoneId: number | string): void {
            this.selectedLayersOffline = this.selectedLayersOffline.filter((selectedZoneId: string | number) => {
                return selectedZoneId !== unselectedZoneId;
            });
        },
        setLastProjectViewed(id: string | number, name: string, online: boolean, customName?: string): void {
            this.$patch({
                lastProjectViewed: {
                    customName: customName,
                    id: id.toString(),
                    name: name,
                    online: online,
                },
            });
        },
        setProject(project: ProjectConfigurationDto, projectId: string): void {
            this.$patch({
                selectedProject: project,
                selectedProjectId: projectId,
            });
        },
        setProjects(projects: Array<ProjectInterface>): void {
            this.$patch({ projects });
        },
        softReset(): void {
            this.$patch({
                layerSelectionList: [],
                selectedLayersOffline: [],
                selectedProjectId: undefined,
                validationOfflineDownload: false,
            });
        },
    },
    state: (): StateInterface => {
        return {
            displayMode: 'grid',
            displayModeGrid: 'grid',
            displayModeList: 'list',
            isOffline: false,
            lastProjectSelected: {},
            lastProjectViewed: undefined,
            layerSelectionList: [],
            offlineProjects: [],
            offlineSelectionStatus: false,
            projects: [],
            projectsLoading: false,
            projImage: null,
            saveFullProject: false,
            selectedLayersOffline: [],
            selectedProject: undefined,
            selectedProjectId: undefined,
            validationOfflineDownload: false,
            zoneSelection: undefined,
        };
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useProjectsStore, import.meta.hot));
}

export default useProjectsStore;
