<template>
    <FormWrapper
        ref="customFormRef"
        :images-model="images"
        :form-model="form"
        :changes-model="changes"
        :layer-name="layerName"
        :object-id="objectId"
        :project-id="projectId"
        :latitude="latitude"
        :longitude="longitude"
        @update:images-model="
            (e) => {
                images = e;
            }
        "
        @update:form-model="
            (e) => {
                form = e;
            }
        "
        @update:changes-model="
            (e) => {
                changes = e;
            }
        "
    >
        <template #menu_tabs>
            <el-row class="scroll-menu">
                <el-col :span="6">
                    <div
                        class="tabs"
                        :class="{ selectedTab: checkCurrentTab('general') }"
                        @click="changeCurrentTab('general')"
                    >
                        General
                    </div>
                </el-col>
                <el-col :span="6">
                    <div
                        class="tabs"
                        :class="{ selectedTab: checkCurrentTab('cables') }"
                        @click="changeCurrentTab('cables')"
                    >
                        Câbles
                    </div>
                </el-col>
                <el-col :span="6">
                    <div
                        class="tabs"
                        :class="{ selectedTab: checkCurrentTab('tubes') }"
                        @click="changeCurrentTab('tubes')"
                    >
                        Tubes
                    </div>
                </el-col>
                <el-col :span="6">
                    <div
                        class="tabs"
                        :class="{ selectedTab: checkCurrentTab('fibres') }"
                        @click="changeCurrentTab('fibres')"
                    >
                        Fibres
                    </div>
                </el-col>
            </el-row>
        </template>
        <template #main_form>
            <el-row v-if="!loading">
                <el-col
                    v-if="checkCurrentTab('general')"
                    :span="24"
                >
                    <CustomInput
                        label="Code Boite"
                        :value="form.bp_code"
                        disabled
                    />
                    <CustomInput
                        label="Nom du noeud"
                        :value="form.nd_nom"
                        disabled
                    />
                    <CustomInput
                        label="Nom de la voie"
                        :value="form.nd_voie"
                        disabled
                    />
                    <CustomInput
                        label="Type"
                        :value="form.libelle"
                        disabled
                    />
                    <br />
                    <el-button
                        type="primary"
                        @click="openCables"
                        >Afficher le plan de boite</el-button
                    >
                </el-col>

                <el-col
                    v-if="checkCurrentTab('cables')"
                    :span="24"
                >
                    <CablesComponent />
                </el-col>

                <el-col
                    v-if="checkCurrentTab('cable')"
                    :span="24"
                >
                    <CableComponent />
                </el-col>

                <el-col
                    v-if="checkCurrentTab('tubes')"
                    :span="24"
                >
                    <TubeComponent />
                </el-col>

                <el-col
                    v-if="checkCurrentTab('fibres')"
                    :span="24"
                >
                    <FibreComponent />
                </el-col>
            </el-row>
        </template>
    </FormWrapper>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapWritableState } from 'pinia';

import CableComponent from '@connect-field/client/components/forms/PlanBoite/CableComponent.vue';
import CablesComponent from '@connect-field/client/components/forms/PlanBoite/CablesComponent.vue';
import FibreComponent from '@connect-field/client/components/forms/PlanBoite/FibreComponent.vue';
import FormTemplate from '@connect-field/client/components/forms/FormTemplate.vue';
import FormWrapper from '@connect-field/client/pages/home/form/FormWrapper.vue';
import TubeComponent from '@connect-field/client/components/forms/PlanBoite/TubeComponent.vue';

import {
    groupByCables,
    groupByCassette,
} from '@connect-field/client/services/boxPlan.service';
import {
    labels,
    options,
} from '@connect-field/client/components/forms/PlanBoite/PlanBoite.data';
import useBoxPlanStore, {
    type CurrentTabType,
} from '@connect-field/client/stores/boxPlan';
import type { FormDataInterface } from '@connect-field/client/components/forms/form.types';

interface DataInterface extends FormDataInterface {}

export default defineComponent({
    components: {
        CableComponent,
        CablesComponent,
        FibreComponent,
        FormWrapper,
        TubeComponent,
    },
    extends: FormTemplate,
    data(): DataInterface {
        return {
            changes: false,
            form: {},
            labels,
            loading: true,
            mandatoryFields: [],
            options,
            valueMissing: false,
        };
    },
    computed: {
        ...mapWritableState(useBoxPlanStore, {
            cablesData: 'cablesData',
            cassettesData: 'cassettesData',
            currentTab: 'currentTab',
        }),
    },
    mounted() {
        this.currentTab = 'general';
        this.loading = true;
        this.cassettesData = groupByCassette(this.form.plan_boite);
        this.cablesData = groupByCables(this.cassettesData);
        this.loading = false;
    },
    methods: {
        changeCurrentTab(tabName: CurrentTabType): void {
            this.currentTab = tabName;
        },
        checkCurrentTab(tabName: CurrentTabType): boolean {
            return this.currentTab === tabName;
        },

        openCables() {
            this.currentTab = 'cables';
        },
    },
});
</script>

<style lang="scss">
@import '@connect-field/client/pages/home/form/Form.scss';
</style>
