<template>
    <BaseDialog
        :show="showReturnAlert"
        :title="$t('projectDetail.backToProjects')"
        @close="cancelBackToProjects"
    >
        <p>{{ $t('projectDetail.backToProjectsQuestion') }}</p>
        <template #actions>
            <BaseDialogButton
                :label="$t('globals.cancel')"
                button-type="default"
                @click="cancelBackToProjects"
            />
            <BaseDialogButton
                :label="$t('projectDetail.backToProjectsAction')"
                button-type="danger"
                @click="confirmBackToProjects"
            />
        </template>
    </BaseDialog>

    <header v-if="currentRoute.name === 'offlineProject'">
        <h3 class="title">
            {{ projectTitle }}
        </h3>
        <button
            class="btn--back custom_link"
            @click="projectLink"
        >
            {{ $t('projectDetail.title') }}
        </button>
    </header>
    <main
        v-loading="isLoading"
        class="main"
        :element-loading-text="$t('projectDetail.backToProjectsAction')"
        element-loading-spinner="el-icon-loading"
        element-loading-background="transparent"
    >
        <router-view
            v-if="!isLoading"
            v-slot="slotProps"
        >
            <component
                :is="slotProps.Component"
                :key="slotProps.route"
            />
        </router-view>
    </main>
</template>

<script lang="ts">
import type BaseLayer from 'ol/layer/Base';
import { defineComponent } from 'vue';
import type Map from 'ol/Map';
import { mapState } from 'pinia';
import type TileLayer from 'ol/layer/Tile';
import type VectorTileLayer from 'ol/layer/VectorTile';
import type XYZ from 'ol/source/XYZ';

import {
    generateGeoJsonOfflineLayer,
    generateMBTilesLayer,
    type OfflineGeoJSONLayerConfiguration,
    type OfflineMBTilesLayerConfiguration,
} from '@connect-field/client/services/layers/offlineLayers.service';
import {
    loadSearchDatabase,
    unloadFile,
} from '@connect-field/client/services/offlineSearch.service';
import {
    ProjectConfigurationLayerFormatEnum,
    ProjectConfigurationLayerTypeEnum,
} from '@connect-field/client/sdk/generated';
import {
    removeHighlightedFeature,
    removePinOnMap,
    resetView,
    setPinOnMap,
    zoomOnBbox,
} from '@connect-field/client/services/map.service';
import useLayersStore, {
    type LayerProperties,
} from '@connect-field/client/stores/layers';
import useProjectsStore, {
    type OfflineProjectInterface,
} from '@connect-field/client/stores/projects';
import { applyStyle } from '@connect-field/client/services/styles.service';
import BaseDialog from '@connect-field/client/components/ui/BaseDialog.vue';
import BaseDialogButton from '@connect-field/client/components/ui/BaseDialogButton.vue';
import { getItem } from '@connect-field/client/utilities/idb-utility';
import useCoordinateStore from '@connect-field/client/stores/coordinate';
import useMapStore from '@connect-field/client/stores/map';
import useMenuStore from '@connect-field/client/stores/menu';
import useNavigationStore from '@connect-field/client/stores/navigation';

interface DataInterface {
    isLoading: boolean;
    showReturnAlert: boolean;
}

export default defineComponent({
    components: {
        BaseDialog,
        BaseDialogButton,
    },
    props: {
        projectId: {
            type: String,
            required: true,
        },
    },
    setup() {
        return {
            layersStore: useLayersStore(),
            mapStore: useMapStore(),
            menuStore: useMenuStore(),
            projectsStore: useProjectsStore(),
        };
    },
    data(): DataInterface {
        return {
            isLoading: false,
            showReturnAlert: false,
        };
    },
    computed: {
        ...mapState(useCoordinateStore, {
            coordinate: 'coordinate',
        }),
        ...mapState(useNavigationStore, {
            currentRoute: 'currentRoute',
        }),
        ...mapState(useProjectsStore, {
            selectedProject: 'selectedProject',
            selectedProjectId: 'selectedProjectId',
        }),
        map(): Map {
            if (!this.mapStore.map) {
                throw new Error('Map is undefined');
            }

            return this.mapStore.map as Map;
        },
        projectTitle() {
            return this.selectedProject?.name;
        },
    },
    async mounted() {
        this.isLoading = true;
        try {
            removePinOnMap();
            this.menuStore.$patch({ displayMenu: true });

            // Here we need to reload the layers only if the page is refresh or if it's a new project loaded
            if (
                !this.selectedProjectId ||
                this.selectedProjectId.toString() !== this.projectId.toString()
            ) {
                this.projectsStore.$patch({ isOffline: true });
                const projectConfiguration =
                    await this.projectsStore.fetchOfflineProject(
                        this.projectId,
                    );

                if (!projectConfiguration) {
                    throw new Error('Project configuration not found');
                }

                this.removeLayers();

                await this.applyLayersProject(this.projectId);

                const { offlineBbox } = await getItem<OfflineProjectInterface>(
                    this.projectId,
                    'offlineProjects',
                );
                zoomOnBbox(offlineBbox);

                if (this.coordinate) {
                    setPinOnMap(this.coordinate);
                }
            }
        } catch (e) {
            this.$router.back();
        } finally {
            this.isLoading = false;
        }
    },
    methods: {
        async applyLayersProject(projectId: string): Promise<void> {
            this.isLoading = true;

            // Hide online background layers
            this.map.getLayers().forEach((layer: BaseLayer) => {
                if (
                    layer.getProperties().isBackgroundLayer &&
                    layer.getProperties().online
                ) {
                    layer.setVisible(false);
                }
            });

            const searchDB = await getItem<Uint8Array>(
                projectId,
                'projectSearch',
            );
            await loadSearchDatabase(searchDB);

            const layers = [];

            // Map background
            const basemap = await getItem<Uint8Array>(
                projectId,
                'projectBasemaps',
            );
            if (basemap.byteLength > 0) {
                const properties: LayerProperties = {
                    alias: 'Fond de plan',
                    category: null,
                    enableCreation: false,
                    enableEdition: false,
                    form: null,
                    format: ProjectConfigurationLayerFormatEnum.PBF,
                    global: true,
                    hiddenLegend: false,
                    id: `basemap-${projectId}`,
                    isBackgroundLayer: true,
                    name: 'background_offline',
                    online: false,
                    table: 'background_offline',
                    type: ProjectConfigurationLayerTypeEnum.DEFAULT,
                };

                const loadedBasemap = await generateMBTilesLayer(
                    basemap,
                    {
                        basemap: true,
                        declutter: true,
                        zIndex: -1,
                    },
                    properties,
                );
                if (loadedBasemap) {
                    layers.push(loadedBasemap);
                }
            }

            // Forms
            const geojsons = await getItem<
                Array<OfflineGeoJSONLayerConfiguration>
            >(projectId, 'projectGeojsons');
            geojsons.forEach((geojson: OfflineGeoJSONLayerConfiguration) => {
                if (!geojson.data) {
                    console.debug('WARNING : Geojson without data', geojson);

                    return;
                }

                const vectorLayer = generateGeoJsonOfflineLayer(geojson);

                applyStyle(vectorLayer);
                layers.push(vectorLayer);
            });

            // Viewable data
            const mbtilesLayers = await getItem<
                Array<OfflineMBTilesLayerConfiguration>
            >(projectId, 'projectMbtiles');

            await Promise.all(
                mbtilesLayers.map(
                    async (mbtiles: OfflineMBTilesLayerConfiguration) => {
                        const { data, ...layerProperties } = mbtiles;

                        if (data.byteLength <= 0) {
                            console.warn(
                                '[offlineProject] This project has no context data',
                                mbtiles,
                            );

                            return;
                        }
                        const properties: LayerProperties = {
                            ...layerProperties,
                            global: false,
                            id: `mbtiles-${projectId}-${layerProperties.id}`,
                            online: false,
                            // With mbTiles, the source layer name is set as the layer name
                            sourceLayer: layerProperties.name,
                        };

                        const loadedMbtiles = await generateMBTilesLayer(
                            data,
                            {
                                basemap: false,
                                declutter: true,
                                zIndex: mbtiles.zIndex,
                            },
                            properties,
                        );

                        if (loadedMbtiles) {
                            layers.push(loadedMbtiles);
                        }
                    },
                ),
            );

            layers.forEach((layer: VectorTileLayer | TileLayer<XYZ>) => {
                this.map.addLayer(layer);
            });

            const layersMap = this.map
                .getLayers()
                .getArray()
                .filter((layer: BaseLayer) => !layer.getProperties().global);

            this.map.getView().changed();
            this.isLoading = false;
            this.layersStore.$patch({ layers: layersMap });
        },
        cancelBackToProjects(): void {
            this.showReturnAlert = false;
        },
        confirmBackToProjects(): void {
            unloadFile();
            removePinOnMap();
            removeHighlightedFeature();
            this.removeLayers();
            this.map
                .getLayers()
                .getArray()
                .forEach((layer: BaseLayer) => {
                    if (layer.getProperties()?.name === 'background_OSM') {
                        layer.setVisible(true);
                    }
                    layer.setOpacity(1);
                });
            resetView();

            this.showReturnAlert = false;
            this.projectsStore.$patch((state) => {
                state.lastProjectSelected = this.selectedProject;
                state.selectedProject = undefined;
                state.selectedProjectId = undefined;
                state.isOffline = false;
            });
            this.$router.push({ name: 'projects' });
        },
        // TODO : Inexplicable function, fix it
        projectLink(): void {
            this.showReturnAlert = true;
        },
        removeLayers(): void {
            this.map
                .getLayers()
                .getArray()
                .filter(
                    (layer: BaseLayer) =>
                        !layer.getProperties().global ||
                        (layer.getProperties().global &&
                            !layer.getProperties().online &&
                            layer.getProperties().isBackgroundLayer),
                )
                .forEach((layer: BaseLayer) => {
                    this.map.removeLayer(layer);
                });
        },
    },
});
</script>

<style scoped lang="scss">
@import 'OfflineProjectDetail.scss';
</style>
