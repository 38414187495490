import EXIF from 'exif-js';

export class CameraSource {
    /**
     * Take a new photo using the camera.
     */
    public static Camera = 'CAMERA';
    /**
     * Pick an existing photo from the gallery.
     */
    public static Photos = 'PHOTOS';
    /**
     * Prompts the user to select either the photo album or take a photo.
     */
    public static Prompt = 'PROMPT';
}

export class CameraDirection {
    public static Front = 'FRONT';
    public static Rear = 'REAR';
}

export class CameraResultType {
    public static Base64 = 'base64';
    public static DataUrl = 'dataUrl';
    public static Uri = 'uri';
}

interface ImageOptions {
    direction: string;
    resultType: string;
    source: string;
}

export interface PhotoResult {
    base64String?: string;
    dataUrl?: string;
    exif: Record<string, unknown>;
    format: string;
    webPath?: string;
}

export class Photo {
    private readonly direction: string;
    private input?: HTMLInputElement;
    private readonly resultType: string;
    private readonly source: string;

    public constructor(options: ImageOptions) {
        this.direction = options.direction;
        this.resultType = options.resultType;
        this.source = options.source;
    }

    public take(resolve: (args: PhotoResult) => void): void {
        this.input = document.querySelector('#_capacitor-camera-input') as HTMLInputElement;
        this.cleanup();

        this.input = document.createElement('input');
        this.input.id = '_capacitor-camera-input';
        this.input.type = 'file';
        this.input.hidden = true;
        document.body.appendChild(this.input);
        this.input.addEventListener('change', () => {
            if (!this.input || !this.input.files) {
                return;
            }

            const file = this.input.files[0];
            let format = 'jpeg';
            if (file.type === 'image/png') {
                format = 'png';
            } else if (file.type === 'image/gif') {
                format = 'gif';
            }

            if (this.resultType === CameraResultType.Uri) {
                this.photoResolver(file, null, format, resolve);
                this.cleanup();
            } else {
                const reader = new FileReader();
                reader.addEventListener('load', () => {
                    this.photoResolver(file, reader.result, format, resolve);
                    this.cleanup();
                });
                reader.readAsDataURL(file);
            }
        });

        this.input.accept = 'image/*';
        this.input.capture = 'environment';
        if (this.source === CameraSource.Photos || this.source === CameraSource.Prompt) {
            this.input.removeAttribute('capture');
        } else if (this.direction === CameraDirection.Front) {
            this.input.capture = 'user';
        } else if (this.direction === CameraDirection.Rear) {
            this.input.capture = 'environment';
        }
        this.input.click();
    }

    private cleanup(): void {
        if (!this.input) {
            return;
        }

        let parentNode;
        (parentNode = this.input.parentNode) === null || parentNode === void 0
            ? void 0
            : parentNode.removeChild(this.input);
    }

    private photoResolver(
        file: File,
        result: string | ArrayBuffer | null,
        format: string,
        callback: (args: PhotoResult) => void,
    ): void {
        const binaryReader = new FileReader();

        binaryReader.addEventListener('load', () => {
            const exif = EXIF.readFromBinaryFile(binaryReader.result);

            switch (this.resultType) {
                case CameraResultType.DataUrl:
                    callback({
                        dataUrl: result,
                        exif,
                        format,
                    } as PhotoResult);
                    break;
                case CameraResultType.Base64: {
                    if (!result || !(result instanceof String)) {
                        throw new Error('Impossible to retrieve base64');
                    }

                    const b64 = result.split(',')[1];
                    callback({
                        base64String: b64,
                        exif,
                        format,
                    } as PhotoResult);
                    break;
                }
                case CameraResultType.Uri:
                    callback({
                        exif,
                        format: format,
                        webPath: URL.createObjectURL(file),
                    } as PhotoResult);
                    break;
                default:
                    break;
            }
        });

        binaryReader.readAsArrayBuffer(file);
    }
}
