import type { OptionsInterface, SelectInterface } from '@connect-field/client/components/forms/form.types';

export const labels: Record<string, string> = {
    date_pose: 'Date de pose',
    cable: 'Photo Câble',
    etiquette: 'Photo Étiquette',
    environnement: 'Photo Environnement',
    tiraer_accroch: "Défaut d'accrochage",
    tiraer_bdx_vert: 'Boîtier',
    tiraer_cable_capa: 'Capacité du câble',
    tiraer_cable_code: 'Nom du câble',
    tiraer_cable_sta: 'Statut du câble',
    tiraer_code: 'Nom du Point Technique',
    tiraer_com_global: 'Commentaire global',
    tiraer_croix: 'Croix de lovage',
    tiraer_elag: 'Non élagage',
    tiraer_etq_cab: 'Étiquetage du câble',
    tiraer_etq_pot: 'Étiquetage du poteau',
    tiraer_fleche: 'Flèche',
    tiraer_gene: 'Généraliser à tous les câbles du support',
    tiraer_pince: 'Pince',
    tiraer_ptech_code: 'Gestionnaire du Point Technique',
    tiraer_rem_aero: 'Remonte Aéro-sout',
    tiraer_ri: 'Respect RI',
    tiraer_ri_cmt: 'Commentaire RI',
    tiraer_secu: 'Sécurité du poteau',
    tiraer_statut_audit: "Statut de l'audit",
    tiraer_trav: 'Réhausse / Traverse',
    tiraer_type_audit: "Type de l'audit",
};

export const options: Record<string, OptionsInterface | SelectInterface> = {
    tiraer_accroch: [
        { label: 'DEFAUT ACCROCHAGE', value: 'DEFAUT ACCROCHAGE' },
        { label: 'OK', value: 'OK' },
    ],
    tiraer_bdx_vert: [
        { label: 'NON CONCERNE', value: 'NON CONCERNE' },
        { label: 'DEFAUT', value: 'DEFAUT' },
        { label: 'ABSENT', value: 'ABSENT' },
        { label: 'À REMPLACER', value: 'A REMPLACER' },
        { label: 'À RAJOUTER', value: 'A RAJOUTER' },
        { label: 'OK', value: 'OK' },
    ],
    tiraer_croix: [
        { label: 'DEFAUT', value: 'DEFAUT' },
        { label: 'ABSENT', value: 'ABSENT' },
        { label: 'OK', value: 'OK' },
        { label: 'LOVE INTERDITE', value: 'LOVE INTERDITE' },
    ],
    tiraer_elag: [
        { label: 'OK', value: 'OK' },
        { label: 'BRANCHAGE NOK', value: 'BRANCHAGE NOK' },
        { label: 'ELAGAGE NOK', value: 'ELAGAGE NOK' },
    ],
    tiraer_etq_cab: [
        { label: 'ABSENT', value: 'ABSENT' },
        { label: 'DEFAUT COULEUR', value: 'DEFAUT COULEUR' },
        { label: 'DEFAUT ETIQUETTE', value: 'DEFAUT ETIQUETTE' },
        { label: 'ETIQUETTE PROVISOIRE', value: 'ETIQUETTE PROVISOIRE' },
        { label: 'INCOHERENCE', value: 'INCOHERENCE' },
        { label: 'NON CONCERNE', value: 'NON CONCERNE' },
        { label: 'OK', value: 'OK' },
    ],
    tiraer_etq_pot: [
        { label: 'ABSENT', value: 'ABSENT' },
        { label: 'DEFAUT COULEUR', value: 'DEFAUT COULEUR' },
        { label: 'DEFAUT ETIQUETTE', value: 'DEFAUT ETIQUETTE' },
        { label: 'ETIQUETTE PROVISOIRE', value: 'ETIQUETTE PROVISOIRE' },
        { label: 'INCOHERENCE', value: 'INCOHERENCE' },
        { label: 'NON CONCERNE', value: 'NON CONCERNE' },
        { label: 'OK', value: 'OK' },
    ],
    tiraer_fleche: [
        { label: 'CABLE TROP TENDU', value: 'CABLE TROP TENDU' },
        { label: 'CABLE TROP DETENDU', value: 'CABLE TROP DETENDU' },
        { label: 'CROISEMENT NAPPE', value: 'CROISEMENT NAPPE' },
    ],
    tiraer_gene: { false: 'OUI', true: 'NON' },
    tiraer_pince: [
        { label: 'DEFAUT PINCE', value: 'DEFAUT PINCE' },
        { label: 'OK', value: 'OK' },
    ],
    tiraer_rem_aero: [
        { label: 'OK', value: 'OK' },
        { label: 'NON CONCERNE', value: 'NON CONCERNE' },
        { label: 'ABSENT', value: 'ABSENT' },
        { label: 'DEFAUT FIXATION', value: 'DEFAUT FIXATION' },
        { label: 'DEFAUT SUPPORT BOITIER', value: 'DEFAUT SUPPORT BOITIER' },
    ],
    tiraer_ri: [
        { label: 'NON RESPECT', value: 'NON RESPECT' },
        { label: 'CÂBLE COUPÉ', value: 'CABLE COUPE' },
        { label: 'CÂBLE ABÎMÉ', value: 'CABLE ABIME' },
        { label: 'INCOHÉRENCE CAPACITÉ CÂBLE', value: 'INCOHERENCE CAPACITE CABLE' },
        { label: 'CÂBLE DÉCROCHÉ', value: 'CABLE DECROCHE' },
        { label: 'CÂBLE ABSENT', value: 'CABLE ABSENT' },
        { label: 'SÉLECTION VALIDE', value: 'SELECTION VALIDE' },
        { label: 'OK', value: 'OK' },
    ],
    tiraer_secu: [
        { label: 'A REDRESSER', value: 'A REDRESSER' },
        { label: 'A RENFORCER', value: 'A RENFORCER' },
        { label: 'MAUVAIS SUPPORT', value: 'MAUVAIS SUPPORT' },
        { label: 'OK', value: 'OK' },
        { label: 'POTEAU ABSENT', value: 'POTEAU ABSENT' },
        { label: 'TIRAGE SUR ETIQUETTE JAUNE', value: 'TIRAGE SUR ETIQUETTE JAUNE' },
        { label: 'VISUELLEMENT A REMPLACER', value: 'VISUELLEMENT A REMPLACER' },
    ],
    tiraer_statut_audit: [
        { label: 'NON RÉALISÉ', value: 'NON REALISE' },
        { label: 'PARTIELLEMENT', value: 'PARTIELLEMENT' },
        { label: 'TERMINÉ AVEC RESERVE', value: 'TERMINE AVEC RESERVE' },
        { label: 'TERMINÉ SANS RESERVE', value: 'TERMINE SANS RESERVE' },
    ],
    tiraer_trav: [
        { label: 'ABSENCE DE TRAVERSE', value: 'ABSENCE DE TRAVERSE' },
        { label: 'ABSENCE DE REHAUSSE', value: 'ABSENCE DE REHAUSSE' },
        { label: 'DEFAUT DE TRAVERSE', value: 'DEFAUT DE TRAVERSE' },
        { label: 'DEFAUT REHAUSSE', value: 'DEFAUT REHAUSSE' },
        { label: 'OK', value: 'OK' },
    ],
    tiraer_type_audit: { false: 'RECETTE', true: 'CONTROLE' },
};
