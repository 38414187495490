<template>
    <div
        v-if="!isLoading"
        class="form-container"
    >
        <header
            ref="panel"
            class="form-header"
        >
            <div class="navbar">
                <div class="button_back">
                    <el-button
                        :icon="Back"
                        :disabled="isSaving"
                        type="primary"
                        @click="projectLink"
                    />
                </div>
                <div class="title_header">
                    <h3 class="title">
                        {{ layerData ? layerData.alias : '' }}
                    </h3>
                </div>
                <div class="submit_header">
                    <el-button
                        :disabled="isSaving || !changes"
                        :icon="Check"
                        type="primary"
                        @click="submitForm"
                    />
                </div>
            </div>
            <div class="menu_header">
                <slot name="menu_tabs" />
            </div>
        </header>
        <main class="form-main">
            <div class="main-layout">
                <BaseDialog
                    :show="showCloseAlert"
                    :title="$t('form.confirmClosing')"
                >
                    <p>{{ $t('form.closeWithoutSaving') }}</p>
                    <p class="font-bold text-[#f56c6c]">
                        {{ $t('form.changesWillBeLost') }}
                    </p>
                    <br />
                    <p class="font-bold">
                        {{ $t('form.confirmClosingQuestion') }}
                    </p>
                    <template #actions>
                        <BaseDialogButton
                            :label="$t('globals.cancel')"
                            button-type="default"
                            @click="handleClose"
                        />
                        <BaseDialogButton
                            :label="$t('form.removeDraft')"
                            button-type="danger"
                            @click="handleConfirm"
                        />
                    </template>
                </BaseDialog>
                <BaseDialog
                    :show="showContinueAlert"
                    title="$t('form.confirmDraft')"
                >
                    <p>{{ $t('form.draftExists') }}</p>
                    <p class="font-bold">
                        {{ $t('form.getDraftQuestion') }}
                    </p>
                    <template #actions>
                        <BaseDialogButton
                            :label="$t('globals.keep')"
                            button-type="default"
                            @click="keepModifications"
                        />
                        <BaseDialogButton
                            :label="$t('globals.cancel')"
                            button-type="default"
                            @click="loseModifications"
                        />
                    </template>
                </BaseDialog>
                <el-form :model="form">
                    <slot name="main_form" />
                </el-form>
            </div>
        </main>
    </div>
</template>

<script lang="ts">
import { Back, Check } from '@element-plus/icons-vue';
import { defineComponent, type PropType } from 'vue';
import type { NavigationFailure, RouteLocationRaw } from 'vue-router';
import { ElMessage } from 'element-plus';
import { mapState } from 'pinia';

import {
    DraftFormService,
    fetchObjectImageOnline,
    getObjectFromForm,
    OfflineFormService,
    removeLastActionLocalStorage,
} from '@connect-field/client/services/form.service';
import {
    type ObjectDataInterface,
    removePinOnMap,
} from '@connect-field/client/services/map.service';
import {
    reloadLayerSource,
    updateFeatureToLayerSource,
} from '@connect-field/client/services/layers.service';
import useObjectStore, {
    type DataFormPropertiesInterface,
} from '@connect-field/client/stores/object';
import BaseDialog from '@connect-field/client/components/ui/BaseDialog.vue';
import BaseDialogButton from '@connect-field/client/components/ui/BaseDialogButton.vue';
import { deepCopy } from '@connect-field/client/utilities/tools';
import type { FormDataInterface } from '@connect-field/client/components/forms/form.types';
import { getItem } from '@connect-field/client/utilities/idb-utility';
import useBannerStore from '@connect-field/client/stores/banner';
import useMapStore from '@connect-field/client/stores/map';
import useMenuStore from '@connect-field/client/stores/menu';
import useNavigationStore from '@connect-field/client/stores/navigation';
import usePanelStore from '@connect-field/client/stores/panel';
import useProjectsStore from '@connect-field/client/stores/projects';

interface DataInterface {
    _latitude?: number;
    _longitude?: number;
    containsEmptyImageFields?: boolean;
    creationSid?: string;
    draftId?: string;
    isLoading: boolean;
    isSaving: boolean;
    missingValue: boolean;
    objectId?: string;
    showCloseAlert: boolean;
    showContinueAlert: boolean;
}

export default defineComponent({
    components: {
        BaseDialog,
        BaseDialogButton,
    },
    beforeRouteLeave(to, from, next) {
        if (this.changes) {
            this.showCloseAlert = true;
            next(false);
        } else {
            next();
        }
        removeLastActionLocalStorage();
    },
    props: {
        changesModel: {
            type: Boolean,
            required: true,
        },
        formModel: {
            type: Object,
            required: true,
        },
        imagesModel: {
            type: Object,
            required: true,
        },
        latitude: {
            type: String,
            default: '',
        },
        layerName: {
            type: String,
            required: true,
        },
        longitude: {
            type: String,
            default: '',
        },
        mandatoryFields: {
            type: Array as PropType<Array<string>>,
            default: () => [],
        },
        objectId: {
            type: [String, Number] as PropType<string | number>,
            default: '',
        },
        projectId: {
            type: [String, Number],
            required: true,
        },
    },
    emits: [
        'update:imagesModel',
        'update:formModel',
        'update:changesModel',
        'update:valueMissing',
    ],
    setup() {
        return {
            Back,
            bannerStore: useBannerStore(),
            Check,
            menuStore: useMenuStore(),
            navigationStore: useNavigationStore(),
            objectStore: useObjectStore(),
            panelStore: usePanelStore(),
        };
    },
    data(): DataInterface {
        return {
            isLoading: true,
            isSaving: false,
            missingValue: false,
            showCloseAlert: false,
            showContinueAlert: false,
        };
    },
    computed: {
        ...mapState(useObjectStore, {
            dataForm: 'dataForm',
            getCoordinate: 'coordinate',
            getFeature: 'Feature',
            layerData: (store) => {
                if (!store.layerData) {
                    console.warn('layerData is undefined');
                }

                return store.layerData;
            },
        }),
        ...mapState(useMapStore, {
            map: (store) => {
                if (!store.map) {
                    throw new Error('Map is undefined');
                }

                return store.map;
            },
        }),
        ...mapState(useProjectsStore, {
            selectedProjectId: 'selectedProjectId',
        }),
        ...mapState(useNavigationStore, {
            previousRoute: 'previousRoute',
        }),
        changes: {
            get(): boolean {
                return this.changesModel;
            },
            set(changesModel: boolean): void {
                this.$emit('update:changesModel', changesModel);
            },
        },
        form: {
            get(): Record<string, unknown> {
                return this.formModel;
            },
            set(formModel: Record<string, unknown>): void {
                this.$emit('update:formModel', formModel);
            },
        },
        images: {
            get(): Record<string, unknown> {
                return this.imagesModel;
            },
            set(imagesModel: Record<string, unknown>): void {
                this.$emit('update:imagesModel', imagesModel);
            },
        },
        objects(): Array<ObjectDataInterface> {
            return this.navigationStore
                .currentObjects as Array<ObjectDataInterface>;
        },
    },
    async mounted() {
        this.menuStore.$patch({ displayMenu: false });
        this.panelStore.setPanelFullyOpened();

        removePinOnMap();
        if (this.isCreationMode()) {
            if (this.latitude && this.longitude) {
                try {
                    this._longitude = parseFloat(this.longitude);
                    this._latitude = parseFloat(this.latitude);
                    this.creationSid = Date.now().toString();
                } catch (error: unknown) {
                    console.error(error);
                }
            } else {
                console.error(
                    '[FormWrapper] latitude and longitude are missing in creation mode',
                );
            }
        } else {
            if (!this.dataForm || Object.keys(this.dataForm).length < 1) {
                console.error('[formWrapper] dataForm undefined');
                if (this.isOffline()) {
                    return this.$router.push({
                        name: 'objectListOffline',
                        params: { id: this.projectId },
                    });
                } else {
                    return this.$router.push({
                        name: 'objectList',
                        params: { id: this.projectId },
                    });
                }
            }
            if (this.objectId) {
                if (!this.isOffline()) {
                    if (this.dataForm.aggregatedProperties) {
                        await Promise.all(
                            this.dataForm.aggregatedProperties.map(
                                async (
                                    aggregate: DataFormPropertiesInterface,
                                ) => {
                                    this.images[aggregate.sid] =
                                        await fetchObjectImageOnline(
                                            this.layerName,
                                            aggregate.sid,
                                            this.images[aggregate.sid],
                                        );
                                },
                            ),
                        );
                    }
                } else {
                    if (this.dataForm.aggregatedProperties) {
                        const savedDataForm = await getItem<FormDataInterface>(
                            `${this.projectId}/${this.layerData.name}/${this.dataForm.sid}`,
                            'savedDataForms',
                        );
                        if (savedDataForm?.form?.images) {
                            this.images = savedDataForm.form.images;
                        }
                        const imagesFromDump: Record<
                            string | number,
                            Array<unknown>
                        > = {};

                        await Promise.all(
                            this.dataForm.aggregatedProperties.map(
                                async (
                                    aggregate: DataFormPropertiesInterface,
                                ) => {
                                    imagesFromDump[aggregate.sid] =
                                        await OfflineFormService.fetchImages(
                                            aggregate.sid,
                                            this.layerName,
                                        );
                                },
                            ),
                        );

                        Object.keys(imagesFromDump).forEach((key: string) => {
                            if (!Array.isArray(this.images[key])) {
                                this.images[key] = imagesFromDump[key];
                            } else {
                                this.images[key] = this.images[key].concat(
                                    imagesFromDump[key],
                                );
                            }
                        });
                    }
                }
            } else {
                console.error('[FormWrapper] objectId is missing in edit mode');
            }
        }
        const draftForm = await DraftFormService.get(
            this.layerData.name,
            this.dataForm.sid,
        );
        if (draftForm) {
            this.showContinueAlert = true;
        }
        this.isLoading = false;
    },
    methods: {
        checkMissingValues(): void {
            if (!this.mandatoryFields) {
                return;
            }

            const missingFields = this.mandatoryFields.reduce(
                (acc: Array<string>, field: string) => {
                    if (
                        typeof this.form[field] === 'undefined' ||
                        this.form[field] === null
                    ) {
                        acc.push(field);
                    }

                    return acc;
                },
                [],
            );
            this.missingValue = missingFields.length > 0;

            const imageValues = Object.values(this.images);
            this.containsEmptyImageFields = imageValues.every(
                (image) => image.length <= 0,
            );

            if (this.containsEmptyImageFields) {
                ElMessage({
                    duration: 5000,
                    message: 'Attention, le boîtier ne contient aucune photo', // TODO : i18n
                    offset: 30,
                    showClose: true,
                    type: 'warning',
                });
            }
            if (this.missingValue) {
                ElMessage({
                    duration: 5000,
                    message: `Attention, certains champs sont manquants : ${missingFields.join(
                        ', ',
                    )}`, // TODO : i18n
                    offset: this.containsEmptyImageFields ? 65 : 30,
                    showClose: true,
                    type: 'warning',
                });
            }
            this.$emit('update:valueMissing', this.missingValue);
        },
        getPreviousRoute(): RouteLocationRaw {
            return this.previousRoute || { name: 'projects' };
        },
        handleClose(): void {
            this.showCloseAlert = false;
        },

        handleConfirm(): void {
            this.changes = false;
            this.showCloseAlert = false;

            if (this.draftId) {
                DraftFormService.remove(this.draftId);
                this.draftId = undefined;
            }

            this.$router.push(this.getPreviousRoute());
        },
        isCreationMode(): boolean {
            return Boolean(this.$route?.meta?.creation);
        },
        isFormOver(): boolean {
            // TODO : faire une fonction de validation de l'état du formulaire pour savoir si ce dernier est en statut terminé
            return false;
        },
        isOffline(): boolean {
            return Boolean(this.$route?.meta?.offline);
        },
        async keepModifications(): Promise<void> {
            if (!this.dataForm) {
                throw new Error('DataForm undefined');
            }

            const draftForm = await DraftFormService.get(
                this.layerData.name,
                this.dataForm.sid,
            );
            if (!draftForm) {
                throw new Error('draftForm is null');
            }

            this.draftId = `${this.layerData.name}-${this.dataForm.sid}`;
            this.form = DraftFormService.apply(this.dataForm, draftForm);
            const imageKeys = Object.keys(this.images);
            for (let element in draftForm.dataForm) {
                imageKeys.forEach((key: string) => {
                    if (key === element) {
                        this.images[key] = draftForm.dataForm[element];
                    }
                });
            }
            this.showContinueAlert = false;
            this.changes = true;
        },
        loseModifications(): void {
            this.showContinueAlert = false;

            if (this.dataForm && this.layerData) {
                this.draftId = `${this.layerData.name}-${this.dataForm.sid}`;
                DraftFormService.remove(this.draftId);
            }

            this.changes = false;
        },
        projectLink(): void {
            if (this.changes) {
                this.showCloseAlert = true;
            } else if (!this.changes) {
                this.$router.push(this.getPreviousRoute());
            }
        },
        async save(): Promise<void> {
            if (!this.layerData) {
                throw new Error('[formWrapper/save] LayerData is undefined');
            }

            try {
                this.checkMissingValues();
                const isOffline = this.isOffline();
                const projectId = this.selectedProjectId;

                const form = deepCopy(this.form);
                if (this.isCreationMode()) {
                    form._lat = this.latitude;
                    form._lng = this.longitude;
                }

                await this.objectStore.save(form, isOffline, projectId);

                this.navigationStore.updateObjectDataForm({
                    dataForm: this.form,
                    layerData: this.layerData,
                });

                const object = getObjectFromForm(
                    this.objects,
                    this.layerData.name,
                    this.form.sid,
                );
                if (object && object?.Feature?.getId) {
                    const objectId = object.Feature.getId();

                    if (isOffline) {
                        updateFeatureToLayerSource(
                            this.layerData.layerId,
                            objectId,
                            object,
                        );
                        await OfflineFormService.updateFeatureProperties(
                            this.projectId,
                            this.layerData.layerId,
                            objectId,
                            object,
                        );
                    } else {
                        reloadLayerSource(this.layerData.layerId);
                    }
                }

                // TODO : add navigation/currentObjects
                this.objectStore.$patch({ objects: this.objects });
                this.isSaving = false;
                this.bannerStore.resetBanner();
                await this.bannerStore.showBannerAction({
                    isClosable: true,
                    message: 'Le formulaire a bien été sauvegardé', // TODO : i18n
                    type: 'success',
                });
                this.changes = false;
            } catch (error: unknown) {
                console.error('[form] Error', error);
                let errorMessage = '';
                if (error === 'QuotaExceededError') {
                    errorMessage =
                        'Votre mémoire cache est pleine. Merci de vider votre cache. Si le problème se reproduit, contactez votre administrateur'; // TODO : i18n
                }
                this.isSaving = false;
                this.bannerStore.resetBanner();
                await this.bannerStore.showBannerAction({
                    isClosable: true,
                    message: `La sauvegarde a échoué. ${errorMessage}`, // TODO : i18n
                    type: 'error',
                });
                this.changes = true;
                throw error;
            }
        },
        // eslint-disable-next-line vue/no-unused-properties
        async saveTemporaryDataForm(
            key: string,
            newValue: unknown,
        ): Promise<void> {
            if (this.isCreationMode()) {
                this.draftId = await DraftFormService.saveOnCreation({
                    key,
                    newValue,
                    sid: this.creationSid,
                });
            } else {
                this.draftId = await DraftFormService.save(key, newValue);
            }
        },
        async submitForm(): Promise<void | NavigationFailure> {
            this.isSaving = true;
            await this.bannerStore.showBannerAction({
                isClosable: false,
                message: 'Sauvegarde en cours', // TODO : i18n
                type: 'info',
            });
            // TODO : Définir les rules pour la validation

            if (Array.isArray(this.form.aggregatedProperties)) {
                Object.keys(this.images).forEach((key: string) => {
                    const index = this.form.aggregatedProperties.findIndex(
                        (r: DataFormPropertiesInterface) =>
                            r.sid.toString() === key,
                    );
                    if (index > -1) {
                        this.form.aggregatedProperties[index].images =
                            this.images[key];
                    }
                });
            }

            await this.save();

            if (this.draftId) {
                await DraftFormService.remove(this.draftId);
                this.draftId = undefined;
            }

            if (this.isCreationMode()) {
                this.panelStore.setPanelHalfOpened();

                return this.$router.push(this.getPreviousRoute());
            }
            if (!this.missingValue && this.isFormOver()) {
                return this.$router.push(this.getPreviousRoute());
            }
        },
    },
});
</script>

<style lang="scss">
@import 'Form.scss';
</style>
