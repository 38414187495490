<template>
    <main>
        <el-tabs type="card">
            <el-tab-pane
                v-for="category in categorizedLayers"
                :key="category.name"
            >
                <template #label>
                    <span class="custom-tabs-label">
                        <FontAwesomeIcon icon="layer-group" />
                        {{ category.name }}
                    </span>
                </template>
                <LayersList :layers="category.layers"></LayersList>
            </el-tab-pane>
        </el-tabs>
    </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import type Layer from 'ol/layer/Layer';

import LayersList from '@connect-field/client/components/layer/LayersList.vue';
import useLayersStore from '@connect-field/client/stores/layers';

interface LayerCategory {
    layers: Array<Layer>;
    name: string;
}

interface DataInterface {
    categorizedLayers: Array<LayerCategory>;
}

export default defineComponent({
    components: {
        LayersList,
    },
    setup() {
        return {
            layersStore: useLayersStore(),
        };
    },
    data(): DataInterface {
        return { categorizedLayers: [] };
    },
    computed: {
        layers(): Array<Layer> {
            return (this.layersStore.layers as Array<Layer>).filter(
                (layer: Layer) => {
                    return !layer.get('hiddenLegend');
                },
            );
        },
    },
    mounted() {
        const categorizedLayersAsMap = this.layers.reduce(
            (categories: Record<string, LayerCategory>, layer: Layer) => {
                const category = layer.get('category') || 'Couches';

                if (!categories[category]) {
                    categories[category] = {
                        layers: [],
                        name: category,
                    };
                }
                categories[category].layers.push(layer);

                return categories;
            },
            {},
        );

        this.categorizedLayers = Object.values(categorizedLayersAsMap)
            .map((category: LayerCategory) => {
                return category;
            })
            .sort(
                (
                    previousCategory: LayerCategory,
                    nextCategory: LayerCategory,
                ) => {
                    return previousCategory.name.localeCompare(
                        nextCategory.name,
                    );
                },
            );
    },
});
</script>

<style scoped lang="scss">
.el-tabs__item.is-active {
    .custom-tabs-label {
        color: var(--color-primary) !important;
    }
}

.el-tabs__item {
    .custom-tabs-label {
        color: white;
    }
}
</style>
