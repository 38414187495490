<template>
    <FormWrapper
        v-if="!loading"
        ref="customFormRef"
        :images-model="images"
        :form-model="form"
        :mandatory-fields="mandatoryFields"
        :changes-model="changes"
        :layer-name="layerName"
        :object-id="objectId"
        :project-id="projectId"
        :latitude="latitude"
        :longitude="longitude"
        @update:images-model="
            (e) => {
                images = e;
            }
        "
        @update:value-missing="
            (e) => {
                valueMissing = e;
            }
        "
        @update:form-model="
            (e) => {
                form = e;
            }
        "
        @update:changes-model="
            (e) => {
                changes = e;
            }
        "
    >
        <template #menu_tabs>
            <el-row class="scroll-menu">
                <el-col :span="8">
                    <div
                        @click="selectTab('GENERAL')"
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'GENERAL' }"
                    >
                        GENERAL
                    </div>
                </el-col>

                <el-col :span="8">
                    <div
                        @click="selectTab('MECANIQUE')"
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'MECANIQUE' }"
                    >
                        MECANIQUE
                    </div>
                </el-col>

                <el-col :span="8">
                    <div
                        @click="selectTab('OPTIQUE')"
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'OPTIQUE' }"
                    >
                        OPTIQUE
                    </div>
                </el-col>
            </el-row>
        </template>
        <template #main_form>
            <el-row>
                <el-col
                    :span="24"
                    v-show="selectedTab === 'GENERAL'"
                >
                    <CustomInput
                        :label="labels.st_code"
                        :value="form.st_code"
                        :disabled="true"
                    />
                    <CustomInputSelect
                        :label="labels.statut + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.statut"
                        :value="form.statut"
                        @update:value="
                            (newValue) => saveData('statut', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.st_nd_code"
                        :value="form.st_nd_code"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.st_codeext"
                        :value="form.st_codeext"
                        :disabled="true"
                    />
                    <CustomInputSelect
                        :label="labels.donnees_metier"
                        :options="options.donnees_metier"
                        :value="form.donnees_metier"
                        @update:value="
                            (newValue) => saveData('donnees_metier', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_st_nom()"
                        :label="labels.st_nom"
                        :value="form.st_nom"
                        :disabled="true"
                    />
                    <CustomInput
                        v-show="condition_st_prop()"
                        :label="labels.st_prop"
                        :value="form.st_prop"
                        :maxlength="20"
                        :disabled="true"
                    />
                    <CustomInput
                        v-show="condition_st_gest()"
                        :label="labels.st_gest"
                        :value="form.st_gest"
                        :disabled="true"
                    />
                    <CustomInput
                        v-show="condition_st_user()"
                        :label="labels.st_user"
                        :value="form.st_user"
                        :maxlength="20"
                        :disabled="true"
                    />
                    <CustomInputSelect
                        v-show="condition_st_proptyp()"
                        :label="labels.st_proptyp"
                        :options="options.st_proptyp"
                        :value="form.st_proptyp"
                        :disabled="true"
                    />
                    <CustomInputSelect
                        v-show="condition_st_statut()"
                        :label="labels.st_statut"
                        :options="options.st_statut"
                        :value="form.st_statut"
                        :disabled="true"
                    />
                    <CustomInputSelect
                        v-show="condition_st_etat()"
                        :label="labels.st_etat"
                        :options="options.st_etat"
                        :value="form.st_etat"
                        :disabled="true"
                    />
                    <CustomDatePicker
                        v-show="condition_st_dateins()"
                        :label="labels.st_dateins"
                        :value="form.st_dateins"
                        :disabled="true"
                    />
                    <CustomDatePicker
                        v-show="condition_st_datemes()"
                        :label="labels.st_datemes"
                        :value="form.st_datemes"
                        :disabled="true"
                    />
                    <CustomInput
                        v-show="condition_st_avct()"
                        :label="labels.st_avct"
                        :value="form.st_avct"
                        :maxlength="1"
                        :disabled="true"
                    />
                    <CustomInput
                        v-show="condition_st_typephy()"
                        :label="labels.st_typephy"
                        :value="form.st_typephy"
                        :disabled="true"
                    />
                    <CustomInput
                        v-show="condition_st_typelog()"
                        :label="labels.st_typelog"
                        :value="form.st_typelog"
                        :maxlength="10"
                        :disabled="true"
                    />
                    <InputNumber
                        v-show="condition_st_nblines()"
                        :label="labels.st_nblines"
                        :value="form.st_nblines"
                        :disabled="true"
                    />
                    <CustomInput
                        v-show="condition_st_ad_code()"
                        :label="labels.st_ad_code"
                        :value="form.st_ad_code"
                        :maxlength="254"
                        :disabled="true"
                    />
                    <CustomInput
                        v-show="condition_st_comment()"
                        :label="labels.st_comment"
                        :value="form.st_comment"
                        :disabled="true"
                    />
                    <CustomDatePicker
                        v-show="condition_st_creadat()"
                        :label="labels.st_creadat"
                        :value="form.st_creadat"
                        :disabled="true"
                    />
                    <CustomDatePicker
                        v-show="condition_st_majdate()"
                        :label="labels.st_majdate"
                        :value="form.st_majdate"
                        :disabled="true"
                    />
                    <CustomInput
                        v-show="condition_st_majsrc()"
                        :label="labels.st_majsrc"
                        :value="form.st_majsrc"
                        :maxlength="254"
                        :disabled="true"
                    />
                    <CustomDatePicker
                        v-show="condition_st_abddate()"
                        :label="labels.st_abddate"
                        :value="form.st_abddate"
                        :disabled="true"
                    />
                    <CustomInput
                        v-show="condition_st_abdsrc()"
                        :label="labels.st_abdsrc"
                        :value="form.st_abdsrc"
                        :maxlength="254"
                        :disabled="true"
                    />
                </el-col>
                <el-col
                    :span="24"
                    v-show="selectedTab === 'MECANIQUE'"
                >
                    <CustomRadioButton
                        :label="labels.fixation_pmz"
                        :options="options.fixation_pmz"
                        :value="form.fixation_pmz"
                        size="small"
                        @update:value="
                            (newValue) => saveData('fixation_pmz', newValue)
                        "
                    />

                    <div v-show="condition_img_fixation_pmz()">
                        <InputCamera
                            :label="labels.img_fixation_pmz"
                            :images="images.img_fixation_pmz"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage('img_fixation_pmz', newValue)
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="condition_commentaire_fixation_pmz()"
                        :label="labels.commentaire_fixation_pmz"
                        :value="form.commentaire_fixation_pmz"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData('commentaire_fixation_pmz', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.etiquette_pmz"
                        :options="options.etiquette_pmz"
                        :value="form.etiquette_pmz"
                        size="small"
                        @update:value="
                            (newValue) => saveData('etiquette_pmz', newValue)
                        "
                    />

                    <div v-show="condition_img_etiquette_pmz()">
                        <InputCamera
                            :label="labels.img_etiquette_pmz"
                            :images="images.img_etiquette_pmz"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage('img_etiquette_pmz', newValue)
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="condition_commentaire_etiquette_pmz()"
                        :label="labels.commentaire_etiquette_pmz"
                        :value="form.commentaire_etiquette_pmz"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData('commentaire_etiquette_pmz', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.arrimage_cables"
                        :options="options.arrimage_cables"
                        :value="form.arrimage_cables"
                        size="small"
                        @update:value="
                            (newValue) => saveData('arrimage_cables', newValue)
                        "
                    />

                    <div v-show="condition_img_arrimage_cables()">
                        <InputCamera
                            :label="labels.img_arrimage_cables"
                            :images="images.img_arrimage_cables"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage('img_arrimage_cables', newValue)
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="condition_commentaire_arrimage_cables()"
                        :label="labels.commentaire_arrimage_cables"
                        :value="form.commentaire_arrimage_cables"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_arrimage_cables',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.etiquettes_definitives_des_ca2"
                        :options="options.etiquettes_definitives_des_ca2"
                        :value="form.etiquettes_definitives_des_ca2"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'etiquettes_definitives_des_ca2',
                                    newValue,
                                )
                        "
                    />

                    <div
                        v-show="condition_img_etiquettes_definitives_des_ca2()"
                    >
                        <InputCamera
                            :label="labels.img_etiquettes_definitives_des_ca2"
                            :images="images.img_etiquettes_definitives_des_ca2"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage(
                                        'img_etiquettes_definitives_des_ca2',
                                        newValue,
                                    )
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="
                            condition_commentaire_etiquettes_definitives_des_ca2()
                        "
                        :label="
                            labels.commentaire_etiquettes_definitives_des_ca2
                        "
                        :value="form.commentaire_etiquettes_definitives_des_ca2"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_etiquettes_definitives_des_ca2',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.mise_terre"
                        :options="options.mise_terre"
                        :value="form.mise_terre"
                        size="small"
                        @update:value="
                            (newValue) => saveData('mise_terre', newValue)
                        "
                    />

                    <div v-show="condition_img_mise_terre()">
                        <InputCamera
                            :label="labels.img_mise_terre"
                            :images="images.img_mise_terre"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage('img_mise_terre', newValue)
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="condition_commentaire_mise_terre()"
                        :label="labels.commentaire_mise_terre"
                        :value="form.commentaire_mise_terre"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData('commentaire_mise_terre', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.protection_tube"
                        :options="options.protection_tube"
                        :value="form.protection_tube"
                        size="small"
                        @update:value="
                            (newValue) => saveData('protection_tube', newValue)
                        "
                    />

                    <div v-show="condition_img_protection_tube()">
                        <InputCamera
                            :label="labels.img_protection_tube"
                            :images="images.img_protection_tube"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage('img_protection_tube', newValue)
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="condition_commentaire_protection_tube()"
                        :label="labels.commentaire_protection_tube"
                        :value="form.commentaire_protection_tube"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_protection_tube',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.passage_cable"
                        :options="options.passage_cable"
                        :value="form.passage_cable"
                        size="small"
                        @update:value="
                            (newValue) => saveData('passage_cable', newValue)
                        "
                    />

                    <div v-show="condition_img_passage_cable()">
                        <InputCamera
                            :label="labels.img_passage_cable"
                            :images="images.img_passage_cable"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage('img_passage_cable', newValue)
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="condition_commentaire_passage_cable()"
                        :label="labels.commentaire_passage_cable"
                        :value="form.commentaire_passage_cable"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData('commentaire_passage_cable', newValue)
                        "
                    />
                </el-col>
                <el-col
                    :span="24"
                    v-show="selectedTab === 'OPTIQUE'"
                >
                    <CustomRadioButton
                        :label="labels.position_fixation_tiroir"
                        :options="options.position_fixation_tiroir"
                        :value="form.position_fixation_tiroir"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('position_fixation_tiroir', newValue)
                        "
                    />

                    <div v-show="condition_img_position_fixation_tiroir()">
                        <InputCamera
                            :label="labels.img_position_fixation_tiroir"
                            :images="images.img_position_fixation_tiroir"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage(
                                        'img_position_fixation_tiroir',
                                        newValue,
                                    )
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="
                            condition_commentaire_position_fixation_tiroir()
                        "
                        :label="labels.commentaire_position_fixation_tiroir"
                        :value="form.commentaire_position_fixation_tiroir"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_position_fixation_tiroir',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.lovage_cassette"
                        :options="options.lovage_cassette"
                        :value="form.lovage_cassette"
                        size="small"
                        @update:value="
                            (newValue) => saveData('lovage_cassette', newValue)
                        "
                    />

                    <div v-show="condition_img_lovage_cassette()">
                        <InputCamera
                            :label="labels.img_lovage_cassette"
                            :images="images.img_lovage_cassette"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage('img_lovage_cassette', newValue)
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="condition_commentaire_lovage_cassette()"
                        :label="labels.commentaire_lovage_cassette"
                        :value="form.commentaire_lovage_cassette"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_lovage_cassette',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.etat_fibre_soudure"
                        :options="options.etat_fibre_soudure"
                        :value="form.etat_fibre_soudure"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('etat_fibre_soudure', newValue)
                        "
                    />

                    <div v-show="condition_img_etat_fibre_soudure()">
                        <InputCamera
                            :label="labels.img_etat_fibre_soudure"
                            :images="images.img_etat_fibre_soudure"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage(
                                        'img_etat_fibre_soudure',
                                        newValue,
                                    )
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="condition_commentaire_etat_fibre_soudure()"
                        :label="labels.commentaire_etat_fibre_soudure"
                        :value="form.commentaire_etat_fibre_soudure"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_etat_fibre_soudure',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.fermeture_pmz"
                        :options="options.fermeture_pmz"
                        :value="form.fermeture_pmz"
                        size="small"
                        @update:value="
                            (newValue) => saveData('fermeture_pmz', newValue)
                        "
                    />

                    <div v-show="condition_img_fermeture_pmz()">
                        <InputCamera
                            :label="labels.img_fermeture_pmz"
                            :images="images.img_fermeture_pmz"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage('img_fermeture_pmz', newValue)
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="condition_commentaire_fermeture_pmz()"
                        :label="labels.commentaire_fermeture_pmz"
                        :value="form.commentaire_fermeture_pmz"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData('commentaire_fermeture_pmz', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.etiquetage_tiroir_optique"
                        :options="options.etiquetage_tiroir_optique"
                        :value="form.etiquetage_tiroir_optique"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('etiquetage_tiroir_optique', newValue)
                        "
                    />

                    <div v-show="condition_img_etiquetage_tiroir_optique()">
                        <InputCamera
                            :label="labels.img_etiquetage_tiroir_optique"
                            :images="images.img_etiquetage_tiroir_optique"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage(
                                        'img_etiquetage_tiroir_optique',
                                        newValue,
                                    )
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="
                            condition_commentaire_etiquetage_tiroir_optique()
                        "
                        :label="labels.commentaire_etiquetage_tiroir_optique"
                        :value="form.commentaire_etiquetage_tiroir_optique"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_etiquetage_tiroir_optique',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.bouchon_connecteur"
                        :options="options.bouchon_connecteur"
                        :value="form.bouchon_connecteur"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('bouchon_connecteur', newValue)
                        "
                    />

                    <div v-show="condition_img_bouchon_connecteur()">
                        <InputCamera
                            :label="labels.img_bouchon_connecteur"
                            :images="images.img_bouchon_connecteur"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage(
                                        'img_bouchon_connecteur',
                                        newValue,
                                    )
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="condition_commentaire_bouchon_connecteur()"
                        :label="labels.commentaire_bouchon_connecteur"
                        :value="form.commentaire_bouchon_connecteur"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_bouchon_connecteur',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.etiquette_cable"
                        :options="options.etiquette_cable"
                        :value="form.etiquette_cable"
                        size="small"
                        @update:value="
                            (newValue) => saveData('etiquette_cable', newValue)
                        "
                    />

                    <div v-show="condition_img_etiquette_cable()">
                        <InputCamera
                            :label="labels.img_etiquette_cable"
                            :images="images.img_etiquette_cable"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage('img_etiquette_cable', newValue)
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="condition_commentaire_etiquette_cable()"
                        :label="labels.commentaire_etiquette_cable"
                        :value="form.commentaire_etiquette_cable"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_etiquette_cable',
                                    newValue,
                                )
                        "
                    />
                    <CustomInputSelect
                        :label="labels.autres_observations"
                        :options="options.autres_observations"
                        :value="form.autres_observations"
                        @update:value="
                            (newValue) =>
                                saveData('autres_observations', newValue)
                        "
                    />

                    <div v-show="condition_img_autres_observations()">
                        <InputCamera
                            :label="labels.img_autres_observations"
                            :images="images.img_autres_observations"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage(
                                        'img_autres_observations',
                                        newValue,
                                    )
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="condition_commentaire_autres_observations()"
                        :label="labels.commentaire_autres_observations"
                        :value="form.commentaire_autres_observations"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_autres_observations',
                                    newValue,
                                )
                        "
                    />
                </el-col>
            </el-row>
        </template>
    </FormWrapper>
</template>

<script lang="ts">
/* eslint-disable */
import { defineComponent } from 'vue';

import type { FormDataInterface } from '@connect-field/client/components/forms/form.types';
import FormTemplate from '@connect-field/client/components/forms/FormTemplate.vue';

import {
    labels,
    options,
} from '@connect-field/client/components/forms/DrivOptic/form_audit_pmz_drivoptic.data';

interface DataInterface extends FormDataInterface {}

export default defineComponent({
    extends: FormTemplate,
    data(): DataInterface {
        return {
            changes: false,
            form: {},
            images: {
                img_fixation_pmz: [],
                img_etiquette_pmz: [],
                img_arrimage_cables: [],
                img_etiquettes_definitives_des_ca2: [],
                img_mise_terre: [],
                img_protection_tube: [],
                img_passage_cable: [],
                img_position_fixation_tiroir: [],
                img_lovage_cassette: [],
                img_etat_fibre_soudure: [],
                img_fermeture_pmz: [],
                img_etiquetage_tiroir_optique: [],
                img_bouchon_connecteur: [],
                img_etiquette_cable: [],
                img_autres_observations: [],
            },
            labels,
            loading: true,
            mandatoryFields: ['statut'],
            options,
            selectedTab: 'GENERAL',
            valueMissing: false,
        };
    },
    methods: {
        condition_st_nom(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_st_prop(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_st_gest(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_st_user(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_st_proptyp(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_st_statut(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_st_etat(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_st_dateins(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_st_datemes(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_st_avct(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_st_typephy(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_st_typelog(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_st_nblines(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_st_ad_code(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_st_comment(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_st_creadat(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_st_majdate(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_st_majsrc(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_st_abddate(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_st_abdsrc(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_img_fixation_pmz(): boolean {
            const { fixation_pmz } = this.form;
            return fixation_pmz?.startsWith('NOK');
        },
        condition_commentaire_fixation_pmz(): boolean {
            const { fixation_pmz } = this.form;
            return fixation_pmz?.startsWith('NOK');
        },
        condition_img_etiquette_pmz(): boolean {
            const { etiquette_pmz } = this.form;
            return etiquette_pmz?.startsWith('NOK');
        },
        condition_commentaire_etiquette_pmz(): boolean {
            const { etiquette_pmz } = this.form;
            return etiquette_pmz?.startsWith('NOK');
        },
        condition_img_arrimage_cables(): boolean {
            const { arrimage_cables } = this.form;
            return arrimage_cables?.startsWith('NOK');
        },
        condition_commentaire_arrimage_cables(): boolean {
            const { arrimage_cables } = this.form;
            return arrimage_cables?.startsWith('NOK');
        },
        condition_img_etiquettes_definitives_des_ca2(): boolean {
            const { etiquettes_definitives_des_ca2 } = this.form;
            return etiquettes_definitives_des_ca2?.startsWith('NOK');
        },
        condition_commentaire_etiquettes_definitives_des_ca2(): boolean {
            const { etiquettes_definitives_des_ca2 } = this.form;
            return etiquettes_definitives_des_ca2?.startsWith('NOK');
        },
        condition_img_mise_terre(): boolean {
            const { mise_terre } = this.form;
            return mise_terre?.startsWith('NOK');
        },
        condition_commentaire_mise_terre(): boolean {
            const { mise_terre } = this.form;
            return mise_terre?.startsWith('NOK');
        },
        condition_img_protection_tube(): boolean {
            const { protection_tube } = this.form;
            return protection_tube?.startsWith('NOK');
        },
        condition_commentaire_protection_tube(): boolean {
            const { protection_tube } = this.form;
            return protection_tube?.startsWith('NOK');
        },
        condition_img_passage_cable(): boolean {
            const { passage_cable } = this.form;
            return passage_cable?.startsWith('NOK');
        },
        condition_commentaire_passage_cable(): boolean {
            const { passage_cable } = this.form;
            return passage_cable?.startsWith('NOK');
        },
        condition_img_position_fixation_tiroir(): boolean {
            const { position_fixation_tiroir } = this.form;
            return position_fixation_tiroir?.startsWith('NOK');
        },
        condition_commentaire_position_fixation_tiroir(): boolean {
            const { position_fixation_tiroir } = this.form;
            return position_fixation_tiroir?.startsWith('NOK');
        },
        condition_img_lovage_cassette(): boolean {
            const { lovage_cassette } = this.form;
            return lovage_cassette?.startsWith('NOK');
        },
        condition_commentaire_lovage_cassette(): boolean {
            const { lovage_cassette } = this.form;
            return lovage_cassette?.startsWith('NOK');
        },
        condition_img_etat_fibre_soudure(): boolean {
            const { etat_fibre_soudure } = this.form;
            return etat_fibre_soudure?.startsWith('NOK');
        },
        condition_commentaire_etat_fibre_soudure(): boolean {
            const { etat_fibre_soudure } = this.form;
            return etat_fibre_soudure?.startsWith('NOK');
        },
        condition_img_fermeture_pmz(): boolean {
            const { fermeture_pmz } = this.form;
            return fermeture_pmz?.startsWith('NOK');
        },
        condition_commentaire_fermeture_pmz(): boolean {
            const { fermeture_pmz } = this.form;
            return fermeture_pmz?.startsWith('NOK');
        },
        condition_img_etiquetage_tiroir_optique(): boolean {
            const { etiquetage_tiroir_optique } = this.form;
            return etiquetage_tiroir_optique?.startsWith('NOK');
        },
        condition_commentaire_etiquetage_tiroir_optique(): boolean {
            const { etiquetage_tiroir_optique } = this.form;
            return etiquetage_tiroir_optique?.startsWith('NOK');
        },
        condition_img_bouchon_connecteur(): boolean {
            const { bouchon_connecteur } = this.form;
            return bouchon_connecteur?.startsWith('NOK');
        },
        condition_commentaire_bouchon_connecteur(): boolean {
            const { bouchon_connecteur } = this.form;
            return bouchon_connecteur?.startsWith('NOK');
        },
        condition_img_etiquette_cable(): boolean {
            const { etiquette_cable } = this.form;
            return etiquette_cable?.startsWith('NOK');
        },
        condition_commentaire_etiquette_cable(): boolean {
            const { etiquette_cable } = this.form;
            return etiquette_cable?.startsWith('NOK');
        },
        condition_img_autres_observations(): boolean {
            const { autres_observations } = this.form;
            return autres_observations === 'OUI';
        },
        condition_commentaire_autres_observations(): boolean {
            const { autres_observations } = this.form;
            return autres_observations === 'OUI';
        },
    },
});
</script>
