import type { OptionsInterface, SelectInterface } from '@connect-field/client/components/forms/form.types';

export const labels: Record<string, string> = {
    arrimage_cables: 'Arrimage des câbles dans la boîte (porteurs)',
    autre_ext: 'Autre',
    autre_fibre: 'Autre',
    bp_abddate: "Date d'abandon",
    bp_abdsrc: "Cause de l'abandon",
    bp_avct: 'Avancement',
    bp_ca_nb: 'Nb cassettes',
    bp_code: 'Code Grace',
    bp_codeext: 'Code externe',
    bp_comment: 'commentaires',
    bp_creadat: "Date de création de l'objet en base (peut être calculé)",
    bp_datemes: 'Date de mise en service',
    bp_entrees: 'Nb entrées câbles.',
    bp_etat: 'État',
    bp_etiquet: 'Etiquette terrain',
    bp_gest: 'Gestionnaire',
    bp_linecod: "Code d'une ligne (cas FTTH) selon la nomenclature du régulateur. Cas d'un PTO. (OO-XXXX-XXXX)",
    bp_lt_code: 'Code de local technique',
    bp_majdate: "Date de la mise à jour de l'objet en base (peut être calculé)",
    bp_majsrc: 'Source de mise à jour',
    bp_nb_pas: 'Nb pas organiseur BPE',
    bp_oc_code:
        "Référence OC (Opérateur Commercial) de la prise terminale. Différent de bp_code. Cas d'une PTO uniquement",
    bp_occp: 'Occupation',
    bp_prop: 'Propriétaire',
    bp_proptyp: 'Type de propriété',
    bp_pt_code: 'Code point technique',
    bp_racco: "Codification Interop de l'échec du raccordement. Cas d'une PTO uniquement.",
    bp_ref_kit: 'Réf kit entrée câble',
    bp_rf_code: 'Référence',
    bp_sf_code: 'Identifiant SUF',
    bp_statut: "Phase d'avancement",
    bp_typelog: 'Type logique',
    bp_typephy: 'Type physique',
    bp_user: 'Utilisateur',
    commentaire_arrimage_cables: 'Commentaire',
    commentaire_autre_ext: 'Commentaire',
    commentaire_autre_fibre: 'Commentaire',
    commentaire_degraissage_fibres: 'Commentaire',
    commentaire_detubage_fibres: 'Commentaire',
    commentaire_diametre_ecam: 'Commentaire',
    commentaire_emplacement_boitier: 'Commentaire',
    commentaire_etancheite_bpe: 'Commentaire',
    commentaire_etiquette_bpe: 'Commentaire',
    commentaire_fixation_boitier: 'Commentaire',
    commentaire_longueurs_love: 'Commentaire',
    commentaire_plan_cablage: 'Commentaire',
    commentaire_presence_cache: 'Commentaire',
    commentaire_presence_gaine_fenduearrimage_cables: 'Commentaire',
    commentaire_protection_fibres: 'Commentaire',
    commentaire_rangement_attentes: 'Commentaire',
    commentaire_rayon_courbure: 'Commentaire',
    commentaire_type_cable: 'Commentaire',
    degraissage_fibres: 'Dégraissage des fibres/tubes',
    detubage_fibres: 'Détubage des fibres',
    diametre_ecam: 'Respect des diamètres des ECAM',
    donnees_metier: 'Affichage des données métier',
    emplacement_boitier: 'Emplacement du boitier',
    etancheite_bpe: 'Étanchéité de la BPE',
    etiquette_bpe: 'Etiquettes (câbles et BPE)',
    fixation_boitier: 'Fixation du boîtier',
    img_arrimage_cables: 'Photos',
    img_autre_ext: 'Photos',
    img_autre_fibre: 'Photos',
    img_degraissage_fibres: 'Photos',
    img_detubage_fibres: 'Photos',
    img_diametre_ecam: 'Photos',
    img_emplacement_boitier: 'Photo de loin dans le contexte',
    img_etancheite_bpe: 'Photos',
    img_etiquette_bpe: 'Photos',
    img_fixation_boitier: 'Photos',
    img_longueurs_love: 'Photos',
    img_plan_cablage: 'Photos',
    img_presence_cache: 'Photos',
    img_presence_gaine_fendue: 'Photos',
    img_protection_fibres: 'Photos',
    img_rangement_attentes: 'Photos',
    img_rayon_courbure: 'Photos',
    img_type_cable: 'Photos',
    longueurs_love: 'Respect des longueurs de love (modules ou FO)',
    plan_cablage: 'Respect du plan de câblage',
    presence_cache: 'Présence de cache sur les cassettes',
    presence_fixation: 'Présence de fixation pour le boitier ?',
    presence_gaine_fendue: 'Présence de la gaine fendue',
    protection_fibres: 'Protection et passage des fibres',
    rangement_attentes: 'Rangement des attentes en fond de boite (modules)',
    rayon_courbure: 'Respect des rayons de courbure',
    statut: 'Statut audit',
    test_tenue_pression: 'Test de tenue à la pression ?',
    type_cable: 'Respect du type de câble G657-A2',
};

export const options: Record<string, OptionsInterface | SelectInterface> = {
    arrimage_cables: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    autre_ext: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    autre_fibre: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    bp_avct: [
        { label: 'EXISTANT', value: 'E' },
        { label: 'A CREER', value: 'C' },
        { label: 'TRAVAUX', value: 'T' },
        { label: 'EN SERVICE', value: 'S' },
        { label: 'HORS SERVICE', value: 'H' },
        { label: 'ABANDONNE', value: 'A' },
    ],
    bp_etat: [
        { label: 'A CHANGER', value: 'HS' },
        { label: 'MAUVAIS ETAT', value: 'ME' },
        { label: 'BON ETAT', value: 'OK' },
        { label: 'NON CONCERNE', value: 'NC' },
    ],
    bp_occp: [
        { label: 'VIDE', value: '0' },
        { label: 'NON VIDE EXPLOITABLE', value: '1.1' },
        { label: 'NON VIDE NON EXPLOITABLE', value: '1.2' },
        { label: 'SATUREE', value: '2' },
    ],
    bp_proptyp: [
        { label: 'CONSTRUCTION', value: 'CST' },
        { label: 'RACHAT', value: 'RAC' },
        { label: 'CESSION', value: 'CES' },
        { label: 'IRU', value: 'IRU' },
        { label: 'LOCATION', value: 'LOC' },
        { label: 'OCCUPATION', value: 'OCC' },
    ],
    bp_racco: [
        { label: 'CLIENT: CONTACT ERRONE', value: 'FCLI01' },
        { label: 'CLIENT : CLIENT INJOIGNABLE IMPOSSIBLE DE PRENDRE RDV', value: 'FCLI02' },
        { label: 'CLIENT : CLIENT N HABITE PAS A L ADRESSE INDIQUEE', value: 'FCLI03' },
        { label: 'CLIENT: DEMANDE ANNULATION DE LA COMMANDE PAR LE CLIENT FINAL', value: 'FCLI04' },
        { label: 'CLIENT : REFUS TRAVAUX CLIENT', value: 'FCLI05' },
        { label: 'CLIENT : REFUS GESTIONNAIRE IMMEUBLE', value: 'FCLI06' },
        { label: 'CLIENT : CLIENT ABSENT LORS DE L INTERVENTION', value: 'FCLI07' },
        { label: 'ADRESSE : CODE ADRESSE IMMEUBLE INEXISTANT DANS LE REFERENTIEL OI', value: 'FADR01' },
        { label: 'ADRESSE : BATIMENT MANQUANT OU INEXISTANT DANS LE REFERENTIEL OI', value: 'FADR02' },
        { label: 'ADRESSE : ESCALIER MANQUANT OU INEXISTANT DANS LE REFERENTIEL OI', value: 'FADR03' },
        { label: 'ADRESSE : ETAGE MANQUANT OU INEXISTANT DANS LE REFERENTIEL OI', value: 'FADR04' },
        { label: 'TRAITEMENT IMPOSSIBLE : PTO REQUISE', value: 'FIMP01' },
        { label: 'TRAITEMENT IMPOSSIBLE : PTO INEXISTANTE DANS LE REFERENTIEL OI', value: 'FIMP02' },
        { label: 'TRAITEMENT IMPOSSIBLE : PTO INCONNUE A L ADRESSE', value: 'FIMP03' },
        { label: 'TRAITEMENT IMPOSSIBLE : REF PRESTATION PM INEXISTANTE DANS LE REFERENTIEL OI', value: 'FIMP04' },
        { label: 'TRAITEMENT IMPOSSIBLE : REF PRESTATION PM ET ADRESSE INCOHERENTES', value: 'FIMP05' },
        { label: 'TRAITEMENT IMPOSSIBLE : TYPE DE COMMANDE IRRECEVABLE SUR CETTE REF PRESTATION PM', value: 'FIMP06' },
        { label: 'TRAITEMENT IMPOSSIBLE : PM INEXISTANT DANS LE REFERENTIEL OI', value: 'FIMP07' },
        { label: 'TRAITEMENT IMPOSSIBLE : PM ET ADRESSE INCOHERENTS', value: 'FIMP08' },
        { label: 'TRAITEMENT IMPOSSIBLE : COMMANDE IRRECEVABLE SUR CE PM', value: 'FIMP09' },
        { label: 'TRAITEMENT IMPOSSIBLE : COMMANDE IRRECEVABLE SUR CETTE ADRESSE', value: 'FIMP10' },
        { label: 'TRAITEMENT IMPOSSIBLE : ADRESSE INELIGIBLE TEMPORAIREMENT', value: 'FIMP11' },
        { label: 'TRAITEMENT IMPOSSIBLE : TYPE COMMANDE ERRONNE', value: 'FIMP12' },
        { label: 'TRAITEMENT IMPOSSIBLE : IDENTIFIANT COMMANDE INTERNE OC DEJA UTILISE', value: 'FIMP13' },
        { label: 'TRAITEMENT IMPOSSIBLE : IDENTIFIANT COMMANDE INTERNE OC INCONNUE', value: 'FIMP14' },
        { label: 'TRAITEMENT IMPOSSIBLE : CHAMPS OBLIGATOIRES MANQUANTS', value: 'FIMP15' },
        { label: 'TRAITEMENT IMPOSSIBLE : CHAMPS INCOHERENTS', value: 'FIMP16' },
        { label: 'TRAITEMENT IMPOSSIBLE : PTO INEXISTANTE', value: 'FIMP17' },
        { label: 'TRAITEMENT IMPOSSIBLE : PTO EXISTANTE', value: 'FIMP18' },
        { label: 'TRAITEMENT IMPOSSIBLE : PTO DEJA AFFECTEE A L OC', value: 'FIMP19' },
        { label: 'ECHEC PRODUCTION : PB OU PM SATURE', value: 'FINT01' },
        { label: 'ECHEC PRODUCTION : SATURATION VIRTUELLE PB OU PM', value: 'FINT02' },
        { label: 'ECHEC PRODUCTION : HOTLINE OI INJOIGNABLE', value: 'FINT03' },
        { label: 'ECHEC PRODUCTION : ABSENCE DE CONTINUITE OPTIQUE', value: 'FINT04' },
        { label: 'ECHEC PRODUCTION : AFFAIBLISSEMENT TROP IMPORTANT', value: 'FINT05' },
        { label: 'ECHEC PRODUCTION : ROUTE OPTIQUE DEJA UTILISEE', value: 'FINT06' },
        { label: 'ECHEC PRODUCTION : INFORMATIONS ROUTE OPTIQUE ERRONEES', value: 'FINT07' },
        { label: 'ECHEC PRODUCTION : POSITION BRASSAGE BAIE OPERATEUR INTROUVABLE', value: 'FINT08' },
        { label: 'ECHEC PRODUCTION : POSITION BRASSAGE BAIE OPERATEUR DEJA UTILISEE', value: 'FINT09' },
        { label: 'ECHEC PRODUCTION : AUTRE PROBLEME TECHNIQUE', value: 'FINT10' },
        { label: 'ECHEC PRODUCTION : INFRA TIERS INDISPONIBLE OU DELAI', value: 'FINT11' },
        { label: 'ECHEC PRODUCTION : PBO NON CONFORME', value: 'FINT12' },
        { label: 'ECHEC PRODUCTION : DEFAUT DE VERTICALITE', value: 'FINT13' },
        { label: 'AUTRE MOTIF : COMMENTAIRES LIBRES', value: 'FAUT01' },
        { label: 'RDV : NOMBRE MAX DE MODIFICATIONS DE RDV DEPASSE', value: 'FRDV01' },
        { label: 'RDV : PAS DE PLAGES DE RDV DISPONIBLES SUR CES CRENEAUX', value: 'FRDV02' },
        { label: 'RDV : PLAGE DE RDV NON RESERVEE', value: 'FRDV03' },
        { label: 'RDV : ETAT RDV NON VALIDE', value: 'FRDV04' },
        { label: 'RDV : RDV SUR PRODUIT NON FTTH', value: 'FRDV05' },
        { label: 'RDV : RDV SUR ADRESSE DIFFERENTE', value: 'FRDV06' },
    ],
    bp_statut: [
        { label: 'ETUDE PRELIMINAIRE', value: 'PRE' },
        { label: 'ETUDE DE DIAGNOSTIC', value: 'DIA' },
        { label: 'AVANT-PROJET', value: 'AVP' },
        { label: 'PROJET', value: 'PRO' },
        { label: 'PASSATION DES MARCHES DE TRAVAUX', value: 'ACT' },
        { label: 'ETUDE D EXECUTION', value: 'EXE' },
        { label: 'TRAVAUX', value: 'TVX' },
        { label: 'RECOLEMENT', value: 'REC' },
        { label: 'MAINTIENT EN CONDITIONS OPERATIONNELLES', value: 'MCO' },
    ],
    bp_typelog: [
        { label: 'BOITIER PROTECTION EPISSURE', value: 'BPE' },
        { label: 'POINT DE TERMINAISON OPTIQUE', value: 'PTO' },
        { label: 'POINT DE BRANCHEMENT OPTIQUE', value: 'PBO' },
        { label: 'DISPOSITIF DE TERMINAISON INTERIEUR OPTIQUE', value: 'DTI' },
    ],
    bp_typephy: [
        { label: 'BPE 6FO', value: 'B006' },
        { label: 'BPE 12FO', value: 'B012' },
        { label: 'BPE 24FO', value: 'B024' },
        { label: 'BPE 36FO', value: 'B036' },
        { label: 'BPE 48FO', value: 'B048' },
        { label: 'BPE 72FO', value: 'B072' },
        { label: 'BPE 96FO', value: 'B096' },
        { label: 'BPE 144FO', value: 'B144' },
        { label: 'BPE 288FO', value: 'B288' },
        { label: 'BPE 432FO', value: 'B432' },
        { label: 'BPE 576FO', value: 'B576' },
        { label: 'BPE 720FO', value: 'B720' },
        { label: 'COFFRET', value: 'COF' },
        { label: 'DTIO 1FO', value: 'DTI1' },
        { label: 'DTIO 2FO', value: 'DTI2' },
        { label: 'DTIO 4FO', value: 'DTI4' },
        { label: 'AUTRE', value: 'AUTR' },
    ],
    degraissage_fibres: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    detubage_fibres: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    diametre_ecam: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    donnees_metier: { false: 'NON', true: 'OUI' },
    emplacement_boitier: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    etancheite_bpe: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    etiquette_bpe: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    fixation_boitier: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    longueurs_love: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    plan_cablage: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    presence_cache: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    presence_fixation: { false: 'NON', true: 'OUI' },
    presence_gaine_fendue: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    protection_fibres: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    rangement_attentes: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    rayon_courbure: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    statut: [
        { label: 'Audit à réaliser', value: 'NON EFFECTUE' },
        { label: 'Audit partiellement réalisé', value: 'PARTIEL' },
        { label: 'Audit réalisé', value: 'TERMINE' },
        { label: 'Non concerné par un audit', value: 'NON CONCERNE' },
    ],
    test_tenue_pression: { false: 'NON', true: 'OUI' },
    type_cable: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
};
