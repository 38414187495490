<template>
    <el-row>
        <el-col :span="24">
            <div class="custom_label_input">{{ label }}</div>
        </el-col>
        <el-col :span="24">
            <el-form-item>
                <el-input
                    v-model="model"
                    type="textarea"
                    autosize
                    :disabled="disabled"
                    :maxlength="maxlength"
                    :placeholder="placeholder"
                    :size="size"
                />
            </el-form-item>
        </el-col>
    </el-row>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';

export default defineComponent({
    name: 'CustomInputTextarea',
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: '',
        },
        maxlength: {
            type: Number,
            default: 255,
        },
        placeholder: {
            type: String,
            default: '',
        },
        size: {
            type: String,
            values: ['large', 'default', 'small'],
            default: 'default',
        },
        value: {
            type: [String, null] as PropType<string | null>,
            required: true,
        },
    },
    emits: ['update:value'],
    computed: {
        model: {
            get(): string | null {
                return this.value;
            },
            set(value: string | null) {
                this.$emit('update:value', value);
            },
        },
    },
    emit: ['update:value'],
});
</script>
