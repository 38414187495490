<template>
    <BaseDialog
        :title="$t('formCreation.title')"
        :has-backdrop-escape="true"
        @close="closeDialog"
    >
        <span>{{ $t('formCreation.type') }}</span>
        <div v-if="geolocation">
            {{ $t('formCreation.currentGeolocation') }}
            {{ geolocation.latitude }} {{ geolocation.longitude }}
        </div>
        <div
            v-else
            class="error"
        >
            {{ $t('formCreation.error') }}
        </div>
        <template #actions>
            <div class="dialog-footer">
                <BaseDialogButton
                    v-for="layer in getCreationLayers()"
                    :key="layer.id"
                    :is-disabled="isDisabled()"
                    :label="layer.alias"
                    :full-width="true"
                    button-type="action"
                    @click="() => redirect(layer)"
                />
                <BaseDialogButton
                    :label="$t('globals.cancel')"
                    :full-width="true"
                    button-type="default"
                    @click="closeDialog"
                />
            </div>
        </template>
    </BaseDialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'pinia';

import {
    type ProjectConfigurationLayer,
    ProjectConfigurationLayerFormatEnum,
} from '@connect-field/client/sdk/generated';
import BaseDialog from '@connect-field/client/components/ui/BaseDialog.vue';
import BaseDialogButton from '@connect-field/client/components/ui/BaseDialogButton.vue';
import { redirectToFormCreation } from '@connect-field/client/services/formRouter.service';
import useMapStore from '@connect-field/client/stores/map';
import useObjectStore from '@connect-field/client/stores/object';
import useProjectsStore from '@connect-field/client/stores/projects';

export default defineComponent({
    components: {
        BaseDialog,
        BaseDialogButton,
    },
    emits: ['closeDialog'],
    setup() {
        return {
            objectStore: useObjectStore(),
        };
    },
    computed: {
        ...mapState(useMapStore, {
            geolocation: 'geolocation',
        }),
        ...mapState(useProjectsStore, {
            selectedProject: 'selectedProject',
        }),
    },
    methods: {
        closeDialog(): void {
            this.$emit('closeDialog');
        },
        getCreationLayers(): Array<ProjectConfigurationLayer> {
            if (!this.selectedProject) {
                return [];
            }

            return this.selectedProject.layers.filter(
                (layer: ProjectConfigurationLayer) => layer.enableCreation,
            );
        },
        isDisabled(): boolean {
            return !this.geolocation;
        },
        redirect(layer: ProjectConfigurationLayer): void {
            if (!this.isDisabled()) {
                this.closeDialog();
                const { projectId } = this.$route.params;

                this.objectStore.$patch((state) => {
                    state.layerData = {
                        alias: layer.alias ?? layer.name,
                        coordinate: [],
                        enableCreation: layer.enableCreation,
                        enableEdition: layer.enableEdition,
                        form: layer.form,
                        layerFormat:
                            ProjectConfigurationLayerFormatEnum.GEOJSON,
                        layerId: layer.id.toString(),
                        name: layer.name,
                        type: layer.type,
                    };
                });

                if (this.geolocation) {
                    redirectToFormCreation({
                        form: layer.form,
                        isOffline: !!this.$route.meta.offline,
                        latitude: this.geolocation?.latitude,
                        layerName: layer.name,
                        longitude: this.geolocation?.longitude,
                        projectId: projectId.toString(),
                        router: this.$router,
                    });
                } else {
                    console.error('Geolocation is not correct');
                }
            }
        },
    },
});
</script>

<style scoped lang="scss">
.dialog-footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.dialog-footer .el-button {
    margin: 0.5rem 0;
}

.dialog-footer div:last-child {
    margin-top: 1rem;
}

.dialog-footer div + div {
    margin-left: 0; /* reset */
}
</style>
