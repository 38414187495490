/*
Store user data
 */

import * as Sentry from '@sentry/vue';
import { acceptHMRUpdate, defineStore } from 'pinia';

interface UserInterface {
    email: string;
    id?: number;
    name: string;
}

export interface StateInterface {
    user: UserInterface;
}

const useUserStore = defineStore('user', {
    actions: {
        setUser(payload: { userEmail: string; userId: number; userName: string }): void {
            this.user = {
                email: payload.userEmail,
                id: payload.userId,
                name: payload.userName,
            };
            Sentry.setUser({
                email: payload.userEmail,
                id: payload.userId.toString(),
                username: payload.userName,
            });
        },
    },
    state: (): StateInterface => ({
        user: {
            email: '',
            id: undefined,
            name: '',
        },
    }),
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
}

export default useUserStore;
