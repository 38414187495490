import type { Router, RouteRecordRaw } from 'vue-router';
import type { Component } from 'vue';

import formRoutes from '@connect-field/client/router/forms.routes';
import useObjectStore from '@connect-field/client/stores/object';

export interface CustomRecordRoute {
    component: Component;
    name: string;
    path: string;
}

export interface FormRedirectionInterface {
    form: string | null;
    isOffline: boolean;
    layerName?: string;
    objectId: string;
    projectId: string;
    router: Router;
}

interface FormRoutesInterface {
    offline: Array<RouteRecordRaw>;
    online: Array<RouteRecordRaw>;
}

function generateRoutes(route: CustomRecordRoute, isOffline: boolean): Array<RouteRecordRaw> {
    const baseFormRoute = 'layer/:layerName/form';

    return [
        {
            component: route.component,
            meta: {
                creation: true,
                offline: isOffline,
            },
            name: `${route.name}Create${isOffline ? 'Offline' : ''}`,
            path: `${baseFormRoute}/${route.path}/:latitude/:longitude`,
            props: true,
        },
        {
            component: route.component,
            meta: {
                creation: false,
                offline: isOffline,
            },
            name: `${route.name}${isOffline ? 'Offline' : ''}`,
            path: `${baseFormRoute}/${route.path}/:objectId`,
            props: true,
        },
    ];
}

export function generateFormRoutes(): FormRoutesInterface {
    return formRoutes.reduce(
        (routes: FormRoutesInterface, route: CustomRecordRoute) => {
            routes.online.push(...generateRoutes(route, false));
            routes.offline.push(...generateRoutes(route, true));

            return routes;
        },
        {
            offline: new Array<RouteRecordRaw>(),
            online: new Array<RouteRecordRaw>(),
        },
    );
}

export function isFormNameValid(formName: string): boolean {
    return (
        formRoutes.findIndex((formRoute: CustomRecordRoute) => {
            return formRoute.name === formName;
        }) > -1
    );
}

export async function redirectToForm(formRedirection: FormRedirectionInterface): Promise<void> {
    const formName = formRedirection.form;
    const objectStore = useObjectStore();

    if (formRedirection.layerName && formName && isFormNameValid(formName)) {
        if (formRedirection.isOffline) {
            await objectStore.syncDataFormOffline(
                formRedirection.projectId,
                formRedirection.layerName,
                formRedirection.objectId,
            );
        } else {
            await objectStore.syncDataForm(formRedirection.objectId, formRedirection.layerName);
        }

        formRedirection.router.replace({
            name: `${formName}${formRedirection.isOffline ? 'Offline' : ''}`,
            params: {
                layerName: formRedirection.layerName,
                objectId: formRedirection.objectId,
                projectId: formRedirection.projectId,
            },
        });
    } else {
        console.warn('[service:formRouter:redirectToForm] This form is not defined in the router', {
            formName,
            isOffline: formRedirection.isOffline,
            layerName: formRedirection.layerName,
            objectId: formRedirection.objectId,
            projectId: formRedirection.projectId,
        });
    }
}

interface FormParamsInterface {
    form: string | null;
    isOffline: boolean;
    latitude: number;
    layerName: string;
    longitude: number;
    projectId: string;
    router: Router;
}

export function redirectToFormCreation(formParams: FormParamsInterface): void {
    const formName = formParams.form;

    if (formName && isFormNameValid(formName)) {
        formParams.router.push({
            name: `${formName}Create${formParams.isOffline ? 'Offline' : ''}`,
            params: {
                latitude: formParams.latitude,
                layerName: formParams.layerName,
                longitude: formParams.longitude,
                projectId: formParams.projectId,
            },
        });
    } else {
        console.warn('[service:formRouter:redirectToFormCreation] This layerTable is not linked to a form', {
            formName,
            isOffline: formParams.isOffline,
            layerName: formParams.layerName,
            projectId: formParams.projectId,
        });
        formParams.router.push({ name: 'projects' });
    }
}
