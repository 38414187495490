import type { OptionsInterface, SelectInterface } from '@connect-field/client/components/forms/form.types';

export const labels = {
    autres_observations: 'Observations',
    commentaire_autres_observations: 'Commentaire',
    commentaire_demi_lune_aero_sout_2_50_m: 'Commentaire',
    commentaire_etat_de_l_appui: 'Commentaire',
    commentaire_etiquetage_cable_s: 'Commentaire',
    commentaire_fixation_cable_s_sur_rehauss: 'Commentaire',
    commentaire_fixation_du_des_cables_s_1: 'Commentaire',
    commentaire_pince_d_ancrage_et_chapeau_de: 'Commentaire',
    commentaire_rehausse_traverse: 'Commentaire',
    commentaire_traverse_fibre_10_20_cm_au: 'Commentaire',
    demi_lune_aero_sout_2_50_m: 'Demi-Lune Aéro-Sout [2,50 mètres]',
    donnees_metier: 'Affichage des données métier',
    etat_de_l_appui: "Etat de l'appui",
    etiquetage_cable_s: 'Etiquetage câble(s)',
    fixation_cable_s_sur_rehauss: 'Fixation câble(s) sur traverse',
    fixation_du_des_cables_s_1: 'Fixation du (des) câbles(s)',
    img_autres_observations: 'Photos',
    img_demi_lune_aero_sout_2_50_m: 'Photos',
    img_etat_de_l_appui: 'Photos',
    img_etiquetage_cable_s: 'Photos',
    img_fixation_cable_s_sur_rehauss: 'Photos',
    img_fixation_du_des_cables_s_1: 'Photos',
    img_pince_d_ancrage_et_chapeau_de: 'Photos',
    img_rehausse_traverse: 'Photos',
    img_traverse_fibre_10_20_cm_au: 'Photos',
    pince_d_ancrage_et_chapeau_de: "Pince d'ancrage et chapeau de gendarme",
    pt_a_dan: 'Effort disponible après pose',
    pt_a_dtetu: "Date de l'étude de charge",
    pt_a_haut: "Hauteur en mètre entre le sol et la base de l'infrastructure",
    pt_a_passa: '0 si uniquement pour passage de câbles',
    pt_a_strat: 'Notion Orange disponible dans les PIT',
    pt_a_struc: 'Simple, Moisé, Haubané, Couple',
    pt_abddate: "Date d'abandon de l'objet",
    pt_abdsrc: "Cause de l'abandon de l'objet",
    pt_ad_code: 'Identifiant unique contenu dans la table t_adresse',
    pt_avct: "Attribut synthétisant l'avancement",
    pt_code: 'Code du point technique',
    pt_codeext: 'Code chez un tiers',
    pt_comment: 'Commentaire',
    pt_creadat: "Date de création de l'objet en base",
    pt_dateins: "Date d'installation",
    pt_datemes: 'Date de mise en service',
    pt_detec: "Présence d'un boitier pour un fil de détection",
    pt_etat: 'Etat du point technique',
    pt_etiquet: 'Etiquette sur le terrain',
    pt_gest: 'Gestionnaire',
    pt_gest_do: 'Gestionnaire de la voirie',
    pt_majdate: "Date de la mise à jour de l'objet en base",
    pt_majsrc: 'Source utilisée pour la mise à jour',
    pt_nature: 'Nature du point technique',
    pt_nd_code: 'Code noeud',
    pt_occp: 'Occupation',
    pt_prop: 'Propriétaire',
    pt_prop_do: 'Propriétaire de la voirie',
    pt_proptyp: 'Type de propriété',
    pt_rf_code: 'Référence',
    pt_rotatio: 'Angle du grand axe du point technique',
    pt_secu: "Point technique équipé d'un système de verrouillage",
    pt_statut: "Phase d'avancement",
    pt_typelog: 'Usage du point technique',
    pt_typephy: 'Type de point technique',
    pt_user: 'Utilisateur',
    rehausse_traverse: 'Traverse / rehausse',
    statut: 'Statut audit',
    traverse_fibre_10_20_cm_au: 'Traverse fibre 10 - 20 cm au-dessus du cuivre (peut varier en fonction du projet)',
};

export const options: Record<string, OptionsInterface | SelectInterface> = {
    autres_observations: [
        { label: 'OUI', value: 'OUI' },
        { label: 'NON', value: 'NON' },
    ],
    demi_lune_aero_sout_2_50_m: [
        { label: 'OK', value: 'OK' },
        { label: 'NOK (min)', value: 'NOK_min' },
        { label: 'NOK (MAJ)', value: 'NOK_maj' },
    ],
    donnees_metier: [
        { label: 'OUI', value: 'OUI' },
        { label: 'NON', value: 'NON' },
    ],
    etat_de_l_appui: [
        { label: 'OK', value: 'OK' },
        { label: 'NOK (min)', value: 'NOK_min' },
        { label: 'NOK (MAJ)', value: 'NOK_maj' },
    ],
    etiquetage_cable_s: [
        { label: 'OK', value: 'OK' },
        { label: 'NOK (min)', value: 'NOK_min' },
        { label: 'NOK (MAJ)', value: 'NOK_maj' },
    ],
    fixation_cable_s_sur_rehauss: [
        { label: 'OK', value: 'OK' },
        { label: 'NOK (min)', value: 'NOK_min' },
        { label: 'NOK (MAJ)', value: 'NOK_maj' },
    ],
    fixation_du_des_cables_s_1: [
        { label: 'OK', value: 'OK' },
        { label: 'NOK (min)', value: 'NOK_min' },
        { label: 'NOK (MAJ)', value: 'NOK_maj' },
    ],
    pince_d_ancrage_et_chapeau_de: [
        { label: 'OK', value: 'OK' },
        { label: 'NOK (min)', value: 'NOK_min' },
        { label: 'NOK (MAJ)', value: 'NOK_maj' },
    ],
    pt_a_passa: { true: '' },
    pt_a_strat: { true: '' },
    pt_avct: [
        { label: 'EXISTANT', value: 'E' },
        { label: 'A CREER', value: 'C' },
        { label: 'TRAVAUX', value: 'T' },
        { label: 'EN SERVICE', value: 'S' },
        { label: 'HORS SERVICE', value: 'H' },
        { label: 'ABANDONNE', value: 'A' },
    ],
    pt_detec: { true: '' },
    pt_etat: [
        { label: 'A CHANGER', value: 'HS' },
        { label: 'MAUVAIS ETAT', value: 'ME' },
        { label: 'BON ETAT', value: 'OK' },
        { label: 'NON CONCERNE', value: 'NC' },
    ],
    pt_gest: [{ label: '', value: '' }],
    pt_nature: [
        { label: 'CHAMBRE A1', value: 'A1' },
        { label: 'CHAMBRE A2', value: 'A2' },
        { label: 'CHAMBRE A3', value: 'A3' },
        { label: 'CHAMBRE A4', value: 'A4' },
        { label: 'CHAMBRE A10', value: 'A10' },
        { label: 'CHAMBRE A11', value: 'A11' },
        { label: 'CHAMBRE A12', value: 'A12' },
        { label: 'CHAMBRE A13', value: 'A13' },
        { label: 'CHAMBRE A14', value: 'A14' },
        { label: 'CHAMBRE A4', value: 'A15' },
        { label: 'CHAMBRE A4', value: 'A16' },
        { label: 'CHAMBRE A4', value: 'A17' },
        { label: 'CHAMBRE A4', value: 'A18' },
        { label: 'CHAMBRE B1', value: 'B1' },
        { label: 'CHAMBRE B2', value: 'B2' },
        { label: 'CHAMBRE B3', value: 'B3' },
        { label: 'CHAMBRE B4', value: 'B4' },
        { label: 'CHAMBRE C1', value: 'C1' },
        { label: 'CHAMBRE C2', value: 'C2' },
        { label: 'CHAMBRE C3', value: 'C3' },
        { label: 'CHAMBRE C4', value: 'C4' },
        { label: 'CHAMBRE D1', value: 'D1' },
        { label: 'CHAMBRE D1C', value: 'D1C' },
        { label: 'CHAMBRE D1T', value: 'D1T' },
        { label: 'CHAMBRE D2', value: 'D2' },
        { label: 'CHAMBRE D2C', value: 'D2C' },
        { label: 'CHAMBRE D2T', value: 'D2T' },
        { label: 'CHAMBRE D3', value: 'D3' },
        { label: 'CHAMBRE D3C', value: 'D3C' },
        { label: 'CHAMBRE D3T', value: 'D3T' },
        { label: 'CHAMBRE D4', value: 'D4' },
        { label: 'CHAMBRE D4C', value: 'D4C' },
        { label: 'CHAMBRE D4T', value: 'D4T' },
        { label: 'CHAMBRE D5', value: 'D5' },
        { label: 'CHAMBRE D5C', value: 'D5C' },
        { label: 'CHAMBRE D6', value: 'D6' },
        { label: 'CHAMBRE D6C', value: 'D6C' },
        { label: 'CHAMBRE D11', value: 'D11' },
        { label: 'CHAMBRE D12', value: 'D12' },
        { label: 'CHAMBRE D13', value: 'D13' },
        { label: 'CHAMBRE D14', value: 'D14' },
        { label: 'CHAMBRE E1', value: 'E1' },
        { label: 'CHAMBRE E2', value: 'E2' },
        { label: 'CHAMBRE E3', value: 'E3' },
        { label: 'CHAMBRE E4', value: 'E4' },
        { label: 'CHAMBRE J2C', value: 'J2C' },
        { label: 'CHAMBRE J2C REHAUSSEE', value: 'J2CR' },
        { label: 'CHAMBRE K1C', value: 'K1C' },
        { label: 'CHAMBRE K1C REHAUSSEE', value: 'K1CR' },
        { label: 'CHAMBRE K1T', value: 'K1T' },
        { label: 'CHAMBRE K2C', value: 'K2C' },
        { label: 'CHAMBRE K2C REHAUSSEE', value: 'K2CR' },
        { label: 'CHAMBRE K2T', value: 'K2T' },
        { label: 'CHAMBRE K3C', value: 'K3C' },
        { label: 'CHAMBRE K3C REHAUSSEE', value: 'K3CR' },
        { label: 'CHAMBRE K3T', value: 'K3T' },
        { label: 'CHAMBRE L0T', value: 'L0T' },
        { label: 'CHAMBRE L0T REHAUSSEE', value: 'L0TR' },
        { label: 'CHAMBRE L1C', value: 'L1C' },
        { label: 'CHAMBRE L1T', value: 'L1T' },
        { label: 'CHAMBRE L1T REHAUSSEE', value: 'L1TR' },
        { label: 'CHAMBRE L2C', value: 'L2C' },
        { label: 'CHAMBRE L2T', value: 'L2T' },
        { label: 'CHAMBRE L2T REHAUSSEE', value: 'L2TR' },
        { label: 'CHAMBRE L3C', value: 'L3C' },
        { label: 'CHAMBRE L3T', value: 'L3T' },
        { label: 'CHAMBRE L3T REHAUSSEE', value: 'L3TR' },
        { label: 'CHAMBRE L4C', value: 'L4C' },
        { label: 'CHAMBRE L4T', value: 'L4T' },
        { label: 'CHAMBRE L4T REHAUSSEE', value: 'L4TR' },
        { label: 'CHAMBRE L5C', value: 'L5C' },
        { label: 'CHAMBRE L5T', value: 'L5T' },
        { label: 'CHAMBRE L5T REHAUSSEE', value: 'L5TR' },
        { label: 'CHAMBRE L6T', value: 'L6T' },
        { label: 'CHAMBRE L6T REHAUSSEE', value: 'L6TR' },
        { label: 'CHAMBRE M1C', value: 'M1C' },
        { label: 'CHAMBRE M1C REHAUSSEE', value: 'M1CR' },
        { label: 'CHAMBRE M2T', value: 'M2T' },
        { label: 'CHAMBRE M2T REHAUSSEE', value: 'M2TR' },
        { label: 'CHAMBRE M3C', value: 'M3C' },
        { label: 'CHAMBRE M3C REHAUSSEE', value: 'M3CR' },
        { label: 'CHAMBRE P1C', value: 'P1C' },
        { label: 'CHAMBRE P1C REHAUSSEE', value: 'P1CR' },
        { label: 'CHAMBRE P1T', value: 'P1T' },
        { label: 'CHAMBRE P1T REHAUSSEE', value: 'P1TR' },
        { label: 'CHAMBRE P2C', value: 'P2C' },
        { label: 'CHAMBRE P2C REHAUSSEE', value: 'P2CR' },
        { label: 'CHAMBRE P2T', value: 'P2T' },
        { label: 'CHAMBRE P2T REHAUSSEE', value: 'P2TR' },
        { label: 'CHAMBRE P3C', value: 'P3C' },
        { label: 'CHAMBRE P3T', value: 'P3T' },
        { label: 'CHAMBRE P4C', value: 'P4C' },
        { label: 'CHAMBRE P4T', value: 'P4T' },
        { label: 'CHAMBRE P5C', value: 'P5C' },
        { label: 'CHAMBRE P5T', value: 'P5T' },
        { label: 'CHAMBRE P6C', value: 'P6C' },
        { label: 'CHAMBRE P6T', value: 'P6T' },
        { label: 'CHAMBRE R1T', value: 'R1T' },
        { label: 'CHAMBRE R2T', value: 'R2T' },
        { label: 'CHAMBRE R3T', value: 'R3T' },
        { label: 'CHAMBRE S1', value: 'S1' },
        { label: 'CHAMBRE S2', value: 'S2' },
        { label: 'CHAMBRE S3', value: 'S3' },
        { label: 'CHAMBRE S4', value: 'S4' },
        { label: 'CHAMBRE S5', value: 'S5' },
        { label: 'CHAMBRE S6', value: 'S6' },
        { label: 'CHAMBRE S6bis', value: 'S6bis' },
        { label: 'CHAMBRE S7', value: 'S7' },
        { label: 'CHAMBRE TU1', value: 'TU1' },
        { label: 'CHAMBRE TU2', value: 'TU2' },
        { label: 'CHAMBRE TU4', value: 'TU4' },
        { label: 'CHAMBRE TU6', value: 'TU6' },
        { label: 'CHAMBRE TU8', value: 'TU8' },
        { label: 'CHAMBRE TU10', value: 'TU10' },
        { label: 'OUVRAGE HORS NORMES', value: 'OHN' },
        { label: 'POTEAU BOIS', value: 'PBOI' },
        { label: 'POTEAU BETON', value: 'PBET' },
        { label: 'POTEAU METAL', value: 'PMET' },
        { label: 'POTEAU INDETERMINE', value: 'PIND' },
        { label: 'POTELET', value: 'POTL' },
        { label: 'BOUCHON', value: 'BOU' },
        { label: 'REGARD 30X30', value: 'REG' },
        { label: 'BALCON', value: 'BAL' },
        { label: 'CROCHET', value: 'CRO' },
        { label: 'FAITIERE', value: 'FAI' },
        { label: 'SOUTERRAIN', value: 'STR' },
        { label: 'SOUS-SOL', value: 'SSO' },
        { label: 'TRAVERSE', value: 'TRA' },
        { label: 'SITE MANCHONNAGE Y', value: 'Y' },
        { label: 'INDETERMINE', value: 'IND' },
    ],
    pt_occp: [
        { label: 'VIDE', value: '0' },
        { label: 'NON VIDE EXPLOITABLE', value: '1.1' },
        { label: 'NON VIDE NON EXPLOITABLE', value: '1.2' },
        { label: 'SATUREE', value: '2' },
    ],
    pt_prop: [{ label: '', value: '' }],
    pt_proptyp: [
        { label: 'CONSTRUCTION', value: 'CST' },
        { label: 'RACHAT', value: 'RAC' },
        { label: 'CESSION', value: 'CES' },
        { label: 'IRU', value: 'IRU' },
        { label: 'LOCATION', value: 'LOC' },
        { label: 'OCCUPATION', value: 'OCC' },
    ],
    pt_secu: { true: '' },
    pt_statut: [
        { label: 'ETUDE PRELIMINAIRE', value: 'PRE' },
        { label: 'ETUDE DE DIAGNOSTIC', value: 'DIA' },
        { label: 'AVANT-PROJET', value: 'AVP' },
        { label: 'PROJET', value: 'PRO' },
        { label: 'PASSATION DES MARCHES DE TRAVAUX', value: 'ACT' },
        { label: 'ETUDE D EXECUTION', value: 'EXE' },
        { label: 'TRAVAUX', value: 'TVX' },
        { label: 'RECOLEMENT', value: 'REC' },
        { label: 'MAINTIENT EN CONDITIONS OPERATIONNELLES', value: 'MCO' },
    ],
    pt_typelog: [
        { label: 'TIRAGE', value: 'T' },
        { label: 'RACCORDEMENT', value: 'R' },
        { label: 'INDETERMINE', value: 'I' },
    ],
    pt_typephy: [
        { label: 'APPUI', value: 'A' },
        { label: 'CHAMBRE', value: 'C' },
        { label: 'ANCRAGE FACADE', value: 'F' },
        { label: 'IMMEUBLE', value: 'I' },
        { label: 'AUTRE', value: 'Z' },
    ],
    pt_user: [{ label: '', value: '' }],
    rehausse_traverse: [
        { label: 'OK', value: 'OK' },
        { label: 'NOK (min)', value: 'NOK_min' },
        { label: 'NOK (MAJ)', value: 'NOK_maj' },
    ],
    statut: [
        { label: 'Audit à réaliser', value: 'NON EFFECTUE' },
        { label: 'Audit partiellement réalisé', value: 'PARTIEL' },
        { label: 'Audit réalisé', value: 'TERMINE' },
        { label: 'Non concerné par un audit', value: 'NON CONCERNE' },
    ],
    traverse_fibre_10_20_cm_au: [
        { label: 'OK', value: 'OK' },
        { label: 'NOK (min)', value: 'NOK_min' },
        { label: 'NOK (MAJ)', value: 'NOK_maj' },
    ],
};
