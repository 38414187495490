<template>
    <section class="main">
        <h1 class="title">ConnectField</h1>
        <h2>{{ $t('notFound.error') }}</h2>
        <el-button
            class="btn"
            type="primary"
            @click.stop="navigate"
        >
            {{ $t('globals.back') }}
        </el-button>
    </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    methods: {
        navigate(): void {
            this.$router.replace('/auth');
        },
    },
});
</script>

<style scoped lang="scss">
.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;
    color: #fff;
    background: rgba(17, 25, 40, 1);
}

.title {
    color: #fff;
    text-align: center;
    margin: 0 auto 3rem;
}

h2 {
    margin: 0 auto 3rem;
}

p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.link {
    margin: 1rem auto;
}
</style>
