import type { OptionsInterface, SelectInterface } from '@connect-field/client/components/forms/form.types';

export const labels: Record<string, string> = {
    aplomb: 'Aplomb et distance réseau',
    appui: "Type et taille de l'appui",
    armement_cable: 'État armement et câble',
    code: 'Code',
    comment: 'Commentaire Global',
    comment_pose: 'Commentaire techniques de pose',
    criticite: 'Criticité',
    date_audit: 'Date audit',
    date_pose: 'Date de pose',
    etat_voirie: 'État voirie',
    etiquette_pastille: 'Étiquettage et pastille',
    gestionnaire: 'Gestionnaire',
    img_autres: 'Photo Autres',
    img_poteau_environnement: 'Photo Environnement',
    img_poteau_etiquette: 'Photo Étiquette',
    img_poteau_tete_armement: 'Photo Tête et armement',
    modele: 'Modèle',
    prof_implantation: 'Profondeur implantation et calage',
    ras: 'Adéquation RAS',
    respect_pgc: 'Respect PGC / PMV',
    respect_pose: 'Respect des techniques de pose',
    statut_audit: 'Statut audit',
    type_fonc: 'Type fonctionnalité',
    type_struc: 'Type de structure',
};

export const options: Record<string, OptionsInterface | SelectInterface> = {
    aplomb: [
        { label: 'Conforme', value: 'Conforme' },
        {
            label: 'Non conforme',
            value: 'Non conforme',
        },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    appui: [
        { label: 'Conforme', value: 'Conforme' },
        {
            label: 'Non conforme',
            value: 'Non conforme',
        },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    armement_cable: [
        { label: 'Conforme', value: 'Conforme' },
        {
            label: 'Non conforme',
            value: 'Non conforme',
        },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    criticite: [
        { label: 'MAJEUR', value: 'MAJEUR' },
        { label: 'MINEUR', value: 'MINEUR' },
    ],
    etat_voirie: [
        { label: 'Conforme', value: 'Conforme' },
        {
            label: 'Non conforme',
            value: 'Non conforme',
        },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    etiquette_pastille: [
        { label: 'Conforme', value: 'Conforme' },
        {
            label: 'Non conforme',
            value: 'Non conforme',
        },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    prof_implantation: [
        { label: 'Conforme', value: 'Conforme' },
        {
            label: 'Non conforme',
            value: 'Non conforme',
        },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    ras: [
        { label: 'Conforme', value: 'Conforme' },
        {
            label: 'Non conforme',
            value: 'Non conforme',
        },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    respect_pgc: [
        { label: 'Conforme', value: 'Conforme' },
        {
            label: 'Non conforme',
            value: 'Non conforme',
        },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    respect_pose: [
        { label: 'Conforme', value: 'Conforme' },
        {
            label: 'Non conforme',
            value: 'Non conforme',
        },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    statut_audit: [
        { label: 'NON REALISE', value: 'NON REALISE' },
        {
            label: 'PARTIELLEMENT',
            value: 'PARTIELLEMENT',
        },
        { label: 'TERMINE', value: 'TERMINE' },
    ],
};
