import { getArea, getLength } from 'ol/sphere';
import type { Geometry } from 'ol/geom';

function formatLength(line: Geometry): string {
    const length = getLength(line);
    let output;
    if (length > 100) {
        output = Math.round((length / 1000) * 100) / 100 + ' km';
    } else {
        output = Math.round(length * 100) / 100 + ' m';
    }

    return output;
}

function formatArea(polygon: Geometry): string {
    const area = getArea(polygon);
    let output;
    if (area > 10000) {
        output = Math.round((area / 1000000) * 100) / 100 + ' km\xB2';
    } else {
        output = Math.round(area * 100) / 100 + ' m\xB2';
    }

    return output;
}

export { formatLength, formatArea };
