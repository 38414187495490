import type { OptionsInterface, SelectInterface } from '@connect-field/client/components/forms/form.types';

export const labels: Record<string, string> = {
    aplomb: 'Aplomb et distance réseau',
    appui: "Type et taille de l'appui",
    armement_cable: 'État armement et câble',
    code: 'Code',
    comment_gb: 'Commentaire Global',
    comment_pose: 'Commentaire techniques de pose',
    criticite: 'Criticité',
    date_audit: 'Date audit',
    date_pose: 'Date de pose',
    etat_voirie: 'État voirie',
    etiquette_chambre: "Etiquette d'identification chambre",
    etiquette_pastille: 'Étiquettage et pastille',
    gestionnaire: 'Gestionnaire',
    img_autres: 'Photo Autres',
    img_chambre_dessus: 'Photo Chambre du dessus',
    img_chambre_environnement: 'Photo Environnement global',
    img_chambre_masques: 'Photo Masques',
    img_poteau_environnement: 'Photo Environnement',
    img_poteau_etiquette: 'Photo Étiquette',
    img_poteau_tete_armement: 'Photo Tête et armement',
    img_refection: 'Photo Réfection',
    masque_percute: 'Qualité des percussions, des masques et bouchons',
    modele: 'Modèle',
    nb_fourreaux: 'Nombre de fourreaux et plynox',
    percement_chambre: 'Percement de la chambre',
    prof_implantation: 'Profondeur implantation et calage',
    qualite_refection: 'Qualité de la réfection',
    ras: 'Adéquation RAS',
    respect_coupe: 'Respect de la coupe type',
    respect_pgc: 'Respect PGC / PMV',
    respect_pose: 'Respect des techniques de pose',
    scellement_cadre: 'Scellement du cadre et tampon',
    statut_audit: 'Statut audit',
    type_chambre: 'Type de chambre conforme aux règles de dimensionnement',
    type_fonc: 'Type fonctionnalité',
    type_struc: 'Type de structure',
};

export const options: Record<string, OptionsInterface | SelectInterface> = {
    aplomb: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non conforme', value: 'Non conforme' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    appui: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non conforme', value: 'Non conforme' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    armement_cable: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non conforme', value: 'Non conforme' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    criticite: [
        { label: 'MAJEUR', value: 'MAJEUR' },
        { label: 'MINEUR', value: 'MINEUR' },
    ],
    etat_voirie: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non conforme', value: 'Non conforme' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    etiquette_chambre: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non conforme', value: 'Non conforme' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    etiquette_pastille: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non conforme', value: 'Non conforme' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    masque_percute: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non conforme', value: 'Non conforme' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    nb_fourreaux: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non conforme', value: 'Non conforme' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    percement_chambre: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non conforme', value: 'Non conforme' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    prof_implantation: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non conforme', value: 'Non conforme' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    qualite_refection: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non conforme', value: 'Non conforme' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    ras: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non conforme', value: 'Non conforme' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    respect_coupe: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non conforme', value: 'Non conforme' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    respect_pgc: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non conforme', value: 'Non conforme' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    respect_pose: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non conforme', value: 'Non conforme' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    scellement_cadre: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non conforme', value: 'Non conforme' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    statut_audit: [
        { label: 'NON REALISE', value: 'NON REALISE' },
        { label: 'PARTIELLEMENT', value: 'PARTIELLEMENT' },
        { label: 'TERMINE', value: 'TERMINE' },
    ],
    type_chambre: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non conforme', value: 'Non conforme' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
};
