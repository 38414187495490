<template>
    <FormWrapper
        v-if="!loading"
        ref="customFormRef"
        :images-model="images"
        :form-model="form"
        :mandatory-fields="mandatoryFields"
        :changes-model="changes"
        :layer-name="layerName"
        :object-id="objectId"
        :project-id="projectId"
        :latitude="latitude"
        :longitude="longitude"
        @update:images-model="
            (e) => {
                images = e;
            }
        "
        @update:value-missing="
            (e) => {
                valueMissing = e;
            }
        "
        @update:form-model="
            (e) => {
                form = e;
            }
        "
        @update:changes-model="
            (e) => {
                changes = e;
            }
        "
    >
        <template #menu_tabs>
            <el-row class="scroll-menu">
                <el-col :span="12">
                    <div
                        @click="selectTab('GENERAL')"
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'GENERAL' }"
                    >
                        GENERAL
                    </div>
                </el-col>

                <el-col :span="12">
                    <div
                        @click="selectTab('MECANIQUE')"
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'MECANIQUE' }"
                    >
                        MECANIQUE
                    </div>
                </el-col>
            </el-row>
        </template>
        <template #main_form>
            <el-row>
                <el-col
                    :span="24"
                    v-show="selectedTab === 'GENERAL'"
                >
                    <CustomInput
                        :label="labels.pt_code + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.pt_code"
                        :disabled="true"
                    />
                    <CustomInputSelect
                        :label="labels.statut + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.statut"
                        :value="form.statut"
                        @update:value="
                            (newValue) => saveData('statut', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.pt_codeext + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.pt_codeext"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.pt_etiquet + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.pt_etiquet"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('pt_etiquet', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.donnees_metier"
                        :options="options.donnees_metier"
                        :value="form.donnees_metier"
                        @update:value="
                            (newValue) => saveData('donnees_metier', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_pt_nd_code()"
                        :label="labels.pt_nd_code"
                        :value="form.pt_nd_code"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('pt_nd_code', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_pt_ad_code()"
                        :label="labels.pt_ad_code"
                        :value="form.pt_ad_code"
                        @update:value="
                            (newValue) => saveData('pt_ad_code', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_pt_gest_do()"
                        :label="labels.pt_gest_do"
                        :value="form.pt_gest_do"
                        :maxlength="20"
                        @update:value="
                            (newValue) => saveData('pt_gest_do', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_pt_prop_do()"
                        :label="labels.pt_prop_do"
                        :value="form.pt_prop_do"
                        @update:value="
                            (newValue) => saveData('pt_prop_do', newValue)
                        "
                    />
                    <CustomInputSelect
                        v-show="condition_pt_prop()"
                        :label="labels.pt_prop"
                        :options="options.pt_prop"
                        :value="form.pt_prop"
                        @update:value="
                            (newValue) => saveData('pt_prop', newValue)
                        "
                    />
                    <CustomInputSelect
                        v-show="condition_pt_gest()"
                        :label="labels.pt_gest"
                        :options="options.pt_gest"
                        :value="form.pt_gest"
                        @update:value="
                            (newValue) => saveData('pt_gest', newValue)
                        "
                    />
                    <CustomInputSelect
                        v-show="condition_pt_user()"
                        :label="labels.pt_user"
                        :options="options.pt_user"
                        :value="form.pt_user"
                        @update:value="
                            (newValue) => saveData('pt_user', newValue)
                        "
                    />
                    <CustomInputSelect
                        v-show="condition_pt_proptyp()"
                        :label="labels.pt_proptyp"
                        :options="options.pt_proptyp"
                        :value="form.pt_proptyp"
                        @update:value="
                            (newValue) => saveData('pt_proptyp', newValue)
                        "
                    />
                    <CustomInputSelect
                        v-show="condition_pt_statut()"
                        :label="labels.pt_statut"
                        :options="options.pt_statut"
                        :value="form.pt_statut"
                        @update:value="
                            (newValue) => saveData('pt_statut', newValue)
                        "
                    />
                    <CustomInputSelect
                        v-show="condition_pt_etat()"
                        :label="labels.pt_etat"
                        :options="options.pt_etat"
                        :value="form.pt_etat"
                        @update:value="
                            (newValue) => saveData('pt_etat', newValue)
                        "
                    />
                    <CustomDatePicker
                        v-show="condition_pt_dateins()"
                        :label="labels.pt_dateins + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.pt_dateins"
                        @update:value="
                            (newValue) => saveData('pt_dateins', newValue)
                        "
                    />
                    <CustomDatePicker
                        v-show="condition_pt_datemes()"
                        :label="labels.pt_datemes + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.pt_datemes"
                        @update:value="
                            (newValue) => saveData('pt_datemes', newValue)
                        "
                    />
                    <CustomInputSelect
                        v-show="condition_pt_avct()"
                        :label="labels.pt_avct"
                        :options="options.pt_avct"
                        :value="form.pt_avct"
                        @update:value="
                            (newValue) => saveData('pt_avct', newValue)
                        "
                    />
                    <CustomInputSelect
                        v-show="condition_pt_typephy()"
                        :label="labels.pt_typephy + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.pt_typephy"
                        :value="form.pt_typephy"
                        :disabled="true"
                    />
                    <CustomInputSelect
                        v-show="condition_pt_typelog()"
                        :label="labels.pt_typelog"
                        :options="options.pt_typelog"
                        :value="form.pt_typelog"
                        @update:value="
                            (newValue) => saveData('pt_typelog', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_pt_rf_code()"
                        :label="labels.pt_rf_code"
                        :value="form.pt_rf_code"
                        @update:value="
                            (newValue) => saveData('pt_rf_code', newValue)
                        "
                    />
                    <CustomInputSelect
                        v-show="condition_pt_nature()"
                        :label="labels.pt_nature"
                        :options="options.pt_nature"
                        :value="form.pt_nature"
                        @update:value="
                            (newValue) => saveData('pt_nature', newValue)
                        "
                    />
                    <CustomInputSwitch
                        v-show="condition_pt_secu()"
                        :label="labels.pt_secu"
                        :options="options.pt_secu"
                        :value="form.pt_secu"
                        @update:value="
                            (newValue) => saveData('pt_secu', newValue)
                        "
                    />
                    <CustomInputSelect
                        v-show="condition_pt_occp()"
                        :label="labels.pt_occp"
                        :options="options.pt_occp"
                        :value="form.pt_occp"
                        @update:value="
                            (newValue) => saveData('pt_occp', newValue)
                        "
                    />
                    <InputNumber
                        v-show="condition_pt_a_dan()"
                        :label="labels.pt_a_dan"
                        :value="form.pt_a_dan"
                        @update:value="
                            (newValue) => saveData('pt_a_dan', newValue)
                        "
                    />
                    <CustomDatePicker
                        v-show="condition_pt_a_dtetu()"
                        :label="labels.pt_a_dtetu"
                        :value="form.pt_a_dtetu"
                        @update:value="
                            (newValue) => saveData('pt_a_dtetu', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_pt_a_struc()"
                        :label="labels.pt_a_struc"
                        :value="form.pt_a_struc"
                        :maxlength="100"
                        @update:value="
                            (newValue) => saveData('pt_a_struc', newValue)
                        "
                    />
                    <InputNumber
                        v-show="condition_pt_a_haut()"
                        :label="labels.pt_a_haut"
                        :value="form.pt_a_haut"
                        @update:value="
                            (newValue) => saveData('pt_a_haut', newValue)
                        "
                    />
                    <CustomInputSwitch
                        v-show="condition_pt_a_passa()"
                        :label="labels.pt_a_passa"
                        :options="options.pt_a_passa"
                        :value="form.pt_a_passa"
                        @update:value="
                            (newValue) => saveData('pt_a_passa', newValue)
                        "
                    />
                    <CustomInputSwitch
                        v-show="condition_pt_a_strat()"
                        :label="labels.pt_a_strat"
                        :options="options.pt_a_strat"
                        :value="form.pt_a_strat"
                        @update:value="
                            (newValue) => saveData('pt_a_strat', newValue)
                        "
                    />
                    <InputNumber
                        v-show="condition_pt_rotatio()"
                        :label="labels.pt_rotatio"
                        :value="form.pt_rotatio"
                        @update:value="
                            (newValue) => saveData('pt_rotatio', newValue)
                        "
                    />
                    <CustomInputSwitch
                        v-show="condition_pt_detec()"
                        :label="labels.pt_detec"
                        :options="options.pt_detec"
                        :value="form.pt_detec"
                        @update:value="
                            (newValue) => saveData('pt_detec', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_pt_comment()"
                        :label="labels.pt_comment"
                        :value="form.pt_comment"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('pt_comment', newValue)
                        "
                    />
                    <CustomDatePicker
                        v-show="condition_pt_creadat()"
                        :label="labels.pt_creadat + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.pt_creadat"
                        @update:value="
                            (newValue) => saveData('pt_creadat', newValue)
                        "
                    />
                    <CustomDatePicker
                        v-show="condition_pt_majdate()"
                        :label="labels.pt_majdate"
                        :value="form.pt_majdate"
                        @update:value="
                            (newValue) => saveData('pt_majdate', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_pt_majsrc()"
                        :label="labels.pt_majsrc"
                        :value="form.pt_majsrc"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('pt_majsrc', newValue)
                        "
                    />
                    <CustomDatePicker
                        v-show="condition_pt_abddate()"
                        :label="labels.pt_abddate"
                        :value="form.pt_abddate"
                        @update:value="
                            (newValue) => saveData('pt_abddate', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_pt_abdsrc()"
                        :label="labels.pt_abdsrc"
                        :value="form.pt_abdsrc"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('pt_abdsrc', newValue)
                        "
                    />
                </el-col>
                <el-col
                    :span="24"
                    v-show="selectedTab === 'MECANIQUE'"
                >
                    <CustomRadioButton
                        :label="labels.etat_de_l_appui"
                        :options="options.etat_de_l_appui"
                        :value="form.etat_de_l_appui"
                        size="small"
                        @update:value="
                            (newValue) => saveData('etat_de_l_appui', newValue)
                        "
                    />

                    <div v-show="condition_img_etat_de_l_appui()">
                        <InputCamera
                            :label="labels.img_etat_de_l_appui"
                            :images="images.img_etat_de_l_appui"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage('img_etat_de_l_appui', newValue)
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="condition_commentaire_etat_de_l_appui()"
                        :label="labels.commentaire_etat_de_l_appui"
                        :value="form.commentaire_etat_de_l_appui"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_etat_de_l_appui',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.pince_d_ancrage_et_chapeau_de"
                        :options="options.pince_d_ancrage_et_chapeau_de"
                        :value="form.pince_d_ancrage_et_chapeau_de"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'pince_d_ancrage_et_chapeau_de',
                                    newValue,
                                )
                        "
                    />

                    <div v-show="condition_img_pince_d_ancrage_et_chapeau_de()">
                        <InputCamera
                            :label="labels.img_pince_d_ancrage_et_chapeau_de"
                            :images="images.img_pince_d_ancrage_et_chapeau_de"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage(
                                        'img_pince_d_ancrage_et_chapeau_de',
                                        newValue,
                                    )
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="
                            condition_commentaire_pince_d_ancrage_et_chapeau_de()
                        "
                        :label="
                            labels.commentaire_pince_d_ancrage_et_chapeau_de
                        "
                        :value="form.commentaire_pince_d_ancrage_et_chapeau_de"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_pince_d_ancrage_et_chapeau_de',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.demi_lune_aero_sout_2_50_m"
                        :options="options.demi_lune_aero_sout_2_50_m"
                        :value="form.demi_lune_aero_sout_2_50_m"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('demi_lune_aero_sout_2_50_m', newValue)
                        "
                    />

                    <div v-show="condition_img_demi_lune_aero_sout_2_50_m()">
                        <InputCamera
                            :label="labels.img_demi_lune_aero_sout_2_50_m"
                            :images="images.img_demi_lune_aero_sout_2_50_m"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage(
                                        'img_demi_lune_aero_sout_2_50_m',
                                        newValue,
                                    )
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="
                            condition_commentaire_demi_lune_aero_sout_2_50_m()
                        "
                        :label="labels.commentaire_demi_lune_aero_sout_2_50_m"
                        :value="form.commentaire_demi_lune_aero_sout_2_50_m"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_demi_lune_aero_sout_2_50_m',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.fixation_du_des_cables_s_1"
                        :options="options.fixation_du_des_cables_s_1"
                        :value="form.fixation_du_des_cables_s_1"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('fixation_du_des_cables_s_1', newValue)
                        "
                    />

                    <div v-show="condition_img_fixation_du_des_cables_s_1()">
                        <InputCamera
                            :label="labels.img_fixation_du_des_cables_s_1"
                            :images="images.img_fixation_du_des_cables_s_1"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage(
                                        'img_fixation_du_des_cables_s_1',
                                        newValue,
                                    )
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="
                            condition_commentaire_fixation_du_des_cables_s_1()
                        "
                        :label="labels.commentaire_fixation_du_des_cables_s_1"
                        :value="form.commentaire_fixation_du_des_cables_s_1"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_fixation_du_des_cables_s_1',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.rehausse_traverse"
                        :options="options.rehausse_traverse"
                        :value="form.rehausse_traverse"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('rehausse_traverse', newValue)
                        "
                    />

                    <div v-show="condition_img_rehausse_traverse()">
                        <InputCamera
                            :label="labels.img_rehausse_traverse"
                            :images="images.img_rehausse_traverse"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage('img_rehausse_traverse', newValue)
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="condition_commentaire_rehausse_traverse()"
                        :label="labels.commentaire_rehausse_traverse"
                        :value="form.commentaire_rehausse_traverse"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_rehausse_traverse',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.fixation_cable_s_sur_rehauss"
                        :options="options.fixation_cable_s_sur_rehauss"
                        :value="form.fixation_cable_s_sur_rehauss"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'fixation_cable_s_sur_rehauss',
                                    newValue,
                                )
                        "
                    />

                    <div v-show="condition_img_fixation_cable_s_sur_rehauss()">
                        <InputCamera
                            :label="labels.img_fixation_cable_s_sur_rehauss"
                            :images="images.img_fixation_cable_s_sur_rehauss"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage(
                                        'img_fixation_cable_s_sur_rehauss',
                                        newValue,
                                    )
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="
                            condition_commentaire_fixation_cable_s_sur_rehauss()
                        "
                        :label="labels.commentaire_fixation_cable_s_sur_rehauss"
                        :value="form.commentaire_fixation_cable_s_sur_rehauss"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_fixation_cable_s_sur_rehauss',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.traverse_fibre_10_20_cm_au"
                        :options="options.traverse_fibre_10_20_cm_au"
                        :value="form.traverse_fibre_10_20_cm_au"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('traverse_fibre_10_20_cm_au', newValue)
                        "
                    />

                    <div v-show="condition_img_traverse_fibre_10_20_cm_au()">
                        <InputCamera
                            :label="labels.img_traverse_fibre_10_20_cm_au"
                            :images="images.img_traverse_fibre_10_20_cm_au"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage(
                                        'img_traverse_fibre_10_20_cm_au',
                                        newValue,
                                    )
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="
                            condition_commentaire_traverse_fibre_10_20_cm_au()
                        "
                        :label="labels.commentaire_traverse_fibre_10_20_cm_au"
                        :value="form.commentaire_traverse_fibre_10_20_cm_au"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_traverse_fibre_10_20_cm_au',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.etiquetage_cable_s"
                        :options="options.etiquetage_cable_s"
                        :value="form.etiquetage_cable_s"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('etiquetage_cable_s', newValue)
                        "
                    />

                    <div v-show="condition_img_etiquetage_cable_s()">
                        <InputCamera
                            :label="labels.img_etiquetage_cable_s"
                            :images="images.img_etiquetage_cable_s"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage(
                                        'img_etiquetage_cable_s',
                                        newValue,
                                    )
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="condition_commentaire_etiquetage_cable_s()"
                        :label="labels.commentaire_etiquetage_cable_s"
                        :value="form.commentaire_etiquetage_cable_s"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_etiquetage_cable_s',
                                    newValue,
                                )
                        "
                    />
                    <CustomInputSelect
                        :label="labels.autres_observations"
                        :options="options.autres_observations"
                        :value="form.autres_observations"
                        @update:value="
                            (newValue) =>
                                saveData('autres_observations', newValue)
                        "
                    />

                    <div v-show="condition_img_autres_observations()">
                        <InputCamera
                            :label="labels.img_autres_observations"
                            :images="images.img_autres_observations"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage(
                                        'img_autres_observations',
                                        newValue,
                                    )
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="condition_commentaire_autres_observations()"
                        :label="labels.commentaire_autres_observations"
                        :value="form.commentaire_autres_observations"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_autres_observations',
                                    newValue,
                                )
                        "
                    />
                </el-col>
            </el-row>
        </template>
    </FormWrapper>
</template>

<script lang="ts">
/* eslint-disable */
import { defineComponent } from 'vue';

import type { FormDataInterface } from '@connect-field/client/components/forms/form.types';
import FormTemplate from '@connect-field/client/components/forms/FormTemplate.vue';

import {
    labels,
    options,
} from '@connect-field/client/components/forms/DrivOptic/form_audit_appui_passage_ft_drivoptic.data';

interface DataInterface extends FormDataInterface {}

export default defineComponent({
    extends: FormTemplate,
    data(): DataInterface {
        return {
            changes: false,
            form: {},
            images: {
                img_etat_de_l_appui: [],
                img_pince_d_ancrage_et_chapeau_de: [],
                img_demi_lune_aero_sout_2_50_m: [],
                img_fixation_du_des_cables_s_1: [],
                img_rehausse_traverse: [],
                img_fixation_cable_s_sur_rehauss: [],
                img_traverse_fibre_10_20_cm_au: [],
                img_etiquetage_cable_s: [],
                img_autres_observations: [],
            },
            labels,
            loading: true,
            mandatoryFields: [
                'pt_code',
                'statut',
                'pt_codeext',
                'pt_etiquet',
                'pt_dateins',
                'pt_datemes',
                'pt_typephy',
                'pt_creadat',
            ],
            options,
            selectedTab: 'GENERAL',
            valueMissing: false,
        };
    },
    methods: {
        condition_pt_nd_code(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_ad_code(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_gest_do(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_prop_do(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_prop(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_gest(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_user(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_proptyp(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_statut(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_etat(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_dateins(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_datemes(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_avct(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_typephy(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_typelog(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_rf_code(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_nature(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_secu(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_occp(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_a_dan(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_a_dtetu(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_a_struc(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_a_haut(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_a_passa(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_a_strat(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_rotatio(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_detec(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_comment(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_creadat(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_majdate(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_majsrc(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_abddate(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_pt_abdsrc(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_img_etat_de_l_appui(): boolean {
            const { etat_de_l_appui } = this.form;
            return etat_de_l_appui?.startsWith('NOK');
        },
        condition_commentaire_etat_de_l_appui(): boolean {
            const { etat_de_l_appui } = this.form;
            return etat_de_l_appui?.startsWith('NOK');
        },
        condition_img_pince_d_ancrage_et_chapeau_de(): boolean {
            const { pince_d_ancrage_et_chapeau_de } = this.form;
            return pince_d_ancrage_et_chapeau_de?.startsWith('NOK');
        },
        condition_commentaire_pince_d_ancrage_et_chapeau_de(): boolean {
            const { pince_d_ancrage_et_chapeau_de } = this.form;
            return pince_d_ancrage_et_chapeau_de?.startsWith('NOK');
        },
        condition_img_demi_lune_aero_sout_2_50_m(): boolean {
            const { demi_lune_aero_sout_2_50_m } = this.form;
            return demi_lune_aero_sout_2_50_m?.startsWith('NOK');
        },
        condition_commentaire_demi_lune_aero_sout_2_50_m(): boolean {
            const { demi_lune_aero_sout_2_50_m } = this.form;
            return demi_lune_aero_sout_2_50_m?.startsWith('NOK');
        },
        condition_img_fixation_du_des_cables_s_1(): boolean {
            const { fixation_du_des_cables_s_1 } = this.form;
            return fixation_du_des_cables_s_1?.startsWith('NOK');
        },
        condition_commentaire_fixation_du_des_cables_s_1(): boolean {
            const { fixation_du_des_cables_s_1 } = this.form;
            return fixation_du_des_cables_s_1?.startsWith('NOK');
        },
        condition_img_rehausse_traverse(): boolean {
            const { rehausse_traverse } = this.form;
            return rehausse_traverse?.startsWith('NOK');
        },
        condition_commentaire_rehausse_traverse(): boolean {
            const { rehausse_traverse } = this.form;
            return rehausse_traverse?.startsWith('NOK');
        },
        condition_img_fixation_cable_s_sur_rehauss(): boolean {
            const { fixation_cable_s_sur_rehauss } = this.form;
            return fixation_cable_s_sur_rehauss?.startsWith('NOK');
        },
        condition_commentaire_fixation_cable_s_sur_rehauss(): boolean {
            const { fixation_cable_s_sur_rehauss } = this.form;
            return fixation_cable_s_sur_rehauss?.startsWith('NOK');
        },
        condition_img_traverse_fibre_10_20_cm_au(): boolean {
            const { traverse_fibre_10_20_cm_au } = this.form;
            return traverse_fibre_10_20_cm_au?.startsWith('NOK');
        },
        condition_commentaire_traverse_fibre_10_20_cm_au(): boolean {
            const { traverse_fibre_10_20_cm_au } = this.form;
            return traverse_fibre_10_20_cm_au?.startsWith('NOK');
        },
        condition_img_etiquetage_cable_s(): boolean {
            const { etiquetage_cable_s } = this.form;
            return etiquetage_cable_s?.startsWith('NOK');
        },
        condition_commentaire_etiquetage_cable_s(): boolean {
            const { etiquetage_cable_s } = this.form;
            return etiquetage_cable_s?.startsWith('NOK');
        },
        condition_img_autres_observations(): boolean {
            const { autres_observations } = this.form;
            return autres_observations === 'OUI';
        },
        condition_commentaire_autres_observations(): boolean {
            const { autres_observations } = this.form;
            return autres_observations === 'OUI';
        },
    },
});
</script>
