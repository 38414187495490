<template>
    <BaseDialog
        :show="showUpdateDialog"
        :title="$t('informations.update.title')"
    >
        <br />
        <p>{{ $t('informations.update.reload') }}</p>
        <br />
        <template #actions>
            <BaseDialogButton
                :full-width="true"
                label="Ok"
                button-type="default"
                @click="showUpdateDialog = false"
            />
        </template>
    </BaseDialog>

    <BaseDialog
        :show="showDownTimeDialog"
        :title="`${$t('informations.downtime.title')} ${downTime.time}`"
    >
        <br />
        <p>{{ downTime.message }}</p>
        <br />
        <template #actions>
            <BaseDialogButton
                :full-width="true"
                label="Ok"
                button-type="default"
                @click="showDownTimeDialog = false"
            />
        </template>
    </BaseDialog>

    <div
        id="map"
        ref="mapRef"
        class="w-full h-full fixed"
    />
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';
import Map, { type AtPixelOptions } from 'ol/Map';
import type { MapBrowserEvent, MapEvent } from 'ol';
import type BaseEvent from 'ol/events/Event';
import type BaseLayer from 'ol/layer/Base';
import Feature from 'ol/Feature';
import type Layer from 'ol/layer/Layer';
import { mapState } from 'pinia';
import PinchRotate from 'ol/interaction/PinchRotate';
import type RenderFeature from 'ol/render/Feature';

import {
    buildObjectDataFromFeature,
    generateMapOptions,
    type ObjectDataInterface,
    setPinOnMap,
} from '@connect-field/client/services/map.service';
import {
    generateGeolocationLayer,
    generateHighLightLayer,
    generatePinLayer,
    generateSearchPbfLayer,
} from '@connect-field/client/services/layers/utilityLayers.service';
import {
    generateIGNBackground,
    generateOSMBackground,
} from '@connect-field/client/services/layers/basemapLayers.service';
import {
    getDownTime,
    isClientOutdated,
} from '@connect-field/client/services/settings.service';
import {
    LayerRenderFormatEnum,
    ProjectConfigurationLayerTypeEnum,
} from '@connect-field/client/sdk/generated';
import BaseDialog from '@connect-field/client/components/ui/BaseDialog.vue';
import BaseDialogButton from '@connect-field/client/components/ui/BaseDialogButton.vue';
import { detectDevice } from '@connect-field/client/utilities/tools';
import MeasureService from '@connect-field/client/services/measure.service';
import { updateLayers } from '@connect-field/client/services/layers.service';
import useCoordinateStore from '@connect-field/client/stores/coordinate';
import useLayersStore from '@connect-field/client/stores/layers';
import useMapStore from '@connect-field/client/stores/map';
import useNavigationStore from '@connect-field/client/stores/navigation';
import useObjectStore from '@connect-field/client/stores/object';
import useProjectsStore from '@connect-field/client/stores/projects';

interface DataInterface {
    downTime: { message: string; time: string };
    intervalTime: number;
    map?: Map;
    showDownTimeDialog: boolean;
    showUpdateDialog: boolean;
}

export default defineComponent({
    components: {
        BaseDialog,
        BaseDialogButton,
    },
    props: {
        onLoaded: {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            type: Function as PropType<(event: BaseEvent | Event) => unknown>,
            default: () => {},
        },
    },
    setup() {
        return {
            coordinateStore: useCoordinateStore(),
            layersStore: useLayersStore(),
            mapStore: useMapStore(),
            navigationStore: useNavigationStore(),
            objectStore: useObjectStore(),
            projectsStore: useProjectsStore(),
        };
    },
    $refs: {
        mapRef: HTMLDivElement,
    },
    data(): DataInterface {
        return {
            downTime: { message: '', time: '' },
            intervalTime: 600000,
            showDownTimeDialog: false,
            showUpdateDialog: false,
        };
    },
    computed: {
        ...mapState(useProjectsStore, {
            isProjectOffline: 'isOffline',
            selectedProjectId: 'selectedProjectId',
            zoneSelection: 'zoneSelection',
        }),
    },
    async mounted() {
        await this.checkAppVersion();
        await this.checkDownTime();

        const layers = [];

        layers.push(generateOSMBackground());
        // This function has to be used without await because the map can be blocked in case of IGN server problems
        generateIGNBackground()
            .then((layer) => {
                layers.push(layer);

                // If the layer is push after map initialization we forced refresh the map
                if (
                    this.map &&
                    typeof this.map.addLayer === 'function' &&
                    typeof this.map.changed === 'function'
                ) {
                    this.map.addLayer(layer);
                    this.map.changed();
                }
            })
            .catch((error) => {
                console.error('Erreur avec le fond de carte IGN', error);
            });

        layers.push(generateGeolocationLayer());
        layers.push(generatePinLayer());
        layers.push(generateHighLightLayer());
        layers.push(generateSearchPbfLayer());

        const options = generateMapOptions();
        options.layers = layers;
        // the map will be created using the 'map-root' ref
        options.target = this.$refs.mapRef as HTMLElement;
        this.map = new Map(options);

        let _zoom = Math.trunc(Number(this.map.getView().getZoom()));
        this.map.on('moveend', (evt: MapEvent) => {
            if (_zoom !== Math.trunc(Number(evt.map.getView().getZoom()))) {
                _zoom = Math.trunc(Number(evt.map.getView().getZoom()));
                this.mapStore.$patch({ zoomLevel: _zoom });
                console.debug('[map] zoomend', _zoom);
            }
        });

        this.map.on('singleclick', (evt: MapBrowserEvent<UIEvent>): void => {
            if (
                MeasureService.measurementLayer ||
                !this.selectedProjectId ||
                !this.map
            ) {
                return;
            }

            if (this.zoneSelection && this.$route.name === 'projectDownload') {
                // Selection of zones for offline download
                const options: AtPixelOptions = {
                    hitTolerance: 2,
                    layerFilter: (layer: Layer): boolean => {
                        return (
                            layer.getProperties().selectionLayer &&
                            layer.getVisible()
                        );
                    },
                };

                this.map.forEachFeatureAtPixel(
                    evt.pixel,
                    (feature: RenderFeature | Feature) => {
                        if (!(feature instanceof Feature)) {
                            return;
                        }

                        const selectedZoneId = feature.getId();
                        if (typeof selectedZoneId === 'undefined') {
                            return;
                        }
                        feature.setProperties({
                            _isSelected: !feature.getProperties()._isSelected,
                        });
                        if (feature.getProperties()._isSelected) {
                            this.projectsStore.addLayerSelectedLayersOffline(
                                selectedZoneId,
                            );
                        } else {
                            this.projectsStore.removeLayerSelectedLayersOffline(
                                selectedZoneId,
                            );
                        }
                    },
                    options,
                );
            } else if (this.$route.name !== 'projectDownload') {
                // Interaction with objects on map except when page for download project is displayed

                this.objectStore.resetStore();
                this.navigationStore.resetCurrentObjects();
                this.coordinateStore.setCoordinate(evt.coordinate);

                setPinOnMap(evt.coordinate);

                const objects: Array<ObjectDataInterface> = [];

                this.map.forEachFeatureAtPixel(
                    evt.pixel,
                    (feature: RenderFeature | Feature, layer: Layer): void => {
                        if (
                            !layer.getProperties().online &&
                            layer.getProperties().format ===
                                LayerRenderFormatEnum.PBF
                        ) {
                            console.log('mbtiles');
                        }

                        const object = buildObjectDataFromFeature(
                            layer,
                            feature,
                        );

                        if (object) {
                            objects.push(object);
                        }
                    },
                    {
                        hitTolerance: 10,
                        layerFilter: (layer: BaseLayer): boolean => {
                            return (
                                layer.getVisible() &&
                                !layer.getProperties().global &&
                                !layer.getProperties().selectionLayer &&
                                layer.getProperties().type !==
                                    ProjectConfigurationLayerTypeEnum.VIRTUAL
                            );
                        },
                    },
                );

                this.navigationStore.setCurrentObjects(objects);

                if (this.isProjectOffline) {
                    // this.coordinateStore.findAddress(); // TODO : Is it needed in offline mode ?
                    this.$router.push({
                        name: 'objectListOffline',
                        params: { projectId: this.selectedProjectId },
                    });
                } else {
                    this.coordinateStore.findAddress();
                    this.$router.push({
                        name: 'objectList',
                        params: { projectId: this.selectedProjectId },
                    });
                }
            }
        });

        if (detectDevice() !== 'ios') {
            this.map.addInteraction(
                new PinchRotate({
                    // Users have to rotate its fingers for at least 45° to engage a rotation
                    threshold: Math.PI / 4,
                }),
            );
        }

        this.mapStore.$patch({ map: this.map });
        if (this.onLoaded) {
            this.map.once('postrender', this.onLoaded);
        }

        const mapLayers = this.map.getLayers().getArray();
        this.layersStore.$patch({ layers: mapLayers });

        // Update layers in online mode every 10 minutes
        setInterval(
            () => updateLayers(mapLayers, this.projectsStore.isOffline),
            10 * 60 * 1000,
        );
    },
    methods: {
        async checkAppVersion(): Promise<void> {
            try {
                this.showUpdateDialog = await isClientOutdated();
            } catch {
                console.debug('Erreur lors de la récupération de la version');

                return;
            }

            setTimeout(async () => {
                await this.checkAppVersion();
            }, this.intervalTime);
        },
        async checkDownTime(): Promise<void> {
            let downTime: Date;
            try {
                const { message, time } = await getDownTime();
                downTime = time;
                this.downTime.message = message;
            } catch {
                console.debug('[downtime] cannot retrieve downtime message');

                return;
            }

            if (!downTime) {
                return;
            }

            this.showDownTimeDialog = Date.now() < downTime?.getTime();

            if (this.showDownTimeDialog) {
                this.downTime.time = new Intl.DateTimeFormat(
                    this.$root?.$i18n.locale ?? 'FR-fr',
                    {
                        dateStyle: 'full',
                        timeStyle: 'short',
                    },
                ).format(downTime);
            }

            setTimeout(async () => {
                await this.checkDownTime();
            }, this.intervalTime);
        },
    },
});
</script>
