<template>
    <teleport to="body">
        <div
            v-if="show"
            class="backdrop"
            @click="tryBackdropClose"
        />
        <transition name="dialog">
            <div
                v-if="show"
                class="dialog"
            >
                <header>
                    <slot name="header">
                        <h3>{{ title }}</h3>
                    </slot>
                </header>
                <main><slot /></main>
                <footer>
                    <slot name="actions">
                        <BaseDialogButton
                            :label="closeBtnLabel"
                            button-type="default"
                            @click="tryClose"
                        />
                    </slot>
                </footer>
            </div>
        </transition>
    </teleport>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import BaseDialogButton from '@connect-field/client/components/ui/BaseDialogButton.vue';

export default defineComponent({
    components: {
        BaseDialogButton,
    },
    props: {
        closeBtnLabel: {
            type: String,
            default: 'Fermer',
        },
        hasBackdropEscape: {
            type: Boolean,
            default: true,
        },
        show: {
            type: Boolean,
            required: true,
        },
        title: {
            type: String,
            default: '',
        },
    },
    emits: ['close'],
    methods: {
        tryBackdropClose(): void {
            if (!this.hasBackdropEscape) {
                return;
            }
            this.tryClose();
        },
        tryClose(): void {
            this.$emit('close');
        },
    },
});
</script>

<style scoped lang="scss">
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: var(--zindex-dialog);
}

.dialog {
    position: fixed;
    top: 5vh;
    left: 5%;
    width: 90%;
    z-index: var(--zindex-dialog);
    border-radius: 12px;
    border: none;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    margin: 0;
    overflow: auto;
    background-color: white;
    padding: 1rem;
    max-height: calc(90vh - 10px);
}

header {
    width: 100%;
    margin-bottom: 3%;
    display: flex;
    align-items: center;
    font-size: 1.1rem;
    font-weight: bold;
}

main {
    color: var(--grey-darker);
}

footer {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
}

.dialog-enter-from,
.dialog-leave-to {
    opacity: 0;
    transform: scale(0.8);
}

.dialog-enter-active {
    transition: all 0.3s ease-out;
}

.dialog-leave-active {
    transition: all 0.3s ease-in;
}

.dialog-enter-to,
.dialog-leave-from {
    opacity: 1;
    transform: scale(1);
}
</style>
