<template>
    <BaseDialog
        :show="showDeleteAlert"
        :title="$t('inputCamera.deleteTitle')"
        @close="closeAlert"
    >
        <p />
        <template #actions>
            <BaseDialogButton
                :label="$t('globals.cancel')"
                button-type="default"
                @click="closeAlert"
            />
            <BaseDialogButton
                :label="$t('inputCamera.delete')"
                button-type="danger"
                @click="confirmDelete"
            />
        </template>
    </BaseDialog>

    <BaseDialog
        :show="showDetectionAlert"
        :title="$t('inputCamera.noIADetectionTitle')"
        @close="closeAlert"
    >
        <p>{{ $t('inputCamera.noIADetection') }}</p>
        <p>{{ $t('inputCamera.deleteQuestion') }}</p>
        <template #actions>
            <BaseDialogButton
                label="Annuler"
                button-type="default"
                @click="closeAlert"
            />
            <BaseDialogButton
                :label="$t('inputCamera.delete')"
                button-type="danger"
                @click="confirmDelete"
            />
        </template>
    </BaseDialog>

    <div
        v-if="showImagePreview"
        class="modal-mask"
        v-bind="$attrs"
    >
        <div class="modal-wrapper">
            <div class="modal-container">
                <div class="modal-header text-right">
                    <slot name="header">
                        <el-button
                            type="primary"
                            :icon="Close"
                            size="small"
                            @click="closePreview"
                        />
                    </slot>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        <img
                            class="pictureFromCamera"
                            alt="Photo"
                            :src="imageToShow"
                        />
                    </slot>
                </div>
            </div>
        </div>
    </div>

    <el-row v-bind="$attrs">
        <el-col
            :span="24"
            class="picture_div"
        >
            <div class="custom_label_input title label_picture">
                {{ label }}
            </div>
            <label
                class="button_picture"
                :for="'cameraFileInput_' + id"
            >
                <el-button
                    :icon="Camera"
                    type="primary"
                    @click="takePicture"
                />
            </label>
        </el-col>
    </el-row>
    <el-row>
        <el-col
            :span="24"
            class="text-center"
        >
            <div
                v-for="(image, index) in imagesModel"
                :key="image"
            >
                <div class="column">
                    <div
                        v-if="isOffline && !image.data"
                        class="offline-images"
                        :title="getImageAlt()"
                    >
                        <FontAwesomeIcon
                            icon="image"
                            size="2x"
                            color="white"
                        />
                    </div>
                    <div
                        v-if="(image.data && isOffline) || !isOffline"
                        :title="getImageAlt()"
                    >
                        <img
                            class="pictureFromCamera"
                            :class="{ 'img-ai-loading': aiLoader[image.uuid] }"
                            :alt="getImageAlt()"
                            :src="getSmallImageURL(image)"
                            @click="() => displayModal(image)"
                        />
                    </div>
                    <el-icon
                        v-if="aiLoader[image.uuid]"
                        class="absolute-center is-loading"
                        color="white"
                    >
                        <LoadingIcon />
                    </el-icon>

                    <el-button
                        v-if="
                            ((image && image.data) || (image && !isOffline)) &&
                            aiInvalidPredictions[image.uuid]
                        "
                        class="top-left"
                        type="warning"
                        :icon="WarningFilled"
                        circle
                        size="small"
                        @click="() => alertWrongDetection(index)"
                    />
                    <el-button
                        v-if="(image && image.data) || (image && !isOffline)"
                        class="top-right"
                        type="danger"
                        :icon="Delete"
                        circle
                        size="small"
                        @click="() => deletePicture(index)"
                    />
                </div>
            </div>
            <div class="hidden">
                <canvas ref="canvas" />
            </div>
        </el-col>
    </el-row>
</template>

<script lang="ts">
import {
    Camera,
    Close,
    Delete,
    Loading as LoadingIcon,
    WarningFilled,
} from '@element-plus/icons-vue';
import { defineComponent, type PropType, toRaw } from 'vue';
import { mapState } from 'pinia';
import { v4 as uuidv4 } from 'uuid';

import * as AiApi from '@connect-field/client/services/api/ai';
import { analyzeCondition, isIOS } from '@connect-field/client/utilities/tools';
import {
    CameraDirection,
    CameraResultType,
    CameraSource,
    Photo,
    type PhotoResult,
} from '@connect-field/client/plugins/camera';
import type {
    EtiquettePostProcess,
    LabelCord,
    URLsImageResponseDto,
} from '@connect-field/client/sdk/generated';
import BaseDialog from '@connect-field/client/components/ui/BaseDialog.vue';
import BaseDialogButton from '@connect-field/client/components/ui/BaseDialogButton.vue';
import { deleteItem } from '@connect-field/client/utilities/idb-utility';
import { isHTMLImageElementEvent } from '@connect-field/client/services/images';
import useObjectStore from '@connect-field/client/stores/object';

interface DataInterface {
    aiInvalidPredictions?: Record<string, boolean>;
    aiLoader: Record<string, boolean>;
    id: number;
    imageToDelete?: number;
    imageToShow?: string;
    showDeleteAlert: boolean;
    showDetectionAlert: boolean;
    showImagePreview: boolean;
}

interface ImageFromAPIInterface {
    sid: number;
    urls: URLsImageResponseDto;
}

interface ImageFromUserInterface {
    data: string;
    exif?: Record<string, unknown>;
    uuid?: string;
}

function isImageFromAPIInterface(
    image: ImageFromAPIInterface | ImageFromUserInterface,
): image is ImageFromAPIInterface {
    return typeof (image as ImageFromAPIInterface).urls !== 'undefined';
}
function isImageFromUserInterface(
    image: ImageFromAPIInterface | ImageFromUserInterface,
): image is ImageFromUserInterface {
    return typeof (image as ImageFromUserInterface).data !== 'undefined';
}

export default defineComponent({
    components: {
        BaseDialog,
        BaseDialogButton,
        LoadingIcon,
    },
    props: {
        ai: { type: Boolean },
        aiExpectedElements: {
            type: Array as PropType<Array<string>>,
            default: () => {
                return [];
            },
        },
        aiExpectedElementsCondition: {
            type: String as PropType<'OR' | 'AND'>,
            default: () => {
                return 'OR';
            },
        },
        aiExpectedText: {
            type: Object as PropType<Record<string, string>>,
            default: () => {
                return {};
            },
        },
        // TODO : Add a system to detect text with case insensitive
        // eslint-disable-next-line vue/no-unused-properties
        aiTextCaseSensitive: {
            type: Boolean,
        },
        images: {
            type: Array as PropType<
                Array<ImageFromAPIInterface | ImageFromUserInterface>
            >,
            required: true,
        },
        label: {
            type: String,
            default: '',
        },
        layerName: {
            type: String,
            required: true,
        },
    },
    emits: ['ai-prediction', 'ai-text-recognition', 'update:images'],
    setup() {
        return {
            Camera,
            Close,
            Delete,
            WarningFilled,
        };
    },
    data(): DataInterface {
        return {
            aiInvalidPredictions: {},
            aiLoader: {},
            id: Math.floor(Math.random() * 1000),
            imageToDelete: undefined,
            imageToShow: undefined,
            showDeleteAlert: false,
            showDetectionAlert: false,
            showImagePreview: false,
        };
    },
    computed: {
        ...mapState(useObjectStore, {
            dataForm: 'dataForm',
        }),
        imagesModel: {
            get(): Array<ImageFromAPIInterface | ImageFromUserInterface> {
                return this.images?.map(toRaw) ?? [];
            },
            set(
                images: Array<ImageFromAPIInterface | ImageFromUserInterface>,
            ): void {
                this.$emit('update:images', images);
            },
        },
        isOffline(): boolean {
            if (this.$route.meta) {
                return Boolean(this.$route.meta.offline);
            }

            return false;
        },
    },
    methods: {
        async aiOnImage(image: ImageFromUserInterface): Promise<void> {
            if (!image.uuid) {
                console.error('[aiOnImage] No uuid. Stop AI prediction');

                return;
            }

            this.aiLoader[image.uuid] = true;
            try {
                const predictResults = await AiApi.predictImage(image.data);

                // Elements detection

                const predictedElements = predictResults?.label_coord.reduce(
                    (elements: Array<string>, label: LabelCord) => {
                        if (label.confidence > 0.3) {
                            elements.push(label.label);
                        }

                        return elements;
                    },
                    [],
                );

                if (
                    this.aiInvalidPredictions &&
                    this.aiExpectedElements?.length > 0
                ) {
                    if (predictedElements.length > 0) {
                        const result = analyzeCondition(
                            this.aiExpectedElementsCondition,
                            predictedElements,
                            this.aiExpectedElements,
                        );
                        this.aiInvalidPredictions[image.uuid] = !result;
                    } else {
                        this.aiInvalidPredictions[image.uuid] = true;
                    }
                }

                // Text recognition

                if (
                    this.aiExpectedText &&
                    predictResults?.post_process?.etiquette_post_processing
                ) {
                    const tags =
                        predictResults.post_process.etiquette_post_processing;
                    const textResults: Record<string, boolean> = {};

                    Object.keys(this.aiExpectedText).forEach((key: string) => {
                        textResults[key] = tags.some(
                            (tag: EtiquettePostProcess) => {
                                return tag.text.some((text: string) => {
                                    return text.includes(
                                        this.aiExpectedText[key],
                                    );
                                });
                            },
                        );
                    });
                    this.$emit('ai-text-recognition', textResults);
                }
                this.$emit('ai-prediction', predictResults);
            } catch (error: unknown) {
                console.error('[aiOnImage]', error);
            } finally {
                this.aiLoader[image.uuid] = false;
            }
        },
        alertWrongDetection(index: number | string): void {
            if (typeof index === 'string') {
                return;
            }

            this.imageToDelete = index;
            this.showDetectionAlert = true;
        },
        closeAlert(): void {
            this.showDeleteAlert = false;
            this.showDetectionAlert = false;
        },
        closePreview(): void {
            this.imageToShow = undefined;
            this.showImagePreview = false;
        },
        async confirmDelete(): Promise<void> {
            if (typeof this.imageToDelete === 'undefined') {
                throw new Error('imageToDelete is undefined');
            }

            const image = this.imagesModel[this.imageToDelete];
            if (isImageFromAPIInterface(image)) {
                try {
                    await deleteItem(
                        'projectImages_v2',
                        `${this.layerName}/${image.sid}`,
                    );
                } catch (error: unknown) {
                    console.error('confirmDelete', error);
                }
            }

            this.imagesModel.splice(this.imageToDelete, 1);
            this.imagesModel = [...this.imagesModel];

            this.closeAlert();
        },
        deletePicture(index: number | string): void {
            if (typeof index === 'string') {
                return;
            }

            this.imageToDelete = index;
            this.showDeleteAlert = true;
        },
        displayModal(
            image: ImageFromAPIInterface | ImageFromUserInterface,
        ): void {
            this.imageToShow = undefined;

            if (isImageFromAPIInterface(image)) {
                if (image.urls.full) {
                    this.imageToShow = image.urls.full;
                } else if (image.urls.small) {
                    this.imageToShow = image.urls.small;
                }
            }

            if (isImageFromUserInterface(image)) {
                this.imageToShow = image.data;
            }

            if (this.imageToShow) {
                this.showImagePreview = true;
            }
        },
        getImageAlt(): string {
            return `\nPhoto`;
        },
        getSmallImageURL(
            image: ImageFromAPIInterface | ImageFromUserInterface,
        ): string {
            if (isImageFromAPIInterface(image)) {
                return image.urls.small || image.urls.full || '';
            }

            if (isImageFromUserInterface(image)) {
                return image.data;
            }

            return '';
        },
        pushImage(image: ImageFromUserInterface): void {
            if (!image) {
                return;
            }

            image.uuid = uuidv4();
            this.imagesModel = [...this.imagesModel, image];

            if (this.ai) {
                setTimeout(() => this.aiOnImage(image));
            }
        },
        resize(
            img: HTMLImageElement,
            height: number,
            width: number,
            retry = false,
        ): string {
            const _MAX_WIDTH = 2000;
            const _MAX_HEIGHT = 2000;

            if (retry) {
                console.warn('Retry resize');
            }
            if (width > height) {
                if (width > _MAX_WIDTH) {
                    height = Math.round((height *= _MAX_WIDTH / width));
                    width = _MAX_WIDTH;
                }
            } else {
                if (height > _MAX_HEIGHT) {
                    width = Math.round((width *= _MAX_HEIGHT / height));
                    height = _MAX_HEIGHT;
                }
            }

            if (!this.$refs.canvas) {
                if (retry) {
                    throw new Error(
                        'Image ne pouvant pas être chargée. Erreur canvas. Retry : 1',
                    );
                }
                console.warn(
                    'Image ne pouvant pas être chargée. Erreur canvas.',
                );

                return this.resize(img, height, width, true);
            }

            const canvas = this.$refs.canvas as HTMLCanvasElement;
            canvas.width = width;
            canvas.height = height;

            const ctx = canvas.getContext('2d');
            if (!ctx) {
                if (retry) {
                    throw new Error(
                        'Image ne pouvant pas être chargée. Erreur contexte.  Retry : 1',
                    );
                }
                console.warn(
                    'Image ne pouvant pas être chargée. Erreur contexte',
                );

                return this.resize(img, height, width, true);
            }

            ctx.drawImage(img, 0, 0, width, height);

            let quality = 0.7;
            if (isIOS()) {
                quality = 0.5;
            }

            const dataURL = canvas.toDataURL('image/jpeg', quality);
            ctx.clearRect(0, 0, width, height);
            canvas.width = 0;
            canvas.height = 0;

            return dataURL;
        },
        takePicture(): void {
            const resizeFn = this.resize;
            const pushImageFn = this.pushImage;

            const photo = new Photo({
                direction: CameraDirection.Rear,
                resultType: CameraResultType.Uri,
                source: CameraSource.Camera,
            });

            photo.take((file: PhotoResult) => {
                if (!file) {
                    throw new Error('no file');
                }

                const image = new Image();
                image.onload = function (): void {
                    if (isHTMLImageElementEvent(this)) {
                        const height = this.height;
                        const width = this.width;
                        const dataURL = resizeFn(image, height, width);
                        pushImageFn({
                            data: dataURL,
                            exif: file.exif,
                        });
                    } else {
                        console.warn(
                            '[InputCamera] image.onload is not an HTMLImageElementEvent',
                        );
                    }
                };
                image.onerror = function (): void {
                    alert(
                        "L'image n'a pas été correctement chargée. Veuillez réessayer, si vous rencontrez de nouveau cette erreur, l'image est sans doute incompatible.",
                    );
                };

                if (file.webPath) {
                    image.src = file.webPath;
                }
            });
        },
    },
});
</script>

<style scoped lang="scss">
@import 'InputCamera.scss';
</style>
