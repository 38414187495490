<template>
    <FormWrapper
        v-if="!loading"
        ref="customFormRef"
        :images-model="images"
        :form-model="form"
        :mandatory-fields="mandatoryFields"
        :changes-model="changes"
        :layer-name="layerName"
        :object-id="objectId"
        :project-id="projectId"
        :latitude="latitude"
        :longitude="longitude"
        @update:images-model="
            (e) => {
                images = e;
            }
        "
        @update:value-missing="
            (e) => {
                valueMissing = e;
            }
        "
        @update:form-model="
            (e) => {
                form = e;
            }
        "
        @update:changes-model="
            (e) => {
                changes = e;
            }
        "
    >
        <template #main_form>
            <el-row>
                <el-col :span="24">
                    <CustomInput
                        :label="labels.cable_code"
                        :value="form.cable_code"
                        :maxlength="255"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.cable_codeext"
                        :value="form.cable_codeext"
                        :disabled="true"
                    />
                    <CustomDatePicker
                        :label="labels.date_audit"
                        :value="form.date_audit"
                        @update:value="
                            (newValue) => saveData('date_audit', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.cable_capa_thd"
                        :value="form.cable_capa_thd"
                        :disabled="true"
                    />
                    <InputNumber
                        :label="labels.cable_capa"
                        :value="form.cable_capa"
                        @update:value="
                            (newValue) => saveData('cable_capa', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.cable_sta_thd"
                        :value="form.cable_sta_thd"
                        :maxlength="255"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.cable_sta"
                        :value="form.cable_sta"
                        @update:value="
                            (newValue) => saveData('cable_sta', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_environnement"
                        :images="images.img_environnement"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_environnement', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_cable"
                        :images="images.img_cable"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('img_cable', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.etq_cab"
                        :value="form.etq_cab"
                        :maxlength="255"
                        :disabled="true"
                    />
                    <CustomInputSelect
                        :label="labels.etat_etq_cab"
                        :options="options.etat_etq_cab"
                        :value="form.etat_etq_cab"
                        @update:value="
                            (newValue) => saveData('etat_etq_cab', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_etiquette"
                        :images="images.img_etiquette"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('img_etiquette', newValue)
                        "
                    />
                    <CustomInputTextarea
                        :label="labels.com_global"
                        :value="form.com_global"
                        :maxlength="255"
                        @update:value="
                            (newValue) => saveData('com_global', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.statut_audit + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.statut_audit"
                        :value="form.statut_audit"
                        @update:value="
                            (newValue) => saveData('statut_audit', newValue)
                        "
                    />
                </el-col>
            </el-row>
        </template>
    </FormWrapper>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import {
    labels,
    options,
} from '@connect-field/client/components/forms/Dotic/Tisseo/CableForm.data';
import type { FormDataInterface } from '@connect-field/client/components/forms/form.types';

import FormTemplate from '@connect-field/client/components/forms/FormTemplate.vue';

interface DataInterface extends FormDataInterface {}

export default defineComponent({
    extends: FormTemplate,
    data(): DataInterface {
        return {
            changes: false,
            form: {},
            images: {
                img_cable: [],
                img_environnement: [],
                img_etiquette: [],
            },
            labels,
            loading: true,
            mandatoryFields: ['statut_audit'],
            options,
            valueMissing: false,
        };
    },
});
</script>
