<template>
    <main
        v-loading="projectsLoading"
        :class="{ main: !projects.length && projectsLoading }"
        :element-loading-text="$t('projectList.loading')"
        element-loading-spinner="el-icon-loading"
        element-loading-background="transparent"
    >
        <h3
            v-if="projects.length && !projectsLoading"
            class="panel__title"
        >
            {{ $t('projectList.choose') }}
        </h3>
        <div
            v-if="lastProjectViewed && lastProjectViewed.id && !projectsLoading"
            class="section last-project"
        >
            <p class="section__title">
                {{ $t('projectList.lastProject') }}
            </p>
            <ProjectItem
                v-if="lastProjectViewed.online"
                :project-id="lastProjectViewed.id"
                :project-title="lastProjectViewed.name"
                display-mode="list"
                :is-featured="true"
                :download-state="downloadedStates[lastProjectViewed.id]"
                @state-changed="downloadStateChanged"
            />
            <OfflineProjectItem
                v-else
                :project-id="lastProjectViewed.id"
                :project-title="lastProjectViewed.name"
                :project-custom-title="lastProjectViewed.customName"
                display-mode="list"
                :is-featured="true"
                @offline-project-deleted="
                    () => deleteOfflineProject(lastProjectViewed.id)
                "
            />
        </div>
        <div
            v-if="projects.length && !projectsLoading"
            class="section"
        >
            <div class="section__header">
                <p class="section__title">
                    {{ $t('projectList.allProjects') }}
                </p>
                <FontAwesomeIcon
                    class="section__action custom_link"
                    :icon="getToggleIcon"
                    @click="toggleProjectDisplay"
                />
            </div>
            <ul class="project-list">
                <ProjectItem
                    v-for="project of projects"
                    :key="project.id"
                    :project-id="project.id.toString()"
                    :project-title="project.name"
                    :display-mode="displayMode"
                    :download-state="downloadedStates[project.id]"
                    class="custom_link"
                    @state-changed="downloadStateChanged"
                />
            </ul>
        </div>
        <div
            v-if="offlineProjects.length && !projectsLoading"
            class="section"
        >
            <div class="section__header">
                <p class="section__title">
                    {{ $t('projectList.allOfflineProjects') }}
                </p>
                <FontAwesomeIcon
                    class="section__action custom_link"
                    :icon="getToggleIcon"
                    @click="toggleProjectDisplay"
                />
            </div>
            <ul class="project-list">
                <!-- TODO : Fix :class here -->
                <OfflineProjectItem
                    v-for="project of offlineProjects"
                    :key="project.id"
                    :project-id="project.id"
                    :project-title="project.name"
                    :project-custom-title="project.customName"
                    :display-mode="displayMode"
                    :class="getDisplayMode"
                    class="custom_link"
                    @offline-project-deleted="
                        () => deleteOfflineProject(project.id)
                    "
                />
            </ul>
        </div>

        <p
            v-if="!projects.length && !projectsLoading"
            class="text-center margin-y-2 warning"
        >
            {{ $t('projectList.noProjectFound') }}
        </p>
    </main>
</template>

<script lang="ts">
import { mapState, mapWritableState } from 'pinia';
import type BaseLayer from 'ol/layer/Base';
import { defineComponent } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import type Map from 'ol/Map';

import {
    removeHighlightedFeature,
    removeLayers,
    removePinOnMap,
    resetView,
} from '@connect-field/client/services/map.service';
import { getAllKeys } from '@connect-field/client/utilities/idb-utility';
import OfflineProjectItem from '@connect-field/client/components/project/OfflineProjectItem.vue';
import ProjectItem from '@connect-field/client/components/project/ProjectItem.vue';
import useCoordinateStore from '@connect-field/client/stores/coordinate';
import useMapStore from '@connect-field/client/stores/map';
import useMenuStore from '@connect-field/client/stores/menu';
import useObjectStore from '@connect-field/client/stores/object';
import usePanelStore from '@connect-field/client/stores/panel';
import useProjectsStore from '@connect-field/client/stores/projects';

const GRID_ICON = 'th-large';
const LIST_ICON = 'th-list';
const PROJECT_ITEM_CARD_MODE = 'project-item--card';
const PROJECT_ITEM_LIST_MODE = 'project-item--list';

export default defineComponent({
    components: {
        FontAwesomeIcon,
        OfflineProjectItem,
        ProjectItem,
    },
    setup() {
        return {
            coordinateStore: useCoordinateStore(),
            mapStore: useMapStore(),
            menuStore: useMenuStore(),
            objectStore: useObjectStore(),
            panelStore: usePanelStore(),
            projectsStore: useProjectsStore(),
        };
    },
    data() {
        return {
            downloadedStates: {},
        };
    },
    computed: {
        ...mapWritableState(useProjectsStore, {
            displayMode: 'displayMode',
            displayModeGrid: 'displayModeGrid',
            displayModeList: 'displayModeList',
            offlineProjects: 'offlineProjects',
            projects: 'projects',
            projectsLoading: 'projectsLoading',
            selectedProject: 'selectedProject',
        }),
        ...mapState(useProjectsStore, {
            lastProjectViewed: 'lastProjectViewed',
        }),
        getDisplayMode(): string {
            // TODO : Delete this
            return this.displayMode === this.displayModeGrid
                ? PROJECT_ITEM_CARD_MODE
                : PROJECT_ITEM_LIST_MODE;
        },
        getToggleIcon(): string {
            return this.displayMode === this.displayModeGrid
                ? GRID_ICON
                : LIST_ICON;
        },
        map(): Map {
            if (!this.mapStore.map) {
                throw new Error('Map is undefined');
            }

            return this.mapStore.map as Map;
        },
    },
    mounted() {
        // No need to await those promises
        this.getProjects();
        this.resetMap();
    },
    methods: {
        deleteOfflineProject(id: string): void {
            const shortId = parseInt(id.split('-')[0]);
            this.updateDownloadedState(shortId, 'download');

            this.projectsStore.$patch((state) => {
                state.offlineProjects = state.offlineProjects.filter(
                    (project) => project.id !== id,
                );
            });
        },
        downloadStateChanged({ projectId, newState }): void {
            this.updateDownloadedState(projectId, newState);
        },
        async getProjects(): Promise<void> {
            try {
                if (!this.projects.length) {
                    await this.projectsStore.fetchProjects();
                }
                if (!this.offlineProjects.length) {
                    await this.projectsStore.fetchOfflineProjects();
                }
            } catch (error: unknown) {
                console.error('[ MainVue ] - failed to fetch projects', error);
            }
        },
        async initializeDownloadedStates(): Promise<void> {
            const states = await getAllKeys('projectConfigurations');

            this.projects.forEach((project) => {
                if (states.includes(project.id)) {
                    this.downloadedStates[project.id] = 'synchronized';
                } else {
                    this.downloadedStates[project.id] = 'download';
                }
            });
        },
        async resetMap(): Promise<void> {
            this.objectStore.resetStore();
            this.projectsStore.softReset();
            this.menuStore.displayMenu = true;
            this.panelStore.setPanelHalfOpened();

            // TODO : Fix behaviour here. Reset only if the route is projectList
            this.coordinateStore.$reset();

            removePinOnMap();
            removeHighlightedFeature();
            removeLayers();
            this.map
                .getLayers()
                .getArray()
                .forEach((layer: BaseLayer) => {
                    if (layer.getProperties()?.name === 'background_OSM') {
                        layer.setVisible(true);
                    }
                    layer.setOpacity(1);
                });
            resetView();

            await this.initializeDownloadedStates();
        },
        toggleProjectDisplay(): void {
            const newDisplayMode =
                this.displayMode === this.displayModeGrid
                    ? this.displayModeList
                    : this.displayModeGrid;
            this.projectsStore.$patch({ displayMode: newDisplayMode });
        },
        updateDownloadedState(projectId, downloadState): void {
            this.downloadedStates[projectId] = downloadState;
        },
    },
});
</script>

<style scoped lang="scss">
.main {
    padding: 8rem 0 0;
}

.section {
    padding: 0 0.5rem 0.5rem;
}

.section__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.section__title {
    margin: 0.3rem 0;
    color: var(--silver);
}

.section__action {
    border: none;
    background: transparent;
    color: var(--silver);
    font-size: 1rem;
}

.project-list {
    display: flex;
    flex-flow: row wrap;
}
</style>
