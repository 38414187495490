/*
Store to save selected object
 */

import { acceptHMRUpdate, defineStore } from 'pinia';
import type { Coordinate } from 'ol/coordinate';
import type Feature from 'ol/Feature';

import * as LayerApi from '@connect-field/client/services/api/layer';
import * as ObjectApi from '@connect-field/client/services/api/object';
import { deleteItem, getItem, setStore } from '@connect-field/client/utilities/idb-utility';
import type { FormDataInterface } from '@connect-field/client/components/forms/form.types';
import type {
    ProjectConfigurationLayerFormatEnum,
    ProjectConfigurationLayerTypeEnum,
} from '@connect-field/client/sdk/generated';

export interface DataFormPropertiesInterface {
    [x: string]: unknown;
}

export interface DataFormInterface extends DataFormPropertiesInterface {
    aggregatedProperties?: Array<DataFormPropertiesInterface>;
    layer: string;
    sid: number | string;
}

export interface LayerDataInterface {
    alias?: string;
    coordinate?: Coordinate;
    enableCreation: boolean;
    enableEdition: boolean;
    form: string | null;
    idField?: string;
    layerFormat?: ProjectConfigurationLayerFormatEnum;
    layerId: string;
    layerType?: ProjectConfigurationLayerTypeEnum;
    name: string;
    type: string;
}

export interface StateInterface {
    coordinate?: Coordinate;
    dataForm?: DataFormInterface;
    Feature?: Feature;
    layerData?: LayerDataInterface;
}

const useObjectStore = defineStore('object', {
    actions: {
        resetStore(): void {
            this.$reset();
        },
        async save(form: DataFormInterface, isOffline = false, projectId: string): Promise<void> {
            if (form._lat && form._lng) {
                try {
                    await this.saveFormAndUpdateLayer(form, projectId, isOffline, form.sid.toString());
                } catch (error: unknown) {
                    console.error('[form/save/creation] Error', error);
                }
            } else if (this.dataForm?.sid) {
                try {
                    await this.saveFormAndUpdateLayer(form, projectId, isOffline, this.dataForm.sid.toString());
                } catch (error: unknown) {
                    console.error('[form/save/edition] Error', error);
                }
            } else {
                console.error('[form/save] Nor sid not _lat/_lng detected', form);
                throw new Error('[form/save] Nor sid not _lat/_lng detected');
            }
        },
        async saveFormAndUpdateLayer(
            form: DataFormInterface,
            projectId: string,
            isOffline: boolean,
            sid: string,
        ): Promise<void> {
            if (!this.layerData?.name) {
                throw new Error('[form/save] LayerData is undefined');
            }

            await setStore(
                'savedDataForms',
                {
                    form,
                    layerId: this.layerData.layerId,
                },
                `${projectId}/${this.layerData.name}/${sid}`,
            );

            if (!isOffline) {
                await LayerApi.updateLayerData(this.layerData.layerId, form);
                await deleteItem('savedDataForms', `${projectId}/${this.layerData.name}/${sid}`);
                this.dataForm = form;
            }
        },
        setObject(object: { dataForm: DataFormInterface; Feature: Feature; layerData: LayerDataInterface }): void {
            this.$patch((state) => {
                state.dataForm = object.dataForm;
                state.Feature = object.Feature;
                state.layerData = object.layerData;
            });
        },
        async syncDataForm(objectId: string, layerName: string): Promise<void> {
            const data = await ObjectApi.getObjectGeojsonFromLayer(objectId, layerName);

            if (data) {
                // TODO : Urgent Need to fix this
                this.$state.dataForm = data.properties;
            }
        },
        async syncDataFormOffline(projectId: string, layerName: string, objectId: string): Promise<void> {
            const id = `${projectId}/${layerName}/${objectId}`;
            const savedDataForm = await getItem<FormDataInterface>(id, 'savedDataForms');

            if (savedDataForm?.form) {
                this.$state.dataForm = savedDataForm.form;
            }
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        async syncDataOffline(options: { config: any; data: any }): Promise<void> {
            const { data, config } = options;
            if (data?.signal?.aborted) {
                return Promise.reject(new DOMException('Download Cancelled'));
            }
            try {
                await LayerApi.updateLayerData(data.layerId, data.form, config);
                await deleteItem('savedDataForms', data.key);
            } catch (error: unknown) {
                console.error('[form/save] Error', error);

                return Promise.reject(error);
            }
        },
    },
    state: (): StateInterface => {
        return {
            coordinate: undefined,
            dataForm: undefined,
            Feature: undefined,
            layerData: undefined,
        };
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useObjectStore, import.meta.hot));
}

export default useObjectStore;
