import initSqlJs, { type Database, type Statement } from 'sql.js';

export interface OptionsInterface {
    basemap: boolean;
    declutter: boolean;
    zIndex: number;
}

export interface MBtilesLayer {
    description: string;
    fields: Record<string, 'String'>;
    id: string;
    maxzoom: number;
    minzoom: number;
}

export class MBTiles {
    public format: string;
    public layers!: Array<MBtilesLayer>;
    public maxZoom: number;
    public minZoom: number;
    public query!: Statement;
    private _databaseIsLoaded: boolean;
    private _db!: Database;
    private attribution: string;
    private bounds!: Array<number>;
    private options: {};

    public constructor() {
        this._databaseIsLoaded = false;
        this.options = {};
        this.format = 'image/png';
        this.attribution = 'No attribution';
        this.minZoom = 0;
        this.maxZoom = 22;
    }

    public async openDB(array: Uint8Array): Promise<void> {
        try {
            const SQL = await initSqlJs({
                // locateFile: (file) => `https://sql.js.org/dist/${file}`,
                locateFile: () => `/sql-wasm-1.8.0.wasm`,
            });

            this._db = new SQL.Database(array);

            this.query = this._db.prepare(
                'SELECT tile_data FROM tiles WHERE zoom_level = :z AND tile_column = :x AND tile_row = :y',
            );

            // Load some metadata (or at least try to)
            const metaStmt = this._db.prepare('SELECT value FROM metadata WHERE name = :key');
            let row;

            row = metaStmt.getAsObject({ ':key': 'attribution' });
            if (row.value) {
                this.attribution = row.value as string;
            }

            row = metaStmt.getAsObject({ ':key': 'json' });
            if (row.value) {
                const data = JSON.parse(row.value as string);
                if (data && data.vector_layers) {
                    this.layers = data.vector_layers;
                }
            }

            row = metaStmt.getAsObject({ ':key': 'minzoom' });
            if (row.value) {
                this.minZoom = Number(row.value);
            }

            row = metaStmt.getAsObject({ ':key': 'maxzoom' });
            if (row.value) {
                this.maxZoom = Number(row.value);
            }

            row = metaStmt.getAsObject({ ':key': 'bounds' });
            if (row.value) {
                this.bounds = (row.value as string).split(',').map(Number);
            }

            row = metaStmt.getAsObject({ ':key': 'format' });
            if (row.value) {
                if (row.value === 'png') {
                    this.format = 'image/png';
                } else if (row.value === 'jpg') {
                    this.format = 'image/jpg';
                } else if (row.value === 'pbf') {
                    this.format = 'application/vnd.mapbox-vector-tile';
                }
            }

            this._databaseIsLoaded = true;
        } catch (error: unknown) {
            console.error(error);
            alert('Problème avec le fichier mbtiles');
            throw error;
        }
    }
}
