<template>
    <el-switch
        v-model="visible"
        :disabled="disabled"
        class="collapse__checkbox"
    />
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';
import type BaseLayer from 'ol/layer/Base';

import type Map from 'ol/Map';

import useMapStore from '@connect-field/client/stores/map';

export default defineComponent({
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        layer: {
            type: Object as PropType<BaseLayer>,
            required: true,
        },
    },
    setup() {
        return {
            mapStore: useMapStore(),
        };
    },
    computed: {
        map(): Map {
            if (!this.mapStore.map) {
                throw new Error('Map is undefined');
            }

            return this.mapStore.map as Map;
        },
        visible: {
            get(): boolean {
                return this.layer.getVisible();
            },
            set(visible: boolean): void {
                if (this.layer.getProperties()?.parentLayerId) {
                    const layer = this.map
                        .getLayers()
                        .getArray()
                        .find(
                            (layer: BaseLayer) =>
                                layer.get('id') ===
                                this.layer.getProperties().parentLayerId,
                        );
                    if (layer) {
                        layer.setVisible(visible);
                    }
                }

                this.layer.setVisible(visible);
            },
        },
    },
});
</script>

<style scoped lang="scss">
.collapse__checkbox {
    margin: 0 0 0 1rem;
}
</style>
