import type { OptionsInterface, SelectInterface } from '@connect-field/client/components/forms/form.types';

export const labels = {
    arrimage_gaine: 'Arrimage de la gaine de protection des micro-modules',
    cheminement_lovage: 'Cheminement en zone de lovage',
    commentaire_arrimage_gaine: 'Commentaire',
    commentaire_cheminement_lovage: 'Commentaire',
    commentaire_detubage_fibres: 'Commentaire',
    commentaire_emplacement_geographique: 'Commentaire',
    commentaire_emplacement_lt: 'Commentaire',
    commentaire_epanouissement: 'Commentaire',
    commentaire_etiquette_baie: 'Commentaire',
    commentaire_etiquette_cable: 'Commentaire',
    commentaire_etiquette_tiroir: 'Commentaire',
    commentaire_fixation_chemin_cable: 'Commentaire',
    commentaire_fixation_eclateur: 'Commentaire',
    commentaire_fixation_entree: 'Commentaire',
    commentaire_fixation_tiroir: 'Commentaire',
    commentaire_longueur_gaine: 'Commentaire',
    commentaire_obturation_fourreaux: 'Commentaire',
    commentaire_ouverture_tiroirs: 'Commentaire',
    commentaire_position_traverses: 'Commentaire',
    commentaire_positions_tiroirs: 'Commentaire',
    commentaire_presence_bouchons: 'Commentaire',
    commentaire_presence_gaine: 'Commentaire',
    commentaire_rayon_courbure: 'Commentaire',
    commentaire_rayon_courbure_tubes: 'Commentaire',
    commentaire_vabf_acces: 'Commentaire',
    detubage_fibres: 'Détubage des fibres',
    emplacement_geographique: 'Emplacement géographique',
    emplacement_lt: 'Emplacement dans le local technique',
    epanouissement: "Mise en oeuvre de l'épanouissement",
    etiquette_baie: 'Etiquette (Présente, libre et juste)',
    etiquette_cable: 'Etiquettes (Présentes, libres et justes)',
    etiquette_tiroir: 'Etiquettes (Présentes, libres et justes)',
    fixation_chemin_cable: 'Fixation sur chemin de câble',
    fixation_eclateur: "Fixation de l'éclateur (Vis et/ou écrou cage)",
    fixation_entree: 'Fixation des câbles en entrée (NRO / SRO)',
    fixation_tiroir: 'Fixation (Partie fixe + Charnière)',
    img_arrimage_gaine: 'Photos',
    img_cheminement_lovage: 'Photos',
    img_detubage_fibres: 'Photos',
    img_emplacement_geographique: 'Photos',
    img_emplacement_lt: 'Photos',
    img_epanouissement: 'Photos',
    img_etiquette_baie: 'Photos',
    img_etiquette_cable: 'Photos',
    img_etiquette_tiroir: 'Photos',
    img_fixation_chemin_cable: 'Photos',
    img_fixation_eclateur: 'Photos',
    img_fixation_entree: 'Photos',
    img_fixation_tiroir: 'Photos',
    img_longueur_gaine: 'Photos',
    img_obturation_fourreaux: 'Photos',
    img_ouverture_tiroirs: 'Photos',
    img_position_traverses: 'Photos',
    img_positions_tiroirs: 'Photos',
    img_presence_bouchons: 'Photos',
    img_presence_gaine: 'Photos',
    img_rayon_courbure: 'Photos',
    img_rayon_courbure_tubes: 'Photos',
    img_vabf_acces: 'Photos',
    lc_abandon: "Défini si l'entité est abandonnée ou non dans un SI",
    lc_abddate: "Date d'abandon de l'objet",
    lc_abdsrc: "Cause de l'abandon de l'objet",
    lc_avct: "Attribut synthétisant l'avancement",
    lc_bat: 'Nom du bâtiment',
    lc_bp_codf: 'Code du PBO pré-identifié',
    lc_bp_codp: 'Code du PBO FTTE ou BPE pré-identifié',
    lc_clim: 'Présence et type du système éventuel de ventilation ou de climatisation.',
    lc_code: 'Code du site',
    lc_codeext: 'Code chez un tiers',
    lc_comment: 'Commentaire',
    lc_creadat: "Date de création de l'objet en base",
    lc_dateins: "Date d'installation",
    lc_datemes: 'Date de mise en service du local',
    lc_elec: "Présence d'une alimentation électrique",
    lc_escal: 'Nom ou numéro d’escalier du local',
    lc_etage: 'Numéro d’étage du local.',
    lc_etat: 'Etat du local.',
    lc_etiquet: "Nom du local tel qu'étiqueté sur le terrain",
    lc_gest: 'Gestionnaire du local.',
    lc_idmajic: 'Identifiant du local dans un référentiel',
    lc_local: 'Informations de localisation',
    lc_majdate: "Date de la mise à jour de l'objet en base",
    lc_majsrc: 'Source utilisée pour la mise à jour',
    lc_occp: 'Occupation.',
    lc_perirec: 'Identifiant du périmètre récolé',
    lc_prop: 'Propriétaire du local.',
    lc_proptyp: 'Type de propriété',
    lc_st_code: 'Identifiant unique contenu dans la classe  SITE',
    lc_statut: 'Statut de déploiement.',
    lc_typelog: 'Type logique du local',
    lc_user: "Identifiant de l'utilisateur",
    longueur_gaine: 'Longueur suffisante de la gaine annelée (Partie',
    obturation_fourreaux: 'Obturation des fourreaux du site',
    ouverture_tiroirs: 'Ouverture libre des tiroirs (Absence de frottements)',
    position_traverses: 'Positionnement des traverses (Nb U)',
    positions_tiroirs: 'Vérification de la position des tiroirs',
    presence_bouchons: 'Présence des bouchons (Connecteurs)',
    presence_gaine: 'Présence de la gaine annelée / bloolite',
    proprete: 'Propreté',
    rayon_courbure: 'Respect des rayons de courbure',
    rayon_courbure_tubes: 'Respect des rayons de courbure des tubes',
    statut: 'Statut audit',
    vabf_acces: "VABF du contrôle d'accès (serrure porte canon)",
};

export const options: Record<string, OptionsInterface | SelectInterface> = {
    arrimage_gaine: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    cheminement_lovage: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    detubage_fibres: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    emplacement_geographique: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    emplacement_lt: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    epanouissement: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    etiquette_baie: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    etiquette_cable: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    etiquette_tiroir: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    fixation_chemin_cable: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    fixation_eclateur: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    fixation_entree: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    fixation_tiroir: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    lc_abandon: [
        { label: 'FAUX', value: '0' },
        { label: 'VRAI', value: '1' },
    ],
    lc_avct: [
        { label: 'EXISTANT', value: 'E' },
        { label: 'A CREER', value: 'C' },
        { label: 'TRAVAUX', value: 'T' },
        { label: 'EN SERVICE', value: 'S' },
        { label: 'HORS SERVICE', value: 'H' },
        { label: 'ABANDONNE', value: 'A' },
    ],
    lc_clim: [
        { label: 'SANS', value: 'SANS' },
        { label: 'VENTILLATION', value: 'VENTIL' },
        { label: 'CLIMATISATION', value: 'CLIM' },
    ],
    lc_elec: [
        { label: 'FAUX', value: '0' },
        { label: 'VRAI', value: '1' },
    ],
    lc_etat: [
        { label: 'A CHANGER', value: 'HS' },
        { label: 'MAUVAIS ETAT', value: 'ME' },
        { label: 'BON ETAT', value: 'OK' },
        { label: 'NON CONCERNE', value: 'NC' },
    ],
    lc_occp: [
        { label: 'VIDE', value: '0' },
        { label: 'NON VIDE EXPLOITABLE', value: '1.1' },
        { label: 'NON VIDE NON EXPLOITABLE', value: '1.2' },
        { label: 'SATUREE', value: '2' },
    ],
    lc_proptyp: [
        { label: 'CONSTRUCTION', value: 'CST' },
        { label: 'RACHAT', value: 'RAC' },
        { label: 'CESSION', value: 'CES' },
        { label: 'IRU', value: 'IRU' },
        { label: 'LOCATION', value: 'LOC' },
        { label: 'OCCUPATION', value: 'OCC' },
    ],
    lc_statut: [
        { label: 'ETUDE PRELIMINAIRE', value: 'PRE' },
        { label: 'ETUDE DE DIAGNOSTIC', value: 'DIA' },
        { label: 'AVANT-PROJET', value: 'AVP' },
        { label: 'PROJET', value: 'PRO' },
        { label: 'PASSATION DES MARCHES DE TRAVAUX', value: 'ACT' },
        { label: 'ETUDE D EXECUTION', value: 'EXE' },
        { label: 'TRAVAUX', value: 'TVX' },
        { label: 'RECOLEMENT', value: 'REC' },
        { label: 'MAINTIENT EN CONDITIONS OPERATIONNELLES', value: 'MCO' },
    ],
    lc_typelog: [
        { label: 'RESIDENTIEL', value: 'RES' },
        { label: 'TECHNIQUE', value: 'TEC' },
        { label: 'PROFESSIONNEL', value: 'PRO' },
        { label: 'ENTREPRISE', value: 'ENT' },
        { label: 'LOCAUX PUBLICS', value: 'PUB' },
        { label: 'OPERATEUR', value: 'OPE' },
        { label: 'OBJET CONNECTE A LA FIBRE', value: 'OBJ' },
        { label: 'NŒUD RACCORDEMENT OPTIQUE', value: 'NRO' },
        { label: 'SOUS-REPARTITEUR OPTIQUE', value: 'SRO' },
        { label: 'RESIDENTIEL OU PROFESSIONNEL', value: 'FTTH' },
        { label: 'SOUS-REPARTITEUR CUIVRE', value: 'SRC' },
        { label: 'NŒUD RACCORDEMENT D ABONNES - ZONE D OMBRE', value: 'NRAZO' },
    ],
    longueur_gaine: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    obturation_fourreaux: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    ouverture_tiroirs: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    position_traverses: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    positions_tiroirs: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    presence_bouchons: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    presence_gaine: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    proprete: { false: 'NON', true: 'OUI' },
    rayon_courbure: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    rayon_courbure_tubes: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    statut: [
        { label: 'Audit à réaliser', value: 'NON EFFECTUE' },
        { label: 'Audit partiellement réalisé', value: 'PARTIEL' },
        { label: 'Audit réalisé', value: 'TERMINE' },
        { label: 'Non concerné par un audit', value: 'NON CONCERNE' },
    ],
    vabf_acces: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
};
