<template>
    <FormWrapper
        v-if="!loading"
        ref="customFormRef"
        :images-model="images"
        :form-model="form"
        :mandatory-fields="mandatoryFields"
        :changes-model="changes"
        :layer-name="layerName"
        :object-id="objectId"
        :project-id="projectId"
        :latitude="latitude"
        :longitude="longitude"
        @update:images-model="
            (e) => {
                images = e;
            }
        "
        @update:value-missing="
            (e) => {
                valueMissing = e;
            }
        "
        @update:form-model="
            (e) => {
                form = e;
            }
        "
        @update:changes-model="
            (e) => {
                changes = e;
            }
        "
    >
        <template #menu_tabs> </template>
        <template #main_form>
            <el-row>
                <el-col :span="24">
                    <CustomInput
                        :label="labels.tirsou_code"
                        :value="form.tirsou_code"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.tirsou_ptech_code"
                        :value="form.tirsou_ptech_code"
                        :maxlength="255"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.tirsou_cable_code"
                        :value="form.tirsou_cable_code"
                        :disabled="true"
                    />
                    <InputNumber
                        :label="labels.tirsou_cable_capa"
                        :value="form.tirsou_cable_capa"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.tirsou_cable_phase"
                        :value="form.tirsou_cable_phase"
                        :maxlength="255"
                        :disabled="true"
                    />
                    <CustomDatePicker
                        :label="labels.date_pose"
                        :value="form.date_pose"
                        :disabled="true"
                    />
                    <InputCamera
                        :label="labels.img_environnement"
                        :images="images.img_environnement"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_environnement', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.tirsou_chb_prop"
                        :options="options.tirsou_chb_prop"
                        :value="form.tirsou_chb_prop"
                        @update:value="
                            (newValue) => saveData('tirsou_chb_prop', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.tirsou_chb_tmp"
                        :options="options.tirsou_chb_tmp"
                        :value="form.tirsou_chb_tmp"
                        @update:value="
                            (newValue) => saveData('tirsou_chb_tmp', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.tirsou_chb_cdr"
                        :options="options.tirsou_chb_cdr"
                        :value="form.tirsou_chb_cdr"
                        @update:value="
                            (newValue) => saveData('tirsou_chb_cdr', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.tirsou_chb_msk"
                        :options="options.tirsou_chb_msk"
                        :value="form.tirsou_chb_msk"
                        @update:value="
                            (newValue) => saveData('tirsou_chb_msk', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.tirsou_chb_obt"
                        :options="options.tirsou_chb_obt"
                        :value="form.tirsou_chb_obt"
                        @update:value="
                            (newValue) => saveData('tirsou_chb_obt', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.tirsou_cbl_gfn"
                        :options="options.tirsou_cbl_gfn"
                        :value="form.tirsou_cbl_gfn"
                        @update:value="
                            (newValue) => saveData('tirsou_cbl_gfn', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.tirsou_cbl_acc"
                        :options="options.tirsou_cbl_acc"
                        :value="form.tirsou_cbl_acc"
                        @update:value="
                            (newValue) => saveData('tirsou_cbl_acc', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.tirsou_cbl_glove"
                        :options="options.tirsou_cbl_glove"
                        :value="form.tirsou_cbl_glove"
                        @update:value="
                            (newValue) => saveData('tirsou_cbl_glove', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.tirsou_cbl_xcbl"
                        :options="options.tirsou_cbl_xcbl"
                        :value="form.tirsou_cbl_xcbl"
                        @update:value="
                            (newValue) => saveData('tirsou_cbl_xcbl', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.tirsou_cbl_occfour"
                        :options="options.tirsou_cbl_occfour"
                        :value="form.tirsou_cbl_occfour"
                        @update:value="
                            (newValue) =>
                                saveData('tirsou_cbl_occfour', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_cable"
                        :images="images.img_cable"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('img_cable', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.tirsou_etq_pot"
                        :options="options.tirsou_etq_pot"
                        :value="form.tirsou_etq_pot"
                        @update:value="
                            (newValue) => saveData('tirsou_etq_pot', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.tirsou_etq_cab"
                        :options="options.tirsou_etq_cab"
                        :value="form.tirsou_etq_cab"
                        @update:value="
                            (newValue) => saveData('tirsou_etq_cab', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_etiquette"
                        :images="images.img_etiquette"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('img_etiquette', newValue)
                        "
                    />
                    <CustomInputTextarea
                        :label="labels.tirsou_com"
                        :value="form.tirsou_com"
                        :maxlength="255"
                        @update:value="
                            (newValue) => saveData('tirsou_com', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.tirsou_type_audit"
                        :options="options.tirsou_type_audit"
                        :value="form.tirsou_type_audit"
                        @update:value="
                            (newValue) =>
                                saveData('tirsou_type_audit', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.tirsou_statut_audit + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.tirsou_statut_audit"
                        :value="form.tirsou_statut_audit"
                        @update:value="
                            (newValue) =>
                                saveData('tirsou_statut_audit', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.tirsou_gene + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.tirsou_gene"
                        :value="form.tirsou_gene"
                        @update:value="
                            (newValue) => saveData('tirsou_gene', newValue)
                        "
                    />
                </el-col>
            </el-row>
        </template>
    </FormWrapper>
</template>

<script lang="ts">
/* eslint-disable */
import { defineComponent } from 'vue';

import type { FormDataInterface } from '@connect-field/client/components/forms/form.types';
import FormTemplate from '@connect-field/client/components/forms/FormTemplate.vue';

import {
    labels,
    options,
} from '@connect-field/client/components/forms/AIC/Travaux/t_tvx_souterrain.data';

interface DataInterface extends FormDataInterface {}

export default defineComponent({
    extends: FormTemplate,
    data(): DataInterface {
        return {
            changes: false,
            form: {},
            images: {
                img_environnement: [],
                img_cable: [],
                img_etiquette: [],
            },
            labels,
            loading: true,
            mandatoryFields: ['tirsou_statut_audit', 'tirsou_gene'],
            options,

            valueMissing: false,
        };
    },
    methods: {},
});
</script>
