<template>
    <FormWrapper
        v-if="!loading"
        ref="customFormRef"
        :images-model="images"
        :form-model="form"
        :mandatory-fields="mandatoryFields"
        :changes-model="changes"
        :layer-name="layerName"
        :object-id="objectId"
        :project-id="projectId"
        @update:images-model="
            (e) => {
                images = e;
            }
        "
        @update:value-missing="
            (e) => {
                valueMissing = e;
            }
        "
        @update:form-model="
            (e) => {
                form = e;
            }
        "
        @update:changes-model="
            (e) => {
                changes = e;
            }
        "
    >
        <template #menu_tabs>
            <el-row class="scroll-menu">
                <el-col :span="12">
                    <div
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'Localisation' }"
                        @click="selectTab('Localisation')"
                    >
                        <FontAwesomeIcon
                            size="xs"
                            :icon="['fab', 'map-marked-alt']"
                        />
                    </div>
                </el-col>
                <el-col :span="12">
                    <div
                        class="tabs"
                        :class="{
                            selectedTab:
                                selectedTab === 'Caractéristiques du site',
                        }"
                        @click="selectTab('Caractéristiques du site')"
                    >
                        <FontAwesomeIcon
                            size="xs"
                            :icon="['fab', 'file-signature']"
                        />
                    </div>
                </el-col>
            </el-row>
        </template>
        <template #main_form>
            <el-row>
                <el-col
                    v-show="selectedTab === 'Localisation'"
                    :span="24"
                >
                    <CustomInput
                        :label="labels.identifiant + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.identifiant"
                        :maxlength="255"
                        @update:value="
                            (newValue) => saveData('identifiant', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.departement"
                        :value="form.departement"
                        @update:value="
                            (newValue) => saveData('departement', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commune"
                        :value="form.commune"
                        :maxlength="255"
                        @update:value="
                            (newValue) => saveData('commune', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.code_postal"
                        :value="form.code_postal"
                        @update:value="
                            (newValue) => saveData('code_postal', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.code_insee"
                        :value="form.code_insee"
                        @update:value="
                            (newValue) => saveData('code_insee', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.lieudit"
                        :value="form.lieudit"
                        :maxlength="255"
                        @update:value="
                            (newValue) => saveData('lieudit', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.adresse_post"
                        :value="form.adresse_post"
                        @update:value="
                            (newValue) => saveData('adresse_post', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.adresse_use"
                        :value="form.adresse_use"
                        :maxlength="255"
                        @update:value="
                            (newValue) => saveData('adresse_use', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.longitude + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.longitude"
                        @update:value="
                            (newValue) => saveData('longitude', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.latitude + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.latitude"
                        @update:value="
                            (newValue) => saveData('latitude', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.origine_coordonnees + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.origine_coordonnees"
                        :value="form.origine_coordonnees"
                        @update:value="
                            (newValue) =>
                                saveData('origine_coordonnees', newValue)
                        "
                    />
                </el-col>
                <el-col
                    v-show="selectedTab === 'Caractéristiques du site'"
                    :span="24"
                >
                    <CustomInputSelect
                        :label="labels.nature + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.nature"
                        :value="form.nature"
                        @update:value="
                            (newValue) => saveData('nature', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.altitude + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.altitude"
                        @update:value="
                            (newValue) => saveData('altitude', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.hauteur + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.hauteur"
                        @update:value="
                            (newValue) => saveData('hauteur', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.photo_site"
                        :value="form.photo_site"
                        @update:value="
                            (newValue) => saveData('photo_site', newValue)
                        "
                    />
                </el-col>
            </el-row>
        </template>
    </FormWrapper>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import {
    labels,
    options,
} from '@connect-field/client/components/forms/Dotic/Ingenierie/SiteForm.data';
import type { FormDataInterface } from '@connect-field/client/components/forms/form.types';
import FormTemplate from '@connect-field/client/components/forms/FormTemplate.vue';

interface DataInterface extends FormDataInterface {}

export default defineComponent({
    extends: FormTemplate,
    data(): DataInterface {
        return {
            changes: false,
            form: {},
            images: {},
            labels,
            loading: true,
            mandatoryFields: [
                'identifiant',
                'longitude',
                'latitude',
                'origine_coordonnees',
                'nature',
                'altitude',
                'hauteur',
            ],
            options,
            selectedTab: 'Localisation',
            valueMissing: false,
        };
    },
});
</script>
