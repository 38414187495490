<template>
    <FormWrapper
        v-if="!loading"
        ref="customFormRef"
        :images-model="images"
        :form-model="form"
        :mandatory-fields="mandatoryFields"
        :changes-model="changes"
        :layer-name="layerName"
        :object-id="objectId"
        :project-id="projectId"
        :latitude="latitude"
        :longitude="longitude"
        @update:images-model="
            (e) => {
                images = e;
            }
        "
        @update:value-missing="
            (e) => {
                valueMissing = e;
            }
        "
        @update:form-model="
            (e) => {
                form = e;
            }
        "
        @update:changes-model="
            (e) => {
                changes = e;
            }
        "
    >
        <template #menu_tabs>
            <el-row class="scroll-menu">
                <el-col :span="12">
                    <div
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'general' }"
                        @click="selectTab('general')"
                    >
                        Général
                    </div>
                </el-col>
                <el-col :span="12">
                    <div
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'site_nro' }"
                        @click="selectTab('site_nro')"
                    >
                        SITE NRO
                    </div>
                </el-col>
            </el-row>
        </template>
        <template #main_form>
            <el-row>
                <el-col
                    v-show="selectedTab === 'general'"
                    :span="24"
                >
                    <CustomInput
                        :label="labels.nt_code"
                        :value="form.code"
                        :disabled="true"
                        :maxlength="255"
                    />
                    <CustomDatePicker
                        :label="labels.date_audit + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.date_audit"
                        @update:value="
                            (newValue) => saveData('date_audit', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.adresse"
                        :value="form.adresse"
                        :disabled="true"
                        :maxlength="255"
                        @update:value="
                            (newValue) => saveData('adresse', newValue)
                        "
                    />
                    <!-- TODO : need to define st_typephy -->
                    <CustomInputSelect
                        :options="options.st_typephy"
                        :label="labels.st_typephy"
                        :value="form.st_typephy"
                        @update:value="
                            (newValue) => saveData('st_typephy', newValue)
                        "
                    />
                    <CustomInputSelect
                        :options="options.statut"
                        :label="labels.statut + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.statut"
                        @update:value="
                            (newValue) => saveData('statut', newValue)
                        "
                    />
                </el-col>

                <el-col
                    v-show="selectedTab === 'site_nro'"
                    :span="24"
                >
                    <CustomInputSwitch
                        :options="options.sn_obturation"
                        :label="labels.sn_obturation"
                        :value="form.sn_obturation"
                        @update:value="
                            (newValue) => saveData('sn_obturation', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :options="options.sn_eti_pe_ctr"
                        :label="labels.sn_eti_pe_ctr"
                        :value="form.sn_eti_pe_ctr"
                        @update:value="
                            (newValue) => saveData('sn_eti_pe_ctr', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.etiquette_ctr_nro"
                        :label="labels.etiquette_ctr_nro"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('etiquette_ctr_nro', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :options="options.sn_position"
                        :label="labels.sn_position"
                        :value="form.sn_position"
                        @update:value="
                            (newValue) => saveData('sn_position', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :options="options.sn_etat"
                        :label="labels.sn_etat"
                        :value="form.sn_etat"
                        @update:value="
                            (newValue) => saveData('sn_etat', newValue)
                        "
                    />

                    <CustomInputSwitch
                        :options="options.sn_capuchon_pe"
                        :label="labels.sn_capuchon_pe"
                        :value="form.sn_capuchon_pe"
                        @update:value="
                            (newValue) => saveData('sn_capuchon_pe', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :options="options.sn_connecteur"
                        :label="labels.sn_connecteur"
                        :value="form.sn_connecteur"
                        @update:value="
                            (newValue) => saveData('sn_connecteur', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :options="options.sn_ri"
                        :label="labels.sn_ri"
                        :value="form.sn_ri"
                        @update:value="
                            (newValue) => saveData('sn_ri', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.plateaux_ttr_nro"
                        :label="labels.plateaux_ttr_nro"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('plateaux_ttr_nro', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :options="options.sn_terrain_sig"
                        :label="labels.sn_terrain_sig"
                        :value="form.sn_terrain_sig"
                        @update:value="
                            (newValue) => saveData('sn_terrain_sig', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.sn_obs"
                        :value="form.sn_obs"
                        :maxlength="255"
                        @update:value="
                            (newValue) => saveData('sn_obs', newValue)
                        "
                    />
                </el-col>
            </el-row>
        </template>
    </FormWrapper>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import {
    labels,
    options,
} from '@connect-field/client/components/forms/Dotic/GraceTHD-v2/NR.data';
import type { FormDataInterface } from '@connect-field/client/components/forms/form.types';
import FormTemplate from '@connect-field/client/components/forms/FormTemplate.vue';

interface DataInterface extends FormDataInterface {}

export default defineComponent({
    extends: FormTemplate,
    data(): DataInterface {
        return {
            changes: false,
            form: {},
            images: {
                abaque: [],
                baie_fermee: [],
                baie_ouverte: [],
                baiea1: [],
                baiea2: [],
                branchement_jarretiere: [],
                cpl: [],
                environnement: [],
                etiquette_cable: [],
                etiquette_ctr_nro: [],
                etiquette_jarretiere: [],
                plateaux_ttr: [],
                plateaux_ttr_nro: [],
                puissance_metre: [],
                ttr: [],
                ttr_nro: [],
            },
            labels,
            loading: true,
            mandatoryFields: ['date_audit', 'statut'],
            options,
            selectedTab: 'general',
            valueMissing: false,
        };
    },
});
</script>

<style lang="scss" scoped>
.text-warning .custom_label_input {
    color: red;
}
</style>
