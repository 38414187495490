/*
Store to save navigation state and previous state
 */

import { acceptHMRUpdate, defineStore } from 'pinia';
import type { DataFormInterface, LayerDataInterface } from '@connect-field/client/stores/object';
import type { RouteMeta, RouteParams, RouteRecordName } from 'vue-router';
import type { ObjectDataInterface } from '@connect-field/client/services/map.service';

export interface RouteInterface {
    meta?: RouteMeta;
    name?: RouteRecordName | null;
    params: RouteParams;
    path: string;
}

export interface StateInterface {
    currentObjects: Array<ObjectDataInterface>;
    currentRoute?: RouteInterface;
    previousRoute?: RouteInterface;
}

const useNavigationStore = defineStore('navigation', {
    actions: {
        getObject(layerName: string, formSid: string | number): ObjectDataInterface | undefined {
            return this.currentObjects.find((object: ObjectDataInterface) => {
                return object.layerData.name === layerName && object.dataForm.sid.toString() === formSid.toString();
            }) as ObjectDataInterface;
        },
        resetCurrentObjects(): void {
            this.currentObjects = [];
        },
        setCurrentObjects(objects: Array<ObjectDataInterface>): void {
            this.$patch((state) => {
                state.currentObjects = objects;
            });
        },
        setCurrentRoute(route: RouteInterface): void {
            this.$patch((state) => {
                state.currentRoute = route;
            });
        },
        setPreviousRoute(route: RouteInterface): void {
            this.$patch((state) => {
                state.previousRoute = route;
            });
        },
        updateObjectDataForm(updatedObject: { dataForm: DataFormInterface; layerData: LayerDataInterface }): void {
            const indexToUpdate = this.currentObjects.findIndex(
                (object: ObjectDataInterface) =>
                    object.layerData.name === updatedObject.layerData.name &&
                    object.dataForm.sid === updatedObject.dataForm.sid,
            );

            if (indexToUpdate !== -1) {
                this.currentObjects[indexToUpdate].dataForm = updatedObject.dataForm;
            }
        },
    },
    state(): StateInterface {
        return {
            currentObjects: [],
            currentRoute: undefined,
            previousRoute: undefined,
        };
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useNavigationStore, import.meta.hot));
}

export default useNavigationStore;
