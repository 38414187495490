const dbName = 'connect-field';

export interface Store {
    indexes?: Array<string>;
    name: string;
    options?: Record<string, unknown>;
}

export const stores: Array<Store> = [
    { name: 'projects' },
    { name: 'projectConfigurations' },
    { name: 'projectMbtiles' },
    { name: 'projectGeojsons' },
    { name: 'projectBasemaps' },
    { name: 'offlineProjects' },
    { name: 'savedDataForms' },
    { name: 'projectSearch' },
    {
        indexes: ['layer', 'fk_sid'],
        name: 'projectImages_v2',
    },
    { name: 'draftDataForms' },
];

export const offlineStores = [
    'projectConfigurations',
    'projectMbtiles',
    'projectBasemaps',
    'projectGeojsons',
    'offlineProjects',
    'savedDataForms',
    'projectSearch',
    'projectImages_v2',
    'draftDataForms',
];

// @ts-ignore
const indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;

const version = 6;

const openRequest = indexedDB.open(dbName, version);

openRequest.onupgradeneeded = (event: IDBVersionChangeEvent): void => {
    // the existing database version outdated or doesn't exist
    const db: IDBDatabase = openRequest.result;
    switch (
        event.oldVersion // existing db version
    ) {
        case 0:
            stores.forEach((store: Store) => {
                if (!db.objectStoreNames.contains(store.name)) {
                    console.debug(`[idb] creating the store ${store.name}`);
                    const _store = db.createObjectStore(store.name, store.options);
                    if (store.indexes) {
                        store.indexes.forEach((index: string) => _store.createIndex(index, index));
                    }
                }
            });
            break;
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
            alert("Votre application n'est pas à jour. Réinstallez l'application");
            break;
        default:
            break;
    }
};

openRequest.onsuccess = (): void => {
    const db = openRequest.result;

    db.onversionchange = (): void => {
        db.close();
        alert("Votre application n'est pas à jour. Fermez et relancez l'application");
    };
};

openRequest.onblocked = function (): void {
    // this event shouldn't trigger if we handle onversionchange correctly
    // it means that there's another open connection to the same database,
    // and it wasn't closed after db.onversionchange triggered for it
};

function getDB(): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
        const openRequest = indexedDB.open(dbName, version);
        openRequest.onsuccess = (): void => {
            resolve(openRequest.result);
        };
        openRequest.onerror = (): void => {
            reject();
        };
        openRequest.onblocked = (): void => {
            reject();
        };
    });
}

export function setStore(storeName: string, val: unknown, key: string): Promise<void> {
    return new Promise((resolve, reject) => {
        console.debug('[idb] setting a store', storeName);
        getDB().then((db: IDBDatabase) => {
            const tx = db.transaction(storeName, 'readwrite');
            tx.onabort = (e): void => {
                e.preventDefault();
                e.stopPropagation();
                // Documentation : https://webidl.spec.whatwg.org/#idl-DOMException-error-names
                if (e instanceof DOMException) {
                    // TODO: Fix this
                    if (e?.target?.error?.name) {
                        reject(e.target.error.name);
                    }
                }
                reject(e);
            };

            const objectStore = tx.objectStore(storeName);
            objectStore.put(val, key);

            tx.oncomplete = (): void => {
                resolve();
            };
        });
    });
}

export function getItem<T>(key: string, store: string): Promise<T> {
    return new Promise((resolve, reject) => {
        console.debug('[idb] getting an item', store, key);
        getDB().then((db) => {
            const transaction = db.transaction([store], 'readonly');
            const objectStore = transaction.objectStore(store);
            const request = objectStore.get(key);

            request.onerror = (event): void => {
                console.error(event);
                reject('[idb:getItem] Error');
            };
            request.onsuccess = (): void => {
                resolve(request.result as T);
            };
        });
    });
}

export function getItems<T = unknown>(store: string): Promise<Array<T>> {
    return new Promise((resolve, reject) => {
        console.debug('[idb] getting a store', store);
        getDB().then((db) => {
            const transaction = db.transaction([store], 'readonly');
            const objectStore = transaction.objectStore(store);
            const request = objectStore.getAll();

            request.onerror = (event): void => {
                console.error(event);
                reject('[idb:getItems] Error');
            };
            request.onsuccess = (): void => {
                resolve(request.result);
            };
        });
    });
}

export function count(store: string): Promise<number> {
    return new Promise((resolve, reject) => {
        console.debug('[idb] getting count', store);
        getDB().then((db) => {
            const transaction = db.transaction([store], 'readonly');
            const objectStore = transaction.objectStore(store);
            const request = objectStore.count();

            request.onerror = (event): void => {
                console.error(event);
                reject('[idb:count] Error');
            };
            request.onsuccess = (): void => {
                resolve(request.result);
            };
        });
    });
}

export function getAllKeysFromIndex(store: string, index: string, indexKey: string | number): Promise<Array<string>> {
    return new Promise((resolve, reject) => {
        console.debug('[idb] getting indexes from a store', store, index, indexKey);
        getDB().then((db) => {
            const transaction = db.transaction([store], 'readonly');
            const objectStore = transaction.objectStore(store);
            const myIndex = objectStore.index(index);
            const request = myIndex.getAllKeys(indexKey);

            request.onerror = (event): void => {
                console.error(event);
                reject('[idb:getAllKeysFromIndex] Error');
            };
            request.onsuccess = (): void => {
                resolve(request.result.map((value: IDBValidKey) => value.toString()));
            };
        });
    });
}

export function getAllKeys(store: string): Promise<Array<string>> {
    return new Promise((resolve, reject) => {
        console.debug('[idb] getting all keys from a store', store);
        getDB().then((db) => {
            const transaction = db.transaction([store], 'readonly');
            const objectStore = transaction.objectStore(store);
            const request = objectStore.getAllKeys();

            request.onerror = (event): void => {
                console.error(event);
                reject('[idb:getAllKeys] Error');
            };
            request.onsuccess = (): void => {
                resolve(request.result);
            };
        });
    });
}

export function deleteItem(store: string, key: string): Promise<void> {
    return new Promise((resolve, reject) => {
        console.debug('[idb]  delete item', store, key);
        getDB().then((db) => {
            const transaction = db.transaction([store], 'readwrite');
            const objectStore = transaction.objectStore(store);
            const request = objectStore.delete(key);

            request.onerror = (event): void => {
                console.error(event);
                reject('[idb:deleteItem] Error request');
            };
            request.onsuccess = (): void => {
                resolve();
            };
        });
    });
}

export function clearStore(store: string): Promise<void> {
    return new Promise((resolve, reject) => {
        console.debug('[idb] clearing store', store);
        getDB().then((db) => {
            const transaction = db.transaction([store], 'readwrite');
            const objectStore = transaction.objectStore(store);
            const request = objectStore.clear();

            request.onerror = (event): void => {
                console.error(event);
                reject('[idb:clearStore] Error');
            };
            request.onsuccess = (): void => {
                resolve();
            };
        });
    });
}
