<template>
    <FormWrapper
        v-if="!loading"
        ref="customFormRef"
        :images-model="images"
        :form-model="form"
        :mandatory-fields="mandatoryFields"
        :changes-model="changes"
        :layer-name="layerName"
        :object-id="objectId"
        :project-id="projectId"
        :latitude="latitude"
        :longitude="longitude"
        @update:images-model="
            (e) => {
                images = e;
            }
        "
        @update:value-missing="
            (e) => {
                valueMissing = e;
            }
        "
        @update:form-model="
            (e) => {
                form = e;
            }
        "
        @update:changes-model="
            (e) => {
                changes = e;
            }
        "
    >
        <template #menu_tabs> </template>
        <template #main_form>
            <el-row>
                <el-col :span="24">
                    <CustomInput
                        :label="labels.tiraer_code"
                        :value="form.tiraer_code"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.tiraer_ptech_code"
                        :value="form.tiraer_ptech_code"
                        :maxlength="255"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.tiraer_cable_code"
                        :value="form.tiraer_cable_code"
                        :disabled="true"
                    />
                    <InputNumber
                        :label="labels.tiraer_cable_capa"
                        :value="form.tiraer_cable_capa"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.tiraer_cable_sta"
                        :value="form.tiraer_cable_sta"
                        :maxlength="255"
                        :disabled="true"
                    />
                    <CustomDatePicker
                        :label="labels.date_pose"
                        :value="form.date_pose"
                        :disabled="true"
                    />
                    <InputCamera
                        :label="labels.environnement"
                        :images="images.environnement"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('environnement', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.tiraer_secu"
                        :options="options.tiraer_secu"
                        :value="form.tiraer_secu"
                        @update:value="
                            (newValue) => saveData('tiraer_secu', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.tiraer_ri"
                        :options="options.tiraer_ri"
                        :value="form.tiraer_ri"
                        @update:value="
                            (newValue) => saveData('tiraer_ri', newValue)
                        "
                    />
                    <CustomInputTextarea
                        :label="labels.tiraer_ri_cmt"
                        :value="form.tiraer_ri_cmt"
                        :maxlength="255"
                        @update:value="
                            (newValue) => saveData('tiraer_ri_cmt', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.tiraer_elag"
                        :options="options.tiraer_elag"
                        :value="form.tiraer_elag"
                        @update:value="
                            (newValue) => saveData('tiraer_elag', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.tiraer_trav"
                        :options="options.tiraer_trav"
                        :value="form.tiraer_trav"
                        @update:value="
                            (newValue) => saveData('tiraer_trav', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.tiraer_pince"
                        :options="options.tiraer_pince"
                        :value="form.tiraer_pince"
                        @update:value="
                            (newValue) => saveData('tiraer_pince', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.tiraer_accroch"
                        :options="options.tiraer_accroch"
                        :value="form.tiraer_accroch"
                        @update:value="
                            (newValue) => saveData('tiraer_accroch', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.tiraer_fleche"
                        :options="options.tiraer_fleche"
                        :value="form.tiraer_fleche"
                        @update:value="
                            (newValue) => saveData('tiraer_fleche', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.tiraer_rem_aero"
                        :options="options.tiraer_rem_aero"
                        :value="form.tiraer_rem_aero"
                        @update:value="
                            (newValue) => saveData('tiraer_rem_aero', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.tiraer_croix"
                        :options="options.tiraer_croix"
                        :value="form.tiraer_croix"
                        @update:value="
                            (newValue) => saveData('tiraer_croix', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.cable"
                        :images="images.cable"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('cable', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.tiraer_etq_pot"
                        :options="options.tiraer_etq_pot"
                        :value="form.tiraer_etq_pot"
                        @update:value="
                            (newValue) => saveData('tiraer_etq_pot', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.tiraer_etq_cab"
                        :options="options.tiraer_etq_cab"
                        :value="form.tiraer_etq_cab"
                        @update:value="
                            (newValue) => saveData('tiraer_etq_cab', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.tiraer_bdx_vert"
                        :options="options.tiraer_bdx_vert"
                        :value="form.tiraer_bdx_vert"
                        @update:value="
                            (newValue) => saveData('tiraer_bdx_vert', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.etiquette"
                        :images="images.etiquette"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('etiquette', newValue)
                        "
                    />
                    <CustomInputTextarea
                        :label="labels.tiraer_com_global"
                        :value="form.tiraer_com_global"
                        :maxlength="255"
                        @update:value="
                            (newValue) =>
                                saveData('tiraer_com_global', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.tiraer_type_audit"
                        :options="options.tiraer_type_audit"
                        :value="form.tiraer_type_audit"
                        @update:value="
                            (newValue) =>
                                saveData('tiraer_type_audit', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.tiraer_statut_audit + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.tiraer_statut_audit"
                        :value="form.tiraer_statut_audit"
                        @update:value="
                            (newValue) =>
                                saveData('tiraer_statut_audit', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.tiraer_gene + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.tiraer_gene"
                        :value="form.tiraer_gene"
                        @update:value="
                            (newValue) => saveData('tiraer_gene', newValue)
                        "
                    />
                </el-col>
            </el-row>
        </template>
    </FormWrapper>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import {
    labels,
    options,
} from '@connect-field/client/components/forms/AIC/Travaux/t_tvx_aerien.data';
import type { FormDataInterface } from '@connect-field/client/components/forms/form.types';
import FormTemplate from '@connect-field/client/components/forms/FormTemplate.vue';
interface DataInterface extends FormDataInterface {}

export default defineComponent({
    extends: FormTemplate,
    data(): DataInterface {
        return {
            changes: false,
            form: {},
            images: {
                environnement: [],
                cable: [],
                etiquette: [],
            },
            labels,
            loading: true,
            mandatoryFields: ['tiraer_statut_audit', 'tiraer_gene'],
            options,

            valueMissing: false,
        };
    },
    methods: {},
});
</script>
