<template>
    <el-input
        v-model="model"
        :placeholder="placeholder"
        clearable
        :prefix-icon="SearchIcon"
    />
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { Search as SearchIcon } from '@element-plus/icons-vue';

export default defineComponent({
    name: 'InputSearch',
    props: {
        placeholder: {
            type: String,
            default: '',
        },
    },
    emits: ['update:value'],
    setup() {
        return { model: ref(''), SearchIcon };
    },
    watch: {
        model: {
            handler(newValue) {
                this.$emit('update:value', newValue);
            },
        },
    },
});
</script>
