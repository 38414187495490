import type { OptionsInterface, SelectInterface } from '@connect-field/client/components/forms/form.types';

export const labels: Record<string, string> = {
    activite: 'Activité',
    agent_controle: 'Agent contrôlé',
    covid_gel: 'Gel ou autres solutions lavantes',
    covid_gestes_barriere: 'Gestes barrières',
    covid_masque: 'Masque',
    covid_observations: 'Observations COVID',
    date_arret_chantier: 'Date arrêt de chantier',
    date_audit: 'Date émission',
    doc_amiante: 'Formation amiante',
    doc_arrete_circu: 'Arrêté de circulation',
    doc_caces: 'CACES',
    doc_carte_pro: 'Carte professionelle',
    doc_dict: 'DICT',
    doc_habilitation_aipr: 'Habilitations AIPR',
    doc_habilitation_elect: 'Habilitations électriques',
    doc_observations: 'Observations documents',
    doc_perm_voirie: 'Permission de Voirie',
    doc_ppsps: 'Plan de prévention des risques',
    doc_tvx_enedis: 'Documents travaux ENEDIS',
    doc_visite_levage: 'VGP/Visite de levage',
    epi_casque: 'Casque / casquette coquée',
    epi_chaussures: 'Chaussures/bottes de sécurité',
    epi_gants: 'Gants',
    epi_gilet: 'Gilet de signalisation floqué au nom du projet',
    epi_lunette_gc: 'Lunettes de protection - Sciage Percement',
    epi_lunette_soudure: 'Lunettes de protection (Travaux Optique de soudure )',
    epi_observations: 'Observations equipement',
    epi_protec_auditive: 'Protections auditives (Travaux de Génie Civil)',
    eqp_blindage_fouille: 'Blindage des fouilles ( Tranchée + 1,30m de prof. / 1m Largeur )',
    eqp_clef_nfc: 'Clef NFC',
    eqp_clef_triangle: 'Clef triangle',
    eqp_detecteur_gaz: 'Détecteur de gaz bi fonction (uniquement chambre plafonnée)',
    eqp_engins: 'Engins de Chantier : Conformité, Etat, Rangement',
    eqp_marteau_leve_plaque: 'Marteau décolleur / Lève plaques',
    eqp_materiel: 'Matériels de Chantier : Conformité',
    eqp_observations: 'Observations Équipement',
    img_as_documents: 'Photo Documents',
    img_as_epi: 'Photo Equipement de protection individuelle',
    img_as_equipement: 'Photo Equipement',
    img_as_securite_chantier: 'Photo Sécurité du chantier',
    img_as_travaux_hauteur: 'Photo Travaux Hauteur',
    nb_personnel_present: 'Nombre de personne présente sur site (hors auditeur)',
    observation_globale: 'Observations Globale',
    phase_tvx: 'Phase',
    sc_amenagement: 'Aménagement Aire de travail (garde-fou, plot, déviation pieton)',
    sc_marquage_reseau: 'Marquage au sol des réseaux',
    sc_observations: 'Observations sécurité',
    sc_panneau_approche: 'Panneau signalisation approche',
    sc_panneau_position: 'Panneau signalisation position',
    sc_proprete: 'Propreté du chantier',
    sc_visibilite_vehicule: 'Visibilité du véhicule (marquage, bande, girophare)',
    sst_rang1: 'Entreprise Rang 1',
    sst_rang2: 'Entreprise Rang 2',
    sst_rang3: 'Entreprise Rang 3',
    statut_audit: 'Statut audit',
    th_binome: '2 agents (binôme)',
    th_ceinture_ligne_vie: 'Ceinture maintien / Ligne de vie',
    th_controle_appui: 'Contrôle appui (s)',
    th_echelle: 'Échelle / Escabeau (Suivant Article R4323-63)',
    th_harnais: 'Harnais de sécurité',
    th_nacelle: 'Utilisation Nacelle',
    th_observations: 'Observations Travaux Hauteur',
    th_point_ancrage: "Point d'ancrage",
    th_systeme_arret_chute: "Système d'arrêt de chute",
    visite_effectue_aic: 'Visite effectuée par',
};

export const options: Record<string, OptionsInterface | SelectInterface> = {
    activite: [
        { label: 'Travaux GC', value: 'Travaux GC' },
        { label: 'Travaux Optique', value: 'Travaux Optique' },
        { label: 'Tirage Câble', value: 'Tirage Câble' },
        { label: 'Implantation Support', value: 'Implantation Support' },
        { label: 'Travaux façade', value: 'Travaux façade' },
        { label: 'Travaux immeuble', value: 'Travaux immeuble' },
    ],
    covid_gel: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Insuffisant', value: 'Insuffisant' },
        { label: 'Absence', value: 'Absence' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    covid_gestes_barriere: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Insuffisant', value: 'Insuffisant' },
        { label: 'Absence', value: 'Absence' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    covid_masque: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Insuffisant', value: 'Insuffisant' },
        { label: 'Absence', value: 'Absence' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    doc_amiante: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Insuffisant', value: 'Insuffisant' },
        { label: 'Absence', value: 'Absence' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    doc_arrete_circu: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Insuffisant', value: 'Insuffisant' },
        { label: 'Absence', value: 'Absence' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    doc_caces: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Insuffisant', value: 'Insuffisant' },
        { label: 'Absence', value: 'Absence' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    doc_carte_pro: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Insuffisant', value: 'Insuffisant' },
        { label: 'Absence', value: 'Absence' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    doc_dict: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Insuffisant', value: 'Insuffisant' },
        { label: 'Absence', value: 'Absence' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    doc_habilitation_aipr: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Insuffisant', value: 'Insuffisant' },
        { label: 'Absence', value: 'Absence' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    doc_habilitation_elect: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Insuffisant', value: 'Insuffisant' },
        { label: 'Absence', value: 'Absence' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    doc_perm_voirie: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Insuffisant', value: 'Insuffisant' },
        { label: 'Absence', value: 'Absence' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    doc_ppsps: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Insuffisant', value: 'Insuffisant' },
        { label: 'Absence', value: 'Absence' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    doc_tvx_enedis: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Insuffisant', value: 'Insuffisant' },
        { label: 'Absence', value: 'Absence' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    doc_visite_levage: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Insuffisant', value: 'Insuffisant' },
        { label: 'Absence', value: 'Absence' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    epi_casque: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Insuffisant', value: 'Insuffisant' },
        { label: 'Absence', value: 'Absence' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    epi_chaussures: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Insuffisant', value: 'Insuffisant' },
        { label: 'Absence', value: 'Absence' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    epi_gants: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Insuffisant', value: 'Insuffisant' },
        { label: 'Absence', value: 'Absence' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    epi_gilet: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Insuffisant', value: 'Insuffisant' },
        { label: 'Absence', value: 'Absence' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    epi_lunette_gc: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Insuffisant', value: 'Insuffisant' },
        { label: 'Absence', value: 'Absence' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    epi_lunette_soudure: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Insuffisant', value: 'Insuffisant' },
        { label: 'Absence', value: 'Absence' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    epi_protec_auditive: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Insuffisant', value: 'Insuffisant' },
        { label: 'Absence', value: 'Absence' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    eqp_blindage_fouille: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Insuffisant', value: 'Insuffisant' },
        { label: 'Absence', value: 'Absence' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    eqp_clef_nfc: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Insuffisant', value: 'Insuffisant' },
        { label: 'Absence', value: 'Absence' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    eqp_clef_triangle: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Insuffisant', value: 'Insuffisant' },
        { label: 'Absence', value: 'Absence' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    eqp_detecteur_gaz: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Insuffisant', value: 'Insuffisant' },
        { label: 'Absence', value: 'Absence' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    eqp_engins: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Insuffisant', value: 'Insuffisant' },
        { label: 'Absence', value: 'Absence' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    eqp_marteau_leve_plaque: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Insuffisant', value: 'Insuffisant' },
        { label: 'Absence', value: 'Absence' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    eqp_materiel: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Insuffisant', value: 'Insuffisant' },
        { label: 'Absence', value: 'Absence' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    phase_tvx: [
        { label: 'Collecte', value: 'Collecte' },
        { label: 'Transport', value: 'Transport' },
        { label: 'Distribution', value: 'Distribution' },
    ],
    sc_amenagement: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Insuffisant', value: 'Insuffisant' },
        { label: 'Absence', value: 'Absence' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    sc_marquage_reseau: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Insuffisant', value: 'Insuffisant' },
        { label: 'Absence', value: 'Absence' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    sc_panneau_approche: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Insuffisant', value: 'Insuffisant' },
        { label: 'Absence', value: 'Absence' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    sc_panneau_position: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Insuffisant', value: 'Insuffisant' },
        { label: 'Absence', value: 'Absence' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    sc_proprete: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Insuffisant', value: 'Insuffisant' },
        { label: 'Absence', value: 'Absence' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    sc_visibilite_vehicule: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Insuffisant', value: 'Insuffisant' },
        { label: 'Absence', value: 'Absence' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    statut_audit: [
        { label: 'NON REALISE', value: 'NON REALISE' },
        { label: 'PARTIELLEMENT', value: 'PARTIELLEMENT' },
        { label: 'TERMINE', value: 'TERMINE' },
    ],
    th_binome: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Insuffisant', value: 'Insuffisant' },
        { label: 'Absence', value: 'Absence' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    th_ceinture_ligne_vie: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Insuffisant', value: 'Insuffisant' },
        { label: 'Absence', value: 'Absence' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    th_controle_appui: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Insuffisant', value: 'Insuffisant' },
        { label: 'Absence', value: 'Absence' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    th_echelle: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Insuffisant', value: 'Insuffisant' },
        { label: 'Absence', value: 'Absence' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    th_harnais: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Insuffisant', value: 'Insuffisant' },
        { label: 'Absence', value: 'Absence' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    th_nacelle: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Insuffisant', value: 'Insuffisant' },
        { label: 'Absence', value: 'Absence' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    th_point_ancrage: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Insuffisant', value: 'Insuffisant' },
        { label: 'Absence', value: 'Absence' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
    th_systeme_arret_chute: [
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Insuffisant', value: 'Insuffisant' },
        { label: 'Absence', value: 'Absence' },
        { label: 'Sans objet', value: 'Sans objet' },
    ],
};
