<script lang="ts">
// import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { defineComponent } from 'vue';
import { mapState } from 'pinia';

import {
    deepCopy,
    resetScrollValue,
} from '@connect-field/client/utilities/tools';
import CustomDatePicker from '@connect-field/client/components/inputs/DatePicker.vue';
import CustomInput from '@connect-field/client/components/inputs/Input.vue';
import CustomInputMultiSelect from '@connect-field/client/components/inputs/InputMultiSelect.vue';
import CustomInputSelect from '@connect-field/client/components/inputs/InputSelect.vue';
import CustomInputSwitch from '@connect-field/client/components/inputs/InputSwitch.vue';
import CustomInputTextarea from '@connect-field/client/components/inputs/InputTextarea.vue';
import CustomRadioButton from '@connect-field/client/components/inputs/InputRadioButton.vue';
import type { FormDataInterface } from '@connect-field/client/components/forms/form.types';
import FormWrapper from '@connect-field/client/pages/home/form/FormWrapper.vue';
import InputCamera from '@connect-field/client/components/inputs/InputCamera.vue';
import InputNumber from '@connect-field/client/components/inputs/InputNumber.vue';
// import { updateLastActionLocalStorage } from '@connect-field/client/services/form.service';
import useNavigationStore from '@connect-field/client/stores/navigation';
import useObjectStore from '@connect-field/client/stores/object';

type FormWrapperInterface = typeof FormWrapper;

export default defineComponent({
    $refs: { customFormRef: FormWrapper },
    components: {
        CustomDatePicker,
        CustomInput,
        CustomInputMultiSelect,
        CustomInputSelect,
        CustomInputSwitch,
        CustomInputTextarea,
        CustomRadioButton,
        FormWrapper,
        InputCamera,
        InputNumber,
    },
    props: {
        // eslint-disable-next-line vue/no-unused-properties
        latitude: {
            type: String,
            default: '',
        },
        // eslint-disable-next-line vue/no-unused-properties
        layerName: {
            type: String,
            required: true,
        },
        // eslint-disable-next-line vue/no-unused-properties
        longitude: {
            type: String,
            default: '',
        },
        objectId: {
            type: [String, Number],
            default: '',
        },
        // eslint-disable-next-line vue/no-unused-properties
        projectId: {
            type: [String, Number],
            required: true,
        },
    },
    data(): FormDataInterface {
        return {
            changes: false,
            form: {},
            images: {},
            // eslint-disable-next-line vue/no-unused-properties
            level: 0,
            loading: true,
            // eslint-disable-next-line vue/no-unused-properties
            mandatoryFields: [],
            selectedTab: '',
            // eslint-disable-next-line vue/no-unused-properties
            valueMissing: false,
        };
    },
    computed: {
        ...mapState(useObjectStore, {
            coordinate: 'coordinate',
            dataForm: 'dataForm',
            Feature: 'Feature',
            layerData: 'layerData',
        }),
        ...mapState(useNavigationStore, {
            objects: 'currentObjects',
        }),
    },
    mounted() {
        if (this.objectId && this.dataForm) {
            this.form = deepCopy<Record<string, any>>(this.dataForm);
        }
        this.loading = false;
    },
    methods: {
        // eslint-disable-next-line vue/no-unused-properties
        saveData(
            key: string,
            newValue: string | boolean | number | Array<string> | Array<number>,
        ): void {
            this.form[key] = newValue;
            this.changes = true;
            this.saveTemporaryDataForm(key, newValue);
        },
        // eslint-disable-next-line vue/no-unused-properties
        saveImage(key: string, newImage: string): void {
            this.images[key] = newImage;
            this.changes = true;
            this.saveTemporaryDataForm(key, newImage);
        },
        saveTemporaryDataForm(
            key: string,
            newValue: string | boolean | number | Array<string> | Array<number>,
        ): void {
            if (this.$refs.customFormRef) {
                (
                    this.$refs.customFormRef as FormWrapperInterface
                )?.saveTemporaryDataForm(key, newValue);
            }
        },
        // eslint-disable-next-line vue/no-unused-properties
        selectTab(selection: string): void {
            resetScrollValue('main.form-main');
            this.selectedTab = selection;
        },
    },
});
</script>
