import { Circle, Fill, Icon, RegularShape, Stroke, Style } from 'ol/style';
import GeoJSON from 'ol/format/GeoJSON';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';

export function generateSelectionLayer(url: string, keyColumnName: string): VectorLayer<VectorSource> {
    const source = new VectorSource({
        format: new GeoJSON(),
        url,
    });

    const selectionLayer = new VectorLayer({
        className: `layer-geojson-selection`,
        properties: {
            alias: 'Couche de sélection',
            global: false,
            keyColumnName: keyColumnName,
            name: 'layer.selection_layer',
            online: true,
            selectionLayer: true,
        },
        source,
        visible: true,
    });

    selectionLayer.setStyle((feature) => {
        if (feature.getProperties()._isSelected) {
            return new Style({
                fill: new Fill({
                    color: 'rgba(255,0,21,0.3)',
                }),
                stroke: new Stroke({
                    color: 'rgb(234,4,43)',
                    width: 2,
                }),
                zIndex: 1,
            });
        }

        return new Style({
            fill: new Fill({ color: 'rgba(0,0,0,0.1)' }),
            stroke: new Stroke({ width: 0 }),
            zIndex: 0,
        });
    });
    selectionLayer.setZIndex(Number.MAX_SAFE_INTEGER);

    return selectionLayer;
}

export function generateGeolocationLayer(): VectorLayer<VectorSource> {
    const geolocationLayer = new VectorLayer({
        className: 'layer-geolocation',
        properties: {
            geolocation: true,
            global: true,
            online: false,
        },
        source: new VectorSource({
            features: [],
        }),
        style: new Style({
            image: new Circle({
                fill: new Fill({ color: '#41a4ea' }),
                radius: 6,
                stroke: new Stroke({
                    color: '#0279ff',
                    width: 2,
                }),
            }),
        }),
    });
    geolocationLayer.setZIndex(1001);

    return geolocationLayer;
}

export function generatePinLayer(): VectorLayer<VectorSource> {
    const pinLayer = new VectorLayer({
        className: 'layer-pin',
        properties: {
            global: true,
            online: false,
            pin: true,
        },
        source: new VectorSource({
            features: [],
        }),
        style: new Style({
            image: new Icon({
                anchor: [0.5, 24],
                anchorXUnits: 'fraction',
                anchorYUnits: 'pixels',
                opacity: 1,
                size: [24, 24],
                src: '/img/placeholder24_24.png',
            }),
        }),
    });
    pinLayer.setZIndex(100000);

    return pinLayer;
}

export function generateHighLightLayer(): VectorLayer<VectorSource> {
    const highlightLayer = new VectorLayer({
        className: 'layer-highlight',
        properties: {
            global: true,
            highlight: true,
            name: 'highlightLayer',
            online: false,
        },
        source: new VectorSource({ features: [] }),
        style: new Style({
            image: new RegularShape({
                angle: 0,
                points: 4,
                radius: 10,
                radius2: 0,
                stroke: new Stroke({
                    color: 'red',
                    width: 3,
                }),
            }),
            stroke: new Stroke({
                color: 'red',
                width: 3,
            }),
        }),
    });
    highlightLayer.setZIndex(10000);

    return highlightLayer;
}

export function generateSearchPbfLayer(): VectorLayer<VectorSource> {
    const searchResultPbfLayer = new VectorLayer({
        className: 'layer-search-result-pbf',
        properties: {
            global: true,
            name: 'searchResultPbfLayer',
            online: false,
        },
        source: new VectorSource({ features: [] }),
    });
    searchResultPbfLayer.setZIndex(1000);

    return searchResultPbfLayer;
}
