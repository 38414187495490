import type { OptionsInterface, SelectInterface } from '@connect-field/client/components/forms/form.types';

export const labels: Record<string, string> = {
    arrimage_gaine: 'Arrimage de la gaine de la bloolite',
    autre_general: 'Autre',
    brassage_correct: 'Brassage correct',
    cheminement_bloolite: 'Cheminement de la bloolite (longueur suffisante, courbure)',
    commentaire_arrimage_gaine: 'Commentaire',
    commentaire_autre_general: 'Commentaire',
    commentaire_brassage_correct: 'Commentaire',
    commentaire_cheminement_bloolite: 'Commentaire',
    commentaire_contrôle_acces: 'Commentaire',
    commentaire_detubage_fibres_lovage_fibres: 'Commentaire',
    commentaire_epanouissement: 'Commentaire',
    commentaire_etiquette_armoire: 'Commentaire',
    commentaire_etiquettes_cab_ecl: 'Commentaire',
    commentaire_etiquettes_tiroir: 'Commentaire',
    commentaire_fixation_cables_entree: 'Commentaire',
    commentaire_fixation_eclateur: 'Commentaire',
    commentaire_fixation_tiroir: 'Commentaire',
    commentaire_georeferencement_pm: 'Commentaire',
    commentaire_nb_fibres_transport: 'Commentaire',
    commentaire_nb_u_correct: 'Commentaire',
    commentaire_obturation_fourreaux_site: 'Commentaire',
    commentaire_ouverture_libre_tiroirs: 'Commentaire',
    commentaire_presence_bouchons: 'Commentaire',
    commentaire_presence_consigne_brassage: 'Commentaire',
    commentaire_presence_gaine_annelee: 'Commentaire',
    commentaire_proprete: 'Commentaire',
    commentaire_type_brassage: 'Commentaire',
    commentaire_verification_position_tiroirs: 'Commentaire',
    contrôle_acces: "Contrôle d'accès (serrure porte canon)",
    detubage_fibres_lovage_fibres: 'Détubage des fibres et lovages des fibres',
    donnees_metier: 'Affichage des données métier',
    epanouissement: 'Epanouissement (bloolite fixée et assez longue)',
    etiquette_armoire: "Étiquette sur l'armoire",
    etiquettes_cab_ecl: 'Etiquettes (Présentes, libres et justes)',
    etiquettes_tiroir: 'Etiquettes (Présentes, libres et justes)',
    fixation_cables_entree: 'Fixation des câbles en entrée',
    fixation_eclateur: "Fixation de l'éclateur",
    fixation_tiroir: 'Fixation (Partie fixe + Charnière)',
    georeferencement_pm: 'Géoréférencement du PM',
    img_arrimage_gaine: 'Photo de quelques tiroirs, surtout si non-conformité',
    img_autre_general: 'Photo',
    img_brassage_correct: 'Photo',
    img_cheminement_bloolite: "Photo du cheminement en fond d'armoire",
    img_contrôle_acces: 'Photos  canon, poignée fermée, poignée ouverte, tringlerie intérieure',
    img_detubage_fibres_lovage_fibres: 'Photo de quelques tiroirs, surtout si non-conformité',
    img_epanouissement: 'Photo',
    img_etiquette_armoire: 'Photo',
    img_etiquettes_cab_ecl: 'Photo',
    img_etiquettes_tiroir: 'Photo',
    img_fixation_cables_entree: 'Photo',
    img_fixation_eclateur: 'Photo éclateur fermé',
    img_fixation_tiroir: 'Photo des vis de fixation',
    img_georeferencement_pm: 'Photo de loin dans le contexte',
    img_nb_fibres_transport: 'Photo',
    img_nb_u_correct: 'Photo',
    img_obturation_fourreaux_site: "Photo des entrées de câble en bas d'armoire",
    img_ouverture_libre_tiroirs: 'Photo de face, tous les tiroirs ouverts',
    img_presence_bouchons: 'Photo de quelques tiroirs, surtout si non-conformité',
    img_presence_consigne_brassage: 'Photo',
    img_presence_gaine_annelee: 'Photo éclateur ouvert',
    img_proprete: "Photo du bas de l'armoire",
    img_type_brassage: 'Photo',
    img_verification_position_tiroirs: 'Photo globale des tiroirs de distri',
    nb_fibres_transport: 'Nb de fibres de transport',
    nb_u_correct: 'Nb U correct',
    obturation_fourreaux_site: "Obturation des fourreaux du site en bas d'armoire",
    ouverture_libre_tiroirs: 'Ouverture libre des tiroirs (Absence de frottements)',
    presence_bouchons: 'Présence des bouchons (Connecteurs)',
    presence_consigne_brassage: 'Présence des consignes de brassage',
    presence_gaine_annelee: 'Présence de la gaine annelée / bloolite',
    proprete: 'Propreté',
    st_abddate: "Date d'abandon de l'objet",
    st_abdsrc: "Cause de l'abandon de l'objet",
    st_ad_code: 'Identifiant unique contenu dans la table ADRESSE',
    st_avct: "Attribut synthétisant l'avancement.",
    st_code: 'Code du site',
    st_codeext: 'Code chez un tiers',
    st_comment: 'Commentaire',
    st_creadat: "Date de création de l'objet en base",
    st_dateins: "Date d'installation",
    st_datemes: 'Date de mise en service',
    st_etat: 'Etat du site.',
    st_gest: 'Identifiant du gestionnaire',
    st_majdate: "Date de la mise à jour de l'objet en base",
    st_majsrc: 'Source utilisée pour la mise à jour',
    st_nblines: 'Nombre de lignes du site.',
    st_nd_code: 'Identifiant unique contenu dans la table Noeud',
    st_nom: 'Nom du site.',
    st_prop: 'Identifiant du propriétaire',
    st_proptyp: 'Type de propriété',
    st_statut: "Phase d'avancement",
    st_typelog: 'Type logique du site',
    st_typephy: 'Type physique du site',
    st_user: 'utilisateur du site',
    statut: 'Statut audit',
    type_brassage: 'Type de brassage',
    verification_position_tiroirs: 'Vérification de la position des tiroirs',
};

export const options: Record<string, OptionsInterface | SelectInterface> = {
    arrimage_gaine: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    autre_general: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    brassage_correct: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    cheminement_bloolite: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    contrôle_acces: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    detubage_fibres_lovage_fibres: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    donnees_metier: { false: 'NON', true: 'OUI' },
    epanouissement: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    etiquette_armoire: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    etiquettes_cab_ecl: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    etiquettes_tiroir: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    fixation_cables_entree: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    fixation_eclateur: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    fixation_tiroir: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    georeferencement_pm: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    nb_fibres_transport: [
        { label: '24FO', value: '24FO' },
        { label: '36FO', value: '36FO' },
        { label: '48FO', value: '48FO' },
        { label: '72FO', value: '72FO' },
        { label: '96FO', value: '96FO' },
        { label: '144FO', value: '144FO' },
        { label: 'Autre', value: 'Autre' },
    ],
    nb_u_correct: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    obturation_fourreaux_site: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    ouverture_libre_tiroirs: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    presence_bouchons: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    presence_consigne_brassage: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    presence_gaine_annelee: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    proprete: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    st_etat: [
        { label: 'A CHANGER', value: 'HS' },
        { label: 'MAUVAIS ETAT', value: 'ME' },
        { label: 'BON ETAT', value: 'OK' },
        { label: 'NON CONCERNE', value: 'NC' },
    ],
    st_proptyp: [
        { label: 'CONSTRUCTION', value: 'CST' },
        { label: 'RACHAT', value: 'RAC' },
        { label: 'CESSION', value: 'CES' },
        { label: 'IRU', value: 'IRU' },
        { label: 'LOCATION', value: 'LOC' },
        { label: 'OCCUPATION', value: 'OCC' },
    ],
    st_statut: [
        { label: 'ETUDE PRELIMINAIRE', value: 'PRE' },
        { label: 'ETUDE DE DIAGNOSTIC', value: 'DIA' },
        { label: 'AVANT-PROJET', value: 'AVP' },
        { label: 'PROJET', value: 'PRO' },
        { label: 'PASSATION DES MARCHES DE TRAVAUX', value: 'ACT' },
        { label: 'ETUDE D EXECUTION', value: 'EXE' },
        { label: 'TRAVAUX', value: 'TVX' },
        { label: 'RECOLEMENT', value: 'REC' },
        { label: 'MAINTIENT EN CONDITIONS OPERATIONNELLES', value: 'MCO' },
    ],
    statut: [
        { label: 'Audit à réaliser', value: 'NON EFFECTUE' },
        { label: 'Audit partiellement réalisé', value: 'PARTIEL' },
        { label: 'Audit réalisé', value: 'TERMINE' },
        { label: 'Non concerné par un audit', value: 'NON CONCERNE' },
    ],
    type_brassage: [
        { label: 'W (monocouleur)', value: 'W_monocouleur' },
        { label: 'M (multicolore)', value: 'M_multicolore' },
    ],
    verification_position_tiroirs: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
};
