import * as Sentry from '@sentry/vue';
import axios from 'axios';
import type { AxiosInstance } from 'axios';

import {
    AddressApi,
    AiApi,
    AuthApi,
    ClientSettingsApi,
    Configuration,
    DebugApi,
    DomainApi,
    GroupApi,
    HealthzApi,
    LayerApi,
    MetricsApi,
    ObjectApi,
    ProjectApi,
    RoleApi,
    StyleApi,
    UserApi,
} from '@connect-field/client/sdk/generated';
import { redirectToLogin, TokenError } from '@connect-field/client/services/tokenErrors.service';

export interface ControllersInterface {
    AddressApi: AddressApi;
    AiApi: AiApi;
    AuthApi: AuthApi;
    ClientSettingsApi: ClientSettingsApi;
    DebugApi: DebugApi;
    DomainApi: DomainApi;
    GroupApi: GroupApi;
    HealthzApi: HealthzApi;
    LayerApi: LayerApi;
    MetricsApi: MetricsApi;
    ObjectApi: ObjectApi;
    ProjectApi: ProjectApi;
    RoleApi: RoleApi;
    StyleApi: StyleApi;
    UserApi: UserApi;
}

class HTTP {
    public controllers?: ControllersInterface;
    // private APIInstance?: Api<any>;
    private axios?: AxiosInstance;
    private configuration?: Configuration;

    public constructor() {}

    public client(): ControllersInterface {
        if (!this.configuration || !this.controllers) {
            throw new Error('HTTP is undefined');
        }

        return this.controllers;
    }

    public init(): void {
        const baseURL = import.meta.env.VITE_BASE_API_URL;

        if (!baseURL) {
            throw new Error('[api] Incorrect setup');
        }

        Sentry.setContext('API', {
            URI: baseURL,
        });
        console.debug('[api] server base URL', baseURL);

        this.configuration = new Configuration({ basePath: baseURL });

        const instance = axios.create();
        instance.interceptors.response.use(
            (response) => response,
            (error) => {
                console.debug('axios err', error, Object.keys(error), error.response);

                if (error.response?.status === 403) {
                    const tokenErrorTypes = [TokenError.expiredToken, TokenError.wrongToken];

                    if (tokenErrorTypes.includes(error.response?.data?.type)) {
                        redirectToLogin();
                    }
                }
                throw error;
            },
        );

        this.axios = instance;
        this.refreshControllers();
    }

    public refreshControllers(): void {
        this.controllers = {
            AddressApi: new AddressApi(this.configuration, undefined, this.axios),
            AiApi: new AiApi(this.configuration, undefined, this.axios),
            AuthApi: new AuthApi(this.configuration, undefined, this.axios),
            ClientSettingsApi: new ClientSettingsApi(this.configuration, undefined, this.axios),
            DebugApi: new DebugApi(this.configuration, undefined, this.axios),
            DomainApi: new DomainApi(this.configuration, undefined, this.axios),
            GroupApi: new GroupApi(this.configuration, undefined, this.axios),
            HealthzApi: new HealthzApi(this.configuration, undefined, this.axios),
            LayerApi: new LayerApi(this.configuration, undefined, this.axios),
            MetricsApi: new MetricsApi(this.configuration, undefined, this.axios),
            ObjectApi: new ObjectApi(this.configuration, undefined, this.axios),
            ProjectApi: new ProjectApi(this.configuration, undefined, this.axios),
            RoleApi: new RoleApi(this.configuration, undefined, this.axios),
            StyleApi: new StyleApi(this.configuration, undefined, this.axios),
            UserApi: new UserApi(this.configuration, undefined, this.axios),
        };
    }

    public setToken(token: string): void {
        if (!this.configuration) {
            throw new Error('HTTP must be initialized');
        }
        this.configuration.accessToken = token;
        this.refreshControllers();
    }
}

export default new HTTP();
