export function isStringUniqueInObject(
    testedString: string,
    referenceObject: Array<Record<string, unknown>>,
    objectFieldToCompare: string,
): boolean {
    if (referenceObject.length < 1) {
        return true;
    }

    for (let i = 0; i < referenceObject.length; i++) {
        const item: Record<string, unknown> = referenceObject[i];
        const field: unknown = item[objectFieldToCompare];

        if (typeof field === 'string' && field.toLowerCase() === testedString.toLowerCase().trim()) {
            return false;
        }
    }

    return true;
}
