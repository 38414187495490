<template>
    <el-row>
        <el-col :span="24">
            <div class="custom_label_input">
                {{ label }}
            </div>
        </el-col>
        <el-col :span="24">
            <el-form-item>
                <el-date-picker
                    v-model="model"
                    type="date"
                    :placeholder="placeholder"
                    :size="size"
                    :disabled="disabled"
                    :editable="false"
                    class="w-full"
                    @focus="getNewDate"
                />
            </el-form-item>
        </el-col>
    </el-row>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';

export default defineComponent({
    name: 'CustomDatePicker',
    props: {
        disabled: {
            type: Boolean,

            default: false,
        },
        label: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        size: {
            type: String as PropType<'large' | 'default' | 'small'>,
            default: 'default',
        },
        value: {
            type: [Date, String, null] as PropType<Date | string | null>,
            required: true,
        },
    },
    emits: ['update:value'],
    computed: {
        model: {
            get(): Date | string | null {
                return this.value;
            },
            set(value: Date | string | null) {
                this.$emit('update:value', value);
            },
        },
    },
    methods: {
        getNewDate(): void {
            if (!this.model) {
                this.model = new Date();
            }
        },
    },
});
</script>
