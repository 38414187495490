<template>
    <el-row>
        <el-col :span="24">
            <div class="custom_label_input">{{ label }}</div>
        </el-col>
        <el-col :span="24">
            <el-form-item>
                <el-input
                    v-model="model"
                    type="number"
                    :disabled="disabled"
                    :maxlength="maxlength"
                    :size="size"
                    :placeholder="placeholder"
                />
            </el-form-item>
        </el-col>
    </el-row>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'InputNumber',
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: '',
        },
        maxlength: {
            type: Number,
            default: 0,
        },
        placeholder: {
            type: String,
            default: '',
        },
        size: {
            type: String,
            values: ['large', 'default', 'small'],
            default: 'default',
        },
        value: {
            type: Number,
            default: undefined,
        },
    },
    emits: ['update:value'],
    computed: {
        model: {
            get(): number {
                return Number(this.value);
            },
            set(value: number): void {
                this.$emit('update:value', Number(value));
            },
        },
    },
});
</script>
