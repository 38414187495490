import type { OptionsInterface, SelectInterface } from '@connect-field/client/components/forms/form.types';

export const labels: Record<string, string> = {
    date_pose: 'Date de pose',
    img_cable: 'Photo Câble',
    img_environnement: 'Photo Environnement',
    img_etiquette: 'Photo Étiquette',
    tirsou_cable_capa: 'Capacité du câble',
    tirsou_cable_code: 'Nom du câble',
    tirsou_cable_phase: 'Statut du câble',
    tirsou_cbl_acc: 'Accroche des câbles',
    tirsou_cbl_gfn: 'Gaine fendue',
    tirsou_cbl_glove: 'Gestion de la love',
    tirsou_cbl_occfour: 'Occupation des fourreaux',
    tirsou_cbl_xcbl: 'Non croisement des câbles',
    tirsou_chb_cdr: 'Cadre',
    tirsou_chb_msk: 'État des masques',
    tirsou_chb_obt: 'Obturation des fourreaux',
    tirsou_chb_prop: 'Propreté Chambre (int/ext)',
    tirsou_chb_tmp: 'État tampon',
    tirsou_code: 'Nom du Point Technique',
    tirsou_com: 'Commentaire global',
    tirsou_etq_cab: 'Étiquetage Entrée / Sortie de boîte',
    tirsou_etq_pot: 'Étiquetage Sortie de fourreaux',
    tirsou_gene: 'Généraliser à tous les câbles du support',
    tirsou_ptech_code: 'Gestionnaire du Point Technique',
    tirsou_statut_audit: "Statut de l'audit",
    tirsou_type_audit: "Type de l'audit",
};

export const options: Record<string, OptionsInterface | SelectInterface> = {
    tirsou_cbl_acc: [
        { label: 'RAS', value: 'RAS' },
        { label: 'NON RESPECT REGLE', value: 'NON RESPECT REGLE' },
        { label: 'CABLE TENDU', value: 'CABLE TENDU' },
    ],
    tirsou_cbl_gfn: [
        { label: 'RAS', value: 'RAS' },
        { label: 'ABSENCE GAINE', value: 'ABSENCE GAINE' },
        { label: 'DEFAUT DE POSE', value: 'DEFAUT DE POSE' },
        { label: 'MAUVAISE COULEUR', value: 'MAUVAISE COULEUR' },
    ],
    tirsou_cbl_glove: [
        { label: 'NON CONCERNE', value: 'NON CONCERNE' },
        { label: 'RAS', value: 'RAS' },
        { label: 'ABSENCE LOVE BOITIER', value: 'ABSENCE LOVE BOITIER' },
        { label: 'ABSENCE LOVE DE SECURITE', value: 'ABSENCE LOVE DE SECURITE' },
        { label: 'DEFAUT FIXATION', value: 'DEFAUT FIXATION' },
    ],
    tirsou_cbl_occfour: [
        { label: 'RAS', value: 'RAS' },
        { label: 'MUTUALISE AVEC COLLECTE', value: 'MUTUALISE AVEC COLLECTE' },
        { label: 'MUTUALISE AVEC TRANSPORT', value: 'MUTUALISE AVEC TRANSPORT' },
        { label: 'MUTUALISE AVEC DISTRIBUTION', value: 'MUTUALISE AVEC DISTRIBUTION' },
        { label: 'UTILISATION FOURREAU RESERVE', value: 'UTILISATION FOURREAU RESERVE' },
        { label: 'NON MUTUALISATION', value: 'NON MUTUALISATION' },
        { label: 'SATURATION', value: 'SATURATION' },
    ],
    tirsou_cbl_xcbl: [
        { label: 'RAS', value: 'RAS' },
        { label: 'CROISEMENT AVEC CUIVRE', value: 'CROISEMENT AVEC CUIVRE' },
        { label: 'CROISEMENT AVEC TIERS', value: 'CROISEMENT AVEC TIERS' },
    ],
    tirsou_chb_cdr: [
        { label: 'RAS', value: 'RAS' },
        { label: 'DEFAUT SCELLEMENT', value: 'DEFAUT SCELLEMENT' },
    ],
    tirsou_chb_msk: [
        { label: 'RAS', value: 'RAS' },
        { label: 'DEFAUT PERCUTION', value: 'DEFAUT PERCUTION' },
        { label: 'REPRISE MACONNAGE', value: 'REPRISE MACONNAGE' },
    ],
    tirsou_chb_obt: [
        { label: 'NON CONCERNE', value: 'NON CONCERNE' },
        { label: 'RAS', value: 'RAS' },
        { label: 'DEFAUT DE JOINT VERS IMB', value: 'DEFAUT DE JOINT VERS IMB' },
        { label: 'ABSENCE BOUCHON', value: 'ABSENCE BOUCHON' },
    ],
    tirsou_chb_prop: [
        { label: 'RAS', value: 'RAS' },
        { label: 'DECHET A EVACUER', value: 'DECHET A EVACUER' },
        { label: 'INONDEE', value: 'INONDEE' },
        { label: 'BOUE IMPORTANTE', value: 'BOUE IMPORTANTE' },
    ],
    tirsou_chb_tmp: [
        { label: 'RAS', value: 'RAS' },
        { label: 'ABSENT', value: 'ABSENT' },
        { label: 'CASSE', value: 'CASSE' },
        { label: 'VOILE/TORDU', value: 'VOILE/TORDU' },
        { label: 'FENDU', value: 'FENDU' },
        { label: 'A DECROUTER', value: 'A DECROUTER' },
    ],
    tirsou_etq_cab: [
        { label: 'NON CONCERNE', value: 'NON CONCERNE' },
        { label: 'INCOHERENCE', value: 'INCOHERENCE' },
        { label: 'DEFAUT COULEUR', value: 'DEFAUT COULEUR' },
        { label: 'DEFAUT ETIQUETTE', value: 'DEFAUT ETIQUETTE' },
        { label: 'ABSENT', value: 'ABSENT' },
        { label: 'OK', value: 'OK' },
    ],
    tirsou_etq_pot: [
        { label: 'NON CONCERNE', value: 'NON CONCERNE' },
        { label: 'INCOHERENCE', value: 'INCOHERENCE' },
        { label: 'DEFAUT COULEUR', value: 'DEFAUT COULEUR' },
        { label: 'DEFAUT ETIQUETTE', value: 'DEFAUT ETIQUETTE' },
        { label: 'ABSENT', value: 'ABSENT' },
        { label: 'OK', value: 'OK' },
    ],
    tirsou_gene: { false: 'OUI', true: 'NON' },
    tirsou_statut_audit: [
        { label: 'NON RÉALISÉ', value: 'NON REALISE' },
        { label: 'PARTIELLEMENT', value: 'PARTIELLEMENT' },
        { label: 'TERMINÉ AVEC RÉSERVE', value: 'TERMINE AVEC RESERVE' },
        { label: 'TERMINÉ SANS RÉSERVE', value: 'TERMINE SANS RESERVE' },
    ],
    tirsou_type_audit: { false: 'RECETTE', true: 'CONTROLE' },
};
