export interface ApiErrorInterface {
    detail: string;
    instance: string;
    status: number;
    title: string;
    type: string;
}

export default class ApiError extends Error {
    public title: string;
    private detail: string;
    private instance: string;
    private status: number;
    private type: string;

    /**
     * Implementation of API Error RFC 7808
     * https://datatracker.ietf.org/doc/html/rfc7807#section-3.1
     * https://simonplend.com/send-awesome-structured-error-responses-with-express/
     * @param type {String} An URI reference that identifies the problem type.
     * @param title {String} A short, human-readable summary of the problem type.
     * @param status {Number} The HTTP status code.
     * @param detail {String} A human-readable explanation specific to this occurrence of the problem.
     * @param instance {String} An URI reference that identifies the specific occurrence of the problem.
     */
    public constructor({ type, title, status, detail, instance }: ApiErrorInterface) {
        if (!type || !title) {
            throw new Error('type and title are required to declare an APIError');
        }
        super('APIError');
        this.type = type;
        this.title = title;
        this.status = status;
        this.detail = detail;
        this.instance = instance;
    }
}
