<template>
    <div class="alert">
        <BaseDialog
            :show="showDeletionDialog"
            :title="$t('offlineProjectItem.delete')"
            :has-backdrop-escape="false"
        >
            <p>
                {{ $t('offlineProjectItem.aboutDelete') }}
                <strong> {{ projectCustomTitle }}</strong>
                {{ $t('globals.from') }} {{ $t('globals.project') }}
                <strong>{{ projectTitle }}</strong
                >.
            </p>
            <br />
            <p v-show="canSyncData">
                {{ $t('offlineProjectItem.someDataAlert') }}
            </p>
            <br />
            <p class="text-[#f56c6c] font-bold">
                {{ $t('offlineProjectItem.definitiveAction') }}
            </p>

            <template #actions>
                <BaseDialogButton
                    :label="$t('globals.cancel')"
                    button-type="default"
                    @click="showDeletionDialog = false"
                />
                <BaseDialogButton
                    :label="$t('offlineProjectItem.deleteProject')"
                    button-type="danger"
                    @click="deleteDownloadedProject"
                />
            </template>
        </BaseDialog>

        <BaseDialog
            :show="showSyncDialog"
            :title="$t('globals.synchronisation')"
            :has-backdrop-escape="false"
            :close-btn-label="$t('globals.cancel')"
            @close="closeSyncDialog"
        >
            <SyncStep :step="syncStep"></SyncStep>

            <hr class="space" />

            <div class="header">
                <h3>{{ $t('projectDownload.title') }}</h3>
            </div>

            <el-progress
                :percentage="downloadPercentage"
                :status="downloadStatus"
                :stroke-width="7"
            />
            <DownloadStep
                v-for="step in downloadSteps"
                :key="step"
                :step="step"
            >
            </DownloadStep>

            <template #actions>
                <BaseDialogButton
                    v-show="downloadPercentage < 100"
                    :label="$t('offlineProjectItem.cancelSync')"
                    button-type="danger"
                    @click="closeSyncDialog"
                />
                <BaseDialogButton
                    v-show="downloadPercentage === 100"
                    :label="$t('globals.submit')"
                    button-type="action"
                    @click="closeSyncDialog"
                />
            </template>
        </BaseDialog>
    </div>
    <li
        :class="{
            active: isActive,
            'project-item--card': isDisplayGrid,
            'project-item--list': !isDisplayGrid,
        }"
        v-bind="$attrs"
    >
        <div
            v-if="isDisplayGrid"
            class="project card"
            @click="openProject"
        >
            <div class="card__header">
                <div class="card__header__titles">
                    <p class="card__header__title">{{ projectCustomTitle }}</p>
                    <p class="card__header__subtitle">{{ projectTitle }}</p>
                </div>
                <div class="dropdown">
                    <div
                        class="card__header__action"
                        @click.stop="showDropDown = !showDropDown"
                    >
                        <FontAwesomeIcon icon="ellipsis-h" />
                    </div>
                    <div v-if="showDropDown">
                        <div class="menu list_dropdown">
                            <div class="element_dropdown">
                                <div
                                    class="card__header__action"
                                    @click.stop="syncData"
                                >
                                    <FontAwesomeIcon icon="sync" />
                                </div>
                            </div>
                            <div class="element_dropdown">
                                <div
                                    class="card__header__action"
                                    @click.stop="showDeletionDialog = true"
                                >
                                    <FontAwesomeIcon icon="trash-alt" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card__body">
                <img
                    alt="project image"
                    :src="getProjectImgSrc"
                />
                <div
                    v-if="canSyncData"
                    class="unsaved_changes"
                >
                    <p class="notification">
                        <FontAwesomeIcon icon="sync" /> {{ numberOfChanges }}
                        {{
                            $t(
                                'offlineProjectItem.changesToSync',
                                numberOfChanges,
                            )
                        }}
                    </p>
                </div>
            </div>
        </div>
        <div
            v-else
            class="project list"
            :class="{ list__big: isFeatured }"
            @click="openProject"
        >
            <div class="list__main">
                <div>
                    <img
                        alt="project image"
                        :src="getProjectImgSrc"
                    />
                </div>
                <div class="list__header">
                    <div class="list__titles">
                        <p class="list__title">{{ projectCustomTitle }}</p>
                        <p class="list__subtitle">{{ projectTitle }}</p>
                    </div>

                    <div class="list__action">
                        <div
                            v-if="showDropDown"
                            class="list__action__btn"
                            @click.stop="syncData"
                        >
                            <FontAwesomeIcon icon="sync" />
                        </div>
                        <div
                            v-if="showDropDown"
                            class="list__action__btn"
                            @click.stop="showDeletionDialog = true"
                        >
                            <FontAwesomeIcon icon="trash-alt" />
                        </div>
                        <div
                            class="list__action__btn"
                            @click.stop="showDropDown = !showDropDown"
                        >
                            <FontAwesomeIcon icon="ellipsis-h" />
                        </div>
                        <div class="list__action__btn list_action__btn__angle">
                            <FontAwesomeIcon icon="angle-right" />
                        </div>
                    </div>
                </div>
            </div>

            <div
                v-if="canSyncData"
                class="unsaved_changes"
            >
                <p class="notification">
                    <FontAwesomeIcon icon="sync" />
                    {{ numberOfChanges }}
                    {{
                        $t('offlineProjectItem.changesToSync', numberOfChanges)
                    }}
                </p>
            </div>
        </div>
    </li>
</template>

<script lang="ts">
import * as Sentry from '@sentry/vue';
import { defineComponent, type PropType } from 'vue';
import type { AxiosRequestConfig } from 'axios';
import type { ElProgress } from 'element-plus';
import { mapActions } from 'pinia';

import {
    deleteItem,
    getAllKeys,
    getItem,
    offlineStores,
    setStore,
} from '@connect-field/client/utilities/idb-utility';
import {
    type DownloadOptions,
    DownloadProjectStep,
    DownloadService,
    StepStatusEnum,
    SyncProjectStep,
} from '@connect-field/client/services/download.service';
import type {
    OfflineGeoJSONLayerConfiguration,
    OfflineMBTilesLayerConfiguration,
} from '@connect-field/client/services/layers/offlineLayers.service';
import BaseDialog from '@connect-field/client/components/ui/BaseDialog.vue';
import BaseDialogButton from '@connect-field/client/components/ui/BaseDialogButton.vue';
import DownloadStep from '@connect-field/client/components/atoms/DownloadStep.vue';
import { importImages } from '@connect-field/client/services/images';
import type { OfflineProjectInterface } from '@connect-field/client/stores/projects';
import type { ProjectConfigurationDto } from '@connect-field/client/sdk/generated';
import SyncStep from '@connect-field/client/components/atoms/SyncStep.vue';
import useObjectStore from '@connect-field/client/stores/object';
import useProjectsStore from '@connect-field/client/stores/projects';

type ElProgressPropsType = InstanceType<typeof ElProgress>['$props'];
const GRID = 'grid';

interface DataInterface {
    canSyncData: boolean;
    controller?: AbortController;
    downloadPercentage: number;
    downloadStatus: ElProgressPropsType['status'];
    downloadSteps: Array<DownloadProjectStep>;
    failedDownload: boolean;
    isActive: boolean;
    numberOfChanges: number;
    showDeletionDialog: boolean;
    showDropDown: boolean;
    showSyncDialog: boolean;
    signal?: AbortSignal;
    syncStep: SyncProjectStep;
}

export default defineComponent({
    components: { BaseDialog, BaseDialogButton, DownloadStep, SyncStep },
    props: {
        displayMode: {
            type: String as PropType<'grid' | 'list'>,
            default: 'grid',
        },
        isFeatured: {
            type: Boolean,
            default: false,
        },
        projectCustomTitle: {
            type: String,
            required: true,
        },
        projectId: {
            type: String,
            required: true,
        },
        projectImgSrc: {
            type: Object as PropType<Blob>,
            default: undefined,
        },
        projectTitle: {
            type: String,
            required: true,
        },
    },
    emits: ['offlineProjectDeleted'],
    setup() {
        return {
            objectStore: useObjectStore(),
        };
    },
    data(): DataInterface {
        return {
            canSyncData: false,
            downloadPercentage: 0,
            downloadStatus: '',
            downloadSteps: [
                new DownloadProjectStep('projectDownloadSteps.step1'),
                new DownloadProjectStep('projectDownloadSteps.step2'),
                new DownloadProjectStep('projectDownloadSteps.step3'),
                new DownloadProjectStep('projectDownloadSteps.step4'),
                new DownloadProjectStep('projectDownloadSteps.step5'),
                new DownloadProjectStep('projectDownloadSteps.step6'),
                new DownloadProjectStep('projectDownloadSteps.step7'),
            ],
            failedDownload: false,
            isActive: false,
            numberOfChanges: 0,
            showDeletionDialog: false,
            showDropDown: false,
            showSyncDialog: false,
            syncStep: new SyncProjectStep('offlineProjectSyncSteps.step0'),
        };
    },
    computed: {
        getProjectImgSrc(): string {
            // TODO: find a better way to filter the img
            if (this.projectImgSrc && this.projectImgSrc.size > 10) {
                return URL.createObjectURL(this.projectImgSrc);
            } else {
                return '/img/default_project_img.jpg';
            }
        },
        isDisplayGrid(): boolean {
            return this.displayMode === GRID;
        },
    },
    async mounted() {
        await this.updateCanSyncData();
    },
    updated() {
        this.controller = new AbortController();
        this.signal = this.controller.signal;
    },
    unmounted() {
        this.isActive = false;
    },
    methods: {
        ...mapActions(useProjectsStore, {
            setLastProjectViewed: 'setLastProjectViewed',
        }),
        closeSyncDialog(): void {
            this.controller?.abort();
            console.debug('Cancel download');
            this.showSyncDialog = false;
        },
        async deleteDownloadedProject(): Promise<void> {
            this.showDeletionDialog = false;
            try {
                const shortProjectId = this.projectId.split('-')[0];
                const allOfflineProjectIds =
                    await getAllKeys('offlineProjects');
                const offlineProjectsIdsWithSameParentId =
                    allOfflineProjectIds.filter(
                        (key: string) => key.split('-')[0] === shortProjectId,
                    );
                const hasOnlyOneOfflineProjectWithSameParentId =
                    offlineProjectsIdsWithSameParentId.length === 1;

                await Promise.all(
                    offlineStores.map((store: string) => {
                        if (
                            store === 'projectConfigurations' &&
                            hasOnlyOneOfflineProjectWithSameParentId
                        ) {
                            return deleteItem(store, shortProjectId);
                        } else {
                            const id = this.projectId;

                            return deleteItem(store, id);
                        }
                    }),
                );

                const savedDataFormsKeysOfProject =
                    await this.getSavedDataFormsKeysOfProject(this.projectId);

                await Promise.all(
                    savedDataFormsKeysOfProject.map((key: string) => {
                        return deleteItem('savedDataForms', key);
                    }),
                );

                await this.updateCanSyncData();

                this.$emit('offlineProjectDeleted');
                console.debug(
                    `[Offline] - Offline project ${this.projectId} deleted`,
                );
            } catch (error: unknown) {
                console.error(error);
            }
        },
        async fetchNewData(): Promise<void> {
            try {
                const [parentProjectId] = this.projectId.split('-');
                let options: DownloadOptions = {
                    bbox: undefined,
                    projectId: parseInt(parentProjectId, 10),
                    signal: this.signal,
                };

                if (isNaN(options.projectId) || options.projectId < 1) {
                    throw new Error('ProjectId is invalid');
                }

                const offlineProjectBbox =
                    await getItem<OfflineProjectInterface>(
                        this.projectId,
                        'offlineProjects',
                    );

                options.bbox = offlineProjectBbox.offlineBbox;
                options.zoneIds = offlineProjectBbox.zoneIds;

                const downloadService = new DownloadService(
                    options,
                    this.downloadSteps,
                    (percentage: number) =>
                        (this.downloadPercentage += percentage),
                );

                const projectConfiguration =
                    await downloadService.downloadProjectConfiguration();
                const geoJSONLayers =
                    await downloadService.downloadGeoJSONLayers(
                        projectConfiguration,
                    );
                const mbTilesLayers =
                    await downloadService.downloadPBFLayers2(
                        projectConfiguration,
                    );
                const basemap = await downloadService.downloadBaseMap();
                const searchDump = await downloadService.downloadSearchDump();
                const imagesDump = await downloadService.downloadImagesDump();

                await this.saveProject(
                    parentProjectId,
                    projectConfiguration,
                    options,
                    geoJSONLayers,
                    mbTilesLayers,
                    basemap,
                    searchDump,
                    imagesDump,
                );

                this.downloadStatus = 'success';
                this.downloadPercentage = 100;
                this.canSyncData = false;
                this.numberOfChanges = 0;
            } catch (error: unknown) {
                console.error(error);
                Sentry.captureException(error);

                this.failedDownload = true;
                this.downloadStatus = 'exception';
            }
        },
        async getSavedDataFormsKeysOfProject(
            projectId: string,
        ): Promise<Array<string>> {
            const allDataForms = await getAllKeys('savedDataForms');

            return allDataForms.filter(
                (key: string) => key.split('/')[0] === projectId,
            );
        },
        openProject(): void {
            this.setLastProjectViewed(
                this.projectId,
                this.projectTitle,
                false,
                this.projectCustomTitle,
            );
            this.isActive = true;
            this.$router.push({
                name: 'offlineProject',
                params: { projectId: this.projectId },
            });
        },
        reset(): void {
            this.downloadPercentage = 0;
            this.downloadStatus = '';
            this.syncStep = new SyncProjectStep(
                'offlineProjectSyncSteps.step0',
            );

            this.downloadSteps = [
                new DownloadProjectStep('projectDownloadSteps.step1'),
                new DownloadProjectStep('projectDownloadSteps.step2'),
                new DownloadProjectStep('projectDownloadSteps.step3'),
                new DownloadProjectStep('projectDownloadSteps.step4'),
                new DownloadProjectStep('projectDownloadSteps.step5'),
                new DownloadProjectStep('projectDownloadSteps.step6'),
                new DownloadProjectStep('projectDownloadSteps.step7'),
            ];
        },
        async saveProject(
            parentProjectId: string,
            projectConfiguration: ProjectConfigurationDto,
            options: DownloadOptions,
            geoJSONLayers: Array<OfflineGeoJSONLayerConfiguration>,
            mbTilesLayers: Array<OfflineMBTilesLayerConfiguration>,
            basemap: Uint8Array,
            searchDump: Uint8Array,
            imagesDump: Uint8Array,
        ) {
            try {
                this.downloadSteps[6].status = StepStatusEnum.RUNNING;
                await setStore(
                    'projectConfigurations',
                    projectConfiguration,
                    parentProjectId,
                );
                await setStore(
                    'projectGeojsons',
                    geoJSONLayers,
                    this.projectId,
                );
                await setStore('projectMbtiles', mbTilesLayers, this.projectId);
                await setStore('projectBasemaps', basemap, this.projectId);
                await setStore('projectSearch', searchDump, this.projectId);
                await importImages(imagesDump);

                this.downloadSteps[6].status = StepStatusEnum.SUCCESS;
            } catch (error: unknown) {
                this.downloadSteps[6].status = StepStatusEnum.FAILED;
                this.downloadSteps[6].errors.push(
                    error?.toString() ||
                        'Erreur lors de la sauvegarde du projet',
                );

                throw error;
            }
        },
        async syncData(): Promise<void> {
            this.reset();
            this.showSyncDialog = true;
            const savedDataFormsKeysOfProject =
                await this.getSavedDataFormsKeysOfProject(this.projectId);

            this.syncStep.status = StepStatusEnum.RUNNING;
            for (const key of savedDataFormsKeysOfProject) {
                const form = await getItem<{ key: unknown; signal: unknown }>(
                    key,
                    'savedDataForms',
                );
                form.key = key;
                form.signal = this.signal;

                const config: AxiosRequestConfig = {
                    onUploadProgress: (progressEvent) => {
                        this.syncStep.downloadPercentage = Math.round(
                            (progressEvent.loaded * 100) /
                                (progressEvent.total ?? 100),
                        );
                    },
                };
                try {
                    await this.objectStore.syncDataOffline({
                        config,
                        data: form,
                    });
                } catch (error: unknown) {
                    this.syncStep.downloadStatus = 'exception';
                    this.syncStep.status = StepStatusEnum.FAILED;

                    return;
                }
            }

            this.syncStep.status = 'success';
            this.syncStep.downloadStatus = 'success';
            this.syncStep.downloadPercentage = 100;

            // In order to have a better feedback, we wait on purpose 0.5 second
            await new Promise<void>((resolve) =>
                setTimeout(() => resolve(), 500),
            );

            return this.fetchNewData();
        },
        async updateCanSyncData(): Promise<void> {
            const keys = await getAllKeys('savedDataForms');
            this.numberOfChanges = keys.filter(
                (key: string) => key.split('/')[0] === this.projectId,
            ).length;
            this.canSyncData = keys.length > 0 && this.numberOfChanges > 0;
        },
    },
});
</script>

<style scoped lang="scss">
@import 'ProjectItem';

.unsaved_changes {
    font-style: italic;
    width: 100%;
}

.notification {
    color: red;
    padding: 0.2rem 0;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.menu {
    position: absolute;
    /* background-color: var(--color-primary); */
    top: 35px;
    right: 0px;
}

.list_dropdown {
    background-color: transparent;
    border-radius: 2px;
}

.element_dropdown {
    margin-top: 4px;
}

.card__actions {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    margin: 0 0 0 0.5rem;
    background: var(--color-primary);
    border-radius: 10px;
}

.space {
    margin-top: 5%;
    border-top: 3px solid #909399;
    border-radius: 5px;
    margin-bottom: 5%;
}

.header {
    width: 100%;
    margin-bottom: 3%;
    display: flex;
    align-items: center;
    font-size: 1.1rem;
    font-weight: bold;
}
</style>
