export const labels = {
    code: 'Code',
    date1: 'Champ date',
    images1: 'Photo',
    images2: 'Autre',
    input1: 'Champ texte',
    input2: 'Champ texte',
    inputText1: 'Remarques supplémentaire',
    multiSelect1: 'Selecteur multiple avec condition',
    multiSelect2: 'Selecteur multiple sans condition',
    select1: 'Selecteur',
    select2: 'Selecteur avec condition',
    select3: 'Statut audit',
    switch1: 'Switch',
};
export const options = {
    multiSelect1: [
        { label: 'CONFORME', value: 'CONFORME' },
        { label: 'Option 1 - Non conforme', value: 'Option 1' },
        { label: 'Option 2 - Non conforme', value: 'Option 2' },
        { label: 'Option 3 - Non conforme', value: 'Option 3' },
    ],
    multiSelect2: [
        { label: 'CONFORME', value: 'CONFORME' },
        { label: 'Option 1 - Non conforme', value: 'Option 1' },
        { label: 'Option 2 - Non conforme', value: 'Option 2' },
        { label: 'Option 3 - Non conforme', value: 'Option 3' },
    ],
    select1: [
        { label: 'Option 1', value: 'Option 1' },
        { label: 'Option 2', value: 'Option 2' },
        { label: 'Option 3', value: 'Option 3' },
    ],
    select2: [
        { label: 'CONFORME', value: 'CONFORME' },
        { label: 'Option 1 - Non conforme', value: 'Option 1' },
        { label: 'Option 2 - Non conforme', value: 'Option 2' },
        { label: 'Option 3 - Non conforme', value: 'Option 3' },
    ],
    select3: [
        { label: 'NON REALISE', value: 'NON REALISE' },
        { label: 'PARTIELLEMENT', value: 'PARTIELLEMENT' },
        { label: 'TERMINE', value: 'TERMINE' },
    ],
    switch1: { false: 'NON CONFORME', true: 'CONFORME' },
};
