<template>
    <FormWrapper
        v-if="!loading"
        ref="customFormRef"
        :images-model="images"
        :form-model="form"
        :mandatory-fields="mandatoryFields"
        :changes-model="changes"
        :layer-name="layerName"
        :object-id="objectId"
        :project-id="projectId"
        @update:images-model="
            (e) => {
                images = e;
            }
        "
        @update:value-missing="
            (e) => {
                valueMissing = e;
            }
        "
        @update:form-model="
            (e) => {
                form = e;
            }
        "
        @update:changes-model="
            (e) => {
                changes = e;
            }
        "
    >
        <template #main_form>
            <el-row>
                <el-col :span="24">
                    <CustomInput
                        :label="labels.techno_ant"
                        :value="form.techno_ant"
                        :maxlength="255"
                        @update:value="
                            (newValue) => saveData('techno_ant', newValue)
                        "
                    />
                    <CustomDatePicker
                        :label="labels.date_ant"
                        :value="form.date_ant"
                        @update:value="
                            (newValue) => saveData('date_ant', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.frequence_ant + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.frequence_ant"
                        :maxlength="255"
                        @update:value="
                            (newValue) => saveData('frequence_ant', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.hauteur_ant"
                        :value="form.hauteur_ant"
                        @update:value="
                            (newValue) => saveData('hauteur_ant', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.azimut_ant"
                        :value="form.azimut_ant"
                        @update:value="
                            (newValue) => saveData('azimut_ant', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.proprietaire_ant + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.proprietaire_ant"
                        :maxlength="255"
                        @update:value="
                            (newValue) => saveData('proprietaire_ant', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.comentaires_ant"
                        :value="form.comentaires_ant"
                        @update:value="
                            (newValue) => saveData('comentaires_ant', newValue)
                        "
                    />
                </el-col>
            </el-row>
        </template>
    </FormWrapper>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import {
    labels,
    options,
} from '@connect-field/client/components/forms/Dotic/Ingenierie/AntenneForm.data';
import type { FormDataInterface } from '@connect-field/client/components/forms/form.types';
import FormTemplate from '@connect-field/client/components/forms/FormTemplate.vue';

interface DataInterface extends FormDataInterface {}

export default defineComponent({
    extends: FormTemplate,
    data(): DataInterface {
        return {
            changes: false,
            form: {},
            images: {},
            labels,
            loading: true,
            mandatoryFields: ['frequence_ant', 'proprietaire_ant'],
            options,
            valueMissing: false,
        };
    },
});
</script>
