<template>
    <FormWrapper
        v-if="!loading"
        ref="customFormRef"
        :images-model="images"
        :form-model="form"
        :mandatory-fields="mandatoryFields"
        :changes-model="changes"
        :layer-name="layerName"
        :object-id="objectId"
        :project-id="projectId"
        :latitude="latitude"
        :longitude="longitude"
        @update:images-model="
            (e) => {
                images = e;
            }
        "
        @update:value-missing="
            (e) => {
                valueMissing = e;
            }
        "
        @update:form-model="
            (e) => {
                form = e;
            }
        "
        @update:changes-model="
            (e) => {
                changes = e;
            }
        "
    >
        <template #menu_tabs> </template>
        <template #main_form>
            <el-row>
                <el-col :span="24">
                    <CustomInput
                        :label="labels.st_code"
                        :value="form.st_code"
                        :disabled="true"
                    />
                    <CustomInputSelect
                        :label="labels.statut + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.statut"
                        :value="form.statut"
                        @update:value="
                            (newValue) => saveData('statut', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_sro"
                        :images="images.img_sro"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('img_sro', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.st_nd_code"
                        :value="form.st_nd_code"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('st_nd_code', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.st_codeext + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.st_codeext"
                        @update:value="
                            (newValue) => saveData('st_codeext', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.st_nom"
                        :value="form.st_nom"
                        @update:value="
                            (newValue) => saveData('st_nom', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.st_prop"
                        :value="form.st_prop"
                        :maxlength="20"
                        @update:value="
                            (newValue) => saveData('st_prop', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.st_gest"
                        :value="form.st_gest"
                        @update:value="
                            (newValue) => saveData('st_gest', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.st_user"
                        :value="form.st_user"
                        :maxlength="20"
                        @update:value="
                            (newValue) => saveData('st_user', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.st_proptyp"
                        :options="options.st_proptyp"
                        :value="form.st_proptyp"
                        @update:value="
                            (newValue) => saveData('st_proptyp', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.st_statut"
                        :options="options.st_statut"
                        :value="form.st_statut"
                        @update:value="
                            (newValue) => saveData('st_statut', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.st_etat"
                        :options="options.st_etat"
                        :value="form.st_etat"
                        @update:value="
                            (newValue) => saveData('st_etat', newValue)
                        "
                    />
                    <CustomDatePicker
                        :label="labels.st_dateins"
                        :value="form.st_dateins"
                        @update:value="
                            (newValue) => saveData('st_dateins', newValue)
                        "
                    />
                    <CustomDatePicker
                        :label="labels.st_datemes"
                        :value="form.st_datemes"
                        @update:value="
                            (newValue) => saveData('st_datemes', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.st_avct"
                        :value="form.st_avct"
                        :maxlength="1"
                        @update:value="
                            (newValue) => saveData('st_avct', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.st_typephy"
                        :value="form.st_typephy"
                        @update:value="
                            (newValue) => saveData('st_typephy', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.st_typelog"
                        :value="form.st_typelog"
                        :maxlength="10"
                        @update:value="
                            (newValue) => saveData('st_typelog', newValue)
                        "
                    />
                    <InputNumber
                        :label="labels.st_nblines"
                        :value="form.st_nblines"
                        @update:value="
                            (newValue) => saveData('st_nblines', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.st_ad_code"
                        :value="form.st_ad_code"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('st_ad_code', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.st_comment"
                        :value="form.st_comment"
                        @update:value="
                            (newValue) => saveData('st_comment', newValue)
                        "
                    />
                    <CustomDatePicker
                        :label="labels.st_creadat"
                        :value="form.st_creadat"
                        :disabled="true"
                    />
                    <CustomDatePicker
                        :label="labels.st_majdate"
                        :value="form.st_majdate"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.st_majsrc"
                        :value="form.st_majsrc"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('st_majsrc', newValue)
                        "
                    />
                    <CustomDatePicker
                        :label="labels.st_abddate"
                        :value="form.st_abddate"
                        @update:value="
                            (newValue) => saveData('st_abddate', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.st_abdsrc"
                        :value="form.st_abdsrc"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('st_abdsrc', newValue)
                        "
                    />
                </el-col>
            </el-row>
        </template>
    </FormWrapper>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import type { FormDataInterface } from '@connect-field/client/components/forms/form.types';
import FormTemplate from '@connect-field/client/components/forms/FormTemplate.vue';

import {
    labels,
    options,
} from '@connect-field/client/components/forms/DrivOptic/form_creation_pm.data';

interface DataInterface extends FormDataInterface {}

export default defineComponent({
    extends: FormTemplate,
    data(): DataInterface {
        return {
            changes: false,
            form: {},
            images: {
                img_sro: [],
            },
            labels,
            loading: true,
            mandatoryFields: ['st_codeext'],
            options,

            valueMissing: false,
        };
    },
    methods: {},
});
</script>
