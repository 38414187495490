<template>
    <FormWrapper
        v-if="!loading"
        ref="customFormRef"
        :images-model="images"
        :form-model="form"
        :mandatory-fields="mandatoryFields"
        :changes-model="changes"
        :layer-name="layerName"
        :object-id="objectId"
        :project-id="projectId"
        :latitude="latitude"
        :longitude="longitude"
        @update:images-model="
            (e) => {
                images = e;
            }
        "
        @update:value-missing="
            (e) => {
                valueMissing = e;
            }
        "
        @update:form-model="
            (e) => {
                form = e;
            }
        "
        @update:changes-model="
            (e) => {
                changes = e;
            }
        "
    >
        <template #menu_tabs> </template>
        <template #main_form>
            <el-row>
                <el-col :span="24">
                    <CustomInput
                        :label="labels.code_boite"
                        :value="form.code_boite"
                        :maxlength="255"
                        :disabled="true"
                    />
                    <CustomDatePicker
                        :label="labels.date_pose"
                        :value="form.date_pose"
                        @update:value="
                            (newValue) => saveData('date_pose', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.boite_support"
                        :value="form.boite_support"
                        :maxlength="255"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.boite_ptech"
                        :value="form.boite_ptech"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.boite_gest"
                        :value="form.boite_gest"
                        :maxlength="255"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.boite_fonc"
                        :value="form.boite_fonc"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.boite_ref"
                        :value="form.boite_ref"
                        :maxlength="255"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.boite_etat"
                        :value="form.boite_etat"
                        :disabled="true"
                    />
                    <CustomInputSelect
                        :label="labels.boite_recet"
                        :options="options.boite_recet"
                        :value="form.boite_recet"
                        @update:value="
                            (newValue) => saveData('boite_recet', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.boite_gps"
                        :options="options.boite_gps"
                        :value="form.boite_gps"
                        @update:value="
                            (newValue) => saveData('boite_gps', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_environnement"
                        :images="images.img_environnement"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_environnement', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.boite_struc"
                        :options="options.boite_struc"
                        :value="form.boite_struc"
                        @update:value="
                            (newValue) => saveData('boite_struc', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.boite_gest_pt"
                        :options="options.boite_gest_pt"
                        :value="form.boite_gest_pt"
                        @update:value="
                            (newValue) => saveData('boite_gest_pt', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.boite_fix"
                        :options="options.boite_fix"
                        :value="form.boite_fix"
                        @update:value="
                            (newValue) => saveData('boite_fix', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_fixation"
                        :images="images.img_fixation"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('img_fixation', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.boite_etq"
                        :options="options.boite_etq"
                        :value="form.boite_etq"
                        @update:value="
                            (newValue) => saveData('boite_etq', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_etiquette"
                        :images="images.img_etiquette"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('img_etiquette', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.boite_info"
                        :options="options.boite_info"
                        :value="form.boite_info"
                        @update:value="
                            (newValue) => saveData('boite_info', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.boite_etat_1"
                        :options="options.boite_etat_1"
                        :value="form.boite_etat_1"
                        @update:value="
                            (newValue) => saveData('boite_etat_1', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_etat"
                        :images="images.img_etat"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('img_etat', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.boite_cable"
                        :options="options.boite_cable"
                        :value="form.boite_cable"
                        @update:value="
                            (newValue) => saveData('boite_cable', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_entree"
                        :images="images.img_entree"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('img_entree', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.boite_remarque"
                        :value="form.boite_remarque"
                        :maxlength="255"
                        @update:value="
                            (newValue) => saveData('boite_remarque', newValue)
                        "
                    />
                    <CustomInputSwitch
                        :label="labels.tirboit_type_audit"
                        :options="options.tirboit_type_audit"
                        :value="form.tirboit_type_audit"
                        @update:value="
                            (newValue) =>
                                saveData('tirboit_type_audit', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.tirboit_statut_audit"
                        :options="options.tirboit_statut_audit"
                        :value="form.tirboit_statut_audit"
                        @update:value="
                            (newValue) =>
                                saveData('tirboit_statut_audit', newValue)
                        "
                    />
                </el-col>
            </el-row>
        </template>
    </FormWrapper>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import {
    labels,
    options,
} from '@connect-field/client/components/forms/AIC/Travaux/t_tvx_boite.data';
import type { FormDataInterface } from '@connect-field/client/components/forms/form.types';
import FormTemplate from '@connect-field/client/components/forms/FormTemplate.vue';

interface DataInterface extends FormDataInterface {}

export default defineComponent({
    extends: FormTemplate,
    data(): DataInterface {
        return {
            changes: false,
            form: {},
            images: {
                img_environnement: [],
                img_fixation: [],
                img_etiquette: [],
                img_etat: [],
                img_entree: [],
            },
            labels,
            loading: true,
            mandatoryFields: [],
            options,

            valueMissing: false,
        };
    },
    methods: {},
});
</script>
