import type { GeoJSONReverseBANDto } from '@connect-field/client/sdk/generated';
import HTTP from '@connect-field/client/utilities/http';

export async function getReverseAddress(lat: number | string, lon: number | string): Promise<GeoJSONReverseBANDto> {
    const response = await HTTP.client().AddressApi.addressControllerReverseBAN({
        lat: lat.toString(),
        lon: lon.toString(),
    });

    if (!response.data) {
        throw new Error('No address found');
    }

    return response.data;
}
