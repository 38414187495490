import type { OptionsInterface, SelectInterface } from '@connect-field/client/components/forms/form.types';

export const labels = {
    arrimage_cables: 'Arrimage des câbles dans la boÃ®te',
    bp_abandon: "Défini si l'entité est abandonnée ou non dans un SI",
    bp_abddate: "Date d'abandon de l'objet",
    bp_abdsrc: "Cause de l'abandon de l'objet",
    bp_avct: "Attribut synthétisant l'avancement.",
    bp_ca_nb: "Nombre de cassettes contenues dans l'EBP",
    bp_code: 'Code Grace',
    bp_codeext: 'Code chez un tiers ou dans une autre base de données.',
    bp_comment: 'Commentaire',
    bp_creadat: "Date de création de l'objet en base",
    bp_dateins: "Date d'installation",
    bp_datemes: 'Date de mise en service',
    bp_entrees: "Nombre d'entrées de câbles.",
    bp_etat: 'État',
    bp_etiquet: 'Etiquette sur le terrain',
    bp_gest: "Gestionnaire de l'élément",
    bp_lc_code: "Identifiant unique du local dans lequel est installé l'ebp.",
    bp_linecod: "Code d'une ligne selon la nomenclature du régulateur",
    bp_majdate: "Date de la mise à jour de l'objet en base",
    bp_majsrc: 'Source utilisée pour la mise à jour',
    bp_nb_pas: "Nombre de pas de l'organiseur de l'EBP",
    bp_oc_code: 'Référence OC de la prise terminale. Différent de bp_code.',
    bp_occp: 'Occupation.',
    bp_perirec: 'Identifiant du périmètre récolé livré à un instant t',
    bp_prop: "Propriétaire de l'élément",
    bp_proptyp: 'Type de propriété',
    bp_pt_code: 'Code point technique',
    bp_racco: "Codification Interop de l'échec du raccordement.",
    bp_ref_kit: "Référence du kit d'entrée de câble utilisé",
    bp_rf_code: 'Référence.',
    bp_statut: 'Statut de déploiement.',
    bp_typelog: "Type de l'élément",
    bp_typephy: "Type physique d'élément de branchement passif.",
    bp_user: "Utilisateur de l'élément",
    commentaire_arrimage_cables: 'Commentaire',
    commentaire_degraissage_fibres: 'Commentaire',
    commentaire_detubage_fibres: 'Commentaire',
    commentaire_diametre_ecam: 'Commentaire',
    commentaire_emplacement_boitier: 'Commentaire',
    commentaire_etancheite_bpe: 'Commentaire',
    commentaire_etiquette_bpe: 'Commentaire',
    commentaire_fixation_boitier: 'Commentaire',
    commentaire_longueurs_love: 'Commentaire',
    commentaire_plan_cablage: 'Commentaire',
    commentaire_presence_cache: 'Commentaire',
    commentaire_protection_fibres: 'Commentaire',
    commentaire_rangement_attentes: 'Commentaire',
    commentaire_rayon_courbure: 'Commentaire',
    commentaire_separation_abonnes: 'Commentaire',
    commentaire_type_cable: 'Commentaire',
    degraissage_fibres: 'Dégraissage des fibres',
    detubage_fibres: 'Détubage des fibres',
    diametre_ecam: 'Respect des diamètres des ECAM',
    emplacement_boitier: 'Emplacement du boitier',
    etancheite_bpe: 'Ã‰tanchéité de la BPE',
    etiquette_bpe: 'Etiquettes (câbles et BPE)',
    fixation_boitier: 'Fixation du boÃ®tier',
    img_arrimage_cables: 'Photos',
    img_degraissage_fibres: 'Photos',
    img_detubage_fibres: 'Photos',
    img_diametre_ecam: 'Photos',
    img_emplacement_boitier: 'Photos',
    img_etancheite_bpe: 'Photos',
    img_etiquette_bpe: 'Photos',
    img_fixation_boitier: 'Photos',
    img_longueurs_love: 'Photos',
    img_plan_cablage: 'Photos',
    img_presence_cache: 'Photos',
    img_protection_fibres: 'Photos',
    img_rangement_attentes: 'Photos',
    img_rayon_courbure: 'Photos',
    img_separation_abonnes: 'Photos',
    img_type_cable: 'Photos',
    longueurs_love: 'Respect des longueurs de love (modules)',
    plan_cablage: 'Respect du plan de câblage',
    presence_cache: 'Présence de cache sur les cassettes',
    proprete: 'Propreté',
    protection_fibres: 'Protection et passage des fibres',
    rangement_attentes: 'Rangement des attentes en fond de boite (modules)',
    rayon_courbure: 'Respect des rayons de courbure',
    separation_abonnes: 'Séparation des abonnés et passage dans des cassettes',
    statut: 'Statut audit',
    type_cable: 'Respect du type de câble G657-A2',
};

export const options: Record<string, OptionsInterface | SelectInterface> = {
    arrimage_cables: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    bp_abandon: { false: 'FAUX', true: 'VRAI' },
    bp_avct: [
        { label: 'EXISTANT', value: 'E' },
        { label: 'A CREER', value: 'C' },
        { label: 'TRAVAUX', value: 'T' },
        { label: 'EN SERVICE', value: 'S' },
        { label: 'HORS SERVICE', value: 'H' },
        { label: 'ABANDONNE', value: 'A' },
    ],
    bp_etat: [
        { label: 'A CHANGER', value: 'HS' },
        { label: 'MAUVAIS ETAT', value: 'ME' },
        { label: 'BON ETAT', value: 'OK' },
        { label: 'NON CONCERNE', value: 'NC' },
    ],
    bp_occp: [
        { label: 'VIDE', value: '0' },
        { label: 'NON VIDE EXPLOITABLE', value: '1.1' },
        { label: 'NON VIDE NON EXPLOITABLE', value: '1.2' },
        { label: 'SATUREE', value: '2' },
    ],
    bp_proptyp: [
        { label: 'CONSTRUCTION', value: 'CST' },
        { label: 'RACHAT', value: 'RAC' },
        { label: 'CESSION', value: 'CES' },
        { label: 'IRU', value: 'IRU' },
        { label: 'LOCATION', value: 'LOC' },
        { label: 'OCCUPATION', value: 'OCC' },
    ],
    bp_racco: [
        { label: 'CLIENT : CONTACT ERRONE', value: 'FCLI01' },
        {
            label: 'CLIENT : CLIENT INJOIGNABLE IMPOSSIBLE DE PRENDRE RDV',
            value: 'FCLI02',
        },
        {
            label: 'CLIENT : CLIENT N HABITE PAS A L ADRESSE INDIQUEE',
            value: 'FCLI03',
        },
        {
            label: 'CLIENT : DEMANDE ANNULATION DE LA COMMANDE PAR LE CLIENT FINAL',
            value: 'FCLI04',
        },
        { label: 'CLIENT : REFUS TRAVAUX CLIENT', value: 'FCLI05' },
        { label: 'CLIENT : REFUS GESTIONNAIRE IMMEUBLE', value: 'FCLI06' },
        {
            label: 'CLIENT : CLIENT ABSENT LORS DE L INTERVENTION',
            value: 'FCLI07',
        },
        {
            label: 'ADRESSE : CODE ADRESSE IMMEUBLE INEXISTANT DANS LE REFERENTIEL OI',
            value: 'FADR01',
        },
        {
            label: 'ADRESSE : BATIMENT MANQUANT OU INEXISTANT DANS LE REFERENTIEL OI',
            value: 'FADR02',
        },
        {
            label: 'ADRESSE : ESCALIER MANQUANT OU INEXISTANT DANS LE REFERENTIEL OI',
            value: 'FADR03',
        },
        {
            label: 'ADRESSE : ETAGE MANQUANT OU INEXISTANT DANS LE REFERENTIEL OI',
            value: 'FADR04',
        },
        { label: 'TRAITEMENT IMPOSSIBLE : PTO REQUISE', value: 'FIMP01' },
        {
            label: 'TRAITEMENT IMPOSSIBLE : PTO INEXISTANTE DANS LE REFERENTIEL OI',
            value: 'FIMP02',
        },
        {
            label: 'TRAITEMENT IMPOSSIBLE : PTO INCONNUE A L ADRESSE',
            value: 'FIMP03',
        },
        {
            label: 'TRAITEMENT IMPOSSIBLE : REF PRESTATION SRO INEXISTANTE DANS LE REFERENTIEL OI',
            value: 'FIMP04',
        },
        {
            label: 'TRAITEMENT IMPOSSIBLE : REF PRESTATION SRO ET ADRESSE INCOHERENTES',
            value: 'FIMP05',
        },
        {
            label: 'TRAITEMENT IMPOSSIBLE : TYPE DE COMMANDE IRRECEVABLE SUR CETTE REF PRESTATION SRO',
            value: 'FIMP06',
        },
        {
            label: 'TRAITEMENT IMPOSSIBLE : SRO INEXISTANT DANS LE REFERENTIEL OI',
            value: 'FIMP07',
        },
        {
            label: 'TRAITEMENT IMPOSSIBLE : SRO ET ADRESSE INCOHERENTS',
            value: 'FIMP08',
        },
        {
            label: 'TRAITEMENT IMPOSSIBLE : COMMANDE IRRECEVABLE SUR CE SRO',
            value: 'FIMP09',
        },
        {
            label: 'TRAITEMENT IMPOSSIBLE : COMMANDE IRRECEVABLE SUR CETTE ADRESSE',
            value: 'FIMP10',
        },
        {
            label: 'TRAITEMENT IMPOSSIBLE : ADRESSE INELIGIBLE TEMPORAIREMENT',
            value: 'FIMP11',
        },
        {
            label: 'TRAITEMENT IMPOSSIBLE : TYPE COMMANDE ERRONNE',
            value: 'FIMP12',
        },
        {
            label: 'TRAITEMENT IMPOSSIBLE : IDENTIFIANT COMMANDE INTERNE OC DEJA UTILISE',
            value: 'FIMP13',
        },
        {
            label: 'TRAITEMENT IMPOSSIBLE : IDENTIFIANT COMMANDE INTERNE OC INCONNUE',
            value: 'FIMP14',
        },
        {
            label: 'TRAITEMENT IMPOSSIBLE : CHAMPS OBLIGATOIRES MANQUANTS',
            value: 'FIMP15',
        },
        {
            label: 'TRAITEMENT IMPOSSIBLE : CHAMPS INCOHERENTS',
            value: 'FIMP16',
        },
        { label: 'TRAITEMENT IMPOSSIBLE : PTO INEXISTANTE', value: 'FIMP17' },
        { label: 'TRAITEMENT IMPOSSIBLE : PTO EXISTANTE', value: 'FIMP18' },
        {
            label: 'TRAITEMENT IMPOSSIBLE : PTO DEJA AFFECTEE A L OC',
            value: 'FIMP19',
        },
        { label: 'ECHEC PRODUCTION : PB OU SRO SATURE', value: 'FINT01' },
        {
            label: 'ECHEC PRODUCTION : SATURATION VIRTUELLE PB OU SRO',
            value: 'FINT02',
        },
        { label: 'ECHEC PRODUCTION : HOTLINE OI INJOIGNABLE', value: 'FINT03' },
        {
            label: 'ECHEC PRODUCTION : ABSENCE DE CONTINUITE OPTIQUE',
            value: 'FINT04',
        },
        {
            label: 'ECHEC PRODUCTION : AFFAIBLISSEMENT TROP IMPORTANT',
            value: 'FINT05',
        },
        {
            label: 'ECHEC PRODUCTION : ROUTE OPTIQUE DEJA UTILISEE',
            value: 'FINT06',
        },
        {
            label: 'ECHEC PRODUCTION : INFORMATIONS ROUTE OPTIQUE ERRONEES',
            value: 'FINT07',
        },
        {
            label: 'ECHEC PRODUCTION : POSITION BRASSAGE BAIE OPERATEUR INTROUVABLE',
            value: 'FINT08',
        },
        {
            label: 'ECHEC PRODUCTION : POSITION BRASSAGE BAIE OPERATEUR DEJA UTILISEE',
            value: 'FINT09',
        },
        {
            label: 'ECHEC PRODUCTION : AUTRE PROBLEME TECHNIQUE',
            value: 'FINT10',
        },
        {
            label: 'ECHEC PRODUCTION : INFRA TIERS INDISPONIBLE OU DELAI',
            value: 'FINT11',
        },
        { label: 'ECHEC PRODUCTION : PBO NON CONFORME', value: 'FINT12' },
        { label: 'ECHEC PRODUCTION : DEFAUT DE VERTICALITE', value: 'FINT13' },
        { label: 'AUTRE MOTIF : COMMENTAIRES LIBRES', value: 'FAUT01' },
        {
            label: 'RDV : NOMBRE MAX DE MODIFICATIONS DE RDV DEPASSE',
            value: 'FRDV01',
        },
        {
            label: 'RDV : PAS DE PLAGES DE RDV DISPONIBLES SUR CES CRENEAUX',
            value: 'FRDV02',
        },
        { label: 'RDV : PLAGE DE RDV NON RESERVEE', value: 'FRDV03' },
        { label: 'RDV : ETAT RDV NON VALIDE', value: 'FRDV04' },
        { label: 'RDV : RDV SUR PRODUIT NON FTTH', value: 'FRDV05' },
        { label: 'RDV : RDV SUR ADRESSE DIFFERENTE', value: 'FRDV06' },
    ],
    bp_statut: [
        { label: 'ETUDE PRELIMINAIRE', value: 'PRE' },
        { label: 'ETUDE DE DIAGNOSTIC', value: 'DIA' },
        { label: 'AVANT-PROJET', value: 'AVP' },
        { label: 'PROJET', value: 'PRO' },
        { label: 'PASSATION DES MARCHES DE TRAVAUX', value: 'ACT' },
        { label: 'ETUDE D EXECUTION', value: 'EXE' },
        { label: 'TRAVAUX', value: 'TVX' },
        { label: 'RECOLEMENT', value: 'REC' },
        { label: 'MAINTIENT EN CONDITIONS OPERATIONNELLES', value: 'MCO' },
    ],
    bp_typephy: [
        { label: 'BPE 6FO', value: 'B006' },
        { label: 'BPE 12FO', value: 'B012' },
        { label: 'BPE 24FO', value: 'B024' },
        { label: 'BPE 36FO', value: 'B036' },
        { label: 'BPE 48FO', value: 'B048' },
        { label: 'BPE 72FO', value: 'B072' },
        { label: 'BPE 96FO', value: 'B096' },
        { label: 'BPE 144FO', value: 'B144' },
        { label: 'BPE 288FO', value: 'B288' },
        { label: 'BPE 366FO', value: 'B336' },
        { label: 'BPE 432FO', value: 'B432' },
        { label: 'BPE 576FO', value: 'B576' },
        { label: 'BPE 720FO', value: 'B720' },
        { label: 'BPE 864FO', value: 'B864' },
        { label: 'COFFRET', value: 'COF' },
        { label: 'DTIO 1FO', value: 'DTI1' },
        { label: 'DTIO 2FO', value: 'DTI2' },
        { label: 'DTIO 4FO', value: 'DTI4' },
        { label: 'AUTRE', value: 'AUTR' },
    ],
    degraissage_fibres: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    detubage_fibres: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    diametre_ecam: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    emplacement_boitier: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    etancheite_bpe: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    etiquette_bpe: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    fixation_boitier: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    longueurs_love: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    plan_cablage: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    presence_cache: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    proprete: { false: 'NON', true: 'OUI' },
    protection_fibres: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    rangement_attentes: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    rayon_courbure: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    separation_abonnes: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
    statut: [
        { label: 'Audit à réaliser', value: 'NON EFFECTUE' },
        { label: 'Audit partiellement réalisé', value: 'PARTIEL' },
        { label: 'Audit réalisé', value: 'TERMINE' },
        { label: 'Non concerné par un audit', value: 'NON CONCERNE' },
    ],
    type_cable: [
        { label: 'Sans objet', value: null },
        { label: 'Conforme', value: 'Conforme' },
        { label: 'Non-conforme', value: 'Non-conforme' },
    ],
};
