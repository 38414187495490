<template>
    <div class="info_version">
        {{ $t('version.version') }} {{ version }} ({{ $t('version.build') }}
        {{ buildNumber }})
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

interface DataInterface {
    buildNumber: string | number;
    version: string;
}

export default defineComponent({
    data(): DataInterface {
        return {
            buildNumber: import.meta.env.VITE_BUILD_NUMBER,
            version: import.meta.env.VITE_VERSION,
        };
    },
});
</script>

<style lang="scss" scoped>
.info_version {
    color: white;
    opacity: 0.7;
    font-size: 14px;
}
</style>
