import type { OptionsInterface, SelectInterface } from '@connect-field/client/components/forms/form.types';

export const labels: Record<string, string> = {
    date_pose: 'Date de pose',
    img_cable: 'Photo Câble',
    img_environnement: 'Photo Environnement',
    img_etiquette: 'Photo Étiquette',
    tirfac_cable_acc: 'Accroche des câbles',
    tirfac_cable_capa: 'Capacite du câble',
    tirfac_cable_code: 'Nom du câble',
    tirfac_cable_phase: 'Statut du câble',
    tirfac_cbl_glove: 'Gestion de la love',
    tirfac_chb_obt: 'Obturation des fourreaux (remonté sout-façade)',
    tirfac_chb_prop: 'Accroche',
    tirfac_chb_tmp: 'État façade',
    tirfac_code: 'Nom du Point technique',
    tirfac_com: 'Commentaire global',
    tirfac_conf_ca: 'Conformité Câble - REC',
    tirfac_conv_fac: 'Convention Façade',
    tirfac_ebp_code: 'Nom du boîtier',
    tirfac_ebp_type: 'Type de boîtier',
    tirfac_etq_cable: 'Étiquetage Entrée / Sortie de boîte',
    tirfac_etq_pot: 'Étiquetage Sortie de fourreaux',
    tirfac_gene: 'Généraliser à tous les câbles du support',
    tirfac_ptech_code: 'Gestionnaire Point technique',
    tirfac_ri: 'Respect / conformité passage cuivre',
    tirfac_statut_audit: "Statut de l'audit",
    tirfac_type_audit: "Type de l'audit",
    tirfac_zone: 'Zone ABF',
};

export const options: Record<string, OptionsInterface | SelectInterface> = {
    tirfac_cable_acc: [
        { label: 'RAS', value: 'RAS' },
        { label: 'NON RESPECT REGLE', value: 'NON RESPECT REGLE' },
        { label: 'CABLE TENDU', value: 'CABLE TENDU' },
    ],
    tirfac_cbl_glove: [
        { label: 'NON CONCERNE', value: 'NON CONCERNE' },
        { label: 'LOVE INTERDITE', value: 'LOVE INTERDITE' },
    ],
    tirfac_chb_obt: [
        { label: 'NON CONCERNE', value: 'NON CONCERNE' },
        { label: 'RAS', value: 'RAS' },
        { label: 'DEFAUT DE JOINT VERS IMB', value: 'DEFAUT DE JOINT VERS IMB' },
        { label: 'ABSENCE BOUCHON', value: 'ABSENCE BOUCHON' },
    ],
    tirfac_chb_prop: [
        { label: 'RAS', value: 'RAS' },
        { label: "DEFAUT D''ACCROCHE", value: "DEFAUT D''ACCROCHE" },
    ],
    tirfac_chb_tmp: [
        { label: 'RAS', value: 'RAS' },
        { label: 'DETERIORE', value: 'DETERIORE' },
    ],
    tirfac_conf_ca: [
        { label: 'NON CONCERNE', value: 'NON CONCERNE' },
        { label: 'RAS', value: 'RAS' },
        { label: 'NON CONFORME', value: 'NON CONFORME' },
    ],
    tirfac_etq_cable: [
        { label: 'NON CONCERNE', value: 'NON CONCERNE' },
        { label: 'INCOHERENCE', value: 'INCOHERENCE' },
        { label: 'DEFAUT COULEUR', value: 'DEFAUT COULEUR' },
        { label: 'DEFAUT ETIQUETTE', value: 'DEFAUT ETIQUETTE' },
        { label: 'ABSENT', value: 'ABSENT' },
        { label: 'ETIQUETTE PROVISOIRE', value: 'ETIQUETTE PROVISOIRE' },
        { label: 'OK', value: 'OK' },
    ],
    tirfac_etq_pot: [
        { label: 'NON CONCERNE', value: 'NON CONCERNE' },
        { label: 'INCOHERENCE', value: 'INCOHERENCE' },
        { label: 'DEFAUT COULEUR', value: 'DEFAUT COULEUR' },
        { label: 'DEFAUT ETIQUETTE', value: 'DEFAUT ETIQUETTE' },
        { label: 'ABSENT', value: 'ABSENT' },
        { label: 'ETIQUETTE PROVISOIRE', value: 'ETIQUETTE PROVISOIRE' },
        { label: 'OK', value: 'OK' },
    ],
    tirfac_gene: { false: 'OUI', true: 'NON' },
    tirfac_ri: [
        { label: 'RAS', value: 'RAS' },
        { label: 'CREATION DE PARCOURS NON AUTORISE', value: 'CREATION DE PARCOURS NON AUTORISE' },
        { label: 'NON RESPECT CONVENTION', value: 'NON RESPECT CONVENTION' },
    ],
    tirfac_statut_audit: [
        { label: 'NON REALISE', value: 'NON REALISE' },
        { label: 'PARTIELLEMENT', value: 'PARTIELLEMENT' },
        { label: 'TERMINE AVEC RESERVE', value: 'TERMINE AVEC RESERVE' },
        { label: 'TERMINE SANS RESERVE', value: 'TERMINE SANS RESERVE' },
    ],
    tirfac_type_audit: { false: 'RECETTE', true: 'CONTROLE' },
};
