import * as turf from '@turf/turf';
import { LineString, MultiLineString, MultiPoint, MultiPolygon, Point, Polygon, type SimpleGeometry } from 'ol/geom';
import type BaseLayer from 'ol/layer/Base';
import type { Coordinate } from 'ol/coordinate';
import { defaults as defaultControls } from 'ol/control';
import { defaults as defaultInteractions } from 'ol/interaction';
import Feature from 'ol/Feature';
import { fromLonLat } from 'ol/proj';
import Layer from 'ol/layer/Layer';
import type Map from 'ol/Map';
import type { MapOptions } from 'ol/Map';
import type RenderFeature from 'ol/render/Feature';
import type { Size } from 'ol/size';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import View from 'ol/View';

import type { DataFormInterface, LayerDataInterface } from '@connect-field/client/stores/object';
import { detectDevice, isIOS } from '@connect-field/client/utilities/tools';
import { type SearchGeoJSONGeometry, SearchGeoJSONGeometryTypeEnum } from '@connect-field/client/sdk/generated';
import { clearPBFResultLayer } from '@connect-field/client/services/search.service';
import type { LayerProperties } from '@connect-field/client/stores/layers';
import useMapStore from '@connect-field/client/stores/map';

// TODO : Duplicated with ObjectInterface
export interface ObjectDataInterface {
    dataForm: DataFormInterface;
    Feature: Feature | RenderFeature;
    layerData: LayerDataInterface;
}
export interface ZoomOptionsInterface {
    duration?: number;
    maxZoom: number;
    padding?: Array<number>;
    size?: Size;
}

export function getMap(): Map {
    const mapStore = useMapStore();

    if (!mapStore.map) {
        throw new Error('map is undefined');
    }

    return mapStore.map as Map;
}

function getZoomOptions(): ZoomOptionsInterface {
    const map = getMap();
    const zoomOptions: ZoomOptionsInterface = {
        duration: 300,
        maxZoom: 18,
        padding: [0, 0, 200, 0],
        size: map.getSize(),
    };

    if (isIOS()) {
        zoomOptions.duration = undefined;
        zoomOptions.padding = undefined;
    }

    return zoomOptions;
}

export function resetView(): void {
    const map = getMap();
    const zoomOptions = getZoomOptions();
    zoomOptions.maxZoom = 5;

    const centerCoordinates = [267015.20990737923, 5383327.8443994075];
    const centerPoint = new Point(centerCoordinates);

    map.getView().fit(centerPoint, zoomOptions);
    map.getView().changed();
}

export function zoomOnBbox(bbox: Array<number>): void {
    const map = getMap();

    if (bbox && Array.isArray(bbox) && bbox.length === 4) {
        map.getView().fit(bbox, getZoomOptions());
    }
}

export function zoomToFeature(feature: Feature | RenderFeature): void {
    const map = getMap();

    const geom = feature.getGeometry();
    if (!geom) {
        return;
    }

    map.getView().fit(geom as SimpleGeometry, getZoomOptions());
}

export function generateGeometryFromGeoJSON(
    geom: SearchGeoJSONGeometry,
): Point | Polygon | LineString | MultiLineString | MultiPoint | MultiPolygon {
    switch (geom.type) {
        case SearchGeoJSONGeometryTypeEnum.POINT:
            return new Point(geom.coordinates);
        case SearchGeoJSONGeometryTypeEnum.POLYGON:
            return new Polygon(geom.coordinates);
        case SearchGeoJSONGeometryTypeEnum.LINE_STRING:
            return new LineString(geom.coordinates);
        case SearchGeoJSONGeometryTypeEnum.MULTI_LINE_STRING:
            return new MultiLineString(geom.coordinates);
        case SearchGeoJSONGeometryTypeEnum.MULTI_POINT:
            return new MultiPoint(geom.coordinates);
        case SearchGeoJSONGeometryTypeEnum.MULTI_POLYGON:
            return new MultiPolygon(geom.coordinates);
        case SearchGeoJSONGeometryTypeEnum.GEOMETRY_COLLECTION:
        default:
            throw new Error('Geometry type not supported');
    }
}

export function zoomToGeometry(geom: SearchGeoJSONGeometry): void {
    const map = getMap();
    const geometry = generateGeometryFromGeoJSON(geom);

    map.getView().fit(geometry, getZoomOptions());
}

export function highlightPoint(feature: Feature | RenderFeature): void {
    const geometry = feature.getGeometry();
    if (!geometry || geometry.getType() !== 'Point') {
        return;
    }

    let _feature: Feature;

    if (feature instanceof Feature) {
        _feature = feature.clone();
    } else {
        const [minX, minY, maxX, maxY] = feature.getExtent();
        if (minX !== maxX || minY !== maxY) {
            return;
        }
        _feature = new Feature({ geometry: new Point([minX, minY]) });
    }
    _feature.setId('highlight');

    const map = getMap();
    map.getLayers()
        .getArray()
        .filter((layer: BaseLayer) => layer.getProperties().highlight)
        .forEach((layer: BaseLayer) => {
            if (!(layer && layer instanceof Layer)) {
                return;
            }

            const source = layer.getSource();
            if (source instanceof VectorSource) {
                const point = source.getFeatureById('highlight');
                if (point) {
                    source.removeFeature(point);
                }
                if (_feature instanceof Feature) {
                    source.addFeature(_feature);
                }
            }

            layer.changed();
        });
}

export function setPinOnMap(cursorCoordinate: Coordinate): void {
    if (!cursorCoordinate || cursorCoordinate.length !== 2) {
        console.debug('[setPinOnMap] Not valid cursorCoordinate', cursorCoordinate);

        return;
    }
    const map = getMap();

    const [pinLayer] = map
        .getLayers()
        .getArray()
        .filter((layer: BaseLayer) => layer.getProperties().pin);

    const [highlightLayer] = map
        .getLayers()
        .getArray()
        .filter((layer: BaseLayer) => layer.getProperties().highlight);

    if (pinLayer instanceof VectorLayer) {
        const pin: Feature = pinLayer.getSource().getFeatureById('pin');

        const p = new Point(cursorCoordinate);

        if (pin) {
            // pin already exists, we have to change its coordinate
            pin.setGeometry(p);
        } else {
            // pin does not exist, we have to create it
            const feature = new Feature(p);
            feature.setId('pin');

            // For an unknown reason, addFeature does not work instantly
            // Maybe this issue : https://github.com/openlayers/openlayers/issues/13586
            setTimeout(() => {
                pinLayer.getSource().addFeature(feature);
            });
        }
    }

    if (highlightLayer instanceof VectorLayer) {
        const point = highlightLayer.getSource().getFeatureById('highlight');
        if (point) {
            highlightLayer.getSource().removeFeature(point);
        }
    }

    map.changed();
}

export function removePinOnMap(): void {
    const map = getMap();

    const pinLayer = map
        .getLayers()
        .getArray()
        .find((layer: BaseLayer) => layer.getProperties().pin);
    if (pinLayer && pinLayer instanceof Layer) {
        pinLayer.getSource().clear();
    }
}

export function removeHighlightedFeature(): void {
    const map = getMap();

    clearPBFResultLayer();

    const highlightLayer = map
        .getLayers()
        .getArray()
        .find((layer: BaseLayer) => layer.getProperties().highlight);

    if (highlightLayer && highlightLayer instanceof VectorLayer) {
        const point = highlightLayer.getSource().getFeatureById('highlight');

        if (point) {
            highlightLayer.getSource().removeFeature(point);
        }
    }
}

export function removeLayers(): void {
    const map = getMap();

    map.getLayers()
        .getArray()
        .filter((layer: BaseLayer) => {
            return !layer.getProperties().global;
        })
        .forEach((layer: BaseLayer) => map.removeLayer(layer));
}

export function generateMapOptions(): MapOptions {
    const device = detectDevice();
    // this is where we create the OpenLayers map
    const options: MapOptions = {
        controls: defaultControls({
            rotate: false,
            zoom: false,
        }),
        interactions: defaultInteractions({
            altShiftDragRotate: false,
            pinchRotate: false,
        }),

        // the map view will initially show the whole world
        view: new View({
            center: fromLonLat([2, 44]),
            constrainResolution: true,
            // Lock the map extent to avoid the user to loop the world map
            extent: [-20026376.39, -20048966.1, 20026376.39, 20048966.1],
            zoom: 5,
        }),
    };

    if (device === 'ios' || device === 'android') {
        console.debug('[map] pixelRatio to 1 reduced to avoid crashes');
        options.pixelRatio = 1;
    }

    return options;
}

export function buildObjectDataFromFeature(
    parentLayer: Layer,
    feature: RenderFeature | Feature,
): ObjectDataInterface | undefined {
    if (!parentLayer || !feature) {
        return;
    }

    const { geometry, ...values } = feature.getProperties();
    const layerProperties = parentLayer.getProperties() as LayerProperties;

    let coordinates;
    if (feature instanceof Feature) {
        coordinates = geometry.getCoordinates();
    } else {
        const flatCoordinates = feature.getFlatCoordinates();
        const points = [];
        for (let i = 0; i < flatCoordinates.length; i += 2) {
            points.push([flatCoordinates[i], flatCoordinates[i + 1]]);
        }

        const _feature = turf.multiPoint(points);
        const centroid = turf.centroid(_feature);
        coordinates = turf.getCoord(centroid);
    }

    return {
        dataForm: values,
        Feature: feature,
        layerData: {
            alias: layerProperties.alias,
            coordinate: coordinates,
            enableCreation: layerProperties.enableCreation,
            enableEdition: layerProperties.enableEdition,
            form: layerProperties.form,
            idField: layerProperties.idField,
            layerFormat: layerProperties.format,
            layerId: layerProperties.id.toString(),
            layerType: layerProperties.type,
            name: layerProperties.name,
        },
    };
}
