import type { GeoJSON, ImageResponseDto } from '@connect-field/client/sdk/generated';
import HTTP from '@connect-field/client/utilities/http';

export async function getObjectGeojsonFromLayer(objectId: string, layerName: string): Promise<GeoJSON> {
    const response = await HTTP.client().ObjectApi.objectControllerGetGeoJSON({
        layerName,
        objectId,
    });

    if (!response.data) {
        throw new Error('data undefined');
    }

    return response.data;
}

// TODO : Better typing
export async function getObjectImages({ condition, form, layerName, where }): Promise<Array<ImageResponseDto>> {
    const response = await HTTP.client().ObjectApi.objectControllerQuerySpecificImages({
        condition,
        form,
        layerName,
        where,
    });

    if (!response.data) {
        throw new Error('data undefined');
    }

    return response.data;
}

export async function getObjectImagesFromLayer(objectId: string, layerName: string): Promise<Array<ImageResponseDto>> {
    const response = await HTTP.client().ObjectApi.objectControllerGetImages({
        layerName,
        objectId,
    });

    if (!response.data) {
        throw new Error('data undefined');
    }

    return response.data;
}
