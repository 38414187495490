/* Workaround :
Les layers devrait être en "declutter:true" pour éviter de se faire découpe par le tuilage
Mais si les layers sont en "declutter:true" la propriété "icon-allow-overlap:true" n'est pas prise en compte
Issue : https://github.com/openlayers/ol-mapbox-style/issues/279
 */

import type BaseLayer from 'ol/layer/Base';
import { stylefunction } from 'ol-mapbox-style';
import type VectorLayer from 'ol/layer/Vector';
import type VectorTileLayer from 'ol/layer/VectorTile';

import {
    type LayerStyleSpecification,
    LayerStyleSpecificationTypeEnum,
    LayoutSpecificationVisibilityEnum,
    ProjectConfigurationLayerFormatEnum,
} from '@connect-field/client/sdk/generated';
import type { LayerProperties } from '@connect-field/client/stores/layers';
import type { OfflineGeoJSONLayerConfiguration } from '@connect-field/client/services/layers/offlineLayers.service';

import basemap from '@connect-field/client/assets/styles/basemap/bright.json';
import spriteData from '@connect-field/public/assets/sprites/sprites.json';

const spriteImageURL = '/assets/sprites/sprites.png';
const mapboxStyleMatrix = {
    sources: {
        composite: {
            type: 'vector',
            url: 'mapbox://',
        },
    },
    version: 8,
};

type MapboxStyleType = typeof mapboxStyleMatrix & {
    layers: Array<LayerStyleSpecification>;
};

export function getLegendAlias(_layer: BaseLayer, mapboxLayer: string): string | null {
    const properties = _layer.getProperties() as LayerProperties | OfflineGeoJSONLayerConfiguration;

    const style = properties.style;

    if (!style) {
        console.warn(`[service:style:getLegendAlias] Style not found`, {
            _layer,
            mapboxLayer,
        });

        return null;
    }
    const layerStyleSpecification = style.find(
        (layerStyleSpecification: LayerStyleSpecification) => layerStyleSpecification.id === mapboxLayer,
    );

    if (layerStyleSpecification && layerStyleSpecification.metadata && layerStyleSpecification.metadata.alias) {
        return layerStyleSpecification.metadata.alias;
    }
    console.warn(`[service:style:getLegendAlias] Alias not found`, {
        _layer,
        mapboxLayer,
    });

    return null;
}

export function getLegendStyle(_layer: BaseLayer, mapboxLayer: string): Partial<CSSStyleDeclaration> {
    const properties = _layer.getProperties() as LayerProperties | OfflineGeoJSONLayerConfiguration;

    const style = properties.style;

    if (!style) {
        console.warn(`[service:style:getLegendStyle] Style not found`, {
            _layer,
        });

        return { display: 'none' };
    }

    const layerStyleSpecification = style.find(
        (layerStyleSpecification: LayerStyleSpecification) => layerStyleSpecification.id === mapboxLayer,
    );

    if (layerStyleSpecification) {
        switch (layerStyleSpecification.type) {
            case LayerStyleSpecificationTypeEnum.SYMBOL:
                if (
                    layerStyleSpecification.layout &&
                    typeof layerStyleSpecification.layout['icon-image'] === 'string'
                ) {
                    const imageName = layerStyleSpecification.layout['icon-image'] as keyof typeof spriteData;
                    const sprite = spriteData[imageName];
                    if (sprite) {
                        return {
                            backgroundImage: `url('${spriteImageURL}')`,
                            backgroundPosition: `${sprite.x * -1}px ${sprite.y * -1}px`,
                            backgroundRepeat: 'no-repeat',
                            height: `${sprite.height}px`,
                            width: `${sprite.width}px`,
                        };
                    } else {
                        console.warn('[service:style:getLegendStyle] Sprite not found', {
                            _layer,
                            imageName,
                        });
                    }
                }
                break;
            case LayerStyleSpecificationTypeEnum.LINE:
                if (layerStyleSpecification.paint && typeof layerStyleSpecification.paint['line-color'] === 'string') {
                    return {
                        backgroundColor: layerStyleSpecification.paint['line-color'],
                        border: `2px solid #595959`,
                        height: `50px`,
                        width: `128px`,
                    };
                } else {
                    console.warn(
                        '[service:style:getLegendStyle] Paint or line-color property not found',
                        _layer,
                        layerStyleSpecification.paint,
                    );
                }
                break;
            default:
                break;
        }
    }

    return { display: 'none' };
}

interface StyleOptions {
    hiddenLayers: Array<number | string>;
}

function applyOptionsOnLayer(layerStyle: LayerStyleSpecification, options?: StyleOptions): LayerStyleSpecification {
    if (!options?.hiddenLayers || !layerStyle.layout) {
        return layerStyle;
    }

    if (options.hiddenLayers.includes(layerStyle.id)) {
        layerStyle.layout.visibility = LayoutSpecificationVisibilityEnum.NONE;

        return layerStyle;
    }

    layerStyle.layout.visibility = LayoutSpecificationVisibilityEnum.VISIBLE;

    return layerStyle;
}

function generateLayersForGeoJSON(
    styleLayers: Array<LayerStyleSpecification>,
    options?: StyleOptions,
): Array<LayerStyleSpecification> {
    return styleLayers.map((styleLayer: LayerStyleSpecification) => {
        return applyOptionsOnLayer(styleLayer, options);
    });
}

function generateLayersForPBF(
    styleLayers: Array<LayerStyleSpecification>,
    sourceLayer: string,
    options?: StyleOptions,
): Array<LayerStyleSpecification> {
    // Why source layer is mandatory for PBF : https://github.com/mapbox/mapbox-gl-js/issues/5820
    return styleLayers.map((styleLayer: LayerStyleSpecification) => {
        styleLayer['source-layer'] = sourceLayer;

        return applyOptionsOnLayer(styleLayer, options);
    });
}

function generateStyle(
    style: Array<LayerStyleSpecification>,
    layerFormat: ProjectConfigurationLayerFormatEnum,
    sourceLayer: string | undefined,
    options?: StyleOptions,
): MapboxStyleType {
    switch (layerFormat) {
        case ProjectConfigurationLayerFormatEnum.PBF:
            if (!sourceLayer) {
                throw new Error('[service:style:generateStyle] A PBF Layer must have a sourceLayer');
            }

            return {
                ...mapboxStyleMatrix,
                layers: generateLayersForPBF(style, sourceLayer, options),
            };
        case ProjectConfigurationLayerFormatEnum.GEOJSON:
        default:
            return {
                ...mapboxStyleMatrix,
                layers: generateLayersForGeoJSON(style, options),
            };
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function applyStyle(layer: VectorLayer<any> | VectorTileLayer, options?: StyleOptions): void {
    const properties = layer.getProperties() as LayerProperties | OfflineGeoJSONLayerConfiguration;

    if (!properties.style) {
        throw new Error('Style is not defined');
    }

    const hasSprite = properties.style.some((style: LayerStyleSpecification) => {
        return style?.layout?.['icon-image'];
    });

    if (hasSprite) {
        const spriteStyle = generateStyle(properties.style, properties.format, properties.sourceLayer, options);
        stylefunction(layer, spriteStyle, 'composite', undefined, spriteData, spriteImageURL);

        return;
    }

    if (!properties.style) {
        console.warn('[service:style:applyStyle] Use default style', properties);

        return;
    }

    const style = generateStyle(properties.style, properties.format, properties.sourceLayer, options);
    stylefunction(layer, style, 'composite');

    return;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function applyStyleBasemap(layer: VectorLayer<any> | VectorTileLayer): void {
    stylefunction(layer, { ...mapboxStyleMatrix, layers: basemap }, 'composite');
}
