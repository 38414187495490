<template>
    <FormWrapper
        v-if="!loading"
        ref="customFormRef"
        :images-model="images"
        :form-model="form"
        :mandatory-fields="mandatoryFields"
        :changes-model="changes"
        :layer-name="layerName"
        :object-id="objectId"
        :project-id="projectId"
        :latitude="latitude"
        :longitude="longitude"
        @update:images-model="
            (e) => {
                images = e;
            }
        "
        @update:value-missing="
            (e) => {
                valueMissing = e;
            }
        "
        @update:form-model="
            (e) => {
                form = e;
            }
        "
        @update:changes-model="
            (e) => {
                changes = e;
            }
        "
    >
        <template #menu_tabs> </template>
        <template #main_form>
            <el-row>
                <el-col :span="24">
                    <CustomInput
                        :label="labels.bp_code"
                        :value="form.bp_code"
                        :disabled="true"
                    />
                    <CustomInputSelect
                        :label="labels.statut + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.statut"
                        :value="form.statut"
                        @update:value="
                            (newValue) => saveData('statut', newValue)
                        "
                    />
                    <InputCamera
                        :label="labels.img_pbo"
                        :images="images.img_pbo"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('img_pbo', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.bp_etiquet + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.bp_etiquet"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('bp_etiquet', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.bp_codeext"
                        :value="form.bp_codeext"
                        @update:value="
                            (newValue) => saveData('bp_codeext', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.bp_pt_code"
                        :value="form.bp_pt_code"
                        :maxlength="254"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.bp_lt_code"
                        :value="form.bp_lt_code"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.bp_sf_code"
                        :value="form.bp_sf_code"
                        :maxlength="254"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.bp_prop"
                        :value="form.bp_prop"
                        @update:value="
                            (newValue) => saveData('bp_prop', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.bp_gest"
                        :value="form.bp_gest"
                        :maxlength="20"
                        @update:value="
                            (newValue) => saveData('bp_gest', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.bp_user"
                        :value="form.bp_user"
                        @update:value="
                            (newValue) => saveData('bp_user', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.bp_proptyp"
                        :options="options.bp_proptyp"
                        :value="form.bp_proptyp"
                        @update:value="
                            (newValue) => saveData('bp_proptyp', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.bp_statut"
                        :options="options.bp_statut"
                        :value="form.bp_statut"
                        @update:value="
                            (newValue) => saveData('bp_statut', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.bp_etat"
                        :options="options.bp_etat"
                        :value="form.bp_etat"
                        @update:value="
                            (newValue) => saveData('bp_etat', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.bp_occp"
                        :options="options.bp_occp"
                        :value="form.bp_occp"
                        @update:value="
                            (newValue) => saveData('bp_occp', newValue)
                        "
                    />
                    <CustomDatePicker
                        :label="labels.bp_datemes"
                        :value="form.bp_datemes"
                        @update:value="
                            (newValue) => saveData('bp_datemes', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.bp_avct"
                        :options="options.bp_avct"
                        :value="form.bp_avct"
                        @update:value="
                            (newValue) => saveData('bp_avct', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.bp_typephy"
                        :options="options.bp_typephy"
                        :value="form.bp_typephy"
                        @update:value="
                            (newValue) => saveData('bp_typephy', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.bp_typelog"
                        :options="options.bp_typelog"
                        :value="form.bp_typelog"
                        @update:value="
                            (newValue) => saveData('bp_typelog', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.bp_rf_code"
                        :value="form.bp_rf_code"
                        @update:value="
                            (newValue) => saveData('bp_rf_code', newValue)
                        "
                    />
                    <InputNumber
                        :label="labels.bp_entrees"
                        :value="form.bp_entrees"
                        @update:value="
                            (newValue) => saveData('bp_entrees', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.bp_ref_kit"
                        :value="form.bp_ref_kit"
                        :maxlength="30"
                        @update:value="
                            (newValue) => saveData('bp_ref_kit', newValue)
                        "
                    />
                    <InputNumber
                        :label="labels.bp_ca_nb"
                        :value="form.bp_ca_nb"
                        @update:value="
                            (newValue) => saveData('bp_ca_nb', newValue)
                        "
                    />
                    <InputNumber
                        :label="labels.bp_nb_pas"
                        :value="form.bp_nb_pas"
                        @update:value="
                            (newValue) => saveData('bp_nb_pas', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.bp_linecod"
                        :value="form.bp_linecod"
                        :maxlength="12"
                        @update:value="
                            (newValue) => saveData('bp_linecod', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.bp_oc_code"
                        :value="form.bp_oc_code"
                        @update:value="
                            (newValue) => saveData('bp_oc_code', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.bp_racco"
                        :options="options.bp_racco"
                        :value="form.bp_racco"
                        @update:value="
                            (newValue) => saveData('bp_racco', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.bp_comment"
                        :value="form.bp_comment"
                        @update:value="
                            (newValue) => saveData('bp_comment', newValue)
                        "
                    />
                    <CustomDatePicker
                        :label="labels.bp_creadat"
                        :value="form.bp_creadat"
                        :disabled="true"
                    />
                    <CustomDatePicker
                        :label="labels.bp_majdate"
                        :value="form.bp_majdate"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.bp_majsrc"
                        :value="form.bp_majsrc"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('bp_majsrc', newValue)
                        "
                    />
                    <CustomDatePicker
                        :label="labels.bp_abddate"
                        :value="form.bp_abddate"
                        @update:value="
                            (newValue) => saveData('bp_abddate', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.bp_abdsrc"
                        :value="form.bp_abdsrc"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('bp_abdsrc', newValue)
                        "
                    />
                </el-col>
            </el-row>
        </template>
    </FormWrapper>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import type { FormDataInterface } from '@connect-field/client/components/forms/form.types';
import FormTemplate from '@connect-field/client/components/forms/FormTemplate.vue';

import {
    labels,
    options,
} from '@connect-field/client/components/forms/DrivOptic/form_creation_pbo.data';

interface DataInterface extends FormDataInterface {}

export default defineComponent({
    extends: FormTemplate,
    data(): DataInterface {
        return {
            changes: false,
            form: {},
            images: {
                img_pbo: [],
            },
            labels,
            loading: true,
            mandatoryFields: ['bp_etiquet'],
            options,

            valueMissing: false,
        };
    },
    methods: {},
});
</script>
