<template>
    <FormWrapper
        v-if="!loading"
        ref="customFormRef"
        :images-model="images"
        :form-model="form"
        :mandatory-fields="mandatoryFields"
        :changes-model="changes"
        :layer-name="layerName"
        :object-id="objectId"
        :project-id="projectId"
        :latitude="latitude"
        :longitude="longitude"
        @update:images-model="
            (e) => {
                images = e;
            }
        "
        @update:value-missing="
            (e) => {
                valueMissing = e;
            }
        "
        @update:form-model="
            (e) => {
                form = e;
            }
        "
        @update:changes-model="
            (e) => {
                changes = e;
            }
        "
    >
        <template #menu_tabs>
            <el-row class="scroll-menu">
                <el-col :span="8">
                    <div
                        @click="selectTab('GENERAL')"
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'GENERAL' }"
                    >
                        GENERAL
                    </div>
                </el-col>

                <el-col :span="8">
                    <div
                        @click="selectTab('MECANIQUE')"
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'MECANIQUE' }"
                    >
                        MECANIQUE
                    </div>
                </el-col>

                <el-col :span="8">
                    <div
                        @click="selectTab('OPTIQUE')"
                        class="tabs"
                        :class="{ selectedTab: selectedTab === 'OPTIQUE' }"
                    >
                        OPTIQUE
                    </div>
                </el-col>
            </el-row>
        </template>
        <template #main_form>
            <el-row>
                <el-col
                    :span="24"
                    v-show="selectedTab === 'GENERAL'"
                >
                    <CustomInput
                        :label="labels.bp_code"
                        :value="form.bp_code"
                        :disabled="true"
                    />
                    <CustomInputSelect
                        :label="labels.statut + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.statut"
                        :value="form.statut"
                        @update:value="
                            (newValue) => saveData('statut', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.bp_etiquet"
                        :value="form.bp_etiquet"
                        :disabled="true"
                    />
                    <CustomInput
                        :label="labels.bp_codeext"
                        :value="form.bp_codeext"
                        :maxlength="254"
                        :disabled="true"
                    />
                    <CustomInputSelect
                        :label="labels.donnees_metier"
                        :options="options.donnees_metier"
                        :value="form.donnees_metier"
                        @update:value="
                            (newValue) => saveData('donnees_metier', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_bp_pt_code()"
                        :label="labels.bp_pt_code"
                        :value="form.bp_pt_code"
                        :maxlength="254"
                        :disabled="true"
                    />
                    <CustomInput
                        v-show="condition_bp_lt_code()"
                        :label="labels.bp_lt_code"
                        :value="form.bp_lt_code"
                        :disabled="true"
                    />
                    <CustomInput
                        v-show="condition_bp_sf_code()"
                        :label="labels.bp_sf_code"
                        :value="form.bp_sf_code"
                        :maxlength="254"
                        :disabled="true"
                    />
                    <CustomInput
                        v-show="condition_bp_prop()"
                        :label="labels.bp_prop"
                        :value="form.bp_prop"
                        :disabled="true"
                    />
                    <CustomInput
                        v-show="condition_bp_gest()"
                        :label="labels.bp_gest"
                        :value="form.bp_gest"
                        :maxlength="20"
                        :disabled="true"
                    />
                    <CustomInput
                        v-show="condition_bp_user()"
                        :label="labels.bp_user"
                        :value="form.bp_user"
                        :disabled="true"
                    />
                    <CustomInputSelect
                        v-show="condition_bp_proptyp()"
                        :label="labels.bp_proptyp"
                        :options="options.bp_proptyp"
                        :value="form.bp_proptyp"
                        :disabled="true"
                    />
                    <CustomInputSelect
                        v-show="condition_bp_statut()"
                        :label="labels.bp_statut"
                        :options="options.bp_statut"
                        :value="form.bp_statut"
                        :disabled="true"
                    />
                    <CustomInputSelect
                        v-show="condition_bp_etat()"
                        :label="labels.bp_etat"
                        :options="options.bp_etat"
                        :value="form.bp_etat"
                        :disabled="true"
                    />
                    <CustomInputSelect
                        v-show="condition_bp_occp()"
                        :label="labels.bp_occp"
                        :options="options.bp_occp"
                        :value="form.bp_occp"
                        :disabled="true"
                    />
                    <CustomDatePicker
                        v-show="condition_bp_datemes()"
                        :label="labels.bp_datemes"
                        :value="form.bp_datemes"
                        :disabled="true"
                    />
                    <CustomInputSelect
                        v-show="condition_bp_avct()"
                        :label="labels.bp_avct"
                        :options="options.bp_avct"
                        :value="form.bp_avct"
                        :disabled="true"
                    />
                    <CustomInputSelect
                        v-show="condition_bp_typephy()"
                        :label="labels.bp_typephy"
                        :options="options.bp_typephy"
                        :value="form.bp_typephy"
                        :disabled="true"
                    />
                    <CustomInputSelect
                        v-show="condition_bp_typelog()"
                        :label="labels.bp_typelog"
                        :options="options.bp_typelog"
                        :value="form.bp_typelog"
                        :disabled="true"
                    />
                    <CustomInput
                        v-show="condition_bp_rf_code()"
                        :label="labels.bp_rf_code"
                        :value="form.bp_rf_code"
                        :disabled="true"
                    />
                    <InputNumber
                        v-show="condition_bp_entrees()"
                        :label="labels.bp_entrees"
                        :value="form.bp_entrees"
                        :disabled="true"
                    />
                    <CustomInput
                        v-show="condition_bp_ref_kit()"
                        :label="labels.bp_ref_kit"
                        :value="form.bp_ref_kit"
                        :maxlength="30"
                        :disabled="true"
                    />
                    <InputNumber
                        v-show="condition_bp_ca_nb()"
                        :label="labels.bp_ca_nb"
                        :value="form.bp_ca_nb"
                        :disabled="true"
                    />
                    <InputNumber
                        v-show="condition_bp_nb_pas()"
                        :label="labels.bp_nb_pas"
                        :value="form.bp_nb_pas"
                        :disabled="true"
                    />
                    <CustomInput
                        v-show="condition_bp_linecod()"
                        :label="labels.bp_linecod"
                        :value="form.bp_linecod"
                        :maxlength="12"
                        :disabled="true"
                    />
                    <CustomInput
                        v-show="condition_bp_oc_code()"
                        :label="labels.bp_oc_code"
                        :value="form.bp_oc_code"
                        :disabled="true"
                    />
                    <CustomInputSelect
                        v-show="condition_bp_racco()"
                        :label="labels.bp_racco"
                        :options="options.bp_racco"
                        :value="form.bp_racco"
                        :disabled="true"
                    />
                    <CustomInput
                        v-show="condition_bp_comment()"
                        :label="labels.bp_comment"
                        :value="form.bp_comment"
                        :disabled="true"
                    />
                    <CustomDatePicker
                        v-show="condition_bp_creadat()"
                        :label="labels.bp_creadat"
                        :value="form.bp_creadat"
                        :disabled="true"
                    />
                    <CustomDatePicker
                        v-show="condition_bp_majdate()"
                        :label="labels.bp_majdate"
                        :value="form.bp_majdate"
                        :disabled="true"
                    />
                    <CustomInput
                        v-show="condition_bp_majsrc()"
                        :label="labels.bp_majsrc"
                        :value="form.bp_majsrc"
                        :maxlength="254"
                        :disabled="true"
                    />
                    <CustomDatePicker
                        v-show="condition_bp_abddate()"
                        :label="labels.bp_abddate"
                        :value="form.bp_abddate"
                        :disabled="true"
                    />
                    <CustomInput
                        v-show="condition_bp_abdsrc()"
                        :label="labels.bp_abdsrc"
                        :value="form.bp_abdsrc"
                        :maxlength="254"
                        :disabled="true"
                    />
                </el-col>
                <el-col
                    :span="24"
                    v-show="selectedTab === 'MECANIQUE'"
                >
                    <CustomRadioButton
                        :label="labels.etat_de_l_appui"
                        :options="options.etat_de_l_appui"
                        :value="form.etat_de_l_appui"
                        size="small"
                        @update:value="
                            (newValue) => saveData('etat_de_l_appui', newValue)
                        "
                    />

                    <div v-show="condition_img_etat_de_l_appui()">
                        <InputCamera
                            :label="labels.img_etat_de_l_appui"
                            :images="images.img_etat_de_l_appui"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage('img_etat_de_l_appui', newValue)
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="condition_commentaire_etat_de_l_appui()"
                        :label="labels.commentaire_etat_de_l_appui"
                        :value="form.commentaire_etat_de_l_appui"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_etat_de_l_appui',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.presence_de_bandeau_vert"
                        :options="options.presence_de_bandeau_vert"
                        :value="form.presence_de_bandeau_vert"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('presence_de_bandeau_vert', newValue)
                        "
                    />

                    <div v-show="condition_img_presence_de_bandeau_vert()">
                        <InputCamera
                            :label="labels.img_presence_de_bandeau_vert"
                            :images="images.img_presence_de_bandeau_vert"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage(
                                        'img_presence_de_bandeau_vert',
                                        newValue,
                                    )
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="
                            condition_commentaire_presence_de_bandeau_vert()
                        "
                        :label="labels.commentaire_presence_de_bandeau_vert"
                        :value="form.commentaire_presence_de_bandeau_vert"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_presence_de_bandeau_vert',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.hauteur_reglementaire_de_la_b"
                        :options="options.hauteur_reglementaire_de_la_b"
                        :value="form.hauteur_reglementaire_de_la_b"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'hauteur_reglementaire_de_la_b',
                                    newValue,
                                )
                        "
                    />

                    <div v-show="condition_img_hauteur_reglementaire_de_la_b()">
                        <InputCamera
                            :label="labels.img_hauteur_reglementaire_de_la_b"
                            :images="images.img_hauteur_reglementaire_de_la_b"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage(
                                        'img_hauteur_reglementaire_de_la_b',
                                        newValue,
                                    )
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="
                            condition_commentaire_hauteur_reglementaire_de_la_b()
                        "
                        :label="
                            labels.commentaire_hauteur_reglementaire_de_la_b
                        "
                        :value="form.commentaire_hauteur_reglementaire_de_la_b"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_hauteur_reglementaire_de_la_b',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.fixation_de_la_boite1"
                        :options="options.fixation_de_la_boite1"
                        :value="form.fixation_de_la_boite1"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('fixation_de_la_boite1', newValue)
                        "
                    />

                    <div v-show="condition_img_fixation_de_la_boite1()">
                        <InputCamera
                            :label="labels.img_fixation_de_la_boite1"
                            :images="images.img_fixation_de_la_boite1"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage(
                                        'img_fixation_de_la_boite1',
                                        newValue,
                                    )
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="condition_commentaire_fixation_de_la_boite1()"
                        :label="labels.commentaire_fixation_de_la_boite1"
                        :value="form.commentaire_fixation_de_la_boite1"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_fixation_de_la_boite1',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.presence_de_love_goutte_d_e"
                        :options="options.presence_de_love_goutte_d_e"
                        :value="form.presence_de_love_goutte_d_e"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'presence_de_love_goutte_d_e',
                                    newValue,
                                )
                        "
                    />

                    <div v-show="condition_img_presence_de_love_goutte_d_e()">
                        <InputCamera
                            :label="labels.img_presence_de_love_goutte_d_e"
                            :images="images.img_presence_de_love_goutte_d_e"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage(
                                        'img_presence_de_love_goutte_d_e',
                                        newValue,
                                    )
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="
                            condition_commentaire_presence_de_love_goutte_d_e()
                        "
                        :label="labels.commentaire_presence_de_love_goutte_d_e"
                        :value="form.commentaire_presence_de_love_goutte_d_e"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_presence_de_love_goutte_d_e',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.fixation_de_la_love_de_cable"
                        :options="options.fixation_de_la_love_de_cable"
                        :value="form.fixation_de_la_love_de_cable"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'fixation_de_la_love_de_cable',
                                    newValue,
                                )
                        "
                    />

                    <div v-show="condition_img_fixation_de_la_love_de_cable()">
                        <InputCamera
                            :label="labels.img_fixation_de_la_love_de_cable"
                            :images="images.img_fixation_de_la_love_de_cable"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage(
                                        'img_fixation_de_la_love_de_cable',
                                        newValue,
                                    )
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="
                            condition_commentaire_fixation_de_la_love_de_cable()
                        "
                        :label="labels.commentaire_fixation_de_la_love_de_cable"
                        :value="form.commentaire_fixation_de_la_love_de_cable"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_fixation_de_la_love_de_cable',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.fixation_du_des_cables_s_1"
                        :options="options.fixation_du_des_cables_s_1"
                        :value="form.fixation_du_des_cables_s_1"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('fixation_du_des_cables_s_1', newValue)
                        "
                    />

                    <div v-show="condition_img_fixation_du_des_cables_s_1()">
                        <InputCamera
                            :label="labels.img_fixation_du_des_cables_s_1"
                            :images="images.img_fixation_du_des_cables_s_1"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage(
                                        'img_fixation_du_des_cables_s_1',
                                        newValue,
                                    )
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="
                            condition_commentaire_fixation_du_des_cables_s_1()
                        "
                        :label="labels.commentaire_fixation_du_des_cables_s_1"
                        :value="form.commentaire_fixation_du_des_cables_s_1"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_fixation_du_des_cables_s_1',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.respect_du_rayon_de_courbure_1"
                        :options="options.respect_du_rayon_de_courbure_1"
                        :value="form.respect_du_rayon_de_courbure_1"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'respect_du_rayon_de_courbure_1',
                                    newValue,
                                )
                        "
                    />

                    <div
                        v-show="condition_img_respect_du_rayon_de_courbure_1()"
                    >
                        <InputCamera
                            :label="labels.img_respect_du_rayon_de_courbure_1"
                            :images="images.img_respect_du_rayon_de_courbure_1"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage(
                                        'img_respect_du_rayon_de_courbure_1',
                                        newValue,
                                    )
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="
                            condition_commentaire_respect_du_rayon_de_courbure_1()
                        "
                        :label="
                            labels.commentaire_respect_du_rayon_de_courbure_1
                        "
                        :value="form.commentaire_respect_du_rayon_de_courbure_1"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_respect_du_rayon_de_courbure_1',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.etiquetage_du_des_cables_s_1"
                        :options="options.etiquetage_du_des_cables_s_1"
                        :value="form.etiquetage_du_des_cables_s_1"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'etiquetage_du_des_cables_s_1',
                                    newValue,
                                )
                        "
                    />

                    <div v-show="condition_img_etiquetage_du_des_cables_s_1()">
                        <InputCamera
                            :label="labels.img_etiquetage_du_des_cables_s_1"
                            :images="images.img_etiquetage_du_des_cables_s_1"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage(
                                        'img_etiquetage_du_des_cables_s_1',
                                        newValue,
                                    )
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="
                            condition_commentaire_etiquetage_du_des_cables_s_1()
                        "
                        :label="labels.commentaire_etiquetage_du_des_cables_s_1"
                        :value="form.commentaire_etiquetage_du_des_cables_s_1"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_etiquetage_du_des_cables_s_1',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.demi_lune_aero_sout_2_50_m"
                        :options="options.demi_lune_aero_sout_2_50_m"
                        :value="form.demi_lune_aero_sout_2_50_m"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('demi_lune_aero_sout_2_50_m', newValue)
                        "
                    />

                    <div v-show="condition_img_demi_lune_aero_sout_2_50_m()">
                        <InputCamera
                            :label="labels.img_demi_lune_aero_sout_2_50_m"
                            :images="images.img_demi_lune_aero_sout_2_50_m"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage(
                                        'img_demi_lune_aero_sout_2_50_m',
                                        newValue,
                                    )
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="
                            condition_commentaire_demi_lune_aero_sout_2_50_m()
                        "
                        :label="labels.commentaire_demi_lune_aero_sout_2_50_m"
                        :value="form.commentaire_demi_lune_aero_sout_2_50_m"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_demi_lune_aero_sout_2_50_m',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.pince_d_ancrage_et_chapeau_de"
                        :options="options.pince_d_ancrage_et_chapeau_de"
                        :value="form.pince_d_ancrage_et_chapeau_de"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'pince_d_ancrage_et_chapeau_de',
                                    newValue,
                                )
                        "
                    />

                    <div v-show="condition_img_pince_d_ancrage_et_chapeau_de()">
                        <InputCamera
                            :label="labels.img_pince_d_ancrage_et_chapeau_de"
                            :images="images.img_pince_d_ancrage_et_chapeau_de"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage(
                                        'img_pince_d_ancrage_et_chapeau_de',
                                        newValue,
                                    )
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="
                            condition_commentaire_pince_d_ancrage_et_chapeau_de()
                        "
                        :label="
                            labels.commentaire_pince_d_ancrage_et_chapeau_de
                        "
                        :value="form.commentaire_pince_d_ancrage_et_chapeau_de"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_pince_d_ancrage_et_chapeau_de',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.rehausse_traverse"
                        :options="options.rehausse_traverse"
                        :value="form.rehausse_traverse"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('rehausse_traverse', newValue)
                        "
                    />

                    <div v-show="condition_img_rehausse_traverse()">
                        <InputCamera
                            :label="labels.img_rehausse_traverse"
                            :images="images.img_rehausse_traverse"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage('img_rehausse_traverse', newValue)
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="condition_commentaire_rehausse_traverse()"
                        :label="labels.commentaire_rehausse_traverse"
                        :value="form.commentaire_rehausse_traverse"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_rehausse_traverse',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.fixation_cable_s_sur_rehauss"
                        :options="options.fixation_cable_s_sur_rehauss"
                        :value="form.fixation_cable_s_sur_rehauss"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'fixation_cable_s_sur_rehauss',
                                    newValue,
                                )
                        "
                    />

                    <div v-show="condition_img_fixation_cable_s_sur_rehauss()">
                        <InputCamera
                            :label="labels.img_fixation_cable_s_sur_rehauss"
                            :images="images.img_fixation_cable_s_sur_rehauss"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage(
                                        'img_fixation_cable_s_sur_rehauss',
                                        newValue,
                                    )
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="
                            condition_commentaire_fixation_cable_s_sur_rehauss()
                        "
                        :label="labels.commentaire_fixation_cable_s_sur_rehauss"
                        :value="form.commentaire_fixation_cable_s_sur_rehauss"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_fixation_cable_s_sur_rehauss',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.etiquette_de_boite1"
                        :options="options.etiquette_de_boite1"
                        :value="form.etiquette_de_boite1"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('etiquette_de_boite1', newValue)
                        "
                    />

                    <div v-show="condition_img_etiquette_de_boite1()">
                        <InputCamera
                            :label="labels.img_etiquette_de_boite1"
                            :images="images.img_etiquette_de_boite1"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage(
                                        'img_etiquette_de_boite1',
                                        newValue,
                                    )
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="condition_commentaire_etiquette_de_boite1()"
                        :label="labels.commentaire_etiquette_de_boite1"
                        :value="form.commentaire_etiquette_de_boite1"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_etiquette_de_boite1',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.marquage_pochage1"
                        :options="options.marquage_pochage1"
                        :value="form.marquage_pochage1"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('marquage_pochage1', newValue)
                        "
                    />

                    <div v-show="condition_img_marquage_pochage1()">
                        <InputCamera
                            :label="labels.img_marquage_pochage1"
                            :images="images.img_marquage_pochage1"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage('img_marquage_pochage1', newValue)
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="condition_commentaire_marquage_pochage1()"
                        :label="labels.commentaire_marquage_pochage1"
                        :value="form.commentaire_marquage_pochage1"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_marquage_pochage1',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.arrimage_des_cables_dans_la_b1"
                        :options="options.arrimage_des_cables_dans_la_b1"
                        :value="form.arrimage_des_cables_dans_la_b1"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'arrimage_des_cables_dans_la_b1',
                                    newValue,
                                )
                        "
                    />

                    <div
                        v-show="condition_img_arrimage_des_cables_dans_la_b1()"
                    >
                        <InputCamera
                            :label="labels.img_arrimage_des_cables_dans_la_b1"
                            :images="images.img_arrimage_des_cables_dans_la_b1"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage(
                                        'img_arrimage_des_cables_dans_la_b1',
                                        newValue,
                                    )
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="
                            condition_commentaire_arrimage_des_cables_dans_la_b1()
                        "
                        :label="
                            labels.commentaire_arrimage_des_cables_dans_la_b1
                        "
                        :value="form.commentaire_arrimage_des_cables_dans_la_b1"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_arrimage_des_cables_dans_la_b1',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.longueur_denudee_du_des_cab"
                        :options="options.longueur_denudee_du_des_cab"
                        :value="form.longueur_denudee_du_des_cab"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'longueur_denudee_du_des_cab',
                                    newValue,
                                )
                        "
                    />

                    <div v-show="condition_img_longueur_denudee_du_des_cab()">
                        <InputCamera
                            :label="labels.img_longueur_denudee_du_des_cab"
                            :images="images.img_longueur_denudee_du_des_cab"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage(
                                        'img_longueur_denudee_du_des_cab',
                                        newValue,
                                    )
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="
                            condition_commentaire_longueur_denudee_du_des_cab()
                        "
                        :label="labels.commentaire_longueur_denudee_du_des_cab"
                        :value="form.commentaire_longueur_denudee_du_des_cab"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_longueur_denudee_du_des_cab',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.etancheite_de_la_boite1"
                        :options="options.etancheite_de_la_boite1"
                        :value="form.etancheite_de_la_boite1"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('etancheite_de_la_boite1', newValue)
                        "
                    />

                    <div v-show="condition_img_etancheite_de_la_boite1()">
                        <InputCamera
                            :label="labels.img_etancheite_de_la_boite1"
                            :images="images.img_etancheite_de_la_boite1"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage(
                                        'img_etancheite_de_la_boite1',
                                        newValue,
                                    )
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="condition_commentaire_etancheite_de_la_boite1()"
                        :label="labels.commentaire_etancheite_de_la_boite1"
                        :value="form.commentaire_etancheite_de_la_boite1"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_etancheite_de_la_boite1',
                                    newValue,
                                )
                        "
                    />
                </el-col>
                <el-col
                    :span="24"
                    v-show="selectedTab === 'OPTIQUE'"
                >
                    <CustomRadioButton
                        :label="labels.degraissage_fibres"
                        :options="options.degraissage_fibres"
                        :value="form.degraissage_fibres"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('degraissage_fibres', newValue)
                        "
                    />

                    <div v-show="condition_img_degraissage_fibres()">
                        <InputCamera
                            :label="labels.img_degraissage_fibres"
                            :images="images.img_degraissage_fibres"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage(
                                        'img_degraissage_fibres',
                                        newValue,
                                    )
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="condition_commentaire_degraissage_fibres()"
                        :label="labels.commentaire_degraissage_fibres"
                        :value="form.commentaire_degraissage_fibres"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_degraissage_fibres',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.lovage_dans_les_cassettes_et_1"
                        :options="options.lovage_dans_les_cassettes_et_1"
                        :value="form.lovage_dans_les_cassettes_et_1"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'lovage_dans_les_cassettes_et_1',
                                    newValue,
                                )
                        "
                    />

                    <div
                        v-show="condition_img_lovage_dans_les_cassettes_et_1()"
                    >
                        <InputCamera
                            :label="labels.img_lovage_dans_les_cassettes_et_1"
                            :images="images.img_lovage_dans_les_cassettes_et_1"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage(
                                        'img_lovage_dans_les_cassettes_et_1',
                                        newValue,
                                    )
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="
                            condition_commentaire_lovage_dans_les_cassettes_et_1()
                        "
                        :label="
                            labels.commentaire_lovage_dans_les_cassettes_et_1
                        "
                        :value="form.commentaire_lovage_dans_les_cassettes_et_1"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_lovage_dans_les_cassettes_et_1',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.soudures_des_fibres1"
                        :options="options.soudures_des_fibres1"
                        :value="form.soudures_des_fibres1"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('soudures_des_fibres1', newValue)
                        "
                    />

                    <div v-show="condition_img_soudures_des_fibres1()">
                        <InputCamera
                            :label="labels.img_soudures_des_fibres1"
                            :images="images.img_soudures_des_fibres1"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage(
                                        'img_soudures_des_fibres1',
                                        newValue,
                                    )
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="condition_commentaire_soudures_des_fibres1()"
                        :label="labels.commentaire_soudures_des_fibres1"
                        :value="form.commentaire_soudures_des_fibres1"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_soudures_des_fibres1',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.identification_des_micromodul"
                        :options="options.identification_des_micromodul"
                        :value="form.identification_des_micromodul"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'identification_des_micromodul',
                                    newValue,
                                )
                        "
                    />

                    <div v-show="condition_img_identification_des_micromodul()">
                        <InputCamera
                            :label="labels.img_identification_des_micromodul"
                            :images="images.img_identification_des_micromodul"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage(
                                        'img_identification_des_micromodul',
                                        newValue,
                                    )
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="
                            condition_commentaire_identification_des_micromodul()
                        "
                        :label="
                            labels.commentaire_identification_des_micromodul
                        "
                        :value="form.commentaire_identification_des_micromodul"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_identification_des_micromodul',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.reperage_des_cassettes_n_1"
                        :options="options.reperage_des_cassettes_n_1"
                        :value="form.reperage_des_cassettes_n_1"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('reperage_des_cassettes_n_1', newValue)
                        "
                    />

                    <div v-show="condition_img_reperage_des_cassettes_n_1()">
                        <InputCamera
                            :label="labels.img_reperage_des_cassettes_n_1"
                            :images="images.img_reperage_des_cassettes_n_1"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage(
                                        'img_reperage_des_cassettes_n_1',
                                        newValue,
                                    )
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="
                            condition_commentaire_reperage_des_cassettes_n_1()
                        "
                        :label="labels.commentaire_reperage_des_cassettes_n_1"
                        :value="form.commentaire_reperage_des_cassettes_n_1"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_reperage_des_cassettes_n_1',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.cache_present_sur_les_cassett"
                        :options="options.cache_present_sur_les_cassett"
                        :value="form.cache_present_sur_les_cassett"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'cache_present_sur_les_cassett',
                                    newValue,
                                )
                        "
                    />

                    <div v-show="condition_img_cache_present_sur_les_cassett()">
                        <InputCamera
                            :label="labels.img_cache_present_sur_les_cassett"
                            :images="images.img_cache_present_sur_les_cassett"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage(
                                        'img_cache_present_sur_les_cassett',
                                        newValue,
                                    )
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="
                            condition_commentaire_cache_present_sur_les_cassett()
                        "
                        :label="
                            labels.commentaire_cache_present_sur_les_cassett
                        "
                        :value="form.commentaire_cache_present_sur_les_cassett"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_cache_present_sur_les_cassett',
                                    newValue,
                                )
                        "
                    />
                    <CustomInputSelect
                        :label="labels.autres_observations"
                        :options="options.autres_observations"
                        :value="form.autres_observations"
                        @update:value="
                            (newValue) =>
                                saveData('autres_observations', newValue)
                        "
                    />

                    <div v-show="condition_img_autres_observations()">
                        <InputCamera
                            :label="labels.img_autres_observations"
                            :images="images.img_autres_observations"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage(
                                        'img_autres_observations',
                                        newValue,
                                    )
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="condition_commentaire_autres_observations()"
                        :label="labels.commentaire_autres_observations"
                        :value="form.commentaire_autres_observations"
                        :maxlength="254"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_autres_observations',
                                    newValue,
                                )
                        "
                    />
                </el-col>
            </el-row>
        </template>
    </FormWrapper>
</template>

<script lang="ts">
/* eslint-disable */
import { defineComponent } from 'vue';

import type { FormDataInterface } from '@connect-field/client/components/forms/form.types';
import FormTemplate from '@connect-field/client/components/forms/FormTemplate.vue';

import {
    labels,
    options,
} from '@connect-field/client/components/forms/DrivOptic/form_audit_pep_appui_enedis_drivoptic.data';

interface DataInterface extends FormDataInterface {}

export default defineComponent({
    extends: FormTemplate,
    data(): DataInterface {
        return {
            changes: false,
            form: {},
            images: {
                img_etat_de_l_appui: [],
                img_presence_de_bandeau_vert: [],
                img_hauteur_reglementaire_de_la_b: [],
                img_fixation_de_la_boite1: [],
                img_presence_de_love_goutte_d_e: [],
                img_fixation_de_la_love_de_cable: [],
                img_fixation_du_des_cables_s_1: [],
                img_respect_du_rayon_de_courbure_1: [],
                img_etiquetage_du_des_cables_s_1: [],
                img_demi_lune_aero_sout_2_50_m: [],
                img_pince_d_ancrage_et_chapeau_de: [],
                img_rehausse_traverse: [],
                img_fixation_cable_s_sur_rehauss: [],
                img_etiquette_de_boite1: [],
                img_marquage_pochage1: [],
                img_arrimage_des_cables_dans_la_b1: [],
                img_longueur_denudee_du_des_cab: [],
                img_etancheite_de_la_boite1: [],
                img_degraissage_fibres: [],
                img_lovage_dans_les_cassettes_et_1: [],
                img_soudures_des_fibres1: [],
                img_identification_des_micromodul: [],
                img_reperage_des_cassettes_n_1: [],
                img_cache_present_sur_les_cassett: [],
                img_autres_observations: [],
            },
            labels,
            loading: true,
            mandatoryFields: ['statut'],
            options,
            selectedTab: 'GENERAL',
            valueMissing: false,
        };
    },
    methods: {
        condition_bp_pt_code(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_bp_lt_code(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_bp_sf_code(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_bp_prop(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_bp_gest(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_bp_user(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_bp_proptyp(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_bp_statut(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_bp_etat(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_bp_occp(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_bp_datemes(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_bp_avct(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_bp_typephy(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_bp_typelog(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_bp_rf_code(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_bp_entrees(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_bp_ref_kit(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_bp_ca_nb(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_bp_nb_pas(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_bp_linecod(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_bp_oc_code(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_bp_racco(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_bp_comment(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_bp_creadat(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_bp_majdate(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_bp_majsrc(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_bp_abddate(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_bp_abdsrc(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === 'OUI';
        },
        condition_img_etat_de_l_appui(): boolean {
            const { etat_de_l_appui } = this.form;
            return etat_de_l_appui?.startsWith('NOK');
        },
        condition_commentaire_etat_de_l_appui(): boolean {
            const { etat_de_l_appui } = this.form;
            return etat_de_l_appui?.startsWith('NOK');
        },
        condition_img_presence_de_bandeau_vert(): boolean {
            const { presence_de_bandeau_vert } = this.form;
            return presence_de_bandeau_vert?.startsWith('NOK');
        },
        condition_commentaire_presence_de_bandeau_vert(): boolean {
            const { presence_de_bandeau_vert } = this.form;
            return presence_de_bandeau_vert?.startsWith('NOK');
        },
        condition_img_hauteur_reglementaire_de_la_b(): boolean {
            const { hauteur_reglementaire_de_la_b } = this.form;
            return hauteur_reglementaire_de_la_b?.startsWith('NOK');
        },
        condition_commentaire_hauteur_reglementaire_de_la_b(): boolean {
            const { hauteur_reglementaire_de_la_b } = this.form;
            return hauteur_reglementaire_de_la_b?.startsWith('NOK');
        },
        condition_img_fixation_de_la_boite1(): boolean {
            const { fixation_de_la_boite1 } = this.form;
            return fixation_de_la_boite1?.startsWith('NOK');
        },
        condition_commentaire_fixation_de_la_boite1(): boolean {
            const { fixation_de_la_boite1 } = this.form;
            return fixation_de_la_boite1?.startsWith('NOK');
        },
        condition_img_presence_de_love_goutte_d_e(): boolean {
            const { presence_de_love_goutte_d_e } = this.form;
            return presence_de_love_goutte_d_e?.startsWith('NOK');
        },
        condition_commentaire_presence_de_love_goutte_d_e(): boolean {
            const { presence_de_love_goutte_d_e } = this.form;
            return presence_de_love_goutte_d_e?.startsWith('NOK');
        },
        condition_img_fixation_de_la_love_de_cable(): boolean {
            const { fixation_de_la_love_de_cable } = this.form;
            return fixation_de_la_love_de_cable?.startsWith('NOK');
        },
        condition_commentaire_fixation_de_la_love_de_cable(): boolean {
            const { fixation_de_la_love_de_cable } = this.form;
            return fixation_de_la_love_de_cable?.startsWith('NOK');
        },
        condition_img_fixation_du_des_cables_s_1(): boolean {
            const { fixation_du_des_cables_s_1 } = this.form;
            return fixation_du_des_cables_s_1?.startsWith('NOK');
        },
        condition_commentaire_fixation_du_des_cables_s_1(): boolean {
            const { fixation_du_des_cables_s_1 } = this.form;
            return fixation_du_des_cables_s_1?.startsWith('NOK');
        },
        condition_img_respect_du_rayon_de_courbure_1(): boolean {
            const { respect_du_rayon_de_courbure_1 } = this.form;
            return respect_du_rayon_de_courbure_1?.startsWith('NOK');
        },
        condition_commentaire_respect_du_rayon_de_courbure_1(): boolean {
            const { respect_du_rayon_de_courbure_1 } = this.form;
            return respect_du_rayon_de_courbure_1?.startsWith('NOK');
        },
        condition_img_etiquetage_du_des_cables_s_1(): boolean {
            const { etiquetage_du_des_cables_s_1 } = this.form;
            return etiquetage_du_des_cables_s_1?.startsWith('NOK');
        },
        condition_commentaire_etiquetage_du_des_cables_s_1(): boolean {
            const { etiquetage_du_des_cables_s_1 } = this.form;
            return etiquetage_du_des_cables_s_1?.startsWith('NOK');
        },
        condition_img_demi_lune_aero_sout_2_50_m(): boolean {
            const { demi_lune_aero_sout_2_50_m } = this.form;
            return demi_lune_aero_sout_2_50_m?.startsWith('NOK');
        },
        condition_commentaire_demi_lune_aero_sout_2_50_m(): boolean {
            const { demi_lune_aero_sout_2_50_m } = this.form;
            return demi_lune_aero_sout_2_50_m?.startsWith('NOK');
        },
        condition_img_pince_d_ancrage_et_chapeau_de(): boolean {
            const { pince_d_ancrage_et_chapeau_de } = this.form;
            return pince_d_ancrage_et_chapeau_de?.startsWith('NOK');
        },
        condition_commentaire_pince_d_ancrage_et_chapeau_de(): boolean {
            const { pince_d_ancrage_et_chapeau_de } = this.form;
            return pince_d_ancrage_et_chapeau_de?.startsWith('NOK');
        },
        condition_img_rehausse_traverse(): boolean {
            const { rehausse_traverse } = this.form;
            return rehausse_traverse?.startsWith('NOK');
        },
        condition_commentaire_rehausse_traverse(): boolean {
            const { rehausse_traverse } = this.form;
            return rehausse_traverse?.startsWith('NOK');
        },
        condition_img_fixation_cable_s_sur_rehauss(): boolean {
            const { fixation_cable_s_sur_rehauss } = this.form;
            return fixation_cable_s_sur_rehauss?.startsWith('NOK');
        },
        condition_commentaire_fixation_cable_s_sur_rehauss(): boolean {
            const { fixation_cable_s_sur_rehauss } = this.form;
            return fixation_cable_s_sur_rehauss?.startsWith('NOK');
        },
        condition_img_etiquette_de_boite1(): boolean {
            const { etiquette_de_boite1 } = this.form;
            return etiquette_de_boite1?.startsWith('NOK');
        },
        condition_commentaire_etiquette_de_boite1(): boolean {
            const { etiquette_de_boite1 } = this.form;
            return etiquette_de_boite1?.startsWith('NOK');
        },
        condition_img_marquage_pochage1(): boolean {
            const { marquage_pochage1 } = this.form;
            return marquage_pochage1?.startsWith('NOK');
        },
        condition_commentaire_marquage_pochage1(): boolean {
            const { marquage_pochage1 } = this.form;
            return marquage_pochage1?.startsWith('NOK');
        },
        condition_img_arrimage_des_cables_dans_la_b1(): boolean {
            const { arrimage_des_cables_dans_la_b1 } = this.form;
            return arrimage_des_cables_dans_la_b1?.startsWith('NOK');
        },
        condition_commentaire_arrimage_des_cables_dans_la_b1(): boolean {
            const { arrimage_des_cables_dans_la_b1 } = this.form;
            return arrimage_des_cables_dans_la_b1?.startsWith('NOK');
        },
        condition_img_longueur_denudee_du_des_cab(): boolean {
            const { longueur_denudee_du_des_cab } = this.form;
            return longueur_denudee_du_des_cab?.startsWith('NOK');
        },
        condition_commentaire_longueur_denudee_du_des_cab(): boolean {
            const { longueur_denudee_du_des_cab } = this.form;
            return longueur_denudee_du_des_cab?.startsWith('NOK');
        },
        condition_img_etancheite_de_la_boite1(): boolean {
            const { etancheite_de_la_boite1 } = this.form;
            return etancheite_de_la_boite1?.startsWith('NOK');
        },
        condition_commentaire_etancheite_de_la_boite1(): boolean {
            const { etancheite_de_la_boite1 } = this.form;
            return etancheite_de_la_boite1?.startsWith('NOK');
        },
        condition_img_degraissage_fibres(): boolean {
            const { degraissage_fibres } = this.form;
            return degraissage_fibres?.startsWith('NOK');
        },
        condition_commentaire_degraissage_fibres(): boolean {
            const { degraissage_fibres } = this.form;
            return degraissage_fibres?.startsWith('NOK');
        },
        condition_img_lovage_dans_les_cassettes_et_1(): boolean {
            const { lovage_dans_les_cassettes_et_1 } = this.form;
            return lovage_dans_les_cassettes_et_1?.startsWith('NOK');
        },
        condition_commentaire_lovage_dans_les_cassettes_et_1(): boolean {
            const { lovage_dans_les_cassettes_et_1 } = this.form;
            return lovage_dans_les_cassettes_et_1?.startsWith('NOK');
        },
        condition_img_soudures_des_fibres1(): boolean {
            const { soudures_des_fibres1 } = this.form;
            return soudures_des_fibres1?.startsWith('NOK');
        },
        condition_commentaire_soudures_des_fibres1(): boolean {
            const { soudures_des_fibres1 } = this.form;
            return soudures_des_fibres1?.startsWith('NOK');
        },
        condition_img_identification_des_micromodul(): boolean {
            const { identification_des_micromodul } = this.form;
            return identification_des_micromodul?.startsWith('NOK');
        },
        condition_commentaire_identification_des_micromodul(): boolean {
            const { identification_des_micromodul } = this.form;
            return identification_des_micromodul?.startsWith('NOK');
        },
        condition_img_reperage_des_cassettes_n_1(): boolean {
            const { reperage_des_cassettes_n_1 } = this.form;
            return reperage_des_cassettes_n_1?.startsWith('NOK');
        },
        condition_commentaire_reperage_des_cassettes_n_1(): boolean {
            const { reperage_des_cassettes_n_1 } = this.form;
            return reperage_des_cassettes_n_1?.startsWith('NOK');
        },
        condition_img_cache_present_sur_les_cassett(): boolean {
            const { cache_present_sur_les_cassett } = this.form;
            return cache_present_sur_les_cassett?.startsWith('NOK');
        },
        condition_commentaire_cache_present_sur_les_cassett(): boolean {
            const { cache_present_sur_les_cassett } = this.form;
            return cache_present_sur_les_cassett?.startsWith('NOK');
        },
        condition_img_autres_observations(): boolean {
            const { autres_observations } = this.form;
            return autres_observations === 'OUI';
        },
        condition_commentaire_autres_observations(): boolean {
            const { autres_observations } = this.form;
            return autres_observations === 'OUI';
        },
    },
});
</script>
