import { type Geometry, Point } from 'ol/geom';
import { Stroke, Style } from 'ol/style';
import type BaseLayer from 'ol/layer/Base';
import Feature from 'ol/Feature';
import type RenderFeature from 'ol/render/Feature';
import VectorLayer from 'ol/layer/Vector';
import type VectorSource from 'ol/source/Vector';
import VectorTileLayer from 'ol/layer/VectorTile';

import { getMap, highlightPoint } from '@connect-field/client/services/map.service';
import { SearchGeoJSONLayerFormatEnum } from '@connect-field/client/sdk/generated';
import type { SearchResult } from '@connect-field/client/pages/home/search/SearchView.vue';

export function getVectorLayerFromSearchResult(searchResult: SearchResult): VectorLayer<VectorSource> {
    const map = getMap();

    if (searchResult.layerFormat !== SearchGeoJSONLayerFormatEnum.GEOJSON) {
        throw new Error('Wrong layer format');
    }

    const layer = map
        .getLayers()
        .getArray()
        .find(
            (layer: BaseLayer) =>
                layer instanceof VectorLayer &&
                layer?.getProperties()?.id?.toString() === searchResult.layerId.toString(),
        );

    if (!layer) {
        throw new Error('Parent layer not found');
    }

    return layer as VectorLayer<VectorSource>;
}

export function getVectorTileLayerFromSearchResult(searchResult: SearchResult): VectorTileLayer {
    const map = getMap();

    if (searchResult.layerFormat !== SearchGeoJSONLayerFormatEnum.PBF) {
        throw new Error('Wrong layer format');
    }

    const layer = map
        .getLayers()
        .getArray()
        .find(
            (layer: BaseLayer) =>
                layer instanceof VectorTileLayer &&
                layer?.getProperties()?.id?.toString() === searchResult.layerId.toString(),
        );

    if (!layer) {
        throw new Error('Parent layer not found');
    }

    return layer as VectorTileLayer;
}

export function getSearchResultPbfLayer(): VectorSource | undefined {
    const map = getMap();

    const searchResultPbfLayer = map
        .getLayers()
        .getArray()
        .find(
            (layer: BaseLayer) => layer instanceof VectorLayer && layer.getProperties().name === 'searchResultPbfLayer',
        );

    if (searchResultPbfLayer && searchResultPbfLayer instanceof VectorLayer) {
        return searchResultPbfLayer.getSource();
    }

    return undefined;
}

export function clearPBFResultLayer(): void {
    const pbfLayerSource = getSearchResultPbfLayer();
    pbfLayerSource?.clear();
}

export function highlightFeatureFromGeometry(geometry?: Geometry): void {
    if (!geometry) {
        return;
    }

    clearPBFResultLayer();

    if (geometry instanceof Point) {
        highlightPoint(new Feature({ geometry }));

        return;
    }

    const feature = new Feature({
        geometry: geometry,
    });

    const style = new Style({
        stroke: new Stroke({
            color: 'rgba(255, 0, 0, 1)',
            width: 5,
        }),
    });
    feature.setStyle(style);

    const pbfLayerSource = getSearchResultPbfLayer();
    pbfLayerSource?.addFeature(feature);
}

export function getFeatureByIdFromSearchResult(searchResult: SearchResult): Feature {
    const layer = getVectorLayerFromSearchResult(searchResult);
    const layerSource = layer?.getSource();

    if (!layer || !layerSource) {
        throw new Error(`Cannot retrieve the parent layer`);
    }

    const feature = layerSource?.getFeatureById(searchResult.feature.id);

    if (!feature) {
        // This case should never happen but if it does, we'll know
        throw new Error(
            `Should return a feature but returned ${feature} for feature ${JSON.stringify(searchResult.feature)}`,
        );
    }

    return feature;
}

export function getRenderFeatureByIdFromSearchResult(searchResult: SearchResult): RenderFeature {
    const map = getMap();
    const layer = getVectorTileLayerFromSearchResult(searchResult);
    const layerSource = layer?.getSource();

    if (!layer || !layerSource) {
        throw new Error(`Cannot retrieve the parent layer`);
    }

    const renderFeature = layerSource
        .getFeaturesInExtent(map.getView().calculateExtent())
        .find((feature) => feature.getId() === searchResult.feature.id);

    if (!renderFeature) {
        // This case should never happen but if it does, we'll know
        throw new Error(
            `Should return a feature but returned ${renderFeature} for feature ${JSON.stringify(searchResult.feature)}`,
        );
    }

    return renderFeature as RenderFeature;
}
