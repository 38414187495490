<template>
    <SSTWrapper
        v-if="!loading"
        ref="customFormRef"
        :images-model="images"
        :form-model="form"
        :mandatory-fields="mandatoryFields"
        :changes-model="changes"
        :layer-name="layerName"
        :object-id="objectId"
        :project-id="projectId"
        :latitude="latitude"
        :longitude="longitude"
        @update:images-model="
            (e) => {
                images = e;
            }
        "
        @update:value-missing="
            (e) => {
                valueMissing = e;
            }
        "
        @update:form-model="
            (e) => {
                form = e;
            }
        "
        @update:changes-model="
            (e) => {
                changes = e;
            }
        "
    >
        <template #menu_tabs />
        <template #main_form>
            <el-row>
                <el-col :span="24">
                    <CustomInput
                        :label="labels.obj_rattach"
                        :value="form.sid"
                        :disabled="true"
                        :maxlength="255"
                    />
                    <div v-if="form.aggregatedProperties">
                        <div
                            v-for="(
                                reserve, index
                            ) in form.aggregatedProperties"
                            :key="reserve.sid"
                        >
                            <h3>{{ reserve.question }}</h3>
                            <h4>{{ reserve.detail }}</h4>
                            <DisplayImage
                                :condition="reserve.question"
                                form="AIC_LEVEE_RESERVE_BOITE"
                                :layer-name="getTableName()"
                                :where="form.sid"
                            />
                            <CustomInputSwitch
                                :options="options.levee_reserve"
                                :label="labels.levee_reserve"
                                :value="reserve.levee_reserve"
                                @update:value="
                                    (newValue) =>
                                        saveAggregatedData(
                                            index,
                                            'levee_reserve',
                                            newValue,
                                        )
                                "
                            />
                            <InputCamera
                                :images="images[reserve.sid].photos"
                                :label="labels.photos"
                                :layer-name="layerName"
                                @update:images="
                                    (newValue) =>
                                        saveImage(
                                            reserve.sid,
                                            'photos',
                                            newValue,
                                        )
                                "
                            />
                            <CustomInputTextarea
                                :label="labels.commentaire_sst"
                                :value="reserve.commentaire_sst"
                                @update:value="
                                    (newValue) =>
                                        saveAggregatedData(
                                            index,
                                            'commentaire_sst',
                                            newValue,
                                        )
                                "
                            />
                        </div>
                    </div>
                </el-col>
            </el-row>
        </template>
    </SSTWrapper>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import {
    deepCopy,
    resetScrollValue,
} from '@connect-field/client/utilities/tools';
import {
    labels,
    options,
} from '@connect-field/client/components/forms/AIC/SST/Levee_Reserve.data';
import DisplayImage from '@connect-field/client/components/ui/DisplayImage.vue';
import type { FormDataInterface } from '@connect-field/client/components/forms/form.types';
import FormTemplate from '@connect-field/client/components/forms/FormTemplate.vue';
import SSTWrapper from '@connect-field/client/pages/home/form/SSTWrapper.vue';

interface FormInterface {
    aggregatedProperties: Array<any>;
}

// TODO : fix type
interface DataInterface extends FormDataInterface {
    form: Record<string, any> & FormInterface;
    images: Record<string, { photos: Array<unknown> }>;
}

export default defineComponent({
    components: {
        DisplayImage,
        SSTWrapper,
    },
    extends: FormTemplate,
    data(): DataInterface {
        return {
            changes: false,
            form: {},
            images: {},
            labels,
            loading: true,
            mandatoryFields: [],
            options,
            valueMissing: false,
        };
    },
    mounted() {
        if (this.objectId) {
            this.form = deepCopy(this.dataForm);
        } else {
            this.form = { aggregatedProperties: [] };
        }
        this.images = this.form.aggregatedProperties.reduce(
            (acc: Record<string, { photos: Array<unknown> }>, reserve) => {
                acc[reserve.sid] = { photos: [] };

                return acc;
            },
            {},
        );
    },
    methods: {
        getTableName(): string {
            if (!this.layerName) {
                throw new Error('LayerName undefined');
            }

            const split = this.layerName.split('.');
            if (split.length !== 2) {
                throw new Error('Unknown layer');
            }

            return `${split[0]}.t_qr_boite`;
        },
        saveAggregatedData(
            index: number,
            key: string,
            newValue: unknown,
        ): void {
            this.form.aggregatedProperties[index][key] = newValue;
            this.changes = true;
        },
        /**
         * TODO : check usages
         */
        // eslint-disable-next-line vue/no-unused-properties
        saveData(key: string, newValue: unknown): void {
            this.form[key] = newValue;
            this.changes = true;
            this.saveTemporaryDataForm(key, newValue);
        },
        saveImage(index: number, key: string, newImage: unknown): void {
            this.images[index][key] = newImage;
            this.changes = true;
            this.saveTemporaryDataForm(key, newImage);
        },
        saveTemporaryDataForm(key: string, newValue: unknown): void {
            if (this.$refs.customFormRef) {
                this.$refs.customFormRef.saveTemporaryDataForm(key, newValue);
            }
        },
        /**
         * TODO : check usages
         */
        // eslint-disable-next-line vue/no-unused-properties
        selectTab(selection: string) {
            resetScrollValue('main.form-main');
            this.selectedTab = selection;
        },
    },
});
</script>

<style scoped lang="scss">
h3,
h4 {
    color: white;
}

h3 {
    padding: 10px 0;
}
</style>
