import HTTP from '@connect-field/client/utilities/http';

export async function getHealthzApi(): Promise<{ status: string }> {
    const response = await HTTP.client().HealthzApi.healthControllerCheckApi();

    if (!response.data) {
        throw new Error('data undefined');
    }

    return response.data;
}
