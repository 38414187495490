<template>
    <div class="h-full w-full auth">
        <div class="container__backdrop" />
        <main class="main">
            <!-- Error dialog -->
            <BaseDialog
                :show="!!error"
                :title="$t('globals.error')"
                @close="handleClose"
            >
                <p>{{ error }}</p>
            </BaseDialog>

            <div class="logo_container">
                <img
                    class="logo"
                    src="../../assets/img/Logo_ConnectControl.png"
                />
            </div>

            <el-form
                ref="authform"
                :model="authForm"
                :rules="rules"
            >
                <div
                    v-if="getSignUpMode"
                    class="form__item"
                >
                    <p class="form__label">Nom</p>
                    <el-form-item prop="name">
                        <el-input
                            v-model.trim="authForm.name"
                            class="form__input"
                        />
                    </el-form-item>
                </div>
                <div class="form__item">
                    <p class="form__label">{{ $t(labelUsername) }}</p>
                    <el-form-item prop="email">
                        <el-input
                            v-model.trim="authForm.email"
                            :type="typeUsername"
                        />
                    </el-form-item>
                </div>
                <div class="form__item">
                    <p class="form__label">{{ $t('login.password') }}</p>
                    <el-form-item prop="password">
                        <el-input
                            v-model.trim="authForm.password"
                            show-password
                            type="password"
                        />
                    </el-form-item>
                </div>
                <div class="form__item">
                    <el-form-item prop="ldap">
                        <el-checkbox
                            v-model="isLDAP"
                            :label="$t('login.LDAP')"
                            size="large"
                        />
                    </el-form-item>
                </div>
                <div class="btn-container">
                    <div class="btn__wrapper">
                        <el-button
                            v-if="!redirecting"
                            class="btn"
                            type="primary"
                            :loading="isLoading"
                            :disabled="isFormInvalid"
                            @click.stop="submitForm"
                        >
                            {{ $t(getActionBtn) }}
                        </el-button>
                        <el-button
                            v-if="redirecting"
                            class="custom_loading"
                            type="primary"
                            :loading="true"
                        >
                            {{ redirectText }}
                        </el-button>
                    </div>
                </div>
            </el-form>
            <div class="build_version">
                <api-status />
                <build-version />
            </div>
        </main>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import ApiError from '@connect-field/client/services/apiError.service';
import ApiStatus from '@connect-field/client/components/atoms/ApiStatus.vue';
import BaseDialog from '@connect-field/client/components/ui/BaseDialog.vue';
import BuildVersion from '@connect-field/client/components/atoms/BuildVersion.vue';
import useAuthStore from '@connect-field/client/stores/auth';

const LOGIN = 'login';
const SIGN_UP = 'sign up';

interface DataInterface {
    authForm: {
        email: string;
        name: string;
        password: string;
    };
    error?: string;
    isLDAP: boolean;
    isLoading: boolean;
    mode: string;
    redirecting: boolean;
    redirectText: string;
    rules: Record<
        string,
        Array<{
            max?: number;
            message: string;
            min?: number;
            required?: boolean;
            trigger: string;
        }>
    >;
}

export default defineComponent({
    components: {
        ApiStatus,
        BaseDialog,
        BuildVersion,
    },
    setup() {
        return {
            authStore: useAuthStore(),
        };
    },
    data(): DataInterface {
        return {
            authForm: {
                email: '',
                name: '',
                password: '',
            },
            error: '',
            isLDAP:
                window?.location?.hostname
                    ?.toLocaleLowerCase()
                    ?.endsWith('altitudeinfra.fr') ?? false,
            isLoading: false,
            mode: LOGIN,
            redirecting: false,
            redirectText: 'Connexion réussie, chargement en cours...',
            rules: {
                email: [
                    {
                        message: 'Veuillez entrer une adresse mail',
                        required: true,
                        trigger: 'blur',
                    },
                ],
                name: [
                    {
                        message: 'Veuillez entrer un nom',
                        required: true,
                        trigger: 'blur',
                    },
                    {
                        message: 'Le nom doit avoir au minimum 3 caractères',
                        min: 3,
                        trigger: 'blur',
                    },
                ],
                password: [
                    {
                        message: 'Veuillez entrer un mot de passe',
                        required: true,
                        trigger: 'blur',
                    },
                    {
                        message:
                            'Le mot de passe doit avoir au minimum 6 caractères',
                        min: 6,
                        trigger: 'blur',
                    },
                ],
            },
        };
    },
    computed: {
        getActionBtn(): string {
            return this.mode === LOGIN ? 'login.login' : 'login.register';
        },
        getSignUpMode(): boolean {
            return this.mode === SIGN_UP;
        },
        isFormInvalid(): boolean {
            if (this.mode === SIGN_UP) {
                return (
                    this.authForm.name === '' ||
                    this.authForm.name.length < 3 ||
                    this.authForm.email === '' ||
                    !this.authForm.email.includes('@') ||
                    this.authForm.password === '' ||
                    this.authForm.password.length < 6
                );
            } else if (!this.isLDAP) {
                return (
                    this.authForm.email === '' ||
                    !this.authForm.email.includes('@') ||
                    this.authForm.password === '' ||
                    this.authForm.password.length < 6
                );
            } else {
                return (
                    this.authForm.email === '' || this.authForm.password === ''
                );
            }
        },
        labelUsername(): string {
            return this.isLDAP ? 'login.username' : 'login.email';
        },
        typeUsername(): string {
            return this.isLDAP ? 'text' : 'email';
        },
    },
    methods: {
        handleClose(): void {
            this.error = undefined;
        },
        async submitForm(): Promise<void> {
            this.isLoading = true;
            const payload = {
                email: this.authForm.email,
                name: this.authForm.name,
                password: this.authForm.password,
            };
            try {
                if (this.mode === LOGIN) {
                    if (this.isLDAP) {
                        await this.authStore.loginLDAP(payload);
                    } else {
                        await this.authStore.login(payload);
                    }
                } else {
                    await this.authStore.signup(payload);
                }

                this.isLoading = false;
                this.redirecting = true;

                const redirectUrl =
                    '/' + (this.$route.query.redirect || 'home/projects');

                this.$router.replace(redirectUrl);
            } catch (error: unknown) {
                if (error instanceof ApiError) {
                    this.error = error.title;
                } else if (error instanceof Error) {
                    this.error = error.toString();
                }
                this.isLoading = false;
            }
        },
    },
});
</script>

<style scoped lang="scss">
@import 'LoginPage.scss';
</style>
