/*
Store to save menu state
 */

import { acceptHMRUpdate, defineStore } from 'pinia';

export interface StateInterface {
    displayMenu: boolean;
}

const useMenuStore = defineStore('menu', {
    state: (): StateInterface => {
        return {
            displayMenu: true,
        };
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useMenuStore, import.meta.hot));
}

export default useMenuStore;
