import '@connect-field/client/plugins/pwa';

import '@connect-field/client/plugins/tailwind.scss';
import '@connect-field/client/plugins/element-variables.scss';
import 'element-plus/dist/index.css';
import 'ol/ol.css';

import * as Sentry from '@sentry/vue';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { Integrations } from '@sentry/tracing';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

import App from '@connect-field/client/App.vue';

import HTTP from '@connect-field/client/utilities/http';
import i18n from '@connect-field/client/plugins/i18n';
import router from '@connect-field/client/router';
import setupElementPlus from '@connect-field/client/plugins/elementPlus';
import setupFontAwesome from '@connect-field/client/plugins/fontAwesome';

HTTP.init();
const app = createApp(App);
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

console.debug('[main] environment variables', import.meta.env);

if (!import.meta.env.DEV) {
    window.onbeforeunload = function (): string {
        return "Êtes vous sûr de vouloir quitter l'application ?  Vous allez perdre toutes les données non sauvegardées.";
    };
}

if (import.meta.env.VITE_SENTRY_DSN) {
    console.debug('[Sentry] enabled');
    Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        environment: import.meta.env.MODE,
        integrations: [
            new Integrations.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracePropagationTargets: [
                    'app.connect-field.com',
                    'app.staging.connect-field.app',
                    'mobilite-terrain.altitudeinfra.fr',
                    'preprod-mobilite-terrain.altitudeinfra.fr',
                ],
            }),
        ],
        tracesSampleRate: 0.1,
    });
}

setupFontAwesome(app);
setupElementPlus(app);

app.use(i18n);
app.use(pinia);
app.use(router);
app.mount('#app');

defineCustomElements(window);
