<template>
    <OpenNavigationApp
        :coordinate="navigationCoordinates"
        :form-name="formName"
        :show="softwareChoiceDialogVisible"
        @close-dialog="closeDialog"
    />

    <main v-bind="$attrs">
        <div class="main__header">
            <h3 class="title">
                {{ $t('objectList.objectSelection') }}
            </h3>
            <button
                class="btn--back custom_link"
                @click="projectLink"
            >
                <span>{{ $t('objectList.layersList') }}</span>
            </button>
        </div>
        <div class="address">
            <p v-if="addressLoading">
                {{ $t('objectList.loadingAddress') }}
            </p>
            <p v-if="!addressLoading && address">
                <FontAwesomeIcon icon="map-marker-alt" />
                {{ address }}
            </p>
        </div>
        <div
            v-for="object in sortedObjects"
            :key="object"
            class="collapse"
        >
            <div
                :class="{ visible: true }"
                class="collapse__wrapper"
                @click="() => selectFeature(object)"
            >
                <div
                    :class="{ visible: true }"
                    class="collapse__header"
                >
                    <div class="collapse__btn">
                        <span class="collapse__id">
                            {{ getObjectId(object) }}
                        </span>
                        <span class="ml-2 bold text-red-500">{{
                            getEditionStatus(object)
                        }}</span>
                    </div>
                    <div class="icons">
                        <el-button
                            v-if="isLayerEditable(object.layerData)"
                            :disabled="loadingData"
                            :icon="Edit"
                            :loading="loadingData"
                            type="primary"
                            @click="() => openForm(object)"
                        />
                        <el-button
                            :icon="MapLocation"
                            type="primary"
                            @click="() => openNavigation(object)"
                        />
                        <el-button
                            v-show="hasLayerInSelectedProject(object)"
                            :icon="View"
                            type="primary"
                            @click="() => openObjectSummary(object)"
                        />
                    </div>
                </div>
                <div class="collapse__btn">
                    <span class="collapse__title">
                        {{ object.layerData.alias }}
                    </span>
                </div>
            </div>
        </div>
        <div v-if="!sortedObjects.length">
            <div v-if="!loading">
                <div
                    :class="{ visible: true }"
                    class="collapse__header"
                >
                    <div class="collapse__btn">
                        <span class="collapse__title">{{
                            $t('objectList.noObject')
                        }}</span>
                    </div>
                </div>
            </div>
            <div v-else>
                <div
                    :class="{ visible: true }"
                    class="collapse__header"
                >
                    <div class="collapse__btn">
                        <span class="collapse__title">{{
                            $t('objectList.loadingObjects')
                        }}</span>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script lang="ts">
import { defineComponent, toRaw } from 'vue';
import { Edit, MapLocation, View } from '@element-plus/icons-vue';
import { getCenter } from 'ol/extent';
import { mapState } from 'pinia';

import {
    highlightPoint,
    type ObjectDataInterface,
    zoomToFeature,
} from '@connect-field/client/services/map.service';
import useObjectStore, {
    type LayerDataInterface,
} from '@connect-field/client/stores/object';
import type { FormDataInterface } from '@connect-field/client/components/forms/form.types';
import { getItem } from '@connect-field/client/utilities/idb-utility';
import { getLayerFromObjectData } from '@connect-field/client/services/form.service';
import OpenNavigationApp from '@connect-field/client/components/dialogs/OpenNavigationApp.vue';
import { ProjectConfigurationLayerFormatEnum } from '@connect-field/client/sdk/generated';
import { redirectToForm } from '@connect-field/client/services/formRouter.service';
import useCoordinateStore from '@connect-field/client/stores/coordinate';
import useLayersStore from '@connect-field/client/stores/layers';
import useMenuStore from '@connect-field/client/stores/menu';
import useNavigationStore from '@connect-field/client/stores/navigation';
import usePanelStore from '@connect-field/client/stores/panel';
import useProjectsStore from '@connect-field/client/stores/projects';

interface DataInterface {
    editedObjects: Record<string, boolean>;
    formName?: string;
    loading: boolean;
    loadingData: boolean;
    navigationCoordinates?: unknown;
    softwareChoiceDialogVisible: boolean;
}

export default defineComponent({
    name: 'ObjectList',
    components: {
        OpenNavigationApp,
    },
    props: {
        projectId: {
            type: String,
            required: true,
        },
    },
    setup() {
        return {
            coordinateStore: useCoordinateStore(),
            Edit,
            layersStore: useLayersStore(),
            MapLocation,
            menuStore: useMenuStore(),
            objectStore: useObjectStore(),
            panelStore: usePanelStore(),
            View,
        };
    },
    data(): DataInterface {
        return {
            editedObjects: {},
            formName: undefined,
            loading: true,
            loadingData: false,
            navigationCoordinates: undefined,
            softwareChoiceDialogVisible: false,
        };
    },
    computed: {
        ...mapState(useCoordinateStore, {
            address: 'address',
            addressLoading: 'addressLoading',
            coordinate: 'coordinate',
        }),
        ...mapState(useObjectStore, {
            dataForm: 'dataForm',
        }),
        ...mapState(useNavigationStore, {
            objects: 'currentObjects',
        }),
        ...mapState(usePanelStore, {
            panelState: 'panelState',
        }),
        ...mapState(useProjectsStore, {
            projectConfiguration: 'selectedProject',
        }),
        sortedObjects() {
            return this.sortObjects(this.objects as Array<ObjectDataInterface>);
        },
    },
    async mounted() {
        this.menuStore.displayMenu = true;
        this.panelStore.setPanelHalfOpened();

        this.coordinateStore.findAddress();

        await Promise.all(
            (this.objects as Array<ObjectDataInterface>).map(
                (object: ObjectDataInterface) => {
                    return this.setObjectEditStatus(object);
                },
            ),
        );

        if (!this.projectId) {
            this.$router.push({ name: 'projects' });
        }
        this.loading = false;
    },
    methods: {
        closeDialog(): void {
            this.softwareChoiceDialogVisible = false;
        },
        getEditionStatus(object: ObjectDataInterface): string {
            const id = `${this.projectId}/${object.layerData.name}/${object.dataForm.sid}`;
            if (this.editedObjects[id]) {
                return '*';
            }

            return '';
        },
        getObjectId(object: ObjectDataInterface): unknown | null {
            const currentLayer = getLayerFromObjectData(object.layerData);
            if (!currentLayer) {
                return null;
            }

            const { idField } = currentLayer;
            if (!idField || typeof object.dataForm[idField] === 'undefined') {
                return object.dataForm.sid;
            }

            return object.dataForm[idField];
        },
        hasLayerInSelectedProject(object: ObjectDataInterface): boolean {
            return !!getLayerFromObjectData(object.layerData);
        },
        isLayerEditable(layer: LayerDataInterface) {
            return layer.enableEdition;
        },
        isOffline(): boolean {
            if (this.$route.meta) {
                return Boolean(this.$route.meta.offline);
            }

            return false;
        },
        async openForm(object: ObjectDataInterface): Promise<void> {
            this.loadingData = true;
            try {
                if (!object.Feature) {
                    throw new Error('Feature is not defined');
                }
                const featureId = object.Feature.getId();

                if (featureId) {
                    this.objectStore.setObject({
                        dataForm: object.dataForm,
                        Feature: object.Feature,
                        layerData: object.layerData,
                    });

                    await redirectToForm({
                        form: object.layerData.form,
                        isOffline: this.isOffline(),
                        layerName: object.layerData.name,
                        objectId: featureId.toString(),
                        projectId: this.projectId,
                        router: this.$router,
                        type: object.layerData.type,
                    });
                } else {
                    throw new Error('Feature has no id');
                }
            } catch (error: unknown) {
                console.error(error);
                alert(
                    "Une erreur vient de se produire, impossible d'ouvrir le formulaire",
                );
            } finally {
                this.loadingData = false;
            }
        },
        openNavigation(object: ObjectDataInterface): void {
            const feature = toRaw(object.Feature);

            if (!feature) {
                return;
            }

            const geometry = feature.getGeometry();
            if (!geometry) {
                return;
            }

            const points = getCenter(geometry.getExtent());

            this.formName = object.layerData.name;
            this.navigationCoordinates = points;
            this.softwareChoiceDialogVisible = true;
        },
        openObjectSummary(object: ObjectDataInterface): void {
            if (!object.Feature) {
                throw new Error('Feature is undefined');
            }

            let featureId = object.Feature.getId();

            if (!featureId) {
                if (object.layerData.idField) {
                    featureId =
                        object.Feature.getProperties()[
                            object.layerData.idField
                        ];
                }
                if (!featureId) {
                    throw new Error('Feature has no id');
                }
            }

            this.objectStore.setObject({
                dataForm: object.dataForm,
                Feature: object.Feature,
                layerData: object.layerData,
            });

            if (
                object.layerData.layerFormat ===
                ProjectConfigurationLayerFormatEnum.GEOJSON
            ) {
                zoomToFeature(object.Feature);
            }

            this.$router.push({
                name: this.isOffline() ? 'objectDataOffline' : 'objectData',
                params: {
                    layerName: object.layerData.name,
                    objectId: featureId.toString(),
                    projectId: this.projectId,
                },
            });
        },
        projectLink(): void {
            if (this.isOffline()) {
                this.$router.push({
                    name: 'offlineProject',
                    params: { projectId: this.projectId },
                });
            } else {
                this.$router.push({
                    name: 'project',
                    params: { projectId: this.projectId },
                });
            }
        },
        selectFeature(object: ObjectDataInterface): void {
            const feature = object.Feature;
            if (feature) {
                zoomToFeature(feature);
                highlightPoint(feature);
            }
            this.layersStore.$patch({ selection: object });
        },
        async setObjectEditStatus(object: ObjectDataInterface): Promise<void> {
            const id = `${this.projectId}/${object.layerData.name}/${object.dataForm.sid}`;
            const savedDataForm = await getItem<FormDataInterface>(
                id,
                'savedDataForms',
            );

            this.editedObjects[id] = Boolean(savedDataForm?.form);
        },
        sortObjects(
            objects: Array<ObjectDataInterface>,
        ): Array<ObjectDataInterface> {
            const sortBy = {
                geojson: 0,
                pbf: 1,
            };
            if (Array.isArray(objects)) {
                return objects.sort(
                    (a: ObjectDataInterface, b: ObjectDataInterface) =>
                        sortBy[a.layerData.layerFormat] -
                        sortBy[b.layerData.layerFormat],
                );
            }

            return objects;
        },
    },
});
</script>

<style lang="scss" scoped>
@import 'ObjectList.scss';
</style>
