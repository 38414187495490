<template>
    <el-row>
        <el-col :span="24">
            <div class="custom_label_input">{{ label }}</div>
        </el-col>
        <el-col :span="24">
            <el-form-item class="w-full">
                <el-select
                    v-model="model"
                    :disabled="disabled"
                    :size="size"
                    :placeholder="placeholder"
                    class="customInputSelect"
                >
                    <el-option
                        v-for="option in options"
                        :key="option.value"
                        :label="option.label"
                        :value="option.value"
                    />
                </el-select>
            </el-form-item>
        </el-col>
    </el-row>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';

import type { OptionsInterface } from '@connect-field/client/components/forms/form.types';

export default defineComponent({
    name: 'CustomInputSelect',
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: '',
        },
        options: {
            type: Array as PropType<OptionsInterface>,
            required: true,
        },
        placeholder: {
            type: String,
            default: 'Veuillez choisir une option',
        },
        size: {
            type: String,
            values: ['large', 'default', 'small'],
            default: 'default',
        },
        value: {
            type: String,
            required: true,
        },
    },
    emits: ['update:value'],
    computed: {
        model: {
            get(): unknown {
                return this.value;
            },
            set(value: unknown): void {
                this.$emit('update:value', value);
            },
        },
    },
    emit: ['update:value'],
});
</script>
