<template>
    <FormWrapper
        v-if="!loading"
        ref="customFormRef"
        :changes-model="changes"
        :form-model="form"
        :images-model="images"
        :latitude="latitude"
        :layer-name="layerName"
        :longitude="longitude"
        :mandatory-fields="mandatoryFields"
        :object-id="objectId"
        :project-id="projectId"
        @update:images-model="
            (e) => {
                images = e;
            }
        "
        @update:value-missing="
            (e) => {
                valueMissing = e;
            }
        "
        @update:form-model="
            (e) => {
                form = e;
            }
        "
        @update:changes-model="
            (e) => {
                changes = e;
            }
        "
    >
        <template #menu_tabs></template>
        <template #main_form>
            <el-row>
                <el-col :span="24">
                    <CustomInput
                        :label="labels.code"
                        :value="form.code"
                        @update:value="(newValue) => saveData('code', newValue)"
                    />
                    <CustomInput
                        :label="labels.proprietaire"
                        :maxlength="254"
                        :value="form.proprietaire"
                        @update:value="
                            (newValue) => saveData('proprietaire', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.gestionnaire"
                        :value="form.gestionnaire"
                        @update:value="
                            (newValue) => saveData('gestionnaire', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.type_struc"
                        :maxlength="254"
                        :value="form.type_struc"
                        @update:value="
                            (newValue) => saveData('type_struc', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.structure"
                        :value="form.structure"
                        @update:value="
                            (newValue) => saveData('structure', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.mode_pose"
                        :maxlength="254"
                        :value="form.mode_pose"
                        @update:value="
                            (newValue) => saveData('mode_pose', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.compo"
                        :value="form.compo"
                        @update:value="
                            (newValue) => saveData('compo', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.utilisation"
                        :maxlength="254"
                        :value="form.utilisation"
                        @update:value="
                            (newValue) => saveData('utilisation', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.lg_reelle"
                        :value="form.lg_reelle"
                        @update:value="
                            (newValue) => saveData('lg_reelle', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.date_deb_realisation"
                        :maxlength="254"
                        :value="form.date_deb_realisation"
                        @update:value="
                            (newValue) =>
                                saveData('date_deb_realisation', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.date_fin_realisation"
                        :value="form.date_fin_realisation"
                        @update:value="
                            (newValue) =>
                                saveData('date_fin_realisation', newValue)
                        "
                    />
                    <CustomDatePicker
                        :label="labels.date_audit"
                        :value="form.date_audit"
                        @update:value="
                            (newValue) => saveData('date_audit', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.respect_pgc"
                        :options="options.respect_pgc"
                        :value="form.respect_pgc"
                        size="small"
                        @update:value="
                            (newValue) => saveData('respect_pgc', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.img_gc_environnement"
                        :label="labels.img_gc_environnement"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_gc_environnement', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.respect_coupe"
                        :options="options.respect_coupe"
                        :value="form.respect_coupe"
                        size="small"
                        @update:value="
                            (newValue) => saveData('respect_coupe', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.qualite_compactage"
                        :options="options.qualite_compactage"
                        :value="form.qualite_compactage"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('qualite_compactage', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.conf_nb_fourreau"
                        :options="options.conf_nb_fourreau"
                        :value="form.conf_nb_fourreau"
                        size="small"
                        @update:value="
                            (newValue) => saveData('conf_nb_fourreau', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.qualite_refection"
                        :options="options.qualite_refection"
                        :value="form.qualite_refection"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('qualite_refection', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.img_gc_refection"
                        :label="labels.img_gc_refection"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_gc_refection', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.img_gc_autres"
                        :label="labels.img_gc_autres"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('img_gc_autres', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.criticite"
                        :options="options.criticite"
                        :value="form.criticite"
                        @update:value="
                            (newValue) => saveData('criticite', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_comment_gb()"
                        :label="labels.comment_gb"
                        :value="form.comment_gb"
                        @update:value="
                            (newValue) => saveData('comment_gb', newValue)
                        "
                    />
                    <CustomInputSelect
                        :class="{ 'text-warning': valueMissing }"
                        :label="labels.statut_audit + ' *'"
                        :options="options.statut_audit"
                        :value="form.statut_audit"
                        @update:value="
                            (newValue) => saveData('statut_audit', newValue)
                        "
                    />
                </el-col>
            </el-row>
        </template>
    </FormWrapper>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import {
    labels,
    options,
} from '@connect-field/client/components/forms/NGE/form_concept_gc_nge.data';
import type { FormDataInterface } from '@connect-field/client/components/forms/form.types';
import FormTemplate from '@connect-field/client/components/forms/FormTemplate.vue';

interface DataInterface extends FormDataInterface {}

export default defineComponent({
    extends: FormTemplate,
    data(): DataInterface {
        return {
            changes: false,
            form: {},
            images: {
                img_gc_environnement: [],
                img_gc_refection: [],
                img_gc_autres: [],
            },
            labels,
            loading: true,
            mandatoryFields: ['statut_audit'],
            options,

            valueMissing: false,
        };
    },
    methods: {
        condition_comment_gb(): boolean {
            const { criticite } = this.form;
            return !!criticite;
        },
    },
});
</script>
