<template>
    <FormWrapper
        v-if="!loading"
        ref="customFormRef"
        :images-model="images"
        :form-model="form"
        :mandatory-fields="mandatoryFields"
        :changes-model="changes"
        :layer-name="layerName"
        :object-id="objectId"
        :project-id="projectId"
        :latitude="latitude"
        :longitude="longitude"
        @update:images-model="
            (e) => {
                images = e;
            }
        "
        @update:value-missing="
            (e) => {
                valueMissing = e;
            }
        "
        @update:form-model="
            (e) => {
                form = e;
            }
        "
        @update:changes-model="
            (e) => {
                changes = e;
            }
        "
    >
        <template #menu_tabs> </template>
        <template #main_form>
            <el-row>
                <el-col :span="24">
                    <CustomInput
                        :label="labels.bp_code + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.bp_code"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('bp_code', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.real_audit + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.real_audit"
                        :value="form.real_audit"
                        @update:value="
                            (newValue) => saveData('real_audit', newValue)
                        "
                    />

                    <div v-show="condition_img_ebp()">
                        <InputCamera
                            :label="labels.img_ebp"
                            :images="images.img_ebp"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) => saveImage('img_ebp', newValue)
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="condition_bp_etiquet()"
                        :label="labels.bp_etiquet + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.bp_etiquet"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('bp_etiquet', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_bp_codeext()"
                        :label="labels.bp_codeext + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.bp_codeext"
                        @update:value="
                            (newValue) => saveData('bp_codeext', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_bp_pt_code()"
                        :label="labels.bp_pt_code + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.bp_pt_code"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('bp_pt_code', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_bp_lt_code()"
                        :label="labels.bp_lt_code + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.bp_lt_code"
                        @update:value="
                            (newValue) => saveData('bp_lt_code', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_bp_sf_code()"
                        :label="labels.bp_sf_code"
                        :value="form.bp_sf_code"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('bp_sf_code', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_bp_prop()"
                        :label="labels.bp_prop + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.bp_prop"
                        @update:value="
                            (newValue) => saveData('bp_prop', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_bp_gest()"
                        :label="labels.bp_gest + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.bp_gest"
                        :maxlength="20"
                        @update:value="
                            (newValue) => saveData('bp_gest', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_bp_user()"
                        :label="labels.bp_user + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.bp_user"
                        @update:value="
                            (newValue) => saveData('bp_user', newValue)
                        "
                    />
                    <CustomInputSelect
                        v-show="condition_bp_proptyp()"
                        :label="labels.bp_proptyp"
                        :options="options.bp_proptyp"
                        :value="form.bp_proptyp"
                        @update:value="
                            (newValue) => saveData('bp_proptyp', newValue)
                        "
                    />
                    <CustomInputSelect
                        v-show="condition_bp_statut()"
                        :label="labels.bp_statut"
                        :options="options.bp_statut"
                        :value="form.bp_statut"
                        @update:value="
                            (newValue) => saveData('bp_statut', newValue)
                        "
                    />
                    <CustomInputSelect
                        v-show="condition_bp_etat()"
                        :label="labels.bp_etat + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.bp_etat"
                        :value="form.bp_etat"
                        @update:value="
                            (newValue) => saveData('bp_etat', newValue)
                        "
                    />
                    <CustomInputSelect
                        v-show="condition_bp_occp()"
                        :label="labels.bp_occp"
                        :options="options.bp_occp"
                        :value="form.bp_occp"
                        @update:value="
                            (newValue) => saveData('bp_occp', newValue)
                        "
                    />
                    <CustomDatePicker
                        v-show="condition_bp_datemes()"
                        :label="labels.bp_datemes + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.bp_datemes"
                        @update:value="
                            (newValue) => saveData('bp_datemes', newValue)
                        "
                    />
                    <CustomInputSelect
                        v-show="condition_bp_avct()"
                        :label="labels.bp_avct + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.bp_avct"
                        :value="form.bp_avct"
                        @update:value="
                            (newValue) => saveData('bp_avct', newValue)
                        "
                    />
                    <CustomInputSelect
                        v-show="condition_bp_typephy()"
                        :label="labels.bp_typephy + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.bp_typephy"
                        :value="form.bp_typephy"
                        @update:value="
                            (newValue) => saveData('bp_typephy', newValue)
                        "
                    />
                    <CustomInputSelect
                        v-show="condition_bp_typelog()"
                        :label="labels.bp_typelog + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :options="options.bp_typelog"
                        :value="form.bp_typelog"
                        @update:value="
                            (newValue) => saveData('bp_typelog', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_bp_rf_code()"
                        :label="labels.bp_rf_code + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.bp_rf_code"
                        @update:value="
                            (newValue) => saveData('bp_rf_code', newValue)
                        "
                    />
                    <InputNumber
                        v-show="condition_bp_entrees()"
                        :label="labels.bp_entrees + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.bp_entrees"
                        @update:value="
                            (newValue) => saveData('bp_entrees', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_bp_ref_kit()"
                        :label="labels.bp_ref_kit + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.bp_ref_kit"
                        :maxlength="30"
                        @update:value="
                            (newValue) => saveData('bp_ref_kit', newValue)
                        "
                    />
                    <InputNumber
                        v-show="condition_bp_ca_nb()"
                        :label="labels.bp_ca_nb + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.bp_ca_nb"
                        @update:value="
                            (newValue) => saveData('bp_ca_nb', newValue)
                        "
                    />
                    <InputNumber
                        v-show="condition_bp_nb_pas()"
                        :label="labels.bp_nb_pas + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.bp_nb_pas"
                        @update:value="
                            (newValue) => saveData('bp_nb_pas', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_bp_linecod()"
                        :label="labels.bp_linecod"
                        :value="form.bp_linecod"
                        :maxlength="12"
                        @update:value="
                            (newValue) => saveData('bp_linecod', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_bp_oc_code()"
                        :label="labels.bp_oc_code"
                        :value="form.bp_oc_code"
                        @update:value="
                            (newValue) => saveData('bp_oc_code', newValue)
                        "
                    />
                    <CustomInputSelect
                        v-show="condition_bp_racco()"
                        :label="labels.bp_racco"
                        :options="options.bp_racco"
                        :value="form.bp_racco"
                        @update:value="
                            (newValue) => saveData('bp_racco', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_bp_comment()"
                        :label="labels.bp_comment + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.bp_comment"
                        @update:value="
                            (newValue) => saveData('bp_comment', newValue)
                        "
                    />
                    <CustomDatePicker
                        v-show="condition_bp_creadat()"
                        :label="labels.bp_creadat + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.bp_creadat"
                        :disabled="true"
                    />
                    <CustomDatePicker
                        v-show="condition_bp_majdate()"
                        :label="labels.bp_majdate + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.bp_majdate"
                        :disabled="true"
                    />
                    <CustomInput
                        v-show="condition_bp_majsrc()"
                        :label="labels.bp_majsrc + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.bp_majsrc"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('bp_majsrc', newValue)
                        "
                    />
                    <CustomDatePicker
                        v-show="condition_bp_abddate()"
                        :label="labels.bp_abddate + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.bp_abddate"
                        @update:value="
                            (newValue) => saveData('bp_abddate', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_bp_abdsrc()"
                        :label="labels.bp_abdsrc + ' *'"
                        :class="{ 'text-warning': valueMissing }"
                        :value="form.bp_abdsrc"
                        :maxlength="254"
                        @update:value="
                            (newValue) => saveData('bp_abdsrc', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_commentaire()"
                        :label="labels.commentaire"
                        :value="form.commentaire"
                        @update:value="
                            (newValue) => saveData('commentaire', newValue)
                        "
                    />
                </el-col>
            </el-row>
        </template>
    </FormWrapper>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import type { FormDataInterface } from '@connect-field/client/components/forms/form.types';
import FormTemplate from '@connect-field/client/components/forms/FormTemplate.vue';

import {
    labels,
    options,
} from '@connect-field/client/components/forms/Dotic/INEO/form_ebp.data';

interface DataInterface extends FormDataInterface {}

export default defineComponent({
    extends: FormTemplate,
    data(): DataInterface {
        return {
            changes: false,
            form: {},
            images: {
                img_ebp: [],
            },
            labels,
            loading: true,
            mandatoryFields: [
                'bp_code',
                'real_audit',
                'bp_etiquet',
                'bp_codeext',
                'bp_pt_code',
                'bp_lt_code',
                'bp_prop',
                'bp_gest',
                'bp_user',
                'bp_etat',
                'bp_datemes',
                'bp_avct',
                'bp_typephy',
                'bp_typelog',
                'bp_rf_code',
                'bp_entrees',
                'bp_ref_kit',
                'bp_ca_nb',
                'bp_nb_pas',
                'bp_comment',
                'bp_creadat',
                'bp_majdate',
                'bp_majsrc',
                'bp_abddate',
                'bp_abdsrc',
            ],
            options,

            valueMissing: false,
        };
    },
    methods: {
        condition_bp_abddate() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_bp_abdsrc() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_bp_avct() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_bp_ca_nb() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_bp_codeext() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_bp_comment() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_bp_creadat() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_bp_datemes() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_bp_entrees() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_bp_etat() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_bp_etiquet() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_bp_gest() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_bp_linecod() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_bp_lt_code() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_bp_majdate() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_bp_majsrc() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_bp_nb_pas() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_bp_oc_code() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_bp_occp() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_bp_prop() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_bp_proptyp() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_bp_pt_code() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_bp_racco() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_bp_ref_kit() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_bp_rf_code() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_bp_sf_code() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_bp_statut() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_bp_typelog() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_bp_typephy() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_bp_user() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
        condition_commentaire() {
            const { real_audit } = this.form;
            return real_audit === 'NON';
        },
        condition_img_ebp() {
            const { real_audit } = this.form;
            return real_audit === 'OUI';
        },
    },
});
</script>
