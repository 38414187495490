<template>
    <div class="card">
        <header>
            <h3 class="title">{{ title }}</h3>
        </header>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import usePanelStore from '@connect-field/client/stores/panel';
import useProjectsStore from '@connect-field/client/stores/projects';

const PANEL_CLOSED = 'closed';

export default defineComponent({
    props: {
        title: {
            type: String,
            default: '',
        },
    },
    setup() {
        return {
            panelStore: usePanelStore(),
            projectsStore: useProjectsStore(),
        };
    },
    methods: {
        // TODO : unused or unmounted here ?
        // eslint-disable-next-line vue/no-unused-properties
        onClose(): void {
            const lastPanelState = this.panelStore.panelLastState;
            if (lastPanelState === PANEL_CLOSED) {
                this.panelStore.setPanelClosed();
            }
            if (this.projectsStore.lastProjectSelected) {
                this.$router.push({
                    name: 'project',
                    params: { projectId: this.selectedProjectId }, // TODO : Fix this
                });
            } else {
                this.$router.push({ name: 'projects' });
            }
        },
    },
});
</script>

<style scoped lang="scss">
header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--silver);
    margin: 0 1rem;
    padding: 0 0 0.5rem;
}

.title {
    color: #fff;
}
</style>
