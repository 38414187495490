<template>
    <div
        ref="panelContainer"
        class="panel glass"
    >
        <header
            ref="panel"
            class="panel__header"
            @click="togglePanel"
            @swiping="swiping"
            @swiperelease="swipeRelease"
        >
            <div class="panel__handle" />
        </header>
        <main class="panel__body">
            <div
                ref="router"
                class="router"
                :class="{ 'full-height': !displayMenu }"
                @scroll="preventScroll"
            >
                <router-view v-slot="slotProps">
                    <component
                        :is="slotProps.Component"
                        :key="slotProps.route"
                    />
                </router-view>
            </div>
            <menu
                v-if="displayMenu"
                class="menu"
            >
                <ul class="menu__list">
                    <li class="menu__item">
                        <router-link
                            class="menu__link"
                            to="/home/projects"
                            :class="{ active: isProjectDetail }"
                            @click="navigate(LAYERS)"
                        >
                            <span>
                                <FontAwesomeIcon icon="layer-group" />
                            </span>
                        </router-link>
                    </li>
                    <li class="menu__item">
                        <router-link
                            class="menu__link"
                            to="/home/backgroundMap"
                            @click="navigate"
                        >
                            <span>
                                <FontAwesomeIcon icon="map" />
                            </span>
                        </router-link>
                    </li>
                    <li
                        v-if="selectedProjectId"
                        class="menu__item"
                    >
                        <router-link
                            :class="{ active: isSearchRoute }"
                            class="menu__link"
                            to="/home/projects/:projectId/search"
                            @click="navigate(SEARCH)"
                        >
                            <span>
                                <FontAwesomeIcon icon="search" />
                            </span>
                        </router-link>
                    </li>
                    <li class="menu__item">
                        <router-link
                            class="menu__link"
                            to="/home/settings"
                            replace
                            @click="navigate"
                        >
                            <span>
                                <FontAwesomeIcon icon="user-cog" />
                            </span>
                        </router-link>
                    </li>
                </ul>
            </menu>
        </main>
    </div>
</template>

<script lang="ts">
import { mapActions, mapState } from 'pinia';
import { defineComponent } from 'vue';
import SwipeListener from 'swipe-listener';

import { isAppleDevice } from '@connect-field/client/utilities/tools';
import useMenuStore from '@connect-field/client/stores/menu';
import usePanelStore from '@connect-field/client/stores/panel';
import useProjectsStore from '@connect-field/client/stores/projects';

const BACKGROUND_MAP = 'backgroundMap';
const CLOSED = 'closed';
const FORMS = 'forms';
const LAYERS = 'layers';
const OBJECTS = 'objectList';
const OFFLINE_PROJECT = 'offlineProject';
const PANEL_MIN_HEIGHT = 90;
const PANEL_MIN_HEIGHT_NO_NAV = 40;
const PROJECT = 'project';
const PROJECTS = 'projects';
const SEARCH = 'search';
const SEARCH_OFFLINE = 'searchOffline';
const SETTINGS = 'settings';
const SLIDE_DOWN = 'slide-down';
const SLIDE_LEFT = 'slide-left';
const SLIDE_RIGHT = 'slide-right';
const SLIDE_UP = 'slide-up';
const WIDE_RANGE_OF_MOTION = 350;

let y: number;

// Documentation https://github.com/umanghome/swipe-listener#swipe---emitted-once-a-swipe-is-performed
export interface SwipeEventInterface {
    detail: {
        directions: {
            bottom: boolean;
            left: boolean;
            right: boolean;
            top: boolean;
        };
        touch: boolean;
        x: [number, number];
        y: [number, number];
    };
}

//https://github.com/umanghome/swipe-listener#swiping---emitted-while-a-swipe-is-being-performed
export interface SwipingEventInterface {
    detail: {
        touch: boolean;
        x: [number, number];
        y: [number, number];
    };
}

// Documentation https://github.com/umanghome/swipe-listener#swiperelease---emitted-once-the-swipe-is-releasedcompleted
export interface SwipeReleaseEventInterface {
    detail: {
        touch: boolean;
        x: [number, number];
        y: [number, number];
    };
}

interface DataInterface {
    isScrolling: boolean;
    LAYERS: string;
    scrollTimeout?: ReturnType<typeof setTimeout>;
    SEARCH: string;
    transitionName: string;
}

export default defineComponent({
    $refs: {
        panel: HTMLElement,
        panelContainer: HTMLElement,
        router: HTMLElement,
    },
    setup() {
        return {
            panelStore: usePanelStore(),
        };
    },
    data(): DataInterface {
        return {
            isScrolling: false,
            LAYERS: LAYERS,
            SEARCH: SEARCH,
            transitionName: '',
        };
    },
    computed: {
        ...mapState(useMenuStore, {
            displayMenu: 'displayMenu',
        }),
        ...mapState(usePanelStore, {
            panelHeight: 'panelHeight',
            panelMinHeight: 'panelMinHeight',
            panelState: 'panelState',
            panelStateClosed: 'panelStateClosed',
            panelStateHalfOpened: 'panelStateHalfOpened',
            panelStateOpened: 'panelStateOpened',
        }),
        ...mapState(useProjectsStore, {
            isProjectOffline: 'isOffline',
            selectedProjectId: 'selectedProjectId',
        }),
        isProjectDetail(): boolean {
            return (
                this.$route.name === PROJECT ||
                this.$route.name === OFFLINE_PROJECT
            );
        },
        isSearchRoute(): boolean {
            return (
                this.$route.name === SEARCH ||
                this.$route.name === SEARCH_OFFLINE
            );
        },
    },
    watch: {
        $route(to, from) {
            if (
                to.name === SETTINGS ||
                to.name === SEARCH ||
                to.name === BACKGROUND_MAP ||
                (from.name === FORMS && to.name !== OBJECTS)
            ) {
                this.transitionName = SLIDE_UP;
            } else if (
                from.name === SETTINGS ||
                from.name === SEARCH ||
                from.name === BACKGROUND_MAP ||
                (to.name === FORMS && from.name !== OBJECTS)
            ) {
                this.transitionName = SLIDE_DOWN;
            } else if (
                to.name === FORMS ||
                (to.name === OBJECTS && from.name !== FORMS) ||
                from.name === PROJECTS
            ) {
                this.transitionName = SLIDE_LEFT;
            } else if (
                (from.name === FORMS && to.name === OBJECTS) ||
                to.name === PROJECTS ||
                from.name === FORMS ||
                from.name === OBJECTS ||
                (to.name === OBJECTS && from.name !== FORMS)
            ) {
                this.transitionName = SLIDE_RIGHT;
            } else {
                this.transitionName = SLIDE_LEFT;
            }
        },
        panelHeight() {
            this.updateStyle();
        },
    },
    mounted() {
        // Mount the listener for the swipe movement
        SwipeListener(this.$refs.panel as HTMLElement, {
            deltaVertical: 10,
            minHorizontal: Infinity,
            minVertical: 5,
            mouse: false, // Horrible behaviour with the click event
            preventScroll: true,
        });
    },
    updated() {
        (this.$refs.router as HTMLElement).scrollTop = 0;
    },
    methods: {
        ...mapActions(usePanelStore, {
            getHalfHeight: 'getHalfHeight',
            getMaxHeight: 'getMaxHeight',
        }),
        navigate(target: string): void {
            if (
                (isAppleDevice() &&
                    this.panelState === 'fully-opened' &&
                    (this.$route.name === 'search' ||
                        this.$route.name === 'searchOffline')) ||
                this.panelState === CLOSED
            ) {
                this.panelStore.setPanelHalfOpened();
            } else {
                this.panelStore.setPanelLastState(this.panelState);
            }

            if (this.selectedProjectId && target === LAYERS) {
                if (this.isProjectOffline) {
                    this.$router.push({
                        name: OFFLINE_PROJECT,
                        params: { projectId: this.selectedProjectId },
                    });
                } else if (!this.isProjectOffline) {
                    this.$router.push({
                        name: PROJECT,
                        params: { projectId: this.selectedProjectId },
                    });
                }
            }
            if (this.selectedProjectId && target === SEARCH) {
                if (this.isProjectOffline) {
                    this.$router.push({
                        name: SEARCH_OFFLINE,
                        params: { projectId: this.selectedProjectId },
                    });
                } else if (!this.isProjectOffline) {
                    this.$router.push({
                        name: SEARCH,
                        params: { projectId: this.selectedProjectId },
                    });
                }
            }
        },
        preventScroll(): void {
            if (this.scrollTimeout) {
                window.clearTimeout(this.scrollTimeout);
            }
            this.isScrolling = true;
            this.scrollTimeout = setTimeout(() => {
                this.isScrolling = false;
            }, 500);
        },
        swipeRelease(event: SwipeReleaseEventInterface): void {
            if (this.isScrolling) {
                return;
            }

            const startPoint = event.detail.y[0];
            const endPoint = event.detail.y[1];
            const distance = startPoint - endPoint;
            const rangeOfMotion = Math.abs(startPoint - endPoint);
            const isToTop = Math.sign(distance) >= 0;
            const isToBottom = Math.sign(distance) < 0;
            const computedHeight = this.getMaxHeight() - event.detail.y[1];

            // TODO: Move classic action like open/mid-open/close the panel in a service file or anything that can be called easily
            if (isToTop) {
                if (
                    this.panelState === this.panelStateClosed &&
                    rangeOfMotion < WIDE_RANGE_OF_MOTION
                ) {
                    this.panelStore.setPanelHeight(this.getHalfHeight());
                    this.panelStore.setPanelState(this.panelStateHalfOpened);
                } else if (
                    this.panelState === this.panelStateClosed &&
                    rangeOfMotion >= WIDE_RANGE_OF_MOTION
                ) {
                    this.panelStore.setPanelHeight(`${this.getMaxHeight()}px`);
                    this.panelStore.setPanelState(this.panelStateOpened);
                } else {
                    this.panelStore.setPanelHeight(`${this.getMaxHeight()}px`);
                    this.panelStore.setPanelState(this.panelStateOpened);
                }
            } else if (isToBottom) {
                if (
                    this.panelState === this.panelStateOpened &&
                    rangeOfMotion < WIDE_RANGE_OF_MOTION
                ) {
                    this.panelStore.setPanelHeight(this.getHalfHeight());
                    this.panelStore.setPanelState(this.panelStateHalfOpened);
                } else if (
                    this.panelState === this.panelStateOpened &&
                    rangeOfMotion >= WIDE_RANGE_OF_MOTION
                ) {
                    this.panelStore.setPanelState(this.panelStateClosed);
                    this.panelStore.setPanelHeight(this.panelMinHeight);
                } else {
                    this.panelStore.setPanelState(this.panelStateClosed);
                    this.panelStore.setPanelHeight(this.panelMinHeight);
                }
                if (computedHeight < PANEL_MIN_HEIGHT && !this.displayMenu) {
                    this.panelStore.setPanelHeight('40px');
                }
            }

            this.updateStyle();
        },
        swiping(event: SwipingEventInterface): void {
            if (this.isScrolling) {
                return;
            }
            const _y = event.detail.y[1];
            if (!y) {
                y = _y;
            }
            if (Math.abs(y - _y) > 5) {
                y = _y;
                const computedHeight = this.getMaxHeight() - event.detail.y[1];
                let height = `${computedHeight}px`;
                if (computedHeight < PANEL_MIN_HEIGHT && this.displayMenu) {
                    height = this.panelMinHeight;
                }
                this.panelStore.setPanelHeight(height);
                this.updateStyle();
            }
        },
        togglePanel(): void {
            (this.$refs['panelContainer'] as HTMLElement).style.transition =
                'height 150ms ease-in-out';
            if (this.panelState === this.panelStateClosed) {
                this.panelStore.setPanelHalfOpened();
            } else if (this.panelState === this.panelStateHalfOpened) {
                this.panelStore.setPanelFullyOpened();
            } else if (
                this.panelState === this.panelStateOpened &&
                !this.displayMenu
            ) {
                this.panelStore.setPanelState(this.panelStateClosed);
                this.panelStore.setPanelHeight(`${PANEL_MIN_HEIGHT_NO_NAV}px`);
            } else {
                this.panelStore.setPanelClosed();
            }

            this.updateStyle();
            setTimeout(() => {
                (this.$refs['panelContainer'] as HTMLElement).style.transition =
                    '';
            }, 150);
        },
        updateStyle(): void {
            (this.$refs['panelContainer'] as HTMLElement).style.height =
                this.panelHeight;
        },
    },
});
</script>

<style scoped lang="scss">
@import 'PanelInstance.scss';
</style>
