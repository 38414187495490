<template>
    <FormWrapper
        v-if="!loading"
        ref="customFormRef"
        :changes-model="changes"
        :form-model="form"
        :images-model="images"
        :latitude="latitude"
        :layer-name="layerName"
        :longitude="longitude"
        :mandatory-fields="mandatoryFields"
        :object-id="objectId"
        :project-id="projectId"
        @update:images-model="
            (e) => {
                images = e;
            }
        "
        @update:value-missing="
            (e) => {
                valueMissing = e;
            }
        "
        @update:form-model="
            (e) => {
                form = e;
            }
        "
        @update:changes-model="
            (e) => {
                changes = e;
            }
        "
    >
        <template #menu_tabs>
            <el-row class="scroll-menu">
                <el-col :span="10">
                    <div
                        :class="{ selectedTab: selectedTab === 'METIER' }"
                        class="tabs"
                        @click="selectTab('METIER')"
                    >
                        METIER
                    </div>
                </el-col>

                <el-col :span="10">
                    <div
                        :class="{ selectedTab: selectedTab === 'EXT' }"
                        class="tabs"
                        @click="selectTab('EXT')"
                    >
                        EXT
                    </div>
                </el-col>

                <el-col :span="10">
                    <div
                        :class="{ selectedTab: selectedTab === 'FIBRE' }"
                        class="tabs"
                        @click="selectTab('FIBRE')"
                    >
                        FIBRE
                    </div>
                </el-col>
            </el-row>
        </template>
        <template #main_form>
            <el-row>
                <el-col
                    v-show="selectedTab === 'METIER'"
                    :span="24"
                >
                    <CustomInput
                        :disabled="true"
                        :label="labels.bp_code"
                        :value="form.bp_code"
                    />
                    <CustomInputSelect
                        :class="{ 'text-warning': valueMissing }"
                        :label="labels.statut + ' *'"
                        :options="options.statut"
                        :value="form.statut"
                        @update:value="
                            (newValue) => saveData('statut', newValue)
                        "
                    />
                    <CustomInput
                        :disabled="true"
                        :label="labels.bp_etiquet"
                        :value="form.bp_etiquet"
                    />
                    <CustomInput
                        :disabled="true"
                        :label="labels.bp_codeext"
                        :maxlength="254"
                        :value="form.bp_codeext"
                    />
                    <CustomInputSwitch
                        :label="labels.donnees_metier"
                        :options="options.donnees_metier"
                        :value="form.donnees_metier"
                        @update:value="
                            (newValue) => saveData('donnees_metier', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_bp_pt_code()"
                        :disabled="true"
                        :label="labels.bp_pt_code"
                        :maxlength="254"
                        :value="form.bp_pt_code"
                    />
                    <CustomInput
                        v-show="condition_bp_lt_code()"
                        :disabled="true"
                        :label="labels.bp_lt_code"
                        :value="form.bp_lt_code"
                    />
                    <CustomInput
                        v-show="condition_bp_sf_code()"
                        :disabled="true"
                        :label="labels.bp_sf_code"
                        :maxlength="254"
                        :value="form.bp_sf_code"
                    />
                    <CustomInput
                        v-show="condition_bp_prop()"
                        :disabled="true"
                        :label="labels.bp_prop"
                        :value="form.bp_prop"
                    />
                    <CustomInput
                        v-show="condition_bp_gest()"
                        :disabled="true"
                        :label="labels.bp_gest"
                        :maxlength="20"
                        :value="form.bp_gest"
                    />
                    <CustomInput
                        v-show="condition_bp_user()"
                        :disabled="true"
                        :label="labels.bp_user"
                        :value="form.bp_user"
                    />
                    <CustomInputSelect
                        v-show="condition_bp_proptyp()"
                        :disabled="true"
                        :label="labels.bp_proptyp"
                        :options="options.bp_proptyp"
                        :value="form.bp_proptyp"
                    />
                    <CustomInputSelect
                        v-show="condition_bp_statut()"
                        :disabled="true"
                        :label="labels.bp_statut"
                        :options="options.bp_statut"
                        :value="form.bp_statut"
                    />
                    <CustomInputSelect
                        v-show="condition_bp_etat()"
                        :disabled="true"
                        :label="labels.bp_etat"
                        :options="options.bp_etat"
                        :value="form.bp_etat"
                    />
                    <CustomInputSelect
                        v-show="condition_bp_occp()"
                        :disabled="true"
                        :label="labels.bp_occp"
                        :options="options.bp_occp"
                        :value="form.bp_occp"
                    />
                    <CustomDatePicker
                        v-show="condition_bp_datemes()"
                        :disabled="true"
                        :label="labels.bp_datemes"
                        :value="form.bp_datemes"
                    />
                    <CustomInputSelect
                        v-show="condition_bp_avct()"
                        :disabled="true"
                        :label="labels.bp_avct"
                        :options="options.bp_avct"
                        :value="form.bp_avct"
                    />
                    <CustomInputSelect
                        v-show="condition_bp_typephy()"
                        :disabled="true"
                        :label="labels.bp_typephy"
                        :options="options.bp_typephy"
                        :value="form.bp_typephy"
                    />
                    <CustomInputSelect
                        v-show="condition_bp_typelog()"
                        :disabled="true"
                        :label="labels.bp_typelog"
                        :options="options.bp_typelog"
                        :value="form.bp_typelog"
                    />
                    <CustomInput
                        v-show="condition_bp_rf_code()"
                        :disabled="true"
                        :label="labels.bp_rf_code"
                        :value="form.bp_rf_code"
                    />
                    <InputNumber
                        v-show="condition_bp_entrees()"
                        :disabled="true"
                        :label="labels.bp_entrees"
                        :value="form.bp_entrees"
                    />
                    <CustomInput
                        v-show="condition_bp_ref_kit()"
                        :disabled="true"
                        :label="labels.bp_ref_kit"
                        :maxlength="30"
                        :value="form.bp_ref_kit"
                    />
                    <InputNumber
                        v-show="condition_bp_ca_nb()"
                        :disabled="true"
                        :label="labels.bp_ca_nb"
                        :value="form.bp_ca_nb"
                    />
                    <InputNumber
                        v-show="condition_bp_nb_pas()"
                        :disabled="true"
                        :label="labels.bp_nb_pas"
                        :value="form.bp_nb_pas"
                    />
                    <CustomInput
                        v-show="condition_bp_linecod()"
                        :disabled="true"
                        :label="labels.bp_linecod"
                        :maxlength="12"
                        :value="form.bp_linecod"
                    />
                    <CustomInput
                        v-show="condition_bp_oc_code()"
                        :disabled="true"
                        :label="labels.bp_oc_code"
                        :value="form.bp_oc_code"
                    />
                    <CustomInputSelect
                        v-show="condition_bp_racco()"
                        :disabled="true"
                        :label="labels.bp_racco"
                        :options="options.bp_racco"
                        :value="form.bp_racco"
                    />
                    <CustomInput
                        v-show="condition_bp_comment()"
                        :disabled="true"
                        :label="labels.bp_comment"
                        :value="form.bp_comment"
                    />
                    <CustomDatePicker
                        v-show="condition_bp_creadat()"
                        :disabled="true"
                        :label="labels.bp_creadat"
                        :value="form.bp_creadat"
                    />
                    <CustomDatePicker
                        v-show="condition_bp_majdate()"
                        :disabled="true"
                        :label="labels.bp_majdate"
                        :value="form.bp_majdate"
                    />
                    <CustomInput
                        v-show="condition_bp_majsrc()"
                        :disabled="true"
                        :label="labels.bp_majsrc"
                        :maxlength="254"
                        :value="form.bp_majsrc"
                    />
                    <CustomDatePicker
                        v-show="condition_bp_abddate()"
                        :disabled="true"
                        :label="labels.bp_abddate"
                        :value="form.bp_abddate"
                    />
                    <CustomInput
                        v-show="condition_bp_abdsrc()"
                        :disabled="true"
                        :label="labels.bp_abdsrc"
                        :maxlength="254"
                        :value="form.bp_abdsrc"
                    />
                </el-col>
                <el-col
                    v-show="selectedTab === 'EXT'"
                    :span="24"
                >
                    <CustomRadioButton
                        :label="labels.emplacement_boitier"
                        :options="options.emplacement_boitier"
                        :value="form.emplacement_boitier"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('emplacement_boitier', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.img_emplacement_boitier"
                        :label="labels.img_emplacement_boitier + ' *'"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_emplacement_boitier', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_emplacement_boitier"
                        :maxlength="254"
                        :value="form.commentaire_emplacement_boitier"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_emplacement_boitier',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.type_cable"
                        :options="options.type_cable"
                        :value="form.type_cable"
                        size="small"
                        @update:value="
                            (newValue) => saveData('type_cable', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.img_type_cable"
                        :label="labels.img_type_cable + ' *'"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('img_type_cable', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_type_cable"
                        :maxlength="254"
                        :value="form.commentaire_type_cable"
                        @update:value="
                            (newValue) =>
                                saveData('commentaire_type_cable', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.presence_gaine_fendue"
                        :options="options.presence_gaine_fendue"
                        :value="form.presence_gaine_fendue"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('presence_gaine_fendue', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.img_presence_gaine_fendue"
                        :label="labels.img_presence_gaine_fendue + ' *'"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_presence_gaine_fendue', newValue)
                        "
                    />
                    <CustomInput
                        :label="
                            labels.commentaire_presence_gaine_fenduearrimage_cables
                        "
                        :maxlength="254"
                        :value="
                            form.commentaire_presence_gaine_fenduearrimage_cables
                        "
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_presence_gaine_fenduearrimage_cables',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.etiquette_bpe"
                        :options="options.etiquette_bpe"
                        :value="form.etiquette_bpe"
                        size="small"
                        @update:value="
                            (newValue) => saveData('etiquette_bpe', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.img_etiquette_bpe"
                        :label="labels.img_etiquette_bpe + ' *'"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_etiquette_bpe', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_etiquette_bpe"
                        :maxlength="254"
                        :value="form.commentaire_etiquette_bpe"
                        @update:value="
                            (newValue) =>
                                saveData('commentaire_etiquette_bpe', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.fixation_boitier"
                        :options="options.fixation_boitier"
                        :value="form.fixation_boitier"
                        size="small"
                        @update:value="
                            (newValue) => saveData('fixation_boitier', newValue)
                        "
                    />
                    <CustomInputSwitch
                        v-show="condition_presence_fixation()"
                        :class="{ 'text-warning': valueMissing }"
                        :label="labels.presence_fixation + ' *'"
                        :options="options.presence_fixation"
                        :value="form.presence_fixation"
                        @update:value="
                            (newValue) =>
                                saveData('presence_fixation', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.img_fixation_boitier"
                        :label="labels.img_fixation_boitier + ' *'"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_fixation_boitier', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_fixation_boitier"
                        :maxlength="254"
                        :value="form.commentaire_fixation_boitier"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_fixation_boitier',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.diametre_ecam"
                        :options="options.diametre_ecam"
                        :value="form.diametre_ecam"
                        size="small"
                        @update:value="
                            (newValue) => saveData('diametre_ecam', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.img_diametre_ecam"
                        :label="labels.img_diametre_ecam + ' *'"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_diametre_ecam', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_diametre_ecam"
                        :maxlength="254"
                        :value="form.commentaire_diametre_ecam"
                        @update:value="
                            (newValue) =>
                                saveData('commentaire_diametre_ecam', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.etancheite_pbo"
                        :options="options.etancheite_pbo"
                        :value="form.etancheite_pbo"
                        size="small"
                        @update:value="
                            (newValue) => saveData('etancheite_pbo', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.img_etancheite_pbo"
                        :label="labels.img_etancheite_pbo + ' *'"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_etancheite_pbo', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_etancheite_pbo"
                        :maxlength="254"
                        :value="form.commentaire_etancheite_pbo"
                        @update:value="
                            (newValue) =>
                                saveData('commentaire_etancheite_pbo', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.arrimage_cables"
                        :options="options.arrimage_cables"
                        :value="form.arrimage_cables"
                        size="small"
                        @update:value="
                            (newValue) => saveData('arrimage_cables', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.img_arrimage_cables"
                        :label="labels.img_arrimage_cables + ' *'"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_arrimage_cables', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_arrimage_cables"
                        :maxlength="254"
                        :value="form.commentaire_arrimage_cables"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_arrimage_cables',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.autre_ext"
                        :options="options.autre_ext"
                        :value="form.autre_ext"
                        size="small"
                        @update:value="
                            (newValue) => saveData('autre_ext', newValue)
                        "
                    />

                    <div v-show="condition_img_autre_ext()">
                        <InputCamera
                            :images="images.img_autre_ext"
                            :label="labels.img_autre_ext + ' *'"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage('img_autre_ext', newValue)
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="condition_commentaire_autre_ext()"
                        :label="labels.commentaire_autre_ext"
                        :maxlength="254"
                        :value="form.commentaire_autre_ext"
                        @update:value="
                            (newValue) =>
                                saveData('commentaire_autre_ext', newValue)
                        "
                    />
                </el-col>
                <el-col
                    v-show="selectedTab === 'FIBRE'"
                    :span="24"
                >
                    <CustomRadioButton
                        :label="labels.plan_cablage"
                        :options="options.plan_cablage"
                        :value="form.plan_cablage"
                        size="small"
                        @update:value="
                            (newValue) => saveData('plan_cablage', newValue)
                        "
                    />

                    <div v-show="condition_img_plan_cablage()">
                        <InputCamera
                            :images="images.img_plan_cablage"
                            :label="labels.img_plan_cablage + ' *'"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage('img_plan_cablage', newValue)
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="condition_commentaire_plan_cablage()"
                        :label="labels.commentaire_plan_cablage"
                        :maxlength="254"
                        :value="form.commentaire_plan_cablage"
                        @update:value="
                            (newValue) =>
                                saveData('commentaire_plan_cablage', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.degraissage_fibres"
                        :options="options.degraissage_fibres"
                        :value="form.degraissage_fibres"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('degraissage_fibres', newValue)
                        "
                    />

                    <div v-show="condition_img_degraissage_fibres()">
                        <InputCamera
                            :images="images.img_degraissage_fibres"
                            :label="labels.img_degraissage_fibres + ' *'"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage(
                                        'img_degraissage_fibres',
                                        newValue,
                                    )
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="condition_commentaire_degraissage_fibres()"
                        :label="labels.commentaire_degraissage_fibres"
                        :maxlength="254"
                        :value="form.commentaire_degraissage_fibres"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_degraissage_fibres',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.detubage_fibres"
                        :options="options.detubage_fibres"
                        :value="form.detubage_fibres"
                        size="small"
                        @update:value="
                            (newValue) => saveData('detubage_fibres', newValue)
                        "
                    />

                    <div v-show="condition_img_detubage_fibres()">
                        <InputCamera
                            :images="images.img_detubage_fibres"
                            :label="labels.img_detubage_fibres + ' *'"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage('img_detubage_fibres', newValue)
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="condition_commentaire_detubage_fibres()"
                        :label="labels.commentaire_detubage_fibres"
                        :maxlength="254"
                        :value="form.commentaire_detubage_fibres"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_detubage_fibres',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.presence_cache"
                        :options="options.presence_cache"
                        :value="form.presence_cache"
                        size="small"
                        @update:value="
                            (newValue) => saveData('presence_cache', newValue)
                        "
                    />

                    <div v-show="condition_img_presence_cache()">
                        <InputCamera
                            :images="images.img_presence_cache"
                            :label="labels.img_presence_cache + ' *'"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage('img_presence_cache', newValue)
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="condition_commentaire_presence_cache()"
                        :label="labels.commentaire_presence_cache"
                        :maxlength="254"
                        :value="form.commentaire_presence_cache"
                        @update:value="
                            (newValue) =>
                                saveData('commentaire_presence_cache', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.protection_fibres"
                        :options="options.protection_fibres"
                        :value="form.protection_fibres"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('protection_fibres', newValue)
                        "
                    />

                    <div v-show="condition_img_protection_fibres()">
                        <InputCamera
                            :images="images.img_protection_fibres"
                            :label="labels.img_protection_fibres + ' *'"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage('img_protection_fibres', newValue)
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="condition_commentaire_protection_fibres()"
                        :label="labels.commentaire_protection_fibres"
                        :maxlength="254"
                        :value="form.commentaire_protection_fibres"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_protection_fibres',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.rangement_attentes"
                        :options="options.rangement_attentes"
                        :value="form.rangement_attentes"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('rangement_attentes', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.img_rangement_attentes"
                        :label="labels.img_rangement_attentes + ' *'"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_rangement_attentes', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.commentaire_rangement_attentes"
                        :maxlength="254"
                        :value="form.commentaire_rangement_attentes"
                        @update:value="
                            (newValue) =>
                                saveData(
                                    'commentaire_rangement_attentes',
                                    newValue,
                                )
                        "
                    />
                    <CustomRadioButton
                        :label="labels.longueurs_love"
                        :options="options.longueurs_love"
                        :value="form.longueurs_love"
                        size="small"
                        @update:value="
                            (newValue) => saveData('longueurs_love', newValue)
                        "
                    />

                    <div v-show="condition_img_longueurs_love()">
                        <InputCamera
                            :images="images.img_longueurs_love"
                            :label="labels.img_longueurs_love + ' *'"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage('img_longueurs_love', newValue)
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="condition_commentaire_longueurs_love()"
                        :label="labels.commentaire_longueurs_love"
                        :maxlength="254"
                        :value="form.commentaire_longueurs_love"
                        @update:value="
                            (newValue) =>
                                saveData('commentaire_longueurs_love', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.rayon_courbure"
                        :options="options.rayon_courbure"
                        :value="form.rayon_courbure"
                        size="small"
                        @update:value="
                            (newValue) => saveData('rayon_courbure', newValue)
                        "
                    />

                    <div v-show="condition_img_rayon_courbure()">
                        <InputCamera
                            :images="images.img_rayon_courbure"
                            :label="labels.img_rayon_courbure + ' *'"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage('img_rayon_courbure', newValue)
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="condition_commentaire_rayon_courbure()"
                        :label="labels.commentaire_rayon_courbure"
                        :maxlength="254"
                        :value="form.commentaire_rayon_courbure"
                        @update:value="
                            (newValue) =>
                                saveData('commentaire_rayon_courbure', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.autre_fibre"
                        :options="options.autre_fibre"
                        :value="form.autre_fibre"
                        size="small"
                        @update:value="
                            (newValue) => saveData('autre_fibre', newValue)
                        "
                    />

                    <div v-show="condition_img_autre_fibre()">
                        <InputCamera
                            :images="images.img_autre_fibre"
                            :label="labels.img_autre_fibre + ' *'"
                            :layer-name="layerName"
                            @update:images="
                                (newValue) =>
                                    saveImage('img_autre_fibre', newValue)
                            "
                        />
                    </div>
                    <CustomInput
                        v-show="condition_commentaire_autre_fibre()"
                        :label="labels.commentaire_autre_fibre"
                        :maxlength="254"
                        :value="form.commentaire_autre_fibre"
                        @update:value="
                            (newValue) =>
                                saveData('commentaire_autre_fibre', newValue)
                        "
                    />
                </el-col>
            </el-row>
        </template>
    </FormWrapper>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import {
    labels,
    options,
} from '@connect-field/client/components/forms/NGE/form_audit_pbo_nge.data';
import type { FormDataInterface } from '@connect-field/client/components/forms/form.types';
import FormTemplate from '@connect-field/client/components/forms/FormTemplate.vue';

interface DataInterface extends FormDataInterface {}

export default defineComponent({
    extends: FormTemplate,
    data(): DataInterface {
        return {
            changes: false,
            form: {},
            images: {
                img_emplacement_boitier: [],
                img_type_cable: [],
                img_presence_gaine_fendue: [],
                img_etiquette_bpe: [],
                img_fixation_boitier: [],
                img_diametre_ecam: [],
                img_etancheite_pbo: [],
                img_arrimage_cables: [],
                img_autre_ext: [],
                img_plan_cablage: [],
                img_degraissage_fibres: [],
                img_detubage_fibres: [],
                img_presence_cache: [],
                img_protection_fibres: [],
                img_rangement_attentes: [],
                img_longueurs_love: [],
                img_rayon_courbure: [],
                img_autre_fibre: [],
            },
            labels,
            loading: true,
            mandatoryFields: [
                'statut',
                'img_emplacement_boitier',
                'img_type_cable',
                'img_presence_gaine_fendue',
                'img_etiquette_bpe',
                'presence_fixation',
                'img_fixation_boitier',
                'img_diametre_ecam',
                'img_etancheite_pbo',
                'img_arrimage_cables',
                'img_autre_ext',
                'img_plan_cablage',
                'img_degraissage_fibres',
                'img_detubage_fibres',
                'img_presence_cache',
                'img_protection_fibres',
                'img_rangement_attentes',
                'img_longueurs_love',
                'img_rayon_courbure',
                'img_autre_fibre',
            ],
            options,
            selectedTab: 'METIER',
            valueMissing: false,
        };
    },
    methods: {
        condition_bp_pt_code(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === true;
        },
        condition_bp_lt_code(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === true;
        },
        condition_bp_sf_code(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === true;
        },
        condition_bp_prop(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === true;
        },
        condition_bp_gest(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === true;
        },
        condition_bp_user(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === true;
        },
        condition_bp_proptyp(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === true;
        },
        condition_bp_statut(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === true;
        },
        condition_bp_etat(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === true;
        },
        condition_bp_occp(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === true;
        },
        condition_bp_datemes(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === true;
        },
        condition_bp_avct(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === true;
        },
        condition_bp_typephy(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === true;
        },
        condition_bp_typelog(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === true;
        },
        condition_bp_rf_code(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === true;
        },
        condition_bp_entrees(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === true;
        },
        condition_bp_ref_kit(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === true;
        },
        condition_bp_ca_nb(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === true;
        },
        condition_bp_nb_pas(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === true;
        },
        condition_bp_linecod(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === true;
        },
        condition_bp_oc_code(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === true;
        },
        condition_bp_racco(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === true;
        },
        condition_bp_comment(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === true;
        },
        condition_bp_creadat(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === true;
        },
        condition_bp_majdate(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === true;
        },
        condition_bp_majsrc(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === true;
        },
        condition_bp_abddate(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === true;
        },
        condition_bp_abdsrc(): boolean {
            const { donnees_metier } = this.form;
            return donnees_metier === true;
        },
        condition_presence_fixation(): boolean {
            const { fixation_boitier } = this.form;
            return fixation_boitier?.startsWith('Non-conforme');
        },
        condition_img_autre_ext(): boolean {
            const { autre_ext } = this.form;
            return autre_ext?.startsWith('Non-conforme');
        },
        condition_commentaire_autre_ext(): boolean {
            const { autre_ext } = this.form;
            return autre_ext?.startsWith('Non-conforme');
        },
        condition_img_plan_cablage(): boolean {
            const { plan_cablage } = this.form;
            return plan_cablage?.startsWith('Non-conforme');
        },
        condition_commentaire_plan_cablage(): boolean {
            const { plan_cablage } = this.form;
            return plan_cablage?.startsWith('Non-conforme');
        },
        condition_img_degraissage_fibres(): boolean {
            const { degraissage_fibres } = this.form;
            return degraissage_fibres?.startsWith('Non-conforme');
        },
        condition_commentaire_degraissage_fibres(): boolean {
            const { degraissage_fibres } = this.form;
            return degraissage_fibres?.startsWith('Non-conforme');
        },
        condition_img_detubage_fibres(): boolean {
            const { detubage_fibres } = this.form;
            return detubage_fibres?.startsWith('Non-conforme');
        },
        condition_commentaire_detubage_fibres(): boolean {
            const { detubage_fibres } = this.form;
            return detubage_fibres?.startsWith('Non-conforme');
        },
        condition_img_presence_cache(): boolean {
            const { presence_cache } = this.form;
            return presence_cache?.startsWith('Non-conforme');
        },
        condition_commentaire_presence_cache(): boolean {
            const { presence_cache } = this.form;
            return presence_cache?.startsWith('Non-conforme');
        },
        condition_img_protection_fibres(): boolean {
            const { protection_fibres } = this.form;
            return protection_fibres?.startsWith('Non-conforme');
        },
        condition_commentaire_protection_fibres(): boolean {
            const { protection_fibres } = this.form;
            return protection_fibres?.startsWith('Non-conforme');
        },
        condition_img_longueurs_love(): boolean {
            const { longueurs_love } = this.form;
            return longueurs_love?.startsWith('Non-conforme');
        },
        condition_commentaire_longueurs_love(): boolean {
            const { longueurs_love } = this.form;
            return longueurs_love?.startsWith('Non-conforme');
        },
        condition_img_rayon_courbure(): boolean {
            const { rayon_courbure } = this.form;
            return rayon_courbure?.startsWith('Non-conforme');
        },
        condition_commentaire_rayon_courbure(): boolean {
            const { rayon_courbure } = this.form;
            return rayon_courbure?.startsWith('Non-conforme');
        },
        condition_img_autre_fibre(): boolean {
            const { autre_fibre } = this.form;
            return autre_fibre?.startsWith('Non-conforme');
        },
        condition_commentaire_autre_fibre(): boolean {
            const { autre_fibre } = this.form;
            return autre_fibre?.startsWith('Non-conforme');
        },
    },
});
</script>
