import type { OptionsInterface, SelectInterface } from '@connect-field/client/components/forms/form.types';

export const labels = {
    arrimage_des_cables_dans_la_b1: 'Arrimage des câbles dans la boîte',
    autres_observations: 'Observations',
    bp_abddate: "Date d'abandon",
    bp_abdsrc: "Cause de l'abandon",
    bp_avct: 'Avancement',
    bp_ca_nb: 'Nb cassettes',
    bp_code: 'Code Grace',
    bp_codeext: 'Code externe',
    bp_comment: 'Commentaires',
    bp_creadat: "Date de création de l'objet en base (peut être calculé)",
    bp_datemes: 'Date de mise en service',
    bp_entrees: 'Nb entrées câbles',
    bp_etat: 'État',
    bp_etiquet: 'Etiquette terrain',
    bp_gest: 'Gestionnaire',
    bp_linecod: "Code d'une ligne (cas FTTH) selon la nomenclature du régulateur",
    bp_lt_code: 'Code de local technique',
    bp_majdate: "Date de la mise à jour de l'objet en base (peut être calculé)",
    bp_majsrc: 'Source de mise à jour',
    bp_nb_pas: 'Nb pas organiseur BPE',
    bp_oc_code: 'Référence OC (Opérateur Commercial) de la prise terminale',
    bp_occp: 'Occupation',
    bp_prop: 'Propriétaire',
    bp_proptyp: 'Type de propriété',
    bp_pt_code: 'Code point technique',
    bp_racco: "Codification Interop de l'échec du raccordement",
    bp_ref_kit: 'Réf kit entrée câble',
    bp_rf_code: 'Référence',
    bp_sf_code: 'Identifiant SUF',
    bp_statut: "Phase d'avancement",
    bp_typelog: 'Type logique',
    bp_typephy: 'Type physique',
    bp_user: 'Utilisateur',
    cache_present_sur_les_cassett: 'Caches présents sur les cassettes',
    commentaire_arrimage_des_cables_dans_la_b1: 'Commentaire',
    commentaire_autres_observations: 'Commentaire',
    commentaire_cache_present_sur_les_cassett: 'Commentaire',
    commentaire_degraissage_fibres: 'Commentaire',
    commentaire_demi_lune_aero_sout_2_50_m: 'Commentaire',
    commentaire_etancheite_de_la_boite1: 'Commentaire',
    commentaire_etat_de_l_appui: 'Commentaire',
    commentaire_etiquetage_du_des_cables_s_1: 'Commentaire',
    commentaire_etiquette_de_boite1: 'Commentaire',
    commentaire_fixation_cable_s_sur_rehauss: 'Commentaire',
    commentaire_fixation_de_la_boite1: 'Commentaire',
    commentaire_fixation_de_la_love_de_cable: 'Commentaire',
    commentaire_fixation_du_des_cables_s_1: 'Commentaire',
    commentaire_hauteur_reglementaire_de_la_b: 'Commentaire',
    commentaire_identification_des_micromodul: 'Commentaire',
    commentaire_longueur_denudee_du_des_cab: 'Commentaire',
    commentaire_lovage_dans_les_cassettes_et_1: 'Commentaire',
    commentaire_marquage_pochage1: 'Commentaire',
    commentaire_pince_d_ancrage_et_chapeau_de: 'Commentaire',
    commentaire_presence_de_bandeau_vert: 'Commentaire',
    commentaire_presence_de_love_goutte_d_e: 'Commentaire',
    commentaire_rehausse_traverse: 'Commentaire',
    commentaire_reperage_des_cassettes_n_1: 'Commentaire',
    commentaire_respect_du_rayon_de_courbure_1: 'Commentaire',
    commentaire_soudures_des_fibres1: 'Commentaire',
    degraissage_fibres: 'Dégraissage fibres',
    demi_lune_aero_sout_2_50_m: 'Demi-Lune Aéro-Sout [2,50 mètres]',
    donnees_metier: 'Affichage des données métier',
    etancheite_de_la_boite1: 'Etanchéité de la boîte',
    etat_de_l_appui: "État de l'appui",
    etiquetage_du_des_cables_s_1: 'Etiquetage du (des) câble(s) en entrée de boîte',
    etiquette_de_boite1: 'Étiquette de boîte',
    fixation_cable_s_sur_rehauss: 'Fixation câble(s) sur traverse',
    fixation_de_la_boite1: 'Fixation de la boîte',
    fixation_de_la_love_de_cable: 'Fixation de la love de câble(s)',
    fixation_du_des_cables_s_1: 'Fixation du (des) câbles(s)',
    hauteur_reglementaire_de_la_b: 'Hauteur réglementaire de la boîte [2,2 à 4 mètres]',
    identification_des_micromodul: 'Identification des micromodules (bagues)',
    img_arrimage_des_cables_dans_la_b1: 'Photos',
    img_autres_observations: 'Photos',
    img_cache_present_sur_les_cassett: 'Photos',
    img_degraissage_fibres: 'Photos',
    img_demi_lune_aero_sout_2_50_m: 'Photos',
    img_etancheite_de_la_boite1: 'Photos',
    img_etat_de_l_appui: 'Photos',
    img_etiquetage_du_des_cables_s_1: 'Photos',
    img_etiquette_de_boite1: 'Photos',
    img_fixation_cable_s_sur_rehauss: 'Photos',
    img_fixation_de_la_boite1: 'Photos',
    img_fixation_de_la_love_de_cable: 'Photos',
    img_fixation_du_des_cables_s_1: 'Photos',
    img_hauteur_reglementaire_de_la_b: 'Photos',
    img_identification_des_micromodul: 'Photos',
    img_longueur_denudee_du_des_cab: 'Photos',
    img_lovage_dans_les_cassettes_et_1: 'Photos',
    img_marquage_pochage1: 'Photos',
    img_pince_d_ancrage_et_chapeau_de: 'Photos',
    img_presence_de_bandeau_vert: 'Photos',
    img_presence_de_love_goutte_d_e: 'Photos',
    img_rehausse_traverse: 'Photos',
    img_reperage_des_cassettes_n_1: 'Photos',
    img_respect_du_rayon_de_courbure_1: 'Photos',
    img_soudures_des_fibres1: 'Photos',
    longueur_denudee_du_des_cab: 'Longueur dénudée du (des) câbles(s) en entrée de boîte',
    lovage_dans_les_cassettes_et_1: 'Lovage dans les cassettes et rangement des soudures',
    marquage_pochage1: 'Marquage / Pochage',
    pince_d_ancrage_et_chapeau_de: "Pince d'ancrage et chapeau de gendarme",
    presence_d3: 'Présence D3',
    presence_de_bandeau_vert: 'Présence de bandeau vert',
    presence_de_love_goutte_d_e: "Présence de love / goutte d'eau inférieur à 30 cm",
    rehausse_traverse: 'Traverse / rehausse',
    reperage_des_cassettes_n_1: 'Repérage des cassettes (n°)',
    respect_du_rayon_de_courbure_1: 'Respect du rayon de courbure du (des) câbles',
    soudures_des_fibres1: 'État fibres / soudures',
    statut: 'Statut audit',
    statut_d3: 'Statut de D3',
};

export const options: Record<string, OptionsInterface | SelectInterface> = {
    arrimage_des_cables_dans_la_b1: [
        { label: 'OK', value: 'OK' },
        { label: 'NOK (min)', value: 'NOK_min' },
        { label: 'NOK (MAJ)', value: 'NOK_maj' },
    ],
    autres_observations: [
        { label: 'OUI', value: 'OUI' },
        { label: 'NON', value: 'NON' },
    ],
    bp_avct: [
        { label: 'EXISTANT', value: 'E' },
        { label: 'A CREER', value: 'C' },
        { label: 'TRAVAUX', value: 'T' },
        { label: 'EN SERVICE', value: 'S' },
        { label: 'HORS SERVICE', value: 'H' },
        { label: 'ABANDONNE', value: 'A' },
    ],
    bp_etat: [
        { label: 'A CHANGER', value: 'HS' },
        { label: 'MAUVAIS ETAT', value: 'ME' },
        { label: 'BON ETAT', value: 'OK' },
        { label: 'NON CONCERNE', value: 'NC' },
    ],
    bp_occp: [
        { label: 'VIDE', value: '0' },
        { label: 'NON VIDE EXPLOITABLE', value: '1.1' },
        { label: 'NON VIDE NON EXPLOITABLE', value: '1.2' },
        { label: 'SATUREE', value: '2' },
    ],
    bp_proptyp: [
        { label: 'CONSTRUCTION', value: 'CST' },
        { label: 'RACHAT', value: 'RAC' },
        { label: 'CESSION', value: 'CES' },
        { label: 'IRU', value: 'IRU' },
        { label: 'LOCATION', value: 'LOC' },
        { label: 'OCCUPATION', value: 'OCC' },
    ],
    bp_racco: [
        { label: 'CLIENT: CONTACT ERRONE', value: 'FCLI01' },
        {
            label: 'CLIENT : CLIENT INJOIGNABLE IMPOSSIBLE DE PRENDRE RDV',
            value: 'FCLI02',
        },
        {
            label: 'CLIENT : CLIENT N HABITE PAS A L ADRESSE INDIQUEE',
            value: 'FCLI03',
        },
        {
            label: 'CLIENT: DEMANDE ANNULATION DE LA COMMANDE PAR LE CLIENT FINAL',
            value: 'FCLI04',
        },
        { label: 'CLIENT : REFUS TRAVAUX CLIENT', value: 'FCLI05' },
        { label: 'CLIENT : REFUS GESTIONNAIRE IMMEUBLE', value: 'FCLI06' },
        {
            label: 'CLIENT : CLIENT ABSENT LORS DE L INTERVENTION',
            value: 'FCLI07',
        },
        {
            label: 'ADRESSE : CODE ADRESSE IMMEUBLE INEXISTANT DANS LE REFERENTIEL OI',
            value: 'FADR01',
        },
        {
            label: 'ADRESSE : BATIMENT MANQUANT OU INEXISTANT DANS LE REFERENTIEL OI',
            value: 'FADR02',
        },
        {
            label: 'ADRESSE : ESCALIER MANQUANT OU INEXISTANT DANS LE REFERENTIEL OI',
            value: 'FADR03',
        },
        {
            label: 'ADRESSE : ETAGE MANQUANT OU INEXISTANT DANS LE REFERENTIEL OI',
            value: 'FADR04',
        },
        { label: 'TRAITEMENT IMPOSSIBLE : PTO REQUISE', value: 'FIMP01' },
        {
            label: 'TRAITEMENT IMPOSSIBLE : PTO INEXISTANTE DANS LE REFERENTIEL OI',
            value: 'FIMP02',
        },
        {
            label: 'TRAITEMENT IMPOSSIBLE : PTO INCONNUE A L ADRESSE',
            value: 'FIMP03',
        },
        {
            label: 'TRAITEMENT IMPOSSIBLE : REF PRESTATION PM INEXISTANTE DANS LE REFERENTIEL OI',
            value: 'FIMP04',
        },
        {
            label: 'TRAITEMENT IMPOSSIBLE : REF PRESTATION PM ET ADRESSE INCOHERENTES',
            value: 'FIMP05',
        },
        {
            label: 'TRAITEMENT IMPOSSIBLE : TYPE DE COMMANDE IRRECEVABLE SUR CETTE REF PRESTATION PM',
            value: 'FIMP06',
        },
        {
            label: 'TRAITEMENT IMPOSSIBLE : PM INEXISTANT DANS LE REFERENTIEL OI',
            value: 'FIMP07',
        },
        {
            label: 'TRAITEMENT IMPOSSIBLE : PM ET ADRESSE INCOHERENTS',
            value: 'FIMP08',
        },
        {
            label: 'TRAITEMENT IMPOSSIBLE : COMMANDE IRRECEVABLE SUR CE PM',
            value: 'FIMP09',
        },
        {
            label: 'TRAITEMENT IMPOSSIBLE : COMMANDE IRRECEVABLE SUR CETTE ADRESSE',
            value: 'FIMP10',
        },
        {
            label: 'TRAITEMENT IMPOSSIBLE : ADRESSE INELIGIBLE TEMPORAIREMENT',
            value: 'FIMP11',
        },
        {
            label: 'TRAITEMENT IMPOSSIBLE : TYPE COMMANDE ERRONNE',
            value: 'FIMP12',
        },
        {
            label: 'TRAITEMENT IMPOSSIBLE : IDENTIFIANT COMMANDE INTERNE OC DEJA UTILISE',
            value: 'FIMP13',
        },
        {
            label: 'TRAITEMENT IMPOSSIBLE : IDENTIFIANT COMMANDE INTERNE OC INCONNUE',
            value: 'FIMP14',
        },
        {
            label: 'TRAITEMENT IMPOSSIBLE : CHAMPS OBLIGATOIRES MANQUANTS',
            value: 'FIMP15',
        },
        {
            label: 'TRAITEMENT IMPOSSIBLE : CHAMPS INCOHERENTS',
            value: 'FIMP16',
        },
        { label: 'TRAITEMENT IMPOSSIBLE : PTO INEXISTANTE', value: 'FIMP17' },
        { label: 'TRAITEMENT IMPOSSIBLE : PTO EXISTANTE', value: 'FIMP18' },
        {
            label: 'TRAITEMENT IMPOSSIBLE : PTO DEJA AFFECTEE A L OC',
            value: 'FIMP19',
        },
        { label: 'ECHEC PRODUCTION : PB OU PM SATURE', value: 'FINT01' },
        {
            label: 'ECHEC PRODUCTION : SATURATION VIRTUELLE PB OU PM',
            value: 'FINT02',
        },
        { label: 'ECHEC PRODUCTION : HOTLINE OI INJOIGNABLE', value: 'FINT03' },
        {
            label: 'ECHEC PRODUCTION : ABSENCE DE CONTINUITE OPTIQUE',
            value: 'FINT04',
        },
        {
            label: 'ECHEC PRODUCTION : AFFAIBLISSEMENT TROP IMPORTANT',
            value: 'FINT05',
        },
        {
            label: 'ECHEC PRODUCTION : ROUTE OPTIQUE DEJA UTILISEE',
            value: 'FINT06',
        },
        {
            label: 'ECHEC PRODUCTION : INFORMATIONS ROUTE OPTIQUE ERRONEES',
            value: 'FINT07',
        },
        {
            label: 'ECHEC PRODUCTION : POSITION BRASSAGE BAIE OPERATEUR INTROUVABLE',
            value: 'FINT08',
        },
        {
            label: 'ECHEC PRODUCTION : POSITION BRASSAGE BAIE OPERATEUR DEJA UTILISEE',
            value: 'FINT09',
        },
        {
            label: 'ECHEC PRODUCTION : AUTRE PROBLEME TECHNIQUE',
            value: 'FINT10',
        },
        {
            label: 'ECHEC PRODUCTION : INFRA TIERS INDISPONIBLE OU DELAI',
            value: 'FINT11',
        },
        { label: 'ECHEC PRODUCTION : PBO NON CONFORME', value: 'FINT12' },
        { label: 'ECHEC PRODUCTION : DEFAUT DE VERTICALITE', value: 'FINT13' },
        { label: 'AUTRE MOTIF : COMMENTAIRES LIBRES', value: 'FAUT01' },
        {
            label: 'RDV : NOMBRE MAX DE MODIFICATIONS DE RDV DEPASSE',
            value: 'FRDV01',
        },
        {
            label: 'RDV : PAS DE PLAGES DE RDV DISPONIBLES SUR CES CRENEAUX',
            value: 'FRDV02',
        },
        { label: 'RDV : PLAGE DE RDV NON RESERVEE', value: 'FRDV03' },
        { label: 'RDV : ETAT RDV NON VALIDE', value: 'FRDV04' },
        { label: 'RDV : RDV SUR PRODUIT NON FTTH', value: 'FRDV05' },
        { label: 'RDV : RDV SUR ADRESSE DIFFERENTE', value: 'FRDV06' },
    ],
    bp_statut: [
        { label: 'ETUDE PRELIMINAIRE', value: 'PRE' },
        { label: 'ETUDE DE DIAGNOSTIC', value: 'DIA' },
        { label: 'AVANT-PROJET', value: 'AVP' },
        { label: 'PROJET', value: 'PRO' },
        { label: 'PASSATION DES MARCHES DE TRAVAUX', value: 'ACT' },
        { label: 'ETUDE D EXECUTION', value: 'EXE' },
        { label: 'TRAVAUX', value: 'TVX' },
        { label: 'RECOLEMENT', value: 'REC' },
        { label: 'MAINTIENT EN CONDITIONS OPERATIONNELLES', value: 'MCO' },
    ],
    bp_typelog: [
        { label: 'BOITIER PROTECTION EPISSURE', value: 'BPE' },
        { label: 'POINT DE TERMINAISON OPTIQUE', value: 'PTO' },
        { label: 'POINT DE BRANCHEMENT OPTIQUE', value: 'PBO' },
        { label: 'DISPOSITIF DE TERMINAISON INTERIEUR OPTIQUE', value: 'DTI' },
    ],
    bp_typephy: [
        { label: 'BPE 6FO', value: 'B006' },
        { label: 'BPE 12FO', value: 'B012' },
        { label: 'BPE 24FO', value: 'B024' },
        { label: 'BPE 36FO', value: 'B036' },
        { label: 'BPE 48FO', value: 'B048' },
        { label: 'BPE 72FO', value: 'B072' },
        { label: 'BPE 96FO', value: 'B096' },
        { label: 'BPE 144FO', value: 'B144' },
        { label: 'BPE 288FO', value: 'B288' },
        { label: 'BPE 432FO', value: 'B432' },
        { label: 'BPE 576FO', value: 'B576' },
        { label: 'BPE 720FO', value: 'B720' },
        { label: 'COFFRET', value: 'COF' },
        { label: 'DTIO 1FO', value: 'DTI1' },
        { label: 'DTIO 2FO', value: 'DTI2' },
        { label: 'DTIO 4FO', value: 'DTI4' },
        { label: 'AUTRE', value: 'AUTR' },
    ],
    cache_present_sur_les_cassett: [
        { label: 'OK', value: 'OK' },
        { label: 'NOK (min)', value: 'NOK_min' },
        { label: 'NOK (MAJ)', value: 'NOK_maj' },
    ],
    degraissage_fibres: [
        { label: 'OK', value: 'OK' },
        { label: 'NOK (min)', value: 'NOK_min' },
        { label: 'NOK (MAJ)', value: 'NOK_maj' },
    ],
    demi_lune_aero_sout_2_50_m: [
        { label: 'OK', value: 'OK' },
        { label: 'NOK (min)', value: 'NOK_min' },
        { label: 'NOK (MAJ)', value: 'NOK_maj' },
    ],
    donnees_metier: [
        { label: 'OUI', value: 'OUI' },
        { label: 'NON', value: 'NON' },
    ],
    etancheite_de_la_boite1: [
        { label: 'OK', value: 'OK' },
        { label: 'NOK (min)', value: 'NOK_min' },
        { label: 'NOK (MAJ)', value: 'NOK_maj' },
    ],
    etat_de_l_appui: [
        { label: 'OK', value: 'OK' },
        { label: 'NOK (min)', value: 'NOK_min' },
        { label: 'NOK (MAJ)', value: 'NOK_maj' },
    ],
    etiquetage_du_des_cables_s_1: [
        { label: 'OK', value: 'OK' },
        { label: 'NOK (min)', value: 'NOK_min' },
        { label: 'NOK (MAJ)', value: 'NOK_maj' },
    ],
    etiquette_de_boite1: [
        { label: 'OK', value: 'OK' },
        { label: 'NOK (min)', value: 'NOK_min' },
        { label: 'NOK (MAJ)', value: 'NOK_maj' },
    ],
    fixation_cable_s_sur_rehauss: [
        { label: 'OK', value: 'OK' },
        { label: 'NOK (min)', value: 'NOK_min' },
        { label: 'NOK (MAJ)', value: 'NOK_maj' },
    ],
    fixation_de_la_boite1: [
        { label: 'OK', value: 'OK' },
        { label: 'NOK (min)', value: 'NOK_min' },
        { label: 'NOK (MAJ)', value: 'NOK_maj' },
    ],
    fixation_de_la_love_de_cable: [
        { label: 'OK', value: 'OK' },
        { label: 'NOK (min)', value: 'NOK_min' },
        { label: 'NOK (MAJ)', value: 'NOK_maj' },
    ],
    fixation_du_des_cables_s_1: [
        { label: 'OK', value: 'OK' },
        { label: 'NOK (min)', value: 'NOK_min' },
        { label: 'NOK (MAJ)', value: 'NOK_maj' },
    ],
    hauteur_reglementaire_de_la_b: [
        { label: 'OK', value: 'OK' },
        { label: 'NOK (min)', value: 'NOK_min' },
        { label: 'NOK (MAJ)', value: 'NOK_maj' },
    ],
    identification_des_micromodul: [
        { label: 'OK', value: 'OK' },
        { label: 'NOK (min)', value: 'NOK_min' },
        { label: 'NOK (MAJ)', value: 'NOK_maj' },
    ],
    longueur_denudee_du_des_cab: [
        { label: 'OK', value: 'OK' },
        { label: 'NOK (min)', value: 'NOK_min' },
        { label: 'NOK (MAJ)', value: 'NOK_maj' },
    ],
    lovage_dans_les_cassettes_et_1: [
        { label: 'OK', value: 'OK' },
        { label: 'NOK (min)', value: 'NOK_min' },
        { label: 'NOK (MAJ)', value: 'NOK_maj' },
    ],
    marquage_pochage1: [
        { label: 'OK', value: 'OK' },
        { label: 'NOK (min)', value: 'NOK_min' },
        { label: 'NOK (MAJ)', value: 'NOK_maj' },
    ],
    pince_d_ancrage_et_chapeau_de: [
        { label: 'OK', value: 'OK' },
        { label: 'NOK (min)', value: 'NOK_min' },
        { label: 'NOK (MAJ)', value: 'NOK_maj' },
    ],
    presence_d3: { false: 'NON', true: 'OUI' },
    presence_de_bandeau_vert: [
        { label: 'OK', value: 'OK' },
        { label: 'NOK (min)', value: 'NOK_min' },
        { label: 'NOK (MAJ)', value: 'NOK_maj' },
    ],
    presence_de_love_goutte_d_e: [
        { label: 'OK', value: 'OK' },
        { label: 'NOK (min)', value: 'NOK_min' },
        { label: 'NOK (MAJ)', value: 'NOK_maj' },
    ],
    rehausse_traverse: [
        { label: 'OK', value: 'OK' },
        { label: 'NOK (min)', value: 'NOK_min' },
        { label: 'NOK (MAJ)', value: 'NOK_maj' },
    ],
    reperage_des_cassettes_n_1: [
        { label: 'OK', value: 'OK' },
        { label: 'NOK (min)', value: 'NOK_min' },
        { label: 'NOK (MAJ)', value: 'NOK_maj' },
    ],
    respect_du_rayon_de_courbure_1: [
        { label: 'OK', value: 'OK' },
        { label: 'NOK (min)', value: 'NOK_min' },
        { label: 'NOK (MAJ)', value: 'NOK_maj' },
    ],
    soudures_des_fibres1: [
        { label: 'OK', value: 'OK' },
        { label: 'NOK (min)', value: 'NOK_min' },
        { label: 'NOK (MAJ)', value: 'NOK_maj' },
    ],
    statut: [
        { label: 'Audit à réaliser', value: 'NON EFFECTUE' },
        { label: 'Audit partiellement réalisé', value: 'PARTIEL' },
        { label: 'Audit réalisé', value: 'TERMINE' },
        { label: 'Non concerné par un audit', value: 'NON CONCERNE' },
    ],
    statut_d3: [
        {
            label: 'Passage de D3 avec incidence',
            value: 'passage_D3_avec_incidence',
        },
        {
            label: 'Passage de D3 sans incidence',
            value: 'passage_D3_sans_incidence',
        },
        {
            label: 'Avec incidence non concerné par D2',
            value: 'avec_incidence_non_concerne_par_d3',
        },
    ],
};
