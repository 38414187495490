<template>
    <FormWrapper
        v-if="!loading"
        ref="customFormRef"
        :changes-model="changes"
        :form-model="form"
        :images-model="images"
        :latitude="latitude"
        :layer-name="layerName"
        :longitude="longitude"
        :mandatory-fields="mandatoryFields"
        :object-id="objectId"
        :project-id="projectId"
        @update:images-model="
            (e) => {
                images = e;
            }
        "
        @update:value-missing="
            (e) => {
                valueMissing = e;
            }
        "
        @update:form-model="
            (e) => {
                form = e;
            }
        "
        @update:changes-model="
            (e) => {
                changes = e;
            }
        "
    >
        <template #menu_tabs>
            <el-row class="scroll-menu">
                <el-col :span="12">
                    <div
                        :class="{ selectedTab: selectedTab === 'General' }"
                        class="tabs"
                        @click="selectTab('General')"
                    >
                        General
                    </div>
                </el-col>

                <el-col :span="12">
                    <div
                        :class="{ selectedTab: selectedTab === 'Chambre' }"
                        class="tabs"
                        @click="selectTab('Chambre')"
                    >
                        Chambre
                    </div>
                </el-col>
            </el-row>
        </template>
        <template #main_form>
            <el-row>
                <el-col
                    v-show="selectedTab === 'General'"
                    :span="24"
                >
                    <CustomInput
                        :label="labels.code"
                        :value="form.code"
                        @update:value="(newValue) => saveData('code', newValue)"
                    />
                    <CustomInput
                        :label="labels.gestionnaire"
                        :maxlength="254"
                        :value="form.gestionnaire"
                        @update:value="
                            (newValue) => saveData('gestionnaire', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.type_fonc"
                        :value="form.type_fonc"
                        @update:value="
                            (newValue) => saveData('type_fonc', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.type_struc"
                        :maxlength="254"
                        :value="form.type_struc"
                        @update:value="
                            (newValue) => saveData('type_struc', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.modele"
                        :value="form.modele"
                        @update:value="
                            (newValue) => saveData('modele', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.date_pose"
                        :maxlength="254"
                        :value="form.date_pose"
                        @update:value="
                            (newValue) => saveData('date_pose', newValue)
                        "
                    />
                    <CustomDatePicker
                        :label="labels.date_audit"
                        :value="form.date_audit"
                        @update:value="
                            (newValue) => saveData('date_audit', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.respect_pose"
                        :options="options.respect_pose"
                        :value="form.respect_pose"
                        size="small"
                        @update:value="
                            (newValue) => saveData('respect_pose', newValue)
                        "
                    />
                    <CustomInput
                        :label="labels.comment_pose"
                        :value="form.comment_pose"
                        @update:value="
                            (newValue) => saveData('comment_pose', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.respect_pgc"
                        :options="options.respect_pgc"
                        :value="form.respect_pgc"
                        size="small"
                        @update:value="
                            (newValue) => saveData('respect_pgc', newValue)
                        "
                    />
                </el-col>
                <el-col
                    v-show="selectedTab === 'Chambre'"
                    :span="24"
                >
                    <InputCamera
                        :images="images.img_chambre_environnement"
                        :label="labels.img_chambre_environnement"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_chambre_environnement', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.scellement_cadre"
                        :options="options.scellement_cadre"
                        :value="form.scellement_cadre"
                        size="small"
                        @update:value="
                            (newValue) => saveData('scellement_cadre', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.img_chambre_dessus"
                        :label="labels.img_chambre_dessus"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_chambre_dessus', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.masque_percute"
                        :options="options.masque_percute"
                        :value="form.masque_percute"
                        size="small"
                        @update:value="
                            (newValue) => saveData('masque_percute', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.img_chambre_masques"
                        :label="labels.img_chambre_masques"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) =>
                                saveImage('img_chambre_masques', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.nb_fourreaux"
                        :options="options.nb_fourreaux"
                        :value="form.nb_fourreaux"
                        size="small"
                        @update:value="
                            (newValue) => saveData('nb_fourreaux', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.qualite_refection"
                        :options="options.qualite_refection"
                        :value="form.qualite_refection"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('qualite_refection', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.img_refection"
                        :label="labels.img_refection"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('img_refection', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.etiquette_chambre"
                        :options="options.etiquette_chambre"
                        :value="form.etiquette_chambre"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('etiquette_chambre', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.type_chambre"
                        :options="options.type_chambre"
                        :value="form.type_chambre"
                        size="small"
                        @update:value="
                            (newValue) => saveData('type_chambre', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.respect_coupe"
                        :options="options.respect_coupe"
                        :value="form.respect_coupe"
                        size="small"
                        @update:value="
                            (newValue) => saveData('respect_coupe', newValue)
                        "
                    />
                    <CustomRadioButton
                        :label="labels.percement_chambre"
                        :options="options.percement_chambre"
                        :value="form.percement_chambre"
                        size="small"
                        @update:value="
                            (newValue) =>
                                saveData('percement_chambre', newValue)
                        "
                    />
                    <InputCamera
                        :images="images.img_autres"
                        :label="labels.img_autres"
                        :layer-name="layerName"
                        @update:images="
                            (newValue) => saveImage('img_autres', newValue)
                        "
                    />
                    <CustomInputSelect
                        :label="labels.criticite"
                        :options="options.criticite"
                        :value="form.criticite"
                        @update:value="
                            (newValue) => saveData('criticite', newValue)
                        "
                    />
                    <CustomInput
                        v-show="condition_comment()"
                        :label="labels.comment"
                        :value="form.comment"
                        @update:value="
                            (newValue) => saveData('comment', newValue)
                        "
                    />
                    <CustomInputSelect
                        :class="{ 'text-warning': valueMissing }"
                        :label="labels.statut_audit + ' *'"
                        :options="options.statut_audit"
                        :value="form.statut_audit"
                        @update:value="
                            (newValue) => saveData('statut_audit', newValue)
                        "
                    />
                </el-col>
            </el-row>
        </template>
    </FormWrapper>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import {
    labels,
    options,
} from '@connect-field/client/components/forms/NGE/form_concept_chambre_nge.data';
import type { FormDataInterface } from '@connect-field/client/components/forms/form.types';
import FormTemplate from '@connect-field/client/components/forms/FormTemplate.vue';

interface DataInterface extends FormDataInterface {}

export default defineComponent({
    extends: FormTemplate,
    data(): DataInterface {
        return {
            changes: false,
            form: {},
            images: {
                img_chambre_environnement: [],
                img_chambre_dessus: [],
                img_chambre_masques: [],
                img_refection: [],
                img_autres: [],
            },
            labels,
            loading: true,
            mandatoryFields: ['statut_audit'],
            options,
            selectedTab: 'General',
            valueMissing: false,
        };
    },
    methods: {
        condition_comment(): boolean {
            const { criticite } = this.form;
            return !!criticite;
        },
    },
});
</script>
