import type { OptionsInterface, SelectInterface } from '@connect-field/client/components/forms/form.types';

export const labels: Record<string, string> = {
    date_pose: 'Date de pose',
    img_cable: 'Photo Câble',
    img_environnement: 'Photo Environnement',
    img_etiquette: 'Photo Étiquette',
    tirimb_cable_capa: 'Capacité du câble',
    tirimb_cable_code: 'Nom du câble',
    tirimb_cable_glove: 'Gestion de la love',
    tirimb_cable_phase: 'Statut du câble',
    tirimb_cbl_acc: 'Accroche des câbles',
    tirimb_chb_obt: 'Obturation des fourreaux (remonté sout-colonne montante)',
    tirimb_chb_prop: 'Fixation',
    tirimb_chb_tmp: 'État',
    tirimb_code: 'Nom du Point Technique',
    tirimb_com_global: 'Commentaire global',
    tirimb_conf_cable: 'Conformité Câble LS0H - REC',
    tirimb_conv_imb: 'Convention immeuble',
    tirimb_dta: 'DTA',
    tirimb_dti: 'DTI',
    tirimb_ebp_code: 'Nom du boîtier',
    tirimb_ebp_type: 'Type de boîtier',
    tirimb_etq_cable: 'Étiquetage Entrée / Sortie de boîte',
    tirimb_etq_pot: 'Étiquetage Sortie de fourreaux',
    tirimb_fai: 'FAI',
    tirimb_gene: 'Généraliser à tous les câbles du support',
    tirimb_ptech_code: 'Gestionnaire du Point Technique',
    tirimb_ri: 'Respect / conformité RI',
    tirimb_statut_audit: "Statut de l'audit",
    tirimb_type_audit: "Type de l'audit",
};

export const options: Record<string, OptionsInterface | SelectInterface> = {
    tirimb_cable_glove: [
        { label: 'NON CONCERNE', value: 'NON CONCERNE' },
        { label: 'LOVE INTERDITE', value: 'LOVE INTERDITE' },
    ],
    tirimb_cbl_acc: [
        { label: 'RAS', value: 'RAS' },
        { label: 'NON RESPECT REGLE', value: 'NON RESPECT REGLE' },
        { label: 'CABLE TENDU', value: 'CABLE TENDU' },
    ],
    tirimb_chb_obt: [
        { label: 'NON CONCERNE', value: 'NON CONCERNE' },
        { label: 'RAS', value: 'RAS' },
        { label: 'DEFAUT DE JOINT VERS IMB', value: 'DEFAUT DE JOINT VERS IMB' },
        { label: 'ABSENCE BOUCHON', value: 'ABSENCE BOUCHON' },
    ],
    tirimb_chb_prop: [
        { label: 'RAS', value: 'RAS' },
        { label: 'DEFAUT DE FIXATION', value: 'DEFAUT DE FIXATION' },
    ],
    tirimb_chb_tmp: [
        { label: 'RAS', value: 'RAS' },
        { label: 'DETERIORE', value: 'DETERIORE' },
    ],
    tirimb_conf_cable: [
        { label: 'NON CONCERNE', value: 'NON CONCERNE' },
        { label: 'RAS', value: 'RAS' },
        { label: 'NON CONFORME', value: 'NON CONFORME' },
    ],
    tirimb_etq_cable: [
        { label: 'NON CONCERNE', value: 'NON CONCERNE' },
        { label: 'INCOHERENCE', value: 'INCOHERENCE' },
        { label: 'DEFAUT COULEUR', value: 'DEFAUT COULEUR' },
        { label: 'DEFAUT ETIQUETTE', value: 'DEFAUT ETIQUETTE' },
        { label: 'ABSENT', value: 'ABSENT' },
        { label: 'ETIQUETTE PROVISOIRE', value: 'ETIQUETTE PROVISOIRE' },
        { label: 'OK', value: 'OK' },
    ],
    tirimb_etq_pot: [
        { label: 'NON CONCERNE', value: 'NON CONCERNE' },
        { label: 'INCOHERENCE', value: 'INCOHERENCE' },
        { label: 'DEFAUT COULEUR', value: 'DEFAUT COULEUR' },
        { label: 'DEFAUT ETIQUETTE', value: 'DEFAUT ETIQUETTE' },
        { label: 'ABSENT', value: 'ABSENT' },
        { label: 'ETIQUETTE PROVISOIRE', value: 'ETIQUETTE PROVISOIRE' },
        { label: 'OK', value: 'OK' },
    ],
    tirimb_gene: { false: 'OUI', true: 'NON' },
    tirimb_ri: [
        { label: 'CREATION DE PARCOURS NON AUTORISE', value: 'CREATION DE PARCOURS NON AUTORISE' },
        { label: 'NON RESPECT DTI', value: 'NON RESPECT DTI' },
    ],
    tirimb_statut_audit: [
        { label: 'NON REALISE', value: 'NON REALISE' },
        { label: 'PARTIELLEMENT', value: 'PARTIELLEMENT' },
        { label: 'TERMINE AVEC RESERVE', value: 'TERMINE AVEC RESERVE' },
        { label: 'TERMINE SANS RESERVE', value: 'TERMINE SANS RESERVE' },
    ],
    tirimb_type_audit: { false: 'RECETTE', true: 'CONTROLE' },
};
