import type { OptionsInterface, SelectInterface } from '@connect-field/client/components/forms/form.types';

export const labels = {
    img_sro: 'Photo du SRO',
    st_abddate: "Date d'abandon de l'objet",
    st_abdsrc: "Cause de l'abandon de l'objet",
    st_ad_code: 'Identifiant unique contenu dans la table ADRESSE',
    st_avct: "Attribut synthétisant l'avancement.",
    st_code: 'Code du site',
    st_codeext: 'Code chez un tiers',
    st_comment: 'Commentaire',
    st_creadat: "Date de création de l'objet en base",
    st_dateins: "Date d'installation",
    st_datemes: 'Date de mise en service',
    st_etat: 'Etat du site.',
    st_gest: 'Identifiant du gestionnaire',
    st_majdate: "Date de la mise à jour de l'objet en base",
    st_majsrc: 'Source utilisée pour la mise à jour',
    st_nblines: 'Nombre de lignes du site.',
    st_nd_code: 'Identifiant unique contenu dans la table Noeud',
    st_nom: 'Nom du site.',
    st_prop: 'Identifiant du propriétaire',
    st_proptyp: 'Type de propriété',
    st_statut: "Phase d'avancement",
    st_typelog: 'Type logique du site',
    st_typephy: 'Type physique du site',
    st_user: 'utilisateur du site',
    statut: 'Statut audit',
};

export const options: Record<string, OptionsInterface | SelectInterface> = {
    st_etat: [
        { label: 'A CHANGER', value: 'HS' },
        { label: 'MAUVAIS ETAT', value: 'ME' },
        { label: 'BON ETAT', value: 'OK' },
        { label: 'NON CONCERNE', value: 'NC' },
    ],
    st_proptyp: [
        { label: 'CONSTRUCTION', value: 'CST' },
        { label: 'RACHAT', value: 'RAC' },
        { label: 'CESSION', value: 'CES' },
        { label: 'IRU', value: 'IRU' },
        { label: 'LOCATION', value: 'LOC' },
        { label: 'OCCUPATION', value: 'OCC' },
    ],
    st_statut: [
        { label: 'ETUDE PRELIMINAIRE', value: 'PRE' },
        { label: 'ETUDE DE DIAGNOSTIC', value: 'DIA' },
        { label: 'AVANT-PROJET', value: 'AVP' },
        { label: 'PROJET', value: 'PRO' },
        { label: 'PASSATION DES MARCHES DE TRAVAUX', value: 'ACT' },
        { label: 'ETUDE D EXECUTION', value: 'EXE' },
        { label: 'TRAVAUX', value: 'TVX' },
        { label: 'RECOLEMENT', value: 'REC' },
        { label: 'MAINTIENT EN CONDITIONS OPERATIONNELLES', value: 'MCO' },
    ],
    statut: [
        { label: 'Audit à réaliser', value: 'NON EFFECTUE' },
        { label: 'Audit partiellement réalisé', value: 'PARTIEL' },
        { label: 'Audit réalisé', value: 'TERMINE' },
        { label: 'Non concerné par un audit', value: 'NON CONCERNE' },
    ],
};
