/*
Store to save current cursor position
If an object is selected, the coordinate is set ?
 */

import { acceptHMRUpdate, defineStore } from 'pinia';
import type { Coordinate } from 'ol/coordinate';
import { toLonLat } from 'ol/proj';

import * as AddressApi from '@connect-field/client/services/api/address';
import type { GeoJSONReverseBANDto } from '@connect-field/client/sdk/generated';

export interface StateInterface {
    address?: string;
    addressLoading: boolean;
    coordinate?: Coordinate;
}

const useCoordinateStore = defineStore({
    actions: {
        /**
         * You have to set coordinate before execute this action
         */
        findAddress(): void {
            const coordinate = this.coordinate;
            if (coordinate) {
                this.addressLoading = true;
                const gps = toLonLat(coordinate);

                AddressApi.getReverseAddress(gps[1], gps[0])
                    .then((data: GeoJSONReverseBANDto) => {
                        const properties = data?.features?.[0]?.properties;

                        if (!properties) {
                            throw new Error('No address found');
                        }

                        this.$patch({
                            address: `${properties.name}, ${properties.postcode} ${properties.city}`,
                        });
                    })
                    .catch(() => {
                        // TODO : i18n
                        this.$patch({
                            address: 'Aucune adresse détectée',
                        });
                    })
                    .finally(() => {
                        this.$patch({ addressLoading: false });
                    });
            }
        },
        setCoordinate(coordinate: Coordinate | undefined) {
            this.coordinate = coordinate;
        },
    },
    id: 'coordinate',
    persist: true,
    state: (): StateInterface => {
        return {
            address: undefined,
            addressLoading: false,
            coordinate: undefined,
        };
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useCoordinateStore, import.meta.hot));
}

export default useCoordinateStore;
