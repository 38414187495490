export {};

if (navigator.storage && navigator.storage.persist) {
    navigator.storage.persist().then((persistent) => {
        if (persistent) {
            console.debug('[PWA] Le stockage ne sera pas nettoyé sauf en cas d’action humaine explicite');
        } else {
            console.warn('[PWA] Le stockage peut être nettoyé par le navigateur en cas de pression de stockage.');
        }
    });
}
